import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { MODALS } from '../../constants/modals';
import { IModalState } from '../../types/modals';
import * as AuthActions from '../actions/auth';
import * as ModalActions from '../actions/modals';
import * as OrganizationActions from '../actions/organizations';

const initialState: IModalState = {
  resetPassword: {
    id: MODALS.RESET_PASSWORD,
    isOpen: false,
    content: null,
  },
  rocket: {
    id: MODALS.ROCKET,
    isOpen: false,
    content: null,
    metadata: null,
  },
  opportunities: {
    id: MODALS.OPPORTUNITIES,
    isOpen: false,
    content: null,
    required: true,
  },
  integrationWarning: {
    id: MODALS.INTEGRATION_WARNING,
    isOpen: false,
    content: null,
    required: true,
  },
  downloadSuccess: {
    id: MODALS.DOWNLOAD_SUCCESS,
    isOpen: false,
    content: null,
  },
  lowInventoryItems: {
    id: MODALS.LOW_INVENTORY_ITEMS,
    isOpen: false,
    metadata: null,
  },
  removePII: {
    id: MODALS.REMOVE_PII,
    isOpen: false,
    metadata: null,
  },
};

const reducer = (state: IModalState = initialState, action: any): IModalState => {
  switch (action.type) {
    case ModalActions.TOGGLE_RESET_PASSWORD_MODAL: {
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          isOpen: typeof action.payload !== 'undefined' ? Boolean(action.payload) : !state.resetPassword.isOpen,
          content: action.payload || null,
        },
      };
    }
    case ModalActions.TOGGLE_ROCKET_MODAL: {
      return {
        ...state,
        rocket: {
          ...state.rocket,
          isOpen: typeof action.payload !== 'undefined' ? Boolean(action.payload) : !state.rocket.isOpen,
          content: action.payload || null,
          metadata: action.metadata || null,
        },
      };
    }
    case ModalActions.TOGGLE_OPPORTUNITIES_MODAL: {
      return {
        ...state,
        opportunities: {
          ...state.opportunities,
          isOpen: typeof action.payload !== 'undefined' ? Boolean(action.payload) : !state.opportunities.isOpen,
          content: action.payload || null,
        },
      };
    }
    case ModalActions.TOGGLE_INTEGRATION_WARNING_MODAL: {
      return {
        ...state,
        integrationWarning: {
          ...state.integrationWarning,
          isOpen: typeof action.payload !== 'undefined' ? Boolean(action.payload) : !state.integrationWarning.isOpen,
          content: action.payload || null,
        },
      };
    }
    case ModalActions.TOGGLE_DOWNLOAD_SUCCESS_MODAL: {
      return {
        ...state,
        downloadSuccess: {
          ...state.downloadSuccess,
          isOpen: typeof action.payload !== 'undefined' ? Boolean(action.payload) : !state.downloadSuccess.isOpen,
          content: action.payload || null,
        },
      };
    }
    case ModalActions.TOGGLE_LOW_INVENTORY_MODAL: {
      return {
        ...state,
        lowInventoryItems: {
          ...state.lowInventoryItems,
          isOpen: action.payload,
          metadata: action.metadata || null,
        },
      };
    }
    case ModalActions.TOGGLE_REMOVE_PII_MODAL: {
      return {
        ...state,
        removePII: {
          ...state.removePII,
          isOpen: action.payload,
          metadata: action.metadata || null,
        },
      };
    }
    case OrganizationActions.REMOVE_PII_SUCCESS: {
      return {
        ...state,
        removePII: {
          ...state.removePII,
          isOpen: false,
          metadata: null,
        },
      };
    }
    case FirebaseActions.LOGOUT:
    case AuthActions.ADMIN_ROLE_CHANGED:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
