import cn from 'classnames';
import * as React from 'react';
import { BUTTON_BUTTON } from '../../../../types/forms';

import styles from './RedCrossButton.module.scss';

interface IProps {
  style?: React.CSSProperties;
  className?: string;
  onClick: (...args: any[]) => void;
  size?: 'xs' | 'l' | 'xl';
  hint?: string;
  disabled?: boolean;
}

const RedCrossButton = ({ hint, className, style, onClick, size = 'xl', disabled }: IProps) => {
  return (
    <button
      disabled={disabled}
      title={hint}
      type={BUTTON_BUTTON}
      style={style}
      className={cn(styles.container, className, styles[size])}
      onClick={onClick}
    />
  );
};

export default RedCrossButton;
