import cn from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  FULFILLMENT_CENTER_ID,
  MSKU_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING,
  SKU_OPTIONS,
} from '../../../constants/inventories';
import { selectInventoryItemById } from '../../../store/selectors/inventories';
import { IInventoryItem } from '../../../types/inventories';
import { IInventoryRouteParams } from '../../../types/routing';
import { getCampaignFCID } from '../../../utils/campaigns';
import { isBrokenMSKU } from '../../../utils/inventories';
import { HiddenInput, WarningMessage } from '../../index';
import EditMskuItemsForm from '../EditMskuItemsForm/EditMskuItemsForm';
import Common, { IChildRenderProps } from './Common';
import { IRenderInventoryProps } from './InventoryItemForm';

import styles from './InventoryItemForm.module.scss';

const Msku = (props: IRenderInventoryProps) => {
  const { form, requiredFields, readOnly, onChange, isAdding } = props;

  const { itemId } = useParams<IInventoryRouteParams>();
  const originalItem = useSelector(selectInventoryItemById(itemId));

  const selectedOptions = React.useMemo(() => form.values[SKU_OPTIONS], [form.values[SKU_OPTIONS]]);

  const mskuItemFulfillmentCenterID = React.useMemo(() => {
    return getCampaignFCID(selectedOptions || []);
  }, [selectedOptions]);

  const isDifferentFC = React.useMemo(() => {
    return (
      !isAdding &&
      originalItem &&
      (isBrokenMSKU(form.values as IInventoryItem) ||
        form.values?.[FULFILLMENT_CENTER_ID] !== originalItem?.fulfillment_center_id)
    );
  }, [form.values?.[FULFILLMENT_CENTER_ID], originalItem, isAdding]);

  React.useEffect(() => {
    if (mskuItemFulfillmentCenterID && mskuItemFulfillmentCenterID !== form.values?.[FULFILLMENT_CENTER_ID]) {
      onChange(FULFILLMENT_CENTER_ID, mskuItemFulfillmentCenterID);
    }
  }, [mskuItemFulfillmentCenterID, form.values?.[FULFILLMENT_CENTER_ID], onChange]);

  //#region Components to render

  const mskuItemsForm = useMemo(
    () => (
      <EditMskuItemsForm
        isDifferentFC={Boolean(isDifferentFC)}
        readOnly={readOnly}
        values={form.values[SKU_OPTIONS]}
        onChange={(skuOptions: IInventoryItem[]) => onChange(SKU_OPTIONS, skuOptions)}
        isAdding={isAdding}
        required={requiredFields[SKU_OPTIONS]}
        parentItemFulfillmentCenterID={mskuItemFulfillmentCenterID || ''}
      />
    ),
    [readOnly, form.values[SKU_OPTIONS], onChange, isAdding, requiredFields[SKU_OPTIONS], mskuItemFulfillmentCenterID],
  );

  const hiddenFCInput = useMemo(
    () => <HiddenInput name={FULFILLMENT_CENTER_ID} value={form.values[FULFILLMENT_CENTER_ID] || ''} />,
    [form.values[FULFILLMENT_CENTER_ID]],
  );

  //#endregion

  return (
    <Common {...props}>
      {({
        nameInput,
        descriptionTextArea,
        imageInput,
        departmentsInput,
        hiddenOrgIdInput,
        hiddenTypeInput,
      }: IChildRenderProps) => (
        <form onSubmit={form.handleSubmit} className={styles.inventoryItemForm}>
          <div className={styles.baseInfo}>
            {imageInput}
            <div className={styles.info}>
              <div className={cn(styles.infoRow, styles.headerInputRow)}>
                {departmentsInput}
                {nameInput}
                {isDifferentFC && <WarningMessage mode="error" message={MSKU_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING} />}
              </div>
            </div>
          </div>
          {descriptionTextArea}
          {hiddenOrgIdInput}
          {hiddenTypeInput}
          {hiddenFCInput}
          {mskuItemsForm}
        </form>
      )}
    </Common>
  );
};

export default Msku;
