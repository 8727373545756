import { IReportStatusMapper, ReportTypesEnum } from '../types/reports';
import { FilterTypesEnum } from '../types/shell';
import { DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from './users';

export const MAX_REPORTS_PER_PAGE = 10;

export const DATE_TO_SEND = 'date_to_send';
export const CREATED_AT = 'created_at';
export const ORDER_ID = 'order_id';
export const UID = 'uid';
export const ENGAGEMENT_SOURCE_TYPE = 'engagement_source_type';

export const PROCESSING = {
  label: 'Processing',
  color: '#AAAAAA',
};

export const AWAITING_SHIPMENT = 'awaiting_shipment';
export const AWAITING_ADDRESS = 'awaiting_address';
export const VIEWED = 'viewed';
export const CANCELED = 'canceled';
export const CANCELED_MANUALLY = 'canceled_manually';
export const NEW = 'New';
export const SHIPPED = 'shipped';
export const IN_FULFILLMENT = 'in_fulfillment';
export const DELIVERED = 'delivered';

export const PAYMENT_PENDING = 'Payment Pending';
export const PROCESSING_ORDER = 'Processing Order';
export const GIFT_CLAIMED = 'Gift Claimed';
export const GIFT_EXPIRED = 'Gift Expired';
export const CANCELLED = 'Cancelled';
export const EMAIL_SENT = 'Email Sent';
export const EMAIL_DELIVERED = 'Email Delivered';
export const EMAIL_REJECTED = 'Email Rejected;';
export const EMAIL_BOUNCED = 'Email Bounced';
export const EMAIL_QUEUED = 'Email Queued';
export const EMAIL_OPENED = 'Email Opened';
export const EMAIL_BLOCKED = 'Email Blocked';

// TODO: should colors from _variables.scss be added here?
export const PHYSICAL_STATUS: IReportStatusMapper = {
  [AWAITING_SHIPMENT]: {
    label: 'In Production',
    color: '#AEC3FC',
  },
  [AWAITING_ADDRESS]: {
    label: 'Awaiting Address',
    color: '#FAA950',
  },
  [VIEWED]: {
    label: 'Viewed',
    color: '#0461EC',
  },
  [CANCELED]: {
    label: 'Canceled',
    color: '#FFD000',
  },
  [SHIPPED]: {
    label: 'In Transit',
    color: '#00C8FF',
  },
  [IN_FULFILLMENT]: {
    label: 'In Fulfillment',
    color: '#00C8FF',
  },
  [DELIVERED]: {
    label: 'Delivered',
    color: '#00DBA8',
  },
  [CANCELED_MANUALLY]: {
    label: 'Canceled',
    color: '#9197A5',
  },
};

// TODO: should colors from _variables.scss be added here?
export const DIGITAL_STATUS: IReportStatusMapper = {
  [PAYMENT_PENDING]: {
    label: PAYMENT_PENDING,
    color: '#FFD100',
  },
  [PROCESSING_ORDER]: {
    label: PROCESSING_ORDER,
    color: '#00AEFF',
  },
  [GIFT_CLAIMED]: {
    label: GIFT_CLAIMED,
    color: '#4BD7AB',
  },
  [GIFT_EXPIRED]: {
    label: GIFT_EXPIRED,
    color: '#FFD099',
  },
  [CANCELLED]: {
    label: CANCELLED,
    color: '#FFD100',
  },
  [CANCELED_MANUALLY]: {
    label: 'Canceled',
    color: '#9197A5',
  },
  [EMAIL_SENT]: {
    label: EMAIL_SENT,
    color: '#00AEFF',
  },
  [EMAIL_DELIVERED]: {
    label: EMAIL_DELIVERED,
    color: '#FFD095',
  },
  [NEW]: {
    label: NEW,
    color: '#00AEFF',
  },
  [EMAIL_REJECTED]: {
    label: EMAIL_REJECTED,
    color: '#FFD096',
  },
  [EMAIL_BOUNCED]: {
    label: EMAIL_BOUNCED,
    color: '#FFD097',
  },
  [EMAIL_QUEUED]: {
    label: EMAIL_QUEUED,
    color: '#FFD098',
  },
  [EMAIL_OPENED]: {
    label: EMAIL_OPENED,
    color: '#FFD099',
  },
  [EMAIL_BLOCKED]: {
    label: EMAIL_BLOCKED,
    color: '#FFD100',
  },
};

export const REPORT_FILTERS = [
  {
    label: 'Organization',
    filterId: FilterTypesEnum.Organization,
    role: [ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN],
  },
  {
    label: 'Department',
    filterId: FilterTypesEnum.Department,
    role: [DEPT_ADMIN],
  },
  {
    label: 'Individual',
    filterId: FilterTypesEnum.Individual,
  },
];

export const REPORT_TABS = [
  {
    label: 'Regular',
    tabId: ReportTypesEnum.Regular,
  },
  {
    label: 'Scheduled',
    tabId: ReportTypesEnum.Scheduled,
  },
];
