import cn from 'classnames';
import { FormikErrors, FormikHelpers, FormikTouched, useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnChangeValue } from 'react-select';

import {
  ANNIVERSARY_YEAR,
  ANNIVERSARY_YEAR_TOOLTIP_TEXT,
  AUTOMATED_CAMPAIGNS_INITIAL_VALUES,
  AUTOMATED_CAMPAIGNS_VALIDATION_SCHEMA as validationSchema,
  AUTOMATED_CAMPAIGN_DELETE_TEXT,
  AUTOMATION_FORM_FIELDS_MAP,
  CAMPAIGN_DEACTIVATE_TOOLTIP_TEXT,
  CAMPAIGN_IDS,
  CAMPAIGN_TYPES_SELECT_OPTIONS,
  CONNECTION_ID,
  CONNECTION_TOOLTIP_TEXT,
  NAME,
  SENDER_ID,
  SENDER_TOOLTIP_TEXT,
  STATUS,
  TYPE,
} from '../../../constants/automations';
import { URL_VARS } from '../../../constants/routing';
import useWindowSize from '../../../hooks/useWindowSize';
import {
  addAutomatedCampaignRequest,
  deleteAutomatedCampaignRequest,
  updateAutomatedCampaignRequest,
} from '../../../store/actions/automations';
import { selectAutomatedCampaignById, selectAutomatedCampaigns } from '../../../store/selectors/automations';
import {
  AutomatedCampaignTypeEnum,
  AutomationStatusEnum,
  IAddAutomatedCampaignActionPayload,
  IAutomatedCampaign,
  IUpdateAutomatedCampaignActionPayload,
} from '../../../types/automations';
import { SUBMIT_BUTTON } from '../../../types/forms';
import { ISelectorValue, NotificationListEnum } from '../../../types/shell';
import { getAutomationStatusLabel } from '../../../utils/automations';
import { getRequiredFields } from '../../../utils/form';
import notification from '../../../utils/notification';
import { handleApiError } from '../../../utils/ui';
import {
  ActionButton,
  CampaignsTable,
  DeleteAllWarning,
  HelpTooltip,
  Input,
  InputLabel,
  Loader,
  Selector,
  TextIconButton,
  Toggle,
  UserOption,
} from '../../index';

import { fetchUsersRequest } from '../../../store/actions/users';
import { selectCurrentOrganizationAutomatedConnections } from '../../../store/selectors/organizations';
import { selectUsers } from '../../../store/selectors/users';

import styles from './AutomatedCampaignsForm.module.scss';

interface IAutomatedCampaignsFormProps {
  id: string;
  onClose?: () => void;
}

const AutomatedCampaignsForm: React.FC<IAutomatedCampaignsFormProps> = ({ id, onClose }) => {
  const [isDeletePanelOpen, setIsDeletePanelOpen] = React.useState(false);

  const formRef = React.useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const { mobile } = useWindowSize();

  const senders = useSelector(selectUsers);
  const automatedCampaigns = useSelector(selectAutomatedCampaigns);
  const campaign = useSelector(selectAutomatedCampaignById(id));
  const connections = useSelector(selectCurrentOrganizationAutomatedConnections);

  const isNew = id === URL_VARS.NEW;

  const AutomatedCampaignSuccessToastMessages = useCallback((isNewCampaign: boolean) => {
    return isNewCampaign ? 'New automated campaign was added' : 'Automated campaign was edited';
  }, []);

  const connectionsOptions = React.useMemo(() => {
    if (!connections || !connections.length) {
      return [];
    }

    return connections?.map(({ name, uid }) => {
      return {
        value: uid,
        label: name,
      };
    });
  }, [connections]);

  const senderOptions = React.useMemo(() => {
    if (!senders || !senders.length) {
      return [];
    }

    return senders?.map((user) => {
      const { uid, email } = user;

      return {
        value: uid,
        label: email,
        user,
      };
    });
  }, [senders]);

  const status = useMemo(() => {
    if (!campaign) {
      return null;
    }

    const { message, color } = getAutomationStatusLabel(campaign.status, 'Campaign');
    return (
      <div className={styles.status}>
        <InputLabel className={styles.statusLabel} value="Status" />
        <div
          style={{
            backgroundColor: color,
          }}
          className={styles.bullet}
        />
        {message}
      </div>
    );
  }, [campaign]);

  const renderTooltipText = useCallback(
    ({ text, fieldName }: { text: string; fieldName: string }) => {
      const label = AUTOMATION_FORM_FIELDS_MAP[fieldName];
      return (
        <>
          {label}
          <HelpTooltip
            id={`${fieldName}-info`}
            className={styles.tooltip}
            offset={{ right: mobile ? 0 : 10 }}
            data-border
            data-tip
          >
            {text}
          </HelpTooltip>
        </>
      );
    },
    [mobile],
  );

  const dispatchSubmit = React.useCallback(
    (payload: IAddAutomatedCampaignActionPayload | IUpdateAutomatedCampaignActionPayload) => {
      switch (isNew) {
        case true:
          return dispatch(addAutomatedCampaignRequest(payload as IAddAutomatedCampaignActionPayload));
        case false:
          return dispatch(updateAutomatedCampaignRequest(payload as IUpdateAutomatedCampaignActionPayload));
        default:
          return null;
      }
    },
    [dispatch, isNew],
  );

  const handleSubmit = React.useCallback(
    (automatedCampaign: Partial<IAutomatedCampaign>, { setSubmitting }: FormikHelpers<Partial<IAutomatedCampaign>>) => {
      return new Promise((resolve, reject) => {
        const { anniversary_year, failure_reason, org_id, ...rest } = automatedCampaign;

        const anniversaryYearNumber = anniversary_year ? +anniversary_year : undefined;

        const candidate = {
          ...rest,
          ...(automatedCampaign.type === AutomatedCampaignTypeEnum.Anniversary
            ? { anniversary_year: anniversaryYearNumber }
            : {}),
        };

        dispatchSubmit({ automatedCampaign: candidate, resolve, reject });
      })
        .then(() => {
          notification.success(NotificationListEnum.Success, {
            content: AutomatedCampaignSuccessToastMessages(isNew),
          });
        })
        .catch(handleApiError('Something bad happened. Please, try again'))
        .finally(() => {
          setSubmitting(false);
          onClose?.();
        });
    },
    [dispatchSubmit, isNew, AutomatedCampaignSuccessToastMessages, onClose],
  );

  const handleDeleteAutomatedCampaign = React.useCallback(() => {
    return new Promise((resolve, reject) => {
      if (id) {
        dispatch(deleteAutomatedCampaignRequest({ id, resolve, reject }));
      }
    })
      .then(() => {
        notification.success(NotificationListEnum.Success, {
          content: 'Automated campaign was deleted',
        });
      })
      .catch(handleApiError(`Something bad happened. Campaign wasn't deleted.`))
      .finally(() => {
        setIsDeletePanelOpen(false);
        onClose?.();
      });
  }, [dispatch, id, onClose]);

  const form = useFormik({
    initialValues: campaign ? campaign : AUTOMATED_CAMPAIGNS_INITIAL_VALUES,
    validateOnMount: true,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const title = useMemo(() => {
    return isNew ? 'Create Automated Campaign' : 'Edit Automated Campaign';
  }, [isNew]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { name, value } = e.currentTarget;

      if (!name) {
        console.warn('No field name was specified');
        return;
      }

      form.setFieldValue(name, value);
    },
    [form.setFieldValue],
  );

  const handleSelectInputChange = React.useCallback(
    (name: keyof IAutomatedCampaign, selected?: OnChangeValue<ISelectorValue, boolean> | null) => {
      const { value } = (selected as ISelectorValue) || {};
      return form.setFieldValue(name, value);
    },
    [form.setFieldValue],
  );

  const getFieldErrors = React.useCallback(
    (fieldName: keyof IAutomatedCampaign) => {
      const touched: FormikTouched<IAutomatedCampaign> = form.touched;
      const errors: FormikErrors<IAutomatedCampaign> = form.errors;

      return touched[fieldName] ? (errors[fieldName] as string | string[]) : undefined;
    },
    [form.touched, form.errors],
  );

  const handleCancelDelete = React.useCallback(() => {
    setIsDeletePanelOpen(false);
  }, []);

  const requiredFields = React.useMemo(() => getRequiredFields(validationSchema), [automatedCampaigns]);

  const handleToggleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(
        e.target.name,
        e.target.checked ? AutomationStatusEnum.NotActive : AutomationStatusEnum.Active,
      );
    },
    [form.setFieldValue],
  );

  const handleOpenDeletePanel = useCallback(() => {
    setIsDeletePanelOpen(true);
  }, []);

  useEffect(() => {
    if (!senders || !senders.length) {
      dispatch(fetchUsersRequest());
    }
  }, [senders, dispatch]);

  const handleCampaignsChange = useCallback(
    (values: string[]) => {
      form.setFieldValue(CAMPAIGN_IDS, values);
    },
    [form],
  );

  React.useEffect(() => {
    if ((isDeletePanelOpen || form.isSubmitting) && formRef.current && formRef.current.scrollTop !== 0) {
      formRef.current.scrollTo({ top: 0 });
    }
  }, [isDeletePanelOpen, form.isSubmitting]);

  return (
    <>
      {isDeletePanelOpen && (
        <DeleteAllWarning
          submitLabel="Delete campaign"
          onCancel={handleCancelDelete}
          message={AUTOMATED_CAMPAIGN_DELETE_TEXT.map((p, i) => (
            <p key={i}>{p}</p>
          ))}
          onSubmit={handleDeleteAutomatedCampaign}
        />
      )}
      <div
        className={cn(styles.formWrapper, isDeletePanelOpen && styles.noScroll, form.isSubmitting && styles.noScroll)}
        ref={formRef}
      >
        {form.isSubmitting && <Loader isLoading={form.isSubmitting} />}
        <h1 className={styles.title}>{title}</h1>
        {!isNew && status}
        <form onSubmit={form.handleSubmit} className={styles.form}>
          <div className={styles.inputsWrapper}>
            <div className={styles.row}>
              <Input
                field={form.getFieldProps(NAME)}
                name={NAME}
                placeholder="Name"
                helperText="Name"
                inputClassName={styles.input}
                required={requiredFields[NAME]}
                value={form.values[NAME]}
                onChange={handleChange}
                error={getFieldErrors(NAME)}
                errorClassName={styles.errorMessage}
              />
              <Toggle
                checked={form.values[STATUS] !== AutomationStatusEnum.Active}
                hint={CAMPAIGN_DEACTIVATE_TOOLTIP_TEXT}
                name={STATUS}
                helperText="Temporarily deactivate"
                onChange={handleToggleChange}
                switchClassName={styles.switch}
                disabled={isNew}
              />
            </div>
            <div className={styles.row}>
              <Selector
                required={requiredFields[TYPE]}
                containerClassName={cn(styles.select, {
                  [styles.error]: form.errors[TYPE] && form.touched[TYPE],
                })}
                helperText="Type"
                placeholder="Select Type"
                name={TYPE}
                onChange={(value) => handleSelectInputChange(TYPE, value)}
                error={form.touched[TYPE] ? form.errors[TYPE] : undefined}
                options={CAMPAIGN_TYPES_SELECT_OPTIONS}
                isClearable
                closeMenuOnSelect
                value={CAMPAIGN_TYPES_SELECT_OPTIONS.find((option) => option.value === form.values[TYPE]) || null}
              />
              <Input
                field={form.getFieldProps(ANNIVERSARY_YEAR)}
                type="number"
                min={1}
                decimalScale={0}
                name={ANNIVERSARY_YEAR}
                inputClassName={cn(styles.input, styles.number)}
                onChange={handleChange}
                value={form.values[ANNIVERSARY_YEAR]}
                disabled={form.values[TYPE] !== AutomatedCampaignTypeEnum.Anniversary || !isNew}
                helperText={renderTooltipText({ text: ANNIVERSARY_YEAR_TOOLTIP_TEXT, fieldName: ANNIVERSARY_YEAR })}
                errorClassName={styles.errorMessage}
              />
            </div>
            <div className={cn(styles.row)}>
              <Selector
                required={requiredFields[CONNECTION_ID]}
                containerClassName={cn(styles.select, {
                  [styles.error]: form.errors[CONNECTION_ID] && form.touched[CONNECTION_ID],
                })}
                helperText={renderTooltipText({ text: CONNECTION_TOOLTIP_TEXT, fieldName: CONNECTION_ID })}
                placeholder="Select Connection"
                name={CONNECTION_ID}
                onChange={(value) => handleSelectInputChange(CONNECTION_ID, value)}
                onBlur={() => {
                  form.setFieldTouched(CONNECTION_ID);
                }}
                error={form.touched[CONNECTION_ID] ? form.errors[CONNECTION_ID] : undefined}
                options={connectionsOptions}
                isMulti={false}
                isSearchable={true}
                isClearable={true}
                closeMenuOnSelect
                value={connectionsOptions?.find((option) => option.value === form.values[CONNECTION_ID]) || null}
              />
            </div>
            <div className={cn(styles.row)}>
              <Selector
                required={requiredFields[SENDER_ID]}
                containerClassName={cn(styles.select, {
                  [styles.error]: form.errors[SENDER_ID] && form.touched[SENDER_ID],
                })}
                helperText={renderTooltipText({ text: SENDER_TOOLTIP_TEXT, fieldName: SENDER_ID })}
                placeholder="Select Sender"
                name={SENDER_ID}
                onChange={(value) => handleSelectInputChange(SENDER_ID, value)}
                onBlur={() => {
                  form.setFieldTouched(SENDER_ID);
                }}
                error={form.touched[SENDER_ID] ? form.errors[SENDER_ID] : undefined}
                options={senderOptions}
                customOption={UserOption}
                isMulti={false}
                isSearchable={true}
                isClearable={true}
                closeMenuOnSelect
                value={senderOptions?.find((option) => option.value === form.values[SENDER_ID]) || null}
              />
            </div>
          </div>
        </form>
        <CampaignsTable
          onChange={handleCampaignsChange}
          value={form.values[CAMPAIGN_IDS] ?? []}
          error={form.touched[CAMPAIGN_IDS] ? form.errors[CAMPAIGN_IDS] : undefined}
        />
        <div className={styles.controlsWrapper}>
          <TextIconButton
            title="Delete"
            disabled={isNew}
            className={styles.deleteButton}
            onClick={handleOpenDeletePanel}
          />
          <div className={styles.controlsGroup}>
            <TextIconButton title="Cancel" className={styles.cancelButton} onClick={onClose} />
            <ActionButton
              type={SUBMIT_BUTTON}
              title="Save"
              disabled={form.isSubmitting}
              className={styles.saveButton}
              onClick={form.submitForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AutomatedCampaignsForm;
