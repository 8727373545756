import cn from 'classnames';
import * as React from 'react';

import Toggle, { IProps as IToggleProps } from '../Toggle/Toggle';

import styles from './TextIconToggle.module.scss';

interface IProps extends IToggleProps {
  textClassName?: string;
  toggleClassName?: string;
  active?: boolean;
  onClick?: (e: React.MouseEvent<HTMLInputElement | HTMLButtonElement>) => void;
}

const TextIconToggle: React.FC<IProps> = ({
  title,
  disabled,
  textClassName,
  toggleClassName,
  trigger,
  type,
  onClick,
  active,
  ...toggleProps
}: IProps) => {
  return (
    <React.Fragment>
      {!trigger ? (
        <button disabled={disabled} type={type} className={textClassName} onClick={onClick}>
          {title}
        </button>
      ) : (
        <Toggle
          {...toggleProps}
          className={cn(styles.toggle, active ? styles.activate : styles.deactivate)}
          sliderClassName={styles.slider}
          switchClassName={styles.switch}
          checked={active}
          onClick={onClick}
          compact
        />
      )}
    </React.Fragment>
  );
};

export default TextIconToggle;
