import cn from 'classnames';
import * as React from 'react';

import { IAccordionItemProps } from './AccordionItem/AccordionItem';

import styles from './Accordion.module.scss';

interface IProps {
  activeItemId?: string | null;
  className?: string;
  children?: React.ReactNode;
}

const Accordion: React.FC<IProps> = ({ activeItemId, className, children }) => {
  const [activeItem, setActiveItem] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!activeItemId) {
      return;
    }

    setActiveItem(activeItemId);
  }, [activeItemId]);

  return (
    <div className={cn(styles.container, className)}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        const { id: itemId, onClick: onItemClick } = child.props;
        return React.cloneElement<IAccordionItemProps>(child, {
          isActive: itemId === activeItem,
          onClick: (id: string) => {
            setActiveItem((prev) => (prev === id ? null : id));
            onItemClick?.(id);
          },
        });
      })}
    </div>
  );
};

export default Accordion;
