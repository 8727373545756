import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { routes } from '../../constants/routing';
import { selectIsAuthenticated } from '../../store/selectors/auth';

// A wrapper for <Route> that redirects guests to the login or users to the dashboard
export default () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  return (
    <Route
      path="*"
      render={() =>
        isAuthenticated ? (
          <Redirect to={{ pathname: routes.dashboard }} />
        ) : (
          <Redirect to={{ pathname: routes.login }} />
        )
      }
    />
  );
};
