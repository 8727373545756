import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AutomatedCampaignsForm, AutomationSidebar } from '../../components';

import { CAMPAIGN_ID, NEW_ID } from '../../constants/automations';
import { routes } from '../../constants/routing';
import { AutomatedCampaignsContainer } from '../../containers';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import { selectCurrentOrganizationAutomatedConnections } from '../../store/selectors/organizations';
import { TAutomationFormCandidateId } from '../../types/automations';

import styles from './Automations.module.scss';

const Automations: React.FC = () => {
  const [candidate, setCandidate] = React.useState<TAutomationFormCandidateId | undefined>();

  const { setHeader } = useHeaderDispatch();
  const history = useHistory();
  const connections = useSelector(selectCurrentOrganizationAutomatedConnections);

  const handleAddClick = React.useCallback(
    (key: keyof TAutomationFormCandidateId) => () => {
      setCandidate({ [key]: NEW_ID } as TAutomationFormCandidateId);
    },
    [],
  );

  const handleEditClick = React.useCallback(
    (key: keyof TAutomationFormCandidateId) =>
      ({ uid }: { uid: string }) => {
        setCandidate({ [key]: uid } as TAutomationFormCandidateId);
      },
    [],
  );

  const handleClose = React.useCallback(() => {
    setCandidate(undefined);
  }, []);

  React.useEffect(() => {
    if (setHeader) {
      setHeader({ title: 'Automated Campaigns', action: () => history.push(routes.dashboard) });
    }
  }, [history, setHeader]);

  return (
    <div className={styles.container}>
      <AutomatedCampaignsContainer
        onAdd={handleAddClick(CAMPAIGN_ID)}
        onEdit={handleEditClick(CAMPAIGN_ID)}
        disabled={connections.length === 0}
      />
      <AutomationSidebar onClose={handleClose} trigger={!!candidate}>
        {candidate?.[CAMPAIGN_ID] && <AutomatedCampaignsForm id={candidate[CAMPAIGN_ID]} onClose={handleClose} />}
      </AutomationSidebar>
    </div>
  );
};

export default Automations;
