import cn from 'classnames';
import { FormikErrors, FormikHelpers, FormikTouched, useFormik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnChangeValue } from 'react-select';

import { DeleteAllWarning, HelpTooltip, InputLabel, Loader } from '../../../components';
import { ActionButton, TextIconButton } from '../buttons';
import { Input, SecretInput, Selector, Toggle } from '../inputs';

import {
  API_KEY_TOOLTIP_TEXT,
  AUTOMATED_CONNECTION_DELETE_TEXT,
  AUTOMATED_CONNECTION_INITIAL_VALUES,
  AUTOMATED_CONNECTION_VALIDATION_SCHEMA as validationSchema,
  CONNECTION_DEACTIVATE_TOOLTIP_TEXT,
  CONNECTION_TYPES_SELECT_OPTIONS,
  NAME,
  NEW_ID,
  STATUS,
  TOKEN,
  TYPE,
} from '../../../constants/automations';
import useWindowSize from '../../../hooks/useWindowSize';
import {
  addAutomatedConnectionRequest,
  deleteAutomatedConnectionRequest,
  updateAutomatedConnectionRequest,
} from '../../../store/actions/automations';
import { selectAutomatedConnectionById } from '../../../store/selectors/automations';
import {
  AutomationStatusEnum,
  IAddAutomatedConnectionActionPayload,
  IAutomatedConnection,
  IUpdateAutomatedConnectionRequestActionPayload,
} from '../../../types/automations';
import { FormStatusEnum, SUBMIT_BUTTON } from '../../../types/forms';
import { IApiError, ISelectorValue, NotificationListEnum } from '../../../types/shell';
import { getAutomationStatusLabel } from '../../../utils/automations';
import { getRequiredFields } from '../../../utils/form';
import notification from '../../../utils/notification';
import { handleApiError } from '../../../utils/ui';

import styles from './AutomatedConnectionsForm.module.scss';

interface IAutomatedConnectionsFormProps {
  id: string;
  orgId: string;
  onClose?: () => void;
}
const AutomatedConnectionsForm: React.FC<IAutomatedConnectionsFormProps> = ({ id, orgId, onClose }) => {
  const [isDeletePanelOpen, setIsDeletePanelOpen] = React.useState(false);
  const { mobile } = useWindowSize();
  const dispatch = useDispatch();
  const connection = useSelector(selectAutomatedConnectionById(orgId, id));

  const isAddMode = id === NEW_ID;

  const AutomatedConnectionSuccessToastMessages = (isNewConnection: boolean) => {
    return isNewConnection ? 'New Automated Connection was added' : 'Automated Connection was edited';
  };

  const status = React.useMemo(() => {
    if (!connection) {
      return null;
    }

    const { message, color } = getAutomationStatusLabel(connection.status, 'Connection');
    return (
      <div className={styles.status}>
        <InputLabel className={styles.statusLabel} value="Status" />
        <div
          style={{
            backgroundColor: color,
          }}
          className={styles.bullet}
        />
        {message}
      </div>
    );
  }, [connection]);

  const dispatchSubmit = React.useCallback(
    (payload: IAddAutomatedConnectionActionPayload | IUpdateAutomatedConnectionRequestActionPayload) => {
      switch (isAddMode) {
        case true:
          return dispatch(addAutomatedConnectionRequest(payload as IAddAutomatedConnectionActionPayload));
        case false:
          return dispatch(updateAutomatedConnectionRequest(payload as IUpdateAutomatedConnectionRequestActionPayload));
        default:
          return null;
      }
    },
    [dispatch, isAddMode],
  );

  const handleSubmit = React.useCallback(
    async (values: Partial<IAutomatedConnection>, { setSubmitting }: FormikHelpers<Partial<IAutomatedConnection>>) => {
      await new Promise((resolve, reject) => {
        const { failure_reason, ...automatedConnection } = values;

        dispatchSubmit({ automatedConnection, orgId, resolve, reject });
      })
        .then(() => {
          onClose?.();
          notification.success(NotificationListEnum.Success, {
            content: AutomatedConnectionSuccessToastMessages(isAddMode),
          });
        })
        .catch(handleApiError("Something bad happened. Connection wasn't saved."))
        .finally(() => {
          setSubmitting(false);
        });
    },
    [onClose, dispatchSubmit, isAddMode, orgId],
  );

  const form = useFormik({
    initialStatus: FormStatusEnum.Start,
    initialValues: connection ? connection : AUTOMATED_CONNECTION_INITIAL_VALUES,
    validateOnMount: true,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const title = useMemo(() => {
    return isAddMode ? 'Add Connection' : 'Edit Connection';
  }, [isAddMode]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { name, value } = e.currentTarget;

      if (!name) {
        console.warn('No field name was specified');
        return;
      }

      form.setFieldValue(name, value);
    },
    [form.setFieldValue],
  );

  const handleSelectInputChange = React.useCallback(
    (name: keyof IAutomatedConnection, selected?: OnChangeValue<ISelectorValue, boolean> | null) => {
      const { value } = (selected as ISelectorValue) || {};
      return form.setFieldValue(name, value);
    },
    [form.setFieldValue],
  );

  const getFieldErrors = React.useCallback(
    (fieldName: keyof IAutomatedConnection) => {
      const touched: FormikTouched<IAutomatedConnection> = form.touched;
      const errors: FormikErrors<IAutomatedConnection> = form.errors;

      return touched[fieldName] ? (errors[fieldName] as string | string[]) : undefined;
    },
    [form.touched, form.errors],
  );

  const handleCancelDelete = React.useCallback(() => {
    setIsDeletePanelOpen(false);
  }, []);

  const requiredFields = React.useMemo(() => getRequiredFields(validationSchema), [validationSchema]);

  const handleToggleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(STATUS, e.target.checked ? AutomationStatusEnum.NotActive : AutomationStatusEnum.Active);
    },
    [form.setFieldValue],
  );

  const handleOpenDeletePanel = useCallback(() => {
    setIsDeletePanelOpen(true);
  }, [setIsDeletePanelOpen]);

  const handleDeleteAutomatedConnection = React.useCallback(() => {
    return new Promise((resolve, reject) => {
      if (id) {
        form.setSubmitting(true);
        dispatch(deleteAutomatedConnectionRequest({ id, orgId, resolve, reject }));
      }
    })
      .then(() =>
        notification.success(NotificationListEnum.Success, {
          content: 'Connection was deleted',
        }),
      )
      .then(() => {
        onClose?.();
      })
      .catch((error: unknown) => {
        form.setSubmitting(false);
        setIsDeletePanelOpen(false);
        handleApiError(`Something bad happened. Connection wasn't deleted.`)(error as IApiError);
      });
  }, [dispatch, id, onClose, form.setSubmitting, orgId]);

  return (
    <>
      {isDeletePanelOpen && (
        <DeleteAllWarning
          submitLabel="Delete connection"
          onCancel={handleCancelDelete}
          onSubmit={handleDeleteAutomatedConnection}
          message={AUTOMATED_CONNECTION_DELETE_TEXT.map((p: string, i) => (
            <p key={i}>{p}</p>
          ))}
        />
      )}
      <div className={styles.formWrapper}>
        {form.isSubmitting && <Loader isLoading={form.isSubmitting} />}

        <h1 className={styles.title}>{title}</h1>
        {!isAddMode && status}
        <form onSubmit={form.handleSubmit} className={styles.form}>
          <div className={styles.inputsWrapper}>
            <div className={styles.row}>
              <Input
                field={form.getFieldProps(NAME)}
                name={NAME}
                placeholder="Name"
                helperText="Name"
                inputClassName={styles.input}
                required={requiredFields[NAME]}
                value={form.values[NAME]}
                onChange={handleChange}
                error={getFieldErrors(NAME)}
              />
              <Toggle
                checked={form.values[STATUS] !== AutomationStatusEnum.Active}
                hint={CONNECTION_DEACTIVATE_TOOLTIP_TEXT}
                helperText="Temporarily Deactivate"
                name={STATUS}
                onChange={handleToggleChange}
                switchClassName={styles.switch}
                disabled={isAddMode}
              />
            </div>
            <div className={styles.row}>
              <Selector
                required={requiredFields[TYPE]}
                containerClassName={cn(styles.select, {
                  [styles.error]: form.errors[TYPE] && form.touched[TYPE],
                })}
                helperText="Type"
                placeholder="Select Type"
                name={TYPE}
                onChange={(value) => handleSelectInputChange(TYPE, value)}
                error={getFieldErrors(TYPE)}
                options={CONNECTION_TYPES_SELECT_OPTIONS}
                isSearchable={false}
                isClearable
                closeMenuOnSelect
                value={CONNECTION_TYPES_SELECT_OPTIONS.find((option) => option.value === form.values[TYPE]) || null}
              />
            </div>
            <div className={styles.row}>
              <SecretInput
                field={form.getFieldProps(TOKEN)}
                name={TOKEN}
                helperText={
                  <>
                    API Key
                    {isAddMode && (
                      <HelpTooltip
                        id="api-key-info"
                        className={styles.tooltip}
                        offset={{ right: mobile ? 0 : 10 }}
                        data-border
                        data-tip
                      >
                        {API_KEY_TOOLTIP_TEXT}
                      </HelpTooltip>
                    )}
                  </>
                }
                inputClassName={styles.input}
                required={requiredFields[TOKEN]}
                value={form.values[TOKEN]}
                onChange={handleChange}
                error={getFieldErrors(TOKEN)}
              />
            </div>
          </div>
        </form>
        <div className={styles.controlsWrapper}>
          <TextIconButton
            title="Delete"
            disabled={isAddMode}
            className={styles.deleteButton}
            onClick={handleOpenDeletePanel}
          />
          <div className={styles.controlsGroup}>
            <TextIconButton title="Cancel" className={styles.cancelButton} onClick={onClose} />
            <ActionButton
              type={SUBMIT_BUTTON}
              title="Save"
              disabled={form.isSubmitting}
              className={styles.saveButton}
              onClick={form.submitForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AutomatedConnectionsForm;
