import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnChangeValue } from 'react-select';

import { ReactComponent as CloseIcon } from '../../../assets/images/icon-close-cross.svg';
import useWindowSize from '../../../hooks/useWindowSize';
import { changeOrganizationsRequest } from '../../../store/actions/auth';
import { selectCurrentOrganization, selectOrganizations } from '../../../store/selectors/organizations';
import { BUTTON_BUTTON } from '../../../types/forms';
import { IOrganizationItem } from '../../../types/organizations';
import { ISelectorValue } from '../../../types/shell';
import { handleApiError } from '../../../utils/ui';
import { ActionButton, Selector } from '../../forms';
import Loader from '../../Loader/Loader';
import ModalActions from '../ModalActions/ModalActions';
import ModalHeader from '../ModalHeader/ModalHeader';

import styles from './ChangeOrganizationModal.module.scss';

interface IProps {
  onClose: () => void;
}

const ChangeOrganizationModal: React.FC<IProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { mobile } = useWindowSize();

  const organizations = useSelector(selectOrganizations);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedOrganizationUid, setSelectedOrganizationUid] = React.useState(currentOrganization?.uid);

  const organizationsOptions = React.useMemo(() => {
    return organizations
      ? organizations.map((organization: IOrganizationItem) => {
          return { value: organization.uid, label: organization.name };
        })
      : [];
  }, [organizations]);

  const selectorValue = React.useMemo(() => {
    return organizationsOptions.find((orgOption) => orgOption.value === selectedOrganizationUid);
  }, [organizationsOptions, selectedOrganizationUid]);

  const handleOrganizationSelect = React.useCallback((orgOption: OnChangeValue<ISelectorValue, boolean>) => {
    const { value } = orgOption as ISelectorValue;
    setSelectedOrganizationUid(value);
  }, []);

  const handleContinueOnClick = React.useCallback(() => {
    if (selectedOrganizationUid) {
      return new Promise((resolve, reject) => {
        setIsLoading(true);
        dispatch(changeOrganizationsRequest({ org_id: selectedOrganizationUid, resolve, reject }));
      })
        .then(() => {
          onClose();
        })
        .catch(handleApiError(`Something bad happened. Organization wasn't deleted.`, () => setIsLoading(false)));
    }
  }, [dispatch, selectedOrganizationUid, onClose]);

  return (
    <div className={styles.container}>
      <Loader isLoading={isLoading} />
      <ModalHeader
        className={styles.header}
        right={
          <button type={BUTTON_BUTTON} onClick={onClose}>
            <CloseIcon className={styles.closeBtn} />
          </button>
        }
      >
        <h1>Change Organization</h1>
      </ModalHeader>
      <div className={styles.content}>
        <Selector
          autoFocus={!mobile}
          placeholder="Search organization"
          options={organizationsOptions}
          value={selectorValue}
          menuIsOpen
          onChange={handleOrganizationSelect}
          className={styles.select}
          isClearable={false}
        />
      </div>

      <ModalActions className={styles.footer}>
        <ActionButton
          title="Continue"
          className={styles.button}
          onClick={handleContinueOnClick}
          disabled={selectedOrganizationUid === currentOrganization?.uid}
        />
      </ModalActions>
    </div>
  );
};

export default ChangeOrganizationModal;
