import cn from 'classnames';
import * as React from 'react';
import { NumericFormat } from 'react-number-format';

import { ReactComponent as CreateIcon } from '../../../assets/images/icon-create.svg';
import { ReactComponent as InventoryIcon } from '../../../assets/images/icon-inventory.svg';

import { DIGITAL_WALLET_BALANCE_WARNING_RATE } from '../../../constants/dashboard';
import { useCurrency } from '../../../contexts/CurrencyProvider';
import { IOrganizationSummary } from '../../../types/dashboard';
import { StatusEnum } from '../../../types/shell';
import { Price } from '../../index';

import styles from './OrganizationSummaryTitle.module.scss';

interface IProps {
  summary: IOrganizationSummary;
}

const OrganizationSummaryTitle: React.FC<IProps> = ({ summary }) => {
  const { getRequiredPriceAndCurrency } = useCurrency();

  const {
    has_delayed_packages: hasDelayedPackages,
    has_low_number_of_items: hasLowNumberOfItems,
    org_name: orgName,
    number_of_sends: sends,
    amount_spent: amount,
    currency_code,
    digital_budget: { value: digitalBudgetValue, status: digitalBudgetStatus },
  } = React.useMemo(() => summary, [summary]);

  const getIconHintText = React.useCallback((flag: boolean, errorText: string) => {
    return flag ? errorText : 'No issues here';
  }, []);

  return (
    <div className={styles.title}>
      <span className={styles.name}>{orgName}</span>
      {digitalBudgetStatus === StatusEnum.Available ? (
        <Price
          value={digitalBudgetValue}
          thousandSeparator
          className={cn(styles.price, {
            [styles.lowPrice]: digitalBudgetValue.price < DIGITAL_WALLET_BALANCE_WARNING_RATE,
          })}
          hint={digitalBudgetValue.price < DIGITAL_WALLET_BALANCE_WARNING_RATE ? 'Low budget' : ''}
        />
      ) : (
        <span className={styles.price}>N/A</span>
      )}
      <span className={styles.sends}>
        <NumericFormat displayType="text" value={sends} thousandSeparator /> {`send${sends > 1 ? 's' : ''}`}
      </span>

      <Price
        value={getRequiredPriceAndCurrency({ price: amount, currency_code })}
        thousandSeparator
        className={styles.price}
        hint="Amount Spent"
      />
      <span className={styles.iconWrapper}>
        <InventoryIcon
          className={cn(styles.icon, { [styles.error]: hasLowNumberOfItems })}
          title={getIconHintText(hasLowNumberOfItems, 'Low number of items')}
        />
        <CreateIcon
          className={cn(styles.icon, { [styles.error]: hasDelayedPackages })}
          title={getIconHintText(hasDelayedPackages, 'Delayed packages')}
        />
      </span>
    </div>
  );
};

export default OrganizationSummaryTitle;
