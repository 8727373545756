import cn from 'classnames';
import React from 'react';

import styles from './BoxSidebarMessage.module.scss';

interface IProps {
  icon: React.ReactNode;
  text: string;
  className?: string;
}

const BoxSidebarMessage: React.FC<IProps> = ({ icon, text, className }) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.icon}>{icon}</div>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default BoxSidebarMessage;
