import cn from 'classnames';
import * as React from 'react';
import { FC } from 'react';
import { FALLBACK_CURRENCY_CODE } from '../../constants/shell';
import { useCurrency } from '../../contexts/CurrencyProvider';

import { Price } from '../index';

import styles from './Budget.module.scss';

interface IProps {
  className?: string;
  availableBudget: number;
  usedBudget: number;
  fixedDecimalScale?: boolean;
  remainingBudgetHint?: string;
  availableBudgetHint?: string;
  lowBudgetRate?: number;
  budgetCurrency?: string;
}

const Budget: FC<IProps> = ({
  className,
  availableBudget,
  usedBudget,
  fixedDecimalScale,
  remainingBudgetHint,
  availableBudgetHint,
  lowBudgetRate,
  budgetCurrency,
}) => {
  const { getRequiredPriceAndCurrency, displayCurrency } = useCurrency();

  const remainingBudget = availableBudget - usedBudget < 0 ? 0 : Math.floor(availableBudget - usedBudget);

  return (
    <div className={cn(styles.container, className)}>
      <Price
        value={
          displayCurrency
            ? getRequiredPriceAndCurrency({ price: remainingBudget, currency_code: budgetCurrency })
            : { price: remainingBudget, currency: budgetCurrency || FALLBACK_CURRENCY_CODE }
        }
        thousandSeparator
        fixedDecimalScale={fixedDecimalScale}
        className={cn(styles.budgetLeft, {
          [styles.budgetLow]: lowBudgetRate && remainingBudget <= lowBudgetRate,
          [styles.outOfBudget]: remainingBudget < 1,
        })}
        hint={remainingBudgetHint}
      />
      <span className={styles.budgetDelimiter}>/</span>
      <Price
        value={
          displayCurrency
            ? getRequiredPriceAndCurrency({ price: Math.floor(availableBudget), currency_code: budgetCurrency })
            : { price: availableBudget, currency: budgetCurrency || FALLBACK_CURRENCY_CODE }
        }
        thousandSeparator
        fixedDecimalScale={fixedDecimalScale}
        className={styles.budgetAvailable}
        hint={availableBudgetHint}
      />
    </div>
  );
};

export default Budget;
