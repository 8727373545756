import cn from 'classnames';
import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';

import { OrgAddressFilterTypesEnum } from '../../constants/addressBook';
import { IOrgAddress } from '../../types/addressBook';

import styles from './AddressBookTable.module.scss';

export interface ISidebarTableAddressItem {
  address: IOrgAddress;
  status: OrgAddressFilterTypesEnum;
}

interface IProps {
  data: ISidebarTableAddressItem[];
  columns: Column<ISidebarTableAddressItem>[];
  placeholderText: string;
  className?: string;
  containerClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
  onClick?: (address: IOrgAddress) => void;
  selectedIDs?: string[] | string;
}

const AddressBookTable = ({
  data,
  columns,
  placeholderText,
  className,
  containerClassName,
  rowClassName,
  bodyClassName,
  selectedIDs,
  onClick,
}: IProps) => {
  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({ columns, data });
  const tableBodyRef = React.useRef<HTMLDivElement>(null);

  const isSingleMode = useMemo(() => selectedIDs && !Array.isArray(selectedIDs), [selectedIDs]);

  const emptyPlaceholder = React.useMemo(
    () => (
      <div className={cn(styles.empty, className)}>
        <p>{placeholderText}</p>
      </div>
    ),
    [className, placeholderText],
  );

  return (
    <div {...getTableProps()} className={cn(styles.container, containerClassName)}>
      {data?.length === 0 ? (
        emptyPlaceholder
      ) : (
        <div {...getTableBodyProps()} className={cn(styles.body, bodyClassName)} ref={tableBodyRef}>
          {rows.map((row) => {
            prepareRow(row);
            const { key: rowKey, ...getRowProps } = row.getRowProps();
            const isRowDisabled = row.original.status === OrgAddressFilterTypesEnum.NotActive;
            const isRowSelected = selectedIDs?.includes(row.original.address.uid);
            const handleClick = onClick ? () => onClick(row.original.address) : undefined;

            return (
              <div
                className={cn(styles.row, rowClassName, {
                  [styles.disabledRow]: isRowDisabled,
                  [styles.selected]: isRowSelected && isSingleMode,
                })}
                key={rowKey}
                {...getRowProps}
                onClick={isRowDisabled ? undefined : handleClick}
              >
                {row.cells.map((cell) => {
                  const { key: cellKey, ...getCellProps } = cell.getCellProps();

                  const isActionColumn = cell.column.id === 'action';
                  const isCheckboxColumn = cell.column.id === 'selected';
                  const isStatusNonActive = cell.row.original.status === OrgAddressFilterTypesEnum.NotActive;
                  const shouldNotBeDisabled =
                    isActionColumn || (isCheckboxColumn && isStatusNonActive && isRowSelected);

                  return (
                    <div
                      className={cn(styles.cell, {
                        [styles.disabled]: isRowDisabled && !shouldNotBeDisabled,
                        [styles.actionCell]: isActionColumn,
                        [styles.checkboxCell]: isCheckboxColumn,
                      })}
                      key={cellKey}
                      {...getCellProps}
                    >
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AddressBookTable;
