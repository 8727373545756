import * as React from 'react';
import ReactDOM from 'react-dom';

import useDynamicRoot from './useDynamicRoot';

const useBluebirdPortal = () => {
  const [containerCreated, setContainerCreated] = React.useState(false);
  const root = useDynamicRoot();
  const el = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    if (root) {
      el.current = document.createElement('div');
      root.appendChild(el.current);
      setContainerCreated(true);
    }
    return () => {
      if (el.current) {
        root?.removeChild(el.current);
      }
    };
  }, []);

  const portal = React.useCallback(
    (content: React.ReactNode) => {
      return containerCreated && el.current ? ReactDOM.createPortal(content, el.current) : null;
    },
    [containerCreated],
  );

  return { mounted: containerCreated, element: el.current, portal };
};

export default useBluebirdPortal;
