import cn from 'classnames';
import * as React from 'react';

import styles from './Card.module.scss';

export interface ICardProps {
  label?: string;
  inline?: boolean;
  className?: string;
  labelClassName?: string;
  topClassName?: string;
  middleClassName?: string;
  bottomClassName?: string;
  top?: React.ReactNode | React.ReactNode[] | string;
  middle?: React.ReactNode | React.ReactNode[] | string;
  bottom?: React.ReactNode | React.ReactNode[] | string;
  onClick?: (...args: any[]) => void;
}

const Card = ({
  onClick,
  top,
  middle,
  bottom,
  className,
  topClassName,
  middleClassName,
  bottomClassName,
  inline = false,
  label,
  labelClassName,
}: ICardProps) => {
  return (
    <div
      className={cn(styles.container, className, { [styles.btn]: !!onClick, [styles.inline]: inline })}
      {...(typeof onClick === 'function'
        ? {
            role: 'button',
            tabIndex: -1,
            onClick,
            onKeyPress: onClick,
          }
        : {})}
    >
      {label && <span className={cn(styles.label, labelClassName)}>{label}</span>}
      {top && <div className={cn(styles.top, topClassName)}>{top}</div>}
      {middle && <div className={cn(styles.middle, middleClassName)}>{middle}</div>}
      {bottom && <div className={cn(styles.bottom, bottomClassName)}>{bottom}</div>}
    </div>
  );
};

export default Card;
