import { FormikValues } from 'formik';
import * as React from 'react';
import { OnChangeValue } from 'react-select';

import { GENDER_SELECT_OPTIONS_LIST } from '../../../../constants/inventories';
import { GenderEnum } from '../../../../types/inventories';
import { ISelectorValue } from '../../../../types/shell';
import { Selector } from '../index';

import styles from './GenderSelect.module.scss';

interface IProps<T> extends FormikValues {
  className?: string;
  name: keyof T;
  helperText: string;
  value?: string | null;
  readOnly?: boolean;
  onChange: (name: keyof T, value: string) => void;
  isClearable?: boolean;
}

const GenderSelect = <T extends object>({
  isMulti = false,
  name,
  className,
  value,
  onChange,
  readOnly = false,
  isClearable = true,
  helperText,
  ...rest
}: IProps<T>) => {
  const isSelectClearable = React.useMemo(() => {
    return isClearable && !!value;
  }, [isClearable, value]);

  const formattedValue = React.useMemo<ISelectorValue | undefined>(() => {
    return GENDER_SELECT_OPTIONS_LIST ? GENDER_SELECT_OPTIONS_LIST.find((g) => g.value === value) : undefined;
  }, [value]);

  const handleChange = React.useCallback(
    (selectedValue: OnChangeValue<ISelectorValue, boolean>) => {
      onChange(name, (selectedValue as ISelectorValue)?.value);
    },
    [onChange, name],
  );

  return (
    <Selector
      closeMenuOnSelect
      clearHint="Clear"
      className={className}
      containerClassName={styles.selectContainer}
      isMulti={isMulti}
      isSearchable={false}
      placeholder="Gender"
      helperText={helperText}
      value={formattedValue || { value: GenderEnum.Empty, label: 'Select Gender' }}
      options={GENDER_SELECT_OPTIONS_LIST}
      onChange={handleChange}
      isClearable={isSelectClearable}
      name={name as string}
      readOnly={readOnly}
      {...rest}
    />
  );
};

export default GenderSelect;
