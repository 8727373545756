import React from 'react';
import ModalPortal from '../containers/ModalContainer/ModalPortal';

interface IChildProps<T> {
  children(props: T): JSX.Element;
  className?: string;
  overlayClassName?: string;
}

interface IHookConfig {
  required?: boolean;
  resetStyles?: boolean;
}

/*
 * This hook allows to open modal window in React portal
 * Also, we can pass any props in runtime within openModal function, which is exported from this hook
 * All the data passed in arguments of `openModal` function will be passed through to the child component
 *
 * `closeModal` function takes an optional callback which will fire before the modal is closed
 *
 * Essentially, there are two ways to pass props to the child component:
 *  1. Pass them directly when  <Modal><ComponentToRender {...ownProps}></Modal>
 *  2. Pass them as an argument of `openModal` function
 */

interface IModalHook<T> {
  closeModal: (callback?: (...args: any[]) => void) => void;
  openModal: (props?: T) => void;
  isOpen: boolean;
  Modal: React.FC<IChildProps<T>>;
}

// tslint:disable-next-line: prettier
const useModal = <PropertyType,>(config?: IHookConfig): IModalHook<PropertyType> => {
  const { required, resetStyles = false } = React.useMemo(() => config || ({} as IHookConfig), [config]);

  const [childProps, setChildProps] = React.useState<PropertyType>({} as PropertyType);
  const [isOpen, setVisible] = React.useState(false);

  const closeModal = React.useCallback((callback?: (...args: any[]) => void) => {
    if (typeof callback === 'function') {
      callback();
    }
    setChildProps({} as PropertyType);
    setVisible(false);
  }, []);

  const openModal = React.useCallback((props?: PropertyType) => {
    setVisible(true);
    if (props) {
      setChildProps(props);
    }
  }, []);

  const Modal: React.FC<IChildProps<PropertyType>> = React.useMemo(
    () =>
      ({ children, className, overlayClassName }) =>
        (
          <ModalPortal<PropertyType>
            className={className}
            overlayClassName={overlayClassName}
            resetStyles={resetStyles}
            childProps={childProps}
            closeModal={closeModal}
            openModal={openModal}
            isOpen={isOpen}
            required={required}
          >
            {children}
          </ModalPortal>
        ),
    [isOpen, childProps, closeModal, openModal, resetStyles],
  );

  return {
    closeModal,
    openModal,
    isOpen,
    Modal,
  };
};

export default useModal;
