import { createTransform } from 'redux-persist';

import { IFulfillmentCentersState } from '../../types/fulfillmentCenters';

const fulfillmentCentersTransforms = createTransform(
  (stateIn: IFulfillmentCentersState) => stateIn,
  (stateOut: IFulfillmentCentersState) => {
    return {
      ...stateOut,
      error: null,
      isLoading: false,
    };
  },
  { whitelist: ['fulfillmentCenters'] },
);

const transformArray = [fulfillmentCentersTransforms];

export default transformArray;
