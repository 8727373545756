import * as React from 'react';
import { useSelector } from 'react-redux';

import { endpoints } from '../../constants/routing';
import { DISPLAY_CURRENCY } from '../../constants/users';

import useFetch from '../../hooks/useFetch';
import { selectUserDisplayCurrency } from '../../store/selectors/auth';
import { UISizeEnum } from '../../types/shell';
import { IEditUserProfileRequestPayload } from '../../types/users';
import { handleApiError } from '../../utils/ui';
import { InputLabel } from '../forms';
import { CurrencySelector } from '../forms/inputs';
import Loader from '../Loader/Loader';

import styles from './DisplayCurrency.module.scss';

const DisplayCurrency: React.FC = () => {
  const displayCurrency = useSelector(selectUserDisplayCurrency);

  const { make: editUserProfile, isLoading: isCurrencyLoading } = useFetch<IEditUserProfileRequestPayload, never>({
    endpoint: endpoints.editUserProfile,
    showToastOnError: true,
    method: 'POST',
  });

  const handleDisplayCurrencyChange = React.useCallback(
    (value: string) => {
      editUserProfile({
        body: {
          [DISPLAY_CURRENCY]: value,
        },
      }).catch(handleApiError(`Something bad happened. Display Currency wasn't changed.`));
    },
    [editUserProfile],
  );

  return (
    <div className={styles.wrapper}>
      <InputLabel value="Display currency" containerClassName={styles.label} />
      {isCurrencyLoading ? (
        <Loader isLoading size={UISizeEnum.Tiny} className={styles.loader} />
      ) : (
        <CurrencySelector value={displayCurrency} className={styles.container} onChange={handleDisplayCurrencyChange} />
      )}
    </div>
  );
};

export default DisplayCurrency;
