import * as React from 'react';

import { Checkbox } from '../forms';

interface IProps {
  value?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isReadOnly?: boolean;
  hint?: string;
  className?: string;
}

const DisableEmailNotifications: React.FC<IProps> = ({
  value,
  onChange,
  isReadOnly,
  hint = 'If the box is checked, Imprint Engine will not generate email notifications with updates on every send. You will still be able to check the status of each one within the Delivery Reports.',
  className,
}) => {
  return (
    <Checkbox
      checked={value}
      onChange={onChange}
      text="Disable sender notifications"
      hint={hint}
      className={className}
      isReadOnly={isReadOnly}
    />
  );
};

export default DisableEmailNotifications;
