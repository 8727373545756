import { createTransform } from 'redux-persist';

import { IReasonsState } from '../../types/reasons';

const reasonDetailsTransforms = createTransform(
  (stateIn: IReasonsState) => stateIn,
  (stateOut: IReasonsState) => {
    return {
      ...stateOut,
      isReasonDetailsLoading: false,
    };
  },
  { whitelist: ['reasons'] },
);

const reasonsTransforms = createTransform(
  (stateIn: IReasonsState) => stateIn,
  (stateOut: IReasonsState) => {
    return {
      ...stateOut,
      error: null,
      isLoading: false,
    };
  },
  { whitelist: ['reasons'] },
);

const transformArray = [reasonsTransforms, reasonDetailsTransforms];

export default transformArray;
