export const IMAGE_TYPES = ['.png', '.jpg', '.jpeg', '.svg'];

export const EMAIL_IMAGE_TYPES = ['.png', '.jpg', '.jpeg', '.svg'];

export const MAX_IMAGE_SIZE = 5242880;

export const fileRejectionErrorCode = {
  fileTooLarge: 'file-too-large',
  fileTooSmall: 'file-too-small',
  tooManyFiles: 'too-many-files',
  fileInvalidType: 'file-invalid-type',
};
