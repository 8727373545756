import cn from 'classnames';
import { FormikProps } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContent } from 'react-toastify';

import CancelIcon from '../../../assets/images/icon-cancel-button-2.svg';
import { ReactComponent as CheckmarkIcon } from '../../../assets/images/icon-checkmark.svg';
import DeleteIcon from '../../../assets/images/icon-delete-button.svg';
import { useHeaderDispatch } from '../../../contexts/HeaderInfo';
import useModal from '../../../hooks/useModal';
import useWindowSize from '../../../hooks/useWindowSize';
import {
  FormStatusEnum,
  IMaintenanceFormOutputData,
  MaintenanceFormStateEnum,
  SUBMIT_BUTTON,
} from '../../../types/forms';
import notification from '../../../utils/notification';
import { DeleteItemModal, UnsavedChangesModal } from '../../modals/CommonModals';
import { ActionButton, TextIconButton } from '../buttons';
import styles from './MaintenanceForm.module.scss';

interface IConfigMember {
  label?: string;
  disabled?: boolean;
  action?: (...args: any[]) => void;
  component?: React.ReactNode;
  hide?: boolean;
  hint?: string;
}

interface IMaintenanceFormConfigProps {
  submit?: IConfigMember;
  header?: IConfigMember;
  delete?: IConfigMember;
  cancel?: IConfigMember;
  actions?: IConfigMember[];
}

interface IProps {
  assetName?: string;
  className?: string;
  controlsClassName?: string;
  children: (data: IMaintenanceFormOutputData) => React.ReactNode | React.ReactNode[];
  form: FormikProps<Partial<any>>;
  mode?: MaintenanceFormStateEnum;
  onDelete?: (callback?: () => void) => Promise<void>;
  onCancel?: (...args: any[]) => void;
  onDiscard?: (...args: any[]) => void;
  onSave?: (...args: any[]) => void;
  showControls?: boolean;
  root: string;
  config?: IMaintenanceFormConfigProps;
  isValueChanged: boolean;
  disabled?: boolean; // Used to force disable the form
}

const MaintenanceForm: React.FC<IProps> = ({
  config,
  children,
  className,
  controlsClassName,
  assetName = 'item',
  form,
  mode = MaintenanceFormStateEnum.Adding,
  root,
  showControls = true,
  onDelete,
  onCancel,
  onDiscard,
  onSave,
  isValueChanged,
  disabled = false,
}) => {
  const history = useHistory();

  const { setHeader, setAdditionalData } = useHeaderDispatch();
  const { openModal: openDeleteItemModal, closeModal: closeDeleteItemModal, Modal: DeleteModal } = useModal();
  const { openModal: openSaveChangesModal, closeModal: closeSaveChangesModal, Modal: SaveChangesModal } = useModal();

  const { mobile } = useWindowSize();
  const isAdding = React.useMemo(() => mode === MaintenanceFormStateEnum.Adding, [mode]);
  const isEditing = React.useMemo(() => mode === MaintenanceFormStateEnum.Editing, [mode]);
  const isReading = React.useMemo(() => mode === MaintenanceFormStateEnum.Reading, [mode]);

  const isSubmitSuccess = React.useMemo(() => form.status === FormStatusEnum.Success, [form.status]);
  const isSubmitFailure = React.useMemo(() => form.status === FormStatusEnum.Error, [form.status]);
  const apiError = React.useMemo(() => form?.errors?.api, [form?.errors?.api]);
  const backAction = React.useMemo(() => {
    return config && config.header ? config.header.action : undefined;
  }, [config?.header]);

  const handleOnDeleteClick = React.useCallback(() => {
    openDeleteItemModal();
  }, [openDeleteItemModal]);

  const handleOnCancelEditingClick = React.useCallback(() => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
  }, [onCancel]);

  const renderOptionalControls = React.useCallback(() => {
    const { delete: deleteConfig, cancel, actions } = config || {};
    return (
      <div className={styles.optionalControls}>
        {!cancel?.hide && isValueChanged && !isSubmitSuccess && (
          <TextIconButton
            trigger={mobile}
            className={styles.textBtn}
            title={cancel?.label ?? 'Cancel editing'}
            onClick={cancel?.action ?? handleOnCancelEditingClick}
            disabled={cancel?.disabled}
            icon={CancelIcon}
          />
        )}
        {!deleteConfig?.hide && (
          <TextIconButton
            trigger={mobile}
            className={cn(styles.textBtn, styles.delete)}
            title={deleteConfig?.label ?? `Delete this ${assetName}`}
            onClick={deleteConfig?.action ?? handleOnDeleteClick}
            disabled={deleteConfig?.disabled}
            icon={DeleteIcon}
          />
        )}
        {actions && actions.length ? actions.map(({ component }: IConfigMember) => component) : null}
      </div>
    );
  }, [isSubmitSuccess, assetName, handleOnDeleteClick, handleOnCancelEditingClick, isValueChanged, config, mobile]);

  const setHeaderInfo = React.useCallback(() => {
    if (typeof setHeader === 'function') {
      if (isAdding) {
        return setHeader({
          title: config?.header?.label || `Add New ${assetName}`,
          action: backAction || (isValueChanged ? openSaveChangesModal : () => history.push(root)),
        });
      }
      if (isEditing) {
        return setHeader({
          title: config?.header?.label || `Edit ${assetName}`,
          action: backAction || (isValueChanged ? openSaveChangesModal : () => history.push(root)),
        });
      }
      if (isReading) {
        return setHeader({
          title: config?.header?.label || `View ${assetName}`,
          action: backAction ? backAction : () => history.push(root),
        });
      }
    }
  }, [
    config?.header?.label,
    setHeader,
    openSaveChangesModal,
    assetName,
    isAdding,
    isEditing,
    isReading,
    backAction,
    isValueChanged,
  ]);

  const setHeaderAdditionalData = React.useCallback(() => {
    if (!setAdditionalData) {
      return;
    }

    if (mobile && showControls && isEditing) {
      setAdditionalData(renderOptionalControls());
    } else {
      setAdditionalData();
    }

    // Need to clean additional information  after yourself
    return setAdditionalData;
  }, [setAdditionalData, mobile, renderOptionalControls, showControls, isEditing]);

  const handleOnSaveClick = React.useCallback(
    (e?: React.FormEvent) => {
      e?.stopPropagation();
      if (config?.submit?.action) {
        config?.submit?.action();
      } else if (typeof form.handleSubmit === 'function') {
        form.setStatus(FormStatusEnum.Start);
        form.handleSubmit();
      }
    },
    [form],
  );

  const handleDeleteItem = React.useCallback(() => {
    if (typeof onDelete === 'function') {
      onDelete(closeDeleteItemModal);
    }
  }, [onDelete, closeDeleteItemModal]);

  const handleSaveChanges = React.useCallback(() => {
    return onSave
      ? () =>
          onSave(form.values, {
            setSubmitting: form.setSubmitting,
            setStatus: form.setStatus,
            setErrors: form.setErrors,
            resetForm: form.resetForm,
          })
      : undefined;
  }, [onSave, form.values, form.resetForm, form.setSubmitting, form.setStatus, form.setErrors]);

  const isSubmitDisabled = React.useMemo(() => {
    switch (true) {
      case isEditing: {
        return config &&
          config.submit &&
          typeof config.submit.disabled !== 'undefined' &&
          config.submit.disabled !== null
          ? config.submit.disabled
          : !isValueChanged;
      }
      default:
        return false;
    }
  }, [isValueChanged, config?.submit, isEditing]);

  const toasts = React.useMemo(() => {
    switch (true) {
      case isAdding:
        return {
          success: `The new ${assetName} was added!`,
          failure: apiError ? apiError : `An unexpected error has occurred. The new ${assetName} was not added.`,
        };
      case isEditing:
        return {
          success: `The ${assetName} was edited!`,
          failure: apiError ? apiError : `An unexpected error has occurred. The new ${assetName} was not edited.`,
        };
      default:
        return null;
    }
  }, [isAdding, isEditing, assetName, apiError]);

  const saveChangesModalActions = React.useMemo(() => {
    return [
      ...(onSave
        ? [
            {
              title: 'Save and close',
              onClick: handleSaveChanges(),
            },
          ]
        : []),
      { title: 'Discard changes', onClick: onDiscard, isOutlined: true },
    ];
  }, [onDiscard, onSave, handleSaveChanges]);

  const deleteItemModal = React.useMemo(
    () => (
      <DeleteModal className="common-modal">
        {() => <DeleteItemModal assetName={assetName} onDelete={handleDeleteItem} onClose={closeDeleteItemModal} />}
      </DeleteModal>
    ),
    [closeDeleteItemModal, handleDeleteItem, DeleteModal],
  );

  const saveChangesModal = React.useMemo(
    () => (
      <SaveChangesModal className="common-modal">
        {() => <UnsavedChangesModal actions={saveChangesModalActions} onClose={closeSaveChangesModal} />}
      </SaveChangesModal>
    ),
    [closeSaveChangesModal, onDiscard, saveChangesModalActions, SaveChangesModal],
  );

  React.useEffect(() => {
    return setHeaderAdditionalData();
  }, [setHeaderAdditionalData]);

  React.useEffect(() => {
    setHeaderInfo();
  }, [setHeaderInfo]);

  React.useEffect(() => {
    if (isSubmitSuccess && toasts?.success) {
      notification.success(void 0, { content: toasts.success });
    }
    if (isSubmitFailure && toasts?.failure) {
      notification.error(void 0, { content: toasts.failure as ToastContent });
    }
  }, [isSubmitSuccess, isSubmitFailure, toasts]);

  return (
    <React.Fragment>
      {children({ className, mode, root })}
      {showControls && !isReading && (
        <div className={cn(styles.controls, controlsClassName)}>
          {isEditing && (
            <React.Fragment>
              {!mobile && renderOptionalControls()}
              {!config?.submit?.hide && !isSubmitSuccess ? (
                <ActionButton
                  type={SUBMIT_BUTTON}
                  title={config?.submit?.label || 'Save changes'}
                  hint={config?.submit?.hint}
                  disabled={isSubmitDisabled || !form.isValid || disabled}
                  onClick={handleOnSaveClick}
                  className={styles.saveBtn}
                />
              ) : (
                <div className={cn(styles.status)}>
                  <CheckmarkIcon className={cn(styles.statusIcon)} />
                  Changes are saved
                </div>
              )}
            </React.Fragment>
          )}
          {!config?.submit?.hide && isAdding && (
            <ActionButton
              type={SUBMIT_BUTTON}
              title={config?.submit?.label || `Add new ${assetName}`}
              disabled={form.isSubmitting || !form.isValid || disabled}
              onClick={handleOnSaveClick}
              className={styles.saveBtn}
            />
          )}
        </div>
      )}
      {deleteItemModal}
      {saveChangesModal}
    </React.Fragment>
  );
};

export default MaintenanceForm;
