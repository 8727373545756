import { createSelector } from 'reselect';
import { IAppState } from '../reducers';

export const selectRootState = (state: IAppState) => state.modals;

export const selectResetPasswordModal = createSelector(selectRootState, (modals) => modals && modals.resetPassword);

export const selectRocketModal = createSelector(selectRootState, (modals) => modals && modals.rocket);

export const selectOpportunitiesModal = createSelector(selectRootState, (modals) => modals && modals.opportunities);

export const selectIntegrationWarningModal = createSelector(
  selectRootState,
  (modals) => modals && modals.integrationWarning,
);

export const selectDownloadSuccessModal = createSelector(selectRootState, (modals) => modals && modals.downloadSuccess);

export const selectLowInventoryModal = createSelector(selectRootState, (modals) => modals && modals.lowInventoryItems);

export const selectRemovePIIModal = createSelector(selectRootState, (modals) => modals && modals.removePII);
