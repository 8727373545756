import * as React from 'react';

import { Drawer } from '../../components';
import useWindowSize from '../../hooks/useWindowSize';
import { DrawerAnimationDirectionEnum } from '../../types/shell';

import { BUTTON_BUTTON } from '../../types/forms';

import variables from '../../_variables.scss';
import styles from './ReceiverFixedAddressSidebar.module.scss';

interface IProps {
  onClose: () => void;
  trigger: boolean;
  children?: React.ReactNode;
}

const ReceiverFixedAddressSidebar: React.FC<IProps> = ({ trigger, onClose, children }) => {
  const { mobile, HD } = useWindowSize();

  const from = React.useMemo(() => (mobile ? variables.orgSidebarMinHeight : variables.orgSidebarMinWidth), [mobile]);
  const to = React.useMemo(() => {
    if (mobile) {
      return variables.orgSidebarMaxHeight;
    }

    if (HD) {
      return variables.orgSidebarMaxWidthHD;
    }

    return variables.orgSidebarMaxWidthFullHD;
  }, [mobile, HD]);

  const header = React.useMemo(
    () => (
      <>
        <button type={BUTTON_BUTTON} className={styles.closeBtn} onClick={onClose} />
        <h1 className={styles.header}>Enter delivery address</h1>
        <p className={styles.text}>
          Enter the address where all gifts will be delivered. The recipient cannot change this address, but they can
          still select preferred gift options if available.
        </p>
      </>
    ),
    [onClose],
  );

  // Renders children instead of the standard layout to be able to show some custom UI, like custom loader, etc
  return (
    <Drawer
      className={styles.container}
      direction={mobile ? DrawerAnimationDirectionEnum.Vertically : DrawerAnimationDirectionEnum.Horizontally}
      from={from}
      to={to}
      trigger={trigger}
      onClose={onClose}
      withOverlay
      overlayClassName={styles.overlay}
    >
      {trigger && (
        <div className={styles.wrapper}>
          {header}
          {children}
        </div>
      )}
    </Drawer>
  );
};

export default ReceiverFixedAddressSidebar;
