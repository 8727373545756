import cn from 'classnames';
import intersection from 'lodash/intersection';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import EditIcon from '../../assets/images/icon-edit-button.svg';

import { ActionButton, Card, Filter, NoResultsPlaceholder } from '../../components';
import { AddButton, TextIconButton, TextIconToggle } from '../../components/forms';
import { NAME, ORGANIZATIONS_FILTERS, ORGANIZATION_LABEL } from '../../constants/organizations';
import { routes, URL_VARS } from '../../constants/routing';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import useSearchFilter from '../../hooks/useSearchFilter/useSearchFilter';
import useWindowSize from '../../hooks/useWindowSize';
import * as ModalActions from '../../store/actions/modals';
import { toggleRemovePIIModal } from '../../store/actions/modals';
import { selectIsSuperAdmin } from '../../store/selectors/auth';
import { selectRemovePIIModal } from '../../store/selectors/modals';
import { selectCanEditOrganization, selectOrganizations } from '../../store/selectors/organizations';
import { IOrganizationItem, OrganizationFilterTypesEnum } from '../../types/organizations';
import { filterByActiveStatus } from '../../utils/organizations';

import styles from './OrganizationsContainer.module.scss';

interface IProps {
  onActivate?: (e: React.MouseEvent<HTMLInputElement | HTMLButtonElement, MouseEvent>) => void;
  onDeactivate?: (e: React.MouseEvent<HTMLInputElement | HTMLButtonElement, MouseEvent>) => void;
  onSelect: (id: string) => void;
}

const OrganizationsContainer: React.FC<IProps> = ({ onActivate, onDeactivate, onSelect }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { mobile } = useWindowSize();
  const { setHeader, setAdditionalData } = useHeaderDispatch();

  const [tab, setTab] = React.useState(OrganizationFilterTypesEnum.All);

  const organizations = useSelector(selectOrganizations);
  const canEditOrganization = useSelector(selectCanEditOrganization);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const { isOpen: isPIIModalOpened } = useSelector(selectRemovePIIModal);

  const { SearchInput, filteredItems: filteredBySearch } = useSearchFilter(organizations || [], [NAME], {
    className: styles.search,
    placeholder: 'Search organization',
  });

  const filteredByActiveStatus = React.useMemo(
    () => organizations?.filter(filterByActiveStatus(tab)),
    [organizations, tab],
  );

  const filteredItems = React.useMemo(
    () => intersection(filteredBySearch, filteredByActiveStatus),
    [filteredBySearch, filteredByActiveStatus],
  );

  const getCount = React.useCallback(
    (tabId: number) => filteredBySearch?.filter(filterByActiveStatus(tabId)).length,
    [filteredBySearch],
  );

  const handleOrganizationClick = React.useCallback(
    (uid: string) => {
      history.push(
        routes.organizations.getOrganizationUrl({
          flowId: URL_VARS.VIEW,
          orgId: uid,
        }),
      );
    },
    [history],
  );

  const handleDepartmentsClick = React.useCallback(
    (uid: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      onSelect(uid);
    },
    [onSelect],
  );

  const filterConfig = React.useMemo(() => {
    return ORGANIZATIONS_FILTERS.map(({ tabId, label }) => {
      return {
        onClick: () => setTab(tabId),
        isActive: tabId === tab,
        key: label,
        label: `${label} (${getCount(tabId)})`,
      };
    });
  }, [tab, getCount]);

  const handleAddClick = React.useCallback(() => {
    history.push(
      routes.organizations.getOrganizationUrl({
        flowId: URL_VARS.NEW,
      }),
    );
  }, [history]);

  const renderAddButton = React.useMemo(
    () =>
      isSuperAdmin && (
        <AddButton assetName={ORGANIZATION_LABEL.toLowerCase()} className={styles.addButton} onClick={handleAddClick} />
      ),
    [handleAddClick, isSuperAdmin],
  );

  const renderControls = React.useCallback(
    ({ uid, org_departments }: IOrganizationItem) => {
      // TODO: Replace `isOrgActive` with the real value when ready
      const isOrgActive = true;

      return (
        <React.Fragment>
          {canEditOrganization && (
            <div className={styles.controls}>
              <TextIconButton
                trigger={mobile}
                onClick={() => {
                  history.push(
                    routes.organizations.getOrganizationUrl({
                      flowId: URL_VARS.EDIT,
                      orgId: uid,
                    }),
                  );
                }}
                className={styles.actionBtn}
                icon={EditIcon}
                title="Edit"
              />
              <TextIconToggle
                active={isOrgActive}
                title={isOrgActive ? 'Deactivate' : 'Activate'}
                trigger={mobile}
                onClick={isOrgActive ? onDeactivate : onActivate}
                textClassName={cn(styles.actionBtn, isOrgActive ? styles.deactivate : styles.activate)}
              />
            </div>
          )}
          <TextIconButton
            className={cn(styles.actionBtn, styles.department)}
            title={org_departments?.length ? `Departments ${org_departments.length}` : 'No departments'}
            onClick={handleDepartmentsClick(uid)}
          />
        </React.Fragment>
      );
    },
    [canEditOrganization, mobile, onDeactivate, onActivate, handleDepartmentsClick, history],
  );

  const handleRemovePII = React.useCallback(
    (orgId: string) => {
      dispatch(
        ModalActions.toggleRemovePIIModal(true, {
          orgId,
        }),
      );
    },
    [dispatch],
  );

  const renderSearchFilter = React.useMemo(() => SearchInput, [SearchInput]);

  const renderItems = React.useMemo(() => {
    return (
      <div className={styles.list}>
        {filteredItems && filteredItems.length ? (
          filteredItems?.map((organization: IOrganizationItem) => {
            const { name, uid, delayed_shipping_page } = organization;
            return (
              <Card
                className={styles.card}
                key={uid}
                onClick={!canEditOrganization ? () => handleOrganizationClick(uid) : void 0}
                top={
                  <div className={styles.cardTopContainer}>
                    <img src={delayed_shipping_page?.logo_url} alt="" />
                    {isSuperAdmin && (
                      <ActionButton
                        outlined
                        className={styles.actionBtn}
                        title="Remove PII"
                        onClick={() => handleRemovePII(uid)}
                      />
                    )}
                  </div>
                }
                middle={<span>{name}</span>}
                bottom={<React.Fragment>{renderControls(organization)}</React.Fragment>}
                topClassName={styles.logo}
                middleClassName={styles.organizationName}
                bottomClassName={styles.controlsWrapper}
              />
            );
          })
        ) : (
          <NoResultsPlaceholder />
        )}
      </div>
    );
  }, [filteredItems, canEditOrganization, isSuperAdmin, renderControls, handleOrganizationClick, handleRemovePII]);

  React.useEffect(() => {
    if (typeof setHeader === 'function') {
      setHeader({ title: 'Organizations', action: () => history.push(routes.dashboard) });
    }
  }, [setHeader]);

  React.useEffect(() => {
    if (!setAdditionalData) {
      return;
    }

    setAdditionalData(mobile ? renderAddButton : null);

    // Need to clean additional information  after yourself
    return setAdditionalData;
  }, [mobile, renderAddButton, setAdditionalData]);

  React.useEffect(() => {
    if (isPIIModalOpened) {
      dispatch(toggleRemovePIIModal(false));
    }
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {organizations && organizations.length ? (
          <div className={styles.filtersWrapper}>
            <Filter className={styles.filter} config={filterConfig} />
            {renderSearchFilter}
          </div>
        ) : null}
        {!mobile && renderAddButton}
      </div>
      <div className={styles.listWrapper}>{renderItems}</div>
    </div>
  );
};

export default OrganizationsContainer;
