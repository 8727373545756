import {
  IAddBusinessReasonRequestAction,
  IBusinessReason,
  IDeleteBusinessReasonRequestAction,
  IEditBusinessReasonRequestAction,
  IReasonsDetails,
} from '../../types/reasons';

export const FETCH_BUSINESS_REASONS_REQUEST = 'FETCH_BUSINESS_REASONS_REQUEST';
export const FETCH_BUSINESS_REASONS_SUCCESS = 'FETCH_BUSINESS_REASONS_SUCCESS';
export const FETCH_BUSINESS_REASONS_FAILURE = 'FETCH_BUSINESS_REASONS_FAILURE';

export const ADD_BUSINESS_REASON_REQUEST = 'ADD_BUSINESS_REASON_REQUEST';
export const ADD_BUSINESS_REASON_SUCCESS = 'ADD_BUSINESS_REASON_SUCCESS';
export const ADD_BUSINESS_REASON_FAILURE = 'ADD_BUSINESS_REASON_FAILURE';

export const EDIT_BUSINESS_REASON_REQUEST = 'EDIT_BUSINESS_REASON_REQUEST';
export const EDIT_BUSINESS_REASON_SUCCESS = 'EDIT_BUSINESS_REASON_SUCCESS';
export const EDIT_BUSINESS_REASON_FAILURE = 'EDIT_BUSINESS_REASON_FAILURE';

export const DELETE_BUSINESS_REASON_REQUEST = 'DELETE_BUSINESS_REASON_REQUEST';
export const DELETE_BUSINESS_REASON_SUCCESS = 'DELETE_BUSINESS_REASON_SUCCESS';
export const DELETE_BUSINESS_REASON_FAILURE = 'DELETE_BUSINESS_REASON_FAILURE';

export const CHANGE_REASON_DETAILS = 'CHANGE_REASON_DETAILS';
export const CLEAR_REASON_DETAILS = 'CLEAR_REASON_DETAILS';

export const fetchBusinessReasonsRequest = () => ({
  type: FETCH_BUSINESS_REASONS_REQUEST,
});

export const fetchBusinessReasonsSuccess = (payload: IBusinessReason[]) => ({
  type: FETCH_BUSINESS_REASONS_SUCCESS,
  payload,
});

export const fetchBusinessReasonsFailure = (payload: any) => ({
  type: FETCH_BUSINESS_REASONS_FAILURE,
  payload,
});

export const addBusinessReasonRequest = (payload: IAddBusinessReasonRequestAction) => ({
  type: ADD_BUSINESS_REASON_REQUEST,
  payload,
});

export const addBusinessReasonSuccess = (payload: any) => ({
  type: ADD_BUSINESS_REASON_SUCCESS,
  payload,
});

export const addBusinessReasonFailure = (payload: any) => ({
  type: ADD_BUSINESS_REASON_FAILURE,
  payload,
});

export const editBusinessReasonRequest = (payload: IEditBusinessReasonRequestAction) => ({
  type: EDIT_BUSINESS_REASON_REQUEST,
  payload,
});

export const editBusinessReasonSuccess = (payload: IBusinessReason) => ({
  type: EDIT_BUSINESS_REASON_SUCCESS,
  payload,
});

export const editBusinessReasonFailure = (payload: any) => ({
  type: EDIT_BUSINESS_REASON_FAILURE,
  payload,
});

export const deleteBusinessReasonRequest = (payload: IDeleteBusinessReasonRequestAction) => ({
  type: DELETE_BUSINESS_REASON_REQUEST,
  payload,
});

export const deleteBusinessReasonSuccess = (payload: { uid: string }) => ({
  type: DELETE_BUSINESS_REASON_SUCCESS,
  payload,
});

export const deleteBusinessReasonFailure = (payload: any) => ({
  type: DELETE_BUSINESS_REASON_FAILURE,
  payload,
});

export const changeReasonDetails = (payload: Partial<IReasonsDetails>) => ({
  type: CHANGE_REASON_DETAILS,
  payload,
});

export const clearReasonDetails = () => ({
  type: CLEAR_REASON_DETAILS,
});
