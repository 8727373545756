import cn from 'classnames';
import * as React from 'react';
import { IOneLink } from '../../../types/oneLink';

import styles from './ConfirmedSendsCount.module.scss';

interface IProps {
  className?: string;
  instance: IOneLink;
}

const ConfirmedSendsCount = ({ className, instance: { created_sends, max_submit_count } }: IProps) => (
  <div className={cn(styles.container, className)}>
    <span className={styles.label}>Confirmed recipients:</span>
    <span className={styles.count} title="Confirmed recipients">
      {created_sends?.length || 0}
    </span>{' '}
    {max_submit_count && <span className={styles.count} title="Redemption limit">{`/ ${max_submit_count}`}</span>}
  </div>
);

export default ConfirmedSendsCount;
