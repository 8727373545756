import cn from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';

import { ReactComponent as CopyIcon } from '../../../assets/images/icon-copy.svg';
import { API_KEY, USER_EMAIL } from '../../../constants/integrations';
import { IZapierAPIKey } from '../../../types/integrations';
import { UISizeEnum } from '../../../types/shell';
import { ActionButton, CopyableButton } from '../buttons';
import { Input, SecretInput } from '../inputs';

import styles from './ZapierKeyForm.module.scss';

interface IProps {
  className?: string;
  form: FormikProps<IZapierAPIKey>;
  readOnly?: boolean;
  generateKey?: () => void;
}
const ZapierKeyForm = ({ className, form, readOnly, generateKey }: IProps) => {
  const handleGenerateNewKey = React.useCallback(() => {
    if (typeof generateKey === 'function') {
      generateKey();
    }
  }, [generateKey]);

  return (
    <form className={cn(styles.zapierKeyForm, className)} onSubmit={form.handleSubmit}>
      <div className={styles.inputContainer}>
        <Input
          helperText="Email"
          name={USER_EMAIL}
          className={styles.input}
          value={form.values[USER_EMAIL]}
          readOnly={readOnly}
        />
        <CopyableButton
          className={styles.copyBtn}
          value={form.values[USER_EMAIL]}
          size={UISizeEnum.Normal}
          disabled={!form.values[USER_EMAIL]}
        >
          <CopyIcon />
        </CopyableButton>
      </div>

      <div className={styles.inputContainer}>
        <SecretInput
          field={form.getFieldProps(API_KEY)}
          name={API_KEY}
          helperText="API Key"
          value={form.values[API_KEY]}
          className={cn(styles.input, { [styles.noValue]: !form.values[API_KEY] })}
          readOnly={readOnly}
          isSecretPresent={!!form.values[API_KEY]}
        />
        <CopyableButton
          className={styles.copyBtn}
          value={form.values[API_KEY]}
          size={UISizeEnum.Normal}
          disabled={!form.values[API_KEY]}
        >
          <CopyIcon />
        </CopyableButton>
      </div>

      <ActionButton
        className={cn(styles.inputContainer, styles.button)}
        title="Generate new"
        outlined={true}
        onClick={handleGenerateNewKey}
      />
    </form>
  );
};

export default ZapierKeyForm;
