import omit from 'lodash/omit';
import { AUTOMATION_CONNECTIONS } from '../constants/organizations';
import {
  IOrganizationItem,
  IOrganizationOptions,
  ISimplifiedOrganization,
  OrganizationFilterTypesEnum,
} from '../types/organizations';

// TODO: added logic after implementation activate\deactivate organization
export const filterByActiveStatus = (tabId: number) => (/*org: IOrganizationItem*/) => {
  switch (tabId) {
    case OrganizationFilterTypesEnum.Activated:
      return true;
    case OrganizationFilterTypesEnum.Deactivated:
      return false;
    default:
      return true;
  }
};

export const sortByName =
  (isCaseSensitive: boolean = true) =>
  ({ name: firstName }: { name: string }, { name: secondName }: { name: string }) => {
    const a = isCaseSensitive ? firstName : firstName.toLocaleLowerCase();
    const b = isCaseSensitive ? secondName : secondName.toLocaleLowerCase();

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

export const getSimplifiedOrganization = ({
  uid,
  name,
  org_options,
  org_departments,
  delayed_shipping_page: { logo_url },
}: IOrganizationItem): ISimplifiedOrganization => ({
  uid,
  name,
  org_options,
  org_departments,
  logo_url,
});

export const removeUntrackedOrgFields = ({
  org_options: orgOptions,
  ...restOrg
}: {
  org_options?: IOrganizationOptions;
}) => ({
  ...restOrg,
  org_options: omit(orgOptions, AUTOMATION_CONNECTIONS),
});
