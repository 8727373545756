import cn from 'classnames';
import subDays from 'date-fns/subDays';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from '../../../assets/images/icon-close-cross.svg';
import { PII_NOT_REMOVABLE_DAYS_PERIOD } from '../../../constants/organizations';
import useWindowSize from '../../../hooks/useWindowSize';
import { removePIIRequest } from '../../../store/actions/organizations';
import { selectRemovePIIModal } from '../../../store/selectors/modals';
import { selectIsRemovePIILoading, selectOrganizationById } from '../../../store/selectors/organizations';
import { BUTTON_BUTTON } from '../../../types/forms';
import { IDatePickerOutput } from '../../../types/shell';
import notification from '../../../utils/notification';
import { ActionButton, DateRangePicker } from '../../forms';
import { Loader, ModalActions, ModalHeader } from '../../index';

import styles from './RemovePIIModal.module.scss';

interface IProps {
  onClose: () => void;
}

const RemovePIIModal: React.FC<IProps> = ({ onClose }: IProps) => {
  const dispatch = useDispatch();

  const { mobile } = useWindowSize();

  const { metadata: modalMetadata } = useSelector(selectRemovePIIModal);
  const { name: organizationName } = useSelector(selectOrganizationById(modalMetadata?.orgId)) || {};
  const isLoading = useSelector(selectIsRemovePIILoading);

  const [dateRange, setDateRange] = React.useState<IDatePickerOutput | null>(null);

  const isRemoveButtonDisabled = React.useMemo(() => {
    return !dateRange?.to || !dateRange?.from;
  }, [dateRange]);

  const handleDateRangeChange = React.useCallback((range?: IDatePickerOutput | null) => {
    setDateRange(range || null);
  }, []);

  const handleSuccessPIIRemove = React.useCallback(() => {
    notification.success('pii-remove-success', { content: `PII for ${organizationName} was successfully removed!` });
  }, [organizationName]);

  const handleRemovePII = React.useCallback(() => {
    const { from, to } = dateRange || {};
    if (from && to && modalMetadata?.orgId) {
      dispatch(
        removePIIRequest({
          orgId: modalMetadata?.orgId,
          from,
          to,
          resolve: handleSuccessPIIRemove,
          reject: (content: string) => notification.error('pii-remove-error', { content }),
        }),
      );
    }
  }, [dateRange, modalMetadata, handleSuccessPIIRemove, dispatch]);

  return (
    <div className={styles.container}>
      <Loader isLoading={isLoading} className={styles.loaderContainer} />
      <ModalHeader
        className={styles.header}
        right={
          <button type={BUTTON_BUTTON} onClick={onClose}>
            <CloseIcon className={styles.closeModalButton} />
          </button>
        }
        titleClassName={styles.title}
        title="Remove PII"
      />
      <p className={styles.description}>Select a date range to remove recipients’ personally identifiable info.</p>
      <div className={styles.modalContent}>
        <DateRangePicker
          wide={!mobile}
          maxDate={subDays(new Date(), PII_NOT_REMOVABLE_DAYS_PERIOD)}
          pickerClassName={cn({ [styles.picker]: !mobile })}
          value={dateRange}
          onSelect={handleDateRangeChange}
          onReset={handleDateRangeChange}
        />
      </div>
      <ModalActions className={styles.modalFooter}>
        <ActionButton
          title="Remove"
          className={styles.button}
          onClick={handleRemovePII}
          disabled={isRemoveButtonDisabled || isLoading}
        />
      </ModalActions>
    </div>
  );
};

export default RemovePIIModal;
