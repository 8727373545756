import { useCallback, useEffect, useMemo } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { OneLinkSummaryComponent } from '../../components';
import { routes } from '../../constants/routing';
import { ALLOWED_DOMAIN, ENABLED_2FA, PASSWORD } from '../../constants/shipping';
import { EMAIL_TEMPLATE_ID, MESSAGE } from '../../constants/templates';
import { useCurrency } from '../../contexts/CurrencyProvider';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import { fetchOneLinkByIdRequest, populateOneLinkCampaignSummariesRequest } from '../../store/actions/oneLink';
import { fetchBusinessReasonsRequest } from '../../store/actions/reasons';
import { selectOneLinkById } from '../../store/selectors/oneLink';
import { IOneLinkRouteParams } from '../../types/routing';
import { isDigitalPYGReport, isDigitalReport, isPYGReport } from '../../utils/reports';
import { handleApiError } from '../../utils/ui';

import styles from './OneLinkSummaryContainer.module.scss';

const OneLinkSummaryContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { itemId, status } = useParams<IOneLinkRouteParams>();
  const { getSendTotalPrice } = useCurrency();

  const { setAction, setTitle } = useHeaderDispatch();

  const oneLink = useSelector(selectOneLinkById(itemId, status));

  const isDigital = useMemo(() => !!oneLink && (isDigitalReport(oneLink) || isDigitalPYGReport(oneLink)), [oneLink]);

  const showEmailSection = useMemo(() => {
    return typeof oneLink?.[EMAIL_TEMPLATE_ID] !== 'undefined' && oneLink?.[EMAIL_TEMPLATE_ID] && isDigital;
  }, [oneLink, isDigital]);

  const fetchOneLink = useCallback(() => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchOneLinkByIdRequest({
          itemId,
          status,
          resolve,
          reject,
        }),
      );
    }).catch(
      handleApiError(`Something bad happened during the one link retrieval.`, () =>
        history.push(routes.oneLinkManager.root),
      ),
    );
  }, [dispatch, history, status, itemId]);

  const fetchReasons = useCallback(() => {
    dispatch(fetchBusinessReasonsRequest());
  }, [dispatch]);

  const fetchCampaignSummaries = useCallback(
    (campaignIds: string[]) => {
      dispatch(populateOneLinkCampaignSummariesRequest({ campaign_ids: campaignIds, itemId, status }));
    },
    [dispatch, itemId, status],
  );

  useEffect(() => {
    if (!oneLink) {
      fetchOneLink();
    }
  }, [oneLink, fetchOneLink]);

  useEffect(() => {
    if (oneLink?.pick_campaign_ids && !oneLink?.pick_campaigns) {
      fetchCampaignSummaries(oneLink.pick_campaign_ids);
    }
  }, [oneLink, fetchCampaignSummaries]);

  useEffect(() => {
    fetchReasons();
  }, [fetchReasons]);

  useEffect(() => {
    setTitle?.('One Link Summary');
    setAction?.(() => history.push(routes.oneLinkManager.root));
  }, [setTitle, setAction, history]);

  return (
    <div className={styles.container}>
      <OneLinkSummaryComponent
        status={status}
        instance={oneLink}
        showNote={!!oneLink?.[MESSAGE]}
        showAdvancedSettings={Boolean(
          oneLink?.[PASSWORD] || oneLink?.[ALLOWED_DOMAIN] || oneLink?.[ENABLED_2FA] || !isDigital,
        )}
        showEmail={!!showEmailSection}
        total={getSendTotalPrice(oneLink)}
        reportMode
        isDigital={isDigital}
        isPYG={!!oneLink && isPYGReport(oneLink)}
        showQuantity
      />
    </div>
  );
};

export default OneLinkSummaryContainer;
