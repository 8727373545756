import cn from 'classnames';
import * as React from 'react';

import { IModalAction } from '../../../types/modals';
import { ActionButton } from '../../forms';
import ModalActions from '../ModalActions/ModalActions';
import ModalHeader from '../ModalHeader/ModalHeader';
import styles from './CommonModals.module.scss';

interface IChoosingItemTypeAction extends IModalAction {
  isOutlined?: boolean;
}

interface IProps {
  description?: React.ReactNode | React.ReactNode[] | string;
  onClose: () => void;
  actions: IChoosingItemTypeAction[];
}

const UnsavedChangesModal: React.FC<IProps> = ({ description, onClose, actions }: IProps) => {
  const handleOnControlClick = React.useCallback(
    (cb?: () => void) => {
      if (cb) {
        cb();
      }
      onClose();
    },
    [onClose],
  );

  return (
    <div className={styles.container}>
      <ModalHeader className={styles.header} />
      <div className={styles.content}>
        <div className={styles.contentHeader}>Unsaved Changes</div>
        <div className={styles.description}>
          {description || (
            <React.Fragment>
              <p>Are you sure you want to leave?</p>
              <p>Your changes will not be saved</p>
            </React.Fragment>
          )}
        </div>
      </div>
      <ModalActions className={styles.footer}>
        {actions
          .sort((a, b) => {
            if (a.isOutlined !== b.isOutlined) {
              return a.isOutlined ? -1 : 1;
            }
            return 0;
          })
          .map(({ title, onClick, isOutlined }) => {
            return (
              <ActionButton
                key={title}
                title={title}
                onClick={() => handleOnControlClick(onClick)}
                outlined={isOutlined}
              />
            );
          })}
      </ModalActions>
    </div>
  );
};

export default UnsavedChangesModal;
