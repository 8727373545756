import { BOX_ID } from '../../../constants/campaigns';
import { ICampaign } from '../../../types/campaigns';
import { convertArrayToObject } from '../../../utils/helpers';

export default (state: any) => {
  const { items, boxDetails: details, error } = state.inventory;

  return {
    ...state,
    campaigns: {
      items: items && items.length ? convertArrayToObject<ICampaign, typeof BOX_ID>(items, BOX_ID) : null,
      details,
      error,
    },
  };
};
