import merge from 'lodash/merge';
import { PersistConfig } from 'redux-persist';

const isPlainEnoughObject = (o: any) => {
  return o !== null && !Array.isArray(o) && typeof o === 'object';
};

/*The autoMergeLevel2 function is taken as a basis
 * Shallow copy was replaced my deep copy
 * autoMergeLevel2 could be find here: redux-persist\src\stateReconciler\autoMergeLevel2.js
 */
const deepMerge = (inboundState: any, originalState: any, reducedState: any, { debug }: PersistConfig<any>) => {
  const newState = { ...reducedState };

  if (inboundState && typeof inboundState === 'object') {
    Object.keys(inboundState).forEach((key) => {
      // ignore _persist data
      if (key === '_persist') {
        return;
      }
      // if reducer modifies substate, skip auto rehydration
      if (originalState[key] !== reducedState[key]) {
        return;
      }
      if (isPlainEnoughObject(reducedState[key])) {
        // Deep merge instead of shallow
        newState[key] = merge({}, newState[key], inboundState[key]);
        return;
      }

      newState[key] = inboundState[key];
    });
  }

  return newState;
};

export default deepMerge;
