import { call, put, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { endpoints } from '../../constants/routing';
import { IAddOrgAddressActionPayload, IEditOrgAddressActionPayload } from '../../types/addressBook';
import { IReduxAction } from '../../types/redux';
import * as AddressBookActions from '../actions/addressBook';
import { AuthRequest } from './helpers';

function* addressBookWorkerSaga(): Generator<any, any, any> {
  try {
    const endpoint = `${apiUrl}${endpoints.getAddressBook}`;

    const response = yield call<any>(AuthRequest, { endpoint });
    if (!response.ok) {
      yield put(AddressBookActions.fetchAddressBookFailure(response.body));
    } else {
      yield put(AddressBookActions.fetchAddressBookSuccess(response.body));
    }
  } catch (error) {
    yield put(AddressBookActions.fetchAddressBookFailure(error));
  }
}

function* addOrgAddressWorkerSaga(action: IReduxAction<IAddOrgAddressActionPayload>): Generator<any, any, any> {
  const { address, resolve, reject } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.createOrgAddress}`;
    const response = yield call<any>(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({ ...address }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(AddressBookActions.addOrgAddressFailure(response.body));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(AddressBookActions.addOrgAddressSuccess({ ...address, ...response.body }));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(AddressBookActions.addOrgAddressFailure(error));
  }
}

function* editOrgAddressWorkerSaga(action: IReduxAction<IEditOrgAddressActionPayload>): Generator<any, any, any> {
  const { address, resolve, reject } = action.payload || {};
  try {
    const endpoint = `${apiUrl}${endpoints.updateOrgAddress}`;
    const response = yield call(AuthRequest, {
      endpoint,
      method: 'PATCH',
      body: JSON.stringify({ ...address }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(AddressBookActions.editOrgAddressFailure(response.body));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(AddressBookActions.editOrgAddressSuccess(address));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(AddressBookActions.editOrgAddressFailure(error));
  }
}

const sagas = {
  *watchAddressBookRequest() {
    yield takeLatest(AddressBookActions.FETCH_ADDRESS_BOOK_REQUEST, addressBookWorkerSaga);
  },
  *watchAddOrgAddressRequest() {
    yield takeLatest(AddressBookActions.ADD_ORG_ADDRESS_REQUEST, addOrgAddressWorkerSaga);
  },
  *watchEditOrgAddressRequest() {
    yield takeLatest(AddressBookActions.EDIT_ORG_ADDRESS_REQUEST, editOrgAddressWorkerSaga);
  },
};

export default sagas;
