import cn from 'classnames';
import * as React from 'react';
import { OnChangeValue } from 'react-select';

import { ENGAGEMENT_REMINDER_HELP_TEXT } from '../../../../constants/engagement';
import useWindowSize from '../../../../hooks/useWindowSize';
import {
  EngagementReminderIntervalsEnum,
  EngagementReminderWeekdaysEnum,
  REMINDER_INTERVAL_LABELS,
} from '../../../../types/reports';
import { ISelectorValue, PositionStylesEnum } from '../../../../types/shell';
import { Checkbox, DropdownWithContent, HelpTooltip, Selector } from '../../../index';
import { ControlButton } from '../../buttons';

import styles from './IntervalInput.module.scss';

interface IProps {
  name?: string;
  placeholder?: string;
  className?: string;
  dropdownContentClassName?: string;
  value?: string | null;
  onChange?: (interval: string | null) => void;
  readOnly?: boolean;
  label?: string;
}

interface IInitialInterval {
  interval: EngagementReminderIntervalsEnum | null;
  days: EngagementReminderWeekdaysEnum[];
}

function getInitialReminderData(initialInterval?: string | null): IInitialInterval {
  if (initialInterval) {
    const isCustomIntervalSelected =
      initialInterval !== EngagementReminderIntervalsEnum.Weekly &&
      initialInterval !== EngagementReminderIntervalsEnum.Biweekly &&
      !initialInterval.includes(EngagementReminderIntervalsEnum.Custom);

    if (isCustomIntervalSelected) {
      return {
        interval: EngagementReminderIntervalsEnum.Custom,
        days: initialInterval.split(',') as EngagementReminderWeekdaysEnum[],
      };
    }

    return {
      interval: (initialInterval || EngagementReminderIntervalsEnum.Weekly) as EngagementReminderIntervalsEnum,
      days: [],
    };
  }

  return {
    interval: null,
    days: [],
  };
}

const IntervalInput = ({ className, dropdownContentClassName, value, onChange, label, readOnly }: IProps) => {
  const { mobile } = useWindowSize();

  const isReminderEnabled = React.useMemo(() => !!value, [value]);

  const [selectedOption, setSelectedOption] = React.useState<EngagementReminderIntervalsEnum | null>(null);
  const [selectedDays, setSelectedDays] = React.useState<EngagementReminderWeekdaysEnum[]>([]);

  const isCustomIntervalSelected = React.useMemo(() => {
    return selectedOption === EngagementReminderIntervalsEnum.Custom;
  }, [selectedOption]);

  const dropdownOptions = React.useMemo(() => {
    return Object.entries(EngagementReminderIntervalsEnum).map(([_, val]) => ({
      label: REMINDER_INTERVAL_LABELS[val],
      value: val,
    }));
  }, []);

  const handleReminderEnabledChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.currentTarget;

      onChange?.(checked ? EngagementReminderIntervalsEnum.Weekly : null);
    },
    [onChange],
  );

  const dropdownLabel = React.useCallback(
    (isOpened: boolean) => {
      const selectedValue = selectedOption
        ? dropdownOptions?.find((option) => selectedOption === option.value)
        : ({} as ISelectorValue);

      return (
        <ControlButton
          readOnly={readOnly}
          label={`${readOnly ? 'Reminder repeat' : 'Repeat'}: ${selectedValue && selectedValue?.label}`}
          isOpened={isOpened}
        />
      );
    },
    [selectedOption, dropdownOptions, readOnly],
  );

  const handleSelectChange = React.useCallback(
    (v: OnChangeValue<ISelectorValue, boolean>) => {
      const val = (v as ISelectorValue)?.value;

      if (typeof onChange === 'function') {
        onChange(val);
      }
      setSelectedOption(val as EngagementReminderIntervalsEnum);
    },
    [onChange],
  );

  const getIsDaySelected = React.useCallback(
    (day: EngagementReminderWeekdaysEnum) => {
      return selectedDays.includes(day);
    },
    [selectedDays],
  );

  const handleSelectDay = React.useCallback(
    (day: EngagementReminderWeekdaysEnum) => {
      if (getIsDaySelected(day)) {
        const filteredSelectedDays = selectedDays.filter((selectedDay) => selectedDay !== day);

        if (typeof onChange === 'function') {
          onChange(filteredSelectedDays.join(','));
        }
        setSelectedDays(filteredSelectedDays);

        return;
      }

      const updatedSelectedDays = [...selectedDays, day];

      if (typeof onChange === 'function') {
        onChange(updatedSelectedDays.join(','));
      }
      setSelectedDays(updatedSelectedDays);

      return;
    },
    [getIsDaySelected, selectedDays, onChange],
  );

  const intervalsMarkup = React.useMemo(() => {
    const daysOfWeek = EngagementReminderWeekdaysEnum;
    const daysMarkup = Object.values(daysOfWeek).map((day) => {
      const isDaySelected = getIsDaySelected(day);
      return (
        <span
          role="button"
          key={day}
          className={cn(styles.day, { [styles.selected]: isDaySelected })}
          onClick={() => handleSelectDay(day)}
        >
          {day}
        </span>
      );
    });

    return (
      <div className={styles.intervalsContainer}>
        <span className={styles.label}>on:</span>
        <div className={styles.daysContainer}>{daysMarkup}</div>
      </div>
    );
  }, [getIsDaySelected, handleSelectDay]);

  const renderIntervalDropdown = React.useMemo(
    () => (
      <DropdownWithContent
        label={({ isOpened }) => dropdownLabel(isOpened)}
        labelClassName={styles.dropDownLabel}
        contentClassName={cn(styles.dropdownContent, dropdownContentClassName)}
      >
        {({ close }) => (
          <Selector
            isClearable={false}
            isSearchable={false}
            menuIsOpen
            closeMenuOnSelect
            containerClassName={styles.selector}
            value={dropdownOptions?.find(({ value: val }) => val === selectedOption)}
            onChange={(v) => {
              handleSelectChange(v);
              close();
            }}
            options={dropdownOptions || []}
            readOnly={readOnly}
          />
        )}
      </DropdownWithContent>
    ),
    [selectedOption, dropdownOptions, dropdownContentClassName, handleSelectChange, dropdownLabel],
  );

  React.useEffect(() => {
    const { interval, days } = getInitialReminderData(value);

    setSelectedOption(interval);
    setSelectedDays(days);
  }, [value]);

  React.useEffect(() => {
    if (!isCustomIntervalSelected && selectedDays.length > 0) {
      setSelectedDays([]);
    }
  }, [isCustomIntervalSelected, selectedDays]);

  return (
    <div className={cn(styles.container, className, { [styles.readOnly]: readOnly })}>
      {!readOnly && (
        <Checkbox
          checked={isReminderEnabled}
          onChange={handleReminderEnabledChange}
          className={styles.checkboxContainer}
          text={
            <React.Fragment>
              {label || 'Send a reminder'}
              <HelpTooltip
                id="sp-recipient-email-reminder-info"
                className={styles.infoIcon}
                place={mobile ? PositionStylesEnum.Right : PositionStylesEnum.Top}
              >
                {ENGAGEMENT_REMINDER_HELP_TEXT}
              </HelpTooltip>
            </React.Fragment>
          }
        />
      )}
      {isReminderEnabled && (
        <div className={styles.controlsContainer}>
          {renderIntervalDropdown}
          {isCustomIntervalSelected && intervalsMarkup}
        </div>
      )}
    </div>
  );
};

export default IntervalInput;
