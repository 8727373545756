import cn from 'classnames';
import debounce from 'lodash/debounce';
import * as React from 'react';

import { ReactComponent as IconSearch } from '../../../../assets/images/icon-search.svg';

import { CAMPAIGN_ITEMS_PER_PAGE_WHEN_SCROLLED_INFINITELY } from '../../../../constants/campaigns';
import { useCurrency } from '../../../../contexts/CurrencyProvider';
import useClickOutside from '../../../../hooks/useClickOutside';
import { useCampaignsInfiniteScroll } from '../../../../service/InventoryService';
import { ICampaign } from '../../../../types/campaigns';
import { UISizeEnum } from '../../../../types/shell';
import { Loader, Price } from '../../../index';
import { Input } from '../Input';

import stylesModule from './CampaignAutocompleteInput.module.scss';

interface IProps {
  styles?: { dropdown?: React.CSSProperties };
  className?: string;
  value?: string;
  exclude?: string[];
  onSelect: (campaign: ICampaign) => void;
  placeholder?: string;
}

const CampaignAutocompleteInput = ({
  className,
  onSelect,
  exclude = [],
  value = '',
  styles,
  placeholder = 'Start typing...',
}: IProps) => {
  const { getCampaignTotal } = useCurrency();
  const [input, setInput] = React.useState<string>(value);
  const [query, setQuery] = React.useState<string>(input);
  const [isExpanded, setIsExpanded] = React.useState(false);

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => setIsExpanded(false));

  const {
    data: campaignsData,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchedAfterMount,
  } = useCampaignsInfiniteScroll({
    page_size: CAMPAIGN_ITEMS_PER_PAGE_WHEN_SCROLLED_INFINITELY,
    search_query: query,
  });

  const campaigns = React.useMemo<ICampaign[]>(
    () => (campaignsData?.pages.flat() as ICampaign[]) || [],
    [campaignsData],
  );

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = dropdownRef.current || event.currentTarget;

    const scrollPosition = scrollTop + clientHeight;
    const threshold = scrollHeight * 0.75;

    if (scrollPosition >= threshold && hasNextPage && !isFetchingNextPage) {
      void fetchNextPage();
    }
  };

  // Since the dropdown height is passed via style and next page fetch is triggered by the scroll event
  // we need this Effect that tracks whether it's enough campaigns to fulfill the height of the dropdown
  // if there's still page to fetch and there's no scroll on the UI, it will fetch the next page
  React.useEffect(() => {
    if (isFetchedAfterMount && campaigns.length && dropdownRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = dropdownRef.current;

      if (scrollTop === 0 && clientHeight === scrollHeight && hasNextPage && !isFetching) {
        void fetchNextPage();
      }
    }
  }, [hasNextPage, fetchNextPage, isFetching, isFetchedAfterMount, campaigns.length]);

  const debouncedChange = React.useCallback(
    debounce((v: string) => {
      setQuery(v);
    }, 500),
    [],
  );

  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  }, []);

  React.useEffect(() => {
    if (input !== query) {
      debouncedChange(input);
    }
  }, [debouncedChange, input, query]);

  const handleSelect = React.useCallback(
    (campaign: ICampaign) => {
      setInput(campaign.name);
      setIsExpanded(false);
      onSelect(campaign);
    },
    [onSelect],
  );

  React.useEffect(() => {
    if (campaigns?.length) {
      setIsExpanded(true);
    }
  }, [campaigns]);

  return (
    <div className={cn(stylesModule.container, className)}>
      <div className={stylesModule.inputContainer}>
        {isFetching ? (
          <Loader className={stylesModule.spinner} isLoading size={UISizeEnum.Tiny} />
        ) : (
          <IconSearch className={stylesModule.icon} />
        )}
        <Input
          inline
          className={stylesModule.input}
          autoFocus
          type="text"
          onChange={handleChange}
          value={input}
          placeholder={placeholder}
          onFocus={() => setIsExpanded(!!campaigns?.length)}
        />
      </div>
      <div
        {...(styles?.dropdown ? { style: styles.dropdown } : {})}
        className={cn(stylesModule.dropdown, { [stylesModule.expanded]: isExpanded })}
        ref={dropdownRef}
        onScroll={handleScroll}
      >
        {campaigns
          .filter((c) => !exclude?.includes(c.box_id))
          .map((campaign) => {
            const { name, box_id, icon_url } = campaign;
            const totalCost = getCampaignTotal(campaign);

            return (
              <button className={stylesModule.option} type="button" key={box_id} onClick={() => handleSelect(campaign)}>
                <div className={stylesModule.content}>
                  <div className={stylesModule.nameWrapper}>
                    <img src={icon_url} alt={`${name} icon`} className={stylesModule.iconImage} />
                    <span className={stylesModule.name}>{name}</span>
                  </div>
                  <Price className={stylesModule.price} value={totalCost} />
                </div>
              </button>
            );
          })}
        {isFetching && (
          <span className={cn(stylesModule.option, stylesModule.empty)}>
            <Loader className={stylesModule.spinner} isLoading size={UISizeEnum.Tiny} />
          </span>
        )}
        {!isFetching && !campaigns?.length && (
          <div className={cn(stylesModule.option, stylesModule.empty)}>No results</div>
        )}
      </div>
    </div>
  );
};

export default CampaignAutocompleteInput;
