import * as React from 'react';

import { ReactComponent as GmailIcon } from '../assets/images/icon-gmail.svg';
import { ReactComponent as HubspotIcon } from '../assets/images/icon-hubspot.svg';
import { ReactComponent as OutlookIcon } from '../assets/images/icon-outlook.svg';
import { ReactComponent as SalesforceIcon } from '../assets/images/icon-salesforce.svg';
import { BLUEBIRD, GMAIL, HUBSPOT, NONE, OUTLOOK, SALESFORCE } from './integrations';

export const EMPTY_INTEGRATION_WARNING = { icon: null as unknown as React.FC<any>, description: null };

export const INTEGRATION_WARNINGS_INFO = {
  [HUBSPOT]: {
    icon: HubspotIcon,
    description: (
      <React.Fragment>
        <p>
          In order to proceed with searching the information about receiver we need a permission to your Hubspot
          account.
        </p>
        <p>Please, click `Continue` button to proceed.</p>
      </React.Fragment>
    ),
  },
  [SALESFORCE]: {
    icon: SalesforceIcon,
    description: (
      <React.Fragment>
        <p>
          In order to proceed with searching the information about receiver we need a permission to your Salesforce
          account.
        </p>
        <p>Please, click `Continue` button to proceed.</p>
      </React.Fragment>
    ),
  },
  [GMAIL]: {
    icon: GmailIcon,
    description: (
      <React.Fragment>
        <p>In order to send emails on your behalf we need your permission.</p>
        <p>Please, click `Continue` button to proceed</p>
      </React.Fragment>
    ),
  },
  [OUTLOOK]: {
    icon: OutlookIcon,
    description: (
      <React.Fragment>
        <p>In order to send emails on your behalf we need your permission.</p>
        <p>Please, click `Continue` button to proceed</p>
      </React.Fragment>
    ),
  },
  [NONE]: EMPTY_INTEGRATION_WARNING,
  [BLUEBIRD]: EMPTY_INTEGRATION_WARNING,
};
