import cn from 'classnames';
import find from 'lodash/find';
import qs from 'query-string';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import SlideUpList from '../../animations/SlideUpList';
import { FlowPickerItem } from '../../components';
import { dashboardItems } from '../../constants/nav';
import { queryParams, routes } from '../../constants/routing';
import { DASHBOARD_ANIMATION_TIME_MS } from '../../constants/shell';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import { selectAdminType } from '../../store/selectors/auth';
import { selectIsAutomationsEnabled } from '../../store/selectors/organizations';
import { TFlowOption } from '../../types/dashboard';
import { hasPermission } from '../../utils/users';

import headerStyles from '../../components/Header/Header.module.scss';
import styles from './DashboardContainer.module.scss';

const DashboardContainer: React.FC = () => {
  const history = useHistory();
  const adminType = useSelector(selectAdminType);
  const { setHeader } = useHeaderDispatch();
  const [rootFlowOption, setRootFlowOption] = React.useState('');
  const [showSubFlowOptions, setSubFlowOptions] = React.useState(false);
  const { search } = useLocation();
  const isAutomationsEnabled = useSelector(selectIsAutomationsEnabled);

  const { sub: subOption } = React.useMemo(() => qs.parse(search), [search]);

  const filterByRole = React.useCallback(
    (item: TFlowOption) => {
      return hasPermission(item.roles, adminType);
    },
    [adminType],
  );

  // filter out the Automations icon if this option is set to false in org
  const filterByAutomationsFlag = React.useCallback(
    (item: TFlowOption) => item.link !== routes.automations || isAutomationsEnabled,
    [isAutomationsEnabled],
  );

  const filterMenuItems = React.useCallback(
    (
      itemsArray: TFlowOption[],
      roleFilter: (item: TFlowOption) => boolean,
      flagFilter: (item: TFlowOption) => boolean,
    ) => {
      return itemsArray.filter(roleFilter).filter(flagFilter);
    },
    [],
  );

  const flowItemsList = React.useMemo(() => {
    const items = (() => {
      switch (true) {
        case rootFlowOption && !showSubFlowOptions:
          return [];
        case rootFlowOption && showSubFlowOptions:
          return find(dashboardItems, ['key', rootFlowOption])?.subFlowOptions || dashboardItems;
        default:
          return dashboardItems;
      }
    })();

    return filterMenuItems(items, filterByRole, filterByAutomationsFlag);
  }, [filterByRole, rootFlowOption, showSubFlowOptions, filterMenuItems, filterByAutomationsFlag]);

  const openSubFlowOptionsList = React.useCallback(
    ({ key, title }: TFlowOption) => {
      /* It's a workround React spring useTransition
       * The main problem is useTransition enter doen't start after the useTransition leave
       * More information is here: https://github.com/pmndrs/react-spring/issues/583
       */
      setRootFlowOption(key);
      setTimeout(() => setSubFlowOptions(true), flowItemsList.length * DASHBOARD_ANIMATION_TIME_MS);
      setHeader?.({
        title: <h1 className={headerStyles.title}>{title}</h1>,
        action: () => {
          history.push(routes.dashboard);
          setTimeout(() => setRootFlowOption(''), flowItemsList.length * DASHBOARD_ANIMATION_TIME_MS);
          setSubFlowOptions(false);
          setHeader?.({ title: '' });
        },
      });
    },
    [flowItemsList, setHeader, history],
  );

  React.useEffect(() => {
    if (!subOption) {
      setSubFlowOptions(false);
      setTimeout(() => setRootFlowOption(''), dashboardItems.length * DASHBOARD_ANIMATION_TIME_MS);
      return setHeader?.({ title: '' });
    }

    const item = flowItemsList.find(({ key }) => key === subOption);
    if (item) {
      return openSubFlowOptionsList(item);
    }
  }, [flowItemsList, subOption, openSubFlowOptionsList]);

  const handleFlowPickerClick = React.useCallback(
    (item: TFlowOption) => {
      const { key, link, subFlowOptions } = item;
      if (subFlowOptions && subFlowOptions.length) {
        history.push({
          pathname: routes.dashboard,
          search: `?${queryParams.dashboardSubOption}=${key}`,
        });
        openSubFlowOptionsList(item);
      }
      if (link) {
        history.push(link);
      }
    },
    [history, openSubFlowOptionsList],
  );

  return (
    <div className={cn(styles.dashboard)}>
      <div className={styles.listRow}>
        <SlideUpList
          className={styles.listCell}
          items={flowItemsList.map((item: TFlowOption) => ({
            key: item.key,
            renderer: (
              <FlowPickerItem
                key={item.key}
                title={item.title}
                description={item.description}
                icon={item.icon}
                onClick={() => handleFlowPickerClick(item)}
              />
            ),
          }))}
        />
      </div>
    </div>
  );
};

export default DashboardContainer;
