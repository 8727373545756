import * as yup from 'yup';

import {
  EmailLogoTypesEnum,
  IOrganizationCustomizationOptions,
  IOrganizationFormValues,
  IOrganizationOptions,
  IShipAddress,
  OrganizationFilterTypesEnum,
  SigninMethodsEnum,
  TOrganizationsDetails,
} from '../types/organizations';
import { BLUEBIRD, crmSupports, emailSupports, NONE } from './integrations';
import { URL_VARS } from './routing';
import {
  CUSTOMIZE_ITEMS_PAGE_DEFAULT_BODY,
  CUSTOMIZE_ITEMS_PAGE_DEFAULT_TITLE,
  EMAIL_CUSTOM_SENDER_DEFAULT,
  EMAIL_DEFAULT_ACCENT_COLOR,
  EMAIL_DEFAULT_LOGO_TYPE,
  EMAIL_DEFAULT_MAIN_COLOR,
  EMAIL_DEFAULT_USE_ORG_LOGO,
  EMAIL_VALIDATION_REGEX,
  MSKU_PAGE_DEFAULT_BODY,
  MSKU_PAGE_DEFAULT_TITLE,
  PICK_YOUR_GIFT_DEFAULT_BODY,
  PICK_YOUR_GIFT_DEFAULT_TITLE,
  REQUIRED_FIELD_MESSAGE,
  SHIPPING_PAGE_DEFAULT_BODY,
  SHIPPING_PAGE_DEFAULT_BUTTON_COLOR,
  SHIPPING_PAGE_DEFAULT_HEADER_COLOR,
  SHIPPING_PAGE_DEFAULT_TITLE,
  SQUARE_BRACKETS_EXTRACTOR_REGEX,
  THEME_DEFAULT_ACCENT_COLOR,
  THEME_DEFAULT_SIDEBAR_COLOR,
  THEME_DEFAULT_SIDEBAR_TEXT_COLOR,
  THEME_DEFAULT_USE_COBRANDING_ORG_LOGO,
  THEME_DEFAULT_USE_ORG_LOGO,
} from './shell';

export const ORGANIZATION_LABEL = 'Organization';
export const NO_DEPARTMENT = 'no_department';

export const NAME = 'name';
export const ORDER_KEY = 'order_key';
export const DELAYED_SHIPPING_BASE_URL = 'delayed_shipping_base_url';
export const SALESFORCE_CAMPAIGN_ID = 'salesforce_campaign_id';
export const SALESFORCE_CLIENT_ID = 'salesforce_client_id';
export const SALESFORCE_CLIENT_SECRET = 'salesforce_client_secret';
export const SALESFORCE_CLIENT_SECRET_HAS_VALUE = 'salesforce_client_secret_has_value';
export const RYBBON_CLIENT_ID = 'rybbon_client_id';
export const RYBBON_CLIENT_SECRET = 'rybbon_client_secret';
export const RYBBON_CLIENT_SECRET_HAS_VALUE = 'rybbon_client_secret_has_value';
export const EXTERNAL_ID = 'external_id';
export const WOOCOMMERCE_HOST_URL = 'woocommerce_host_url';
export const WOOCOMMERCE_SERVICE_ACCOUNT_USER_ID = 'woocommerce_service_account_user_id';
export const WOOCOMMERCE_API_KEY = 'woocommerce_api_key';
export const ORG_DEPARTMENTS = 'org_departments';
export const SHIPPING_ADDRESS = 'shipping_address';
export const DELAYED_SHIPPING_PAGE = 'delayed_shipping_page';
export const BUTTON_COLOR = 'button_color';
export const HEADER_COLOR = 'header_color';
export const EMAIL = 'email';
export const STREET1 = 'street1';
export const STREET2 = 'street2';
export const CITY = 'city';
export const STATE = 'state';
export const POSTAL_CODE = 'postalCode';
export const RESIDENTIAL = 'residential';
export const COUNTRY = 'country';
export const PHONE = 'phone';
export const LOGO_URL = 'logo_url';
export const SQUARE_LOGO_URL = 'square_logo_url';
export const VECTOR_IMAGE_URL = 'vector_image_url';
export const SQUARE_COBRANDING_LOGO_URL = 'square_cobranding_logo_url';
export const PORTAL_USE_COBRANDING_LOGO = 'portal_use_cobranding_logo';
export const SOB_ENABLED = 'send_on_behalf_of_enabled';
export const DEPARTMENT_BUDGET_ENABLED = 'department_budget_enabled';
export const DIGITAL_BALANCE_LIMIT_ENABLED = 'digital_balance_limit_enabled';
export const AUTOMATIONS_ENABLED = 'automated_campaigns_enabled';
export const DIGITAL_BALANCE_LIMIT_VALUE = 'digital_balance_limit_value';
export const ORG_OPTIONS = 'org_options';
export const CRM_CURRENTLY_SUPPORTS = 'crm_currently_supports';
export const CRM_SKIP_ALLOWED = 'crm_skip_allowed';
export const EMAIL_CURRENTLY_SUPPORTS = 'email_currently_supports';
export const SIGN_IN_METHOD_SUPPORTS = 'sign_in_method_supports';
export const ORG_CUSTOMIZATION_OPTIONS = 'org_customization_options';
export const EMAIL_USE_ORG_LOGO = 'email_use_org_logo';
export const EMAIL_ACCENT_COLOR = 'email_accent_color';
export const EMAIL_MAIN_COLOR = 'email_main_color';
export const EMAIL_LOGO_TYPE = 'email_logo_type';
export const PORTAL_SIDEBAR_COLOR = 'portal_sidebar_color';
export const PORTAL_SIDEBAR_TEXT_COLOR = 'portal_sidebar_text_color';
export const PORTAL_ACCENT_COLOR = 'portal_accent_color';
export const PORTAL_USE_ORG_LOGO = 'portal_use_org_logo';
export const RECT_COBRANDING_LOGO_URL = 'rect_cobranding_logo_url';
export const SHIPPING_PAGE_TITLE = 'shipping_page_title';
export const SHIPPING_PAGE_BODY = 'shipping_page_body';
export const PICK_YOUR_GIFT_TITLE = 'pick_your_gift_title';
export const PICK_YOUR_GIFT_BODY = 'pick_your_gift_body';
export const MSKU_PAGE_TITLE = 'msku_page_title';
export const MSKU_PAGE_BODY = 'msku_page_body';
export const CUSTOMIZE_ITEMS_PAGE_TITLE = 'customize_items_page_title';
export const CUSTOMIZE_ITEMS_PAGE_BODY = 'customize_items_page_body';
export const ORG_RECT_LOGO = 'org_rect_logo';
export const ORG_SQUARE_LOGO = 'org_square_logo';
export const ORG_ID = 'org_id';
export const UID = 'uid';
export const AVAILABLE_BUDGET = 'available_budget';
export const USED_BUDGET = 'used_budget';
export const LOW_INVENTORY_NOTIFICATION_EMAILS = 'low_inventory_notification_emails';
export const BILLING_PLAN_ID = 'billing_plan_id';
export const AI_GIFT_SUGGESTION_ENABLED = 'ai_gift_suggestion_enabled';
export const EMAIL_CUSTOM_SENDER = 'email_custom_sender';
export const AUTOMATION_CONNECTIONS = 'automation_connections';

export const NO_DEPARTMENT_ITEM = {
  uid: NO_DEPARTMENT,
  name: 'No department',
};

export const RYBBON_HELP_TOOLTIP_TEXT = 'This field is not required. Put in a Client ID and a Client Secret primarily.';

export const COBRANDING_LOGO_HELP_TOOLTIP_TEXT = 'This logo will be used in a portal for co-branded organizations.';
export const COBRANDING_LOGO_DISABLED_TOOLTIP_TEXT =
  'To enable this feature at least rectangular co-branding logo should be uploaded.';

export const DISTRIBUTORS_NOTIFICATION_EMAILS_LIST_HELP_TEXT = 'The recipients will receive the email twice a month.';

export const PII_NOT_REMOVABLE_DAYS_PERIOD = 45;

export const ORGANIZATION_VALIDATION_SCHEMA = yup.object().shape<TOrganizationsDetails>(
  {
    [NAME]: yup.string().required(REQUIRED_FIELD_MESSAGE),
    [SHIPPING_ADDRESS]: yup.object().shape<IShipAddress>({
      [NAME]: yup.string().required(REQUIRED_FIELD_MESSAGE),
      [EMAIL]: yup.string().email('Email is invalid').required(REQUIRED_FIELD_MESSAGE),
      [STREET1]: yup.string().required(REQUIRED_FIELD_MESSAGE),
      [STREET2]: yup.string(),
      [CITY]: yup.string().required(REQUIRED_FIELD_MESSAGE),
      [STATE]: yup.string().required(REQUIRED_FIELD_MESSAGE),
      [POSTAL_CODE]: yup.string().required(REQUIRED_FIELD_MESSAGE),
      [COUNTRY]: yup.string().required(REQUIRED_FIELD_MESSAGE),
      [PHONE]: yup.string().required(REQUIRED_FIELD_MESSAGE),
    }),
    [ORDER_KEY]: yup
      .string()
      .min(3, 'Key is too short')
      .max(10, 'Max key length is 10 characters')
      .required(REQUIRED_FIELD_MESSAGE),
    [RYBBON_CLIENT_ID]: yup.string(),
    [RYBBON_CLIENT_SECRET]: yup.string(),
    [BILLING_PLAN_ID]: yup.string(),
    [SALESFORCE_CAMPAIGN_ID]: yup.string(),
    [SALESFORCE_CLIENT_ID]: yup.string(),
    [SALESFORCE_CLIENT_SECRET]: yup.string(),
    [EXTERNAL_ID]: yup.string(),
    [WOOCOMMERCE_HOST_URL]: yup.string(),
    [WOOCOMMERCE_SERVICE_ACCOUNT_USER_ID]: yup.string(),
    [WOOCOMMERCE_API_KEY]: yup.string(),
    [DELAYED_SHIPPING_PAGE]: yup.object().shape({
      [HEADER_COLOR]: yup.string().required(REQUIRED_FIELD_MESSAGE),
      [BUTTON_COLOR]: yup.string().required(REQUIRED_FIELD_MESSAGE),
      [LOGO_URL]: yup.string().required('This logo is required'),
    }),
    [SQUARE_LOGO_URL]: yup.string(),
    [VECTOR_IMAGE_URL]: yup.string(),
    [RECT_COBRANDING_LOGO_URL]: yup.string(),
    [SQUARE_COBRANDING_LOGO_URL]: yup.string(),
    [DELAYED_SHIPPING_BASE_URL]: yup.string().required(REQUIRED_FIELD_MESSAGE),
    [ORG_OPTIONS]: yup.object().shape<IOrganizationOptions>({
      [CRM_CURRENTLY_SUPPORTS]: yup.string().required(REQUIRED_FIELD_MESSAGE).oneOf(crmSupports),
      [EMAIL_CURRENTLY_SUPPORTS]: yup.string().required(REQUIRED_FIELD_MESSAGE).oneOf(emailSupports),
      [SIGN_IN_METHOD_SUPPORTS]: yup.string().required(REQUIRED_FIELD_MESSAGE).oneOf(Object.values(SigninMethodsEnum)),
      [CRM_SKIP_ALLOWED]: yup.boolean(),
      [SOB_ENABLED]: yup.boolean().required(),
      [DEPARTMENT_BUDGET_ENABLED]: yup.boolean().required(),
      [DIGITAL_BALANCE_LIMIT_ENABLED]: yup.boolean(),
      [AUTOMATIONS_ENABLED]: yup.boolean(),
      [AI_GIFT_SUGGESTION_ENABLED]: yup.boolean(),
      [DIGITAL_BALANCE_LIMIT_VALUE]: yup
        .number()
        .nullable(true)
        .when(DIGITAL_BALANCE_LIMIT_ENABLED, {
          is: (isBalanceEnabled) => {
            return isBalanceEnabled;
          },
          then: yup.number().nullable(true).required(REQUIRED_FIELD_MESSAGE),
        }),
    }),
    [ORG_CUSTOMIZATION_OPTIONS]: yup.object().shape<IOrganizationCustomizationOptions>({
      [EMAIL_LOGO_TYPE]: yup.string(),
      [EMAIL_MAIN_COLOR]: yup.string(),
      [EMAIL_ACCENT_COLOR]: yup.string(),
      [EMAIL_CUSTOM_SENDER]: yup.string().email('Invalid email'),
      [EMAIL_USE_ORG_LOGO]: yup.boolean(),
      [PORTAL_ACCENT_COLOR]: yup.string(),
      [PORTAL_SIDEBAR_COLOR]: yup.string(),
      [PORTAL_SIDEBAR_TEXT_COLOR]: yup.string(),
      [PORTAL_USE_ORG_LOGO]: yup.boolean(),
      [PORTAL_USE_COBRANDING_LOGO]: yup.boolean(),
      [SHIPPING_PAGE_TITLE]: yup.string(),
      [SHIPPING_PAGE_BODY]: yup.string(),
      [PICK_YOUR_GIFT_TITLE]: yup.string(),
      [PICK_YOUR_GIFT_BODY]: yup.string(),
      [MSKU_PAGE_TITLE]: yup.string(),
      [MSKU_PAGE_BODY]: yup.string(),
      [CUSTOMIZE_ITEMS_PAGE_TITLE]: yup.string(),
      [CUSTOMIZE_ITEMS_PAGE_BODY]: yup.string(),
    }),
    [LOW_INVENTORY_NOTIFICATION_EMAILS]: yup
      .array()
      .of(
        yup
          .string()
          .required()
          .test({
            name: 'is-email-pattern',
            message: ({ path = '', value }) => {
              const index = (() => {
                const match = new RegExp(SQUARE_BRACKETS_EXTRACTOR_REGEX).exec(path);

                return match ? parseInt(match[1], 10) : null;
              })();

              return {
                index,
                message: `"${value}" is not a valid email address.`,
              };
            },
            test: (value) => {
              return new RegExp(EMAIL_VALIDATION_REGEX).test(value);
            },
          }),
      )
      .nullable(),
  },
  [[DIGITAL_BALANCE_LIMIT_ENABLED, DIGITAL_BALANCE_LIMIT_VALUE]],
);

export const INITIAL_ORGANIZATION_FORM_STATE: Partial<IOrganizationFormValues> = {
  [NAME]: '',
  [SHIPPING_ADDRESS]: {
    [NAME]: '',
    [EMAIL]: '',
    [STREET1]: '',
    [STREET2]: '',
    [CITY]: '',
    [STATE]: '',
    [POSTAL_CODE]: '',
    [COUNTRY]: '',
    [PHONE]: '',
    [RESIDENTIAL]: false,
  },
  [ORDER_KEY]: '',
  [RYBBON_CLIENT_ID]: '',
  [RYBBON_CLIENT_SECRET]: '',
  [SALESFORCE_CAMPAIGN_ID]: '',
  [SALESFORCE_CLIENT_ID]: '',
  [SALESFORCE_CLIENT_SECRET]: '',
  [BILLING_PLAN_ID]: '',
  [EXTERNAL_ID]: '',
  [WOOCOMMERCE_HOST_URL]: '',
  [WOOCOMMERCE_SERVICE_ACCOUNT_USER_ID]: '',
  [WOOCOMMERCE_API_KEY]: '',
  [DELAYED_SHIPPING_PAGE]: {
    [HEADER_COLOR]: SHIPPING_PAGE_DEFAULT_HEADER_COLOR,
    [BUTTON_COLOR]: SHIPPING_PAGE_DEFAULT_BUTTON_COLOR,
    [LOGO_URL]: '',
  },
  [SQUARE_LOGO_URL]: '',
  [VECTOR_IMAGE_URL]: '',
  [SQUARE_COBRANDING_LOGO_URL]: '',
  [RECT_COBRANDING_LOGO_URL]: '',
  [ORG_OPTIONS]: {
    [CRM_CURRENTLY_SUPPORTS]: NONE,
    [CRM_SKIP_ALLOWED]: false,
    [AUTOMATION_CONNECTIONS]: [],
    [EMAIL_CURRENTLY_SUPPORTS]: BLUEBIRD,
    [SIGN_IN_METHOD_SUPPORTS]: SigninMethodsEnum.All,
    [SOB_ENABLED]: false,
    [DEPARTMENT_BUDGET_ENABLED]: false,
    [AI_GIFT_SUGGESTION_ENABLED]: false,
    [DIGITAL_BALANCE_LIMIT_ENABLED]: false,
    [AUTOMATIONS_ENABLED]: false,
    [DIGITAL_BALANCE_LIMIT_VALUE]: null,
  },
  [ORG_CUSTOMIZATION_OPTIONS]: {
    [EMAIL_LOGO_TYPE]: EMAIL_DEFAULT_LOGO_TYPE,
    [EMAIL_MAIN_COLOR]: EMAIL_DEFAULT_MAIN_COLOR,
    [EMAIL_ACCENT_COLOR]: EMAIL_DEFAULT_ACCENT_COLOR,
    [EMAIL_USE_ORG_LOGO]: EMAIL_DEFAULT_USE_ORG_LOGO,
    [PORTAL_ACCENT_COLOR]: THEME_DEFAULT_ACCENT_COLOR,
    [PORTAL_SIDEBAR_COLOR]: THEME_DEFAULT_SIDEBAR_COLOR,
    [PORTAL_SIDEBAR_TEXT_COLOR]: THEME_DEFAULT_SIDEBAR_TEXT_COLOR,
    [PORTAL_USE_ORG_LOGO]: THEME_DEFAULT_USE_ORG_LOGO,
    [PORTAL_USE_COBRANDING_LOGO]: THEME_DEFAULT_USE_COBRANDING_ORG_LOGO,
    [SHIPPING_PAGE_TITLE]: SHIPPING_PAGE_DEFAULT_TITLE,
    [SHIPPING_PAGE_BODY]: SHIPPING_PAGE_DEFAULT_BODY,
    [PICK_YOUR_GIFT_TITLE]: PICK_YOUR_GIFT_DEFAULT_TITLE,
    [PICK_YOUR_GIFT_BODY]: PICK_YOUR_GIFT_DEFAULT_BODY,
    [MSKU_PAGE_TITLE]: MSKU_PAGE_DEFAULT_TITLE,
    [MSKU_PAGE_BODY]: MSKU_PAGE_DEFAULT_BODY,
    [CUSTOMIZE_ITEMS_PAGE_TITLE]: CUSTOMIZE_ITEMS_PAGE_DEFAULT_TITLE,
    [CUSTOMIZE_ITEMS_PAGE_BODY]: CUSTOMIZE_ITEMS_PAGE_DEFAULT_BODY,
    [EMAIL_CUSTOM_SENDER]: EMAIL_CUSTOM_SENDER_DEFAULT,
  },
  [LOW_INVENTORY_NOTIFICATION_EMAILS]: [],
};

export const ORGANIZATIONS_FILTERS = [
  {
    label: 'All',
    tabId: OrganizationFilterTypesEnum.All,
  },
  {
    label: 'Activated',
    tabId: OrganizationFilterTypesEnum.Activated,
  },
  {
    label: 'Deactivated',
    tabId: OrganizationFilterTypesEnum.Deactivated,
  },
];

export const DEPARTMENT_TABLE_HEADERS = {
  [NAME]: 'Department Name',
  [USED_BUDGET]: 'Budget',
  [AVAILABLE_BUDGET]: 'Limit',
};

export const EMAIL_LOGO_TYPES_OPTION_LABELS: { [key: string]: string } = {
  [EmailLogoTypesEnum.Rectangular]: 'Rectangular logo',
  [EmailLogoTypesEnum.Square]: 'Square logo',
};

export const OrganizationSuccessToastMessages = {
  [URL_VARS.NEW]: 'New organization was added',
  [URL_VARS.EDIT]: 'Organization was edited',
};

export const DEFAULT_SHIPPING_PAGE_CUSTOMIZATION_OPTIONS = (shippingPageUrl: string) => ({
  [DELAYED_SHIPPING_PAGE]: {
    [BUTTON_COLOR]: SHIPPING_PAGE_DEFAULT_BUTTON_COLOR,
    [HEADER_COLOR]: SHIPPING_PAGE_DEFAULT_HEADER_COLOR,
  },
  [DELAYED_SHIPPING_BASE_URL]: shippingPageUrl,
});

export const DEFAULT_EMAIL_CUSTOMIZATION_OPTIONS = {
  [ORG_CUSTOMIZATION_OPTIONS]: {
    [EMAIL_ACCENT_COLOR]: EMAIL_DEFAULT_ACCENT_COLOR,
    [EMAIL_LOGO_TYPE]: EMAIL_DEFAULT_LOGO_TYPE,
    [EMAIL_MAIN_COLOR]: EMAIL_DEFAULT_MAIN_COLOR,
    [EMAIL_USE_ORG_LOGO]: EMAIL_DEFAULT_USE_ORG_LOGO,
  } as IOrganizationCustomizationOptions,
};

export const DEFAULT_PORTAL_CUSTOMIZATION_OPTIONS = {
  [ORG_CUSTOMIZATION_OPTIONS]: {
    [PORTAL_ACCENT_COLOR]: THEME_DEFAULT_ACCENT_COLOR,
    [PORTAL_SIDEBAR_COLOR]: THEME_DEFAULT_SIDEBAR_COLOR,
    [PORTAL_SIDEBAR_TEXT_COLOR]: THEME_DEFAULT_SIDEBAR_TEXT_COLOR,
    [PORTAL_USE_ORG_LOGO]: THEME_DEFAULT_USE_ORG_LOGO,
    [PORTAL_USE_COBRANDING_LOGO]: THEME_DEFAULT_USE_COBRANDING_ORG_LOGO,
  } as IOrganizationCustomizationOptions,
};
