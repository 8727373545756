import cn from 'classnames';
import * as React from 'react';

import { IOneLinkWithCampaignSummaries } from '../../../types/oneLink';
import { LabelWithEditButton } from '../../forms/labels';

import { IOneLinkEngagementCandidate } from '../../../types/bucket';
import styles from './FixedAddress.module.scss';

interface IProps {
  className?: string;
  instance: IOneLinkEngagementCandidate | IOneLinkWithCampaignSummaries;
}

const FixedAddress = ({ className, instance }: IProps) => {
  const { address1, address2, city, country, state, zip } = instance.receiver_fixed_address || {};

  return (
    <div className={cn(styles.container, className)}>
      <LabelWithEditButton text="Fixed address" className={styles.labelContainer} />
      <div className={styles.content}>
        <React.Fragment>
          <span>
            {address1}
            {`${address2 ? `${address2}, ` : ''}`}
          </span>
          <span>
            {`${city} `}
            {`${state ? `${state}, ` : ''}`}
            {`${country} `}
          </span>
          <span>{zip}</span>
        </React.Fragment>
      </div>
      <p className={styles.text}>Gift recipient will not be able to edit the address</p>
    </div>
  );
};

export default FixedAddress;
