import cn from 'classnames';
import { FormikProps } from 'formik';
import values from 'lodash/values';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { OnChangeValue } from 'react-select';

import { NO_DEPARTMENT } from '../../../constants/organizations';
import {
  BODY,
  DEPARTMENT_IDS,
  EMAIL_MESSAGE_REGEX,
  EMAIL_TEMPLATE_TYPES,
  IS_DEFAULT,
  MAX_NEW_NOTE_LENGTH,
  SUBJECT,
  TEMPLATE_TOKENS,
  TEMPLATE_TYPE,
  TITLE,
  TYPE,
} from '../../../constants/templates';
import useModal from '../../../hooks/useModal';
import { selectIsDeptAdmin } from '../../../store/selectors/auth';
import { IMaintenanceFormOutputData, MaintenanceFormStateEnum } from '../../../types/forms';
import { ISelectorValue, KeysOfUnion } from '../../../types/shell';
import {
  EmailTemplateTypesEnum,
  TCollectiveEmailTemplate,
  TCollectiveNoteTemplate,
  TCollectiveTemplateItem,
  TemplateTypesEnum,
} from '../../../types/templates';
import { isTemplateEmail, replaceLineBreaks } from '../../../utils/template';
import { TemplateTagPicker } from '../../index';
import { UnsavedChangesModal } from '../../modals';
import { Checkbox, DepartmentSelect, HiddenInput, Input, Selector, Textarea, TinyEditor } from '../inputs';

import styles from './TemplateForm.module.scss';

interface IProps extends IMaintenanceFormOutputData {
  onChange: (
    name: KeysOfUnion<TCollectiveTemplateItem>,
    value: TemplateTypesEnum | string | number | boolean | undefined,
  ) => void;
  disabled?: boolean;
  form: FormikProps<Partial<TCollectiveTemplateItem>>;
}

const TemplateForm = ({ onChange, disabled, form, mode }: IProps) => {
  const isDeptAdmin = useSelector(selectIsDeptAdmin);
  const {
    openModal: openChangeTemplateTypeModal,
    closeModal: closeChangeTemplateTypeModal,
    Modal: ChangeTemplateTypeModal,
  } = useModal();

  const isAdding = React.useMemo(() => mode === MaintenanceFormStateEnum.Adding, [mode]);

  const isEmailTemplate = React.useMemo(() => isTemplateEmail(form.values.templateType), [form.values.templateType]);

  const getTokenTooltip = React.useCallback((token: string) => {
    switch (token) {
      case TEMPLATE_TOKENS.SenderSignature:
        return (
          <>
            <p>Sender signature can be set in</p>
            <span className={styles.tinyStrongFont}>Profile - Signature Setup</span> page
          </>
        );
      default:
        return;
    }
  }, []);

  const tagList = React.useMemo(() => {
    return values(TEMPLATE_TOKENS)
      .filter((token: string) => {
        const { type, body } = form.values as TCollectiveEmailTemplate;
        const isDelayedShippingToken = token === TEMPLATE_TOKENS.DelayedShippingURLs;
        const isDigitalGiftToken = token === TEMPLATE_TOKENS.DigitalGiftURLs;
        const isSignatureToken = token === TEMPLATE_TOKENS.SenderSignature;

        const isShowEmailSpecialToken =
          isEmailTemplate &&
          // Show tokens for email templates:
          // - common email template tokens
          // - special tokens by email template type.
          // and they are not typed yet
          ((type === EmailTemplateTypesEnum.DELAYED_SHIPPING && isDelayedShippingToken) ||
            (type === EmailTemplateTypesEnum.DIGITAL_GIFT && isDigitalGiftToken) ||
            isSignatureToken) &&
          !body.includes(`[${token}]`);

        return isShowEmailSpecialToken || (!isDelayedShippingToken && !isDigitalGiftToken && !isSignatureToken);
      })
      .map((token: string) => {
        return {
          title: `[${token}]`,
          isRequired: Boolean(`[${token}]`.match(EMAIL_MESSAGE_REGEX)),
          tooltip: getTokenTooltip(token),
        };
      });
  }, [form.values, isEmailTemplate, getTokenTooltip]);

  const getFieldErrors = React.useCallback(
    (fieldName: KeysOfUnion<TCollectiveTemplateItem>) => {
      // Couldn't find a good solution with right types here
      // TODO: Find better solution!
      return (form.touched as any)[fieldName] ? (form.errors as any)[fieldName] : undefined;
    },
    [form],
  );

  const handleOnChange = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      const { name } = e.currentTarget;
      const value = (() => {
        const checked = (e as React.ChangeEvent<HTMLInputElement>).currentTarget.checked;
        const elValue = e.currentTarget.value;

        return [IS_DEFAULT].includes(name) ? checked : elValue;
      })();

      if (!name) {
        console.warn('No field name was specified');
        return;
      }
      if (typeof onChange === 'function') {
        onChange(name as keyof TCollectiveTemplateItem, value);
      }
    },
    [onChange],
  );

  const handleOnSelectChange = React.useCallback(
    (name: KeysOfUnion<TCollectiveTemplateItem>, option: OnChangeValue<ISelectorValue, boolean>) => {
      if (!option) {
        return;
      }

      const { value } = option as ISelectorValue;
      if (typeof onChange === 'function') {
        onChange(name, value);
      }
    },
    [onChange],
  );

  const handleOnTemplateTypeChange = React.useCallback(
    (option: OnChangeValue<ISelectorValue, boolean>) => {
      if (!option) {
        return;
      }
      // Submit initial value
      openChangeTemplateTypeModal({ option });
    },
    [openChangeTemplateTypeModal],
  );

  const formatTemplateTypeValue = React.useCallback((value: any, options: any) => {
    return options.find((type: any) => type.value === `${value}`);
  }, []);

  // The type of the value option should be string. Great!
  const templateTypeOptions = React.useMemo(() => {
    return [
      { label: 'Custom message', value: `${TemplateTypesEnum.Note}` },
      { label: 'Email', value: `${TemplateTypesEnum.Email}` },
    ];
  }, []);

  const getChangeTemplateTypeModalAction = React.useCallback(
    (option: OnChangeValue<ISelectorValue, boolean>) => {
      return [
        { title: 'Cancel change', isOutlined: true },
        { title: 'Change', onClick: () => handleOnSelectChange(TEMPLATE_TYPE, option) },
      ];
    },
    [handleOnSelectChange],
  );

  const changeTemplateTypeModal = React.useMemo(
    () => (
      <ChangeTemplateTypeModal className="common-modal">
        {({ option }: { option: OnChangeValue<ISelectorValue, boolean> }) => (
          <UnsavedChangesModal
            description={
              <React.Fragment>
                <p>Are you sure you want to change template type?</p>
                <p>Your changes will not be saved</p>
              </React.Fragment>
            }
            onClose={closeChangeTemplateTypeModal}
            actions={getChangeTemplateTypeModalAction(option)}
          />
        )}
      </ChangeTemplateTypeModal>
    ),
    [closeChangeTemplateTypeModal, getChangeTemplateTypeModalAction, ChangeTemplateTypeModal],
  );

  return (
    <React.Fragment>
      <form onSubmit={form.handleSubmit} className={styles.templateForm}>
        <div className={styles.mainInformation}>
          <div className={styles.formData}>
            {!isAdding && <label className={styles.typeLabel}>{form.values[TEMPLATE_TYPE]}</label>}
            <div className={cn(styles.inputContainer, styles.departmentContainer)}>
              <span className={styles.label}>Choose Department</span>
              <DepartmentSelect
                isMulti
                isDisabled={isDeptAdmin}
                value={form.values[DEPARTMENT_IDS]}
                defaultValue={NO_DEPARTMENT}
                onChange={handleOnChange}
                onBlur={form.handleBlur}
                error={form.touched[DEPARTMENT_IDS] ? form.errors[DEPARTMENT_IDS] : undefined}
                className={styles.departmentSelector}
                name={DEPARTMENT_IDS}
              />
            </div>
            <div className={styles.information}>
              {isAdding && (
                <div className={styles.inputContainer}>
                  <span className={styles.label}>Template Type</span>
                  <Selector
                    isClearable={false}
                    isMulti={false}
                    isSearchable={false}
                    closeMenuOnSelect
                    placeholder="Select Template"
                    className={styles.input}
                    value={formatTemplateTypeValue(form.values[TEMPLATE_TYPE], templateTypeOptions)}
                    options={templateTypeOptions}
                    name={TEMPLATE_TYPE}
                    onBlur={form.handleBlur}
                    onChange={handleOnTemplateTypeChange}
                  />
                </div>
              )}
              {isEmailTemplate && (
                <div className={styles.inputContainer}>
                  <span className={styles.label}>Email Category</span>
                  <Selector
                    isClearable={false}
                    isMulti={false}
                    isSearchable={false}
                    closeMenuOnSelect
                    placeholder="Email Category"
                    className={styles.input}
                    value={formatTemplateTypeValue(
                      (form.values as TCollectiveEmailTemplate)[TYPE],
                      EMAIL_TEMPLATE_TYPES,
                    )}
                    options={EMAIL_TEMPLATE_TYPES}
                    name={TYPE}
                    onBlur={form.handleBlur}
                    onChange={handleOnSelectChange.bind(null, TYPE)}
                  />
                </div>
              )}
              {isEmailTemplate ? (
                <Input
                  field={form.getFieldProps(SUBJECT)}
                  helperText="Subject"
                  name={SUBJECT}
                  className={styles.inputContainer}
                  placeholder="Subject"
                  disabled={disabled}
                  value={(form.values as TCollectiveEmailTemplate)[SUBJECT]}
                  onChange={handleOnChange}
                  error={getFieldErrors(SUBJECT)}
                  showErrors={false}
                />
              ) : (
                <Input
                  field={form.getFieldProps(TITLE)}
                  helperText="Template name"
                  name={TITLE}
                  className={styles.inputContainer}
                  placeholder="Template name"
                  disabled={disabled}
                  value={(form.values as TCollectiveNoteTemplate)[TITLE]}
                  onChange={handleOnChange}
                  error={getFieldErrors(TITLE)}
                  showErrors={false}
                />
              )}
            </div>
            {isEmailTemplate ? (
              <TinyEditor
                field={form.getFieldProps(BODY)}
                helperText="Template Text"
                changeCallback={handleOnChange}
                containerClassName={styles.editorContainer}
                className={styles.tinyEditor}
                onBlur={form.handleBlur}
              />
            ) : (
              <Textarea
                field={form.getFieldProps(BODY)}
                className={styles.textareaContainer}
                helperText="Template Text"
                name={BODY}
                placeholder="Template Text"
                onBlur={form.handleBlur}
                onChange={handleOnChange}
                value={replaceLineBreaks(form.values[BODY]!)}
                showCounter
                maxCharsCount={MAX_NEW_NOTE_LENGTH}
              />
            )}
            {!isDeptAdmin && (
              <Checkbox
                id={IS_DEFAULT}
                containerClassName={styles.checkbox}
                text="Default Template"
                checked={form.values[IS_DEFAULT]}
                onChange={handleOnChange}
                hint="Default template is set for the whole organization"
              />
            )}
          </div>
          <TemplateTagPicker tags={tagList} className={styles.tagsContainer} label="Available Tags" />
        </div>
        {!isAdding && <HiddenInput name={TEMPLATE_TYPE} value={form.values[TEMPLATE_TYPE]} />}
      </form>
      {changeTemplateTypeModal}
    </React.Fragment>
  );
};

export default TemplateForm;
