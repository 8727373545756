import { useCallback } from 'react';
import { useFirebase } from 'react-redux-firebase';

export default () => {
  const firebase = useFirebase();

  const logout = useCallback(() => firebase.logout(), [firebase]);

  return { logout };
};
