import React from 'react';

import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning-round.svg';
import { OrgAddressFilterTypesEnum } from '../../constants/addressBook';
import { IOrgAddress } from '../../types/addressBook';
import { CompactOrgAddressView } from '../index';

import styles from './AddressList.module.scss';

interface IProps {
  addresses?: IOrgAddress[];
}

export const AddressList: React.FC<IProps> = ({ addresses }) => {
  return (
    <div className={styles.container}>
      {addresses?.length
        ? addresses?.map((address) => {
            const isNotActive = address.status === OrgAddressFilterTypesEnum.NotActive;
            return (
              <div key={address.uid} className={styles.row}>
                {isNotActive && <WarningIcon />}
                <CompactOrgAddressView row={address} />
              </div>
            );
          })
        : null}
    </div>
  );
};

export default AddressList;
