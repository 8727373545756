import * as React from 'react';
import { OnChangeValue } from 'react-select';

import { ReactComponent as CloseIcon } from '../../../assets/images/icon-close-cross.svg';
import { InventoryTypesEnum } from '../../../types/inventories';
import { ISelectorValue } from '../../../types/shell';

import cn from 'classnames';
import ActionButton from '../../forms/buttons/ActionButton/ActionButton';
import { Selector } from '../../forms/inputs';
import ModalActions from '../ModalActions/ModalActions';
import ModalHeader from '../ModalHeader/ModalHeader';

import commonStyles from '../CommonModals/CommonModals.module.scss';
import styles from './SelectOptionModal.module.scss';

interface IProps {
  assetName: string;
  description: string;
  onClose: () => void;
  onSubmit: (value: InventoryTypesEnum) => void;
  actions: ISelectorValue[];
  showCloseButton?: boolean;
}

const SelectOptionModal: React.FC<IProps> = ({
  assetName,
  description,
  onClose,
  onSubmit,
  actions,
  showCloseButton = true,
}: IProps) => {
  const defaultValue = React.useMemo(() => actions[0], [actions]);
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);

  const handleChange = React.useCallback((newValue: OnChangeValue<ISelectorValue, boolean>) => {
    setSelectedValue(newValue as ISelectorValue);
  }, []);

  const handleSubmit = React.useCallback(() => {
    onClose();
    onSubmit(selectedValue.value as InventoryTypesEnum);
  }, [selectedValue]);

  return (
    <div className={cn(commonStyles.container, styles.container)}>
      <ModalHeader
        className={commonStyles.header}
        right={
          showCloseButton && (
            <div className={styles.closeButton}>
              <CloseIcon onClick={onClose} />
            </div>
          )
        }
      />
      <div className={commonStyles.content}>
        <div className={commonStyles.contentHeader}>Choosing {assetName}</div>
        <div className={commonStyles.description}>{description}</div>
      </div>
      <ModalActions className={styles.footer}>
        <Selector
          closeMenuOnSelect
          value={selectedValue}
          options={actions}
          isSearchable={false}
          isClearable={false}
          onChange={handleChange}
          isMulti={false}
          containerClassName={styles.selectOptionContainer}
        />
        <ActionButton title={'Continue'} className={styles.actionButton} onClick={handleSubmit} />
      </ModalActions>
    </div>
  );
};

export default SelectOptionModal;
