import cn from 'classnames';
import * as React from 'react';

import { RedCrossButton } from '../forms';
import { ItemQuantity } from '../index';

import styles from './InventoryThumbItem.module.scss';

export interface IInventoryThumbItemProps {
  title: string;
  imageUrl: string;
  quantity: number;
  itemId: string;
  isRemoveEnabled?: boolean;
  onRemove?: (itemId: string) => void;
  className?: string;
}

const InventoryThumbItem: React.FC<IInventoryThumbItemProps> = ({
  title,
  imageUrl,
  quantity,
  itemId,
  isRemoveEnabled,
  onRemove,
  className,
}) => {
  const handleRemoveItem = React.useCallback(() => {
    if (typeof onRemove === 'function') {
      onRemove(itemId);
    }
  }, [itemId, onRemove]);

  return (
    <div className={cn(styles.itemContainer, className)}>
      <div className={styles.itemImageContainer}>
        <ItemQuantity quantity={quantity} className={styles.quantity} />
        <div className={styles.iconInner}>
          <div className={styles.icon} style={{ backgroundImage: `url("${imageUrl}")` }} title={title} />
        </div>
        {isRemoveEnabled && (
          <RedCrossButton disabled={!onRemove} onClick={handleRemoveItem} className={styles.deleteButton} />
        )}
      </div>
    </div>
  );
};

export default InventoryThumbItem;
