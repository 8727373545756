import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import { ReactComponent as BackIcon } from '../../assets/images/icon-back.svg';
import { ReactComponent as NextIcon } from '../../assets/images/icon-next.svg';
import { Input } from '../forms';

import styles from './Pagination.module.scss';

export interface IPaginationProps {
  onChange?: (page: number) => void;
  page: number;
  totalPages: number;
  disablePrevBtn?: boolean;
  disableNextBtn?: boolean;
  className?: string;
}

const Pagination = ({ onChange, page, totalPages, disablePrevBtn, className }: IPaginationProps) => {
  const [inputPage, setInputPage] = useState(page);

  const handleSetPage = useCallback(
    (pageNumber: number) => {
      if (onChange) {
        onChange(pageNumber);
      }
    },
    [onChange],
  );

  const handlePageInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputPage(+e.target.value <= totalPages ? +e.target.value : totalPages);
    },
    [totalPages],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSetPage(inputPage);
      }
    },
    [handleSetPage, inputPage],
  );

  const handleOnBlur = useCallback(() => {
    if (inputPage !== page) {
      handleSetPage(inputPage);
    }
  }, [handleSetPage, inputPage, page]);

  return (
    <React.Fragment>
      <div className={cn(styles.pagination, className)}>
        <button
          className={cn(styles.prevBtn)}
          onClick={() => handleSetPage(--page)}
          disabled={disablePrevBtn || page <= 1}
        >
          <BackIcon />
        </button>
        <Input
          onChange={handlePageInput}
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
          value={inputPage}
          type="number"
          className={cn(styles.currentPage)}
          inputClassName={styles.currentPageInput}
          max={totalPages}
        />
        <span className={styles.totalPages}>of {totalPages}</span>
        <button
          className={cn(styles.nextBtn)}
          onClick={() => handleSetPage(++page)}
          disabled={(totalPages || 0) <= page}
        >
          <NextIcon />
        </button>
      </div>
    </React.Fragment>
  );
};

export default Pagination;
