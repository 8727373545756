import { FormikProps } from 'formik';
import React, { useMemo } from 'react';

import { DIGITAL, MSKU, TYPE } from '../../../constants/inventories';
import { IMaintenanceFormOutputData, MaintenanceFormStateEnum } from '../../../types/forms';
import { IInventoryItemCandidate } from '../../../types/inventories';
import Digital from './Digital';
import Msku from './MSKU';
import Physical from './Physical';

export interface IProps extends IMaintenanceFormOutputData {
  onChange: (
    name: keyof IInventoryItemCandidate,
    value: string | number | undefined | boolean | IInventoryItemCandidate[],
  ) => void;
  disabled?: boolean;
  form: FormikProps<Partial<IInventoryItemCandidate>>;
  requiredFields: Record<string, boolean>;
}

export interface IRenderInventoryProps extends IProps {
  readOnly: boolean;
  isAdding: boolean;
  onInputChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  getFieldErrors: (fieldName: keyof IInventoryItemCandidate) => string | string[] | undefined;
}

const InventoryItemForm = ({ onChange, form, mode, requiredFields, root }: IProps) => {
  const readOnly = React.useMemo(() => mode === MaintenanceFormStateEnum.Reading, [mode]);
  const isAdding = React.useMemo(() => mode === MaintenanceFormStateEnum.Adding, [mode]);
  const isDigital = React.useMemo(() => form.values[TYPE] === DIGITAL, [form.values[TYPE]]);
  const isMSKU = React.useMemo(() => form.values[TYPE] === MSKU, [form.values[TYPE]]);

  const getFieldErrors = React.useCallback(
    (fieldName: keyof IInventoryItemCandidate) => {
      return form.touched[fieldName] ? (form.errors[fieldName] as string | string[]) : undefined;
    },
    [form.touched, form.errors],
  );

  const handleChange = React.useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
      const { name, value } = e.currentTarget;
      if (!name) {
        console.warn('No field name was specified');
        return;
      }
      if (typeof onChange === 'function') {
        return onChange(name as keyof IInventoryItemCandidate, value);
      }
    },
    [onChange],
  );

  const ItemForm = useMemo(() => {
    switch (true) {
      case isMSKU:
        return Msku;
      case isDigital:
        return Digital;
      default:
        return Physical;
    }
  }, [isDigital, isMSKU]);

  return (
    <ItemForm
      readOnly={readOnly}
      isAdding={isAdding}
      onInputChange={handleChange}
      getFieldErrors={getFieldErrors}
      onChange={onChange}
      form={form}
      requiredFields={requiredFields}
      mode={mode}
      root={root}
    />
  );
};

export default InventoryItemForm;
