import * as React from 'react';

import { CampaignItemsForm } from '../../../components/forms';
import { ICampaignCandidate } from '../../../types/campaigns';
import { ICampaignFormProps } from '../../../types/forms';

const EditCampaignItemsComponent = (props: ICampaignFormProps<ICampaignCandidate>) => {
  return <CampaignItemsForm {...props} />;
};

export default EditCampaignItemsComponent;
