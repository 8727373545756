import cn from 'classnames';
import * as React from 'react';

import styles from './ModalHeader.module.scss';

interface IProps {
  className?: string;
  titleClassName?: string;
  title?: string;
  left?: React.ReactNode | React.ReactNode[] | string;
  right?: React.ReactNode | React.ReactNode[] | string;
  children?: React.ReactNode | React.ReactNode[] | string;
}

const ModalHeader = ({ left, children, className, titleClassName, right, title }: IProps) => {
  return (
    <div className={cn(styles.container, className)}>
      {left && <div className={styles.left}>{left}</div>}
      <span className={cn(styles.title, titleClassName)}>{title || children}</span>
      {right && <div className={styles.right}>{right}</div>}
    </div>
  );
};

export default ModalHeader;
