import * as React from 'react';

import { DisplayCurrency } from '../index';

import styles from './DisplayCurrencyMenuItem.module.scss';

const DisplayCurrencyMenuItem = () => {
  return (
    <div className={styles.container}>
      <DisplayCurrency />
    </div>
  );
};

export default DisplayCurrencyMenuItem;
