import { Reducer, useCallback, useMemo, useReducer } from 'react';

import { FeatureBudgetEnum } from '../types/shell';

enum ActionTypes {
  setWarning,
  success,
  failure,
}

type State = {
  [key in FeatureBudgetEnum]: boolean;
};

interface IAction {
  type: ActionTypes;
  payload: {
    name: FeatureBudgetEnum;
    value: boolean;
  };
}

const reducer = (state: State, action: IAction) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.setWarning:
      const { name, value } = payload;

      return {
        ...state,
        [name]: value,
      };
    default:
      return state;
  }
};

const initialState: State = {
  digital: false,
  department: false,
};

const useFeatureBudgetErrors = () => {
  const [{ digital, department }, dispatchReducer] = useReducer<Reducer<State, IAction>>(reducer, initialState);

  const setFeatureBudgetWarning = useCallback((name: FeatureBudgetEnum, value: boolean) => {
    dispatchReducer({
      type: ActionTypes.setWarning,
      payload: {
        name,
        value,
      },
    });
  }, []);

  const hasWarnings = useMemo(() => digital || department, [digital, department]);

  return {
    setFeatureBudgetWarning,
    hasWarnings,
  };
};

export default useFeatureBudgetErrors;
