import { NO_FULFILLMENT_CENTER_ITEM } from '../../constants/fulfillmentCenters';
import { IFetchFulfillmentCentersResponse, IFulfillmentCentersState } from '../../types/fulfillmentCenters';
import * as AuthActions from '../actions/auth';
import * as FulfillmentCentersActions from '../actions/fulfillmentCenters';

const initialState: IFulfillmentCentersState = {
  items: null,
  isLoading: false,
  error: null,
};

const reducer = (state: IFulfillmentCentersState = initialState, action: any): IFulfillmentCentersState => {
  switch (action.type) {
    case FulfillmentCentersActions.FETCH_FULFILLMENT_CENTERS_REQUEST: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case FulfillmentCentersActions.FETCH_FULFILLMENT_CENTERS_SUCCESS: {
      const { fulfillment_centers: items } = action.payload as IFetchFulfillmentCentersResponse;

      return {
        ...state,
        items: [...(items ? items : []), NO_FULFILLMENT_CENTER_ITEM],
        isLoading: false,
      };
    }
    case FulfillmentCentersActions.FETCH_FULFILLMENT_CENTERS_FAILURE: {
      return {
        ...state,
        items: null,
        error: action.payload,
        isLoading: false,
      };
    }
    case AuthActions.ADMIN_ROLE_CHANGED: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
