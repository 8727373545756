import { CellProps } from 'react-table';

import { EDITABLE_TABLE_MAX_ROWS_COUNT } from '../constants/shell';
import { EditableTableModes } from '../types/forms';
import { IEditableCellProps, ITableConfig, TPlaceholderCell } from '../types/tables';

export const getOriginal = <T extends object>(cellData: CellProps<T, unknown>) => cellData.row.original;

export const getCustomProps = <T extends object, V>(cellData: CellProps<T, V>): IEditableCellProps<T> | undefined =>
  cellData.getCustomProps ? cellData.getCustomProps() : undefined;

export const getColumnId = <T extends object>(cellData: CellProps<T, unknown>): string => cellData.column.id;

export const getColumnKeys = <T>({ config }: { config?: ITableConfig<T> }): Set<keyof T> => {
  const result: Set<keyof T> = new Set();

  config?.columns?.visible?.forEach((key) => {
    result.add(key as keyof T);
  });

  return result;
};

export const getPlaceholderItems = <T>({
  items,
  mode,
  config,
}: {
  items?: T[];
  mode?: EditableTableModes;
  config?: ITableConfig<T>;
}) => {
  const columnKeys = getColumnKeys({ config });

  const count = (() => {
    let result = EDITABLE_TABLE_MAX_ROWS_COUNT;

    if (Array.isArray(items)) {
      result = items.length < EDITABLE_TABLE_MAX_ROWS_COUNT ? EDITABLE_TABLE_MAX_ROWS_COUNT - items.length : 0;
    }

    return mode === EditableTableModes.Add ? --result : result;
  })();

  if (count > 0) {
    const dummyItem: TPlaceholderCell<T> = {} as TPlaceholderCell<T>;

    Array.from(columnKeys).forEach((key) => {
      dummyItem[key] = null;
    });

    return new Array<TPlaceholderCell<T>>(count).fill(dummyItem);
  }

  return [];
};

export const isPlaceholderRow = <T>(item: T) =>
  Object.values(item).every((val: string | number | boolean | null | undefined) => val === null);
