import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as PreorderIcon } from '../../assets/images/icon-preorder-allowed.svg';
import useWindowSize from '../../hooks/useWindowSize';
import { IInventoryItem } from '../../types/inventories';
import { isItemOutOfStock } from '../../utils/inventories';
import { FCDetails, InventoryItemOptions, ItemCountWithWarning } from '../index';

import styles from './MskuNestedItem.module.scss';

interface IProps {
  item: IInventoryItem;
  isHighlighted?: boolean;
}

const MskuNestedItem: React.FC<IProps> = ({ item, isHighlighted }: IProps) => {
  const {
    name,
    image_url,
    size,
    color,
    flavor,
    gender,
    other_option,
    count,
    item_id,
    product_sku,
    fulfillment_center_id,
    item_customization_allowed: isCustomizable,
    preorder_allowed: isPreorderAllowed,
    type,
  } = item;
  const { mobile } = useWindowSize();

  return (
    <div className={styles.itemContainer}>
      {isPreorderAllowed ? (
        <PreorderIcon className={cn(styles.count, styles.preorderIcon)} />
      ) : (
        <ItemCountWithWarning
          type={type}
          className={cn(styles.count, styles.itemCountContainer)}
          count={count}
          id={item_id}
          isPreorderAllowed={isPreorderAllowed}
        />
      )}
      <div className={cn(styles.itemImageContainer, { [styles.disabled]: isItemOutOfStock(item) })}>
        <div className={styles.iconInner}>
          <div className={styles.icon} style={{ backgroundImage: `url("${image_url}")` }} />
        </div>
      </div>
      <div className={styles.itemDetailsContainer}>
        <div className={cn(styles.itemInfoContainer, { [styles.disabled]: isItemOutOfStock(item) })}>
          <div className={styles.nameGroup}>
            {isPreorderAllowed && <p className={styles.preorderTitle}>Preorder</p>}
            <h3 className={styles.itemName}>{name}</h3>
          </div>
          {mobile && (
            <InventoryItemOptions
              size={size}
              color={color}
              otherOption={other_option}
              flavor={flavor}
              gender={gender}
              isCustomizable={isCustomizable}
            />
          )}
        </div>
        {!mobile && (
          <InventoryItemOptions
            size={size}
            color={color}
            otherOption={other_option}
            flavor={flavor}
            gender={gender}
            isCustomizable={isCustomizable}
          />
        )}
      </div>
      <FCDetails
        className={styles.nestedItem}
        isHighlighted={isHighlighted}
        isDisabled={isItemOutOfStock(item)}
        productSku={product_sku}
        fulfillmentCenterId={fulfillment_center_id}
      />
    </div>
  );
};

export default MskuNestedItem;
