import cn from 'classnames';
import * as React from 'react';

import styles from './ModalActions.module.scss';

interface IProps {
  className?: string;
  actions?: React.ReactNode[] | React.ReactNode;
  children?: React.ReactNode[] | React.ReactNode | string;
}

export default ({ actions, children, className }: IProps) => {
  const renderActions = React.useMemo(() => {
    if (typeof actions !== 'undefined') {
      return Array.isArray(actions) ? actions.map((action) => action) : actions;
    }
    return children;
  }, [actions, children]);

  return <div className={cn(styles.container, className)}>{renderActions}</div>;
};
