import cn from 'classnames';
import * as React from 'react';
import AddCustomSendIcon from '../../assets/images/icon-big-fat-black-plus.svg';
import { BUTTON_BUTTON } from '../../types/forms';

import styles from './BoxItem.module.scss';

interface IProps {
  className?: string;
  onClick: () => void;
}

const CustomSendItem = ({ className, onClick }: IProps) => {
  return (
    <div className={cn(styles.boxItem, styles.customBoxBtn, className)}>
      <button type={BUTTON_BUTTON} onClick={onClick}>
        <div className={styles.iconInner}>
          <div className={styles.customSendIcon} style={{ backgroundImage: `url(${AddCustomSendIcon})` }} />
        </div>
        <div className={styles.info}>
          <span className={styles.title}>Custom Send</span>
          <div className={styles.priceWithControls}>
            <div className={styles.meta}>
              <span className={styles.description}>Make unique send from scratch</span>
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};

export default CustomSendItem;
