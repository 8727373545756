import { FormikHelpers, useFormik } from 'formik';
import { useCallback, useEffect } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionButton, FixedAddressForm, OrgAddressOption, Selector } from '../../components';
import { USA } from '../../constants/countries';
import {
  COUNTRY,
  FIXED_ADDRESS_VALIDATION_SCHEMA as validationSchema,
  INITIAL_FIXED_ADDRESS_FORM_STATE,
  STATE,
} from '../../constants/shipping';
import { setOneLinkFixedAddress } from '../../store/actions/oneLink';
import { selectOneLinkReceiverFixedAddress } from '../../store/selectors/bucket';
import { IOrgAddress } from '../../types/addressBook';
import { IOneLinkReceiverFixedAddress } from '../../types/oneLink';
import { ISelectorValue } from '../../types/shell';
import { getRequiredFields } from '../../utils/form';
import { isObjectsEqual } from '../../utils/helpers';
import { getOneLinkFixedAddressFields } from '../../utils/oneLink';
import { ReceiverFixedAddressSidebar } from '../index';

import styles from './ReceiverFixedAddressSidebarContainer.module.scss';

interface IProps {
  onClose: () => void | undefined;
  trigger: boolean;
  addressBook?: IOrgAddress[] | null;
}

const ReceiverFixedAddressSidebarContainer: React.FC<IProps> = ({ onClose, trigger, addressBook }) => {
  const dispatch = useDispatch();
  const fixedAddress = useSelector(selectOneLinkReceiverFixedAddress);
  const [selectedAddressUID, setSelectedAddressUID] = React.useState<string | null>(null);

  const initialValues = React.useMemo(() => {
    if (!fixedAddress || Object.keys(fixedAddress).length === 0) {
      return INITIAL_FIXED_ADDRESS_FORM_STATE;
    }

    return {
      ...INITIAL_FIXED_ADDRESS_FORM_STATE,
      ...getOneLinkFixedAddressFields(fixedAddress),
    };
  }, [fixedAddress]);

  const handleSubmit = React.useCallback(
    (
      values: Partial<IOneLinkReceiverFixedAddress>,
      { resetForm }: FormikHelpers<Partial<IOneLinkReceiverFixedAddress>>,
    ) => {
      if (!validationSchema.isValidSync(values)) {
        return;
      }

      dispatch(setOneLinkFixedAddress(values));
      resetForm();
      onClose();
    },
    [dispatch, validationSchema, onClose],
  );

  const form = useFormik<Partial<IOneLinkReceiverFixedAddress>>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const requiredFields = React.useMemo(
    () => getRequiredFields(validationSchema, form.values[COUNTRY] === USA.two_digit_code ? [STATE] : []),
    [form.values[COUNTRY]],
  );

  const selectedAddress = React.useMemo(() => {
    const address = addressBook?.find((a) => a.uid === selectedAddressUID);

    return address ? { value: address.uid, label: address.label, address } : undefined;
  }, [addressBook, selectedAddressUID]);

  const handleSelectAddress = useCallback(
    (v: ISelectorValue) => {
      setSelectedAddressUID(v.value);
      const candidate = getOneLinkFixedAddressFields(addressBook?.find((a) => a.uid === v.value));
      if (candidate) {
        form.setValues(candidate);
      }
    },
    [addressBook, form.setValues],
  );

  const selectorOptions = React.useMemo(() => {
    if (!addressBook || !addressBook.length) {
      return null;
    }

    return addressBook.map((address) => {
      const { label, uid } = address;

      return {
        value: uid,
        label,
        address,
      };
    });
  }, [addressBook]);

  useEffect(() => {
    if ((addressBook?.length ?? 0) > 0 && selectedAddressUID) {
      const address = addressBook?.find((a) => a.uid === selectedAddressUID);

      if (address && !isObjectsEqual(form.values, getOneLinkFixedAddressFields(address))) {
        setSelectedAddressUID(null);
      }
    }
  }, [selectedAddressUID, addressBook, form.values]);

  return (
    <ReceiverFixedAddressSidebar trigger={trigger} onClose={onClose}>
      {addressBook && addressBook.length > 0 && (
        <Selector
          className={styles.addressSelect}
          value={selectedAddress}
          isClearable={false}
          onChange={(v) => handleSelectAddress(v as ISelectorValue)}
          closeMenuOnSelect
          options={selectorOptions || []}
          customOption={OrgAddressOption}
        />
      )}
      <FixedAddressForm form={form} requiredFields={requiredFields} />
      <div className={styles.controls}>
        <ActionButton className={styles.cancelButton} onClick={onClose}>
          Cancel
        </ActionButton>
        <ActionButton disabled={form.isSubmitting} type="submit" title="Continue" onClick={form.handleSubmit} />
      </div>
    </ReceiverFixedAddressSidebar>
  );
};

export default ReceiverFixedAddressSidebarContainer;
