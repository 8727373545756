import cn from 'classnames';
import React from 'react';

import { Drawer } from '../../components';
import useWindowSize from '../../hooks/useWindowSize';
import { DrawerAnimationDirectionEnum } from '../../types/shell';

import variables from '../../_variables.scss';
import styles from './OrgAddressSidebar.module.scss';

interface IProps {
  onClose: () => void;
  trigger: boolean;
  children?: React.ReactNode;
}

const OrgAddressSidebar: React.FC<IProps> = ({ trigger, onClose, children }) => {
  const { mobile, HD } = useWindowSize();

  const from = React.useMemo(() => (mobile ? variables.orgSidebarMinHeight : variables.orgSidebarMinWidth), [mobile]);

  const to = React.useMemo(() => {
    if (mobile) {
      return variables.orgSidebarMaxHeight;
    }

    if (HD) {
      return variables.orgSidebarMaxWidthHD;
    }

    return variables.orgSidebarMaxWidthFullHD;
  }, [mobile, HD]);

  return (
    <Drawer
      className={cn(styles.container)}
      direction={mobile ? DrawerAnimationDirectionEnum.Vertically : DrawerAnimationDirectionEnum.Horizontally}
      from={from}
      to={to}
      trigger={trigger}
      onClose={onClose}
      withOverlay
      overlayClassName={styles.overlay}
    >
      <div className={styles.wrapper}>
        <button className={styles.closeBtn} onClick={onClose} />
        {children}
      </div>
    </Drawer>
  );
};

export default OrgAddressSidebar;
