import cn from 'classnames';
import { KeyboardEvent } from 'react';
import * as React from 'react';
import { Cell, Column, HeaderGroup, Row, useTable } from 'react-table';
import { v4 as uuidv4 } from 'uuid';

import FadeInOut from '../../animations/FadeInOut';
import { ReactComponent as CheckIcon } from '../../assets/images/icon-success-check.svg';

import { CONTROLS } from '../../constants/shell';
import useWindowSize from '../../hooks/useWindowSize';
import { EditableTableModes } from '../../types/forms';
import { IEditableCellProps } from '../../types/tables';
import { isObjectsEqual } from '../../utils/helpers';
import { getColumnKeys, getPlaceholderItems, isPlaceholderRow } from '../../utils/tables';
import { InputLabel } from '../forms';
import { NoResultsPlaceholder } from '../index';
import CellControls from './CellControls/CellControls';
import EditableCell from './EditableCell/EditableCell';
import HeaderControls from './HeaderControls/HeaderControls';

import styles from './EditableTable.module.scss';

interface IProps<DataType> extends IEditableCellProps<DataType> {
  listClassName?: string;
  headerClassName?: string;
  rowClassName?: string;
  className?: string;
  items?: DataType[];
  placeholderLabel?: string | React.ReactNode;
  customSavedMessage?: string;
}

const EditableTable = <TableDataType extends object>({
  className,
  items,
  listClassName,
  headerClassName,
  rowClassName,
  activeItem,
  onChange,
  isLoading,
  onAdd,
  onEdit,
  onSave,
  onCancel,
  onDelete,
  readOnly,
  mode,
  isChanged = true,
  allowEmptyInputs = false,
  showControlAsIcons,
  config,
  checkEditMode,
  checkIsSubmitted,
  placeholderLabel,
  customSavedMessage,
}: IProps<TableDataType>) => {
  const { mobile, HD } = useWindowSize();

  const [lastSavedItem, setLastSavedItem] = React.useState<TableDataType | null>(null);

  const handleSave = React.useCallback(
    (item: TableDataType) => onSave(item).then((savedItem) => (savedItem ? setLastSavedItem(savedItem) : void 0)),
    [onSave],
  );

  const renderSuccessMessage = React.useCallback(
    (item: TableDataType) => {
      const message = HD ? 'Saved' : 'Changes are saved';

      return (
        <FadeInOut isOpen={isObjectsEqual(lastSavedItem, item)} className={cn(styles.savedMessage)}>
          <CheckIcon /> {customSavedMessage || message}
        </FadeInOut>
      );
    },
    [lastSavedItem, customSavedMessage, HD],
  );

  const handleInputKeyPress = React.useCallback(
    (e: KeyboardEvent<HTMLInputElement>, item: TableDataType) => {
      if (e.key === 'Enter' && isChanged) {
        handleSave(item);
      }
    },
    [handleSave, isChanged],
  );

  const { tableData, columns } = React.useMemo(() => {
    const columnKeys = getColumnKeys({ config });
    const placeholderItems = getPlaceholderItems({ items, mode, config });

    const tableColumns: Column<TableDataType>[] = Array.from(columnKeys).map((accessor: keyof TableDataType) => ({
      Header: config?.columns?.headers?.[accessor] || '',
      Cell: EditableCell,
      accessor,
    })) as Column<TableDataType>[];

    if (!readOnly) {
      tableColumns.push({
        Header: HeaderControls,
        Cell: CellControls,
        accessor: CONTROLS,
      } as Column<TableDataType>);
    }

    const itemsWithDummies = [...(items || []), ...(placeholderItems || [])];

    return {
      tableData:
        mode === EditableTableModes.Add && activeItem ? [activeItem, ...itemsWithDummies] : itemsWithDummies || [],
      columns: tableColumns,
    };
  }, [items, mode, config, activeItem, readOnly]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable<TableDataType>({
    data: tableData as TableDataType[],
    columns,
    // Don't change the name `getCustomProps`, or change it in ~/utils/tables.ts as well
    getCustomProps() {
      return {
        isChanged,
        allowEmptyInputs,
        showControlAsIcons,
        isLoading,
        onEdit,
        onSave: handleSave,
        onAdd,
        onCancel,
        activeItem,
        onChange,
        mode,
        onDelete,
        readOnly,
        checkEditMode,
        checkIsSubmitted,
        renderSuccessMessage,
        config,
        onKeyPress: handleInputKeyPress,
      };
    },
  });

  React.useEffect(() => {
    if (lastSavedItem) {
      setTimeout(() => {
        setLastSavedItem(null);
      }, 2000);
    }
  }, [lastSavedItem]);

  const renderCell = React.useCallback(
    (cell: Cell<TableDataType>) => {
      if (mobile && cell.column.id !== CONTROLS && cell.column.Header) {
        return (
          <div key={cell.getCellProps().key} className={styles.cellWithHeader}>
            {typeof cell.column.Header === 'string' && cell.column.Header ? (
              <InputLabel value={cell.column.Header} />
            ) : null}
            {cell.render('Cell')}
          </div>
        );
      }

      return <React.Fragment key={cell.getCellProps().key}>{cell.render('Cell')}</React.Fragment>;
    },
    [mobile],
  );

  const renderRow = React.useCallback(
    (row: Row<TableDataType>) => {
      prepareRow(row);
      const { key: rowKey, ...restRowProps } = row.getRowProps();

      const predicate = config?.rows?.find(({ predicateFn }) => predicateFn?.(row.original));

      if (predicate && predicate.render) {
        return predicate.render?.(row.original);
      }

      return !isPlaceholderRow(row.original) ? (
        <div key={rowKey} className={cn(styles.row, rowClassName)} {...restRowProps}>
          {row.cells.map(renderCell)}
        </div>
      ) : (
        <div key={uuidv4()} className={styles.rowPlaceholder} />
      );
    },
    [prepareRow, renderCell, rowClassName, config],
  );

  return (
    <div className={cn(className, styles.editableTable)} {...getTableProps()}>
      {!mobile
        ? headerGroups.map((headerGroup: HeaderGroup<TableDataType>) => {
            const { key: headerGroupKey, ...restHeaderGroup } = headerGroup.getHeaderGroupProps();
            return (
              <div key={headerGroupKey} className={cn(styles.head, headerClassName)} {...restHeaderGroup}>
                {headerGroup.headers.map((column: HeaderGroup<TableDataType>) => {
                  return <div key={column.getHeaderProps().key}>{column.render('Header')}</div>;
                })}
              </div>
            );
          })
        : null}
      {mode !== EditableTableModes.Add && !items?.length ? (
        placeholderLabel && (
          <NoResultsPlaceholder
            label={placeholderLabel as string}
            className={styles.placeholder}
            labelClassName={styles.placeholderTitle}
            imageClassName={styles.imgPlaceholder}
          />
        )
      ) : (
        <div className={cn(styles.list, listClassName)} {...getTableBodyProps()}>
          {rows.map(renderRow)}
        </div>
      )}
    </div>
  );
};

export default EditableTable;
