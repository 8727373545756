import { createTransform } from 'redux-persist';

import { IAddressBookState } from '../../types/addressBook';

const addressBookTransform = createTransform(
  (stateIn: IAddressBookState) => ({
    ...stateIn,
    items: null,
    error: null,
    isLoading: false,
  }),
  (stateOut: IAddressBookState) => ({
    ...stateOut,
    items: null,
    error: null,
    isLoading: false,
  }),
  { whitelist: ['addressBook'] },
);

const transformArray = [addressBookTransform];

export default transformArray;
