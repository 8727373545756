import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { IAuthenticateRequestPayload, IAuthorizeRequest, IAuthResponse } from '../../types/auth';

export const AUTHENTICATE_REQUEST = 'AUTHENTICATE_REQUEST';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILURE = 'AUTHENTICATE_FAILURE';

export const AUTHORIZE_USER_REQUEST = 'AUTHORIZE_USER_REQUEST';
export const AUTHORIZE_USER_SUCCESS = 'AUTHORIZE_USER_SUCCESS';
export const AUTHORIZE_USER_FAILURE = 'AUTHORIZE_USER_FAILURE';

export const FETCH_ORGANIZATIONS_REQUEST = 'FETCH_ORGANIZATIONS_REQUEST';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATIONS_FAILURE = 'FETCH_ORGANIZATIONS_FAILURE';

export const CHANGE_ORGANIZATION_REQUEST = 'CHANGE_ORGANIZATION_REQUEST';
export const CHANGE_ORGANIZATION_SUCCESS = 'CHANGE_ORGANIZATION_SUCCESS';
export const CHANGE_ORGANIZATION_FAILURE = 'CHANGE_ORGANIZATION_FAILURE';

export const GET_DIGITAL_BUDGET_REQUEST = 'GET_DIGITAL_BUDGET_REQUEST';
export const GET_DIGITAL_BUDGET_SUCCESS = 'GET_DIGITAL_BUDGET_SUCCESS';
export const GET_DIGITAL_BUDGET_FAILURE = 'GET_DIGITAL_BUDGET_FAILURE';

export const ADMIN_ROLE_CHANGED = 'ADMIN_ROLE_CHANGED';

export const authenticateRequest = (payload?: IAuthenticateRequestPayload) => ({
  type: AUTHENTICATE_REQUEST,
  payload,
});

export const authenticateSuccess = (payload?: any) => ({
  type: AUTHENTICATE_SUCCESS,
  payload,
});

export const authenticateFailure = (payload?: any) => ({
  type: AUTHENTICATE_FAILURE,
  payload,
});

export const authorizeUserRequest = (payload?: IAuthorizeRequest) => ({
  type: AUTHORIZE_USER_REQUEST,
  payload,
});

export const authorizeUserSuccess = (payload: IAuthResponse) => ({
  type: AUTHORIZE_USER_SUCCESS,
  payload,
});

export const authorizeUserFailure = (payload: any) => ({
  type: AUTHORIZE_USER_FAILURE,
  payload,
});

export const firebaseProfileUpdate = (profile: any) => ({
  type: FirebaseActions.SET_PROFILE,
  profile,
});

export const changeOrganizationsRequest = (payload: { org_id: string; resolve: any; reject: any }) => ({
  type: CHANGE_ORGANIZATION_REQUEST,
  payload,
});

export const changeOrganizationsSuccess = () => ({
  type: CHANGE_ORGANIZATION_SUCCESS,
});

export const changeOrganizationsFailure = (payload: any) => ({
  type: CHANGE_ORGANIZATION_FAILURE,
  payload,
});

export const getDigitalBudgetRequest = () => ({
  type: GET_DIGITAL_BUDGET_REQUEST,
});

export const getDigitalBudgetSuccess = (payload: string) => ({
  type: GET_DIGITAL_BUDGET_SUCCESS,
  payload,
});

export const getDigitalBudgetFailure = (payload: any) => ({
  type: GET_DIGITAL_BUDGET_FAILURE,
  payload,
});

export const adminRoleChanged = () => ({
  type: ADMIN_ROLE_CHANGED,
});
