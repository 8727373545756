import cn from 'classnames';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as BookIcon } from '../../assets/images/icon-book.svg';
import { ReactComponent as InformationIcon } from '../../assets/images/icon-information.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning-round.svg';

import {
  ActionButton,
  AddressBookSidebar,
  AddressList,
  InfoMessage,
  Selector,
  SingleAddress,
  Toggle,
} from '../../components';
import { OrgAddressFilterTypesEnum, ShippingFlowSelectorOptionsEnum } from '../../constants/addressBook';
import { CUSTOM } from '../../constants/campaigns';
import { AddressBookSidebarContainer } from '../../containers';
import { fetchAddressBookRequest } from '../../store/actions/addressBook';
import { selectAddressBook } from '../../store/selectors/addressBook';

import { ISelectorValue, UISizeEnum } from '../../types/shell';
import { IShippingConfiguration } from '../../types/shipping';
import { isObjectEmpty, isObjectsEqual } from '../../utils/helpers';

import { changeAB, changeDS, changeSelect, getInitialState, IState, reducer, reset, TAction } from './reducer';

import styles from './ShippingDetailsContainer.module.scss';

interface IProps {
  value?: Partial<IShippingConfiguration>;
  onChange?: (cfg?: Partial<IShippingConfiguration>) => void;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

const ShippingDetailsContainer: React.FC<IProps> = ({ isDisabled, isReadOnly, value, onChange }) => {
  const reduxDispatch = useDispatch();
  const initialState = useMemo(() => getInitialState(value), [value]);

  const [{ isDelayedShipping, isAddressFixed, addresses, selectorValue }, dispatch] = React.useReducer<
    React.Reducer<IState, TAction>
  >(reducer, initialState);

  const [isSidebarShown, setSidebarShown] = React.useState(false);
  const orgAddresses = useSelector(selectAddressBook);

  const currentValue = useMemo(() => {
    const v = {
      is_delayed_shipping: isDelayedShipping,
      is_receiver_address_fixed: isAddressFixed,
      org_address_ids: addresses,
    };

    return !isObjectEmpty(v) ? v : undefined;
  }, [isDelayedShipping, isAddressFixed, addresses]);

  // Sync effect - syncs reducer state and values outside via onChange
  React.useEffect(() => {
    switch (true) {
      case !value && !!currentValue:
        dispatch(reset());
        break;
      case !isObjectsEqual(value, currentValue) && !!currentValue:
        onChange?.(currentValue);
        break;
      default:
        break;
    }
  }, [value, currentValue, onChange]);

  const options = useMemo(() => {
    return [
      ...(isDelayedShipping
        ? [
            {
              value: ShippingFlowSelectorOptionsEnum.RecipientDecides,
              label: 'Recipient decides',
            },
            {
              value: ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList,
              label: 'Recipient decides from list only',
            },
          ]
        : []),
      {
        value: ShippingFlowSelectorOptionsEnum.Fixed,
        label: 'Fixed',
      },
    ];
  }, [isDelayedShipping]);

  const handleSelectInputChange = React.useCallback(({ value: selected }: ISelectorValue) => {
    dispatch(changeSelect(selected as ShippingFlowSelectorOptionsEnum));
  }, []);

  const handleCloseSidebar = React.useCallback(() => {
    setSidebarShown(false);
  }, []);

  const selectedOption = useMemo(
    () => options.find((option) => option.value === selectorValue),
    [options, selectorValue],
  );

  const hasNonActiveAddresses = useMemo(() => {
    return addresses?.some((address) => {
      const orgAddress = orgAddresses?.find((orgAdr) => orgAdr.uid === address);
      return orgAddress?.status === OrgAddressFilterTypesEnum.NotActive;
    });
  }, [addresses, orgAddresses]);

  const addressesAdded = useMemo(() => addresses?.filter((a) => a !== CUSTOM), [addresses]);

  const fixedAddress = useMemo(() => selectorValue === ShippingFlowSelectorOptionsEnum.Fixed, [selectorValue]);

  const infoMessage = useMemo(() => {
    const recipientDecides = selectorValue === ShippingFlowSelectorOptionsEnum.RecipientDecides;
    const recipientDecidesFromList =
      selectorValue === ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList && addresses && addresses.length >= 2;

    const notEnoughAddressesSelected =
      selectorValue === ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList &&
      (!addresses || addresses?.length < 2);

    switch (true) {
      case hasNonActiveAddresses:
        return (
          <InfoMessage icon={<WarningIcon />} text={'Please remove inactive addresses.'} className={styles.warning} />
        );
      case recipientDecides:
        return (
          <InfoMessage
            icon={<InformationIcon />}
            text={'Recipient will have an option to enter address manually or choose one of the selected addresses.'}
          />
        );
      case notEnoughAddressesSelected:
        return (
          <InfoMessage
            icon={<WarningIcon />}
            text={'Please select at least two addresses to proceed.'}
            className={styles.warning}
          />
        );
      case recipientDecidesFromList:
        return (
          <InfoMessage
            icon={<InformationIcon />}
            text={'Recipient will have an option to choose one of the selected addresses.'}
          />
        );
      case fixedAddress:
        return (
          <InfoMessage
            icon={<InformationIcon />}
            text={"The package will be delivered to the specified address. The recipient won't be able to change it."}
          />
        );
      default:
        return null;
    }
  }, [hasNonActiveAddresses, selectorValue, addresses, fixedAddress]);

  useEffect(() => {
    reduxDispatch(fetchAddressBookRequest());
  }, [dispatch]);

  const AddressBookValue = React.useMemo(() => {
    const shouldShowSingleAddress = fixedAddress && !!addressesAdded;

    switch (true) {
      case !addressesAdded:
        return null;
      case shouldShowSingleAddress:
        const fixedAddressItem = orgAddresses?.find((address) => address.uid === addressesAdded?.[0]);
        return <SingleAddress address={fixedAddressItem} />;
      case !shouldShowSingleAddress:
        const list = orgAddresses?.filter((address) => addressesAdded?.includes(address.uid)).map((address) => address);
        if (!addresses?.length) {
          return null;
        }

        return <AddressList addresses={list} />;
      default:
        return null;
    }
  }, [addresses?.length, addressesAdded, orgAddresses, fixedAddress]);

  return (
    <div className={cn(styles.container, { [styles.disabled]: isDisabled })}>
      <h2 className={styles.title}>Shipping</h2>
      <Toggle
        className={styles.toggle}
        sliderClassName={styles.toggleSlider}
        onChange={!isReadOnly ? (e) => dispatch(changeDS(e.currentTarget.checked)) : undefined}
        value={isDelayedShipping}
        checked={isDelayedShipping}
        defaultValue={false}
      >
        Claim Link
      </Toggle>
      <Selector
        className={styles.selector}
        helperText="Address Options"
        options={options}
        value={selectedOption}
        onChange={(v) => handleSelectInputChange(v as ISelectorValue)}
        isClearable={false}
        isSearchable={false}
        closeMenuOnSelect
        readOnly={isReadOnly}
      />
      {!isDisabled && !isReadOnly && (
        <ActionButton
          className={styles.addressBookBtn}
          icon={<BookIcon />}
          outlined
          title={`${addressesAdded?.length ? 'Edit' : 'Select'} Address Book Option${fixedAddress ? '' : 's'}`}
          size={UISizeEnum.Normal}
          onClick={() => setSidebarShown(true)}
        />
      )}
      {!isDisabled && (
        <>
          {AddressBookValue}
          {infoMessage}
        </>
      )}
      <AddressBookSidebar onClose={handleCloseSidebar} trigger={isSidebarShown}>
        {isSidebarShown && (
          <AddressBookSidebarContainer
            {...(fixedAddress
              ? {
                  onSelect: (addrs: string[]) => {
                    dispatch(changeAB(addrs));
                    setSidebarShown(false);
                  },
                  onReset: () => dispatch(changeAB([CUSTOM])),
                }
              : {
                  onSubmit: (addr: string[]) => dispatch(changeAB(addr)),
                  onReset: () => dispatch(changeAB([])),
                })}
            onClose={handleCloseSidebar}
            addressIds={addressesAdded}
            isSidebarOpen={isSidebarShown}
          />
        )}
      </AddressBookSidebar>
    </div>
  );
};

export default ShippingDetailsContainer;
