export interface IImageLibraryItem {
  name: string;
  public_url: string;
  meta_data: {
    size: number;
    created_at: string;
    updated_at: string;
  };
}

export interface IUploadResponse {
  public_url: string;
}

export enum UploadFileTypeEnum {
  BulkEngagement = 'bulk_engagement',
  BulkInventoryItems = 'bulk_inventory_items',
  InventoryItem = 'product_image',
  OrgLogo = 'org_logo',
  OrgLogoTemp = 'org_logo_temp',
  EmailImage = 'email_image',
  InventoryCSV = 'inventory_csv',
}
