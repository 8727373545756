import * as yup from 'yup';

export const SIGNATURE = 'signature';

export const SIGNATURE_INITIAL_VALUES = {
  [SIGNATURE]: '',
};

export const SIGNATURE_VALIDATION_SCHEMA = yup
  .object()
  .shape({
    [SIGNATURE]: yup.string().required(),
  })
  .required();
