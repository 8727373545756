import cn from 'classnames';
import React from 'react';

import { ReactComponent as ReloadIcon } from '../../assets/images/icon-reload.svg';
import styles from './IntegrationSpinner.module.scss';

interface IProps {
  className?: any;
}

const IntegrationSpinner: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <div className={cn(styles.rotate, className)}>
      <ReloadIcon />
    </div>
  );
};

export default IntegrationSpinner;
