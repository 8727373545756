import { createTransform } from 'redux-persist';

import { IDashboardState } from '../../types/dashboard';

const dashboardMetaDataTransforms = createTransform(
  (stateIn: IDashboardState) => stateIn,
  (stateOut: IDashboardState) => {
    return {
      ...stateOut,
      error: null,
      isLoading: false,
    };
  },
  { whitelist: ['dashboard'] },
);

const transformArray = [dashboardMetaDataTransforms];

export default transformArray;
