import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../components';
import { getDepartmentBudgetSummariesRequest } from '../../store/actions/organizations';
import { toggleUiElement } from '../../store/actions/shell';
import {
  selectIsLoadingDepartmentSummaries,
  selectOrganizationsDetailsIsLoading,
  selectSimplifiedCurrentOrganization,
} from '../../store/selectors/organizations';

import { selectIsDrawerShown } from '../../store/selectors/shell';
import { OrganizationSidebar } from '../index';

const DepartmentMenu = () => {
  const dispatch = useDispatch();

  const organization = useSelector(selectSimplifiedCurrentOrganization);
  const isLoading = useSelector(selectIsLoadingDepartmentSummaries);
  const isUpdating = useSelector(selectOrganizationsDetailsIsLoading);
  const isShown = useSelector(selectIsDrawerShown);

  const handleClose = useCallback(() => {
    dispatch(toggleUiElement({ drawerIsShown: false }));
  }, [dispatch]);

  React.useEffect(() => {
    if (isShown) {
      dispatch(getDepartmentBudgetSummariesRequest());
    }
  }, [dispatch, isShown]);

  return (
    <OrganizationSidebar
      trigger={isShown}
      onClose={handleClose}
      expanded={false}
      organization={organization}
      isRowLoading={isUpdating}
    >
      {
        // we should pass children only if we need to substitute the standard layout of the OrganizationSidebar
        // That's why we are not passing Loader component if the `isLoading` flag is false
        isLoading && <Loader isLoading />
      }
    </OrganizationSidebar>
  );
};

export default DepartmentMenu;
