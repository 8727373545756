import { QueryClientProvider } from '@tanstack/react-query';
import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { CurrencySettings, EmergencyNotification, Footer, Header, Loader, Sidenav } from '../../components';
import queryClient from '../../config/queryConfig';
import { DepartmentMenu, SuggestGiftSidebar } from '../../containers';
import CurrencyProvider from '../../contexts/CurrencyProvider';
import FeedbackProvider from '../../contexts/FeedbackProvider';
import { HeaderInfoProvider } from '../../contexts/HeaderInfo';
import { ThemeProvider } from '../../contexts/ThemeProvider';
import useConnectWithAIExtension from '../../hooks/useConnectWithAIExtension';
import { initAppAction } from '../../store/actions/shell';
import { selectIsAuthenticated, selectIsAuthenticatedInProgress } from '../../store/selectors/auth';
import { selectIsShowContentLoader } from '../../store/selectors/shell';

import styles from './App.module.scss';

interface IProps {
  children: React.ReactNode | React.ReactNode[];
}

const App = ({ children }: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isShowLoader = useSelector(selectIsAuthenticatedInProgress);
  const isShowContentLoader = useSelector(selectIsShowContentLoader);

  React.useEffect(() => {
    dispatch(initAppAction({ firebase, history, location }));
  }, []);

  useConnectWithAIExtension();

  return (
    <ThemeProvider>
      <CurrencyProvider>
        <QueryClientProvider client={queryClient}>
          <FeedbackProvider>
            <div className={cn(styles.App)}>
              <Loader isLoading={isShowLoader} />
              <Sidenav />
              <main className={cn(styles.main)}>
                <HeaderInfoProvider>
                  {isAuthenticated && [
                    <Header key="header" />,
                    <EmergencyNotification key="emergency-notification" />,
                    <CurrencySettings key="currency-settings" />,
                  ]}
                  <div className={cn(styles.content)}>{children}</div>
                </HeaderInfoProvider>
                <Footer />
                <Loader isLoading={isShowContentLoader} className={cn(styles.contentLoader)} />
              </main>
              {isAuthenticated && [
                <DepartmentMenu key="department-menu" />,
                <SuggestGiftSidebar key="suggest-gift-sidebar" />,
              ]}
              <ToastContainer
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
                limit={5}
                theme="colored"
              />
              <div id="dynamic-root" />
            </div>
          </FeedbackProvider>
        </QueryClientProvider>
      </CurrencyProvider>
    </ThemeProvider>
  );
};

export default App;
