import cn from 'classnames';
import * as React from 'react';

import styles from './NavText.module.scss';

interface IProps {
  title: string;
}

const NavText = ({ title }: IProps) => <div className={cn(styles.navText)}>{title}</div>;

export default NavText;
