import cn from 'classnames';
import React from 'react';

import { ReactComponent as CloseIcon } from '../../assets/images/icon-close-cross.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/icon-success-check-outline.svg';

import styles from './CsvUploadResultMessages.module.scss';

interface ICsvUploadSuccessProps {
  successCount: number;
  onClose?: () => void;
}

const CsvUploadSuccess: React.FC<ICsvUploadSuccessProps> = ({ successCount, onClose }) => {
  return (
    <div className={cn(styles.message, styles.success)}>
      <CloseIcon className={cn(styles.closeBtn, styles.green)} onClick={onClose} />
      <CheckIcon />
      <h3>{`File upload successful. ${successCount} recipients have been added.`}</h3>
    </div>
  );
};

export default CsvUploadSuccess;
