import { createSelector } from 'reselect';

import { INITIAL_CAMPAIGN_FORM_STATE, INTERMEDIATE_FC_ID } from '../../constants/campaigns';
import { CampaignsTypeEnum } from '../../types/campaigns';
import { SortOrder } from '../../types/shell';
import { sortByPriority } from '../../utils/campaigns';
import { isItemDigital } from '../../utils/inventories';
import { IAppState } from '../reducers';
import { selectIsDistributor, selectIsOrgAdmin, selectIsSuperAdmin } from './auth';

export const selectRootState = (state: IAppState) => state.campaigns;

export const selectCanEditCampaigns = createSelector(
  selectIsSuperAdmin,
  selectIsDistributor,
  selectIsOrgAdmin,
  (isSuper, isDistributor, isOrg) => isSuper || isDistributor || isOrg,
);

export const selectCampaigns = createSelector(selectRootState, (campaigns) =>
  campaigns && campaigns.items ? Object.values(campaigns.items).sort(sortByPriority(SortOrder.DESC)) : undefined,
);

export const selectCampaignsCount = createSelector(selectRootState, (campaigns) =>
  campaigns ? campaigns.totalCount : 0,
);

export const selectCampaignsByType = (type?: CampaignsTypeEnum) =>
  createSelector(selectCampaigns, (campaignsList) => {
    if (!campaignsList) {
      return void 0;
    }

    const filteredCampaignList = (() => {
      switch (type) {
        case CampaignsTypeEnum.Digital:
          return campaignsList.filter((campaign) => campaign.items.every(isItemDigital));
        case CampaignsTypeEnum.Physical:
          return campaignsList.filter((campaign) => campaign.items.every((item) => !isItemDigital(item)));
        default:
          return campaignsList;
      }
    })();

    return filteredCampaignList.sort(sortByPriority(SortOrder.DESC));
  });

export const selectCampaignDetails = createSelector(selectRootState, (campaigns) =>
  campaigns ? campaigns.details : undefined,
);

export const selectIntermediateFCID = createSelector(selectCampaignDetails, (details) =>
  details ? details[INTERMEDIATE_FC_ID] : undefined,
);

export const selectCampaignById = (id: string | undefined) =>
  createSelector(selectCampaigns, (campaigns) => {
    if (!id || !campaigns) {
      return undefined;
    }
    return campaigns?.find((campaign) => campaign.box_id === id);
  });

export const selectIsCampaignsFetching = createSelector(selectRootState, (campaigns) =>
  campaigns ? campaigns.isLoading : false,
);

export const selectIsFinalCampaign = createSelector(selectRootState, (campaigns) =>
  campaigns ? campaigns.isFinal : false,
);

export const selectCampaignsByIds = (ids: string[] | undefined | null) => (state: IAppState) =>
  ids ? ids.map((id: string) => selectCampaignById(id)(state)) : undefined;

export const selectCampaignInitialValues = createSelector(selectCampaignDetails, (campaignDetails) => {
  return campaignDetails ? campaignDetails : INITIAL_CAMPAIGN_FORM_STATE;
});
