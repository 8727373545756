import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { endpoints } from '../../constants/routing';
import { NO_CURRENCY } from '../../constants/shell';
import { DISPLAY_CURRENCY } from '../../constants/users';
import useFetch from '../../hooks/useFetch';
import { selectUserDisplayCurrency } from '../../store/selectors/auth';
import { selectCurrencyRates } from '../../store/selectors/shell';
import { BUTTON_BUTTON } from '../../types/forms';
import { UISizeEnum } from '../../types/shell';
import { IEditUserProfileRequestPayload } from '../../types/users';
import { handleApiError } from '../../utils/ui';
import { BackButton } from '../forms';
import Loader from '../Loader/Loader';

import styles from './CurrencyList.module.scss';

interface IProps {
  onReturn: () => void;
}

const CurrencyList: React.FC<IProps> = ({ onReturn }) => {
  const rates = useSelector(selectCurrencyRates);
  const displayCurrency = useSelector(selectUserDisplayCurrency);
  const [selectedCurrency, setSelectedCurrency] = React.useState<string | null>(null);

  const { make: editUserProfile, isLoading } = useFetch<IEditUserProfileRequestPayload, never>({
    endpoint: endpoints.editUserProfile,
    showToastOnError: true,
    method: 'POST',
  });

  const handleDisplayCurrencyChange = React.useCallback(
    (value: string) => {
      setSelectedCurrency(value);
      editUserProfile({
        body: {
          [DISPLAY_CURRENCY]: value,
        },
      })
        .catch(handleApiError(`Something bad happened. Display Currency wasn't changed.`))
        .finally(() => setSelectedCurrency(null));
    },
    [editUserProfile],
  );

  const currencies = React.useMemo(
    () => [
      { label: NO_CURRENCY.label, value: '' },
      ...Object.keys(rates).map((currency) => ({ label: currency, value: currency })),
    ],
    [rates],
  );

  return (
    <React.Fragment>
      <div className={styles.backBtn} onClick={onReturn}>
        <BackButton onClick={onReturn} />
        <span>Back</span>
      </div>
      {currencies.length > 0
        ? currencies.map(({ label, value }) => {
            const isActive = selectedCurrency === value;
            const isUpdating = isLoading && isActive;
            return (
              <button
                disabled={isLoading}
                type={BUTTON_BUTTON}
                key={label}
                className={cn(styles.control, { [styles.active]: displayCurrency === value && !isUpdating })}
                onClick={() => handleDisplayCurrencyChange(value)}
              >
                <Loader isLoading={isUpdating} size={UISizeEnum.Tiny} className={styles.loader} />
                <div className={cn(styles.row, { [styles.active]: isActive })}>{label}</div>
              </button>
            );
          })
        : null}
    </React.Fragment>
  );
};

export default CurrencyList;
