import * as React from 'react';

import { PopupProvider } from '../../utils/PopupProvider';

export default () => {
  const [isSent, toggle] = React.useState(false);

  const parseCode = async () => {
    if (window.opener) {
      // get the URL parameters which will include the code
      const code = new URLSearchParams(window.location.search).get('code');

      // send them to the opening window
      await window.opener.postMessage(PopupProvider.getResponse(code, 'No code in URL'));
    } else {
      await window.opener.postMessage(PopupProvider.getResponse(null, `Window isn't a popup`));
    }
  };

  React.useEffect(() => {
    parseCode().then(() => toggle(true));
  }, []);

  React.useEffect(() => {
    // close the popup
    if (isSent) {
      setTimeout(window.close, 0);
    }
  }, [isSent]);

  return <p>Please wait...</p>;
};
