import cn from 'classnames';
import * as React from 'react';

import { ICardProps } from '../../Card/Card';
import { Card } from '../../index';

import styles from './PersonCard.module.scss';

interface IProps extends ICardProps {
  className?: string;
}

const PersonCard = ({ className, label, top, middle, bottom, ...props }: IProps) => {
  return (
    <Card
      {...props}
      inline
      className={cn(styles.container, className)}
      label={label}
      top={<span className={styles.top}>{top}</span>}
      middle={<span className={styles.middle}>{middle}</span>}
      bottom={<span className={styles.bottom}>{bottom}</span>}
    />
  );
};

export default PersonCard;
