import cn from 'classnames';
import * as React from 'react';

import styles from './Nav.module.scss';

interface IProps {
  children: React.ReactNode | React.ReactNode[];
}

const Nav = ({ children }: IProps) => <nav className={cn(styles.nav)}>{children}</nav>;

export default Nav;
