import cn from 'classnames';
import getSymbolFromCurrency from 'currency-symbol-map';
import * as React from 'react';
import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { FALLBACK_CURRENCY_CODE } from '../../constants/shell';

import { useCurrency } from '../../contexts/CurrencyProvider';
import { IResultPriceWithCurrency } from '../../types/shell';

import styles from './Price.module.scss';

interface IProps {
  className?: string;
  hint?: string;
  value: IResultPriceWithCurrency;
  thousandSeparator?: boolean | string;
  fixedDecimalScale?: boolean;
  decimalScale?: number;
  hyphenOnZero?: boolean;
}

const Price: FC<IProps> = ({
  value,
  className,
  hint = '',
  thousandSeparator,
  fixedDecimalScale = true,
  decimalScale = 2,
  hyphenOnZero,
}: IProps) => {
  const { requiredCurrency } = useCurrency();

  const currencySymbol = React.useMemo(() => {
    let { currency } = value;
    if (!currency) {
      console.warn('Currency is not provided for Price', value.price, '. Falling back to', FALLBACK_CURRENCY_CODE);
      currency = requiredCurrency;
    }

    return getSymbolFromCurrency(currency);
  }, [value, requiredCurrency]);

  return (
    <div title={hint} className={cn(styles.price, className)}>
      {hyphenOnZero && !value ? (
        <span className={styles.amount}>{currencySymbol}-.--</span>
      ) : (
        <NumericFormat
          className={styles.amount}
          prefix={currencySymbol}
          value={value.price}
          displayType="text"
          thousandSeparator={thousandSeparator}
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
        />
      )}
    </div>
  );
};

export default Price;
