import firebase from '../config/fbConfig';
import { SupportedAuthProvidersEnum } from '../constants/auth';
import { ILoginFormState } from '../types/auth';

export const authenticateWithPopup = async (providerId?: SupportedAuthProvidersEnum) => {
  const provider = providerId ? new firebase.auth.OAuthProvider(providerId) : new firebase.auth.GoogleAuthProvider();

  try {
    return await firebase.auth().signInWithPopup(provider);
  } catch (error) {
    return { error };
  }
};

export const authenticateWithRedirect = async (providerId?: SupportedAuthProvidersEnum) => {
  const provider = providerId ? new firebase.auth.OAuthProvider(providerId) : new firebase.auth.GoogleAuthProvider();

  try {
    return await firebase.auth().signInWithRedirect(provider);
  } catch (error) {
    return { error };
  }
};

export const logout = async () => {
  try {
    return await firebase.auth().signOut();
  } catch (error) {
    return { error };
  }
};

export const authenticateWithEmailAndPassword = ({ email, password }: ILoginFormState) =>
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch((error) => ({ error }));

export const authenticateWithCredentials = async (token: any) => {
  const credential = firebase.auth.GoogleAuthProvider.credential(null, token);

  try {
    return await firebase.auth().signInWithCredential(credential);
  } catch (error) {
    return { error };
  }
};

export const getIdToken = (forceRefresh?: boolean) => {
  return firebase.auth().currentUser?.getIdTokenResult(forceRefresh);
};
