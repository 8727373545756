import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/images/icon-close-cross.svg';
import { BUTTON_BUTTON } from '../../../types/forms';
import ModalActions from '../ModalActions/ModalActions';
import ModalHeader from '../ModalHeader/ModalHeader';
import styles from './IntegrationWarningModal.module.scss';

interface IProps {
  onClose: () => void;
  icon: React.FC<any>;
  description: React.ReactNode;
  action: React.ReactNode | React.ReactNode[];
}

const IntegrationWarningModal: React.FC<IProps> = ({ onClose, icon: Icon, description, action }) => (
  <div className={cn(styles.container)}>
    <ModalHeader
      className={styles.header}
      right={
        <button type={BUTTON_BUTTON} onClick={onClose}>
          <CloseIcon className={styles.closeBtn} />
        </button>
      }
    />
    <div className={styles.content}>
      {Icon && (
        <div>
          <Icon className={styles.icon} />
        </div>
      )}
      <h1 className={styles.title}>Permission is needed</h1>
      {description && <div className={styles.description}>{description}</div>}
    </div>
    <ModalActions className={styles.footer}>{action}</ModalActions>
  </div>
);

export default IntegrationWarningModal;
