import { createTransform } from 'redux-persist';

import { IModalState } from '../../types/modals';

const modalsTransform = createTransform(
  (stateIn: IModalState) => ({
    ...stateIn,
    resetPassword: {
      ...stateIn.resetPassword,
      isOpen: false,
    },
    rocket: {
      ...stateIn.rocket,
      isOpen: false,
    },
    opportunities: {
      ...stateIn.opportunities,
      isOpen: false,
    },
    integrationWarning: {
      ...stateIn.integrationWarning,
      isOpen: false,
    },
    downloadSuccess: {
      ...stateIn.downloadSuccess,
      isOpen: false,
    },
    lowInventoryItems: {
      ...stateIn.lowInventoryItems,
      isOpen: false,
    },
  }),
  (stateOut: IModalState) => ({
    ...stateOut,
    resetPassword: {
      ...stateOut.resetPassword,
      isOpen: false,
    },
    rocket: {
      ...stateOut.rocket,
      isOpen: false,
    },
    opportunities: {
      ...stateOut.opportunities,
      isOpen: false,
    },
    integrationWarning: {
      ...stateOut.integrationWarning,
      isOpen: false,
    },
    downloadSuccess: {
      ...stateOut.downloadSuccess,
      isOpen: false,
    },
    lowInventoryItems: {
      ...stateOut.lowInventoryItems,
      isOpen: false,
    },
  }),
  { whitelist: ['modals'] },
);

const transformArray = [modalsTransform];

export default transformArray;
