import * as yup from 'yup';

import { ICampaignCandidate } from '../types/campaigns';
import { RECEIVER_REMINDER_INTERVAL } from './bucket';
import { NO_DEPARTMENT } from './organizations';
import { URL_VARS } from './routing';
import { IS_RECEIVER_ADDRESS_FIXED } from './shipping';

export const MAX_CAMPAIGN_ITEMS_PER_PAGE = process.env.REACT_APP_MAX_CAMPAIGNS_PER_PAGE
  ? +process.env.REACT_APP_MAX_CAMPAIGNS_PER_PAGE
  : 50;

export const CAMPAIGN_ITEMS_PER_PAGE_WHEN_SCROLLED_INFINITELY = 10;

export const CAMPAIGN_LABEL = 'Campaign';

export const HIDE_INVENTORY_ITEM_CHECKBOX_SHORT_LABEL = 'Hidden';
export const HIDE_INVENTORY_ITEM_CHECKBOX_HELP_TEXT =
  'This item will be a surprise for the end recipient and not be shown in any web page or communications to them.';
export const HIDE_INVENTORY_ITEM_LAST_PHYSICAL_WARNING = 'Last physical item cannot be hidden or removed.';
export const CHANGE_VISIBILITY_WARNING =
  'Make at least one item that has inventory visible and delete this one instead.';

export const DIGITAL_ITEM_EXISTS_IN_CAMPAIGN_ERROR_MESSAGE =
  'There is only one digital card can be added to the campaign. Please remove added card or create another campaign.';

export const PRINT_YOUR_CAUSE_FC_ID = 'PrintYourCause';

export const ITEM_ID = 'item_id';
export const NAME = 'name';
export const PRICE = 'price';
export const DESCRIPTION = 'description';
export const TYPE = 'type';
export const ORG_ID = 'org_id';
export const BOX_ID = 'box_id';
export const HANDWRITTEN_TEMPLATE_DEFAULT = 'handwritten_template_default';
export const DELAYED_SHIPPING_TEMPLATE_DEFAULT = 'delayed_shipping_template_default';
export const DIGITAL_LINK_TEMPLATE_DEFAULT = 'digital_link_template_default';
export const ITEMS = 'items';
export const PRIORITY = 'priority';
export const ICON_URL = 'icon_url';
export const SIZE = 'size';
export const COLOR = 'color';
export const QUANTITY = 'quantity';
export const DEPARTMENT_IDS = 'department_ids';
export const INTERMEDIATE_FC_ID = 'intermediateFCID';
export const DELAYED_SHIPPING_HEADER_COLOR = 'ds_header_color';
export const DELAYED_SHIPPING_BUTTON_COLOR = 'ds_button_color';
export const DELAYED_SHIPPING_PAGE_TITLE = 'ds_shipping_page_title';
export const DELAYED_SHIPPING_PAGE_BODY = 'ds_shipping_page_body';
export const DELAYED_MSKU_PAGE_TITLE = 'ds_msku_page_title';
export const DELAYED_MSKU_PAGE_BODY = 'ds_msku_page_body';
export const DELAYED_CUSTOMIZE_ITEMS_PAGE_TITLE = 'ds_customize_items_page_title';
export const DELAYED_CUSTOMIZE_ITEMS_PAGE_BODY = 'ds_customize_items_page_body';
export const DELAYED_SHIPPING_LOGO_URL = 'ds_logo_url';
export const DELAYED_SHIPPING_COMPANY_NAME = 'ds_company_name';
export const USE_EMAIL_BRANDING = 'use_email_branding';
export const DISABLE_HANDWRITTEN_NOTE_STEP = 'disable_handwritten_note_step';
export const BUSINESS_REASON_REQUIRED = 'business_reason_required';
export const ORG_ADDRESS_IDS = 'org_address_ids';
export const IS_DELAYED_SHIPPING = 'is_delayed_shipping';
export const CUSTOM = 'custom';

export const CampaignSuccessToastMessages = {
  [URL_VARS.NEW]: 'New campaign was added',
  [URL_VARS.EDIT]: 'Campaign was edited',
};

export const CAMPAIGN_DETAILS_VALIDATION_SCHEMA = yup.object().shape({
  [NAME]: yup.string().required(),
  [DESCRIPTION]: yup.string().required(),
  [DEPARTMENT_IDS]: yup.array().of(yup.string()).required(),
  [PRIORITY]: yup.number().moreThan(0).required(),
  [DELAYED_SHIPPING_TEMPLATE_DEFAULT]: yup.string(),
  [DIGITAL_LINK_TEMPLATE_DEFAULT]: yup.string(),
  [HANDWRITTEN_TEMPLATE_DEFAULT]: yup.string(),
  [ICON_URL]: yup.string().required(),
  [ORG_ID]: yup.string(), // FIXME: remove when backend is ready
  [DELAYED_SHIPPING_BUTTON_COLOR]: yup.string(),
  [DELAYED_SHIPPING_HEADER_COLOR]: yup.string(),
  [DELAYED_SHIPPING_PAGE_TITLE]: yup.string(),
  [DELAYED_SHIPPING_PAGE_BODY]: yup.string(),
  [USE_EMAIL_BRANDING]: yup.boolean(),
  [DISABLE_HANDWRITTEN_NOTE_STEP]: yup.boolean(),
  [BUSINESS_REASON_REQUIRED]: yup.boolean(),
  [DELAYED_MSKU_PAGE_TITLE]: yup.string(),
  [DELAYED_MSKU_PAGE_BODY]: yup.string(),
  [DELAYED_CUSTOMIZE_ITEMS_PAGE_TITLE]: yup.string(),
  [DELAYED_CUSTOMIZE_ITEMS_PAGE_BODY]: yup.string(),
  [DELAYED_SHIPPING_LOGO_URL]: yup.string(),
  [DELAYED_SHIPPING_COMPANY_NAME]: yup.string(),
  shipping_configuration: yup
    .object()
    .shape({
      [ORG_ADDRESS_IDS]: yup.array().of(yup.string()),
      [IS_RECEIVER_ADDRESS_FIXED]: yup.boolean(),
      [IS_DELAYED_SHIPPING]: yup.boolean(),
    })
    .optional(),
});

export const CAMPAIGN_ITEMS_VALIDATION_SCHEMA = yup.object().shape({
  [ITEMS]: yup
    .array()
    .of(
      yup.object().shape({
        [ITEM_ID]: yup.string().required(),
        [QUANTITY]: yup.number().required(),
      }),
    )
    .required(),
});

export const CAMPAIGN_SUMMARY_VALIDATION_SCHEMA = CAMPAIGN_DETAILS_VALIDATION_SCHEMA.concat(
  CAMPAIGN_ITEMS_VALIDATION_SCHEMA as yup.ObjectSchema<any>,
);

export const INITIAL_CAMPAIGN_FORM_STATE: Omit<ICampaignCandidate, typeof BOX_ID> = {
  [NAME]: '',
  [DESCRIPTION]: '',
  [ITEMS]: [],
  [PRIORITY]: 50,
  [DEPARTMENT_IDS]: [NO_DEPARTMENT],
  [DELAYED_SHIPPING_TEMPLATE_DEFAULT]: '',
  [DIGITAL_LINK_TEMPLATE_DEFAULT]: '',
  [HANDWRITTEN_TEMPLATE_DEFAULT]: '',
  [ICON_URL]: '',
  [ORG_ID]: '',
  [INTERMEDIATE_FC_ID]: '',
  [DELAYED_SHIPPING_BUTTON_COLOR]: '',
  [DELAYED_SHIPPING_HEADER_COLOR]: '',
  [DELAYED_SHIPPING_PAGE_TITLE]: '',
  [DELAYED_SHIPPING_PAGE_BODY]: '',
  [RECEIVER_REMINDER_INTERVAL]: '',
  [DELAYED_MSKU_PAGE_TITLE]: '',
  [DELAYED_MSKU_PAGE_BODY]: '',
  [DELAYED_CUSTOMIZE_ITEMS_PAGE_TITLE]: '',
  [DELAYED_CUSTOMIZE_ITEMS_PAGE_BODY]: '',
  [DELAYED_SHIPPING_LOGO_URL]: '',
  [DELAYED_SHIPPING_COMPANY_NAME]: '',
  [DISABLE_HANDWRITTEN_NOTE_STEP]: true,
  [USE_EMAIL_BRANDING]: false,
  [BUSINESS_REASON_REQUIRED]: false,
};

export const CAMPAIGN_SHIPPING_CONFIGURATION_DEFAULT = {
  is_delayed_shipping: false,
  is_receiver_address_fixed: true,
  org_address_ids: [CUSTOM],
};
