import cn from 'classnames';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { ReactComponent as BookIcon } from '../../assets/images/icon-book.svg';
import { ReactComponent as InformationIcon } from '../../assets/images/icon-information.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning-round.svg';

import { ActionButton, AddressBookSidebar, AddressList, InfoMessage, Selector, SingleAddress } from '../../components';
import { ShippingFlowSelectorOptionsEnum } from '../../constants/addressBook';
import { routes } from '../../constants/routing';
import { AddressBookSidebarContainer, ReceiverFixedAddressSidebarContainer } from '../../containers';
import { fetchAddressBookRequest } from '../../store/actions/addressBook';
import { updateOneLinkShippingDetails } from '../../store/actions/bucket';
import { selectAddressBook } from '../../store/selectors/addressBook';
import { selectOneLinkShippingDetails } from '../../store/selectors/bucket';
import { selectOneLinkSummaryByIDShippingConfiguration } from '../../store/selectors/oneLink';

import { IOneLinkShippingDetails } from '../../types/bucket';
import { IOneLinkRouteParams } from '../../types/routing';
import { ISelectorValue, UISizeEnum } from '../../types/shell';
import { isObjectEmpty } from '../../utils/helpers';

import styles from './ShippingDetailsContainer.module.scss';

interface IProps {
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

enum SidebarModesEnum {
  AddressBook = 'address_book',
  FixedAddress = 'fixed_address',
  None = '',
}

const getSelectorState = ({ is_receiver_address_fixed, receiver_fixed_address }: Partial<IOneLinkShippingDetails>) => {
  if (!is_receiver_address_fixed) {
    return ShippingFlowSelectorOptionsEnum.RecipientDecides;
  }

  return typeof receiver_fixed_address === 'undefined'
    ? ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList
    : ShippingFlowSelectorOptionsEnum.Fixed;
};

const OneLink: React.FC<IProps> = ({ isDisabled, isReadOnly }) => {
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch<IOneLinkRouteParams>(routes.oneLinkManager.route);

  const { itemId, status } = React.useMemo(() => routeMatch?.params || ({} as IOneLinkRouteParams), [routeMatch]);

  const isEditable = React.useMemo(() => !(routeMatch || isReadOnly), [routeMatch, isReadOnly]);

  const [sidebarMode, setSidebarMode] = React.useState<SidebarModesEnum>(SidebarModesEnum.None);
  const addressBook = useSelector(selectAddressBook);
  const { org_address_ids, is_receiver_address_fixed, receiver_fixed_address } = useSelector(
    itemId ? selectOneLinkSummaryByIDShippingConfiguration(itemId, status) : selectOneLinkShippingDetails,
  );

  const [selectValue, setSelectValue] = React.useState<ShippingFlowSelectorOptionsEnum>(
    getSelectorState({ is_receiver_address_fixed, receiver_fixed_address }),
  );

  const handleChange = React.useCallback(
    (values: IOneLinkShippingDetails) => {
      dispatch(updateOneLinkShippingDetails(values));
    },
    [dispatch],
  );

  const handleSelectChange = React.useCallback(
    (v: ISelectorValue) => {
      const { value } = v;

      let updates: IOneLinkShippingDetails;
      switch (value) {
        case ShippingFlowSelectorOptionsEnum.Fixed: {
          if (org_address_ids?.length === 1) {
            const address = addressBook?.find((a) => a.uid === org_address_ids[0]);
            updates = {
              is_receiver_address_fixed: true,
              org_address_ids: undefined,
              receiver_fixed_address: address,
            };
          } else {
            setSidebarMode(SidebarModesEnum.FixedAddress);
            updates = {
              is_receiver_address_fixed: true,
              org_address_ids: undefined,
              receiver_fixed_address: undefined,
            };
          }
          break;
        }
        case ShippingFlowSelectorOptionsEnum.RecipientDecides: {
          updates = {
            is_receiver_address_fixed: false,
            receiver_fixed_address: undefined,
          };
          break;
        }
        case ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList: {
          updates = {
            is_receiver_address_fixed: true,
            receiver_fixed_address: undefined,
          };
          break;
        }
        default:
          return;
      }
      setSelectValue(value);
      handleChange(updates);
    },
    [handleChange, addressBook, org_address_ids],
  );

  const handleAddressBookSubmit = React.useCallback(
    (addressIds: string[]) => {
      handleChange({
        is_receiver_address_fixed: !!is_receiver_address_fixed,
        org_address_ids: addressIds,
        receiver_fixed_address: undefined,
      });
    },
    [handleChange, is_receiver_address_fixed],
  );

  const options = useMemo(() => {
    return [
      {
        value: ShippingFlowSelectorOptionsEnum.RecipientDecides,
        label: 'Recipient decides',
      },
      {
        value: ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList,
        label: 'Recipient decides from list only',
      },
      {
        value: ShippingFlowSelectorOptionsEnum.Fixed,
        label: 'Fixed',
      },
    ];
  }, []);

  const handleCloseSidebar = React.useCallback(() => {
    setSidebarMode(SidebarModesEnum.None);
  }, []);

  const addresses = useMemo(
    () => addressBook?.filter((address) => org_address_ids?.includes(address.uid)),
    [addressBook, org_address_ids],
  );

  const isEmptyRecipientFixedAddress = React.useMemo(
    () => isObjectEmpty(receiver_fixed_address),
    [receiver_fixed_address],
  );

  const isFixedSelectorValue = useMemo(() => {
    return selectValue === ShippingFlowSelectorOptionsEnum.Fixed;
  }, [selectValue]);

  const infoMessage = useMemo(() => {
    const recipientDecides = selectValue === ShippingFlowSelectorOptionsEnum.RecipientDecides;
    const recipientDecidesFromList =
      selectValue === ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList && addresses && addresses.length >= 2;

    const notEnoughAddressesSelected =
      selectValue === ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList && addresses && addresses?.length < 2;

    switch (true) {
      case isFixedSelectorValue:
        return (
          <InfoMessage
            icon={<InformationIcon />}
            text={"The package will be delivered to the specified address. The recipient won't be able to change it."}
          />
        );
      case recipientDecides:
        return (
          <InfoMessage
            icon={<InformationIcon />}
            text={'Recipient will have an option to enter address manually or choose one of the selected addresses.'}
          />
        );
      case notEnoughAddressesSelected:
        return (
          <InfoMessage
            icon={<WarningIcon />}
            text={'Please select at least two addresses to proceed.'}
            className={styles.warning}
          />
        );
      case recipientDecidesFromList:
        return (
          <InfoMessage
            icon={<InformationIcon />}
            text={'Recipient will have an option to choose one of the selected addresses.'}
          />
        );
      case !!receiver_fixed_address:
        return (
          <InfoMessage
            icon={<InformationIcon />}
            text={"The package will be delivered to the specified address. The recipient won't be able to change it."}
          />
        );
      default:
        return null;
    }
  }, [isFixedSelectorValue, selectValue, addresses, receiver_fixed_address]);

  const AddressBookValue = React.useMemo(() => {
    const shouldShowSingleAddress = !isEmptyRecipientFixedAddress;

    switch (true) {
      case !(addresses || receiver_fixed_address):
        return null;
      case shouldShowSingleAddress:
        return <SingleAddress address={receiver_fixed_address} />;
      case !shouldShowSingleAddress && !!addresses?.length:
        return <AddressList addresses={addresses} />;
      default:
        return null;
    }
  }, [isEmptyRecipientFixedAddress, addresses, receiver_fixed_address]);

  const actionButtonTitle = useMemo(() => {
    switch (true) {
      case isFixedSelectorValue:
        return `${!isEmptyRecipientFixedAddress ? 'Edit' : 'Enter'} Fixed Address`;
      case org_address_ids && org_address_ids.length !== 0:
        return 'Edit Address Book Option';
      default:
        return 'Select Address Book Option';
    }
  }, [isFixedSelectorValue, org_address_ids, isEmptyRecipientFixedAddress]);

  useEffect(() => {
    dispatch(fetchAddressBookRequest());
  }, [dispatch]);

  return (
    <div className={cn(styles.container, styles.nested, { [styles.disabled]: isDisabled })}>
      <h2 className={styles.title}>Shipping</h2>
      <Selector
        className={styles.selector}
        helperText="Address Options"
        options={options}
        value={options.find((option) => option.value === selectValue)}
        onChange={(v) => handleSelectChange(v as ISelectorValue)}
        isClearable={false}
        isSearchable={false}
        closeMenuOnSelect
        readOnly={!isEditable}
      />
      {isEditable && !isDisabled && (
        <ActionButton
          className={styles.addressBookBtn}
          outlined
          icon={<BookIcon className={styles.icon} />}
          title={actionButtonTitle}
          size={UISizeEnum.Normal}
          onClick={() =>
            setSidebarMode(isFixedSelectorValue ? SidebarModesEnum.FixedAddress : SidebarModesEnum.AddressBook)
          }
        />
      )}
      {!isDisabled && (
        <>
          {AddressBookValue}
          {infoMessage}
        </>
      )}
      <AddressBookSidebar onClose={handleCloseSidebar} trigger={sidebarMode === SidebarModesEnum.AddressBook}>
        <AddressBookSidebarContainer
          onClose={handleCloseSidebar}
          addressIds={org_address_ids}
          onSubmit={handleAddressBookSubmit}
          isSidebarOpen={sidebarMode === SidebarModesEnum.AddressBook}
        />
      </AddressBookSidebar>
      <ReceiverFixedAddressSidebarContainer
        addressBook={addressBook}
        trigger={sidebarMode === SidebarModesEnum.FixedAddress}
        onClose={handleCloseSidebar}
      />
    </div>
  );
};

export default OneLink;
