import cn from 'classnames';
import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import SelectOptionModal from '../../components/modals/SelectOptionModal/SelectOptionModal';
import AdminRoute from '../../components/routes/AdminRoute';
import useModal from '../../hooks/useModal';

import { DeleteItemModal } from '../../components/modals/CommonModals';
import FailedUploadInventoryModal from '../../components/modals/FailedUploadInventoryModal/FailedUploadInventoryModal';
import UploadInventoryModal from '../../components/modals/UploadInventoryModal/UploadInventoryModal';
import { PrivateRoute } from '../../components/routes';
import {
  CSV_UPLOAD_SUCCESS,
  InventorySuccessToastMessages,
  INVENTORY_ITEM_LABEL,
  TYPE,
} from '../../constants/inventories';
import { routes, URL_VARS } from '../../constants/routing';
import { DISTRIBUTOR, SUPER_ADMIN } from '../../constants/users';
import { EditInventoryItemContainer, InventoryContainer } from '../../containers';
import { fetchFulfillmentCentersRequest } from '../../store/actions/fulfillmentCenters';
import {
  deleteInventoryItemRequest,
  setInventoryItemType,
  uploadInventoryCSVRequest,
} from '../../store/actions/inventories';
import { selectInventoryBulkResult } from '../../store/selectors/inventories';
import {
  InventoryTypesEnum,
  IUploadInventoryCSVRequestPayload,
  IUploadInventoryCSVResponsePayload,
} from '../../types/inventories';
import { IAddNewInventoryItemModalProps, IDeleteModalProps } from '../../types/modals';
import { IInventoryRouteParams } from '../../types/routing';
import { IApiError, NotificationListEnum } from '../../types/shell';
import notification from '../../utils/notification';
import { handleApiError } from '../../utils/ui';

import styles from './Inventory.module.scss';

const Inventory: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { /* itemId, */ flowId } = useParams<IInventoryRouteParams>();

  const result = useSelector(selectInventoryBulkResult);

  const {
    openModal: openFailedUploadCSVModal,
    closeModal: closeFailedUploadCSVModal,
    Modal: FailedUploadCSVModal,
  } = useModal();
  const {
    openModal: openUploadCSVModal,
    closeModal: closeUploadCSVModal,
    Modal: UploadCSVModal,
  } = useModal({ resetStyles: true });
  const {
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
    Modal: DeleteModal,
  } = useModal<IDeleteModalProps>();
  const {
    openModal: openNewItemModal,
    closeModal: closeNewItemModal,
    Modal: AddNewItemModal,
  } = useModal<IAddNewInventoryItemModalProps>({
    required: flowId === URL_VARS.NEW,
  });

  const handleDeleteItem = React.useCallback(
    (itemId: string, cb?: () => void) => {
      return new Promise((resolve, reject) => {
        dispatch(deleteInventoryItemRequest({ itemId, resolve, reject }));
      })
        .then(() => {
          notification.success(NotificationListEnum.Success, { content: 'Item was successfully deleted' });
          if (cb) {
            cb();
          }
        })
        .catch(handleApiError(`Something bad happened. The item wasn't deleted.`))
        .finally(() => {
          if (typeof closeDeleteModal === 'function') {
            closeDeleteModal();
          }
        });
    },
    [dispatch, closeDeleteModal],
  );

  const handleAddNewItem = React.useCallback(
    (type: InventoryTypesEnum) => {
      dispatch(setInventoryItemType(type));
      history.push(routes.inventory.getInventoryUrl({ flowId: URL_VARS.NEW }));
    },
    [history, dispatch],
  );

  const uploadInventoryCSV = React.useCallback(
    (payload: IUploadInventoryCSVRequestPayload) => {
      dispatch(uploadInventoryCSVRequest(payload));
    },
    [dispatch],
  );

  const showResult = React.useCallback(
    (response: IUploadInventoryCSVResponsePayload) => {
      if (response.error_file_url === '') {
        return notification.success(NotificationListEnum.Success, { content: `Inventory was successfully uploaded!` });
      }
      if (response.error_file_url !== '') {
        openFailedUploadCSVModal();
      }
    },
    [openFailedUploadCSVModal],
  );

  const handleUploadInventory = React.useCallback(
    (fileName: string, fcId: string) => {
      const uploadInventoryCSVPromise = new Promise<IUploadInventoryCSVResponsePayload>((resolve, reject) => {
        uploadInventoryCSV({ file_name: fileName, fc_id: fcId, resolve, reject });
      }).then((response: IUploadInventoryCSVResponsePayload) => {
        if (response.error_file_url) {
          showResult(response);
        } else {
          notification.success(NotificationListEnum.Success, {
            content: InventorySuccessToastMessages[CSV_UPLOAD_SUCCESS],
          });
        }
      });

      notification.promise<void | IApiError>(NotificationListEnum.DownloadData, {
        promise: uploadInventoryCSVPromise,
        promiseParams: {
          pending: 'Uploading process started. We’ll notify you when inventory will be uploaded!',
          error: {
            render: ({ data: error }) =>
              (error && error.message) || "Something bad has happened. Inventory wasn't uploaded",
          },
        },
      });
    },
    [showResult, uploadInventoryCSV],
  );

  const uploadInventoryModal = React.useMemo(
    () => (
      <UploadCSVModal className={cn('common-modal', styles.modalContainer)}>
        {() => {
          return <UploadInventoryModal onClose={closeUploadCSVModal} onSubmit={handleUploadInventory} />;
        }}
      </UploadCSVModal>
    ),
    [UploadCSVModal, closeUploadCSVModal, handleUploadInventory],
  );

  const failedInventoryUpload = React.useMemo(
    () => (
      <FailedUploadCSVModal className={cn('common-modal', styles.modalContainer)}>
        {() => {
          return (
            <FailedUploadInventoryModal onClose={closeFailedUploadCSVModal} errorFileUrl={result?.error_file_url!} />
          );
        }}
      </FailedUploadCSVModal>
    ),
    [FailedUploadCSVModal, closeFailedUploadCSVModal, result],
  );

  const deleteItemModal = React.useMemo(
    () => (
      <DeleteModal className="common-modal">
        {({ id, cb }) => {
          return (
            <DeleteItemModal
              assetName={INVENTORY_ITEM_LABEL}
              onDelete={() => handleDeleteItem(id, cb)}
              onClose={closeDeleteModal}
            />
          );
        }}
      </DeleteModal>
    ),
    [closeDeleteModal, DeleteModal, handleDeleteItem],
  );

  const newItemActions = React.useMemo(() => {
    return [
      { label: 'Digital item', value: InventoryTypesEnum.Digital },
      { label: 'Physical item', value: InventoryTypesEnum.Physical },
      { label: 'Multiple Option Item', value: InventoryTypesEnum.MSKU },
    ];
  }, []);

  const addNewItemModal = React.useMemo(
    () => (
      <AddNewItemModal className={cn('common-modal', styles.modalContainer)}>
        {({ required }) => {
          return (
            <SelectOptionModal
              assetName={`${INVENTORY_ITEM_LABEL} ${TYPE}`}
              description="Choose the type of item you want to create"
              actions={newItemActions}
              onSubmit={handleAddNewItem}
              onClose={closeNewItemModal}
              showCloseButton={!required}
            />
          );
        }}
      </AddNewItemModal>
    ),
    [AddNewItemModal, newItemActions, handleAddNewItem, closeNewItemModal],
  );

  const render = React.useMemo(() => {
    switch (flowId) {
      case URL_VARS.NEW:
      case URL_VARS.EDIT:
        return (
          <AdminRoute
            roles={[DISTRIBUTOR, SUPER_ADMIN]}
            component={() => <EditInventoryItemContainer onAdd={openNewItemModal} />}
          />
        );
      case URL_VARS.VIEW:
        return <PrivateRoute component={() => <EditInventoryItemContainer />} />;
      default:
        return (
          <InventoryContainer onDelete={openDeleteModal} onAdd={openNewItemModal} onUploadCSV={openUploadCSVModal} />
        );
    }
  }, [flowId, openDeleteModal, openNewItemModal, openUploadCSVModal]);

  React.useEffect(() => {
    dispatch(fetchFulfillmentCentersRequest({ hasOrgInventory: true }));
  }, [dispatch]);

  return (
    <>
      {render}
      {addNewItemModal}
      {deleteItemModal}
      {uploadInventoryModal}
      {failedInventoryUpload}
    </>
  );
};

export default Inventory;
