import { GMAIL, OUTLOOK } from '../../constants/integrations';
import { ISearchLeadsAndContactsPayload } from '../../types/integrations';
import { IPromiseCallbacks } from '../../types/redux';

export const CHECK_CRM_INTEGRATIONS_START = 'CHECK_CRM_INTEGRATIONS_START';
export const CHECK_CRM_INTEGRATIONS_END = 'CHECK_CRM_INTEGRATIONS_END';

export const CRM_OAUTH_START = 'CRM_OAUTH_START';
export const CRM_OAUTH_END = 'CRM_OAUTH_END';
export const CRM_OAUTH_SUCCESS = 'CRM_OAUTH_SUCCESS';
export const CRM_OAUTH_FAILURE = 'CRM_OAUTH_FAILURE';

export const CHECK_SF_AUTH_REQUEST = 'CHECK_SF_AUTH_REQUEST';
export const CHECK_SF_AUTH_SUCCESS = 'CHECK_SF_AUTH_SUCCESS';
export const CHECK_SF_AUTH_FAILURE = 'CHECK_SF_AUTH_FAILURE';

export const CHECK_HUBSPOT_AUTH_REQUEST = 'CHECK_HUBSPOT_AUTH_REQUEST';
export const CHECK_HUBSPOT_AUTH_SUCCESS = 'CHECK_HUBSPOT_AUTH_SUCCESS';
export const CHECK_HUBSPOT_AUTH_FAILURE = 'CHECK_HUBSPOT_AUTH_FAILURE';

export const SEARCH_LEADS_AND_CONTACTS_REQUEST = 'SEARCH_LEADS_AND_CONTACTS_REQUEST';
export const SEARCH_LEADS_AND_CONTACTS_SUCCESS = 'SEARCH_LEADS_AND_CONTACTS_SUCCESS';
export const SEARCH_LEADS_AND_CONTACTS_FAILURE = 'SEARCH_LEADS_AND_CONTACTS_FAILURE';

export const CHOOSE_SEARCH_ITEM = 'CHOOSE_SEARCH_ITEM';
export const CLEAR_SEARCH_ITEMS = 'CLEAR_SEARCH_ITEMS';

export const GET_OPPORTUNITIES_REQUEST = 'GET_OPPORTUNITIES_REQUEST';
export const GET_OPPORTUNITIES_SUCCESS = 'GET_OPPORTUNITIES_SUCCESS';
export const GET_OPPORTUNITIES_FAILURE = 'GET_OPPORTUNITIES_FAILURE';

export const SET_OPPORTUNITY = 'SET_OPPORTUNITY';
export const CLEAR_OPPORTUNITIES = 'CLEAR_OPPORTUNITIES';

export const CHECK_EMAIL_INTEGRATIONS_START = 'CHECK_EMAIL_INTEGRATIONS_START';
export const CHECK_EMAIL_INTEGRATIONS_END = 'CHECK_EMAIL_INTEGRATIONS_END';

export const EMAIL_OAUTH_START = 'EMAIL_OAUTH_START';
export const EMAIL_OAUTH_END = 'EMAIL_OAUTH_END';
export const EMAIL_OAUTH_SUCCESS = 'EMAIL_OAUTH_SUCCESS';
export const EMAIL_OAUTH_FAILURE = 'EMAIL_OAUTH_FAILURE';

export const CHECK_EMAIL_AUTH_REQUEST = 'CHECK_EMAIL_AUTH_REQUEST';
export const CHECK_EMAIL_AUTH_SUCCESS = 'CHECK_EMAIL_AUTH_SUCCESS';
export const CHECK_EMAIL_AUTH_FAILURE = 'CHECK_EMAIL_AUTH_FAILURE';

export const GENERATE_ZAPIER_API_KEY_REQUEST = 'GENERATE_ZAPIER_API_KEY_REQUEST';
export const GENERATE_ZAPIER_API_KEY_SUCCESS = 'GENERATE_ZAPIER_API_KEY_SUCCESS';
export const GENERATE_ZAPIER_API_KEY_FAILURE = 'GENERATE_ZAPIER_API_KEY_FAILURE';

export const GET_USER_API_KEY_REQUEST = 'GET_USER_API_KEY_REQUEST';
export const GET_USER_API_KEY_SUCCESS = 'GET_USER_API_KEY_SUCCESS';
export const GET_USER_API_KEY_FAILURE = 'GET_USER_API_KEY_FAILURE';

export const crmCheckStart = () => ({
  type: CHECK_CRM_INTEGRATIONS_START,
});

export const crmCheckEnd = () => ({
  type: CHECK_CRM_INTEGRATIONS_END,
});

export const crmOAuthStart = (payload?: IPromiseCallbacks) => ({
  type: CRM_OAUTH_START,
  payload,
});

export const crmOAuthEnd = () => ({
  type: CRM_OAUTH_END,
});

export const crmOAuthSuccess = (payload?: any) => ({
  type: CRM_OAUTH_SUCCESS,
  payload,
});

export const crmOAuthFailure = (payload: any) => ({
  type: CRM_OAUTH_FAILURE,
  payload,
});

export const checkSalesForceAuthRequest = () => ({
  type: CHECK_SF_AUTH_REQUEST,
});

export const checkSalesForceAuthSuccess = (payload: any) => ({
  type: CHECK_SF_AUTH_SUCCESS,
  payload,
});

export const checkSalesForceAuthFailure = (payload: any) => ({
  type: CHECK_SF_AUTH_FAILURE,
  payload,
});

export const checkHubspotAuthRequest = () => ({
  type: CHECK_HUBSPOT_AUTH_REQUEST,
});

export const checkHubspotAuthSuccess = (payload?: any) => ({
  type: CHECK_HUBSPOT_AUTH_SUCCESS,
  payload,
});

export const checkHubspotAuthFailure = (payload?: any) => ({
  type: CHECK_HUBSPOT_AUTH_FAILURE,
  payload,
});

export const searchLeadsAndContactsRequest = (payload: ISearchLeadsAndContactsPayload) => ({
  type: SEARCH_LEADS_AND_CONTACTS_REQUEST,
  payload,
});

export const searchLeadsAndContactsSuccess = (payload: any) => ({
  type: SEARCH_LEADS_AND_CONTACTS_SUCCESS,
  payload,
});

export const searchLeadsAndContactsFailure = (payload: any) => ({
  type: SEARCH_LEADS_AND_CONTACTS_FAILURE,
  payload,
});

export const chooseSearchItem = (payload: any) => ({
  type: CHOOSE_SEARCH_ITEM,
  payload,
});

export const clearSearchItems = () => ({
  type: CLEAR_SEARCH_ITEMS,
});

export const getOpportunitiesRequest = (payload: any, options?: { resolve: any; reject: any }) => ({
  type: GET_OPPORTUNITIES_REQUEST,
  payload,
  options,
});

export const getOpportunitiesSuccess = (payload: any) => ({
  type: GET_OPPORTUNITIES_SUCCESS,
  payload,
});

export const getOpportunitiesFailure = (payload: any) => ({
  type: GET_OPPORTUNITIES_FAILURE,
  payload,
});

export const setOpportunity = (payload: any) => ({
  type: SET_OPPORTUNITY,
  payload,
});

export const clearOpportunities = () => ({
  type: SET_OPPORTUNITY,
});

export const emailCheckStart = () => ({
  type: CHECK_EMAIL_INTEGRATIONS_START,
});

export const emailCheckEnd = () => ({
  type: CHECK_EMAIL_INTEGRATIONS_END,
});

export const emailOAuthStart = () => ({
  type: EMAIL_OAUTH_START,
});

export const emailOAuthEnd = () => ({
  type: EMAIL_OAUTH_END,
});

export const emailOAuthSuccess = (payload?: any) => ({
  type: EMAIL_OAUTH_SUCCESS,
  payload,
});

export const emailOAuthFailure = (payload?: any) => ({
  type: EMAIL_OAUTH_FAILURE,
  payload,
});

export const checkEmailAuthRequest = (payload: typeof GMAIL | typeof OUTLOOK) => ({
  type: CHECK_EMAIL_AUTH_REQUEST,
  payload,
});

export const checkEmailAuthSuccess = (payload?: any) => ({
  type: CHECK_EMAIL_AUTH_SUCCESS,
  payload,
});

export const checkEmailAuthFailure = (payload?: any) => ({
  type: CHECK_EMAIL_AUTH_FAILURE,
  payload,
});

export const generateZapierApiKeyRequest = (payload?: IPromiseCallbacks) => ({
  type: GENERATE_ZAPIER_API_KEY_REQUEST,
  payload,
});

export const generateZapierApiKeySuccess = (payload?: any) => ({
  type: GENERATE_ZAPIER_API_KEY_SUCCESS,
  payload,
});

export const generateZapierApiKeyFailure = (payload?: any) => ({
  type: GENERATE_ZAPIER_API_KEY_FAILURE,
  payload,
});

export const getUserApiKeyRequest = (payload?: IPromiseCallbacks) => ({
  type: GET_USER_API_KEY_REQUEST,
  payload,
});

export const getUserApiKeySuccess = (payload: string) => ({
  type: GET_USER_API_KEY_SUCCESS,
  payload,
});

export const getUserApiKeyFailure = (payload?: any) => ({
  type: GET_USER_API_KEY_FAILURE,
  payload,
});
