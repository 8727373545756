import cn from 'classnames';
import * as React from 'react';
import { FileRejection } from 'react-dropzone';

import { ReactComponent as ImagePlaceholderIcon } from '../../../../assets/images/icon-image-placeholder.svg';
import { IMAGE_TYPES, MAX_IMAGE_SIZE } from '../../../../constants/upload';
import useWindowSize from '../../../../hooks/useWindowSize';
import { IDropzoneResult } from '../../../../types/forms';
import { RedCrossButton } from '../../buttons';
import UploadInput from '../UploadInput/UploadInput';

import styles from './ImageUploadInput.module.scss';

interface IProps {
  onUpload?: (args: IDropzoneResult) => void;
  onSuccess?: (...args: any[]) => void;
  onFailure?: (...args: any[]) => void;
  onBlur?: (...args: any[]) => void;
  onDropReject?: (fileRejections: FileRejection[]) => void;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onImageDelete?: (value: string | undefined) => void;
  accept?: string | string[];
  maxSize?: number;
  value?: string;
  className?: string;
  error?: string | string[];
  readOnly?: boolean;
  message?: string | React.ReactNode | React.ReactNode[];
  showErrors?: boolean;
  placeholderIcon?: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
}

const ImageUploadInput: React.FC<IProps> = ({
  onUpload,
  onSuccess,
  onFailure,
  onDropReject,
  onBlur,
  onClick,
  onImageDelete,
  accept = IMAGE_TYPES,
  maxSize = MAX_IMAGE_SIZE,
  value,
  className,
  readOnly,
  error,
  message,
  showErrors,
  placeholderIcon,
  disabled,
}: IProps) => {
  const [showPreview, setShowPreview] = React.useState(true);
  const { fullHD } = useWindowSize();

  React.useEffect(() => {
    if (value) {
      setShowPreview(true);
    }
  }, [value]);

  const handleDeleteBtnClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      if (typeof onImageDelete === 'function') {
        onImageDelete(value);
      }
    },
    [onImageDelete],
  );

  const handleOnClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (typeof onClick === 'function') {
        event.persist();
        event.stopPropagation();
        onClick(event);
      }
    },
    [onClick],
  );

  const closeButtonSize = React.useMemo(() => {
    switch (true) {
      case fullHD:
        return 'xl';
      default:
        return 'l';
    }
  }, [fullHD]);

  return (
    <UploadInput
      accept={accept}
      onUpload={onUpload}
      onSuccess={onSuccess}
      onFailure={onFailure}
      onDropReject={onDropReject}
      className={cn(styles.wrapper, { [styles.uploaded]: showPreview }, disabled ? styles.disabled : '', className)}
      multiple={false}
      onBlur={onBlur}
      onClick={handleOnClick}
      maxSize={maxSize}
      noDrag={!onUpload}
      error={error}
      readOnly={readOnly || disabled}
      showErrors={showErrors}
    >
      {({ isDragActive }) => {
        return showPreview ? (
          <React.Fragment>
            <img className={styles.imgPreview} src={value} onError={() => setShowPreview(false)} alt="" />
            {!readOnly && (
              <RedCrossButton onClick={handleDeleteBtnClick} className={styles.deleteBtn} size={closeButtonSize} />
            )}
          </React.Fragment>
        ) : (
          <div className={styles.imgPlaceHolder}>
            {placeholderIcon ? placeholderIcon : <ImagePlaceholderIcon />}
            <span className={styles.uploadMessage}>
              {isDragActive ? (
                'Drop the files here ...'
              ) : (
                <React.Fragment>
                  {message ? (
                    message
                  ) : (
                    <React.Fragment>
                      Click or drag to
                      <br />
                      upload picture
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </span>
          </div>
        );
      }}
    </UploadInput>
  );
};

export default ImageUploadInput;
