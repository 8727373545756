import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as HiddenIcon } from '../../assets/images/icon-invisible.svg';
import { ReactComponent as MultipleOptionsIcon } from '../../assets/images/icon-msku.svg';
import { ReactComponent as PreorderIcon } from '../../assets/images/icon-preorder-allowed.svg';

import {
  HIDDEN_FOR_RECIPIENT_HINT_TEXT,
  MSKU_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING,
  MSKU_ITEM_ICON_HINT,
  PREORDER_ITEM_HINT_TEXT,
} from '../../constants/inventories';
import useModal from '../../hooks/useModal';
import { IInventoryItem, InventoryTypesEnum } from '../../types/inventories';
import { isBrokenMSKU } from '../../utils/inventories';
import { CountIndicator, CustomizableItemIndicator, HelpTooltip, MskuDetailsModal } from '../index';

import styles from './ItemMetadata.module.scss';

interface IProps {
  className?: string;
  itemId?: string;
  title?: string;
  type: InventoryTypesEnum;
  isPreorder?: boolean;
  isCustomizable?: boolean;
  count?: number;
  skuOptions?: IInventoryItem[];
  fulfillmentCenterId?: string;
  showCount?: boolean;
  isHidden?: boolean;
}

const ItemMetadata = ({
  className,
  count,
  itemId,
  title,
  skuOptions,
  isPreorder,
  type,
  isCustomizable,
  showCount = true,
  isHidden,
  fulfillmentCenterId,
}: IProps) => {
  const { openModal, closeModal, Modal } = useModal();

  const handleShowMskuModal = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      openModal(skuOptions);
    },
    [skuOptions],
  );

  const isMsku = React.useMemo(() => type === InventoryTypesEnum.MSKU, [type]);

  const isPreorderMsku = React.useMemo(
    () => isMsku && skuOptions?.some((option) => option.preorder_allowed),
    [isMsku, skuOptions],
  );

  const shouldShowMskuWarning = React.useMemo(
    () =>
      fulfillmentCenterId &&
      skuOptions &&
      isBrokenMSKU({ fulfillment_center_id: fulfillmentCenterId, sku_options: skuOptions || [] }),
    [fulfillmentCenterId, skuOptions],
  );

  const renderMskuDetailsModal = React.useMemo(
    () => (
      <Modal className={styles.multipleOptionsDetailsModal}>
        {(skuOptionsArray: IInventoryItem[]) =>
          skuOptionsArray && skuOptionsArray.length ? (
            <MskuDetailsModal parentId={itemId} onClose={closeModal} skuOptions={skuOptionsArray} name={title || ''} />
          ) : (
            <React.Fragment />
          )
        }
      </Modal>
    ),
    [closeModal, Modal, title],
  );

  return (
    <div
      className={cn(styles.container, className)}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      {showCount && (
        <CountIndicator
          itemId={itemId}
          type={type}
          count={count}
          skuOptions={skuOptions}
          isPreorderAllowed={isPreorder}
        />
      )}
      {isMsku && (
        <div onClick={handleShowMskuModal} className={cn(styles.mskuDetails, styles.scalable)} role="button">
          <HelpTooltip
            id={`msku-tooltip-for-${title}`}
            icon={MultipleOptionsIcon}
            className={cn(styles.mskuIcon, { [styles.alert]: shouldShowMskuWarning })}
            contentClassName={cn({ [styles.alert]: shouldShowMskuWarning })}
          >
            {shouldShowMskuWarning ? MSKU_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING : MSKU_ITEM_ICON_HINT}
          </HelpTooltip>
        </div>
      )}
      {(isPreorder || isPreorderMsku) && (
        <div className={cn(styles.preorderDetails, styles.scalable)}>
          <HelpTooltip id={`preorder-tooltip-for-${title}`} className={cn(styles.preorderIcon)} icon={PreorderIcon}>
            {PREORDER_ITEM_HINT_TEXT}
          </HelpTooltip>
        </div>
      )}
      {isCustomizable && <CustomizableItemIndicator id={itemId} className={styles.scalable} />}
      {isHidden && (
        <div className={styles.hiddenIconTooltip}>
          <HelpTooltip
            id={`hidden-tooltip-for-${title}`}
            className={cn(styles.scalable, styles.hiddenInfo)}
            icon={HiddenIcon}
          >
            {HIDDEN_FOR_RECIPIENT_HINT_TEXT}
          </HelpTooltip>
        </div>
      )}
      {isMsku && renderMskuDetailsModal}
    </div>
  );
};

export default ItemMetadata;
