import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as TwoFAIcon } from '../../../assets/images/icon-2fa.svg';
import { ReactComponent as LockIcon } from '../../../assets/images/icon-lock-lg.svg';
import { ReactComponent as EmailIcon } from '../../../assets/images/icon-mail.svg';
import { ReactComponent as NoteIcon } from '../../../assets/images/icon-notepad.svg';
import { ReactComponent as OneLinkIcon } from '../../../assets/images/icon-one-link.svg';
import { ReactComponent as PYGIcon } from '../../../assets/images/icon-pick-your-gift.svg';

import { SHIPPING_OPTIONS } from '../../../constants/shipping';
import { IOneLink } from '../../../types/oneLink';
import { IReport } from '../../../types/reports';
import {
  is2FAProtectedEngagement,
  isDigitalReport,
  isOneLinkEngagement,
  isProtectedEngagement,
  isPYGReport,
} from '../../../utils/reports';

import styles from './SendIcons.module.scss';

interface IProps {
  className?: string;
  instance: IReport | IOneLink;
}

const SendIcons = ({ className, instance }: IProps) => {
  const isDigital = React.useMemo(() => isDigitalReport(instance), [instance]);
  const isPYG = React.useMemo(() => isPYGReport(instance), [instance]);
  const isOneLink = React.useMemo(() => isOneLinkEngagement(instance as IReport), [instance]);
  const isProtected = React.useMemo(() => isProtectedEngagement(instance as IOneLink), [instance]);
  const is2FAProtected = React.useMemo(() => is2FAProtectedEngagement(instance as IOneLink), [instance]);

  const { note_message: note, email_template_id: email, shipping_option } = React.useMemo(() => instance, [instance]);

  const { icon: ShippingIcon, label: shippingLabel } = React.useMemo(
    () =>
      SHIPPING_OPTIONS.find(({ value }) => value === shipping_option) || {
        icon: null,
        label: null,
      },
    [shipping_option],
  );

  return (
    <div className={cn(styles.container, className)}>
      {note && <NoteIcon />}
      {email && <EmailIcon />}
      {!isDigital && ShippingIcon && shippingLabel && <ShippingIcon title={shippingLabel} />}
      {isPYG && <PYGIcon />}
      {isOneLink && <OneLinkIcon className={styles.oneLinkIcon} />}
      {isProtected && <LockIcon />}
      {is2FAProtected && <TwoFAIcon />}
    </div>
  );
};

export default SendIcons;
