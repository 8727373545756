import cn from 'classnames';
import * as React from 'react';

import { BUTTON_BUTTON } from '../../../../types/forms';
import styles from './BackButton.module.scss';

interface IProps {
  className?: string;
  onClick: (...args: any[]) => void;
}

export default ({ className, onClick }: IProps) => (
  <button className={cn(styles.btn, className)} type={BUTTON_BUTTON} onClick={onClick} />
);
