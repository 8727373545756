export default (state: any) => {
  return {
    ...state,
    templates: {
      emails: state.emails,
      notes: state.notes,
    },
    notes: undefined,
    emails: undefined,
  };
};
