import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import {
  BOX_ID,
  INITIAL_CAMPAIGN_FORM_STATE,
  INTERMEDIATE_FC_ID,
  MAX_CAMPAIGN_ITEMS_PER_PAGE,
} from '../../constants/campaigns';
import {
  ICampaign,
  ICampaignCandidate,
  ICampaignsState,
  IDeleteCampaignResponse,
  IFetchCampaignByIdResponsePayload,
  IFetchCampaignsResponse,
} from '../../types/campaigns';
import { IReduxAction } from '../../types/redux';
import { getBoxItem } from '../../utils/campaigns';
import { convertArrayToObject } from '../../utils/helpers';
import * as AuthActions from '../actions/auth';
import * as CampaignsActions from '../actions/campaigns';

export const initialState: ICampaignsState = {
  items: null,
  details: null,
  isLoading: false,
  error: null,
  isFinal: false,
  totalCount: 0,
};

const reducer = (state: ICampaignsState = initialState, action: any): ICampaignsState => {
  switch (action.type) {
    case CampaignsActions.FETCH_CAMPAIGNS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        isFinal: false,
      };
    }
    case CampaignsActions.FETCH_CAMPAIGNS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        items: null,
      };
    }
    case CampaignsActions.FETCH_CAMPAIGNS_SUCCESS: {
      const { campaigns: items, total_count: totalCount } = action.payload as IFetchCampaignsResponse;

      const isFinal = !items || (Boolean(items.length) && items.length < MAX_CAMPAIGN_ITEMS_PER_PAGE);

      return {
        ...state,
        isLoading: false,
        error: null,
        totalCount,
        items: items && items.length ? convertArrayToObject<ICampaign, typeof BOX_ID>(items, BOX_ID) : {},
        isFinal,
      };
    }
    case CampaignsActions.FETCH_CAMPAIGN_BY_ID_REQUEST: {
      return { ...state, isLoading: true, error: null };
    }
    case CampaignsActions.FETCH_CAMPAIGN_BY_ID_FAILURE: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case CampaignsActions.FETCH_CAMPAIGN_BY_ID_SUCCESS: {
      const { campaign } = (action.payload as IFetchCampaignByIdResponsePayload) || {};

      return {
        ...state,
        items: {
          ...state.items,
          ...(campaign[BOX_ID] ? { [campaign[BOX_ID]]: campaign } : {}),
        },
        isLoading: false,
        error: null,
      };
    }
    case CampaignsActions.UPDATE_SHIPPING_CONFIG: {
      return {
        ...state,
        details: {
          ...state.details,

          shipping_configuration: (action as IReduxAction<Partial<ICampaignCandidate['shipping_configuration']>>)
            .payload
            ? { ...state.details?.shipping_configuration, ...action.payload }
            : undefined,
        },
      };
    }
    case CampaignsActions.ADD_CAMPAIGN_VALUE: {
      return {
        ...state,
        details: state.details
          ? {
              ...state.details,
              ...action.payload,
            }
          : {
              ...action.payload,
              items: action.payload.items.map(getBoxItem),
            },
      };
    }
    case CampaignsActions.DELETE_CAMPAIGN_SUCCESS: {
      if (state.items) {
        const { [(action.payload as IDeleteCampaignResponse).uid]: _, ...restItems } = state.items;

        return {
          ...state,
          items: restItems,
        };
      }

      return state;
    }
    case CampaignsActions.CLEAR_CAMPAIGN_DETAILS: {
      return {
        ...state,
        details: null,
      };
    }
    case CampaignsActions.CLEAR_CAMPAIGN_VALUE: {
      const campaignPropertyName = action.payload as keyof Omit<ICampaignCandidate, typeof BOX_ID>;
      return {
        ...state,
        details: {
          ...state.details,
          [campaignPropertyName]: INITIAL_CAMPAIGN_FORM_STATE[campaignPropertyName],
        },
      };
    }
    case CampaignsActions.SET_INTERMEDIATE_FC: {
      return {
        ...state,
        details: {
          ...state.details,
          [INTERMEDIATE_FC_ID]: action.payload,
        },
      };
    }
    case FirebaseActions.LOGOUT:
    case AuthActions.ADMIN_ROLE_CHANGED:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
