import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../../../assets/images/icon-close-cross.svg';
import { ReactComponent as RocketImage } from '../../../assets/images/rocket.svg';
import { ActionButton, CopyableButton } from '../../../components/forms';
import { routes } from '../../../constants/routing';
import { selectRocketModal } from '../../../store/selectors/modals';
import { ICreateEngagementResponse, ICreateOneLinkSendResponse, IFlowStepProps } from '../../../types/bucket';
import { IModalProperties } from '../../../types/modals';
import { Input } from '../../forms/inputs';
import { CsvUploadError, ModalHeader } from '../../index';

import styles from './RocketModal.module.scss';

interface IProps {
  onClose: () => void;
}

const RocketModal = ({ onClose }: IProps) => {
  const history = useHistory();
  const modal: IModalProperties<(ICreateEngagementResponse | ICreateOneLinkSendResponse) | null, IFlowStepProps> =
    useSelector(selectRocketModal);

  const {
    order_id: engagementId,
    digital_gifts: gifts,
    delayed_shipping_url,
  } = React.useMemo(() => {
    if (modal && modal.content) {
      return modal.content as ICreateEngagementResponse;
    }
    return { order_id: null, digital_gifts: null, delayed_shipping_url: null, url: null };
  }, [modal]);

  const {
    url: oneLinkUrl,
    password: oneLinkPassword,
    invalid_recipients: invalidRecipients,
  } = React.useMemo(() => {
    if (modal && modal.content) {
      return modal.content as ICreateOneLinkSendResponse;
    }
    return {
      order_id: null,
      digital_gifts: null,
      delayed_shipping_url: null,
      url: null,
      password: null,
      invalid_recipients: null,
    };
  }, [modal]);

  const renderInvalidRecipients = React.useMemo(() => {
    if (invalidRecipients?.length) {
      return <CsvUploadError failedRows={invalidRecipients} fileName="failed-recipients.csv" />;
    }
  }, [invalidRecipients]);

  const handleClose = React.useCallback(() => {
    history.push(routes.dashboard);
    onClose();
  }, [history, onClose]);

  const handleNewEngagementClick = () => {
    if (modal?.metadata?.isBulk) {
      history.push(routes.engagement.bulk);
    }
    if (modal?.metadata?.isSingle) {
      history.push(routes.engagement.new);
    }
    if (modal?.metadata?.isOneLink) {
      history.push(routes.engagement.oneLink);
    }
    // track(Analytics.trackContinueClick());
    onClose();
  };

  const title = React.useMemo(() => {
    if (modal.metadata?.isOneLink) {
      return 'One Link Send Created!';
    }
    return 'Send Initiated';
  }, [modal.metadata]);

  const info = React.useMemo(() => {
    if (modal.metadata?.isOneLink) {
      return 'Copy and send this link to your customers to initiate a send!';
    }
    return (
      <>
        <span>
          Your order ID <strong>#{engagementId}</strong> has been placed.
        </span>
        {renderInvalidRecipients}
      </>
    );
  }, [modal.metadata, renderInvalidRecipients]);

  const links = React.useMemo(() => {
    if (gifts && gifts.length) {
      return gifts.map((gift) => (
        <CopyableButton
          key={gift.url}
          value={gift.url}
          className={styles.linksItem}
          // onClick={() => track(Analytics.trackLinkClick(index))}
        >
          {gift.name}
        </CopyableButton>
      ));
    }
    if (delayed_shipping_url) {
      return (
        <CopyableButton
          value={delayed_shipping_url}
          className={styles.linksItem}
          // onClick={() => track(Analytics.trackLinkClick(index))}
        >
          Copy shipping page link
        </CopyableButton>
      );
    }
    if (oneLinkUrl && oneLinkPassword) {
      return (
        <>
          <div className={cn(styles.inputItem, styles.linksItem)}>
            <div className={styles.labelText}>URL</div>
            <Input value={oneLinkUrl} inputClassName={cn(styles.input)} className={styles.inputContainer} readOnly />
            <CopyableButton key="oneLinkUrl" value={oneLinkUrl} className={cn(styles.copyButton)}>
              copy
            </CopyableButton>
          </div>
          <div className={cn(styles.inputItem, styles.linksItem)}>
            <div className={styles.labelText}>Password</div>
            <Input
              value={oneLinkPassword}
              inputClassName={cn(styles.input)}
              className={styles.inputContainer}
              readOnly
            />
            <CopyableButton key="oneLinkPassword" value={oneLinkPassword} className={cn(styles.copyButton)}>
              copy
            </CopyableButton>
          </div>
        </>
      );
    }
    if (oneLinkUrl) {
      return (
        <CopyableButton
          value={oneLinkUrl}
          className={styles.linksItem}
          // onClick={() => track(Analytics.trackLinkClick(index))}
        >
          Copy One Link
        </CopyableButton>
      );
    }
    return null;
  }, [gifts, delayed_shipping_url, oneLinkUrl]);

  const buttonTitle = React.useMemo(() => {
    if (modal.metadata?.isOneLink) {
      return 'Create new one link send';
    }
    return 'Create new send';
  }, [modal.metadata]);

  return (
    <div className={cn(styles.container)}>
      <ModalHeader className={styles.header}>
        <div className={styles.closeButton} onClick={handleClose}>
          <CloseIcon />
        </div>
      </ModalHeader>
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.info}>{info}</div>
        <RocketImage className={styles.image} />
        <div className={styles.links}>{links}</div>
        <div className={styles.actions}>
          <ActionButton className={styles.btn} onClick={handleNewEngagementClick} title={buttonTitle} />
        </div>
      </div>
    </div>
  );
};

export default RocketModal;
