import { call, put, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { endpoints } from '../../constants/routing';
import { IOrganizationSummariesRequest } from '../../types/dashboard';
import { IReduxAction } from '../../types/redux';
import * as DashboardActions from '../actions/dashboard';
import { AuthRequest } from './helpers';

function* fetchOrganizationSummariesWorkerSaga(
  action: IReduxAction<IOrganizationSummariesRequest>,
): Generator<any, any, any> {
  try {
    const endpoint = `${apiUrl}${endpoints.getDashboard}`;

    const response = yield call<any>(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify(action.payload),
    });

    if (!response.ok) {
      yield put(DashboardActions.fetchOrganizationSummariesFailure(response.body));
    } else {
      yield put(DashboardActions.fetchOrganizationSummariesSuccess(response.body));
    }
  } catch (error) {
    yield put(DashboardActions.fetchOrganizationSummariesFailure(error));
  }
}

const sagas = {
  *watchFetchOrganizationSummariesRequest() {
    yield takeLatest(DashboardActions.FETCH_ORGANIZATION_SUMMARIES_REQUEST, fetchOrganizationSummariesWorkerSaga);
  },
};

export default sagas;
