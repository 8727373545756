import * as React from 'react';

interface IProps<T> {
  type?: string;
  name: string;
  value: T;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const HiddenInput = <T extends string | number | readonly string[] | undefined>({
  type = 'hidden',
  name,
  value,
  onChange,
}: IProps<T>) => {
  return <input name={name} type={type} value={value} onChange={onChange} />;
};

export default HiddenInput;
