import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Column, Row } from 'react-table';

import { ReactComponent as EditIcon } from '../../assets/images/icon-edit-gray.svg';
import { AddButton, AutomationTable, SecretInput } from '../../components';
import { selectAutomatedConnectionsByOrgId } from '../../store/selectors/organizations';
import { IAutomatedConnection } from '../../types/automations';
import { getAutomationStatusLabel } from '../../utils/automations';

import styles from './AutomatedConnectionsContainer.module.scss';

interface IProps {
  onAdd: () => void;
  onEdit: (connection: IAutomatedConnection) => void;
  orgId: string;
}

const AutomatedConnectionsContainer = ({ onAdd, onEdit, orgId }: IProps) => {
  const connections = useSelector(selectAutomatedConnectionsByOrgId(orgId));

  const columns: Column<IAutomatedConnection>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Key',
        accessor: 'token',
        Cell: ({ value }: { value: string }) => (
          <SecretInput value={value} className={styles.secretInput} readOnly={true} />
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }: { value: string }) => {
          const { message, color } = getAutomationStatusLabel(value, 'Connection');

          return (
            <div className={styles.statusWrapper}>
              <div
                style={{
                  backgroundColor: color,
                }}
                className={styles.statusBullet}
              />
              {message}
            </div>
          );
        },
      },
      {
        id: 'action',
        Cell: ({ row }: { row: Row<IAutomatedConnection> }) => (
          <button onClick={() => onEdit(row.original)}>
            <EditIcon />
          </button>
        ),
      },
    ],
    [onEdit],
  );

  return (
    <>
      <div className={styles.header}>
        <AddButton assetName="" onClick={onAdd} className={styles.addButton} />
      </div>
      <AutomationTable
        data={connections}
        columns={columns}
        placeholderText="There are no connections"
        className={styles.tableBody}
      />
    </>
  );
};

export default AutomatedConnectionsContainer;
