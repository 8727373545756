import { createSelector } from 'reselect';

import { IAppState } from '../reducers';

export const selectRootState = (state: IAppState) => state.dashboard;

export const selectOrganizationSummaries = createSelector(selectRootState, (dashboard) =>
  dashboard.summaries ? dashboard.summaries : undefined,
);

export const selectIsOrganizationSummariesFetching = createSelector(selectRootState, (dashboard) =>
  dashboard ? dashboard.isLoading : false,
);

export const selectActiveSummary = createSelector(selectRootState, (dashboard) =>
  dashboard ? dashboard.activeSummary : null,
);

export const selectDashboardFilters = createSelector(selectRootState, (dashboard) =>
  dashboard ? dashboard.filters : undefined,
);

export const selectDashboardDateRangeFilter = createSelector(selectDashboardFilters, (filters) =>
  filters ? filters.dateRange : undefined,
);
