import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import {
  DELAYED_SHIPPING_EXPIRATION,
  DISABLE_SHIPMENT_TRACKING,
  IS_TOGGLE_ON_HOLD_ENABLED,
  SEND_ON_BEHALF_THE_EMAIL,
  SEND_ON_HOLD,
} from '../../constants/bucket';
import { BUSINESS_REASON_REQUIRED, DISABLE_HANDWRITTEN_NOTE_STEP, USE_EMAIL_BRANDING } from '../../constants/campaigns';
import {
  ALLOWED_DOMAIN,
  DISABLE_SENDER_EMAIL_NOTIFICATIONS,
  ENABLED_2FA,
  IS_RECEIVER_ADDRESS_FIXED,
  PASSWORD,
  RECEIVER_FIXED_ADDRESS,
  RECIPIENTS,
  SHIPPING_OPTION,
} from '../../constants/shipping';
import { INITIAL_ADD_EMAIL_FORM_STATE, INITIAL_ADD_NOTE_FORM_STATE } from '../../constants/templates';
import { IBucketState } from '../../types/bucket';
import { ICampaign, ICustomSend } from '../../types/campaigns';
import { TOneLinkRecipient } from '../../types/oneLink';
import { IShippingFormValues, ShippingOptionsEnum } from '../../types/shipping';
import { isDigitalBox } from '../../utils/campaigns';
import { isItemDigital, isItemOnDemand } from '../../utils/inventories';
import * as AuthActions from '../actions/auth';
import * as BucketActions from '../actions/bucket';
import * as EngagementActions from '../actions/engagement';
import * as IntegrationsActions from '../actions/integrations';
import * as OneLinkActions from '../actions/oneLink';

export const initialState: IBucketState = {
  flow: null,
  bulk: {
    csv: null,
    result: null,
    [DISABLE_SHIPMENT_TRACKING]: false,
    isLoading: false,
    isSubmitted: false,
  },
  expiration_date: null,
  pre_created_engagement_id: '',
  engagement_source_type: '',
  business_reason_id: '',
  internal_notes: '',
  [SEND_ON_HOLD]: '',
  [DELAYED_SHIPPING_EXPIRATION]: '',
  [IS_TOGGLE_ON_HOLD_ENABLED]: false,
  [DISABLE_HANDWRITTEN_NOTE_STEP]: false,
  [SEND_ON_BEHALF_THE_EMAIL]: null,
  [PASSWORD]: '',
  [ALLOWED_DOMAIN]: '',
  [ENABLED_2FA]: false,
  [RECIPIENTS]: null,
  shipping: {
    [SHIPPING_OPTION]: ShippingOptionsEnum.Ground,
  },
  note: INITIAL_ADD_NOTE_FORM_STATE,
  isNoteSkipped: true,
  email: INITIAL_ADD_EMAIL_FORM_STATE,
  pick_campaigns: null,
  items: [],
  isFetching: false,
  error: null,
  [DISABLE_SENDER_EMAIL_NOTIFICATIONS]: true,
  receiver_reminder_interval: '',
  campaignBranding: null,
  [IS_RECEIVER_ADDRESS_FIXED]: false,
  [RECEIVER_FIXED_ADDRESS]: undefined,
};

const reducer = (state: IBucketState = initialState, action: any): IBucketState => {
  switch (action.type) {
    case AuthActions.ADMIN_ROLE_CHANGED:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS:
    case FirebaseActions.LOGOUT: {
      return initialState;
    }
    case BucketActions.ADD_TO_BUCKET: {
      // TODO: Review this action handler during the CustomSend feature implementation and add TS to payload
      const itemToAdd = action.payload;
      const items = state.items.slice();

      const item = isItemOnDemand(itemToAdd)
        ? items.find((i) => i.product_sku === itemToAdd.product_sku)
        : items.find((i) => i.item_id === itemToAdd.item_id);

      if (item && !isItemDigital(item)) {
        item.quantity = item.quantity + itemToAdd.quantity;
      } else {
        items.push(itemToAdd);
      }
      return {
        ...state,
        items,
      };
    }
    case BucketActions.REMOVE_FROM_BUCKET: {
      const items = state.items.filter(
        (item) =>
          item.item_id
            ? item.item_id !== action.payload // InventoryItem
            : item.product_sku !== action.payload, // OnDemandItem
      );

      return {
        ...state,
        items,
      };
    }
    case BucketActions.CHANGE_BUCKET_ITEM_VALUE: {
      const { id, value, name } = action.payload;
      const itemToUpdate = state.items.find((item) => item.item_id === id);

      if (itemToUpdate) {
        return {
          ...state,
          items: state.items.map((item) =>
            item.item_id === itemToUpdate.item_id
              ? {
                  ...item,
                  [name]: value,
                }
              : item,
          ),
        };
      }

      return { ...state };
    }
    case BucketActions.ADD_SHIPPING_VALUE:
    case BucketActions.ADD_SHIPPING_INFORMATION: {
      const { disable_sender_email_notifications, ...shippingUpdates } = action.payload as Partial<IShippingFormValues>;
      return {
        ...state,
        ...(typeof disable_sender_email_notifications !== 'undefined'
          ? {
              [DISABLE_SENDER_EMAIL_NOTIFICATIONS]: disable_sender_email_notifications,
            }
          : {}),
        shipping: {
          ...state.shipping,
          ...shippingUpdates,
        },
      };
    }
    case BucketActions.ADD_NOTE: {
      return {
        ...state,
        note: action.payload,
      };
    }
    case BucketActions.TOGGLE_SKIP_NOTE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case BucketActions.ADD_EMAIL: {
      return {
        ...state,
        email: { ...state.email, ...action.payload },
      };
    }
    case BucketActions.FLUSH_BUCKET: {
      return { ...initialState };
    }
    case EngagementActions.POST_ENGAGEMENT_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    }
    case EngagementActions.POST_ENGAGEMENT_SUCCESS: {
      return {
        ...state,
        pre_created_engagement_id: '',
        business_reason_id: '',
        internal_notes: '',
        isFetching: false,
      };
    }
    case EngagementActions.POST_ENGAGEMENT_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : action.payload,
      };
    }
    case BucketActions.PRE_SELECTED_BOX_CONFIRMED: {
      const payload = action.payload as ICampaign | ICustomSend;

      const { box_id, items } = payload;
      // tslint:disable-next-line:variable-name
      const disable_handwritten_note_step =
        'disable_handwritten_note_step' in payload ? payload.disable_handwritten_note_step : false;
      // tslint:disable-next-line:variable-name
      const business_reason_required = 'business_reason_required' in payload ? payload.business_reason_required : false;

      let campaignBranding: Pick<ICampaign, 'ds_company_name'> | null = null;

      if (USE_EMAIL_BRANDING in payload) {
        const { use_email_branding, ds_company_name } = payload as ICampaign;

        campaignBranding = use_email_branding ? { ds_company_name } : null;
      }

      return {
        ...state,
        pre_created_engagement_id: box_id,
        disable_handwritten_note_step,
        business_reason_required,
        items,
        pick_campaigns: null,
        campaignBranding,
      };
    }
    case BucketActions.PICK_YOUR_GIFT_BOXES_CONFIRMED: {
      const campaigns = action.payload as ICampaign[];
      const physicalCampaigns = campaigns.filter((box) => !isDigitalBox(box));
      const isNoteCardStepDisabled = physicalCampaigns.every((campaign) => campaign[DISABLE_HANDWRITTEN_NOTE_STEP]);
      const isBusinessReasonRequired = campaigns.some((campaign: ICampaign) => campaign[BUSINESS_REASON_REQUIRED]);

      return {
        ...state,
        [DISABLE_HANDWRITTEN_NOTE_STEP]: isNoteCardStepDisabled,
        [BUSINESS_REASON_REQUIRED]: isBusinessReasonRequired,
        campaignBranding: initialState.campaignBranding,
      };
    }
    case BucketActions.UPDATE_ONE_LINK_SHIPPING_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case BucketActions.RESET_BUCKET_ERRORS: {
      return {
        ...state,
        error: null,
      };
    }
    case IntegrationsActions.CHOOSE_SEARCH_ITEM: {
      return {
        ...state,
        shipping: action.payload,
      };
    }
    case IntegrationsActions.SET_OPPORTUNITY: {
      return {
        ...state,
        shipping: {
          ...state.shipping,
          ...action.payload,
        },
      };
    }
    case BucketActions.UPLOAD_BULK_CSV: {
      return {
        ...state,
        bulk: {
          ...state.bulk,
          result: null,
        },
      };
    }
    case BucketActions.ADD_BULK_DATA: {
      return {
        ...state,
        bulk: {
          ...state.bulk,
          ...action.payload,
        },
      };
    }
    case BucketActions.CLEAR_BULK_DATA: {
      return {
        ...state,
        bulk: { ...initialState.bulk },
      };
    }
    case BucketActions.BULK_ENGAGEMENT_REQUEST: {
      return {
        ...state,
        bulk: {
          ...state.bulk,
          result: null,
          isLoading: true,
        },
      };
    }
    case BucketActions.BULK_ENGAGEMENT_SUCCESS:
    case BucketActions.BULK_ENGAGEMENT_FAILURE: {
      return {
        ...state,
        bulk: {
          ...state.bulk,
          csv: null,
          result: action.payload,
          isLoading: false,
          isSubmitted: true,
        },
      };
    }
    case BucketActions.BULK_ENGAGEMENT_API_FAILURE: {
      return {
        ...state,
        bulk: {
          ...state.bulk,
          csv: null,
          result: null,
          isLoading: false,
        },
      };
    }
    case BucketActions.ADD_BUCKET_VALUE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case BucketActions.START_ENGAGEMENT_FLOW: {
      return { ...state, flow: action.payload };
    }
    case BucketActions.TOGGLE_PICK_YOUR_GIFT: {
      return {
        ...state,
        pick_campaigns: action.payload ? [] : null,
      };
    }
    case BucketActions.UPLOAD_2FA_RECIPIENTS: {
      const { successfulRecipients, failedRecipients } = action.payload;

      return {
        ...state,
        recipients: {
          successfulRecipients: state?.recipients?.successfulRecipients
            ? [...successfulRecipients, ...state.recipients.successfulRecipients]
            : successfulRecipients,
          failedRecipients,
        },
      };
    }
    case BucketActions.DELETE_ALL_ALLOWED_RECIPIENTS: {
      return {
        ...state,
        recipients: {
          successfulRecipients: [],
          failedRecipients: [],
        },
      };
    }
    case BucketActions.DELETE_ALLOWED_RECIPIENT: {
      return {
        ...state,
        recipients: {
          successfulRecipients: state.recipients?.successfulRecipients?.filter(
            (recipient: TOneLinkRecipient) => recipient.id !== action.payload,
          ),
        },
      };
    }
    case BucketActions.EDIT_ALLOWED_RECIPIENT: {
      const { id } = action.payload;
      const successfulRecipients = state?.recipients?.successfulRecipients
        ? [...state.recipients.successfulRecipients]
        : [];

      const updatedRecipientIndex = successfulRecipients?.findIndex(
        (recipient: TOneLinkRecipient) => recipient.id === id,
      );
      if (typeof updatedRecipientIndex !== 'undefined') {
        successfulRecipients[updatedRecipientIndex] = action.payload;
      }

      return {
        ...state,
        recipients: {
          successfulRecipients,
        },
      };
    }
    case BucketActions.ADD_ALLOWED_RECIPIENT: {
      const newRecipient = action.payload;

      return {
        ...state,
        recipients: {
          successfulRecipients: state?.recipients?.successfulRecipients
            ? [newRecipient, ...state.recipients.successfulRecipients]
            : [newRecipient],
        },
      };
    }
    case BucketActions.SET_PICK_YOUR_GIFT: {
      const items = (() => {
        const pickCampaigns = state.pick_campaigns?.slice();
        const { box_id: payloadBoxId } = action.payload;

        if (!pickCampaigns || !pickCampaigns.length) {
          return [action.payload];
        }

        const searchCampaign =
          (search: boolean) =>
          ({ box_id }: ICampaign) =>
            search ? box_id === payloadBoxId : box_id !== payloadBoxId;
        const isExistCampaign = pickCampaigns.some(searchCampaign(true));

        // If campaign exists, it's removed from the pick_campaigns array. In other case it's added
        return isExistCampaign ? pickCampaigns.filter(searchCampaign(false)) : [...pickCampaigns, action.payload];
      })();

      return {
        ...state,
        pick_campaigns: items,
        pre_created_engagement_id: '',
        items: [],
      };
    }
    case BucketActions.RESET_CUSTOM_SEND: {
      return {
        ...state,
        items: [],
        pre_created_engagement_id: '',
      };
    }
    case OneLinkActions.SET_ONE_LINK_FIXED_ADDRESS: {
      return {
        ...state,
        receiver_fixed_address: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
