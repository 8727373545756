import cn from 'classnames';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SOBSelector from '../../components/SOBSelector/SOBSelector';
import { fetchFulfillmentCentersRequest } from '../../store/actions/fulfillmentCenters';
import { selectAdminType } from '../../store/selectors/auth';
import { selectCurrentOrganizationOptions } from '../../store/selectors/organizations';
import { IFlowStepProps } from '../../types/bucket';
import { isAdminType } from '../../utils/users';
import { AddRecipientContainer, OneLinkShippingContainer } from '../index';

import styles from './ShippingStepContainer.module.scss';

const ShippingStepContainer = (props: IFlowStepProps) => {
  const { send_on_behalf_of_enabled: isSOBAvailableInOrg } = useSelector(selectCurrentOrganizationOptions) || {};
  const adminType = useSelector(selectAdminType);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFulfillmentCentersRequest());
  }, [dispatch]);

  return (
    <div className={cn(styles.container)}>
      <div className={styles.form}>
        {props.isOneLink ? <OneLinkShippingContainer {...props} /> : <AddRecipientContainer {...props} />}
      </div>

      {isSOBAvailableInOrg && isAdminType(adminType) && !props.isDirectSend && (
        <div className={styles.sob}>
          <SOBSelector />
        </div>
      )}
    </div>
  );
};

export default ShippingStepContainer;
