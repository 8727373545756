import { IFileInfo } from './bucket';
import { IPromiseCallbacks } from './redux';
import { SearchByTypesEnum } from './shell';
import { IImageLibraryItem } from './upload';

export interface IInventoriesState {
  csvUpload: {
    file: IFileInfo | null;
    result: IUploadInventoryCSVResponsePayload | null;
    isLoading: boolean;
  };
  items: { [key: string]: IInventoryItem } | null;
  lowInventoryItems: IInventoryItem[] | null;
  itemDetails: Partial<IInventoryItemCandidate> | null;
  isLoading: boolean;
  isLowInventoryLoading: boolean;
  isInventoryReportLoading: boolean;
  error: any;
  isFinal: boolean;
  totalCount: number;
}

export enum InventoryFilterTypesEnum {
  All = 'all',
  Physical = 'physical',
  Digital = 'digital',
  MSKU = 'msku',
}

export enum InventoryTypesEnum {
  Digital = 'digital',
  Physical = 'physical',
  MSKU = 'msku',
}

export enum GenderEnum {
  Male = 'male',
  Female = 'female',
  Unisex = 'unisex',
  Empty = '',
}

export interface IInventoryWithType {
  type: InventoryTypesEnum;
}

export interface ITypedItemsPick {
  items?: IInventoryWithType[] | null;
}

export interface ICustomizableItem {
  item_customization_allowed?: boolean;
}

export interface IGenericItemWithCustomization extends ICustomizableItem {
  sku_options?: ICustomizableItem[];
}

export interface IInventoryItem extends IInventoryWithType {
  item_id: string;
  name: string;
  description: string;
  image_url: string;
  fulfillment_center_id: string;
  department_ids: string[];
  org_id: string;
  price?: number;
  currency_code?: string;
  digital_campaign_key?: string;
  product_sku?: string;
  country_of_origin?: string;
  is_hidden_for_recipient?: boolean;
  sku_options?: IInventoryItem[];
  flavor?: string;
  size?: string;
  gender?: GenderEnum;
  other_option?: string;
  hts_code?: string;
  color?: string;
  count?: number;
  item_customization_allowed?: boolean;
  item_customization_required?: boolean;
  item_customization_text_label?: string;
  preorder_allowed?: boolean;
}

export interface IRequestOnDemandItem
  extends Pick<IInventoryItem, 'name' | 'description' | 'price' | 'image_url' | 'fulfillment_center_id' | 'type'> {
  product_sku: string;
  color?: string;
  quantity?: number;
  on_demand?: boolean;
  count?: number;
  preorder_allowed: never;

  // next fields added for compatibility with IInventoryItem and ICampaignItem
  is_hidden_for_recipient?: boolean;
  department_ids?: never;
  sku_options?: never;
  org_id: never;
  size?: never;
  flavor?: never;
  gender?: never;
  other_option?: never;
  item_customization_allowed?: never;
}

export interface IOnDemandItem extends IRequestOnDemandItem {
  item_id: string;
}

export interface IInventoryItemCandidate extends Omit<IInventoryItem, 'fulfillment_center_id'> {
  fulfillment_center_id: string | null;
}

//#region Request interfaces

export interface IUploadInventoryCSVRequestPayload extends IPromiseCallbacks {
  file_name: string;
  fc_id: string;
}

export interface IUploadInventoryCSVResponsePayload {
  error_file_url?: string;
}

export interface ISetUploadInventoryCSVFilePayload {
  file: IFileInfo | null;
}

export interface IFetchInventoryItemsRequestPayload {
  type: InventoryFilterTypesEnum | InventoryFilterTypesEnum[];
  types?: InventoryFilterTypesEnum | InventoryFilterTypesEnum[];
  search_by?: SearchByTypesEnum;
  search_query?: string;
  department_id?: string;
  fulfillment_center_id?: string;
  page?: number;
  page_size: number;
}

export interface IFetchInventoryItemsRequestMetadata {
  shouldClearItems?: boolean;
}

export interface IFetchLowInventoryItemsRequest extends IPromiseCallbacks {
  showModal?: boolean;
}

export interface IFetchInventoryItemByIdRequest {
  itemId: string;
  includeInventoryCount: boolean;
}

export interface IFetchInventoryItemsResponse {
  inventory_items: IInventoryItem[];
  total_count: number;
}

export interface IFetchLowInventoryItemsResponse {
  low_inventory_items: IInventoryItem[] | null;
}

export interface IFetchInventoryItemResponse {
  inventory_item: IInventoryItem;
}

export interface IFetchInventoryImagesResponse {
  images: IImageLibraryItem[];
}

export interface IAddInventoryItemRequest extends IPromiseCallbacks {
  item: Partial<IInventoryItemCandidate>;
}

export interface IEditInventoryItemRequest extends IPromiseCallbacks {
  item: Partial<IInventoryItemCandidate>;
}

export interface IDeleteInventoryItemRequest extends IPromiseCallbacks {
  itemId: string;
}

export interface IDeleteInventoryItemResponse {
  itemId: string;
}

//#endregion
