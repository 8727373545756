import cn from 'classnames';
import * as React from 'react';

import useWindowSize from '../../../../hooks/useWindowSize';
import ActionButton, { IActionButtonProps } from '../ActionButton/ActionButton';

import styles from './AddButton.module.scss';

interface IProps extends IActionButtonProps {
  assetName?: string;
}

const AddButton: React.FC<IProps> = ({ assetName = 'item', className, ...props }) => {
  const { mobile } = useWindowSize();

  return (
    <ActionButton {...props} className={cn(styles.addButton, className)}>
      {mobile ? '+' : `Add new ${assetName}`}
    </ActionButton>
  );
};

export default AddButton;
