import React, { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTheme } from '../store/selectors/shell';
import { ITheme } from '../types/shell';
import { setThemeToCSSVariables } from '../utils/helpers';

const ThemeContext = React.createContext<ITheme | undefined>(undefined);
const UpdateThemeContext = React.createContext<(newTheme?: ITheme) => void>(() => void 0);

interface IProps {
  children: React.ReactNode | React.ReactNode[];
}

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const useUpdateTheme = () => {
  return useContext(UpdateThemeContext);
};

export const ThemeProvider: React.FC<IProps> = ({ children }) => {
  const initialTheme = useSelector(selectTheme);

  const [theme, setTheme] = useState(initialTheme);

  const updateTheme = useCallback(
    (newTheme?: ITheme) => {
      if (newTheme) {
        setTheme(newTheme);
      } else {
        setTheme(initialTheme);
      }
    },
    [initialTheme],
  );

  React.useEffect(() => {
    setThemeToCSSVariables(theme);
  }, [theme]);

  React.useEffect(() => {
    setTheme(initialTheme);
  }, [initialTheme]);

  return (
    <ThemeContext.Provider value={theme}>
      <UpdateThemeContext.Provider value={updateTheme}>{children}</UpdateThemeContext.Provider>
    </ThemeContext.Provider>
  );
};
