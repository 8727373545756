import cn from 'classnames';
import * as React from 'react';
import { useRef } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import useElementSize from '../../hooks/useElementSize';
import { Loader } from '../index';

import styles from './TabProvider.module.scss';

export interface ITabProviderTab {
  tabId: string;
  label: string;
  renderer: React.ReactNode;
}

interface IProps {
  className?: string;
  disabled?: boolean;
  tabs: ITabProviderTab[];
  onSelect: (index: number) => void;
  activeTab: number;
  children?: React.ReactNode;
  rendererClassName?: string;
  tabClassName?: string;
  loaderOnChange?: boolean;
}

const TabProvider = ({
  children,
  tabs,
  className,
  rendererClassName,
  tabClassName,
  onSelect,
  activeTab,
  disabled,
  loaderOnChange,
}: IProps) => {
  const [changing, setChanging] = React.useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const { height: tabHeight } = useElementSize({ ref, ignoreZero: true });

  const handleSwitch = React.useCallback(
    (index: number) => {
      if (activeTab !== index) {
        setChanging(true);
        onSelect(index);
      }
    },
    [activeTab, onSelect],
  );

  React.useEffect(() => {
    if (changing) {
      setTimeout(() => setChanging(false), 500);
    }
  }, [changing]);

  return (
    <Tabs
      className={cn(styles.container, className)}
      selectedTabClassName={styles.selectedTab}
      selectedTabPanelClassName={styles.selectedPanel}
      selectedIndex={activeTab}
      onSelect={handleSwitch}
      disabled={changing || disabled}
    >
      <TabList className={styles.tabList}>
        {tabs.map(({ label, tabId }, index) => (
          <Tab key={tabId} className={cn(styles.tab, tabClassName, { [styles.active]: index === activeTab })}>
            {label}
          </Tab>
        ))}
      </TabList>
      {tabs.map(({ renderer, tabId }) => (
        <TabPanel key={tabId} className={cn(styles.tabPanel, rendererClassName)}>
          <div className={styles.contentWrapper} ref={ref}>
            {!changing && renderer}
          </div>
          {loaderOnChange && changing && <Loader style={{ height: tabHeight }} className={styles.loader} isLoading />}
        </TabPanel>
      ))}
      {children}
    </Tabs>
  );
};

export default TabProvider;
