import React from 'react';
import { components, GroupBase, OptionProps } from 'react-select';

import { ISelectorValueWithOrgAddress } from '../../../../../../types/addressBook';
import { BUTTON_BUTTON } from '../../../../../../types/forms';
import { CompactOrgAddressView } from '../../../../../index';

export const OrgAddressOption:
  | React.ComponentType<OptionProps<ISelectorValueWithOrgAddress, boolean, GroupBase<ISelectorValueWithOrgAddress>>>
  | undefined = (props) => {
  const { className, selectOption, data } = props;

  const handleClick = React.useCallback(() => {
    selectOption(data);
  }, [data, selectOption]);

  return (
    <components.Option {...props}>
      <button onClick={handleClick} type={BUTTON_BUTTON} className={className}>
        <CompactOrgAddressView row={data.address} />
      </button>
    </components.Option>
  );
};
