import { contentTypeRegex, contentTypes, noContentTypeParseResult } from '../constants/http';
import {
  IContentTypeParseResult,
  IRequestParams,
  IRequestQuery,
  IResponse,
  JSONType,
  RequestStatus,
} from '../types/http';

export const getQuery = (query?: IRequestQuery | string | number | boolean, key?: string) => {
  if (typeof query !== 'undefined') {
    let queryParams: IRequestQuery = {};

    if (typeof query !== 'object') {
      if (!key) {
        throw new Error('No query key was provided.');
      }
      queryParams = { [key]: query } as IRequestQuery;
    } else {
      queryParams = query as unknown as IRequestQuery;
    }

    if (queryParams) {
      return queryParams;
    }
    throw new Error('An error has occurred during query processing');
  }
  return;
};

const parseContentType = (response: Response): IContentTypeParseResult => {
  // Read before asking questions or complaining about implementation:
  // https://www.w3.org/Protocols/rfc1341/4_Content-Type.html
  const input = response.headers.get('Content-Type');
  const { status } = response;

  switch (status) {
    case 204: {
      return noContentTypeParseResult;
    }
    default: {
      if (!input) {
        return noContentTypeParseResult;
      }

      const [matched, type, subtype, rest] = input.match(contentTypeRegex) || Array(4);

      if (!matched || !type || !subtype) {
        throw new Error(`Content-Type parsing failed for input: ${input}`);
      }

      if (!contentTypes.includes(type)) {
        console.warn(
          `Content-Type "${input} was interpreted as "${type}, rather than an expected standard like: ${contentTypes.join(
            ', ',
          )}`,
        );
      }

      return { type, subtype, rest };
    }
  }
};

const parseResponse = (response: Response): Promise<JSONType> => {
  let parsed: IContentTypeParseResult = {};

  // Try to refine the content type, but understand that this relies on RegExp
  // behavior and is nonstandard. We'll suppress the error and continue on,
  // trying the raw Content-Type value (which may itself be valid anyway).
  try {
    parsed = parseContentType(response);
  } catch (error) {
    console.error(error);
  }

  if (parsed && parsed.subtype) {
    switch (parsed.subtype.toLowerCase()) {
      case 'json':
        try {
          return response.json ? response.json() : Promise.resolve(null);
        } catch (error) {
          console.error(error);
          return Promise.reject(new Error("Got a JSON response, but it couldn't be parsed"));
        }

      case 'plain':
      case 'html':
        // These may be returned on a 500 status, or other unexpected outcome.
        try {
          return response.text ? response.text().then((text) => ({ text })) : Promise.resolve(null);
        } catch (error) {
          return Promise.reject(new Error("Got a text response, but it couldn't be parsed"));
        }

      case 'no-content':
        return Promise.resolve(null);

      case 'xml':
        return Promise.reject(new Error('Unexpected XML response'));

      default:
        return Promise.reject(new Error(`Unrecognized Content-Type: ${response.headers.get('Content-Type')}`));
    }
  }
  return Promise.reject(new Error(`Unrecognized Content-Type: ${response.headers.get('Content-Type')}`));
};

const handleResponseStatus = (
  response: Response,
  body: JSONType,
): { response: Response; body: any; status: RequestStatus } => {
  let status: RequestStatus;

  if (response.status < 200) {
    // 1XX STATUS: INFORMATIONAL
    throw new Error('Unexpected informational status');
  }
  if (response.status < 300) {
    // 2XX STATUS: OK
    status = 'SUCCESS';
    body = body as JSONType | null;
  } else if (response.status < 400) {
    // 3XX STATUS: REDIRECT
    throw new Error('Unexpected redirect');
  } else if (response.status < 500) {
    // 4XX STATUS: CLIENT ERROR
    status = 'FAILURE';
    body = body as JSONType | null;
  } else if (response.status < 600) {
    // 5XX STATUS: SERVER ERROR
    status = 'FAILURE';
    body = body as JSONType | null;
  } else {
    throw new Error(`Unknown HTTP status: ${response.status}`);
  }

  return { response, body, status };
};

const handleException = (response: any) => {
  if (response instanceof Error) {
    throw response;
  }
  if (typeof response.text === 'function') {
    return response.text().then((text: any) => {
      let error;
      try {
        error = JSON.parse(text);
      } catch (e) {
        console.error('Error could not be parsed as JSON, falling back to text');
        error = text;
      }

      if (error === '') {
        error = {
          status: response.status,
          statusText: response.statusText,
        };
      }

      throw error;
    });
  }

  throw new Error(
    JSON.stringify(
      {
        message: 'Unhandled exception for response',
        response,
      },
      null,
      2,
    ),
  );
};

export const Request = <ResponseType>({
  endpoint,
  method = 'GET',
  headers = null,
  body,
  isJson = true,
  queryParams,
  signal,
}: IRequestParams): Promise<IResponse<ResponseType>> => {
  if (queryParams && Object.keys(queryParams).length) {
    const qs = Object.keys(queryParams)
      .map((key: string) => `${key}=${queryParams[key]}`)
      .join('&');

    endpoint = `${endpoint}?${qs}`;
  }

  const requestHeaders = {
    ...(isJson
      ? {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      : {}),
    ...(headers ? headers : {}),
  };

  let response: Response;

  return fetch(endpoint, {
    headers: new Headers(requestHeaders),
    method,
    mode: 'cors',
    body,
    signal,
  })
    .then((responseData) => {
      response = responseData;
      return response;
    })
    .then(parseResponse)
    .then((parsedBody) => handleResponseStatus(response, parsedBody))
    .then(({ body: parsedBody, status }) =>
      Promise.resolve({ body: parsedBody, ok: status === 'SUCCESS', status: response.status }),
    )
    .catch(handleException);
};

export const QueryRequest = <ResponseType>(params: IRequestParams, token?: string) =>
  Request<ResponseType>({
    ...params,
    headers: { ...params.headers, ...(token ? { Authorization: `Bearer ${token}` } : {}) },
  });
