import { createTransform } from 'redux-persist';

import { ITemplatesState } from '../../types/templates';

const emailsTransform = createTransform(
  (stateIn: ITemplatesState) => stateIn,
  (stateOut: ITemplatesState) => {
    return {
      ...stateOut,
      emails: { ...stateOut.emails, error: null, isLoading: false },
    };
  },
  { whitelist: ['templates'] },
);

const notesTransform = createTransform(
  (stateIn: ITemplatesState) => stateIn,
  (stateOut: ITemplatesState) => {
    return {
      ...stateOut,
      notes: { ...stateOut.notes, error: null, isLoading: false },
    };
  },
  { whitelist: ['templates'] },
);

const templateDetailsTransform = createTransform(
  (stateIn: ITemplatesState) => stateIn,
  (stateOut: ITemplatesState) => {
    return {
      ...stateOut,
      templateDetails: { ...stateOut.templateDetails, error: null, isLoading: false },
    };
  },
  { whitelist: ['templates'] },
);

const transformArray = [templateDetailsTransform, notesTransform, emailsTransform];

export default transformArray;
