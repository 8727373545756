import cn from 'classnames';
import * as React from 'react';
import { animated } from 'react-spring';

import ScaleInOut from '../../../../animations/ScaleInOut';
import { IActionButtonProps } from '../ActionButton/ActionButton';
import { ActionButton } from '../index';

import styles from './FloatingButton.module.scss';

interface IProps extends IActionButtonProps {
  className?: string;
  trigger?: boolean;
  buttonClassName?: string;
}

const FloatingButton = ({ buttonClassName, className, trigger = true, ...props }: IProps) => {
  return (
    <ScaleInOut trigger={trigger}>
      {({ style }) => (
        <animated.div style={style} className={cn(className, styles.container)}>
          <ActionButton {...props} className={buttonClassName} />
        </animated.div>
      )}
    </ScaleInOut>
  );
};

export default FloatingButton;
