import {
  IAddOrganizationActionPayload,
  IAddOrganizationDepartmentActionPayload,
  IDepartment,
  IDepartmentBudgetSummariesResponse,
  IEditOrganizationActionPayload,
  IEditOrganizationDepartmentActionPayload,
  IFetchOrganizationRequestActionPayload,
  IFetchOrganizationSuccessActionPayload,
  IOrganizationItem,
  IOrganizationRequestItem,
  IRemovePIIRequestPayload,
} from '../../types/organizations';
import { IApiError } from '../../types/shell';

export const FETCH_ORGANIZATIONS_REQUEST = 'FETCH_ORGANIZATIONS_REQUEST';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATIONS_FAILURE = 'FETCH_ORGANIZATIONS_FAILURE';

export const ADD_ORGANIZATION_REQUEST = 'ADD_ORGANIZATION_REQUEST';
export const ADD_ORGANIZATION_SUCCESS = 'ADD_ORGANIZATION_SUCCESS';
export const ADD_ORGANIZATION_FAILURE = 'ADD_ORGANIZATION_FAILURE';

export const EDIT_ORGANIZATION_REQUEST = 'EDIT_ORGANIZATION_REQUEST';
export const EDIT_ORGANIZATION_SUCCESS = 'EDIT_ORGANIZATION_SUCCESS';
export const EDIT_ORGANIZATION_FAILURE = 'EDIT_ORGANIZATION_FAILURE';

export const ADD_ORGANIZATION_DEPARTMENT_REQUEST = 'ADD_ORGANIZATION_DEPARTMENT_REQUEST';
export const ADD_ORGANIZATION_DEPARTMENT_SUCCESS = 'ADD_ORGANIZATION_DEPARTMENT_SUCCESS';
export const ADD_ORGANIZATION_DEPARTMENT_FAILURE = 'ADD_ORGANIZATION_DEPARTMENT_FAILURE';

export const EDIT_ORGANIZATION_DEPARTMENT_REQUEST = 'EDIT_ORGANIZATION_DEPARTMENT_REQUEST';
export const EDIT_ORGANIZATION_DEPARTMENT_SUCCESS = 'EDIT_ORGANIZATION_DEPARTMENT_SUCCESS';
export const EDIT_ORGANIZATION_DEPARTMENT_FAILURE = 'EDIT_ORGANIZATION_DEPARTMENT_FAILURE';

export const ADD_ORGANIZATION_DETAILS = 'ADD_ORGANIZATION_DETAILS';
export const CLEAR_ORGANIZATION_DETAILS = 'CLEAR_ORGANIZATION_DETAILS';

export const GET_DEPARTMENT_BUDGET_SUMMARIES_REQUEST = 'GET_DEPARTMENT_BUDGET_SUMMARIES_REQUEST';
export const GET_DEPARTMENT_BUDGET_SUMMARIES_SUCCESS = 'GET_DEPARTMENT_BUDGET_SUMMARIES_SUCCESS';
export const GET_DEPARTMENT_BUDGET_SUMMARIES_FAILURE = 'GET_DEPARTMENT_BUDGET_SUMMARIES_FAILURE';

export const REMOVE_PII_REQUEST = 'REMOVE_PII_REQUEST';
export const REMOVE_PII_SUCCESS = 'REMOVE_PII_SUCCESS';
export const REMOVE_PII_FAILURE = 'REMOVE_PII_FAILURE';

export const fetchOrganizationsRequest = (payload?: IFetchOrganizationRequestActionPayload) => ({
  type: FETCH_ORGANIZATIONS_REQUEST,
  payload,
});

export const fetchOrganizationsSuccess = (payload: IFetchOrganizationSuccessActionPayload) => ({
  type: FETCH_ORGANIZATIONS_SUCCESS,
  payload,
});

export const fetchOrganizationsFailure = (payload: any) => ({
  type: FETCH_ORGANIZATIONS_FAILURE,
  payload,
});

export const addOrganizationDepartmentRequest = (payload: IAddOrganizationDepartmentActionPayload) => ({
  type: ADD_ORGANIZATION_DEPARTMENT_REQUEST,
  payload,
});

export const addOrganizationDepartmentSuccess = (payload: IDepartment) => ({
  type: ADD_ORGANIZATION_DEPARTMENT_SUCCESS,
  payload,
});

export const addOrganizationDepartmentFailure = (payload: any) => ({
  type: ADD_ORGANIZATION_DEPARTMENT_FAILURE,
  payload,
});

export const editOrganizationDepartmentRequest = (payload: IEditOrganizationDepartmentActionPayload) => ({
  type: EDIT_ORGANIZATION_DEPARTMENT_REQUEST,
  payload,
});

export const editOrganizationDepartmentSuccess = (payload: IDepartment) => ({
  type: EDIT_ORGANIZATION_DEPARTMENT_SUCCESS,
  payload,
});

export const editOrganizationDepartmentFailure = (payload: any) => ({
  type: EDIT_ORGANIZATION_DEPARTMENT_FAILURE,
  payload,
});

export const addOrganizationRequest = (payload: IAddOrganizationActionPayload) => ({
  type: ADD_ORGANIZATION_REQUEST,
  payload,
});

export const addOrganizationSuccess = (payload: any) => ({
  type: ADD_ORGANIZATION_SUCCESS,
  payload,
});

export const addOrganizationFailure = (payload: any) => ({
  type: ADD_ORGANIZATION_FAILURE,
  payload,
});

export const editOrganizationRequest = (payload: IEditOrganizationActionPayload) => ({
  type: EDIT_ORGANIZATION_REQUEST,
  payload,
});

export const editOrganizationSuccess = (payload: IOrganizationItem) => ({
  type: EDIT_ORGANIZATION_SUCCESS,
  payload,
});

export const editOrganizationFailure = (payload: any) => ({
  type: EDIT_ORGANIZATION_FAILURE,
  payload,
});

export const addOrganizationDetails = (payload: Partial<IOrganizationRequestItem>) => ({
  type: ADD_ORGANIZATION_DETAILS,
  payload,
});

export const clearOrganizationDetails = () => ({
  type: CLEAR_ORGANIZATION_DETAILS,
});

export const getDepartmentBudgetSummariesRequest = () => ({
  type: GET_DEPARTMENT_BUDGET_SUMMARIES_REQUEST,
});

export const getDepartmentBudgetSummariesSuccess = (payload: IDepartmentBudgetSummariesResponse) => ({
  type: GET_DEPARTMENT_BUDGET_SUMMARIES_SUCCESS,
  payload,
});

export const getDepartmentBudgetSummariesFailure = (payload: IApiError | Error | unknown) => ({
  type: GET_DEPARTMENT_BUDGET_SUMMARIES_FAILURE,
  payload,
});

export const removePIIRequest = (payload: IRemovePIIRequestPayload) => ({
  type: REMOVE_PII_REQUEST,
  payload,
});

export const removePIISuccess = () => ({
  type: REMOVE_PII_SUCCESS,
});

export const removePIIFailure = (payload: IApiError | Error | unknown) => ({
  type: REMOVE_PII_FAILURE,
  payload,
});
