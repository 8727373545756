import cn from 'classnames';
import * as React from 'react';

import styles from './ReportsStatus.module.scss';

interface IProps {
  status: string;
  backgroundColor?: string;
  className?: string;
}

const ReportsStatus = ({ status, backgroundColor, className }: IProps) => {
  return (
    <div className={cn(styles.status, className)}>
      <span className={cn(styles.statusDot)} style={{ backgroundColor }} />
      <span className={cn(styles.statusLabel)}>{status}</span>
    </div>
  );
};

export default ReportsStatus;
