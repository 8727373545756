import cn from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';

import { ReactComponent as ErrorIcon } from '../../../assets/images/icon-alert.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/images/icon-checkmark.svg';
import { ReactComponent as WarningIcon } from '../../../assets/images/icon-warning.svg';
import { StatusTooltipTypeEnum } from '../../../types/shell';
import HelpTooltip, { IHelpTooltipProps } from '../HelpTooltip/HelpTooltip';

import styles from './StatusTooltip.module.scss';

export interface IStatusTooltipProps extends IHelpTooltipProps {
  message?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  status?: StatusTooltipTypeEnum;
  children?: React.ReactNode;
}

const StatusTooltip: React.FC<IStatusTooltipProps> = ({ className, children, status, message, ...props }) => {
  const Icon = useMemo(() => {
    if (!status) {
      return null;
    }

    switch (true) {
      case status === StatusTooltipTypeEnum.Error:
        return ErrorIcon;
      case status === StatusTooltipTypeEnum.Warning:
        return WarningIcon;
      case status === StatusTooltipTypeEnum.Success:
        return SuccessIcon;
      default:
        return null;
    }
  }, [status]);

  return (
    <HelpTooltip
      {...props}
      className={cn(className, styles.container, styles[status || ''])}
      contentClassName={styles.content}
      icon={Icon}
    >
      {children}
      {message && <div className={cn(styles.message, styles[status || ''])}>{message}</div>}
    </HelpTooltip>
  );
};

export default StatusTooltip;
