import {
  IDashboardFilterState,
  IOrganizationSummariesRequest,
  IOrganizationSummariesResponse,
} from '../../types/dashboard';
import { IApiError } from '../../types/shell';

export const FETCH_ORGANIZATION_SUMMARIES_REQUEST = 'FETCH_ORGANIZATION_SUMMARIES_REQUEST';
export const FETCH_ORGANIZATION_SUMMARIES_SUCCESS = 'FETCH_ORGANIZATION_SUMMARIES_SUCCESS';
export const FETCH_ORGANIZATION_SUMMARIES_FAILURE = 'FETCH_ORGANIZATION_SUMMARIES_FAILURE';

export const SET_ACTIVE_ORGANIZATION_SUMMARY = 'SET_ACTIVE_ORGANIZATION_SUMMARY';
export const SET_DASHBOARD_DATE_RANGE_FILTER = 'SET_DASHBOARD_DATE_RANGE_FILTER';

export const fetchOrganizationSummariesRequest = (payload: IOrganizationSummariesRequest) => ({
  type: FETCH_ORGANIZATION_SUMMARIES_REQUEST,
  payload,
});

export const fetchOrganizationSummariesSuccess = (payload: IOrganizationSummariesResponse) => ({
  type: FETCH_ORGANIZATION_SUMMARIES_SUCCESS,
  payload,
});

export const fetchOrganizationSummariesFailure = (payload: IApiError | unknown) => ({
  type: FETCH_ORGANIZATION_SUMMARIES_FAILURE,
  payload,
});

export const setActiveOrganizationSummary = (payload: string | null) => ({
  type: SET_ACTIVE_ORGANIZATION_SUMMARY,
  payload,
});

export const setDashboardDateRangeFilter = (payload: IDashboardFilterState['dateRange']) => ({
  type: SET_DASHBOARD_DATE_RANGE_FILTER,
  payload,
});
