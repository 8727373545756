import cn from 'classnames';
import * as React from 'react';

import styles from './NavItem.module.scss';

interface IProps {
  children?: React.ReactNode | React.ReactNode[];
  onClick?: (...args: any[]) => void;
  className?: string;
}

const NavItem = ({ children, onClick, className }: IProps) => (
  <div onClick={onClick} className={cn(styles.menuItem, className)}>
    {children}
  </div>
);

export default NavItem;
