import { actionTypes } from 'react-redux-firebase';
import { actionChannel, call, put, race, select, take } from 'redux-saga/effects';

import { IRequestHelperParams } from '../../types/http';
import { Request } from '../../utils/request';
import * as AuthActions from '../actions/auth';
import * as ShellActions from '../actions/shell';
// import * as AnalyticsActions from "../actions/analytics";
import { selectUserAccessToken } from '../selectors/auth';

export function* AuthRequest({
  headers: initHeaders,
  retry = true, // Should be used for retries.
  fail = false, // Should be used for development purposes only - if we want to force the error from the endpoint
  ...requestParams
}: IRequestHelperParams): any {
  const token = yield select(selectUserAccessToken);
  const headers = initHeaders || {};
  if (token && !fail) {
    headers.Authorization = `Bearer ${token}`;
  } else if (!token) {
    yield put(AuthActions.authorizeUserFailure('Authorization failed. No token found'));
    // yield put(
    //   AnalyticsActions.trackApiException({
    //     endpoint,
    //     reason: FAILURE_REASONS.NO_TOKEN,
    //   })
    // );
  }
  let response = yield call<any>(Request, { headers, ...requestParams });

  if (!response.ok && response.status === 401) {
    if (retry) {
      yield put(ShellActions.firebaseProxyReload());

      const [error] = yield race([take(actionTypes.AUTH_RELOAD_ERROR), take(actionTypes.AUTH_RELOAD_SUCCESS)]);

      if (error) {
        yield put(ShellActions.firebaseProxyLogout());
      } else {
        response = yield call(AuthRequest, { headers, retry: false, ...requestParams });
      }
    } else {
      yield put(ShellActions.firebaseProxyLogout());
    }
  }
  return response;
}

export function* firebaseSaga({ firebase }: any): Generator<any, any, any> {
  if (firebase) {
    const channel = yield actionChannel([ShellActions.FIREBASE_PROXY_ACTION]);

    while (true) {
      const { proxy } = yield take(channel);

      switch (proxy) {
        case ShellActions.FIREBASE_PROXY_RELOAD: {
          yield call(firebase.reloadAuth);
          break;
        }
        case ShellActions.FIREBASE_PROXY_LOGOUT: {
          yield call(firebase.logout);
          break;
        }
        default:
          break;
      }
    }
  }
}

export function* routerSaga({ history, location }: any): Generator<any, any, any> {
  if (history && location) {
    const channel = yield actionChannel([ShellActions.ROUTER_PROXY_ACTION]);

    while (true) {
      const { proxy, pathname, meta } = yield take(channel);

      switch (proxy) {
        case ShellActions.ROUTER_PROXY_PUSH: {
          try {
            yield call(history.push, { pathname, state: meta });
          } catch (e) {
            console.error(e);
          }
          break;
        }
        case ShellActions.ROUTER_PROXY_REPLACE: {
          try {
            yield call(history.replace, { pathname, state: meta });
          } catch (e) {
            console.error(e);
          }
          break;
        }
        case ShellActions.ROUTER_PROXY_GET_STATE: {
          try {
            yield { history, location };
          } catch (e) {
            console.error(e);
          }
          break;
        }
        default:
          break;
      }
    }
  }
}

export function* selectorChangeSaga(selector: any): Generator<any, any, any> {
  const previous = yield select(selector);
  while (true) {
    // Probably we should just remove action to resolve the console warning
    const action = yield take();
    const next = yield select(selector);
    if (next !== previous) {
      return next;
    }
  }
}
