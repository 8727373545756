import cn from 'classnames';
import { FieldAttributes } from 'formik';
import * as React from 'react';
import { ColorChangeHandler, ColorResult, CustomPicker, InjectedColorProps } from 'react-color';
import { EditableInput, Hue, Saturation } from 'react-color/lib/components/common';
import ReactTooltip from 'react-tooltip';

import { BUTTON_BUTTON } from '../../../../types/forms';
import { PositionStylesEnum } from '../../../../types/shell';
import { RedCrossButton } from '../../buttons';
import { InputLabel } from '../../labels';

import { isColor } from '../../../../utils/helpers';
import styles from './ColorInput.module.scss';

interface IProps extends FieldAttributes<any> {
  containerClassName?: string;
  labelClassName?: string;
  className?: string;
  name: string;
  value?: string;
  onChange?: (hex: string) => void;
  position?: PositionStylesEnum;
  readOnly?: boolean;
  disabled?: boolean;
  showCurrent?: boolean;
  helperText?: string | React.ReactNode;
  required?: boolean;
  isClearable?: boolean;
}

interface ICustomProps extends InjectedColorProps {
  className?: string;
  showCurrent?: boolean;
}

const ColorInput = ({
  field,
  name,
  value,
  containerClassName,
  labelClassName,
  className,
  onChange,
  readOnly,
  disabled,
  position,
  showCurrent,
  helperText,
  required,
  isClearable,
}: IProps) => {
  const [color, setColor] = React.useState<string>(value || '#ffffff');

  const handleColorChange = React.useCallback(
    ({ hex }: ColorResult) => {
      if (typeof field?.onChange === 'function') {
        field.onChange(hex);
      }
      if (onChange) {
        onChange(hex);
      }
    },
    [onChange, field],
  );

  const handleClearColor = React.useCallback(() => {
    if (onChange) {
      onChange('');
    }
  }, [onChange]);

  return (
    <div className={cn(containerClassName, styles.container)}>
      <InputLabel className={labelClassName} value={helperText} required={required} />
      <div className={styles.buttonWrapper}>
        {isClearable && !readOnly && value && (
          <RedCrossButton size="xs" className={styles.clearButton} onClick={handleClearColor} />
        )}
        <button
          disabled={disabled}
          type={BUTTON_BUTTON}
          name={name}
          className={cn(styles.button, { [styles.readOnly]: readOnly })}
          data-tip="custom show"
          data-event="click"
          data-for={name}
        >
          {value ? (
            <>
              <div className={styles.colorBar} style={{ backgroundColor: `${value}` }} />
              <span className={styles.value}>{value}</span>
            </>
          ) : (
            <span className={styles.emptyText}>Not selected</span>
          )}
        </button>
      </div>

      {!readOnly && (
        <ReactTooltip
          className={styles.tooltip}
          id={name}
          type="light"
          clickable
          globalEventOff="click"
          place={position}
          effect="solid"
        >
          <ColorPickerWrapped
            onChange={(data: any) => {
              setColor((typeof data as string) === 'string' ? data : data.hex);
            }}
            color={color}
            onChangeComplete={handleColorChange}
            className={cn(className)}
            showCurrent={showCurrent}
          />
        </ReactTooltip>
      )}
    </div>
  );
};

const ColorPicker = ({ className, /*setColor,*/ showCurrent, onChange, hex, ...props }: ICustomProps) => {
  const handleInputChange: ColorChangeHandler = React.useCallback(
    (value: ColorResult) => {
      if (isColor(value?.toString())) {
        onChange?.(value);
      }
    },
    [onChange],
  );

  const Pointer = React.useCallback((saturation: boolean) => {
    return <div className={cn(styles.pointer, { [styles.pointerSaturation]: saturation })} />;
  }, []);

  const inputStyles = React.useMemo(() => {
    return {
      input: {
        width: '75px',
        border: '1px solid black',
        borderRadius: '3px',
        padding: '10px 6px 6px',
        fontSize: '16px',
        outline: 'none',
      },
    };
  }, []);

  const dummy = () => void 0;

  return (
    <div className={cn(className, styles.colorInput)}>
      <span className={styles.label}>Color Picker</span>
      <div className={styles.main}>
        <div className={styles.saturation}>
          <Saturation
            {...props}
            // Ignoring the next prop error because of an issue in react-color package - it tells it should be ReactNode,
            // but there are issues with render ReactNode. In real, it takes () => ReactNode
            // @ts-ignore
            pointer={() => Pointer(true)}
            onChange={onChange || dummy}
          />
        </div>
        <div className={styles.hue}>
          <Hue
            {...props}
            // Ignoring the next prop error because of an issue in react-color package - it tells it should be ReactNode,
            // but there are issues with render ReactNode. In real, it takes () => ReactNode
            // @ts-ignore
            pointer={() => Pointer(false)}
            onChange={onChange || dummy}
            direction="vertical"
          />
        </div>
      </div>
      <div className={styles.input}>
        <span className={cn(styles.label, styles.dash)}>#</span>
        <EditableInput style={inputStyles} value={hex?.replace(/#/g, '')} onChange={handleInputChange} />
        {showCurrent && (
          <div
            className={styles.current}
            style={{
              backgroundColor: `${hex}`,
            }}
          />
        )}
      </div>
    </div>
  );
};

const ColorPickerWrapped = CustomPicker(ColorPicker);

export default ColorInput;
