import { IEditSignatureRequestActionPayload, ISignature } from '../../types/signature';

export const EDIT_SIGNATURE_REQUEST = 'EDIT_SIGNATURE_REQUEST';
export const EDIT_SIGNATURE_SUCCESS = 'EDIT_SIGNATURE_SUCCESS';
export const EDIT_SIGNATURE_FAILURE = 'EDIT_SIGNATURE_FAILURE';

export const ADD_CANDIDATE_SIGNATURE = 'ADD_CANDIDATE_SIGNATURE';

export const editSignatureRequest = (payload: IEditSignatureRequestActionPayload) => ({
  type: EDIT_SIGNATURE_REQUEST,
  payload,
});

export const editSignatureSuccess = (payload: ISignature) => ({
  type: EDIT_SIGNATURE_SUCCESS,
  payload,
});

export const editSignatureFailure = (payload: any) => ({
  type: EDIT_SIGNATURE_FAILURE,
  payload,
});

export const addCandidateSignature = (payload: Partial<ISignature>) => ({
  type: ADD_CANDIDATE_SIGNATURE,
  payload,
});
