import { call, put, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { endpoints, queryParams } from '../../constants/routing';
import * as BusinessReasonsActions from '../actions/reasons';
import { AuthRequest } from './helpers';

function* businessReasonsWorkerSaga(): Generator<any, any, any> {
  try {
    const endpoint = `${apiUrl}${endpoints.getBusinessReasons}`;

    const response = yield call<any>(AuthRequest, { endpoint });
    if (!response.ok) {
      yield put(BusinessReasonsActions.fetchBusinessReasonsFailure(response.body));
    } else {
      yield put(BusinessReasonsActions.fetchBusinessReasonsSuccess(response.body));
    }
  } catch (error) {
    yield put(BusinessReasonsActions.fetchBusinessReasonsFailure(error));
  }
}

function* addBusinessReasonWorkerSaga(action: any): Generator<any, any, any> {
  const { reason, resolve, reject } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.addBusinessReason}`;
    const response = yield call<any>(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({
        reason: {
          name: reason.name,
        },
      }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(BusinessReasonsActions.addBusinessReasonFailure(response.body));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(BusinessReasonsActions.addBusinessReasonSuccess(response.body));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(BusinessReasonsActions.addBusinessReasonFailure(error));
  }
}

function* deleteBusinessReasonWorkerSaga(action: any): Generator<any, any, any> {
  const { uid, resolve, reject } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.deleteBusinessReason}`;
    const qs = {
      ...(uid ? { [queryParams.uid]: uid } : {}),
    };

    const response = yield call<any>(AuthRequest, {
      queryParams: qs,
      endpoint,
      method: 'DELETE',
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(BusinessReasonsActions.deleteBusinessReasonFailure(response.body));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(BusinessReasonsActions.deleteBusinessReasonSuccess({ uid }));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(BusinessReasonsActions.deleteBusinessReasonFailure(error));
  }
}

function* editBusinessReasonWorkerSaga(action: any): Generator<any, any, any> {
  const { reason, resolve, reject } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.editBusinessReason}`;
    const response = yield call<any>(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({
        reason,
      }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(BusinessReasonsActions.editBusinessReasonFailure(response.body));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(BusinessReasonsActions.editBusinessReasonSuccess(reason));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(BusinessReasonsActions.editBusinessReasonFailure(error));
  }
}

const sagas = {
  *watchBusinessReasonsRequest() {
    yield takeLatest(BusinessReasonsActions.FETCH_BUSINESS_REASONS_REQUEST, businessReasonsWorkerSaga);
  },
  *watchAddBusinessReasonRequest() {
    yield takeLatest(BusinessReasonsActions.ADD_BUSINESS_REASON_REQUEST, addBusinessReasonWorkerSaga);
  },
  *watchDeleteBusinessReasonRequest() {
    yield takeLatest(BusinessReasonsActions.DELETE_BUSINESS_REASON_REQUEST, deleteBusinessReasonWorkerSaga);
  },
  *watchEditBusinessReasonRequest() {
    yield takeLatest(BusinessReasonsActions.EDIT_BUSINESS_REASON_REQUEST, editBusinessReasonWorkerSaga);
  },
};

export default sagas;
