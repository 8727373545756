import cn from 'classnames';
import { FormikHelpers, useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SignatureForm } from '../../components/forms';
import MaintenanceForm from '../../components/forms/MaintenanceForm/MaintenanceForm';
import { routes } from '../../constants/routing';
import { SIGNATURE_VALIDATION_SCHEMA as validationSchema } from '../../constants/signature';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import { addCandidateSignature, editSignatureRequest } from '../../store/actions/signature';
import { selectCurrentSignature, selectSignatureFormInitialValues } from '../../store/selectors/signature';
import { IMaintenanceFormOutputData, MaintenanceFormStateEnum } from '../../types/forms';
import { NotificationListEnum } from '../../types/shell';
import { ISignature } from '../../types/signature';
import { isObjectsEqual } from '../../utils/helpers';
import notification from '../../utils/notification';
import { handleApiError } from '../../utils/ui';

import styles from './SignatureSetup.module.scss';

const SignatureSetup: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setHeader } = useHeaderDispatch();

  const originalSignature = useSelector(selectCurrentSignature);
  const initialValues = useSelector(selectSignatureFormInitialValues);

  const isInitialValid = React.useMemo(
    () => validationSchema.isValidSync(initialValues),
    [validationSchema, initialValues],
  );

  const isValueChanged = React.useMemo(() => {
    return !isObjectsEqual({ signature: originalSignature }, initialValues);
  }, [originalSignature, initialValues]);

  const handleSubmit = React.useCallback(
    (signature: Partial<ISignature>, { setSubmitting }: FormikHelpers<Partial<ISignature>>) => {
      if (!validationSchema.isValidSync(signature)) {
        return;
      }

      return new Promise<ISignature>((resolve, reject) => {
        dispatch(editSignatureRequest({ ...signature, resolve, reject }));
      })
        .then(() => {
          notification.success(NotificationListEnum.Success, {
            content: 'Changes were successfully saved',
          });
        })
        .catch(handleApiError(`Something bad happened. Signature wasn't edited.`))
        .finally(() => {
          setSubmitting(false);
        });
    },
    [dispatch, validationSchema, history],
  );

  const form = useFormik<Partial<ISignature>>({
    initialValues,
    isInitialValid,
    validateOnMount: true,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const handleFieldChange = React.useCallback(
    (name: keyof ISignature, value: string) => {
      dispatch(addCandidateSignature({ [name]: value }));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (!originalSignature) {
      return;
    }

    if (originalSignature === initialValues.signature) {
      return;
    }

    dispatch(addCandidateSignature({ signature: originalSignature }));
  }, [originalSignature]);

  React.useEffect(() => {
    if (setHeader) {
      setHeader({ title: 'Signature Setup', action: () => history.push(routes.dashboard) });
    }
  }, [history, setHeader]);

  return (
    <div className={styles.container}>
      <MaintenanceForm
        config={{
          header: { label: 'Signature Setup', action: () => history.push(routes.dashboard) },
          delete: { hide: true },
          cancel: { hide: true },
        }}
        root={routes.users.root}
        form={form}
        mode={MaintenanceFormStateEnum.Editing}
        isValueChanged={isValueChanged}
        className={cn(styles.form, styles.formElement)}
        controlsClassName={styles.formElement}
      >
        {(props: IMaintenanceFormOutputData) => {
          return <SignatureForm {...props} onChange={handleFieldChange} form={form} />;
        }}
      </MaintenanceForm>
    </div>
  );
};

export default SignatureSetup;
