import { IFetchFulfillmentCentersRequest, IFetchFulfillmentCentersResponse } from '../../types/fulfillmentCenters';
import { IApiError } from '../../types/shell';

export const FETCH_FULFILLMENT_CENTERS_REQUEST = 'FETCH_FULFILLMENT_CENTERS_REQUEST';
export const FETCH_FULFILLMENT_CENTERS_SUCCESS = 'FETCH_FULFILLMENT_CENTERS_SUCCESS';
export const FETCH_FULFILLMENT_CENTERS_FAILURE = 'FETCH_FULFILLMENT_CENTERS_FAILURE';

export const fetchFulfillmentCentersRequest = (payload?: IFetchFulfillmentCentersRequest) => ({
  type: FETCH_FULFILLMENT_CENTERS_REQUEST,
  payload,
});

export const fetchFulfillmentCentersSuccess = (payload: IFetchFulfillmentCentersResponse) => ({
  type: FETCH_FULFILLMENT_CENTERS_SUCCESS,
  payload,
});

export const fetchFulfillmentCentersFailure = (payload: IApiError | unknown) => ({
  type: FETCH_FULFILLMENT_CENTERS_FAILURE,
  payload,
});
