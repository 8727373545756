import * as React from 'react';
import { CellProps } from 'react-table';

import { getCustomProps } from '../../../utils/tables';
import { ActionButton } from '../../forms';

import styles from './HeaderControls.module.scss';

const HeaderControls = <T extends object>(props: CellProps<T>) => {
  const { onAdd } = getCustomProps<T, unknown>(props) || {};
  return onAdd ? <ActionButton className={styles.addButton} onClick={onAdd} title="+" {...props} /> : null;
};

export default HeaderControls;
