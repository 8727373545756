import { SetRequired } from 'type-fest';

import {
  INITIAL_SHIPPING_FORM_STATE,
  RECEIVER_ADDRESS1,
  RECEIVER_ADDRESS2,
  RECEIVER_CITY,
  RECEIVER_COMPANY_NAME,
  RECEIVER_COUNTRY,
  RECEIVER_CRM_OPPORTUNITY_ID,
  RECEIVER_CRM_RECORD_ID,
  RECEIVER_CRM_RECORD_TYPE,
  RECEIVER_EMAIL,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
  RECEIVER_STATE,
  RECEIVER_ZIP,
  SHIPPING_OPTION,
  SHIP_ORDER_STATUS,
} from '../constants/shipping';
import { ShippingOptionsEnum } from './shipping';

export interface IShippingDigitalAddressClassProps {
  [RECEIVER_FIRST_NAME]: string;
  [RECEIVER_LAST_NAME]: string;
  [RECEIVER_EMAIL]: string;
  [RECEIVER_CRM_RECORD_ID]?: string;
  [RECEIVER_CRM_RECORD_TYPE]?: string;
  [RECEIVER_CRM_OPPORTUNITY_ID]?: string;
}

export interface IShippingAddressClassProps
  extends SetRequired<
    IShippingDigitalAddressClassProps,
    typeof RECEIVER_CRM_RECORD_TYPE | typeof RECEIVER_CRM_RECORD_TYPE
  > {
  [RECEIVER_COMPANY_NAME]?: string;
  [RECEIVER_PHONE]?: string;
  [RECEIVER_ADDRESS1]: string;
  [RECEIVER_ADDRESS2]: string;
  [RECEIVER_STATE]?: string;
  [RECEIVER_ZIP]: string;
  [RECEIVER_COUNTRY]?: string;
  [RECEIVER_CITY]: string;
  [SHIP_ORDER_STATUS]: string;
  [SHIPPING_OPTION]: ShippingOptionsEnum;
}

export class ShippingDigitalAddressCandidate implements Partial<IShippingDigitalAddressClassProps> {
  [RECEIVER_FIRST_NAME] = '';
  [RECEIVER_LAST_NAME] = '';
  [RECEIVER_EMAIL] = '';
  [RECEIVER_CRM_RECORD_ID]: string | undefined;
  [RECEIVER_CRM_RECORD_TYPE]: string | undefined;
  [RECEIVER_CRM_OPPORTUNITY_ID]: string | undefined;

  constructor(props: Partial<IShippingDigitalAddressClassProps>) {
    const {
      receiver_first_name,
      receiver_last_name,
      receiver_email,
      receiver_crm_record_id,
      receiver_crm_record_type,
      receiver_crm_opportunity_id,
    } = props as IShippingDigitalAddressClassProps;
    this[RECEIVER_FIRST_NAME] = receiver_first_name || '';
    this[RECEIVER_LAST_NAME] = receiver_last_name || '';
    this[RECEIVER_EMAIL] = receiver_email || '';
    this[RECEIVER_CRM_RECORD_ID] = receiver_crm_record_id;
    this[RECEIVER_CRM_RECORD_TYPE] = receiver_crm_record_type;
    this[RECEIVER_CRM_OPPORTUNITY_ID] = receiver_crm_opportunity_id;
  }
}

export class ShippingAddressCandidate implements Partial<IShippingAddressClassProps> {
  [RECEIVER_FIRST_NAME] = '';
  [RECEIVER_LAST_NAME] = '';
  [RECEIVER_COMPANY_NAME] = '';
  [RECEIVER_CRM_RECORD_ID] = '';
  [RECEIVER_CRM_RECORD_TYPE] = '';
  [RECEIVER_CRM_OPPORTUNITY_ID] = '';
  [RECEIVER_PHONE] = '';
  [RECEIVER_ADDRESS1] = '';
  [RECEIVER_ADDRESS2] = '';
  [RECEIVER_STATE] = '';
  [RECEIVER_ZIP] = '';
  [RECEIVER_COUNTRY] = '';
  [RECEIVER_CITY] = '';
  [RECEIVER_EMAIL] = '';
  [SHIP_ORDER_STATUS] = '';
  [SHIPPING_OPTION] = ShippingOptionsEnum.Ground;
  constructor(props: Partial<IShippingAddressClassProps>) {
    const {
      receiver_first_name,
      receiver_last_name,
      receiver_company_name,
      receiver_phone,
      receiver_address1,
      receiver_address2,
      receiver_crm_record_id,
      receiver_crm_record_type,
      receiver_crm_opportunity_id,
      receiver_state,
      receiver_zip,
      receiver_country,
      receiver_city,
      receiver_email,
      ship_order_status = INITIAL_SHIPPING_FORM_STATE[SHIP_ORDER_STATUS], // TODO: Add the feature flag or remote-controlled feature flag
      shipping_option = INITIAL_SHIPPING_FORM_STATE[SHIPPING_OPTION],
    } = props as IShippingAddressClassProps;
    this[RECEIVER_FIRST_NAME] = receiver_first_name || '';
    this[RECEIVER_LAST_NAME] = receiver_last_name || '';
    this[RECEIVER_COMPANY_NAME] = receiver_company_name || '';
    this[RECEIVER_CRM_RECORD_ID] = receiver_crm_record_id || '';
    this[RECEIVER_CRM_RECORD_TYPE] = receiver_crm_record_type || '';
    this[RECEIVER_CRM_OPPORTUNITY_ID] = receiver_crm_opportunity_id || '';
    this[RECEIVER_PHONE] = receiver_phone || '';
    this[RECEIVER_ADDRESS1] = receiver_address1 || '';
    this[RECEIVER_ADDRESS2] = receiver_address2 || '';
    this[RECEIVER_STATE] = receiver_state || '';
    this[RECEIVER_ZIP] = receiver_zip || '';
    this[RECEIVER_COUNTRY] = receiver_country || '';
    this[RECEIVER_CITY] = receiver_city || '';
    this[RECEIVER_EMAIL] = receiver_email || '';
    this[SHIP_ORDER_STATUS] = ship_order_status;
    this[SHIPPING_OPTION] = shipping_option || ShippingOptionsEnum.Ground;
  }
}
