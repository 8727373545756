import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { MIN_BLUEBIRD_DATE } from '../../../../constants/date';
import DatePickerHeader from './DatePickerHeader';

import styles from './DatePicker.module.scss';
import './globalStyles.scss';

interface IProps extends Partial<ReactDatePickerProps> {}

const DatePicker = React.forwardRef(
  (
    { minDate = MIN_BLUEBIRD_DATE, maxDate, monthsShown = 1, onChange, closeOnScroll = true, ...rest }: IProps,
    ref: React.Ref<ReactDatePicker>,
  ) => {
    return (
      <div className="bluebird-date-picker">
        <ReactDatePicker
          {...rest}
          ref={ref}
          onChange={onChange!}
          renderDayContents={(day: number) => <span className={styles.day}>{day}</span>}
          calendarClassName={styles.calendar}
          minDate={minDate}
          maxDate={maxDate}
          renderCustomHeader={(headerProps) => (
            <DatePickerHeader {...headerProps} minDate={minDate || MIN_BLUEBIRD_DATE} maxDate={maxDate} />
          )}
          popperPlacement="bottom"
          closeOnScroll={closeOnScroll}
          monthsShown={monthsShown}
        />
      </div>
    );
  },
);

export default DatePicker;
