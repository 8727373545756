import cn from 'classnames';
import * as React from 'react';

import { GENDER_SELECT_OPTIONS_LIST } from '../../constants/inventories';
import { CustomizableItemIndicator } from '../index';

import styles from './InventoryItemOptions.module.scss';

interface IProps {
  className?: string;
  size?: string;
  color?: string;
  flavor?: string;
  gender?: string;
  otherOption?: string;
  isCustomizable?: boolean;
}

const InventoryItemOptions = ({ flavor, color, size, gender, otherOption, isCustomizable, className }: IProps) => {
  const renderOption = React.useCallback((value?: string, label?: string) => {
    if (!value?.trim()) {
      return;
    }

    return (
      <span className={styles.option} title={label}>
        {value}
      </span>
    );
  }, []);

  const shouldRender = React.useMemo(
    () => Boolean(flavor && color && size && gender && otherOption),
    [flavor, color, size, gender, otherOption],
  );

  return shouldRender ? (
    <div className={cn(className, styles.container)}>
      {isCustomizable && <CustomizableItemIndicator className={styles.customIcon} />}
      {renderOption(size, 'Size')}
      {renderOption(color, 'Color')}
      {renderOption(flavor, 'Flavor')}
      {renderOption(GENDER_SELECT_OPTIONS_LIST.find((g) => g.value === gender)?.label, 'Gender')}
      {renderOption(otherOption, 'Other')}
    </div>
  ) : null;
};

export default InventoryItemOptions;
