import { createSelector } from 'reselect';
import { IAutomatedCampaign, IAutomatedConnection } from '../../types/automations';
import { IAppState } from '../reducers';
import { selectAutomatedConnectionsByOrgId } from './organizations';

export const selectRootState = (state: IAppState) => state.automations;

export const selectAutomatedCampaigns = createSelector(
  selectRootState,
  (automatedCampaigns): IAutomatedCampaign[] | undefined | null =>
    automatedCampaigns && automatedCampaigns.items ? automatedCampaigns.items : undefined,
);

export const selectAutomatedCampaignById = (id?: string) =>
  createSelector(selectAutomatedCampaigns, (campaigns) =>
    id && campaigns && campaigns.length ? campaigns.find((campaign) => campaign.uid === id) : undefined,
  );

export const selectAutomatedConnectionById = (orgId: string, connectionId: string) =>
  createSelector(selectAutomatedConnectionsByOrgId(orgId), (connections) => {
    return connections.find((connection: IAutomatedConnection) => connection.uid === connectionId);
  });
