import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as PlaceholderImage } from '../../assets/images/no-items-placeholder.svg';

import styles from './NoResultsPlaceholder.module.scss';

interface IProps {
  label?: React.ReactNode;
  className?: string;
  labelClassName?: string;
  imageClassName?: string;
}

const NoResultsPlaceholder: React.FC<IProps> = ({ label, className, imageClassName, labelClassName }) => {
  return (
    <div className={cn(className, styles.container)}>
      {label && <span className={cn(labelClassName, styles.label)}>{label}</span>}
      <PlaceholderImage className={cn(imageClassName, styles.icon)} />
    </div>
  );
};

export default NoResultsPlaceholder;
