import { useCallback } from 'react';

import { endpoints, queryParams } from '../constants/routing';
import { IRequestHelperParams, IRequestQuery } from '../types/http';
import { IUploadResponse, UploadFileTypeEnum } from '../types/upload';
import { getQuery } from '../utils/request';
import useFetch from './useFetch';

export default function useUploadFile(requestParams?: IRequestHelperParams) {
  const { make, ...additionalInfo } = useFetch<FormData, IUploadResponse>({
    ...requestParams,
    method: 'POST',
    endpoint: endpoints.uploadFileToStorage,
    isJson: false,
  });

  const upload = useCallback(
    async (file: File | Blob, type: UploadFileTypeEnum, query?: IRequestQuery) => {
      const formDataFile = new FormData();
      formDataFile.append('filename', file);

      return await make({
        query: {
          ...query,
          ...getQuery(type, queryParams.type),
        },
        body: formDataFile,
      });
    },
    [make],
  );

  return {
    uploadFile: upload,
    ...additionalInfo,
  };
}
