import cn from 'classnames';
import React from 'react';

import { IOneLinkReceiverFixedAddress } from '../../types/oneLink';

import styles from '../../containers/AddressBookSidebarContainer/AddressBookSidebarContainer.module.scss';

interface IProps {
  row: IOneLinkReceiverFixedAddress & { label?: string };
  className?: string;
  addressClassName?: string;
}

function CompactOrgAddressView({ row, className, addressClassName }: IProps) {
  return (
    <div className={cn(styles.container, className)}>
      {row.label && <div className={styles.truncated}>{row.label}</div>}
      <div className={cn(styles.address, styles.truncated, addressClassName)}>
        <span>{`${row.address1}\u00A0`}</span>
        <span>{row.address2 ? `${row.address2}, \u00A0` : ''}</span>
        <span>{`${row.city},\u00A0`}</span>
        <span>{row.state ? `${row.state},\u00A0` : ''}</span>
        <span>{`${row.zip},\u00A0`}</span>
        <span>{row.country || ''}</span>
      </div>
    </div>
  );
}

export default CompactOrgAddressView;
