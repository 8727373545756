import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { IReasonsState } from '../../types/reasons';
import * as AuthActions from '../actions/auth';
import * as BusinessReasonsActions from '../actions/reasons';

export const initialState: IReasonsState = {
  items: null,
  isLoading: false,
  isReasonDetailsLoading: false,
  error: null,
  reasonDetails: null,
};

const reducer = (state: IReasonsState = initialState, action: any): IReasonsState => {
  switch (action.type) {
    case BusinessReasonsActions.FETCH_BUSINESS_REASONS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        items: null,
      };
    }
    case BusinessReasonsActions.FETCH_BUSINESS_REASONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        items: null,
      };
    }
    case BusinessReasonsActions.FETCH_BUSINESS_REASONS_SUCCESS: {
      const { business_reasons } = action.payload || {};
      return {
        ...state,
        isLoading: false,
        error: null,
        items: business_reasons && business_reasons.length ? business_reasons : null,
      };
    }
    case BusinessReasonsActions.ADD_BUSINESS_REASON_REQUEST: {
      return {
        ...state,
        isReasonDetailsLoading: true,
      };
    }
    case BusinessReasonsActions.ADD_BUSINESS_REASON_SUCCESS: {
      return {
        ...state,
        isReasonDetailsLoading: false,
        reasonDetails: null,
      };
    }
    case BusinessReasonsActions.ADD_BUSINESS_REASON_FAILURE: {
      return {
        ...state,
        isReasonDetailsLoading: false,
      };
    }
    case BusinessReasonsActions.DELETE_BUSINESS_REASON_REQUEST: {
      return {
        ...state,
        isReasonDetailsLoading: true,
      };
    }
    case BusinessReasonsActions.DELETE_BUSINESS_REASON_SUCCESS: {
      if (state.items) {
        return {
          ...state,
          isReasonDetailsLoading: false,
          items: state.items.filter((i) => i.uid !== action.payload.uid),
        };
      }

      return state;
    }
    case BusinessReasonsActions.EDIT_BUSINESS_REASON_REQUEST: {
      return {
        ...state,
        isReasonDetailsLoading: true,
      };
    }
    case BusinessReasonsActions.EDIT_BUSINESS_REASON_SUCCESS: {
      const newReasonItem = action.payload;

      if (state.items && newReasonItem) {
        const oldReasonItemIndex = state.items.findIndex((item) => item.uid === newReasonItem.uid);

        return {
          ...state,
          items: [
            ...state.items.slice(0, oldReasonItemIndex),
            newReasonItem,
            ...state.items.slice(oldReasonItemIndex + 1),
          ],
          isReasonDetailsLoading: false,
          reasonDetails: null,
        };
      }
      return state;
    }
    case BusinessReasonsActions.EDIT_BUSINESS_REASON_FAILURE: {
      return {
        ...state,
        isReasonDetailsLoading: false,
      };
    }
    case BusinessReasonsActions.CHANGE_REASON_DETAILS: {
      return {
        ...state,
        reasonDetails: {
          ...state.reasonDetails,
          ...action.payload,
        },
      };
    }
    case BusinessReasonsActions.CLEAR_REASON_DETAILS: {
      return {
        ...state,
        reasonDetails: null,
      };
    }
    case FirebaseActions.LOGOUT:
    case AuthActions.ADMIN_ROLE_CHANGED:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
