import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { MAX_ONE_LINKS_PER_PAGE, UID } from '../../constants/oneLink';
import {
  IDisableOneLinkSendSuccessPayload,
  IFetchOneLinkByIdSuccessPayload,
  IFetchOneLinkSendsSuccessPayload,
  IOneLink,
  IOneLinksState,
  IPopulateOneLinkCampaignSummariesResponsePayload,
  OneLinkStatusEnum,
  TOneLinkRecipient,
} from '../../types/oneLink';
import { convertArrayToObject } from '../../utils/helpers';
import * as AuthActions from '../actions/auth';
import * as OneLinkActions from '../actions/oneLink';

export const initialState: IOneLinksState = {
  [OneLinkStatusEnum.Active]: null,
  [OneLinkStatusEnum.NotActive]: null,
  isLoading: false,
  isLoadingSingle: false,
  error: null,
  isFinal: false,
  totalCount: 0,
  recipients: null,
};

const reducer = (state: IOneLinksState = initialState, action: any): IOneLinksState => {
  switch (action.type) {
    case OneLinkActions.FETCH_ONE_LINK_SENDS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        isFinal: false,
      };
    }
    case OneLinkActions.FETCH_ONE_LINK_SENDS_SUCCESS: {
      const {
        one_link_sends: items,
        status,
        total_count: totalCount,
      } = action.payload as IFetchOneLinkSendsSuccessPayload;
      const isFinal = !items || (Boolean(items.length) && items.length < MAX_ONE_LINKS_PER_PAGE);

      return {
        ...state,
        [status]: items && items.length ? convertArrayToObject<IOneLink, typeof UID>(items, UID) : {},
        isLoading: false,
        error: null,
        totalCount,
        isFinal,
      };
    }
    case OneLinkActions.FETCH_ONE_LINK_SENDS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isFinal: false,
      };
    }
    case OneLinkActions.FETCH_ONE_LINK_BY_ID_REQUEST: {
      return {
        ...state,
        isLoadingSingle: true,
        error: null,
        isFinal: false,
      };
    }
    case OneLinkActions.FETCH_ONE_LINK_BY_ID_FAILURE: {
      return {
        ...state,
        isLoadingSingle: false,
        error: action.payload,
        isFinal: false,
      };
    }
    case OneLinkActions.FETCH_ONE_LINK_BY_ID_SUCCESS: {
      const { one_link_send, status } = (action.payload as IFetchOneLinkByIdSuccessPayload) || {};

      return {
        ...state,
        isLoadingSingle: false,
        error: null,
        [status]: {
          ...(state[status] ? state[status] : {}),
          ...(one_link_send && one_link_send[UID] ? { [one_link_send[UID]]: one_link_send } : {}),
        },
        isFinal: true,
      };
    }
    case OneLinkActions.POPULATE_ONE_LINK_CAMPAIGN_SUMMARIES_REQUEST: {
      return {
        ...state,
        isLoadingSingle: true,
        error: null,
      };
    }
    case OneLinkActions.POPULATE_ONE_LINK_CAMPAIGN_SUMMARIES_SUCCESS: {
      const { itemId, status, campaign_summaries } = action.payload as IPopulateOneLinkCampaignSummariesResponsePayload;

      return {
        ...state,
        isLoadingSingle: false,
        error: null,
        [status]: {
          ...(state[status] ? state[status] : {}),
          ...(state[status]?.[itemId]
            ? {
                [itemId]: {
                  ...state[status]?.[itemId],
                  pick_campaigns: campaign_summaries,
                },
              }
            : {}),
        },
        isFinal: true,
      };
    }
    case OneLinkActions.POPULATE_ONE_LINK_CAMPAIGN_SUMMARIES_FAILURE: {
      return {
        ...state,
        isLoadingSingle: false,
        error: action.payload,
      };
    }
    case OneLinkActions.DISABLE_ONE_LINK_SEND_SUCCESS: {
      const { uid } = action.payload as IDisableOneLinkSendSuccessPayload;
      const itemToDisable = state[OneLinkStatusEnum.Active]?.[uid];

      const { [uid]: itemToRemove, ...activeItems } = state[OneLinkStatusEnum.Active] || {};
      return {
        ...state,
        [OneLinkStatusEnum.Active]: { ...activeItems },
        [OneLinkStatusEnum.NotActive]: {
          ...(state[OneLinkStatusEnum.NotActive] ? state[OneLinkStatusEnum.NotActive] : null),
          ...(uid && itemToDisable ? { [uid]: itemToDisable } : null),
        },
        isLoading: false,
        error: action.payload,
      };
    }
    case FirebaseActions.LOGOUT:
    case AuthActions.ADMIN_ROLE_CHANGED:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS: {
      return initialState;
    }
    case OneLinkActions.SET_ONE_LINK_RECIPIENTS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        recipients: action.payload,
      };
    }
    case OneLinkActions.DELETE_ALL_ONE_LINK_RECIPIENTS_SUCCESS: {
      const recipientsLeft = state.recipients?.filter((recipient: TOneLinkRecipient) => recipient.is_submitted);
      return {
        ...state,
        recipients: recipientsLeft,
      };
    }
    case OneLinkActions.DELETE_ONE_LINK_RECIPIENTS_SUCCESS: {
      const { uid } = action.payload;
      return {
        ...state,
        recipients: state.recipients?.filter((recipient: TOneLinkRecipient) => recipient.uid !== uid),
      };
    }
    case OneLinkActions.UPDATE_ONE_LINK_RECIPIENTS_SUCCESS: {
      const { uid } = action.payload;
      const recipients = state?.recipients ? [...state.recipients] : [];

      const updatedRecipientIndex = recipients.findIndex((recipient: TOneLinkRecipient) => recipient.uid === uid);

      if (updatedRecipientIndex !== -1) {
        recipients.splice(updatedRecipientIndex, 1, action.payload);
      }

      return {
        ...state,
        recipients,
      };
    }
    case OneLinkActions.ADD_ONE_LINK_RECIPIENTS_SUCCESS: {
      const newRecipient = action.payload;

      return {
        ...state,
        recipients: state?.recipients ? [newRecipient, ...state.recipients] : [newRecipient],
      };
    }
    case OneLinkActions.UPLOAD_ONE_LINK_RECIPIENTS: {
      return {
        ...state,
        recipients: state?.recipients ? [...action.payload, ...state.recipients] : action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
