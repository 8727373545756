import React from 'react';
import { useDispatch } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

import { BLUEBIRD_SETTINGS_FIRESTORE_COLLECTION, CURRENCY_SETTINGS_FIRESTORE_DOC_ID } from '../../constants/shell';

import { updateCurrencyRates } from '../../store/actions/shell';
import { ICurrencySettings } from '../../types/shell';

const CurrencySettings = () => {
  const firebase = useFirebase();
  const dispatch = useDispatch();

  React.useEffect(() => {
    return firebase
      .firestore()
      .collection(BLUEBIRD_SETTINGS_FIRESTORE_COLLECTION)
      .doc(CURRENCY_SETTINGS_FIRESTORE_DOC_ID)
      .onSnapshot((snapshot) => {
        const currencyRates = snapshot?.data() as ICurrencySettings;
        dispatch(updateCurrencyRates(currencyRates));
      });
  }, []);

  return null;
};

export default CurrencySettings;
