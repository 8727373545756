import cn from 'classnames';
import React from 'react';
import { Column, useTable } from 'react-table';

import styles from './AutomationTable.module.scss';

interface IProps<T extends object> {
  data: T[];
  columns: Column<T>[];
  placeholderText: string;
  className?: string;
  containerClassName?: string;
  headerClassName?: string;
  bodyClassName?: string;
  classNames?: {
    header?: {
      [key in keyof T]?: string | ((item: T) => string);
    };
    cell?: {
      [key in keyof T]?: string | ((item: T) => string);
    };
  };
}

const AutomationTable = <T extends object, V = null>({
  data,
  columns,
  placeholderText,
  className,
  containerClassName,
  headerClassName,
  bodyClassName,
  classNames,
}: IProps<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  const emptyPlaceholder = React.useMemo(() => {
    return (
      <div className={cn(styles.empty, className)}>
        <p>{placeholderText}</p>
      </div>
    );
  }, [className, placeholderText]);

  return (
    <div {...getTableProps()} className={cn(styles.container, containerClassName)}>
      <div className={styles.widthLimit}>
        {headerGroups.map((headerGroup) => {
          const { key: headerGroupKey, ...getHeaderGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <div className={cn(styles.head, headerClassName)} key={headerGroupKey} {...getHeaderGroupProps}>
              {headerGroup.headers.map((column) => {
                const { key: headerKey, ...getHeaderProps } = column.getHeaderProps();
                return (
                  <div key={headerKey} {...getHeaderProps} className={styles.cell}>
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          );
        })}
        {data?.length === 0 ? (
          emptyPlaceholder
        ) : (
          <div {...getTableBodyProps()} className={cn(styles.body, className)}>
            {rows.map((row) => {
              prepareRow(row);
              const { key: rowKey, ...getRowProps } = row.getRowProps();
              return (
                <div className={cn(styles.row, bodyClassName)} key={rowKey} {...getRowProps}>
                  {row.cells.map((cell) => {
                    const { key: cellKey, className: cellClassName, ...getCellProps } = cell.getCellProps();
                    const dynamicClassName = (() => {
                      const columnId = cell.column.id as keyof T;
                      if (classNames?.cell && classNames.cell[columnId]) {
                        const content = classNames.cell[columnId];

                        return typeof content === 'string' ? content : content?.(cell.row.original);
                      }
                      return '';
                    })();

                    return (
                      <div className={cn(styles.cell, cellClassName, dynamicClassName)} key={cellKey} {...getCellProps}>
                        {cell.render('Cell', { className: 'asdasdasd' })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AutomationTable;
