import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { LOW_INVENTORY_NOTIFICATION_EMAILS, NO_DEPARTMENT_ITEM } from '../../constants/organizations';
import { IAuthResponse } from '../../types/auth';
import { IAutomatedConnection } from '../../types/automations';
import { IDepartmentBudgetSummariesResponse, IOrganizationItem, IOrganizationsState } from '../../types/organizations';
import { IReduxAction } from '../../types/redux';
import { sortByName } from '../../utils/organizations';
import * as AuthActions from '../actions/auth';
import * as AutomatedCampaignActions from '../actions/automations';
import * as OrganizationsActions from '../actions/organizations';

export const initialState: IOrganizationsState = {
  current: {
    options: null,
    departmentList: null,
    isDepartmentSummariesLoading: false,
    isDepartmentUpdating: false,
    isAutomatedConnectionLoading: false,
    uid: null,
    name: null,
    error: null,
    departmentBudget: null,
  },
  items: null,
  organizationDetails: null,
  isOrganizationDetailsLoading: false,
  isOrganizationsPIILoading: false,
  isLoading: false,
  error: null,
};

const reducer = (state: IOrganizationsState = initialState, action: any): IOrganizationsState => {
  switch (action.type) {
    case AuthActions.AUTHENTICATE_FAILURE:
    case AuthActions.AUTHORIZE_USER_FAILURE:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS:
    case FirebaseActions.LOGOUT: {
      return {
        ...state,
        current: initialState.current,
      };
    }
    case AuthActions.AUTHORIZE_USER_SUCCESS: {
      const {
        departments,
        organization_options = null,
        user_department_info = null,
        user,
      } = (action as IReduxAction<IAuthResponse>).payload || {};

      return {
        ...state,
        current: {
          ...state.current,
          departmentList: [...(departments ? departments : []), NO_DEPARTMENT_ITEM],
          options: organization_options,
          departmentBudget: user_department_info,
          uid: user?.org_id || null,
          name: user?.org_name || null,
        },
      };
    }
    case OrganizationsActions.FETCH_ORGANIZATIONS_REQUEST: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case OrganizationsActions.FETCH_ORGANIZATIONS_SUCCESS: {
      const { organizations: items } = action.payload || {};

      return {
        ...state,
        items,
        error: null,
        isLoading: false,
      };
    }
    case OrganizationsActions.FETCH_ORGANIZATIONS_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }
    case OrganizationsActions.EDIT_ORGANIZATION_REQUEST:
    case OrganizationsActions.ADD_ORGANIZATION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case OrganizationsActions.ADD_ORGANIZATION_SUCCESS: {
      const items = state.items ? state.items.concat(action.payload).sort(sortByName(false)) : { ...action.payload };

      return {
        ...state,
        items,
        isLoading: false,
        organizationDetails: null,
      };
    }
    case OrganizationsActions.EDIT_ORGANIZATION_SUCCESS: {
      const items = state.items ? state.items.slice() : [];

      const payload = (action as IReduxAction<IOrganizationItem>).payload!;

      const index = items?.findIndex((organization: IOrganizationItem) => organization.uid === payload.uid);

      if (typeof index !== 'undefined' && index !== -1) {
        // FIXME: [Workaround] EX-4405 This is done because the form should not overwrite org_options.automation_connections
        items[index] = {
          ...items[index],
          ...payload,
          org_options: {
            ...items[index].org_options,
            ...payload.org_options,
          },
        };
        // Sort once more, if possible name is changed
        items?.sort(sortByName(false));
      }

      const current =
        state.current.uid === payload.uid
          ? ((org: IOrganizationItem) => {
              const { org_options: newOptions, name } = org;

              return {
                ...state.current,
                options: {
                  ...state.current.options,
                  ...newOptions,
                },
                name,
              };
            })(payload)
          : state.current;

      return {
        ...state,
        current,
        isLoading: false,
        items,
      };
    }
    case OrganizationsActions.EDIT_ORGANIZATION_FAILURE:
    case OrganizationsActions.ADD_ORGANIZATION_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }
    case OrganizationsActions.ADD_ORGANIZATION_DEPARTMENT_REQUEST: {
      return {
        ...state,
        isOrganizationDetailsLoading: true,
        error: null,
      };
    }
    case OrganizationsActions.ADD_ORGANIZATION_DEPARTMENT_SUCCESS: {
      const { org_id: orgId } = action.payload;

      // add new department to organization current departmentList
      let departmentList = state.current.departmentList?.slice() || null;
      const isCurrentOrganization = state.current.uid === orgId;

      if (isCurrentOrganization) {
        departmentList = !!departmentList?.length ? departmentList?.concat(action.payload) : [action.payload];
      }

      // add new department to organizations items org_departments
      const organizationList = state.items ? cloneDeep(state.items) : null;
      const index = organizationList?.findIndex((org) => org.uid === orgId);

      if (typeof index !== 'undefined' && index !== -1) {
        organizationList![index].org_departments = organizationList![index].org_departments
          ? organizationList![index].org_departments.concat(action.payload)
          : [action.payload];
      }

      return {
        ...state,
        isOrganizationDetailsLoading: false,
        items: organizationList,
        error: null,
        current: {
          ...state.current,
          departmentList,
        },
      };
    }
    case OrganizationsActions.ADD_ORGANIZATION_DEPARTMENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isOrganizationDetailsLoading: false,
      };
    }
    case OrganizationsActions.EDIT_ORGANIZATION_DEPARTMENT_REQUEST: {
      return {
        ...state,
        error: null,
        isOrganizationDetailsLoading: true,
      };
    }
    case OrganizationsActions.EDIT_ORGANIZATION_DEPARTMENT_SUCCESS: {
      const { uid, org_id: orgId } = action.payload;

      // update organizations current departmentList
      const departmentList = state.current.departmentList?.slice() || null;
      const isCurrentOrganization = state.current.uid === orgId;

      if (isCurrentOrganization) {
        const updatedDeptIndex = departmentList?.findIndex((department) => department.uid === uid);
        if (departmentList && typeof updatedDeptIndex !== 'undefined') {
          departmentList[updatedDeptIndex] = action.payload;
        }
      }

      // update organizations items departmentsList
      const organizationList = state.items ? cloneDeep(state.items) : null;
      const requiredOrgIndex = organizationList?.findIndex((org) => org.uid === orgId);

      if (typeof requiredOrgIndex !== 'undefined' && requiredOrgIndex !== -1) {
        const { org_departments: deptList } = organizationList![requiredOrgIndex];

        for (let i = 0; i < deptList?.length; i++) {
          if (deptList[i].uid === uid) {
            deptList[i] = action.payload;
            break;
          }
        }
      }

      return {
        ...state,
        items: organizationList,
        isOrganizationDetailsLoading: false,
        error: null,
        current: {
          ...state.current,
          departmentList,
        },
      };
    }
    case OrganizationsActions.EDIT_ORGANIZATION_DEPARTMENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isOrganizationDetailsLoading: false,
      };
    }
    case OrganizationsActions.ADD_ORGANIZATION_DETAILS: {
      /* [START] The fields that are moved out from lodash merge because of wrong merging */

      const newLowInventoryEmailReceivers = Object.keys(action.payload).includes(LOW_INVENTORY_NOTIFICATION_EMAILS)
        ? action.payload[LOW_INVENTORY_NOTIFICATION_EMAILS]
        : state.organizationDetails?.[LOW_INVENTORY_NOTIFICATION_EMAILS];

      /* [END] The fields that are moved out from lodash merge because of wrong merging */

      return {
        ...state,
        // merge() is used here for the recursively merge.
        // action.payload could have nested objects
        organizationDetails: merge({}, omit(state.organizationDetails, [LOW_INVENTORY_NOTIFICATION_EMAILS]), {
          ...action.payload,
          [LOW_INVENTORY_NOTIFICATION_EMAILS]: newLowInventoryEmailReceivers,
        }),
      };
    }
    case OrganizationsActions.CLEAR_ORGANIZATION_DETAILS: {
      return {
        ...state,
        organizationDetails: null,
      };
    }
    case AuthActions.ADMIN_ROLE_CHANGED: {
      return initialState;
    }
    case OrganizationsActions.GET_DEPARTMENT_BUDGET_SUMMARIES_REQUEST: {
      return {
        ...state,
        current: {
          ...state.current,
          isDepartmentSummariesLoading: true,
          error: null,
        },
      };
    }
    case OrganizationsActions.GET_DEPARTMENT_BUDGET_SUMMARIES_FAILURE: {
      return {
        ...state,
        current: {
          ...state.current,
          isDepartmentSummariesLoading: false,
          error: action.payload,
        },
      };
    }
    case OrganizationsActions.GET_DEPARTMENT_BUDGET_SUMMARIES_SUCCESS: {
      const { department_budget_summaries } =
        (action as IReduxAction<IDepartmentBudgetSummariesResponse>).payload || {};

      return {
        ...state,
        current: {
          ...state.current,
          departmentList: department_budget_summaries
            ? department_budget_summaries.slice()
            : state.current.departmentList || null,
          isDepartmentSummariesLoading: false,
          error: null,
        },
      };
    }
    case OrganizationsActions.REMOVE_PII_REQUEST: {
      return {
        ...state,
        isOrganizationsPIILoading: true,
      };
    }
    case OrganizationsActions.REMOVE_PII_SUCCESS:
    case OrganizationsActions.REMOVE_PII_FAILURE: {
      return {
        ...state,
        isOrganizationsPIILoading: false,
      };
    }
    case AutomatedCampaignActions.ADD_AUTOMATED_CONNECTION_REQUEST:
    case AutomatedCampaignActions.UPDATE_AUTOMATED_CONNECTION_REQUEST: {
      return {
        ...state,
        error: null,
        current: {
          ...state.current,
          isAutomatedConnectionLoading: true,
        },
      };
    }
    case AutomatedCampaignActions.DELETE_AUTOMATED_CONNECTION_REQUEST: {
      return {
        ...state,
        error: null,
        current: {
          ...state.current,
          isAutomatedConnectionLoading: true,
        },
      };
    }
    case AutomatedCampaignActions.ADD_AUTOMATED_CONNECTION_FAILURE:
    case AutomatedCampaignActions.UPDATE_AUTOMATED_CONNECTION_FAILURE: {
      return {
        ...state,
        error: action.payload,
        current: {
          ...state.current,
          isAutomatedConnectionLoading: false,
        },
      };
    }
    case AutomatedCampaignActions.DELETE_AUTOMATED_CONNECTION_FAILURE: {
      return {
        ...state,
        error: action.payload,
        current: {
          ...state.current,
          isAutomatedConnectionLoading: false,
        },
      };
    }
    case AutomatedCampaignActions.ADD_AUTOMATED_CONNECTION_SUCCESS: {
      // Update automation_connections with the new automation connection for the current organization
      let automatedConnections;

      if (state.current.uid === action.payload.orgId) {
        automatedConnections = state.current.options?.automation_connections
          ? state.current.options.automation_connections.concat(action.payload)
          : [action.payload];
      }

      // Update state.items with the new automation connection for the matching organization
      const updatedItems = (state.items || []).map((item) => {
        if (item.uid === action.payload.orgId) {
          const updatedConnections = item.org_options?.automation_connections
            ? [...item.org_options.automation_connections, action.payload]
            : [action.payload];

          return {
            ...item,
            org_options: {
              ...item.org_options,
              automation_connections: updatedConnections,
            },
          };
        }
        return item;
      });

      return {
        ...state,
        items: updatedItems,
        current: {
          ...state.current,
          isAutomatedConnectionLoading: false,
          options: state.current.options
            ? {
                ...state.current.options,
                ...(automatedConnections ? { automation_connections: automatedConnections } : {}),
              }
            : null,
        },
      };
    }
    case AutomatedCampaignActions.UPDATE_AUTOMATED_CONNECTION_SUCCESS: {
      const updatedPayload = action.payload;
      let automatedConnections;

      // Update automation_connections for the current organization
      if (state.current.uid === updatedPayload.orgId) {
        automatedConnections = state.current.options?.automation_connections?.slice();

        const currentConnectionIndex = automatedConnections?.findIndex(
          (connection: IAutomatedConnection) => connection.uid === updatedPayload.uid,
        );

        if (typeof currentConnectionIndex !== 'undefined' && currentConnectionIndex !== -1) {
          automatedConnections?.splice(currentConnectionIndex, 1, updatedPayload);
        }
      }

      // Update automation_connections for the matching organization in state.items
      const updatedItems = (state.items || []).map((item) => {
        if (item.uid === updatedPayload.orgId) {
          const itemConnections = item.org_options?.automation_connections
            ? item.org_options.automation_connections.slice()
            : undefined;

          const itemConnectionIndex = itemConnections?.findIndex(
            (connection: IAutomatedConnection) => connection.uid === updatedPayload.uid,
          );

          if (typeof itemConnectionIndex !== 'undefined' && itemConnectionIndex !== -1) {
            itemConnections?.splice(itemConnectionIndex, 1, updatedPayload);
          }

          return {
            ...item,
            org_options: {
              ...item.org_options,
              automation_connections: itemConnections,
            },
          };
        }
        return item;
      });

      return {
        ...state,
        items: updatedItems,
        current: {
          ...state.current,
          isAutomatedConnectionLoading: false,
          options: state.current.options
            ? {
                ...state.current.options,
                ...(automatedConnections ? { automation_connections: automatedConnections } : {}),
              }
            : null,
        },
      };
    }
    case AutomatedCampaignActions.DELETE_AUTOMATED_CONNECTION_SUCCESS: {
      const connectionId = action.payload.id;
      const orgId = action.payload.orgId;
      let automatedConnections;

      // Remove the connection from automation_connections in current org
      if (state.current.uid === orgId) {
        automatedConnections = state.current.options?.automation_connections?.filter(
          (connection) => connection.uid !== connectionId,
        );
      }

      // Update state.items by removing the connection from the matching organization
      const updatedItems = (state.items || []).map((item) => {
        if (item.uid === orgId) {
          const filteredConnections = item.org_options?.automation_connections?.filter(
            (connection) => connection.uid !== connectionId,
          );

          return {
            ...item,
            org_options: {
              ...item.org_options,
              automation_connections: filteredConnections,
            },
          };
        }
        return item;
      });

      return {
        ...state,
        items: updatedItems,
        current: {
          ...state.current,
          isAutomatedConnectionLoading: false,
          options: state.current.options
            ? {
                ...state.current.options,
                ...(automatedConnections ? { automation_connections: automatedConnections } : {}),
              }
            : null,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
