import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import {
  DeleteItemModal,
  ReassignUserModal,
  ResetUserPasswordInfoModal,
  ResetUserPasswordModal,
} from '../../components/modals';
import { IReassignUserModalProps } from '../../components/modals/CommonModals/ReassignUserModal/ReassignUserModal';
import { AdminRoute, PrivateRoute } from '../../components/routes';
import { EditUserContainer, UsersContainer } from '../../containers';

import { endpoints, routes, URL_VARS } from '../../constants/routing';
import {
  DISTRIBUTOR,
  ORG_ADMIN,
  SUPER_ADMIN,
  USERS_EDITING_PERMISSION_LIST,
  USER_LABEL,
  USER_REASSIGN_REQUIRED_ERROR_CODE,
} from '../../constants/users';
import useFetch from '../../hooks/useFetch';
import useModal from '../../hooks/useModal';
import { deleteUserRequest, fetchUsersRequest } from '../../store/actions/users';
import { selectAdminType, selectCurrentUserId } from '../../store/selectors/auth';
import { selectCanEditUser, selectUserById, selectUsersIsLoaded } from '../../store/selectors/users';
import { IErrorResponse } from '../../types/bucket';
import { IUserRouteParams } from '../../types/routing';
import { NotificationListEnum } from '../../types/shell';
import { IResetPasswordActionPayload, IResetPasswordResponse, IUser } from '../../types/users';
import notification from '../../utils/notification';
import { handleApiError } from '../../utils/ui';
import { hasPermission } from '../../utils/users';

import styles from './Users.module.scss';

const Users = () => {
  const dispatch = useDispatch();

  const { flowId, userId } = useParams<IUserRouteParams>();

  const adminType = useSelector(selectAdminType);
  const currentUserId = useSelector(selectCurrentUserId);
  const canEditUser = useSelector(selectCanEditUser);
  const originalUser = useSelector(selectUserById(userId || ''));
  const usersIsLoaded = useSelector(selectUsersIsLoaded);

  const { openModal: openDeleteModal, closeModal: closeDeleteModal, Modal: DeleteModal } = useModal();
  const {
    openModal: openReassignModal,
    closeModal: closeReassignModal,
    Modal: ReassignModal,
  } = useModal<IReassignUserModalProps>();
  const {
    openModal: openResetPasswordModal,
    closeModal: closeResetPasswordModal,
    Modal: ResetPasswordModal,
  } = useModal();
  const {
    openModal: openResetPasswordInfoModal,
    closeModal: closeResetPasswordInfoModal,
    Modal: ResetPasswordInfoModal,
  } = useModal();
  const { make: validate, isLoading: isResettingPassword } = useFetch<
    IResetPasswordActionPayload,
    IResetPasswordResponse
  >({
    method: 'POST',
    endpoint: endpoints.resetPassword,
  });

  const handleDeleteUser = React.useCallback(
    (id: string, newOwnerId?: string) => {
      return new Promise((resolve, reject) => {
        dispatch(deleteUserRequest({ userId: id, newOwnerId, resolve, reject }));
      })
        .then(() => {
          notification.success(NotificationListEnum.Success, { content: 'The user was successfully deleted' });
        })
        .catch((e: IErrorResponse) => {
          const { error_code } = e;

          if (error_code === USER_REASSIGN_REQUIRED_ERROR_CODE) {
            openReassignModal({ id, onDelete: handleDeleteUser });
          } else {
            handleApiError(`Something bad happened. The user wasn't deleted.`);
          }
        })
        .finally(() => {
          if (typeof closeDeleteModal === 'function') {
            closeDeleteModal();
          }
        });
    },
    [dispatch, closeDeleteModal, openReassignModal],
  );

  const handleResetPasswordClick = React.useCallback(
    (email: string) => {
      validate({ body: { email } })
        .then(({ link }) => openResetPasswordInfoModal(link))
        .catch(handleApiError(`Something bad happened. The password wasn't reset.`))
        .finally(closeResetPasswordModal);
    },
    [dispatch, openResetPasswordInfoModal, closeResetPasswordModal],
  );

  const checkCanEditUser = React.useCallback(
    ({ admin_type: userAdminType = '', uid = '' }: Partial<IUser>) => {
      const canEditOwnProfile =
        hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], adminType) && uid === currentUserId;
      const isUerIncludeInPermissionList =
        USERS_EDITING_PERMISSION_LIST[adminType] && USERS_EDITING_PERMISSION_LIST[adminType].includes(userAdminType);

      return canEditOwnProfile || (canEditUser && isUerIncludeInPermissionList);
    },
    [canEditUser, adminType, currentUserId],
  );

  const deleteUserModal = React.useMemo(
    () => (
      <DeleteModal className="common-modal">
        {(id: string) => {
          return (
            <DeleteItemModal assetName={USER_LABEL} onDelete={() => handleDeleteUser(id)} onClose={closeDeleteModal} />
          );
        }}
      </DeleteModal>
    ),
    [DeleteModal, handleDeleteUser, closeDeleteModal],
  );

  const reassignUserModal = React.useMemo(
    () => (
      <ReassignModal className={cn('common-modal', styles.reassignModal)}>
        {({ id, onDelete }) => <ReassignUserModal onClose={closeReassignModal} onDelete={onDelete} id={id} />}
      </ReassignModal>
    ),
    [ReassignModal, closeReassignModal],
  );

  const resetUserPasswordModal = React.useMemo(
    () => (
      <ResetPasswordModal className="common-modal">
        {(email: string) => {
          return (
            <ResetUserPasswordModal
              onReset={() => handleResetPasswordClick(email)}
              onCancel={closeResetPasswordModal}
              isLoading={isResettingPassword}
            />
          );
        }}
      </ResetPasswordModal>
    ),
    [closeResetPasswordModal, ResetPasswordModal, ResetPasswordModal, isResettingPassword],
  );

  const resetUserPasswordInfoModal = React.useMemo(
    () => (
      <ResetPasswordInfoModal className="common-modal">
        {(url: string) => {
          return <ResetUserPasswordInfoModal url={url} onClose={closeResetPasswordInfoModal} />;
        }}
      </ResetPasswordInfoModal>
    ),
    [closeResetPasswordInfoModal, ResetPasswordInfoModal],
  );

  const render = React.useMemo(() => {
    switch (flowId) {
      case URL_VARS.EDIT:
        if (!originalUser) {
          if (!usersIsLoaded) {
            return null;
          }

          notification.noAsset(USER_LABEL.toLocaleLowerCase());
          return <Redirect to={{ pathname: routes.dashboard }} />;
        }

        if (!checkCanEditUser(originalUser)) {
          notification.accessDenied();
          return <Redirect to={{ pathname: routes.dashboard }} />;
        }

        return (
          <AdminRoute
            roles={[SUPER_ADMIN, DISTRIBUTOR, ORG_ADMIN]}
            render={() => <EditUserContainer onResetPassword={openResetPasswordModal} />}
          />
        );
      case URL_VARS.NEW:
        return (
          <AdminRoute
            roles={[SUPER_ADMIN, DISTRIBUTOR, ORG_ADMIN]}
            render={() => <EditUserContainer onResetPassword={openResetPasswordModal} />}
          />
        );
      case URL_VARS.VIEW:
        if (!originalUser) {
          if (!usersIsLoaded) {
            return null;
          }

          notification.noAsset(USER_LABEL.toLocaleLowerCase());
          return <Redirect to={{ pathname: routes.dashboard }} />;
        }
        return <PrivateRoute render={() => <EditUserContainer />} />;
      default:
        return (
          <UsersContainer
            onDelete={openDeleteModal}
            onResetPassword={openResetPasswordModal}
            checkCanEditUser={checkCanEditUser}
          />
        );
    }
  }, [flowId, originalUser, usersIsLoaded, checkCanEditUser, openDeleteModal, openResetPasswordModal]);

  React.useEffect(() => {
    dispatch(fetchUsersRequest());
  }, [dispatch]);

  return (
    <>
      {render}
      {deleteUserModal}
      {resetUserPasswordModal}
      {resetUserPasswordInfoModal}
      {reassignUserModal}
    </>
  );
};

export default Users;
