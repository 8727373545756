import { createSelector } from 'reselect';

import { IAppState } from '../reducers';

export const selectRootState = (state: IAppState) => state.reasons;

export const selectBusinessReasons = createSelector(selectRootState, (reasons) =>
  reasons && reasons.items ? reasons.items : null,
);

export const selectIsBusinessReasonsLoading = createSelector(selectRootState, (reasons) =>
  reasons ? reasons.isLoading : false,
);

export const selectReasonDetails = createSelector(selectRootState, (reasons) =>
  reasons ? reasons.reasonDetails : null,
);

export const selectIsReasonDetailsLoading = createSelector(selectRootState, (reasons) =>
  reasons ? reasons.isReasonDetailsLoading : false,
);

export const selectBusinessReasonById = (id?: string | null) =>
  createSelector(selectBusinessReasons, (reasons) => {
    if (!reasons || !id) {
      return null;
    }
    return reasons?.find((item) => item.uid === id);
  });
