import cn from 'classnames';
import { FieldAttributes } from 'formik';
import * as React from 'react';

import FadeInOut from '../../../../animations/FadeInOut';
import { WARNING_RANGE } from '../../../../constants/templates';
import { InputLabel } from '../../labels';

import styles from './Textarea.module.scss';

interface IProps extends FieldAttributes<any> {
  name?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  type?: string;
  label?: string;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  helperText?: string | React.ReactElement[] | React.ReactElement;
  value?: string;
  onMount?: () => void;
  readOnly?: boolean;
  showCounter?: boolean;
  maxCharsCount?: number;
}

const Textarea = ({
  name,
  placeholder,
  className,
  inputClassName,
  helperText,
  field,
  disabled = false,
  onChange,
  onBlur,
  onFocus,
  value,
  onMount,
  readOnly,
  showCounter = false,
  maxCharsCount = 0,
}: IProps) => {
  React.useEffect(() => {
    if (typeof onMount === 'function') {
      onMount();
    }
  }, []);

  const handleChange = (e: any) => {
    if (field?.onChange(e)) {
      field.onChange(e);
    }
    if (onChange) {
      onChange(e);
    }
  };

  const handleBlur = (e: any) => {
    if (field?.onBlur(e)) {
      field.onBlur(e);
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  const renderCounter = React.useMemo(() => {
    const valueLength = (typeof value === 'undefined' || value === null ? field?.value : value)?.length;

    return (
      <FadeInOut isOpen={showCounter} className={styles.counter}>
        <span
          className={cn(styles.current, {
            [styles.warning]: maxCharsCount - valueLength < WARNING_RANGE && valueLength <= maxCharsCount,
            [styles.error]: valueLength > maxCharsCount,
          })}
        >
          {valueLength}
        </span>
        <span className={styles.delimiter}>/</span>
        <span className={styles.max}>{maxCharsCount}</span>
      </FadeInOut>
    );
  }, [maxCharsCount, value, field?.value, showCounter]);

  return (
    <div className={cn(className, styles.textareaContainer)}>
      <InputLabel value={helperText} />
      <div className={styles.textareaWrapper}>
        <textarea
          readOnly={readOnly}
          name={name || field?.name}
          value={value === undefined || value === null ? field?.value : value}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={onFocus}
          className={cn(styles.textarea, inputClassName)}
          placeholder={placeholder}
          disabled={disabled}
        />

        {renderCounter}
      </div>
    </div>
  );
};

export default Textarea;
