import cn from 'classnames';
import * as React from 'react';
import { animated, useTrail } from 'react-spring';

import { ISearchItem } from '../../types/crm';
import { getSearchItemAddressDescription } from '../../utils/crm';
import { capitalize } from '../../utils/ui';
import styles from './LeadsAndContactsList.module.scss';

interface IProps {
  onSelect: (item: ISearchItem) => void;
  items: ISearchItem[] | null;
}

const LeadsAndContactsList = ({ onSelect, items }: IProps) => {
  const trail = useTrail(items ? items.length : 0, { opacity: 1 });

  const results = React.useMemo(
    () =>
      items &&
      trail &&
      trail.map((props, i) => {
        const item = items[i];

        return (
          <animated.button
            style={props}
            key={item.Id}
            className={cn(styles.item)}
            onClick={() => onSelect(item)}
            type="button"
          >
            <div className={cn(styles.caption)}>
              <span className={cn(styles.contactInfo)}>
                {item.FirstName} {item.LastName} , <span className={styles.email}>{item.Email}</span>
              </span>
              {item.Phone && <span className={cn(styles.phone)}>{item.Phone}</span>}
              {item && item.attributes && item.attributes.type && (
                <span className={cn(styles.badge, styles[item.attributes.type.toLowerCase()])}>
                  {capitalize(item.attributes.type)}
                </span>
              )}
            </div>
            <div className={styles.additionalInfo}>
              <span className={styles.description}>{getSearchItemAddressDescription(item)}</span>
              {item.Company && <span className={styles.company}>{item.Company}</span>}
            </div>
          </animated.button>
        );
      }),
    [items, trail],
  );

  const emptyResult = React.useMemo(
    () => <div className={cn(styles.item, styles.noResults)}>No results. Try another query, please.</div>,
    [],
  );

  return (
    <React.Fragment>
      {items === null ? null : <div className={cn(styles.list)}>{items.length ? results : emptyResult}</div>}
    </React.Fragment>
  );
};

export default LeadsAndContactsList;
