import cn from 'classnames';
import getSymbolFromCurrency from 'currency-symbol-map';
import { FormikValues } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { NO_CURRENCY } from '../../../../constants/shell';
import { selectBaseCurrency, selectCurrencyCodes } from '../../../../store/selectors/shell';
import { Selector } from '../../../forms/inputs';
import { CurrencyValue } from '../Selector/components';

import styles from './CurrencySelector.module.scss';

export interface ICurrencySelectorProps extends FormikValues {
  label?: string;
  className?: string;
  displayFormat?: 'short' | 'long';
  defaultValue?: string | null;
  excludedOptions?: string[] | string;
  hint?: string;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

const CurrencySelector = ({
  value,
  onChange,
  label,
  className,
  defaultValue,
  excludedOptions,
  displayFormat = 'long',
  hint,
  isReadOnly,
  isDisabled,
}: ICurrencySelectorProps) => {
  const currencyCodes = useSelector(selectCurrencyCodes);
  const baseCurrency = useSelector(selectBaseCurrency);

  const isShort = React.useMemo(() => displayFormat === 'short', [displayFormat]);

  const currencyOptions = React.useMemo(() => {
    let options = currencyCodes
      ? [
          NO_CURRENCY,
          ...currencyCodes.map((currencyCode) => ({
            value: currencyCode,
            label: `${getSymbolFromCurrency(currencyCode)} ${currencyCode}`,
          })),
        ]
      : [];

    if (excludedOptions && Array.isArray(excludedOptions)) {
      options = options.filter((option) => !excludedOptions.includes(option.value));
    }

    if (excludedOptions && !Array.isArray(excludedOptions)) {
      options = options.filter((option) => option.value !== excludedOptions);
    }

    if (excludedOptions === '') {
      options = options.filter((option) => option.value !== '');
    }

    return options;
  }, [currencyCodes, excludedOptions]);

  const handleChange = React.useCallback(
    (selectedValue: any) => {
      if (onChange) {
        onChange(selectedValue.value);
      }
    },
    [onChange],
  );

  const formattedValue = React.useMemo(() => {
    return currencyOptions.find(
      (option) => option.value === (typeof value !== 'undefined' ? value : defaultValue ?? baseCurrency),
    );
  }, [value, defaultValue, currencyOptions, baseCurrency]);

  return (
    <Selector
      value={formattedValue}
      onChange={handleChange}
      helperText={label}
      containerClassName={cn(
        styles.container,
        className,
        isShort && styles.noCode,
        !!formattedValue && styles.hasValue,
      )}
      options={currencyOptions}
      isSearchable={false}
      isClearable={false}
      closeMenuOnSelect
      placeholder={false}
      hint={hint}
      customValue={isShort ? CurrencyValue : undefined}
      readOnly={isReadOnly}
      isDisabled={isDisabled}
    />
  );
};

export default CurrencySelector;
