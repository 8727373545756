import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as CheckmarkIcon } from '../../assets/images/icon-checkmark.svg';
import { IIntegrationStatus } from '../../types/integrations';
import { ActionButton } from '../forms';
import IntegrationSpinner from '../IntegrationSpinner/IntegrationSpinner';
import styles from './IntegrationPicker.module.scss';

interface IProps {
  className: string;
  onClick: () => void;
  icon?: any;
  title: string;
  status?: IIntegrationStatus;
  buttonTitle?: string;
}

const IntegrationPicker: React.FC<IProps> = ({
  className,
  title,
  icon: IconComponent,
  onClick,
  status,
  buttonTitle = 'Add integration',
}: IProps) => {
  const [showAuthStyle, setShowAuthStyle] = React.useState(false);

  const integrationStatus = React.useMemo(() => {
    if (status && status.isAuthInProgress) {
      return (
        <div className={cn(styles.status)}>
          <IntegrationSpinner className={styles.statusIcon} />
          <span>In progress...</span>
        </div>
      );
    }

    if (status && status.isLoading) {
      return (
        <div className={cn(styles.status)}>
          <IntegrationSpinner className={styles.statusIcon} />
          <span>Checking...</span>
        </div>
      );
    }

    const startIntegration = (
      <ActionButton
        title={buttonTitle}
        className={styles.button}
        onClick={onClick}
        onMouseEnter={() => setShowAuthStyle(true)}
        onMouseLeave={() => setShowAuthStyle(false)}
      />
    );

    const integratedStatus = (
      <div className={cn(styles.status)}>
        <CheckmarkIcon className={cn(styles.statusIcon, styles.rotate)} />
        Integrated
      </div>
    );

    return status && status.isAuthenticated ? integratedStatus : startIntegration;
  }, [status, buttonTitle, onClick]);

  return (
    <div
      className={cn(
        styles.integrationPicker,
        styles[className],
        (status && status.isAuthenticated) || showAuthStyle ? styles.authenticated : null,
      )}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.icon}>
        <IconComponent />
      </div>
      {integrationStatus}
      {status && status.error && (
        <div className={cn(styles.error)}>
          Something went wrong, please, try again or <a href="mailto:info@imprintengine.com">contact us</a>{' '}
        </div>
      )}
    </div>
  );
};

export default IntegrationPicker;
