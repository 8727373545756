import { addDays } from 'date-fns';
import format from 'date-fns/format';

import { OrgAddressFilterTypesEnum } from '../constants/addressBook';
import { DELAYED_SHIPPING_EXPIRATION, MAX_DELAYED_SHIPPING_EXPIRATION_DATE, SEND_ON_HOLD } from '../constants/bucket';
import { CUSTOM, ORG_ADDRESS_IDS } from '../constants/campaigns';
import { DateFormatsEnum } from '../constants/date';
import {
  CRM_TYPE,
  DISABLE_EMAIL_NOTIFICATIONS,
  DISABLE_SENDER_EMAIL_NOTIFICATIONS,
  IS_RECEIVER_ADDRESS_FIXED,
  RECEIVER_FIXED_ADDRESS,
} from '../constants/shipping';
import { IOrgAddress } from '../types/addressBook';
import {
  IFileInfo,
  IFlowStepProps,
  IOneLinkEngagementCandidate,
  IOneLinkShippingDetails,
  TEngagementCandidate,
  TEngagementRequestPayload,
} from '../types/bucket';
import { ICampaign } from '../types/campaigns';
import { IOnDemandItem } from '../types/inventories';
import { IOneLinkReceiverFixedAddress } from '../types/oneLink';
import { isDigitalBox } from './campaigns';
import { formatDate } from './date';
import { isItemMsku, isItemOnDemand } from './inventories';
import { mapOrgAddressToOLFixedAddress } from './oneLink';
import { isAwaitingAddress } from './reports';

export const getFileInfo = (file: File): IFileInfo | null => {
  if (!file) {
    return null;
  }
  const { name, lastModified, size } = file;

  return { name, lastModified, size };
};

export const formatLastModified = (date: number) => format(date, `d MMM, yyyy 'at' H:mm aaa`);

export const formatFileSize = (size: number) => {
  let unitPrefix = '';
  let sizeToShow = size.toString();

  if (size >= 100 && size < 1024 * 1024) {
    unitPrefix = 'K';
    sizeToShow = (size / 1024).toFixed(1);
  } else if (size >= 1024 * 1024) {
    unitPrefix = 'M';
    sizeToShow = (size / 1024 / 1024).toFixed(1);
  }

  return `${sizeToShow} ${unitPrefix}b`;
};

export const getSubstringAfterChar = (str: string, char: string) => str?.substring(str.indexOf(char) + 1);

type EngagementMapperMetadata = Omit<IFlowStepProps, 'className' | 'SenderInfo' | 'isPYG'> & {
  isOnHold: boolean;
  crmType: 'salesforce' | 'hubspot' | 'none';
};

/**
 * Maps the engagement candidate to the engagement request payload.
 *
 * @param {TEngagementCandidate | IOneLinkEngagementCandidate} engagement - The engagement candidate to be mapped.
 * @param {EngagementMapperMetadata} metadata - Additional metadata for the engagement mapping.
 * @returns {TEngagementRequestPayload} - The mapped engagement request payload.
 */
export const mapEngagementCandidateToRequest = (
  engagement: TEngagementCandidate | IOneLinkEngagementCandidate,
  metadata: EngagementMapperMetadata,
): TEngagementRequestPayload => {
  const { items: engagementItems, pick_campaigns, disable_sender_email_notifications, ...restEngagement } = engagement;
  const { isOneLink, crmType, isOnHold: isToggleOnHoldEnabled } = metadata;
  const { on_hold_until, delayed_shipping_expiration } = engagement;

  const isDelayedShipping = !isOneLink && isAwaitingAddress(engagement as TEngagementCandidate);

  const onHoldUntilDefaultDate = addDays(new Date(), 30).toISOString();

  const items = engagementItems?.map((item) => {
    if (isItemOnDemand(item)) {
      const onDemandItem = item as IOnDemandItem;
      const { item_id, ...onDemandRequestItem } = onDemandItem;
      return onDemandRequestItem;
    }
    if (isItemMsku(item) && item.sku_options?.length) {
      const { sku_options, ...restItem } = item;

      return { ...restItem, sku_option_ids: sku_options?.map(({ item_id }) => item_id) };
    }

    return item;
  });

  const onHoldUntil = (() => {
    if (on_hold_until?.length) {
      return on_hold_until;
    }
    if (isToggleOnHoldEnabled) {
      return onHoldUntilDefaultDate;
    }
    return undefined;
  })();

  const expirationDate = (() => {
    if (!isDelayedShipping) {
      return;
    }
    if (delayed_shipping_expiration?.length) {
      return delayed_shipping_expiration;
    }

    return formatDate(MAX_DELAYED_SHIPPING_EXPIRATION_DATE.toISOString(), DateFormatsEnum.End);
  })();

  // Below is a workaround and legacy issue in existing send models
  // Sends have the flag with the name `disable_email_notifications`
  // OneLinks and bulk request have the flag with the name `disable_sender_email_notifications`
  // Both flags are the same, but should be mapped differently in the request
  // isBulk case is not covered here because it's not used there in the current implementation
  const senderNotificationsConfig = {
    [isOneLink ? DISABLE_SENDER_EMAIL_NOTIFICATIONS : DISABLE_EMAIL_NOTIFICATIONS]: disable_sender_email_notifications,
  };

  let olShippingDetails;

  if (isOneLink) {
    const { is_receiver_address_fixed, receiver_fixed_address, org_address_ids } =
      engagement as IOneLinkEngagementCandidate;

    olShippingDetails = {
      is_receiver_address_fixed,
      org_address_ids,
      receiver_fixed_address,
    };
  }

  return {
    ...restEngagement,
    ...(pick_campaigns ? { pick_campaign_ids: pick_campaigns.map(({ box_id }) => box_id) } : {}),
    items,
    ...senderNotificationsConfig,
    [SEND_ON_HOLD]: onHoldUntil,
    [DELAYED_SHIPPING_EXPIRATION]: expirationDate,
    ...(!isOneLink ? { [CRM_TYPE]: crmType } : olShippingDetails),
  };
};

const filterActiveAddresses = (addressIDs?: string[], addressBook?: IOrgAddress[]): string[] | undefined => {
  const activeAddressIDs = addressIDs?.filter((uid) => {
    const address = addressBook?.find((adr) => adr.uid === uid);

    return address?.status === OrgAddressFilterTypesEnum.Active;
  });

  return activeAddressIDs?.length ? activeAddressIDs : undefined;
};

// Unable to move it to constants because of a bundler error
export const DEFAULT_OL_SHIPPING_DETAILS = {
  [IS_RECEIVER_ADDRESS_FIXED]: false,
  [ORG_ADDRESS_IDS]: undefined,
  [RECEIVER_FIXED_ADDRESS]: undefined,
};

export const getShippingDetailsFromCampaign = (
  campaign: ICampaign | ICampaign[],
  { isOneLink, addressBook }: IFlowStepProps & { addressBook?: IOrgAddress[] },
): IOneLinkShippingDetails | undefined => {
  if (isOneLink) {
    // return nothing in case of a digital flow
    if (Array.isArray(campaign) ? campaign.every(isDigitalBox) : isDigitalBox(campaign)) {
      return;
    }

    // PYG case - return default values
    if (Array.isArray(campaign)) {
      return DEFAULT_OL_SHIPPING_DETAILS;
    }

    const { is_receiver_address_fixed, is_delayed_shipping, org_address_ids } = campaign.shipping_configuration || {};

    switch (true) {
      case !is_delayed_shipping:
        return DEFAULT_OL_SHIPPING_DETAILS;
      case is_receiver_address_fixed: {
        // 'Recipient decides from list only' OR 'Fixed' case
        // When 'Fixed' -> campaign's org_address_ids can have only 1 item - either CUSTOM or an address ID
        const hasOneCampaign = org_address_ids?.length === 1;
        const hasCustomCampaign = org_address_ids?.includes(CUSTOM);

        return {
          is_receiver_address_fixed: true,
          // in case there's a custom address OR there are more than one address added, we should not set org_address_ids
          org_address_ids:
            hasOneCampaign || hasCustomCampaign ? undefined : filterActiveAddresses(org_address_ids, addressBook),
          receiver_fixed_address: (() => {
            // in case there is more than 1 address ID, we should not set receiver_fixed_address
            if (!hasOneCampaign) {
              return undefined;
            }

            const emptyReceiverAddress = {} as IOneLinkReceiverFixedAddress;
            // in case there should be a custom address set during the flow,
            // receiver_fixed_address should NOT be undefined, but the empty object
            // it will be used as a flag to show the proper shipping details selector value on the UI
            if (hasCustomCampaign) {
              return emptyReceiverAddress;
            }

            const addressRecord = addressBook?.find(
              (a) => a.status === OrgAddressFilterTypesEnum.Active && a.uid === org_address_ids[0],
            );

            // in case the address is not found in the address book, we should return the empty object
            return addressRecord ? mapOrgAddressToOLFixedAddress(addressRecord) : emptyReceiverAddress;
          })(),
        };
      }
      // 'Recipient decides' case
      // We should take care of active addresses filtering, all the rest should be passed as it is, no fixed address populated
      case !!org_address_ids?.length: {
        return {
          is_receiver_address_fixed: false,
          org_address_ids: filterActiveAddresses(org_address_ids, addressBook),
          receiver_fixed_address: undefined,
        };
      }
      // Should be unreachable case
      default: {
        console.warn('Unreachable case in getShippingDetailsFromCampaign. Contact the product team and share details.');
        return DEFAULT_OL_SHIPPING_DETAILS;
      }
    }
  }

  return DEFAULT_OL_SHIPPING_DETAILS;
};
