import cn from 'classnames';
import { useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ActionButton, OneLinkShippingForm } from '../../components/forms';
import { SHIPPING, SHIPPING_OPTION } from '../../constants/shipping';
import { addBucketValue } from '../../store/actions/bucket';
import {
  selectIsDigitalBucket,
  selectIsShippingOptionEnabled,
  selectOneLinkShippingData,
  selectOneLinkShippingDetails,
  selectOneLinkShippingInitialValues,
  selectOneLinkValidationSchema,
} from '../../store/selectors/bucket';
import { IFlowStepProps, IOneLinkFormFields } from '../../types/bucket';
import { SUBMIT_BUTTON } from '../../types/forms';
import { ShippingOptionsEnum } from '../../types/shipping';
import { handleComplexFieldChange } from '../../utils/form';
import { isObjectEmpty } from '../../utils/helpers';

import styles from '../AddRecipientContainer/AddRecipientContainer.module.scss';

const OneLinkShippingContainer = ({ next }: IFlowStepProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const initialValues = useSelector(selectOneLinkShippingInitialValues);
  const validationSchema = useSelector(selectOneLinkValidationSchema);
  const isDigital = useSelector(selectIsDigitalBucket);
  const originalData = useSelector(selectOneLinkShippingData);
  const isShippingOptionEnabled = useSelector(selectIsShippingOptionEnabled);
  const shippingDetails = useSelector(selectOneLinkShippingDetails);

  const isShippingConfigurationValid = React.useMemo(() => {
    if (isDigital) {
      return true;
    }

    const { is_receiver_address_fixed, receiver_fixed_address, org_address_ids } = shippingDetails;

    if (!is_receiver_address_fixed) {
      return true;
    }

    return !isObjectEmpty(receiver_fixed_address) || (org_address_ids && org_address_ids?.length > 1);
  }, [shippingDetails]);

  const onSubmit = React.useCallback(
    (values: IOneLinkFormFields) => {
      if (!validationSchema.isValidSync(values)) {
        return;
      }

      if (next) {
        history.push(next);
      }
    },
    [next, validationSchema],
  );

  const form = useFormik<IOneLinkFormFields>({
    initialValues,
    validationSchema,
    enableReinitialize: false,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit,
  });

  const onChange = React.useCallback(
    async (name: string, value: number | string | boolean | null) => {
      await form.setFieldTouched(name, true, true);
      await form.setFieldValue(name, value, true).then(() => {
        dispatch(addBucketValue({ ...handleComplexFieldChange(name, value) }));
      });
    },
    [dispatch, form.setFieldValue, form.setFieldTouched],
  );

  React.useEffect(() => {
    if (!isDigital && !originalData?.[SHIPPING]) {
      dispatch(addBucketValue(handleComplexFieldChange(`${SHIPPING}.${SHIPPING_OPTION}`, ShippingOptionsEnum.Ground)));
    }
    if (isDigital && originalData?.[SHIPPING]) {
      dispatch(addBucketValue(handleComplexFieldChange(`${SHIPPING}`, null)));
    }
  }, [dispatch, isDigital, initialValues]);

  return (
    <React.Fragment>
      <OneLinkShippingForm
        onChange={onChange}
        form={form}
        isDigital={isDigital}
        isShippingOptionEnabled={isShippingOptionEnabled}
      />
      <div className={cn(styles.controls)}>
        <ActionButton
          disabled={!form.isValid || !isShippingConfigurationValid}
          type={SUBMIT_BUTTON}
          className={styles.continueButton}
          title="Continue"
          onClick={form.handleSubmit}
        />
      </div>
    </React.Fragment>
  );
};

export default OneLinkShippingContainer;
