import * as React from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/images/icon-close-cross.svg';
import { IModalAction } from '../../../types/modals';
import { ActionButton } from '../../forms';
import ModalActions from '../ModalActions/ModalActions';
import ModalHeader from '../ModalHeader/ModalHeader';
import styles from './CommonModals.module.scss';

interface IProps {
  assetName: string;
  description: string;
  onClose: () => void;
  actions: IModalAction[];
  showCloseButton?: boolean;
}

const ChoosingItemTypeModal: React.FC<IProps> = ({
  assetName,
  description,
  onClose,
  actions,
  showCloseButton = true,
}: IProps) => {
  const handleItemBtnClick = React.useCallback(
    (cb?: (...args: any[]) => void) => {
      if (typeof cb === 'function') {
        cb();
      }
      onClose();
    },
    [onClose],
  );

  return (
    <div className={styles.container}>
      <ModalHeader className={styles.header} right={showCloseButton && <CloseIcon onClick={onClose} />} />
      <div className={styles.content}>
        <div className={styles.contentHeader}>Choosing {assetName}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <ModalActions className={styles.footer}>
        {actions.map(({ title, onClick }) => {
          return <ActionButton key={title} title={title} onClick={() => handleItemBtnClick(onClick)} />;
        })}
      </ModalActions>
    </div>
  );
};

export default ChoosingItemTypeModal;
