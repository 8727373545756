import { CAMPAIGN_ID, CONNECTION_ID } from '../constants/automations';
import { IPromiseCallbacks } from './redux';

//#region Automated connections

export enum AutomationStatusEnum {
  Active = 'active',
  NotActive = 'not_active',
  Failed = 'failed',
}

export enum AutomatedConnectionTypeEnum {
  HR = 'HR',
  Recruiting = 'Recruiting',
}

export interface IAutomatedConnection {
  uid: string;
  name: string;
  type: AutomatedConnectionTypeEnum;
  token: string;
  status: AutomationStatusEnum;
  failure_reason?: string;
}

export interface IDeleteAutomatedConnectionActionPayload extends IPromiseCallbacks {
  id: string;
  orgId: string;
}

export interface IUpdateAutomatedConnectionActionPayload extends Partial<IAutomatedConnection> {
  orgId: string;
}

export interface IUpdateAutomatedConnectionRequestActionPayload extends IPromiseCallbacks {
  automatedConnection: Partial<IAutomatedConnection>;
  orgId: string;
}

export interface IAddAutomatedConnectionActionPayload extends IPromiseCallbacks {
  automatedConnection: Omit<IAutomatedConnection, 'status' & 'uid' & 'failure_reason'>;
  orgId: string;
}

export interface IAutomatedConnectionsState {
  items: IAutomatedConnection[] | null;
  isLoading: boolean;
  error: any;
}

//#endregion

//#region Automated campaigns

export enum AutomatedCampaignTypeEnum {
  Anniversary = 'ac_anniversary',
  NewHire = 'ac_new_hire',
  Birthday = 'ac_birthday',
}

export interface IAutomatedCampaign {
  uid: string;
  connection_id: string;
  type: AutomatedCampaignTypeEnum;
  anniversary_year: number;
  name: string;
  sender_id: string;
  campaign_ids: string[];
  status: AutomationStatusEnum;
  failure_reason?: string;
  org_id?: string;
}

export interface IFetchAutomatedCampaignsResponse {
  automatedCampaigns: IAutomatedCampaign[];
}

export interface IDeleteAutomatedCampaignActionPayload extends IPromiseCallbacks {
  id: string;
}

export interface IUpdateAutomatedCampaignActionPayload extends IPromiseCallbacks {
  automatedCampaign: Partial<IAutomatedCampaign>;
}

export interface IAddAutomatedCampaignActionPayload extends IPromiseCallbacks {
  automatedCampaign: Omit<IAutomatedCampaign, 'status' & 'uid' & 'failure_reason'>;
}

export interface IAutomationsState {
  items: IAutomatedCampaign[] | undefined | null;
  isLoading: boolean;
  error: any;
}

//#endregion

export type TAutomationFormCandidateId =
  | { [CONNECTION_ID]: string; [CAMPAIGN_ID]: never }
  | { [CAMPAIGN_ID]: string; [CONNECTION_ID]: never };
