import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning.svg';
import { BLUEBIRD_SETTINGS_FIRESTORE_COLLECTION, EMERGENCY_NOTIFICATION_FIRESTORE_DOC_ID } from '../../constants/shell';

import { updateEmergencyNotification } from '../../store/actions/shell';
import { selectEmergencyNotification } from '../../store/selectors/shell';
import { IEmergencyNotification } from '../../types/shell';

import styles from './EmergencyNotification.module.scss';

const EmergencyNotification = () => {
  const firebase = useFirebase();
  const dispatch = useDispatch();

  const emergencyNotification = useSelector(selectEmergencyNotification);

  React.useEffect(() => {
    return firebase
      .firestore()
      .collection(BLUEBIRD_SETTINGS_FIRESTORE_COLLECTION)
      .doc(EMERGENCY_NOTIFICATION_FIRESTORE_DOC_ID)
      .onSnapshot((snapshot) => {
        const notification = snapshot?.data() as IEmergencyNotification;
        dispatch(updateEmergencyNotification(notification || null));
      });
  }, []);

  return emergencyNotification?.is_active && emergencyNotification?.message ? (
    <div title={emergencyNotification?.hint} className={styles.container}>
      <WarningIcon />
      <span>{emergencyNotification?.message}</span>
    </div>
  ) : null;
};

export default EmergencyNotification;
