import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as InventoryIcon } from '../../assets/images/icon-inventory.svg';

import styles from './ItemCount.module.scss';

export interface IItemCountProps<RequestType, ResponseType> {
  className?: string;
  count?: number;
}

const ItemCount = <RequestType, ResponseType>({ count, className }: IItemCountProps<RequestType, ResponseType>) => {
  const label = React.useMemo(() => {
    switch (true) {
      case count === -1:
        return 'N/A';
      default:
        return (count || 0).toFixed();
    }
  }, [count]);

  return (
    <div className={cn(className, styles.container)}>
      <InventoryIcon className={styles.inventoryIcon} />
      {<span className={styles.count}>{label}</span>}
    </div>
  );
};

export default ItemCount;
