import migration150 from './1.5/1.5.0';
import migration160 from './1.6/1.6.0';
import migration161 from './1.6/1.6.1';
import migration162 from './1.6/1.6.2';
import migration163 from './1.6/1.6.3';

export const migrations = {
  150: migration150,
  160: migration160,
  161: migration161,
  162: migration162,
  163: migration163,
};
