import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as BackIcon } from '../../../../assets/images/icon-back.svg';
import { ReactComponent as GiftIcon } from '../../../../assets/images/icon-gift.svg';
import useWindowSize from '../../../../hooks/useWindowSize';
import styles from './PickYourGiftButton.module.scss';

interface IProps {
  isEnabled: boolean;
  className?: string;
  onClick: (...args: any[]) => void;
}

const PickYourGiftButton = ({ isEnabled, className, onClick }: IProps) => {
  const { mobile } = useWindowSize();

  return (
    <button className={cn(styles.pygBtn, className, isEnabled ? styles.enabled : styles.disabled)} onClick={onClick}>
      <div className={styles.infoWrapper}>
        {isEnabled ? (
          <React.Fragment>
            <BackIcon className={styles.backIcon} />
            <span>Go to single send</span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <GiftIcon className={styles.giftIcon} />
            {!mobile && <span>Pick your gift!</span>}
          </React.Fragment>
        )}
      </div>
    </button>
  );
};

export default PickYourGiftButton;
