import cn from 'classnames';
import { useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ZapierKeyForm } from '../../components/forms';
import { generateZapierApiKeyRequest, getUserApiKeyRequest } from '../../store/actions/integrations';
import {
  selectApiKeyWasLoaded,
  selectUserApiKey,
  selectZapierInitialState,
  selectZapierIsLoading,
} from '../../store/selectors/integrations';
import { IZapierAPIKey } from '../../types/integrations';
import { handleApiError } from '../../utils/ui';

import styles from './ZapierKeyContainer.module.scss';

interface IProps {
  className?: string;
}

const ZapierKeyContainer = ({ className }: IProps) => {
  const dispatch = useDispatch();

  const wasApiKeyLoaded = useSelector(selectApiKeyWasLoaded);
  const isZapierLoading = useSelector(selectZapierIsLoading);
  const initialState = useSelector(selectZapierInitialState);
  const userApiKey = useSelector(selectUserApiKey);

  const initialValues = React.useMemo(() => {
    return {
      ...initialState,
      api_key: userApiKey || '',
    };
  }, [initialState, userApiKey]);

  const form = useFormik<IZapierAPIKey>({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => void 0,
  });

  const handleGenerateNewApiKey = React.useCallback(() => {
    return new Promise<IZapierAPIKey>((resolve, reject) => {
      dispatch(generateZapierApiKeyRequest({ resolve, reject }));
    })
      .then((apiKey) => {
        form.setValues(apiKey);
        return true;
      })
      .catch(handleApiError(`Something bad happened. API Key wasn't generated.`, () => false));
  }, [dispatch, form]);

  React.useEffect(() => {
    if (!wasApiKeyLoaded && !isZapierLoading) {
      dispatch(getUserApiKeyRequest());
    }
  }, [dispatch, wasApiKeyLoaded, isZapierLoading]);

  return (
    <div className={cn(className, styles.zapierContainer)}>
      <label className={styles.label}>API Key</label>
      <ZapierKeyForm form={form} readOnly={true} generateKey={handleGenerateNewApiKey} />
    </div>
  );
};

export default ZapierKeyContainer;
