import cn from 'classnames';
import * as React from 'react';

import styles from './NavToggle.module.scss';

interface IProps {
  className?: string;
  expanded: boolean;
  onClick: () => void;
}

const NavToggle = ({ className, expanded, onClick }: IProps) => {
  return <button className={cn(styles.toggle, className, { [styles.collapsed]: !expanded })} onClick={onClick} />;
};

export default NavToggle;
