import { IAddUserActionPayload, IDeleteUserActionPayload, IEditUserActionPayload, IUser } from '../../types/users';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const ADD_USER_DETAILS = 'ADD_USER_DETAILS';
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';

export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
});

export const fetchUsersSuccess = (payload?: any) => ({
  type: FETCH_USERS_SUCCESS,
  payload,
});

export const fetchUsersFailure = (payload?: any) => ({
  type: FETCH_USERS_FAILURE,
  payload,
});

export const addUserRequest = (payload: IAddUserActionPayload) => ({
  type: ADD_USER_REQUEST,
  payload,
});

export const addUserSuccess = (payload: Partial<IUser>) => ({
  type: ADD_USER_SUCCESS,
  payload,
});

export const addUserFailure = (payload: any) => ({
  type: ADD_USER_FAILURE,
  payload,
});

export const editUserRequest = (payload: IEditUserActionPayload) => ({
  type: EDIT_USER_REQUEST,
  payload,
});

export const editUserSuccess = (payload: Partial<IUser>) => ({
  type: EDIT_USER_SUCCESS,
  payload,
});

export const editUserFailure = (payload: any) => ({
  type: EDIT_USER_FAILURE,
  payload,
});

export const deleteUserRequest = (payload: IDeleteUserActionPayload) => ({
  type: DELETE_USER_REQUEST,
  payload,
});

export const deleteUserSuccess = (payload: { userId: string }) => ({
  type: DELETE_USER_SUCCESS,
  payload,
});

export const deleteUserFailure = (payload: any) => ({
  type: DELETE_USER_FAILURE,
  payload,
});

export const addUserDetails = (payload: Partial<IUser>) => ({
  type: ADD_USER_DETAILS,
  payload,
});

export const clearUserDetails = () => ({
  type: CLEAR_USER_DETAILS,
});
