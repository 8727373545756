import cn from 'classnames';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { InventoryItemForm } from '../../components/forms';
import MaintenanceForm from '../../components/forms/MaintenanceForm/MaintenanceForm';
import { InventorySuccessToastMessages, INVENTORY_ITEM_LABEL } from '../../constants/inventories';
import { routes, URL_VARS } from '../../constants/routing';
import {
  addInventoryItemRequest,
  addInventoryItemValue,
  clearInventoryItemValue,
  deleteInventoryItemRequest,
  editInventoryItemRequest,
  fetchInventoryItemByIdRequest,
} from '../../store/actions/inventories';
import {
  selectInventoryItemById,
  selectInventoryItemInitialValues,
  selectInventoryItemValidationSchema,
  selectItemDetails,
} from '../../store/selectors/inventories';
import { FormStatusEnum, IMaintenanceFormOutputData, MaintenanceFormStateEnum } from '../../types/forms';
import {
  GenderEnum,
  IAddInventoryItemRequest,
  IEditInventoryItemRequest,
  IInventoryItemCandidate,
} from '../../types/inventories';
import { IAddNewInventoryItemModalProps } from '../../types/modals';
import { IInventoryRouteParams } from '../../types/routing';
import { IApiError, NotificationListEnum } from '../../types/shell';
import { getRequiredFields } from '../../utils/form';
import { isObjectsEqual } from '../../utils/helpers';
import notification from '../../utils/notification';
import { handleApiError } from '../../utils/ui';

import styles from './EditInventoryItemContainer.module.scss';

interface IProps {
  onAdd?: (props: IAddNewInventoryItemModalProps) => void;
}

const EditInventoryItemContainer: React.FC<IProps> = ({ onAdd }: IProps) => {
  const { flowId, itemId } = useParams<IInventoryRouteParams>();

  const dispatch = useDispatch();
  const history = useHistory();
  const itemDetails = useSelector(selectItemDetails);
  const validationSchema = useSelector(selectInventoryItemValidationSchema);
  const initialValues = useSelector(selectInventoryItemInitialValues);
  const originalItem = useSelector(selectInventoryItemById(itemId));

  const requiredFields = React.useMemo(() => getRequiredFields(validationSchema), [validationSchema]);

  const dispatchSubmit = React.useCallback(
    (payload: IAddInventoryItemRequest | IEditInventoryItemRequest) => {
      switch (flowId) {
        case URL_VARS.NEW:
          return dispatch(addInventoryItemRequest(payload as IAddInventoryItemRequest));
        case URL_VARS.EDIT:
          return dispatch(editInventoryItemRequest(payload as IEditInventoryItemRequest));
        default:
          return null;
      }
    },
    [flowId, dispatch],
  );

  const handleSubmit = React.useCallback(
    (
      item: Partial<IInventoryItemCandidate>,
      { resetForm, setSubmitting, setStatus, setErrors }: FormikHelpers<Partial<IInventoryItemCandidate>>,
    ) => {
      if (!validationSchema.isValidSync(item)) {
        return;
      }

      return new Promise((resolve, reject) => {
        dispatchSubmit({ item, resolve, reject });
      })
        .then(() => {
          resetForm();
          setStatus(FormStatusEnum.Success);
          notification.success(NotificationListEnum.Success, { content: InventorySuccessToastMessages[flowId] });
          history.push(routes.inventory.root);
        })
        .catch((e: IApiError) => {
          setErrors({ api: e?.message } as FormikErrors<Partial<IInventoryItemCandidate>>);
          setStatus(FormStatusEnum.Error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [flowId, validationSchema, dispatchSubmit, history],
  );

  const handleDeleteItem = React.useCallback(
    (closeModal?: () => void, id?: string, newItemId?: string) => {
      return new Promise((resolve, reject) => {
        dispatch(deleteInventoryItemRequest({ itemId: itemId!, resolve, reject }));
      })
        .then(() => {
          history.push(routes.inventory.root);
          notification.success(NotificationListEnum.Success, { content: 'Item was successfully deleted' });
        })
        .catch(handleApiError(`Something bad happened. Inventory item wasn't deleted.`))
        .finally(() => {
          if (typeof closeModal === 'function') {
            closeModal();
          }
        });
    },
    [dispatch, history, itemId],
  );

  const isInitialValid = React.useCallback(
    () => validationSchema.isValidSync(initialValues),
    [validationSchema, initialValues],
  );

  const form = useFormik<Partial<IInventoryItemCandidate>>({
    initialStatus: FormStatusEnum.Start,
    initialValues,
    isInitialValid,
    validateOnMount: true,
    validationSchema,
    onSubmit: (values, actions) => {
      const castedValues = validationSchema.cast(values);
      if (castedValues) {
        handleSubmit(castedValues, actions);
      }
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const handleFieldChange = React.useCallback(
    (name: keyof IInventoryItemCandidate, value: string | boolean | number | undefined | IInventoryItemCandidate[]) => {
      dispatch(addInventoryItemValue({ [name]: value }));
    },
    [dispatch],
  );

  const handleCancel = React.useCallback(() => {
    if (originalItem) {
      dispatch(
        addInventoryItemValue({
          ...originalItem,
          size: originalItem?.size || '',
          flavor: originalItem?.flavor || '',
          hts_code: originalItem?.hts_code || '',
          gender: originalItem?.gender || GenderEnum.Empty,
          other_option: originalItem?.other_option || '',
          color: originalItem?.color || '',
        }),
      );
    }
  }, [dispatch, originalItem]);

  const mode = React.useMemo(() => {
    switch (flowId) {
      case URL_VARS.NEW:
        return MaintenanceFormStateEnum.Adding;
      case URL_VARS.EDIT:
        return MaintenanceFormStateEnum.Editing;
      case URL_VARS.VIEW:
        return MaintenanceFormStateEnum.Reading;
      default:
        return MaintenanceFormStateEnum.Reading;
    }
  }, [flowId]);

  const isAdding = React.useMemo(() => mode === MaintenanceFormStateEnum.Adding, [mode]);

  const isValueChanged = React.useMemo(() => {
    switch (true) {
      case mode === MaintenanceFormStateEnum.Adding:
        /* Check if inventory item candidate has other properties except its type
         * If they are missing, the form hasn't changed and the Unsaved Changes modal should not be called
         */
        const preparedItem = omit(itemDetails, ['type', 'org_id']);
        return isEmpty(preparedItem) ? false : Object.values(preparedItem).every((value) => value);
      case mode === MaintenanceFormStateEnum.Editing:
        if (!originalItem || !itemDetails) {
          return true;
        }

        return !isObjectsEqual(originalItem, itemDetails);
      default:
        return false;
    }
  }, [originalItem, itemDetails, mode]);

  React.useEffect(() => {
    if (isAdding || !originalItem || itemDetails) {
      return;
    }

    dispatch(addInventoryItemValue(originalItem));
  }, [isAdding, originalItem, itemDetails]);

  React.useEffect(() => {
    if (!isAdding && !itemDetails && !originalItem && itemId) {
      dispatch(
        fetchInventoryItemByIdRequest({
          itemId,
          includeInventoryCount: true,
        }),
      );
    }
  }, [isAdding, itemDetails, itemId, originalItem]);

  React.useEffect(() => {
    if (isAdding && !itemDetails?.type && typeof onAdd === 'function') {
      onAdd({ required: true });
    }
  }, [isAdding, itemDetails?.type, onAdd]);

  React.useEffect(() => {
    return () => {
      dispatch(clearInventoryItemValue());
    };
  }, [dispatch]);

  return (
    <div className={cn(styles.container)}>
      <MaintenanceForm
        controlsClassName={styles.controls}
        root={routes.inventory.root}
        form={form}
        assetName={INVENTORY_ITEM_LABEL}
        mode={mode}
        onDelete={handleDeleteItem}
        onCancel={handleCancel}
        onDiscard={() => history.push(routes.inventory.root)}
        onSave={form.isValid ? handleSubmit : undefined}
        isValueChanged={isValueChanged}
      >
        {(props: IMaintenanceFormOutputData) => {
          return (
            <InventoryItemForm {...props} onChange={handleFieldChange} form={form} requiredFields={requiredFields} />
          );
        }}
      </MaintenanceForm>
    </div>
  );
};

export default EditInventoryItemContainer;
