import * as React from 'react';

import { IOrganizationSummary } from '../../../types/dashboard';
import { DelayedPackagesWidget, LowItemsWidget, SendsSummaryWidget } from '../../index';

import styles from './OrganizationSummaryContent.module.scss';

interface IProps {
  summary: IOrganizationSummary;
}

const OrganizationSummaryContent = ({ summary }: IProps) => {
  const {
    amount_spent,
    currency_code,
    delayed_packages,
    low_inventory_count_items,
    number_of_manual_sends,
    number_of_automated_sends,
    number_of_sends,
  } = React.useMemo(() => summary, [summary]);

  const orgDetails = React.useMemo(
    () => ({
      total: amount_spent,
      currency_code,
      count: {
        total: number_of_sends,
        manual: number_of_manual_sends,
        automated: number_of_automated_sends,
      },
    }),
    [summary],
  );

  return (
    <div className={styles.content}>
      <SendsSummaryWidget orgDetails={orgDetails} items={summary.org_sends_dashboard_summary} />
      <div className={styles.bottom}>
        <LowItemsWidget className={styles.lowItemsWidget} items={low_inventory_count_items} />
        <DelayedPackagesWidget className={styles.delayedPackagesWidget} items={delayed_packages} />
      </div>
    </div>
  );
};

export default OrganizationSummaryContent;
