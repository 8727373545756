import cn from 'classnames';
import { FormikErrors, FormikHelpers, FormikTouched, useFormik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnChangeValue } from 'react-select';

import { CountrySelector, Loader } from '../../../components';
import {
  ADDRESS1,
  ADDRESS2,
  CITY,
  COUNTRY,
  EMAIL,
  LABEL,
  NEW_ID,
  OrgAddressFilterTypesEnum,
  ORG_ADDRESS_INITIAL_VALUES,
  ORG_ADDRESS_VALIDATION_SCHEMA as validationSchema,
  PHONE,
  STATE,
  STATUS,
  ZIP,
} from '../../../constants/addressBook';
import { addOrgAddressRequest, editOrgAddressRequest } from '../../../store/actions/addressBook';
import { selectOrgAddressById } from '../../../store/selectors/addressBook';
import {
  IAddOrgAddressActionPayload,
  IEditOrgAddressActionPayload,
  IOrgAddress,
  TNewOrgAddress,
} from '../../../types/addressBook';
import { SUBMIT_BUTTON } from '../../../types/forms';
import { ISelectorValue, NotificationListEnum } from '../../../types/shell';
import { getOrgAddressFormRequiredFieldsByCountryCode } from '../../../utils/addressBook';
import { getRequiredFields } from '../../../utils/form';
import notification from '../../../utils/notification';
import { handleApiError } from '../../../utils/ui';
import { ActionButton } from '../buttons';
import { Input, Toggle } from '../inputs';

import styles from './OrgAddressForm.module.scss';

interface IOrgAddressFormProps {
  id: string;
  onClose?: () => void;
}
const OrgAddressForm: React.FC<IOrgAddressFormProps> = ({ id, onClose }) => {
  const dispatch = useDispatch();
  const address = useSelector(selectOrgAddressById(id));

  const isAddMode = id === NEW_ID;

  const OrgAddressSuccessToastMessages = (isNew: boolean) => {
    return isNew ? 'New Address was added' : 'Address was edited';
  };

  const dispatchSubmit = React.useCallback(
    (payload: IAddOrgAddressActionPayload | IEditOrgAddressActionPayload) => {
      switch (isAddMode) {
        case true:
          return dispatch(addOrgAddressRequest(payload as IAddOrgAddressActionPayload));
        case false:
          return dispatch(editOrgAddressRequest(payload as IEditOrgAddressActionPayload));
        default:
          return null;
      }
    },
    [dispatch, isAddMode],
  );

  const handleSubmit = React.useCallback(
    async (values: Partial<IOrgAddress | TNewOrgAddress>, { setSubmitting }: FormikHelpers<Partial<IOrgAddress>>) => {
      await new Promise((resolve, reject) => {
        const addressPayload = isAddMode ? (({ status, uid, ...rest }) => rest)(values) : values;
        dispatchSubmit({ address: addressPayload, resolve, reject });
      })
        .then(() => {
          onClose?.();
          notification.success(NotificationListEnum.Success, {
            content: OrgAddressSuccessToastMessages(isAddMode),
          });
        })
        .catch(handleApiError("Something bad happened. Address wasn't saved."))
        .finally(() => {
          setSubmitting(false);
        });
    },
    [onClose, dispatchSubmit, isAddMode],
  );

  const form = useFormik<Partial<IOrgAddress>>({
    initialValues: address ? address : ORG_ADDRESS_INITIAL_VALUES,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const title = useMemo(() => {
    return isAddMode ? 'Add New Address' : 'Edit Address';
  }, [isAddMode]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { name, value } = e.currentTarget;

      if (!name) {
        console.warn('No field name was specified');
        return;
      }

      form.setFieldValue(name, value);
    },
    [form.setFieldValue],
  );

  const handleSelectInputChange = React.useCallback(
    (name: keyof IOrgAddress, selected?: OnChangeValue<ISelectorValue, boolean> | null) => {
      const { value } = (selected as ISelectorValue) || {};
      return form.setFieldValue(name, value);
    },
    [form.setFieldValue],
  );

  const getFieldErrors = React.useCallback(
    (fieldName: keyof IOrgAddress) => {
      const touched: FormikTouched<IOrgAddress> = form.touched;
      const errors: FormikErrors<IOrgAddress> = form.errors;

      return touched[fieldName] ? (errors[fieldName] as string | string[]) : undefined;
    },
    [form.touched, form.errors],
  );

  const requiredFields = React.useMemo(
    () => getRequiredFields(validationSchema, getOrgAddressFormRequiredFieldsByCountryCode(form?.values[COUNTRY])),
    [validationSchema, form?.values[COUNTRY]],
  );

  const handleToggleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(
        STATUS,
        e.target.checked ? OrgAddressFilterTypesEnum.Active : OrgAddressFilterTypesEnum.NotActive,
      );
    },
    [form.setFieldValue],
  );

  return (
    <div className={styles.formWrapper}>
      {form.isSubmitting && <Loader isLoading={form.isSubmitting} />}
      <h1 className={styles.header}>{title}</h1>
      <form onSubmit={form.handleSubmit} className={styles.form}>
        <div className={styles.info}>
          <div className={styles.inputWrapper}>
            <Input
              field={form.getFieldProps(LABEL)}
              name={LABEL}
              placeholder="Label"
              helperText="Label"
              className={cn(styles.input, styles.fullWidth)}
              inputClassName={cn(styles.inputField, { [styles.errorBorder]: form.errors[LABEL] })}
              required={requiredFields[LABEL]}
              value={form.values[LABEL]}
              onChange={handleChange}
              error={getFieldErrors(LABEL)}
            />
            <Input
              field={form.getFieldProps(ADDRESS1)}
              name={ADDRESS1}
              placeholder="Address 1"
              helperText="Address 1"
              className={cn(styles.input, styles.fullWidth)}
              inputClassName={cn(styles.inputField, { [styles.errorBorder]: form.errors[ADDRESS1] })}
              required={requiredFields[ADDRESS1]}
              value={form.values[ADDRESS1]}
              onChange={handleChange}
              error={getFieldErrors(ADDRESS1)}
            />
            <div className={styles.inputWrapper}>
              <Input
                field={form.getFieldProps(ADDRESS2)}
                name={ADDRESS2}
                placeholder="Address 2"
                helperText="Address 2"
                className={styles.input}
                inputClassName={styles.inputField}
                required={requiredFields[ADDRESS2]}
                value={form.values[ADDRESS2]}
                onChange={handleChange}
                error={getFieldErrors(ADDRESS2)}
              />
              <Input
                field={form.getFieldProps(CITY)}
                name={CITY}
                placeholder="City"
                helperText="City"
                className={styles.input}
                inputClassName={cn(styles.inputField, { [styles.errorBorder]: form.errors[CITY] })}
                required={requiredFields[CITY]}
                value={form.values[CITY]}
                onChange={handleChange}
                error={getFieldErrors(CITY)}
              />
              <Input
                field={form.getFieldProps(STATE)}
                name={STATE}
                placeholder="State"
                helperText="State"
                className={styles.input}
                inputClassName={cn(styles.inputField, { [styles.errorBorder]: form.errors[STATE] })}
                required={requiredFields[STATE]}
                value={form.values[STATE]}
                onChange={handleChange}
                error={getFieldErrors(STATE)}
              />
              <Input
                field={form.getFieldProps(ZIP)}
                name={ZIP}
                placeholder="Zip"
                helperText="Zip"
                className={styles.input}
                inputClassName={cn(styles.inputField, { [styles.errorBorder]: form.errors[ZIP] })}
                required={requiredFields[ZIP]}
                value={form.values[ZIP]}
                onChange={handleChange}
                error={getFieldErrors(ZIP)}
              />
              <CountrySelector
                containerClassName={cn(styles.input, styles.selectContainer)}
                className={cn(styles.inputField, { [styles.errorBorder]: form.errors[COUNTRY] })}
                label="Country"
                name={COUNTRY}
                placeholder="Country"
                required={requiredFields[COUNTRY]}
                value={form.values[COUNTRY]}
                onChange={handleSelectInputChange}
                error={getFieldErrors(COUNTRY)}
              />
              <Input
                field={form.getFieldProps(PHONE)}
                name={PHONE}
                placeholder="Phone"
                helperText="Phone"
                className={styles.input}
                inputClassName={cn(styles.inputField, { [styles.errorBorder]: form.errors[PHONE] })}
                required={requiredFields[PHONE]}
                value={form.values[PHONE]}
                onChange={handleChange}
                error={getFieldErrors(PHONE)}
              />
              {/*<DepartmentSelect*/}
              {/*  containerClassName={cn(styles.input, styles.selectContainer)}*/}
              {/*  className={styles.inputField}*/}
              {/*  isMulti={true}*/}
              {/*  helperText="Departments"*/}
              {/*  name={DEPARTMENT_IDS}*/}
              {/*  onChange={handleChange}*/}
              {/*  value={form.values[DEPARTMENT_IDS]}*/}
              {/*  isClearable={true}*/}
              {/*  error={getFieldErrors(DEPARTMENT_IDS)}*/}
              {/*  closeMenuOnSelect*/}
              {/*/>*/}
              <div className={styles.statusWrapper}>
                <Input
                  field={form.getFieldProps(EMAIL)}
                  name={EMAIL}
                  placeholder="Email"
                  helperText="Email"
                  className={styles.input}
                  inputClassName={cn(styles.inputField, { [styles.errorBorder]: form.errors[EMAIL] })}
                  required={requiredFields[EMAIL]}
                  value={form.values[EMAIL]}
                  onChange={handleChange}
                  error={getFieldErrors(EMAIL)}
                />
                {!isAddMode && (
                  <div className={styles.toggleWrapper}>
                    <Toggle
                      checked={form.values[STATUS] === OrgAddressFilterTypesEnum.Active}
                      helperText="Status"
                      name={STATUS}
                      onChange={handleToggleChange}
                      className={cn(styles.input, styles.toggle)}
                      sliderClassName={styles.inputField}
                    />
                    <p className={styles.toggleTitle}>Active</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.controls}>
            <ActionButton title="Cancel" outlined className={styles.cancelButton} onClick={onClose} />
            <ActionButton type={SUBMIT_BUTTON} title="Save" disabled={form.isSubmitting} onClick={form.submitForm} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default OrgAddressForm;
