import cn from 'classnames';
import * as React from 'react';
import { v4 } from 'uuid';

import { ReactComponent as InventoryIcon } from '../../assets/images/icon-inventory.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/icon-warning-red.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning.svg';

import { IInventoryItem, InventoryTypesEnum } from '../../types/inventories';
import { overrideTooltipPosition } from '../../utils/helpers';
import { getItemCountWarning, isItemOutOfStock } from '../../utils/inventories';
import { HelpTooltip } from '../tooltips';

import styles from './ItemCount.module.scss';

interface IProps {
  className?: string;
  itemId?: string;
  count?: number;
  skuOptions?: IInventoryItem[];
  isPreorderAllowed?: boolean;
  type: InventoryTypesEnum;
}
/* A component that shows counts with warning messages
 * Showing counts logic:
 * - Digital items - not showing anything
 * - Physical:
 *    -- showing count and no warning;
 *    -- showing both count value and warning message
 *    -- warning message only if the count value is not defined (-1)
 * - MSKU:
 *    -- don't show a count value
 *    -- show a warning only in case at least one of its SKU options has any warning
 */
const CountIndicator = ({ className, count, itemId, skuOptions, isPreorderAllowed, type }: IProps) => {
  const uuid = React.useMemo(v4, []);

  const isDigital = React.useMemo(() => type === InventoryTypesEnum.Digital, [type]);
  const isMsku = React.useMemo(() => type === InventoryTypesEnum.MSKU, [type]);

  const warningMessage = React.useMemo(
    () => (isDigital ? undefined : getItemCountWarning({ count, sku_options: skuOptions })),
    [isDigital, count, skuOptions],
  );

  const isItemAvailable = React.useMemo(
    () => !isItemOutOfStock({ count, sku_options: skuOptions, preorder_allowed: isPreorderAllowed, type }),
    [count, skuOptions, isPreorderAllowed, type],
  );

  const warningIcon = React.useMemo(() => {
    if (!warningMessage) {
      return;
    }
    return isItemAvailable ? WarningIcon : ErrorIcon;
  }, [warningMessage, isItemAvailable]);

  const label = React.useMemo(() => {
    switch (true) {
      case count === -1:
        return undefined;
      default:
        return (count || 0).toFixed();
    }
  }, [count]);

  const hasLabel = React.useMemo(() => typeof label !== 'undefined', [label]);

  const showCount = React.useMemo(() => hasLabel && !isMsku, [hasLabel, isMsku]);

  return !isDigital && (warningMessage || showCount) ? (
    <div
      className={cn(className, styles.container, styles.indicator, {
        [styles.noCount]: !showCount,
      })}
    >
      {warningMessage && (
        <HelpTooltip
          key="count-hint"
          effect="float"
          className={styles.countHint}
          contentClassName={cn(styles.countHintContent, { [styles.error]: !isItemAvailable })}
          icon={warningIcon}
          id={`inventory-item-count-${itemId}-${uuid}`}
          overridePosition={overrideTooltipPosition}
        >
          {warningMessage}
        </HelpTooltip>
      )}
      {showCount && (
        <React.Fragment>
          <InventoryIcon className={styles.inventoryIcon} />
          <span className={styles.count}>{label}</span>
        </React.Fragment>
      )}
    </div>
  ) : null;
};

export default CountIndicator;
