import { MutableRefObject, useEffect, useState } from 'react';

interface IProps {
  ref: MutableRefObject<HTMLElement | undefined | null>;
  ignoreZero?: boolean;
}

interface ISize {
  height: number;
  width: number;
}

const useElementSize = ({ ref, ignoreZero }: IProps) => {
  const [size, setSize] = useState<ISize>({ width: 0, height: 0 });

  useEffect(() => {
    setSize((prevState) => ({
      ...prevState,
      ...(() => {
        if (ignoreZero) {
          return {
            width: ref.current?.offsetWidth ? ref.current?.offsetWidth : prevState.width,
            height: ref.current?.offsetHeight ? ref.current?.offsetHeight : prevState.height,
          };
        }
        return ref.current
          ? {
              width: ref.current?.offsetWidth,
              height: ref.current?.offsetHeight,
            }
          : {};
      })(),
    }));
  }, [ref.current?.offsetHeight, ref.current?.offsetWidth, ignoreZero]);

  return { ...size };
};

export default useElementSize;
