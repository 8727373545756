import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BoxList } from '../../components';
import { CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID } from '../../constants/bucket';
import { fetchFulfillmentCentersRequest } from '../../store/actions/fulfillmentCenters';
import { selectPreCreatedBoxId } from '../../store/selectors/bucket';
import { IFlowStepProps } from '../../types/bucket';
import { CustomSendContainer } from '../index';

const AddBoxContainer = (flowProps: IFlowStepProps) => {
  const dispatch = useDispatch();
  const selectedBoxId = useSelector(selectPreCreatedBoxId);

  React.useEffect(() => {
    dispatch(fetchFulfillmentCentersRequest({ hasOrgInventory: true }));
  }, [dispatch]);

  if (selectedBoxId === CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID || flowProps.isDirectSend) {
    return <CustomSendContainer {...flowProps} />;
  }

  return <BoxList {...flowProps} />;
};

export default AddBoxContainer;
