import cn from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';
import { useEffect, useLayoutEffect } from 'react';
import { FileRejection } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OnChangeValue } from 'react-select';

// import customizationItemsBodyHintImage from '../../../assets/images/hints/customization-items-body-hint.png';
// import customizationItemsTitleHintImage from '../../../assets/images/hints/customization-items-title-hint.png';
import emailAccentColorHintImage from '../../../assets/images/hints/email-accent-color-hint.png';
import emailCustomizationHintImage from '../../../assets/images/hints/email-customization-hint.png';
import emailMainColorHintImage from '../../../assets/images/hints/email-main-color-hint.png';
import mskuBodyHintImage from '../../../assets/images/hints/msku-body-hint.png';
import mskuTitleHintImage from '../../../assets/images/hints/msku-title-hint.png';
import spBodyHintImage from '../../../assets/images/hints/shipping-page-body-hint.png';
import spButtonColorHintImage from '../../../assets/images/hints/shipping-page-button-color-hint.png';
import spHeaderColorHintImage from '../../../assets/images/hints/shipping-page-header-color-hint.png';
import spPYGBodyHintImage from '../../../assets/images/hints/shipping-page-pyg-body-hint.png';
import spPYGTitleHintImage from '../../../assets/images/hints/shipping-page-pyg-title-hint.png';
import spTitleHintImage from '../../../assets/images/hints/shipping-page-title-hint.png';

import { ReactComponent as SVGIcon } from '../../../assets/images/icon-file-svg.svg';
import { IS_AI_SUGGEST_ENABLED } from '../../../constants/ai';
import { BLUEBIRD, NONE, SALESFORCE } from '../../../constants/integrations';
import {
  AI_GIFT_SUGGESTION_ENABLED,
  AUTOMATIONS_ENABLED,
  BILLING_PLAN_ID,
  BUTTON_COLOR,
  CITY,
  COBRANDING_LOGO_DISABLED_TOOLTIP_TEXT,
  COBRANDING_LOGO_HELP_TOOLTIP_TEXT,
  COUNTRY,
  CRM_CURRENTLY_SUPPORTS,
  CRM_SKIP_ALLOWED,
  // CUSTOMIZE_ITEMS_PAGE_BODY,
  // CUSTOMIZE_ITEMS_PAGE_TITLE,
  DEFAULT_EMAIL_CUSTOMIZATION_OPTIONS,
  DEFAULT_PORTAL_CUSTOMIZATION_OPTIONS,
  DEFAULT_SHIPPING_PAGE_CUSTOMIZATION_OPTIONS,
  DELAYED_SHIPPING_BASE_URL,
  DELAYED_SHIPPING_PAGE,
  DEPARTMENT_BUDGET_ENABLED,
  DIGITAL_BALANCE_LIMIT_ENABLED,
  DIGITAL_BALANCE_LIMIT_VALUE,
  DISTRIBUTORS_NOTIFICATION_EMAILS_LIST_HELP_TEXT,
  EMAIL,
  EMAIL_ACCENT_COLOR,
  EMAIL_CURRENTLY_SUPPORTS,
  EMAIL_CUSTOM_SENDER,
  EMAIL_LOGO_TYPE,
  EMAIL_LOGO_TYPES_OPTION_LABELS,
  EMAIL_MAIN_COLOR,
  EMAIL_USE_ORG_LOGO,
  EXTERNAL_ID,
  HEADER_COLOR,
  LOGO_URL,
  LOW_INVENTORY_NOTIFICATION_EMAILS,
  MSKU_PAGE_BODY,
  MSKU_PAGE_TITLE,
  NAME,
  ORDER_KEY,
  ORG_CUSTOMIZATION_OPTIONS,
  ORG_OPTIONS,
  PHONE,
  PICK_YOUR_GIFT_BODY,
  PICK_YOUR_GIFT_TITLE,
  PORTAL_ACCENT_COLOR,
  PORTAL_SIDEBAR_COLOR,
  PORTAL_SIDEBAR_TEXT_COLOR,
  PORTAL_USE_COBRANDING_LOGO,
  PORTAL_USE_ORG_LOGO,
  POSTAL_CODE,
  RECT_COBRANDING_LOGO_URL,
  RYBBON_CLIENT_ID,
  RYBBON_CLIENT_SECRET,
  RYBBON_CLIENT_SECRET_HAS_VALUE,
  RYBBON_HELP_TOOLTIP_TEXT,
  SALESFORCE_CAMPAIGN_ID,
  SALESFORCE_CLIENT_ID,
  SALESFORCE_CLIENT_SECRET,
  SALESFORCE_CLIENT_SECRET_HAS_VALUE,
  SHIPPING_ADDRESS,
  SHIPPING_PAGE_BODY,
  SHIPPING_PAGE_TITLE,
  SIGN_IN_METHOD_SUPPORTS,
  SOB_ENABLED,
  SQUARE_COBRANDING_LOGO_URL,
  SQUARE_LOGO_URL,
  STATE,
  STREET1,
  STREET2,
  VECTOR_IMAGE_URL,
  WOOCOMMERCE_API_KEY,
  WOOCOMMERCE_HOST_URL,
  WOOCOMMERCE_SERVICE_ACCOUNT_USER_ID,
} from '../../../constants/organizations';
import { endpoints, queryParams } from '../../../constants/routing';
import { DIGITAL_BALANCE_HELPER_TEXT, DIGITAL_BALANCE_LIMIT_DEFAULT_VALUE } from '../../../constants/shell';
import { useUpdateTheme } from '../../../contexts/ThemeProvider';
import useFetch from '../../../hooks/useFetch';
import useUploadFile from '../../../hooks/useUploadFile';
import useWindowSize from '../../../hooks/useWindowSize';
import { toggleOrganizationForm } from '../../../store/actions/shell';
import {
  selectCrmProviders,
  selectDelayedShippingBaseUrl,
  selectDelayedShippingTextCustomizationTags,
  selectEmailProviders,
  selectIsOrganizationFormExpanded,
} from '../../../store/selectors/shell';
import {
  IDropzoneResult,
  IMaintenanceFormOutputData,
  ITextCustomizationForm,
  MaintenanceFormStateEnum,
} from '../../../types/forms';
import {
  EmailLogoTypesEnum,
  IOrderKeyProps,
  IOrganizationCustomizationOptions,
  OrganizationResetCustomizationOptionsTypesEnum,
  SigninMethodsEnum,
  TOrganizationsDetails,
} from '../../../types/organizations';
import { IOrganizationRouteParams } from '../../../types/routing';
import {
  IApiError,
  ISelectorValue,
  ITheme,
  PositionStylesEnum,
  StatusTooltipTypeEnum,
  UISizeEnum,
} from '../../../types/shell';
import { IUploadResponse, UploadFileTypeEnum } from '../../../types/upload';
import { IFetchUsersResponse } from '../../../types/users';
import notification from '../../../utils/notification';
import { getQuery } from '../../../utils/request';
import { preparedDropZoneErrors } from '../../../utils/upload';
import { AccordionItem } from '../../Accordion';
import {
  Accordion,
  AutomatedConnectionsForm,
  AutomationSidebar,
  HelpTooltip,
  HtmlView,
  TabProvider,
  TemplateTagPicker,
} from '../../index';
import Loader from '../../Loader/Loader';
import { ITabProviderTab } from '../../TabProvider/TabProvider';
import { StatusTooltip } from '../../tooltips';
import { ActionButton, TextIconButton } from '../buttons';
import {
  Checkbox,
  ColorInput,
  ImageUploadInput,
  Input,
  NumericInput,
  RadioButtonGroup,
  SecretInput,
  Selector,
  TagInput,
  TinyEditor,
  Toggle,
} from '../inputs';
import { InputLabel } from '../labels';

import { CONNECTION_ID, NEW_ID } from '../../../constants/automations';
import { AutomatedConnectionsContainer } from '../../../containers';
import { TAutomationFormCandidateId } from '../../../types/automations';
import variables from '../../../_variables.scss';
import styles from './OrganizationForm.module.scss';

type TCustomizeFormFields = Pick<
  IOrganizationCustomizationOptions,
  | 'shipping_page_title'
  | 'shipping_page_body'
  | 'pick_your_gift_title'
  | 'pick_your_gift_body'
  | 'msku_page_title'
  | 'msku_page_body'
  | 'customize_items_page_title'
  | 'customize_items_page_body'
>;

interface IProps extends IMaintenanceFormOutputData {
  onChange: (name: string, value: string | number | boolean | string[] | null) => void;
  isDisabled?: boolean;
  form: FormikProps<TOrganizationsDetails>;
  requiredFields: Record<string, boolean>;
  toggleSidebar: (uid?: string) => void;
  orderKey: IOrderKeyProps;
  onResetCustomization: (property: OrganizationResetCustomizationOptionsTypesEnum) => void;
}

const OrganizationForm = ({
  onChange,
  isDisabled,
  form,
  mode,
  requiredFields,
  toggleSidebar,
  orderKey,
  onResetCustomization,
}: IProps) => {
  const dispatch = useDispatch();
  const { orgId } = useParams<IOrganizationRouteParams>();
  const { mobile } = useWindowSize();
  const { uploadFile } = useUploadFile();

  const [isPreviewMode, setIsPreviewMode] = React.useState(false);
  const [isDSTextCustomizationOn, setIsDSTextCustomizationOn] = React.useState(false);
  const [isDigitalBalanceToggleDisabled, setIsDigitalBalanceToggleDisabled] = React.useState(false);

  const [customActiveTab, setCustomActiveTab] = React.useState(0);

  const [candidate, setCandidate] = React.useState<TAutomationFormCandidateId | undefined>();

  const updateTheme = useUpdateTheme();

  const {
    make: fetchUserList,
    response: usersResponse,
    isLoading: isUsersLoading,
    error: userFetchError,
  } = useFetch<never, IFetchUsersResponse>({
    endpoint: endpoints.users,
    showToastOnError: true,
  });

  const fetchUsers = React.useCallback(
    () =>
      orgId
        ? fetchUserList({
            query: { [queryParams.orgId]: orgId },
          })
        : void 0,
    [fetchUserList, orgId],
  );

  const crmProviders = useSelector(selectCrmProviders);
  const emailProviders = useSelector(selectEmailProviders);
  const isAdvancedOptionsShown = useSelector(selectIsOrganizationFormExpanded);
  const availableDSTags = useSelector(selectDelayedShippingTextCustomizationTags);
  const delayedShippingBaseUrl = useSelector(selectDelayedShippingBaseUrl);

  const selectedCrm = React.useMemo(
    () => form.values?.[ORG_OPTIONS]?.[CRM_CURRENTLY_SUPPORTS],
    [form.values[ORG_OPTIONS]?.[CRM_CURRENTLY_SUPPORTS]],
  );

  const isCrmSkipAllowed = React.useMemo(
    () => form.values?.[ORG_OPTIONS]?.[CRM_SKIP_ALLOWED],
    [form.values[ORG_OPTIONS]?.[CRM_SKIP_ALLOWED]],
  );

  const isSelectedNoneCRM = React.useMemo(() => {
    return selectedCrm === NONE;
  }, [selectedCrm]);

  const isReadOnly = React.useMemo(() => mode === MaintenanceFormStateEnum.Reading, [mode]);

  const isOrderKeyValid = React.useMemo(() => !Boolean(orderKey.error), [orderKey.error]);

  const usersOptions = React.useMemo(
    () =>
      usersResponse && usersResponse.users?.length
        ? usersResponse.users?.map(({ user }) => {
            return {
              value: user.uid,
              label: `${user.first_name} ${user.last_name}`,
            };
          })
        : null,
    [usersResponse],
  );

  const renderOrderKeyStatusIcon = React.useMemo(() => {
    if (orderKey.validating) {
      return <Loader isLoading size={UISizeEnum.Tiny} className={styles.loader} />;
    }

    const { error: syncFieldError, touched, value } = form.getFieldMeta(ORDER_KEY);

    const justChecked = orderKey.lastChecked === value; // Just checked current value

    const hasAsyncWarning = justChecked && !!orderKey.warning;
    const hasAsyncError = justChecked && !isOrderKeyValid; // Just checked current value and it has an error
    const hasSyncError = touched && !!syncFieldError; // Field touched and has errors from Formik

    // We should show the status if
    // - field is touched and has Formik errors
    // - field just checked and has errors from endpoint
    // - field checked and doesn't have async errors
    const showStatus =
      hasSyncError || hasAsyncError || hasAsyncWarning || (justChecked && !(hasAsyncError && hasAsyncWarning));

    // Error tooltip is Formik error by default (if any)
    let tooltip: React.ReactNode | string = syncFieldError;

    // If there's error from Formik OR async error we should show all of them within line-break
    if (tooltip || hasAsyncError || hasAsyncWarning) {
      tooltip = (
        <React.Fragment>
          {tooltip}
          {tooltip && <br />}
          {Array.isArray(orderKey.error)
            ? orderKey.error?.map((message: string) => (
                <React.Fragment key={message}>
                  {message}
                  <br />
                </React.Fragment>
              ))
            : orderKey.error}
          {Array.isArray(orderKey.warning)
            ? orderKey.warning?.map((message: string) => (
                <React.Fragment key={message}>
                  {message}
                  <br />
                </React.Fragment>
              ))
            : orderKey.warning}
        </React.Fragment>
      );
    }

    const tooltipStatus = (() => {
      switch (true) {
        case hasSyncError || hasAsyncError:
          return StatusTooltipTypeEnum.Error;
        case hasAsyncWarning:
          return StatusTooltipTypeEnum.Warning;
        case !(hasSyncError || hasAsyncError):
          return StatusTooltipTypeEnum.Success;
        default:
          return;
      }
    })();

    return showStatus ? (
      <StatusTooltip
        id="order-key-status"
        className={styles.statusIcon}
        type="light"
        effect="solid"
        offset={{ right: 10 }}
        status={tooltipStatus}
        tooltipClassName={styles.tooltip}
        borderColor={variables.graySuit}
        border
        message={tooltip}
      />
    ) : null;
  }, [isOrderKeyValid, orderKey, form.values?.[ORDER_KEY], form.getFieldMeta]);

  const orderKeyStatusClassName = React.useMemo(() => {
    const isCurrentOrderKeyLastChecked = orderKey.lastChecked === form.values?.[ORDER_KEY];
    const hasAsyncError = !orderKey.validating && !!orderKey.error;
    const hasAsyncWarning = !orderKey.validating && !!orderKey.warning;
    const hasSyncError = form.touched?.[ORDER_KEY] && !!form.errors?.[ORDER_KEY];

    switch (true) {
      case isCurrentOrderKeyLastChecked && !(hasAsyncError || hasAsyncWarning):
        return styles.valid;
      case hasAsyncWarning && isCurrentOrderKeyLastChecked:
        return styles.warning;
      case hasAsyncError || hasSyncError:
        return styles.invalid;
      default:
        return;
    }
  }, [orderKey, form.touched?.[ORDER_KEY], form.errors?.[ORDER_KEY], form.values?.[ORDER_KEY]]);

  const isSalesforceCrmSelected = React.useMemo(() => selectedCrm === SALESFORCE, [selectedCrm]);

  const isCoBrandCheckboxDisabled = React.useMemo(() => {
    return !form.values?.[RECT_COBRANDING_LOGO_URL];
  }, [form.values?.[RECT_COBRANDING_LOGO_URL]]);

  const isDigitalBalanceLimitEnabled = React.useMemo(() => {
    return !!form.values?.[ORG_OPTIONS]?.[DIGITAL_BALANCE_LIMIT_ENABLED];
  }, [form.values?.[ORG_OPTIONS]?.[DIGITAL_BALANCE_LIMIT_ENABLED]]);

  const canResetEmailCustomization = React.useMemo(
    () =>
      !isReadOnly &&
      (form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[EMAIL_ACCENT_COLOR] !==
        DEFAULT_EMAIL_CUSTOMIZATION_OPTIONS[ORG_CUSTOMIZATION_OPTIONS][EMAIL_ACCENT_COLOR] ||
        form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[EMAIL_MAIN_COLOR] !==
          DEFAULT_EMAIL_CUSTOMIZATION_OPTIONS[ORG_CUSTOMIZATION_OPTIONS][EMAIL_MAIN_COLOR] ||
        form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[EMAIL_LOGO_TYPE] !==
          DEFAULT_EMAIL_CUSTOMIZATION_OPTIONS[ORG_CUSTOMIZATION_OPTIONS][EMAIL_LOGO_TYPE] ||
        form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[EMAIL_USE_ORG_LOGO] !==
          DEFAULT_EMAIL_CUSTOMIZATION_OPTIONS[ORG_CUSTOMIZATION_OPTIONS][EMAIL_USE_ORG_LOGO]),
    [form.values?.[ORG_CUSTOMIZATION_OPTIONS], isReadOnly],
  );

  const canResetShippingPageCustomization = React.useMemo(() => {
    const DEFAULTS = DEFAULT_SHIPPING_PAGE_CUSTOMIZATION_OPTIONS(delayedShippingBaseUrl || '');

    return (
      !isReadOnly &&
      (form.values?.[DELAYED_SHIPPING_PAGE]?.[BUTTON_COLOR] !== DEFAULTS[DELAYED_SHIPPING_PAGE][BUTTON_COLOR] ||
        form.values?.[DELAYED_SHIPPING_PAGE]?.[HEADER_COLOR] !== DEFAULTS[DELAYED_SHIPPING_PAGE][HEADER_COLOR] ||
        form.values?.[DELAYED_SHIPPING_BASE_URL] !== DEFAULTS[DELAYED_SHIPPING_BASE_URL])
    );
  }, [
    form.values?.[DELAYED_SHIPPING_PAGE],
    form.values?.[DELAYED_SHIPPING_BASE_URL],
    delayedShippingBaseUrl,
    isReadOnly,
  ]);

  const canResetPortalCustomization = React.useMemo(
    () =>
      !isReadOnly &&
      (form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_ACCENT_COLOR] !==
        DEFAULT_PORTAL_CUSTOMIZATION_OPTIONS[ORG_CUSTOMIZATION_OPTIONS][PORTAL_ACCENT_COLOR] ||
        form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_SIDEBAR_COLOR] !==
          DEFAULT_PORTAL_CUSTOMIZATION_OPTIONS[ORG_CUSTOMIZATION_OPTIONS][PORTAL_SIDEBAR_COLOR] ||
        form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_SIDEBAR_TEXT_COLOR] !==
          DEFAULT_PORTAL_CUSTOMIZATION_OPTIONS[ORG_CUSTOMIZATION_OPTIONS][PORTAL_SIDEBAR_TEXT_COLOR] ||
        form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_USE_ORG_LOGO] !==
          DEFAULT_PORTAL_CUSTOMIZATION_OPTIONS[ORG_CUSTOMIZATION_OPTIONS][PORTAL_USE_ORG_LOGO] ||
        form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_USE_COBRANDING_LOGO] !==
          DEFAULT_PORTAL_CUSTOMIZATION_OPTIONS[ORG_CUSTOMIZATION_OPTIONS][PORTAL_USE_COBRANDING_LOGO]),
    [form.values?.[ORG_CUSTOMIZATION_OPTIONS], isReadOnly],
  );

  const getFieldErrors = React.useCallback(
    (fieldName: string) => {
      const { touched, error } = form.getFieldMeta(fieldName);
      return touched ? error : undefined;
    },
    [form],
  );

  const toggleAdvancedOptions = React.useCallback(
    (id: string | boolean) => {
      dispatch(toggleOrganizationForm(!!id));
    },
    [dispatch],
  );

  const handleOnChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { name, value } = e.currentTarget;
      if (!name) {
        console.warn('No field name was specified');
        return;
      }
      if (typeof onChange === 'function') {
        onChange(name, value);
      }
    },
    [onChange],
  );

  const onUploadStart = React.useCallback(
    ({ files, resolve, reject }: IDropzoneResult) => {
      const type =
        mode === MaintenanceFormStateEnum.Adding ? UploadFileTypeEnum.OrgLogoTemp : UploadFileTypeEnum.OrgLogo;

      if (type) {
        uploadFile(files[0], type, {
          ...getQuery(orgId, queryParams.orderId),
        })
          .then(resolve)
          .catch(reject);
      }
    },
    [mode, orgId, uploadFile],
  );

  const onUploadSuccess = React.useCallback(
    (name: string) => (files: File[], response: IUploadResponse) => {
      const { public_url: url } = response;
      onChange(name, url);
    },
    [onChange],
  );

  const onUploadFailure = React.useCallback((errors: IApiError | IApiError[]) => {
    const showError = ({ message }: any) => notification.error(message, { content: message });

    Array.isArray(errors) ? errors.map(showError) : showError(errors);
  }, []);

  const onDropReject = React.useCallback(
    (fileRejections: FileRejection[]) => {
      fileRejections.forEach((fileReject) => {
        const { errors } = fileReject;
        onUploadFailure(preparedDropZoneErrors(errors));
      });
    },
    [onUploadFailure],
  );

  const formatSelectValue = React.useCallback(
    (options: ISelectorValue[] | undefined | null, name: string) => {
      const fieldValue = form.getFieldProps(name).value;
      const option = options?.find((o) => o.value === fieldValue) || {};

      return fieldValue ? (option as OnChangeValue<ISelectorValue, boolean>) : undefined;
    },
    [form],
  );

  const handleSelectInputChange = React.useCallback(
    (name: any, selected?: OnChangeValue<ISelectorValue, boolean>) => {
      const { value } = (selected as ISelectorValue) || {};
      return handleOnChange({ currentTarget: { name, value } } as unknown as
        | React.ChangeEvent<HTMLInputElement>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>);
    },
    [handleOnChange],
  );

  const handleToggleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked: value } = e.currentTarget;
      if (!name) {
        console.warn('No field name was specified');
        return;
      }

      if (typeof onChange === 'function') {
        onChange(name, value);
      }
    },
    [onChange],
  );

  const handleDigitalBalanceLimitToggleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked: value } = e.currentTarget;

      if (onChange) {
        onChange(name, value);

        if (value) {
          onChange(
            `${ORG_OPTIONS}.${DIGITAL_BALANCE_LIMIT_VALUE}`,
            form.values?.[ORG_OPTIONS]?.[DIGITAL_BALANCE_LIMIT_VALUE] || DIGITAL_BALANCE_LIMIT_DEFAULT_VALUE,
          );
        } else {
          onChange(`${ORG_OPTIONS}.${DIGITAL_BALANCE_LIMIT_VALUE}`, null);
        }
      }
    },
    [onChange, form.values?.[ORG_OPTIONS]],
  );

  const renderUploadPlaceholder = React.useCallback(
    (shape: string = '') =>
      mobile ? (
        <span className={styles.uploadMessage}>Click to upload {shape}</span>
      ) : (
        <span className={styles.uploadMessage}>
          Click or drag to
          <br />
          upload {shape}
        </span>
      ),
    [mobile],
  );

  const handlePortalLogoChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.currentTarget;
      if (name === `${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_USE_COBRANDING_LOGO}` && checked) {
        onChange(`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_USE_ORG_LOGO}`, false);
      }

      if (name === `${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_USE_ORG_LOGO}` && checked) {
        onChange(`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_USE_COBRANDING_LOGO}`, false);
      }

      onChange(name, checked);
    },
    [onChange],
  );

  const handleAddAutomationClick = React.useCallback(
    (key: keyof TAutomationFormCandidateId) => () => {
      setCandidate({ [key]: NEW_ID } as TAutomationFormCandidateId);
    },
    [],
  );

  const handleEditAutomationClick = React.useCallback(
    (key: keyof TAutomationFormCandidateId) =>
      ({ uid }: { uid: string }) => {
        setCandidate({ [key]: uid } as TAutomationFormCandidateId);
      },
    [],
  );

  const handleCloseAutomationsSidebar = React.useCallback(() => {
    setCandidate(undefined);
  }, []);

  const orgHasAutomationsEnabled = React.useMemo(
    () => !!form.values?.[ORG_OPTIONS]?.[AUTOMATIONS_ENABLED],
    [form.values],
  );

  const customizeForms: ITextCustomizationForm[] = React.useMemo(
    () => [
      {
        id: 'shipping-page',
        tabLabel: 'Shipping Page',
        title_name: SHIPPING_PAGE_TITLE,
        body_name: SHIPPING_PAGE_BODY,
        titleLabel: 'Shipping Page Title',
        bodyLabel: 'Shipping Page Body',
        titleHintImage: spTitleHintImage,
        bodyHintImage: spBodyHintImage,
      },
      {
        id: 'pyg-page',
        tabLabel: 'Pick Your Gift Page',
        title_name: PICK_YOUR_GIFT_TITLE,
        body_name: PICK_YOUR_GIFT_BODY,
        titleLabel: 'Pick Your Gift Title',
        bodyLabel: 'Pick Your Gift Body',
        titleHintImage: spPYGTitleHintImage,
        bodyHintImage: spPYGBodyHintImage,
      },
      {
        id: 'msku-page',
        tabLabel: 'MSKU Page',
        title_name: MSKU_PAGE_TITLE,
        body_name: MSKU_PAGE_BODY,
        titleLabel: 'MSKU Step Title',
        bodyLabel: 'MSKU Step Body',
        titleHintImage: mskuTitleHintImage,
        bodyHintImage: mskuBodyHintImage,
      },
      // {
      //   id: 'customizing-items-page',
      //   tabLabel: 'Customizing Items Page',
      //   title_name: CUSTOMIZE_ITEMS_PAGE_TITLE,
      //   body_name: CUSTOMIZE_ITEMS_PAGE_BODY,
      //   titleLabel: 'Item Customization Step Title',
      //   bodyLabel: 'Item Customization Step Body',
      //   titleHintImage: customizationItemsTitleHintImage,
      //   bodyHintImage: customizationItemsBodyHintImage,
      // },
    ],
    [],
  );

  const customizationItems: ITabProviderTab[] = React.useMemo(
    () =>
      customizeForms.map((item) => ({
        label: item.tabLabel,
        tabId: item.id,
        hidden: item.shouldHideTitle && item.shouldHideBody,
        renderer: (
          <>
            <div className={styles.info}>
              <InputLabel
                value={
                  <React.Fragment>
                    {item.titleLabel}
                    <HelpTooltip
                      id={`${item.id}-title-hint`}
                      className={styles.infoIcon}
                      place={mobile ? PositionStylesEnum.Right : PositionStylesEnum.Top}
                      contentClassName={styles.tooltipImage}
                    >
                      <img src={item.titleHintImage} alt="" />
                    </HelpTooltip>
                  </React.Fragment>
                }
              />
              <Input
                className={styles.inputContainer}
                name={`${ORG_CUSTOMIZATION_OPTIONS}.${item.title_name}`}
                field={form.getFieldProps(`${ORG_CUSTOMIZATION_OPTIONS}.${item.title_name}`)}
                value={form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[item.title_name as keyof TCustomizeFormFields] ?? ''}
                onChange={handleOnChange}
                disabled={isDisabled}
                required={requiredFields[`${ORG_CUSTOMIZATION_OPTIONS}.${item.title_name}`]}
                error={getFieldErrors(`${ORG_CUSTOMIZATION_OPTIONS}.${item.title_name}`)}
                showErrors
                readOnly={isReadOnly}
              />
            </div>
            <div className={styles.info}>
              <InputLabel
                value={
                  <React.Fragment>
                    {item.bodyLabel}
                    <HelpTooltip
                      id={`${item.id}-body-hint`}
                      className={styles.infoIcon}
                      place={mobile ? PositionStylesEnum.Right : PositionStylesEnum.Top}
                      contentClassName={styles.tooltipImage}
                    >
                      <img src={item.bodyHintImage} alt="" />
                    </HelpTooltip>
                  </React.Fragment>
                }
              />
              {isReadOnly ? (
                <HtmlView
                  className={cn(styles.inputContainer, styles.htmlEditor)}
                  html={form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[item.body_name as keyof TCustomizeFormFields] ?? ''}
                />
              ) : (
                <TinyEditor
                  field={form.getFieldProps(`${ORG_CUSTOMIZATION_OPTIONS}.${item.body_name}`)}
                  changeCallback={handleOnChange}
                  className={cn(styles.inputContainer, styles.htmlEditor)}
                  onBlur={form.handleBlur}
                  showHint={false}
                  disabled={isDisabled}
                  showFontColorPicker={false}
                />
              )}
            </div>
          </>
        ),
      })),
    [customizeForms, form, getFieldErrors, handleOnChange, isDisabled, isReadOnly, mobile, requiredFields],
  );

  useLayoutEffect(() => {
    updateTheme();
  }, [updateTheme]);

  useEffect(() => {
    if (!form.values?.[RECT_COBRANDING_LOGO_URL]) {
      onChange(`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_USE_COBRANDING_LOGO}`, false);

      if (form.values?.[SQUARE_COBRANDING_LOGO_URL]) {
        onChange(SQUARE_COBRANDING_LOGO_URL, '');
      }
    }
  }, [form.values?.[RECT_COBRANDING_LOGO_URL], form.values?.[SQUARE_COBRANDING_LOGO_URL], onChange]);

  useEffect(() => {
    if (isSelectedNoneCRM && isCrmSkipAllowed) {
      onChange(`${ORG_OPTIONS}.${CRM_SKIP_ALLOWED}`, false);
    }
  }, [isCrmSkipAllowed, isSelectedNoneCRM, onChange]);

  useEffect(() => {
    toggleAdvancedOptions(false);
  }, [toggleAdvancedOptions]);

  useEffect(() => {
    if (!form.values?.[RECT_COBRANDING_LOGO_URL]) {
      onChange(`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_USE_COBRANDING_LOGO}`, false);

      if (form.values?.[SQUARE_COBRANDING_LOGO_URL]) {
        onChange(SQUARE_COBRANDING_LOGO_URL, '');
      }
    }
  }, [form.values?.[RECT_COBRANDING_LOGO_URL], form.values?.[SQUARE_COBRANDING_LOGO_URL], onChange]);

  useEffect(() => {
    if (form.values?.[WOOCOMMERCE_HOST_URL] && !usersResponse && !userFetchError && !isUsersLoading) {
      fetchUsers();
    }
  }, [usersResponse, userFetchError, isUsersLoading, fetchUsers, form.values?.[WOOCOMMERCE_HOST_URL]]);

  useEffect(() => {
    const isRybbonCredentialsEmpty =
      !form.values?.[RYBBON_CLIENT_ID] ||
      !(form.values?.[RYBBON_CLIENT_SECRET_HAS_VALUE] || form.values?.[RYBBON_CLIENT_SECRET]);
    if (isRybbonCredentialsEmpty) {
      setIsDigitalBalanceToggleDisabled(true);
      onChange(`${ORG_OPTIONS}.${DIGITAL_BALANCE_LIMIT_ENABLED}`, false);
    } else {
      setIsDigitalBalanceToggleDisabled(false);
    }
  }, [
    onChange,
    form.values?.[RYBBON_CLIENT_ID],
    form.values?.[RYBBON_CLIENT_SECRET],
    form.values?.[RYBBON_CLIENT_SECRET_HAS_VALUE],
  ]);

  useEffect(() => {
    const currentEmailType = form.values?.[ORG_OPTIONS]?.[EMAIL_CURRENTLY_SUPPORTS];
    if (currentEmailType !== BLUEBIRD) {
      onChange(`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_CUSTOM_SENDER}`, '');
    }
  }, [form.values, onChange]);

  useEffect(() => {
    const previewTheme: ITheme = {
      portal_sidebar_color: form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_SIDEBAR_COLOR],
      portal_sidebar_text_color: form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_SIDEBAR_TEXT_COLOR],
      portal_accent_color: form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_ACCENT_COLOR],
      ...(form.values.org_customization_options?.portal_use_org_logo
        ? {
            org_rect_logo: form.values?.[DELAYED_SHIPPING_PAGE]?.[LOGO_URL],
            org_square_logo: form.values?.[SQUARE_LOGO_URL] || form.values?.[DELAYED_SHIPPING_PAGE]?.[LOGO_URL],
          }
        : {}),
      ...(form.values.org_customization_options?.portal_use_cobranding_logo
        ? {
            org_rect_logo: form.values?.[RECT_COBRANDING_LOGO_URL],
            org_square_logo: form.values?.[SQUARE_COBRANDING_LOGO_URL] || form.values?.[RECT_COBRANDING_LOGO_URL],
          }
        : {}),
    };

    if (isPreviewMode) {
      updateTheme(previewTheme);
    } else {
      updateTheme();
    }
  }, [
    form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_USE_ORG_LOGO],
    form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_USE_COBRANDING_LOGO],
    form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_SIDEBAR_COLOR],
    form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_SIDEBAR_TEXT_COLOR],
    form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_ACCENT_COLOR],
    form.values?.[DELAYED_SHIPPING_PAGE]?.[LOGO_URL],
    form.values?.[SQUARE_LOGO_URL],
    form.values?.[SQUARE_COBRANDING_LOGO_URL],
    form.values?.[RECT_COBRANDING_LOGO_URL],
    isPreviewMode,
    updateTheme,
  ]);

  return (
    <form onSubmit={form.handleSubmit} className={styles.form}>
      <section className={cn(styles.logos, styles.info)}>
        <ImageUploadInput
          className={cn(styles.upload, styles.rect)}
          onUpload={onUploadStart}
          onSuccess={onUploadSuccess(`${DELAYED_SHIPPING_PAGE}.${LOGO_URL}`)}
          onFailure={onUploadFailure}
          onDropReject={onDropReject}
          onBlur={() => form.getFieldHelpers(`${DELAYED_SHIPPING_PAGE}.${LOGO_URL}`).setTouched(true, true)}
          message={renderUploadPlaceholder('rectangular logo')}
          value={form.values?.[DELAYED_SHIPPING_PAGE]?.[LOGO_URL]}
          onImageDelete={() => onChange(`${DELAYED_SHIPPING_PAGE}.${LOGO_URL}`, '')}
          readOnly={isReadOnly}
          error={getFieldErrors(`${DELAYED_SHIPPING_PAGE}.${LOGO_URL}`)}
          showErrors
        />
        <ImageUploadInput
          className={cn(styles.upload)}
          onUpload={onUploadStart}
          onSuccess={onUploadSuccess(SQUARE_LOGO_URL)}
          onFailure={onUploadFailure}
          onDropReject={onDropReject}
          message={renderUploadPlaceholder('square logo')}
          value={form.values?.[SQUARE_LOGO_URL] || ''}
          onImageDelete={() => onChange(SQUARE_LOGO_URL, '')}
          readOnly={isReadOnly}
        />
        <ImageUploadInput
          className={cn(styles.upload)}
          onUpload={onUploadStart}
          onSuccess={onUploadSuccess(VECTOR_IMAGE_URL)}
          onFailure={onUploadFailure}
          onDropReject={onDropReject}
          message={renderUploadPlaceholder('vector image')}
          value={form.values?.[VECTOR_IMAGE_URL] || ''}
          onImageDelete={() => onChange(VECTOR_IMAGE_URL, '')}
          placeholderIcon={<SVGIcon />}
          readOnly={isReadOnly}
          accept=".svg"
        />
        <ImageUploadInput
          className={cn(styles.upload, styles.rect)}
          onUpload={onUploadStart}
          onSuccess={onUploadSuccess(RECT_COBRANDING_LOGO_URL)}
          onFailure={onUploadFailure}
          onDropReject={onDropReject}
          message={renderUploadPlaceholder('rectangular co-branding logo')}
          value={form.values?.[RECT_COBRANDING_LOGO_URL] || ''}
          onImageDelete={() => onChange(RECT_COBRANDING_LOGO_URL, '')}
          readOnly={isReadOnly}
        />
        <ImageUploadInput
          className={cn(styles.upload)}
          onUpload={onUploadStart}
          onSuccess={onUploadSuccess(SQUARE_COBRANDING_LOGO_URL)}
          onFailure={onUploadFailure}
          onDropReject={onDropReject}
          message={renderUploadPlaceholder('co-branding logo')}
          value={form.values?.[SQUARE_COBRANDING_LOGO_URL] || ''}
          onImageDelete={() => onChange(SQUARE_COBRANDING_LOGO_URL, '')}
          readOnly={isReadOnly}
          disabled={!form.values?.[RECT_COBRANDING_LOGO_URL]}
        />
      </section>
      <section className={styles.info}>
        <Input
          className={styles.inputContainer}
          field={form.getFieldProps(NAME)}
          name={NAME}
          placeholder="Organization Name"
          helperText="Organization Name"
          disabled={isDisabled}
          required={requiredFields[NAME]}
          value={form.values?.[NAME]}
          onChange={handleOnChange}
          error={getFieldErrors(NAME)}
          showErrors
          readOnly={isReadOnly}
        />
        <Input
          className={styles.inputContainer}
          field={form.getFieldProps(`${SHIPPING_ADDRESS}.${NAME}`)}
          name={`${SHIPPING_ADDRESS}.${NAME}`}
          placeholder="Contact Partner"
          helperText="Contact Partner"
          disabled={isDisabled}
          required={requiredFields[`${SHIPPING_ADDRESS}.${NAME}`]}
          value={form.values?.[SHIPPING_ADDRESS]?.[NAME]}
          onChange={handleOnChange}
          error={getFieldErrors(`${SHIPPING_ADDRESS}.${NAME}`)}
          readOnly={isReadOnly}
          showErrors
        />
        <Input
          className={styles.inputContainer}
          field={form.getFieldProps(`${SHIPPING_ADDRESS}.${PHONE}`)}
          name={`${SHIPPING_ADDRESS}.${PHONE}`}
          placeholder="Personal Phone Number"
          helperText="Personal Phone Number"
          disabled={isDisabled}
          required={requiredFields[`${SHIPPING_ADDRESS}.${PHONE}`]}
          value={form.values?.[SHIPPING_ADDRESS]?.[PHONE]}
          onChange={handleOnChange}
          error={getFieldErrors(`${SHIPPING_ADDRESS}.${PHONE}`)}
          showErrors
          readOnly={isReadOnly}
        />
        <Input
          className={styles.inputContainer}
          field={form.getFieldProps(`${SHIPPING_ADDRESS}.${EMAIL}`)}
          name={`${SHIPPING_ADDRESS}.${EMAIL}`}
          placeholder="Personal Email"
          helperText="Personal Email"
          disabled={isDisabled}
          required={requiredFields[`${SHIPPING_ADDRESS}.${EMAIL}`]}
          value={form.values?.[SHIPPING_ADDRESS]?.[EMAIL]}
          onChange={handleOnChange}
          error={getFieldErrors(`${SHIPPING_ADDRESS}.${EMAIL}`)}
          showErrors
          readOnly={isReadOnly}
        />
        <Input
          className={styles.inputContainer}
          field={form.getFieldProps(`${SHIPPING_ADDRESS}.${COUNTRY}`)}
          name={`${SHIPPING_ADDRESS}.${COUNTRY}`}
          placeholder="Country"
          helperText="Country"
          disabled={isDisabled}
          required={requiredFields[`${SHIPPING_ADDRESS}.${COUNTRY}`]}
          value={form.values?.[SHIPPING_ADDRESS]?.[COUNTRY]}
          onChange={handleOnChange}
          error={getFieldErrors(`${SHIPPING_ADDRESS}.${COUNTRY}`)}
          showErrors
          readOnly={isReadOnly}
        />
        <Input
          className={styles.inputContainer}
          field={form.getFieldProps(`${SHIPPING_ADDRESS}.${STATE}`)}
          name={`${SHIPPING_ADDRESS}.${STATE}`}
          placeholder="State"
          helperText="State"
          disabled={isDisabled}
          required={requiredFields[`${SHIPPING_ADDRESS}.${STATE}`]}
          value={form.values?.[SHIPPING_ADDRESS]?.[STATE]}
          onChange={handleOnChange}
          error={getFieldErrors(`${SHIPPING_ADDRESS}.${STATE}`)}
          showErrors
          readOnly={isReadOnly}
        />
        <Input
          className={styles.inputContainer}
          field={form.getFieldProps(`${SHIPPING_ADDRESS}.${CITY}`)}
          placeholder="City"
          helperText="City"
          name={`${SHIPPING_ADDRESS}.${CITY}`}
          disabled={isDisabled}
          required={requiredFields[`${SHIPPING_ADDRESS}.${CITY}`]}
          value={form.values?.[SHIPPING_ADDRESS]?.[CITY]}
          onChange={handleOnChange}
          error={getFieldErrors(`${SHIPPING_ADDRESS}.${CITY}`)}
          showErrors
          readOnly={isReadOnly}
        />
        <Input
          className={styles.inputContainer}
          field={form.getFieldProps(`${SHIPPING_ADDRESS}.${STREET1}`)}
          name={`${SHIPPING_ADDRESS}.${STREET1}`}
          placeholder="Address 1"
          helperText="Address 1"
          disabled={isDisabled}
          required={requiredFields[`${SHIPPING_ADDRESS}.${STREET1}`]}
          value={form.values?.[SHIPPING_ADDRESS]?.[STREET1]}
          onChange={handleOnChange}
          error={getFieldErrors(`${SHIPPING_ADDRESS}.${STREET1}`)}
          showErrors
          readOnly={isReadOnly}
        />
        <Input
          className={styles.inputContainer}
          field={form.getFieldProps(`${SHIPPING_ADDRESS}.${STREET2}`)}
          name={`${SHIPPING_ADDRESS}.${STREET2}`}
          placeholder="Address 2"
          helperText="Address 2"
          disabled={isDisabled}
          required={requiredFields[`${SHIPPING_ADDRESS}.${STREET2}`]}
          value={form.values?.[SHIPPING_ADDRESS]?.[STREET2]}
          onChange={handleOnChange}
          error={getFieldErrors(`${SHIPPING_ADDRESS}.${STREET2}`)}
          showErrors
          readOnly={isReadOnly}
        />
        <Input
          className={cn(styles.inputContainer, styles.postalCode)}
          field={form.getFieldProps(`${SHIPPING_ADDRESS}.${POSTAL_CODE}`)}
          name={`${SHIPPING_ADDRESS}.${POSTAL_CODE}`}
          placeholder="Code"
          helperText="Postal Code"
          disabled={isDisabled}
          required={requiredFields[`${SHIPPING_ADDRESS}.${POSTAL_CODE}`]}
          value={form.values?.[SHIPPING_ADDRESS]?.[POSTAL_CODE]}
          onChange={handleOnChange}
          error={getFieldErrors(`${SHIPPING_ADDRESS}.${POSTAL_CODE}`)}
          showErrors
          readOnly={isReadOnly}
        />
      </section>
      <section className={styles.togglesSection}>
        <h2 className={styles.heading}>Additional Options</h2>
        <div className={styles.toggles}>
          <Toggle
            field={form.getFieldProps(`${ORG_OPTIONS}.${SOB_ENABLED}`)}
            name={`${ORG_OPTIONS}.${SOB_ENABLED}`}
            className={cn(styles.input, styles.toggle)}
            disabled={isDisabled || isReadOnly}
            hint={
              <React.Fragment>
                This option allows the organization users
                <br />
                send a gift on someone’s behalf.
              </React.Fragment>
            }
            helperText="Send on behalf"
            onChange={handleToggleChange}
            checked={!!form.values?.[ORG_OPTIONS]?.[SOB_ENABLED]}
          />
          <Toggle
            field={form.getFieldProps(`${ORG_OPTIONS}.${DEPARTMENT_BUDGET_ENABLED}`)}
            name={`${ORG_OPTIONS}.${DEPARTMENT_BUDGET_ENABLED}`}
            className={cn(styles.input, styles.toggle)}
            disabled={isDisabled || isReadOnly}
            hint={
              <React.Fragment>
                This option turns on
                <br />
                department budget usage.
              </React.Fragment>
            }
            helperText="Department budget"
            onChange={handleToggleChange}
            checked={!!form.values?.[ORG_OPTIONS]?.[DEPARTMENT_BUDGET_ENABLED]}
          />
          <Toggle
            field={form.getFieldProps(`${ORG_OPTIONS}.${[CRM_SKIP_ALLOWED]}`)}
            name={`${ORG_OPTIONS}.${[CRM_SKIP_ALLOWED]}`}
            className={cn(styles.input, styles.toggle)}
            disabled={isDisabled || isReadOnly || isSelectedNoneCRM}
            hint={
              <React.Fragment>
                This option allows user to skip CRM integration
                <br />
                and only manually type in the shipment information.
              </React.Fragment>
            }
            helperText="Skip CRM"
            onChange={handleToggleChange}
            checked={!!form.values?.[ORG_OPTIONS]?.[CRM_SKIP_ALLOWED]}
          />
          {IS_AI_SUGGEST_ENABLED && (
            <Toggle
              field={form.getFieldProps(`${ORG_OPTIONS}.${[AI_GIFT_SUGGESTION_ENABLED]}`)}
              name={`${ORG_OPTIONS}.${[AI_GIFT_SUGGESTION_ENABLED]}`}
              className={cn(styles.input, styles.toggle)}
              disabled={isDisabled || isReadOnly}
              hint="This option allows AI Gift Suggestions for this organization"
              helperText="AI Service"
              onChange={handleToggleChange}
              checked={!!form.values?.[ORG_OPTIONS]?.[AI_GIFT_SUGGESTION_ENABLED]}
            />
          )}
          <Toggle
            field={form.getFieldProps(`${ORG_OPTIONS}.${DIGITAL_BALANCE_LIMIT_ENABLED}`)}
            name={`${ORG_OPTIONS}.${DIGITAL_BALANCE_LIMIT_ENABLED}`}
            className={cn(styles.input, styles.toggle)}
            disabled={isDisabled || isReadOnly || isDigitalBalanceToggleDisabled}
            hint={
              <React.Fragment>
                This option turns on
                <br />
                digital balance limit.
              </React.Fragment>
            }
            helperText="Digital Balance Notification"
            onChange={handleDigitalBalanceLimitToggleChange}
            checked={!!form.values?.[ORG_OPTIONS]?.[DIGITAL_BALANCE_LIMIT_ENABLED]}
          />
          <Toggle
            field={form.getFieldProps(`${ORG_OPTIONS}.${AUTOMATIONS_ENABLED}`)}
            name={`${ORG_OPTIONS}.${AUTOMATIONS_ENABLED}`}
            className={cn(styles.input, styles.toggle)}
            disabled={!orgId}
            hint={
              !orgId
                ? 'Ability to manage automations becomes active once the organization is saved.'
                : 'This option turns on the Automations.'
            }
            helperText="Automations"
            onChange={handleToggleChange}
            checked={orgHasAutomationsEnabled}
          />
        </div>
        <div className={styles.inputs}>
          {isDigitalBalanceLimitEnabled && (
            <NumericInput
              className={cn(styles.inputContainer, styles.digitalBalanceInput)}
              field={form.getFieldProps(`${ORG_OPTIONS}.${[DIGITAL_BALANCE_LIMIT_VALUE]}`)}
              hint={DIGITAL_BALANCE_HELPER_TEXT}
              helperText="Digital Balance Threshold"
              name={`${ORG_OPTIONS}.${[DIGITAL_BALANCE_LIMIT_VALUE]}`}
              placeholder="Digital Balance Threshold"
              disabled={isDisabled}
              onChange={handleOnChange}
              value={form.values?.[ORG_OPTIONS]?.[DIGITAL_BALANCE_LIMIT_VALUE]}
              error={getFieldErrors(`${ORG_OPTIONS}.${[DIGITAL_BALANCE_LIMIT_VALUE]}`)}
              showErrors
              prefix="$"
              readOnly={isReadOnly}
              required={!!form.values?.[ORG_OPTIONS]?.[DIGITAL_BALANCE_LIMIT_ENABLED]}
            />
          )}
        </div>
      </section>
      {orgHasAutomationsEnabled && (
        <section>
          <h2 className={styles.heading}>Automations</h2>
          <div className={styles.container}>
            <AutomatedConnectionsContainer
              onAdd={handleAddAutomationClick(CONNECTION_ID)}
              onEdit={handleEditAutomationClick(CONNECTION_ID)}
              orgId={orgId!}
            />
            <AutomationSidebar withPortal onClose={handleCloseAutomationsSidebar} trigger={!!candidate}>
              {candidate?.[CONNECTION_ID] && (
                <AutomatedConnectionsForm
                  id={candidate[CONNECTION_ID]}
                  orgId={orgId!}
                  onClose={handleCloseAutomationsSidebar}
                />
              )}
            </AutomationSidebar>
          </div>
        </section>
      )}
      <section>
        <h2 className={styles.heading}>Integrations</h2>
        <div className={styles.info}>
          <Selector
            containerClassName={cn(styles.inputContainer, styles.selectorInput)}
            isClearable={false}
            isMulti={false}
            isSearchable={false}
            closeMenuOnSelect
            isDisabled={isDisabled}
            placeholder="Supporting CRM"
            helperText="Supporting CRM"
            value={formatSelectValue(crmProviders, `${ORG_OPTIONS}.${CRM_CURRENTLY_SUPPORTS}`)}
            options={crmProviders || []}
            name={`${ORG_OPTIONS}.${CRM_CURRENTLY_SUPPORTS}`}
            onBlur={form.handleBlur}
            onChange={(value) => handleSelectInputChange(`${ORG_OPTIONS}.${CRM_CURRENTLY_SUPPORTS}`, value)}
            readOnly={isReadOnly}
            required={requiredFields[`${ORG_OPTIONS}.${CRM_CURRENTLY_SUPPORTS}`]}
          />
          <Selector
            containerClassName={cn(styles.inputContainer, styles.selectorInput)}
            isClearable={false}
            isMulti={false}
            isSearchable={false}
            isDisabled={isDisabled}
            closeMenuOnSelect
            placeholder="Email System"
            helperText="Email System"
            value={formatSelectValue(emailProviders, `${ORG_OPTIONS}.${EMAIL_CURRENTLY_SUPPORTS}`)}
            options={emailProviders || []}
            name={`${ORG_OPTIONS}.${EMAIL_CURRENTLY_SUPPORTS}`}
            onBlur={form.handleBlur}
            onChange={(value) => handleSelectInputChange(`${ORG_OPTIONS}.${EMAIL_CURRENTLY_SUPPORTS}`, value)}
            readOnly={isReadOnly}
            required={requiredFields[`${ORG_OPTIONS}.${EMAIL_CURRENTLY_SUPPORTS}`]}
          />
        </div>
      </section>
      <section>
        <h2 className={styles.heading}>Sign in Options</h2>
        <div className={styles.info}>
          <RadioButtonGroup
            field={form.getFieldProps(`${ORG_OPTIONS}.${[SIGN_IN_METHOD_SUPPORTS]}`)}
            options={Object.entries(SigninMethodsEnum).map(([label, value]) => ({
              label,
              value,
            }))}
            onChange={({ currentTarget }) => {
              onChange(`${ORG_OPTIONS}.${SIGN_IN_METHOD_SUPPORTS}`, currentTarget.value);
            }}
            name={`${ORG_OPTIONS}.${SIGN_IN_METHOD_SUPPORTS}`}
            value={form.values?.[ORG_OPTIONS]?.[SIGN_IN_METHOD_SUPPORTS]}
            disabled={isReadOnly}
            isRounded
          />
        </div>
      </section>
      <section className={styles.orgDetails}>
        <h2 className={styles.heading}>Organization Details</h2>
        <div className={styles.info}>
          <div className={styles.inputContainer}>
            <InputLabel value="Order Key" required={requiredFields[ORDER_KEY]} />
            <div className={styles.orderKeyWrapper}>
              <Input
                field={form.getFieldProps(ORDER_KEY)}
                name={ORDER_KEY}
                className={cn(styles.orderKey, orderKeyStatusClassName)}
                placeholder="XXXXX"
                disabled={isDisabled}
                value={form.values?.[ORDER_KEY]}
                onChange={handleOnChange}
                showErrors={false}
                icon={renderOrderKeyStatusIcon}
                readOnly={isReadOnly}
              />
              <span className={styles.orderIdExample}>XXXX-XXXX</span>
            </div>
          </div>
          <Input
            className={styles.inputContainer}
            field={form.getFieldProps(RYBBON_CLIENT_ID)}
            name={RYBBON_CLIENT_ID}
            placeholder="Rybbon Client ID"
            hint={RYBBON_HELP_TOOLTIP_TEXT}
            helperText="Rybbon Client ID"
            disabled={isDisabled}
            required={requiredFields[RYBBON_CLIENT_ID]}
            value={form.values?.[RYBBON_CLIENT_ID]}
            onChange={handleOnChange}
            error={getFieldErrors(RYBBON_CLIENT_ID)}
            showErrors
            readOnly={isReadOnly}
          />
          <SecretInput
            className={styles.inputContainer}
            field={form.getFieldProps(RYBBON_CLIENT_SECRET)}
            name={RYBBON_CLIENT_SECRET}
            placeholder="Rybbon Client Secret"
            hint={RYBBON_HELP_TOOLTIP_TEXT}
            helperText="Rybbon Client Secret"
            disabled={isReadOnly}
            required={requiredFields[RYBBON_CLIENT_SECRET]}
            value={form.values?.[RYBBON_CLIENT_SECRET] || ''}
            onChange={handleOnChange}
            error={getFieldErrors(RYBBON_CLIENT_SECRET)}
            showErrors
            isSecretPresent={form.values?.[RYBBON_CLIENT_SECRET_HAS_VALUE] || !!form.values?.[RYBBON_CLIENT_SECRET]}
            readOnly={isReadOnly}
            onClear={
              form.values?.[RYBBON_CLIENT_SECRET_HAS_VALUE]
                ? () =>
                    handleToggleChange({
                      currentTarget: { name: RYBBON_CLIENT_SECRET_HAS_VALUE, checked: false },
                    } as React.ChangeEvent<HTMLInputElement>)
                : undefined
            }
          />
          {isSalesforceCrmSelected && (
            <React.Fragment>
              <Input
                className={styles.inputContainer}
                field={form.getFieldProps(SALESFORCE_CAMPAIGN_ID)}
                name={SALESFORCE_CAMPAIGN_ID}
                placeholder="Salesforce Campaign ID"
                helperText="Salesforce Campaign ID"
                disabled={isDisabled}
                required={requiredFields[SALESFORCE_CAMPAIGN_ID]}
                value={form.values?.[SALESFORCE_CAMPAIGN_ID]}
                onChange={handleOnChange}
                error={getFieldErrors(SALESFORCE_CAMPAIGN_ID)}
                showErrors
                readOnly={isReadOnly}
              />
              <Input
                className={styles.inputContainer}
                field={form.getFieldProps(SALESFORCE_CLIENT_ID)}
                name={SALESFORCE_CLIENT_ID}
                placeholder="Salesforce Client ID"
                helperText="Salesforce Client ID"
                disabled={isDisabled}
                required={requiredFields[SALESFORCE_CLIENT_ID]}
                value={form.values?.[SALESFORCE_CLIENT_ID]}
                onChange={handleOnChange}
                error={getFieldErrors(SALESFORCE_CLIENT_ID)}
                showErrors
                readOnly={isReadOnly}
              />
              <SecretInput
                className={styles.inputContainer}
                field={form.getFieldProps(SALESFORCE_CLIENT_SECRET)}
                name={SALESFORCE_CLIENT_SECRET}
                placeholder="Salesforce Client Secret"
                helperText="Salesforce Client Secret"
                disabled={isDisabled}
                required={requiredFields[SALESFORCE_CLIENT_SECRET]}
                value={form.values?.[SALESFORCE_CLIENT_SECRET] || ''}
                onChange={handleOnChange}
                error={getFieldErrors(SALESFORCE_CLIENT_SECRET)}
                showErrors
                isSecretPresent={
                  form.values?.[SALESFORCE_CLIENT_SECRET_HAS_VALUE] || !!form.values?.[SALESFORCE_CLIENT_SECRET]
                }
                readOnly={isReadOnly}
              />
            </React.Fragment>
          )}
          <Input
            helperText="Billing Plan ID"
            className={styles.inputContainer}
            field={form.getFieldProps(BILLING_PLAN_ID)}
            name={BILLING_PLAN_ID}
            placeholder="Billing Plan ID"
            disabled={isDisabled}
            required={requiredFields[BILLING_PLAN_ID]}
            value={form.values?.[BILLING_PLAN_ID]}
            onChange={handleOnChange}
            error={getFieldErrors(BILLING_PLAN_ID)}
            showErrors
            readOnly={isReadOnly}
          />
          <Input
            className={styles.inputContainer}
            field={form.getFieldProps(EXTERNAL_ID)}
            name={EXTERNAL_ID}
            placeholder="External ID"
            helperText="External ID"
            disabled={isDisabled}
            required={requiredFields[EXTERNAL_ID]}
            value={form.values?.[EXTERNAL_ID]}
            onChange={handleOnChange}
            error={getFieldErrors(EXTERNAL_ID)}
            showErrors
            readOnly={isReadOnly}
          />
          <Input
            className={styles.inputContainer}
            field={form.getFieldProps(WOOCOMMERCE_HOST_URL)}
            name={WOOCOMMERCE_HOST_URL}
            placeholder="Woocommerce Host URL"
            helperText="Woocommerce Host URL"
            disabled={isDisabled}
            required={requiredFields[WOOCOMMERCE_HOST_URL]}
            value={form.values?.[WOOCOMMERCE_HOST_URL]}
            onChange={handleOnChange}
            error={getFieldErrors(WOOCOMMERCE_HOST_URL)}
            showErrors
            readOnly={isReadOnly}
          />
          {form.values?.[WOOCOMMERCE_HOST_URL] && (
            <>
              <Selector
                containerClassName={cn(styles.inputContainer, styles.selectorInput)}
                isClearable={false}
                isMulti={false}
                isDisabled={isDisabled}
                closeMenuOnSelect
                placeholder="Woocommerce Service User"
                helperText="Woocommerce Service User"
                value={formatSelectValue(usersOptions, WOOCOMMERCE_SERVICE_ACCOUNT_USER_ID)}
                options={usersOptions || []}
                name={WOOCOMMERCE_SERVICE_ACCOUNT_USER_ID}
                onBlur={form.handleBlur}
                onChange={(value) => handleSelectInputChange(WOOCOMMERCE_SERVICE_ACCOUNT_USER_ID, value)}
                readOnly={isReadOnly}
                required={requiredFields[WOOCOMMERCE_SERVICE_ACCOUNT_USER_ID]}
                isLoading={isUsersLoading}
              />
              <SecretInput
                className={styles.inputContainer}
                field={form.getFieldProps(WOOCOMMERCE_API_KEY)}
                name={WOOCOMMERCE_API_KEY}
                placeholder="Woocommerce Api Key"
                helperText="Woocommerce Api Key"
                disabled={isDisabled}
                required={requiredFields[WOOCOMMERCE_API_KEY]}
                value={form.values?.[WOOCOMMERCE_API_KEY] || ''}
                onChange={handleOnChange}
                error={getFieldErrors(WOOCOMMERCE_API_KEY)}
                showErrors
                readOnly={isReadOnly}
              />
            </>
          )}
        </div>
      </section>
      <section>
        <h2 className={styles.heading}>Inventory Running Low Email</h2>
        <TagInput
          className={styles.inputContainer}
          value={form.values[LOW_INVENTORY_NOTIFICATION_EMAILS] || []}
          onChange={(value) => onChange(LOW_INVENTORY_NOTIFICATION_EMAILS, value || [])}
          placeholder="No receivers"
          error={form.errors[LOW_INVENTORY_NOTIFICATION_EMAILS]}
          isReadOnly={isReadOnly}
          hint={DISTRIBUTORS_NOTIFICATION_EMAILS_LIST_HELP_TEXT}
          label="Choose Receivers"
        />
      </section>
      <section className={styles.departments}>
        <h2 className={styles.heading}>
          <span>Manage Departments</span>
          {!orgId && (
            <HelpTooltip
              id="manage-dept-info"
              place={PositionStylesEnum.Top}
              className={styles.infoIcon}
              tooltipClassName={styles.tooltipContent}
            >
              Ability to manage departments becomes active once the organization is saved
            </HelpTooltip>
          )}
        </h2>
        <ActionButton
          disabled={!orgId}
          title="Manage departments"
          onClick={() => toggleSidebar(orgId)}
          className={styles.manageDept}
        />
      </section>
      <Accordion className={styles.accordion} activeItemId={isAdvancedOptionsShown ? 'advanced-options' : null}>
        <AccordionItem
          onClick={toggleAdvancedOptions}
          className={styles.accordionSection}
          titleClassName={cn(styles.advancedOptionsTitle, styles.heading)}
          contentClassName={styles.advancedOptionsContent}
          id="advanced-options"
          title="Advanced Options"
          content={
            <React.Fragment>
              <section className={styles.accordionSection}>
                <h2 className={styles.heading}>
                  <span>Shipping Page Details</span>
                  {canResetShippingPageCustomization && (
                    <TextIconButton
                      trigger={false}
                      title="Reset to default"
                      className={styles.resetCustomizationButton}
                      onClick={() => onResetCustomization(OrganizationResetCustomizationOptionsTypesEnum.ShippingPage)}
                    />
                  )}
                </h2>
                <div className={styles.info}>
                  <ColorInput
                    containerClassName={cn(styles.inputContainer, styles.colorPicker)}
                    labelClassName={styles.labelWithHint}
                    helperText={
                      <React.Fragment>
                        Banner Color
                        <HelpTooltip
                          id="sp-header-color-hint"
                          className={styles.infoIcon}
                          place={mobile ? PositionStylesEnum.Right : PositionStylesEnum.Top}
                          contentClassName={styles.tooltipImage}
                        >
                          <img src={spHeaderColorHintImage} alt="" />
                        </HelpTooltip>
                      </React.Fragment>
                    }
                    name={`${DELAYED_SHIPPING_PAGE}.${HEADER_COLOR}`}
                    position={PositionStylesEnum.Right}
                    showCurrent
                    value={form.values?.[DELAYED_SHIPPING_PAGE]?.[HEADER_COLOR]}
                    onChange={(data) => onChange(`${DELAYED_SHIPPING_PAGE}.${HEADER_COLOR}`, data)}
                    readOnly={isReadOnly}
                    disabled={isDisabled}
                    required={requiredFields[`${DELAYED_SHIPPING_PAGE}.${HEADER_COLOR}`]}
                  />
                  <ColorInput
                    containerClassName={cn(styles.inputContainer, styles.colorPicker)}
                    labelClassName={styles.labelWithHint}
                    helperText={
                      <React.Fragment>
                        Button Color
                        <HelpTooltip
                          place={PositionStylesEnum.Top}
                          id="sp-button-color-hint"
                          className={styles.infoIcon}
                          contentClassName={styles.tooltipImage}
                        >
                          <img src={spButtonColorHintImage} alt="" />
                        </HelpTooltip>
                      </React.Fragment>
                    }
                    name={`${DELAYED_SHIPPING_PAGE}.${BUTTON_COLOR}`}
                    position={PositionStylesEnum.Right}
                    showCurrent
                    value={form.values?.[DELAYED_SHIPPING_PAGE]?.[BUTTON_COLOR]}
                    onChange={(data) => onChange(`${DELAYED_SHIPPING_PAGE}.${BUTTON_COLOR}`, data)}
                    readOnly={isReadOnly}
                    disabled={isDisabled}
                    required={requiredFields[`${DELAYED_SHIPPING_PAGE}.${BUTTON_COLOR}`]}
                  />
                  <Input
                    className={styles.inputContainer}
                    field={form.getFieldProps(DELAYED_SHIPPING_BASE_URL)}
                    name={DELAYED_SHIPPING_BASE_URL}
                    placeholder="Delayed Shipping Base URL"
                    helperText="Delayed Shipping Base URL"
                    disabled={isDisabled}
                    required={requiredFields[DELAYED_SHIPPING_BASE_URL]}
                    value={form.values?.[DELAYED_SHIPPING_BASE_URL]}
                    onChange={handleOnChange}
                    error={getFieldErrors(DELAYED_SHIPPING_BASE_URL)}
                    showErrors
                    readOnly={isReadOnly}
                  />
                </div>
                <Accordion activeItemId={isDSTextCustomizationOn ? 'org-ds-text-customization' : null}>
                  <AccordionItem
                    onClick={() => setIsDSTextCustomizationOn((prev) => !prev)}
                    className={styles.accordionSection}
                    titleClassName={cn(styles.advancedOptionsTitle, styles.heading, styles.nested)}
                    contentClassName={cn(styles.advancedOptionsContent, styles.nested)}
                    id="org-ds-text-customization"
                    title="Text Customization"
                    content={
                      <div className={styles.dsCustomizer}>
                        <div className={styles.dsCustomizerFields}>
                          <TabProvider
                            rendererClassName={styles.customizerForm}
                            tabClassName={styles.tabLabel}
                            tabs={customizationItems}
                            onSelect={setCustomActiveTab}
                            activeTab={customActiveTab}
                            loaderOnChange
                          />
                        </div>
                        {!isReadOnly && availableDSTags && availableDSTags.length ? (
                          <TemplateTagPicker
                            label="Available Tags"
                            className={styles.dsCustomizerTags}
                            tags={availableDSTags.map((title) => ({ title }))}
                          />
                        ) : (
                          <div className={styles.dsCustomizerTags} />
                        )}
                      </div>
                    }
                  />
                </Accordion>
              </section>
              <section className={cn(styles.accordionSection, styles.emailSection)}>
                <h2 className={styles.heading}>
                  <span>Email Customization</span>
                  <HelpTooltip
                    id="email-customization-hint"
                    className={styles.infoIcon}
                    contentClassName={styles.tooltipImage}
                  >
                    <img src={emailCustomizationHintImage} alt="" />
                  </HelpTooltip>
                  {canResetEmailCustomization && (
                    <TextIconButton
                      trigger={false}
                      title="Reset to default"
                      className={styles.resetCustomizationButton}
                      onClick={() => onResetCustomization(OrganizationResetCustomizationOptionsTypesEnum.Email)}
                    />
                  )}
                </h2>
                <div className={styles.info}>
                  <Input
                    className={styles.inputContainer}
                    field={form.getFieldProps(`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_CUSTOM_SENDER}`)}
                    name={`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_CUSTOM_SENDER}`}
                    placeholder="Custom Email Address"
                    disabled={isDisabled || form.values?.[ORG_OPTIONS]?.[EMAIL_CURRENTLY_SUPPORTS] !== BLUEBIRD}
                    required={requiredFields[`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_CUSTOM_SENDER}`]}
                    value={form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[EMAIL_CUSTOM_SENDER]}
                    onChange={(e) =>
                      onChange(`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_CUSTOM_SENDER}`, e.currentTarget.value)
                    }
                    error={getFieldErrors(`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_CUSTOM_SENDER}`)}
                    showErrors
                    readOnly={isReadOnly}
                    helperText="Custom Email Address"
                  />
                </div>
                <div className={styles.info}>
                  <div className={cn(styles.inputContainer, styles.logoTypesInput)}>
                    <InputLabel value="Logo" />
                    <RadioButtonGroup
                      options={Object.values(EmailLogoTypesEnum).map((value) => ({
                        label: EMAIL_LOGO_TYPES_OPTION_LABELS[value],
                        value,
                      }))}
                      onChange={({ currentTarget }) => onChange(currentTarget.name, currentTarget.value)}
                      name={`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_LOGO_TYPE}`}
                      value={form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[EMAIL_LOGO_TYPE]}
                      disabled={!form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[EMAIL_USE_ORG_LOGO] || isReadOnly}
                    />
                  </div>
                  <ColorInput
                    containerClassName={cn(styles.inputContainer, styles.colorPicker)}
                    helperText={
                      <React.Fragment>
                        Main Color
                        <HelpTooltip
                          id="email-main-color-hint"
                          place={mobile ? PositionStylesEnum.Right : PositionStylesEnum.Top}
                          className={styles.infoIcon}
                          contentClassName={styles.tooltipImage}
                        >
                          <img src={emailMainColorHintImage} alt="" />
                        </HelpTooltip>
                      </React.Fragment>
                    }
                    name={`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_MAIN_COLOR}`}
                    position={PositionStylesEnum.Right}
                    showCurrent
                    value={form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[EMAIL_MAIN_COLOR]}
                    onChange={(data) => onChange(`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_MAIN_COLOR}`, data)}
                    readOnly={isReadOnly}
                    required={requiredFields[`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_MAIN_COLOR}`]}
                  />
                  <ColorInput
                    containerClassName={cn(styles.inputContainer, styles.colorPicker)}
                    helperText={
                      <React.Fragment>
                        Accent Color
                        <HelpTooltip
                          id="email-accent-color-hint"
                          className={styles.infoIcon}
                          place={PositionStylesEnum.Top}
                          contentClassName={styles.tooltipImage}
                        >
                          <img src={emailAccentColorHintImage} alt="" />
                        </HelpTooltip>
                      </React.Fragment>
                    }
                    name={`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_ACCENT_COLOR}`}
                    position={PositionStylesEnum.Right}
                    showCurrent
                    value={form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[EMAIL_ACCENT_COLOR]}
                    onChange={(data) => onChange(`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_ACCENT_COLOR}`, data)}
                    readOnly={isReadOnly}
                    required={requiredFields[`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_ACCENT_COLOR}`]}
                  />
                  <Checkbox
                    containerClassName={cn(styles.inputContainer, styles.checkboxWrapper)}
                    className={styles.checkbox}
                    checked={!!form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[EMAIL_USE_ORG_LOGO]}
                    onChange={(e) =>
                      onChange(`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_USE_ORG_LOGO}`, e.currentTarget.checked)
                    }
                    id={`${ORG_CUSTOMIZATION_OPTIONS}.${EMAIL_USE_ORG_LOGO}`}
                    text="Use organization logo"
                    disabled={isDisabled || isReadOnly}
                  />
                </div>
              </section>
              <section className={styles.accordionSection}>
                <h2 className={styles.heading}>
                  <span>Portal Customization</span>
                  {canResetPortalCustomization && (
                    <TextIconButton
                      trigger={false}
                      className={styles.resetCustomizationButton}
                      title="Reset to default"
                      onClick={() => {
                        onResetCustomization(OrganizationResetCustomizationOptionsTypesEnum.Portal);
                        setIsPreviewMode(false);
                      }}
                    />
                  )}
                </h2>
                <div className={styles.info}>
                  <ColorInput
                    containerClassName={cn(styles.inputContainer, styles.colorPicker)}
                    helperText="Sidebar Color"
                    name={`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_SIDEBAR_COLOR}`}
                    position={PositionStylesEnum.Right}
                    showCurrent
                    value={form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_SIDEBAR_COLOR]}
                    onChange={(data) => onChange(`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_SIDEBAR_COLOR}`, data)}
                    readOnly={isReadOnly}
                    required={requiredFields[`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_SIDEBAR_COLOR}`]}
                    disabled={isDisabled}
                  />
                  <ColorInput
                    containerClassName={cn(styles.inputContainer, styles.colorPicker)}
                    helperText="Sidebar Text Color"
                    name={`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_SIDEBAR_TEXT_COLOR}`}
                    position={PositionStylesEnum.Right}
                    showCurrent
                    value={form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_SIDEBAR_TEXT_COLOR]}
                    onChange={(data) => onChange(`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_SIDEBAR_TEXT_COLOR}`, data)}
                    readOnly={isReadOnly}
                    required={requiredFields[`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_SIDEBAR_TEXT_COLOR}`]}
                    disabled={isDisabled}
                  />
                  <ColorInput
                    containerClassName={cn(styles.inputContainer, styles.colorPicker)}
                    helperText="Accent Color"
                    name={`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_ACCENT_COLOR}`}
                    position={PositionStylesEnum.Right}
                    showCurrent
                    value={form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_ACCENT_COLOR]}
                    onChange={(data) => onChange(`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_ACCENT_COLOR}`, data)}
                    readOnly={isReadOnly}
                    required={requiredFields[`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_ACCENT_COLOR}`]}
                    disabled={isDisabled}
                  />
                  <div className={styles.portalCheckboxContainer}>
                    <Checkbox
                      containerClassName={cn(styles.inputContainer, styles.checkboxWrapper)}
                      className={styles.checkbox}
                      checked={!!form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_USE_ORG_LOGO]}
                      onChange={handlePortalLogoChange}
                      id={`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_USE_ORG_LOGO}`}
                      text="Use organization logo"
                      disabled={isDisabled || isReadOnly}
                    />
                    <Checkbox
                      containerClassName={cn(styles.inputContainer, styles.checkboxWrapper)}
                      className={styles.checkbox}
                      checked={!!form.values?.[ORG_CUSTOMIZATION_OPTIONS]?.[PORTAL_USE_COBRANDING_LOGO]}
                      onChange={handlePortalLogoChange}
                      id={`${ORG_CUSTOMIZATION_OPTIONS}.${PORTAL_USE_COBRANDING_LOGO}`}
                      disabled={isDisabled || isReadOnly || isCoBrandCheckboxDisabled}
                      hint={
                        isCoBrandCheckboxDisabled
                          ? COBRANDING_LOGO_DISABLED_TOOLTIP_TEXT
                          : COBRANDING_LOGO_HELP_TOOLTIP_TEXT
                      }
                      text="Use co-branding logo"
                    />
                  </div>
                  <ActionButton
                    className={cn(styles.inputContainer, styles.previewBtn)}
                    title={isPreviewMode ? 'Exit preview mode' : 'Preview'}
                    onClick={() => setIsPreviewMode((prev) => !prev)}
                    outlined
                  />
                </div>
              </section>
            </React.Fragment>
          }
        />
      </Accordion>
    </form>
  );
};

export default OrganizationForm;
