import cn from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

import { ReactComponent as QuestionIcon } from '../../../assets/images/icon-check-question.svg';
import useWindowSize from '../../../hooks/useWindowSize';
import { PositionStylesEnum } from '../../../types/shell';

import styles from './HelpTooltip.module.scss';

export interface IHelpTooltipProps extends TooltipProps {
  id: string;
  className?: string;
  tooltipClassName?: string;
  contentClassName?: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
  text?: string;
}

const HelpTooltip = ({
  id,
  className,
  children,
  effect = 'solid',
  icon: IconComponent,
  clickable = false,
  tooltipClassName,
  contentClassName,
  place = PositionStylesEnum.Right,
  text,
  ...tooltipProps
}: IHelpTooltipProps) => {
  const { mobile } = useWindowSize();

  const Icon = useMemo(() => (IconComponent ? IconComponent : QuestionIcon), [IconComponent]);

  const isContentAbsent = useMemo(() => {
    const isChildrenEmpty = Array.isArray(children)
      ? !children.length || children.every((child) => typeof child === 'undefined' || child === null)
      : !children;

    return !text && isChildrenEmpty;
  }, [text, children]);

  const dataTip = useMemo(() => {
    if (isContentAbsent) {
      return void 0;
    }

    return text ? text : 'custom show';
  }, [text, isContentAbsent]);

  const renderContent = useMemo(() => {
    if (text || isContentAbsent) {
      return void 0;
    }

    return <div className={cn(styles.content, contentClassName)}>{children}</div>;
  }, [children, text, isContentAbsent]);

  return (
    <React.Fragment>
      <Icon
        data-for={id}
        data-tip={dataTip}
        className={cn(styles.question, className)}
        {...(clickable || mobile ? { 'data-event': 'click' } : {})}
      />
      <ReactTooltip
        {...tooltipProps}
        id={id}
        effect={effect}
        type="light"
        place={place}
        className={cn(styles.tooltip, tooltipClassName)}
        clickable={clickable || mobile}
      >
        {renderContent}
      </ReactTooltip>
    </React.Fragment>
  );
};

export default HelpTooltip;
