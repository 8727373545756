import cn from 'classnames';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import { range } from 'lodash';
import * as React from 'react';

import { ReactComponent as IconArrowDown } from '../../../../assets/images/icon-arrow-down.svg';
import { MONTHS } from '../../../../constants/date';
import { BUTTON_BUTTON } from '../../../../types/forms';
import { DropdownWithContent } from '../../../index';

import styles from './DatePicker.module.scss';

interface IProps {
  className?: string;
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  customHeaderCount: number;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
  monthsShown?: number;
  minDate: Date;
  maxDate?: Date | null;
}

const DatePickerHeader = ({ date, minDate, maxDate, changeYear, changeMonth }: IProps) => {
  const { maxMonth, maxYear, minMonth, minYear } = {
    maxMonth: maxDate ? getMonth(maxDate) : undefined,
    maxYear: maxDate ? getYear(maxDate) : undefined,
    minMonth: getMonth(minDate),
    minYear: getYear(minDate),
  };

  const renderMonths = (newDate: Date) => {
    const year = getYear(newDate);

    if (maxMonth && year === maxYear) {
      return MONTHS.slice(0, maxMonth + 1);
    }

    if (year === minYear) {
      return MONTHS.slice(minMonth);
    }

    return MONTHS;
  };

  const renderYears = (() => {
    const lowerLimit = getYear(minDate);

    //  if higher limit is not set, we can't show years till infinity, so we'll use the current year as a limit
    // +1 here is because lodash.range makes an array which doesn't include the second argument
    const higherLimit = getYear(maxDate || getYear(new Date())) + 1;

    return range(lowerLimit, higherLimit, 1);
  })();

  const sanitizeMonth = (year: number) => {
    const selectedMonth = getMonth(date);

    if (year === maxYear && maxMonth && selectedMonth > maxMonth) {
      return changeMonth(maxMonth);
    }

    if (year === minYear && selectedMonth < minMonth) {
      return changeMonth(minMonth);
    }
  };

  return (
    <div className={styles.header}>
      <DropdownWithContent
        className={styles.headerSelect}
        contentClassName={styles.dropdownContent}
        labelClassName={styles.dropdownLabel}
        label={({ isOpened }) => (
          <React.Fragment>
            {MONTHS[getMonth(date)]}
            <IconArrowDown className={isOpened ? styles.headerOpened : ''} />
          </React.Fragment>
        )}
      >
        {({ close }) => (
          <div className={styles.optionsContainer}>
            {renderMonths(date).map((month) => (
              <button
                type={BUTTON_BUTTON}
                key={month}
                value={month}
                className={cn(styles.option, { [styles.selectedOption]: month === MONTHS[getMonth(date)] })}
                onClick={() => {
                  changeMonth(MONTHS.indexOf(month));
                  close();
                }}
              >
                {month}
              </button>
            ))}
          </div>
        )}
      </DropdownWithContent>
      <DropdownWithContent
        className={styles.headerSelect}
        contentClassName={styles.dropdownContent}
        labelClassName={styles.dropdownLabel}
        label={({ isOpened }) => (
          <React.Fragment>
            {getYear(date)}
            <IconArrowDown className={isOpened ? styles.headerOpened : ''} />
          </React.Fragment>
        )}
      >
        {({ close }) => (
          <div className={styles.optionsContainer}>
            {renderYears.map((year) => (
              <button
                type={BUTTON_BUTTON}
                key={year}
                className={cn(styles.option, { [styles.selectedOption]: year === getYear(date) })}
                onClick={() => {
                  changeYear(year);
                  sanitizeMonth(year);
                  close();
                }}
              >
                {year}
              </button>
            ))}
          </div>
        )}
      </DropdownWithContent>
    </div>
  );
};

export default DatePickerHeader;
