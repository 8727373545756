import cn from 'classnames';
import * as React from 'react';
import Highlighter from 'react-highlight-words';
import { CellProps } from 'react-table';

import styles from './TableCellWithHighlight.module.scss';

interface IProps {
  query: string;
  className?: string;
}

const TableCellWithHighlight =
  <T extends object>({ query, className }: IProps) =>
  (cellProps: CellProps<T>) => {
    const { value } = cellProps;

    return (
      <Highlighter
        highlightClassName={cn(styles.mark, className)}
        searchWords={[query]}
        autoEscape
        textToHighlight={value || ''}
      />
    );
  };

export default TableCellWithHighlight;
