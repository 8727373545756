import {
  IDownloadReportsCSVRequestPayload,
  IFetchReportByIdRequestPayload,
  IFetchReportByIdResponsePayload,
  IFetchReportsRequestPayload,
  IFetchReportsResponsePayload,
  IPopulateReportsCampaignSummariesRequestPayload,
  IPopulateReportsCampaignSummariesResponsePayload,
  IReport,
  UpdateReportValueRequestPayload,
} from '../../types/reports';
import { IApiError, IDownloadCSVSuccessPayload } from '../../types/shell';

export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';

export const FETCH_REPORT_BY_ID_REQUEST = 'FETCH_REPORT_BY_ID_REQUEST';
export const FETCH_REPORT_BY_ID_SUCCESS = 'FETCH_REPORT_BY_ID_SUCCESS';
export const FETCH_REPORT_BY_ID_FAILURE = 'FETCH_REPORT_BY_ID_FAILURE';

export const POPULATE_REPORT_CAMPAIGN_SUMMARIES_REQUEST = 'POPULATE_REPORT_CAMPAIGN_SUMMARIES_REQUEST';
export const POPULATE_REPORT_CAMPAIGN_SUMMARIES_SUCCESS = 'POPULATE_REPORT_CAMPAIGN_SUMMARIES_SUCCESS';
export const POPULATE_REPORT_CAMPAIGN_SUMMARIES_FAILURE = 'POPULATE_REPORT_CAMPAIGN_SUMMARIES_FAILURE';

export const DOWNLOAD_REPORTS_CSV_REQUEST = 'DOWNLOAD_REPORTS_CSV_REQUEST';
export const DOWNLOAD_REPORTS_CSV_SUCCESS = 'DOWNLOAD_REPORTS_CSV_SUCCESS';
export const DOWNLOAD_REPORTS_CSV_FAILURE = 'DOWNLOAD_REPORTS_CSV_FAILURE';

export const UPDATE_REPORT_VALUE = 'UPDATE_REPORT_VALUE';

export const fetchReportsRequest = (payload: IFetchReportsRequestPayload) => ({
  type: FETCH_REPORTS_REQUEST,
  payload,
});

export const fetchReportByIdRequest = (payload: IFetchReportByIdRequestPayload) => ({
  type: FETCH_REPORT_BY_ID_REQUEST,
  payload,
});

export const fetchReportsSuccess = (payload?: IFetchReportsResponsePayload) => ({
  type: FETCH_REPORTS_SUCCESS,
  payload,
});

export const fetchReportByIdSuccess = (payload: IFetchReportByIdResponsePayload) => ({
  type: FETCH_REPORT_BY_ID_SUCCESS,
  payload,
});

export const fetchReportsFailure = (payload?: any) => ({
  type: FETCH_REPORTS_FAILURE,
  payload,
});

export const fetchReportByIdFailure = (payload: any) => ({
  type: FETCH_REPORT_BY_ID_FAILURE,
  payload,
});

export const populateReportCampaignSummariesRequest = (payload: IPopulateReportsCampaignSummariesRequestPayload) => ({
  type: POPULATE_REPORT_CAMPAIGN_SUMMARIES_REQUEST,
  payload,
});

export const populateReportCampaignSummariesSuccess = (payload: IPopulateReportsCampaignSummariesResponsePayload) => ({
  type: POPULATE_REPORT_CAMPAIGN_SUMMARIES_SUCCESS,
  payload,
});

export const populateReportCampaignSummariesFailure = (payload: IApiError | Error | unknown) => ({
  type: POPULATE_REPORT_CAMPAIGN_SUMMARIES_FAILURE,
  payload,
});

export const downloadReportsCSVRequest = (payload: IDownloadReportsCSVRequestPayload) => ({
  type: DOWNLOAD_REPORTS_CSV_REQUEST,
  payload,
});

export const downloadReportsCSVSuccess = (payload: IDownloadCSVSuccessPayload) => ({
  type: DOWNLOAD_REPORTS_CSV_SUCCESS,
  payload,
});

export const downloadReportsCSVFailure = (payload: any) => ({
  type: DOWNLOAD_REPORTS_CSV_FAILURE,
  payload,
});

export const updateReportValue = <T extends IReport>(payload: UpdateReportValueRequestPayload<T>) => ({
  type: UPDATE_REPORT_VALUE,
  payload,
});
