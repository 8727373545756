import * as React from 'react';
import { animated, useTransition } from 'react-spring';

interface IProps {
  className?: string;
  isOpen: boolean;
  children?: React.ReactNode | React.ReactNode[] | string;
  style?: React.CSSProperties;
}

// TODO: Don't use this component with quick animations, or optimize it well
const FadeInOut = ({ children, className, isOpen, style }: IProps) => {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  return (
    <React.Fragment>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={{ ...style, ...props }} className={className}>
              {children}
            </animated.div>
          ),
      )}
    </React.Fragment>
  );
};

export default FadeInOut;
