import React, { useEffect } from 'react';

import { CsvUploadError, CsvUploadSuccess } from '../../components';
import { IOneLinkRecipients } from '../../types/oneLink';

interface IProps {
  recipients: IOneLinkRecipients | null;
  status: 'failed' | 'success' | null;
  onClose?: () => void;
}

const TwoFAStatusMessage: React.FC<IProps> = ({ recipients, status, onClose }) => {
  useEffect(() => {
    if (onClose && status === 'success') {
      const timeoutId = setTimeout(() => {
        onClose();
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [status, onClose]);

  return (
    <>
      {status === 'failed' && (
        <CsvUploadError
          failedRows={recipients?.failedRecipients}
          successRows={recipients?.successfulRecipients}
          onClose={onClose}
          fileName="failed-recipients.csv"
        />
      )}
      {status === 'success' && (
        <CsvUploadSuccess successCount={recipients?.successfulRecipients?.length || 0} onClose={onClose} />
      )}
    </>
  );
};

export default TwoFAStatusMessage;
