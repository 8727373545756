import { ICampaignItem } from '../../../types/campaigns';
import { IReportEngagementItem } from '../../../types/reports';

enum actionTypes {
  Init = 'init',
  Add = 'add',
  Remove = 'remove',
}

export type IPayload = IReportEngagementItem[] | IReportEngagementItem | ICampaignItem | null | string;

export interface IAction {
  type: actionTypes;
  payload?: IPayload;
}

const reducer =
  (items?: IReportEngagementItem[] | null) =>
  (state: IReportEngagementItem[], { type, payload }: IAction) => {
    switch (type) {
      case actionTypes.Init:
        return payload && Array.isArray(payload) && payload.length ? payload.concat() : items || [];
      case actionTypes.Add:
        return state.concat(payload as IReportEngagementItem);
      case actionTypes.Remove:
        return state.filter((item) => item.item_id !== (payload as string));
      default:
        return state;
    }
  };

const actions = {
  init: (payload?: IReportEngagementItem[]) => ({ type: actionTypes.Init, payload }),
  add: (payload: IReportEngagementItem | ICampaignItem) => ({ type: actionTypes.Add, payload }),
  remove: (payload: string) => ({ type: actionTypes.Remove, payload }),
};

export { actions, reducer };
