import {
  IAddTemplateRequest,
  IEditTemplateRequest,
  TCollectiveTemplateItem,
  TemplateTypesEnum,
} from '../../types/templates';

export const FETCH_NOTES_TEMPLATES_REQUEST = 'FETCH_NOTES_TEMPLATES_REQUEST';
export const FETCH_NOTES_TEMPLATES_SUCCESS = 'FETCH_NOTES_TEMPLATES_SUCCESS';
export const FETCH_NOTES_TEMPLATES_FAILURE = 'FETCH_NOTES_TEMPLATES_FAILURE';

export const FETCH_EMAILS_TEMPLATES_REQUEST = 'FETCH_EMAILS_TEMPLATES_REQUEST';
export const FETCH_EMAILS_TEMPLATES_SUCCESS = 'FETCH_EMAILS_TEMPLATES_SUCCESS';
export const FETCH_EMAILS_TEMPLATES_FAILURE = 'FETCH_EMAILS_TEMPLATES_FAILURE';

export const ADD_TEMPLATE_REQUEST = 'ADD_TEMPLATE_REQUEST';
export const ADD_TEMPLATE_SUCCESS = 'ADD_TEMPLATE_SUCCESS';
export const ADD_TEMPLATE_FAILURE = 'ADD_TEMPLATE_FAILURE';

export const EDIT_TEMPLATE_REQUEST = 'EDIT_TEMPLATE_REQUEST';
export const EDIT_TEMPLATE_SUCCESS = 'EDIT_TEMPLATE_SUCCESS';
export const EDIT_TEMPLATE_FAILURE = 'EDIT_TEMPLATE_FAILURE';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';

export const ADD_TEMPLATE_VALUE = 'ADD_TEMPLATE_VALUE';
export const SET_TEMPLATE_VALUE = 'SET_TEMPLATE_VALUE';
export const CLEAR_TEMPLATE_VALUE = 'CLEAR_TEMPLATE_VALUE';

export const fetchEmailsTemplatesRequest = () => ({
  type: FETCH_EMAILS_TEMPLATES_REQUEST,
});

export const fetchEmailsTemplatesSuccess = (payload: any[]) => ({
  type: FETCH_EMAILS_TEMPLATES_SUCCESS,
  payload,
});

export const fetchEmailsTemplatesFailure = (payload: any) => ({
  type: FETCH_EMAILS_TEMPLATES_FAILURE,
  payload,
});

export const fetchNotesTemplatesRequest = () => ({
  type: FETCH_NOTES_TEMPLATES_REQUEST,
});

export const fetchNotesTemplatesSuccess = (payload: any[]) => ({
  type: FETCH_NOTES_TEMPLATES_SUCCESS,
  payload,
});

export const fetchNotesTemplatesFailure = (payload: any) => ({
  type: FETCH_NOTES_TEMPLATES_FAILURE,
  payload,
});

export const addTemplateRequest = (payload: IAddTemplateRequest) => ({
  type: ADD_TEMPLATE_REQUEST,
  payload,
});

export const addTemplateSuccess = (payload?: any) => ({
  type: ADD_TEMPLATE_SUCCESS,
  payload,
});

export const addTemplateFailure = (payload?: any) => ({
  type: ADD_TEMPLATE_FAILURE,
  payload,
});

export const editTemplateRequest = (payload: IEditTemplateRequest) => ({
  type: EDIT_TEMPLATE_REQUEST,
  payload,
});

export const editTemplateSuccess = (payload?: any) => ({
  type: EDIT_TEMPLATE_SUCCESS,
  payload,
});

export const editTemplateFailure = (payload?: any) => ({
  type: EDIT_TEMPLATE_FAILURE,
  payload,
});

export const addTemplateValue = (payload?: Partial<TCollectiveTemplateItem>) => ({
  type: ADD_TEMPLATE_VALUE,
  payload,
});

export const deleteTemplateRequest = (payload: {
  itemId: string;
  type: TemplateTypesEnum | null | undefined;
  resolve: any;
  reject: any;
}) => ({
  type: DELETE_TEMPLATE_REQUEST,
  payload,
});

export const deleteTemplateSuccess = (payload: { itemId: string; type: TemplateTypesEnum }) => ({
  type: DELETE_TEMPLATE_SUCCESS,
  payload,
});

export const deleteTemplateFailure = (payload?: any) => ({
  type: DELETE_TEMPLATE_FAILURE,
  payload,
});

export const setTemplateValue = (payload?: Partial<TCollectiveTemplateItem>) => ({
  type: SET_TEMPLATE_VALUE,
  payload,
});

export const clearTemplateValue = () => ({
  type: CLEAR_TEMPLATE_VALUE,
});
