import classnames from 'classnames';
import * as React from 'react';

import useClickOutside from '../../hooks/useClickOutside';
import { InputLabel } from '../forms';
import InlineSpinner from '../InlineSpinner/InlineSpinner';
import styles from './DropdownWithSpinner.module.scss';

interface IProps {
  selected: any;
  items: any[];
  onSelect: (item: any) => void;
  className?: string;
  isLoading?: boolean;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
}

export default ({ selected, items, onSelect, isLoading, className, placeholder, helperText, disabled }: IProps) => {
  const [isOpened, toggleOpen] = React.useState(false);
  const dropdownEl = React.useRef(null);

  const close = () => toggleOpen(false);

  const handleSelect = (value: any) => {
    onSelect(value);
    close();
  };

  useClickOutside(dropdownEl, close);

  const selectedItemTitle = React.useMemo(() => {
    const { title, subject } = items.find((i) => i.item_id === selected) || {};

    return title || subject;
  }, [selected, items]);

  return (
    <div className={classnames(styles.dropdownContainer, className)}>
      <InputLabel value={helperText} />
      <div className={classnames(styles.dropdownWithSpinner, { opened: isOpened })}>
        <button
          disabled={disabled}
          className={classnames(styles.value, { [styles.opened]: isOpened })}
          onClick={() => toggleOpen((prev) => !prev)}
          type="button"
        >
          {isLoading ? <InlineSpinner /> : selectedItemTitle || placeholder}
        </button>
        {!isLoading && isOpened && (
          <div className={styles.list} ref={dropdownEl}>
            {items &&
              items.map(({ title, subject, item_id }) => (
                <button
                  key={item_id}
                  className={classnames(styles.item, { selected: selected === item_id })}
                  type="button"
                  onClick={() => handleSelect(item_id)}
                >
                  <span>{title || subject}</span>
                </button>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
