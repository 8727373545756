import cn from 'classnames';
import { FormikHelpers, useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

import { ReactComponent as GoogleIcon } from '../../assets/images/icon-google-color.svg';
import { ReactComponent as MailIcon } from '../../assets/images/icon-input-mail.svg';
import { ReactComponent as PasswordIcon } from '../../assets/images/icon-input-password.svg';
import { ReactComponent as JaneStreetIcon } from '../../assets/images/icon-jane-street.svg';
import { ReactComponent as OutlookIcon } from '../../assets/images/icon-outlook.svg';
import { ReactComponent as PaceIcon } from '../../assets/images/icon-pace.svg';
import { ReactComponent as VercelIcon } from '../../assets/images/icon-vercel.svg';

import { ActionButton, Checkbox, Input } from '../../components/forms';
import firebaseInstance from '../../config/fbConfig';
import {
  ERROR_LINK_CREDENTIALS_IS_NEEDED,
  LOGIN_FORM_INITIAL_VALUES as initialValues,
  LOGIN_FORM_VALIDATION_SCHEMA as validationSchema,
  SupportedAuthProvidersEnum,
} from '../../constants/auth';
import * as AuthActions from '../../store/actions/auth';
import { toggleResetPasswordModal } from '../../store/actions/modals';
import { selectIsJaneStreetTenant, selectIsPaceTenant, selectIsVercelTenant } from '../../store/selectors/shell';
import { IAuthenticateRequestPayload, IAuthError, ILoginFormState } from '../../types/auth';
import { EMAIL_INPUT, PASSWORD_INPUT } from '../../types/forms';
import { handleApiError } from '../../utils/ui';

import styles from './LoginContainer.module.scss';

const LoginContainer = () => {
  const dispatch = useDispatch();
  const firebase = useFirebase();

  const [rememberMe, setRememberMe] = React.useState(true);
  const [linkFlowMethods, setLinkFlowMethods] = React.useState<SupportedAuthProvidersEnum[]>();

  const pendingCredsRef = React.useRef<IAuthError>();

  const isJaneStreet = useSelector(selectIsJaneStreetTenant);
  const isPace = useSelector(selectIsPaceTenant);
  const isVercel = useSelector(selectIsVercelTenant);

  const startAuth = React.useCallback(
    async ({ providerId, email, password }: Omit<IAuthenticateRequestPayload, 'resolve' | 'reject'>) => {
      try {
        return await new Promise((resolve, reject) => {
          dispatch(
            AuthActions.authenticateRequest({
              providerId,
              email,
              pendingCredentials: pendingCredsRef.current,
              password,
              resolve,
              reject,
            }),
          );
        });
      } catch (error: any) {
        if (error.code === ERROR_LINK_CREDENTIALS_IS_NEEDED) {
          if (error?.email && error?.credential) {
            const { email: userEmail } = error;

            pendingCredsRef.current = error;

            const methods = await firebase.auth().fetchSignInMethodsForEmail(userEmail);

            setLinkFlowMethods(methods as SupportedAuthProvidersEnum[]);
            if (methods.includes(SupportedAuthProvidersEnum.Password)) {
              await form.setFieldValue(EMAIL_INPUT, userEmail);
            }
          } else {
            handleApiError('Not authorized.')(error);
          }
        } else {
          handleApiError('Not authorized.')(error);
        }
      }
    },
    [dispatch],
  );

  const openResetPasswordModal = React.useCallback(() => {
    dispatch(toggleResetPasswordModal());
  }, [dispatch]);

  const onSubmit = React.useCallback(
    (values: ILoginFormState, { setSubmitting }: FormikHelpers<ILoginFormState>) => {
      setSubmitting(true);
      startAuth(values).then(() => setSubmitting(false));
    },
    [startAuth],
  );

  const getButtonIcon = React.useCallback((provider: SupportedAuthProvidersEnum) => {
    switch (provider) {
      case SupportedAuthProvidersEnum.Google:
        return <GoogleIcon />;
      case SupportedAuthProvidersEnum.Microsoft:
        return <OutlookIcon />;
      case SupportedAuthProvidersEnum.Pace:
        return <PaceIcon />;
      case SupportedAuthProvidersEnum.Vercel:
        return <VercelIcon />;
      case SupportedAuthProvidersEnum.JaneStreet:
        return <JaneStreetIcon />;
      default:
        return null;
    }
  }, []);

  const getButtonTitle = React.useCallback((provider: SupportedAuthProvidersEnum) => {
    switch (provider) {
      case SupportedAuthProvidersEnum.Google:
        return 'Google';
      case SupportedAuthProvidersEnum.Microsoft:
        return 'Outlook';
      case SupportedAuthProvidersEnum.Pace:
        return 'Pace';
      case SupportedAuthProvidersEnum.Vercel:
        return 'Vercel';
      case SupportedAuthProvidersEnum.JaneStreet:
        return 'Jane Street';
      default:
        return '';
    }
  }, []);

  const form = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnMount: true,
  });

  const isLinkMode = React.useMemo(() => !!linkFlowMethods, [linkFlowMethods]);

  const message = React.useMemo(
    () => 'This email is already associated with an account. Sign in using the provider associated with this email.',
    [],
  );

  const shouldShowInLinkMode = React.useCallback(
    (providerId?: SupportedAuthProvidersEnum) => {
      if (!isLinkMode) {
        return true;
      }

      return providerId ? linkFlowMethods?.includes(providerId) : false;
    },
    [linkFlowMethods, isLinkMode],
  );

  React.useEffect(() => {
    firebase
      .auth()
      .setPersistence(
        rememberMe ? firebaseInstance.auth.Auth.Persistence.LOCAL : firebaseInstance.auth.Auth.Persistence.SESSION,
      );
  }, [firebase, rememberMe]);

  return (
    <div className={cn(styles.loginContainer)}>
      <div className={styles.inner}>
        <h1 className={styles.title}>{isLinkMode ? 'User already exists' : 'Sign in'}</h1>
        {isLinkMode && pendingCredsRef.current?.message && <h2 className={styles.message}>{message}</h2>}
        <form className={styles.form} onSubmit={form.handleSubmit}>
          {shouldShowInLinkMode() && (
            <Input
              className={styles.mailInput}
              onChange={form.handleChange}
              id={EMAIL_INPUT}
              name={EMAIL_INPUT}
              type={EMAIL_INPUT}
              onBlur={form.handleBlur}
              value={form.values.email}
              helperText="Your Email"
              placeholder="Your Email"
              icon={<MailIcon />}
              error={form.touched.email ? form.errors.email : undefined}
              autoComplete="username"
            />
          )}
          <div className={cn(styles.passwordContainer, { [styles.linkMode]: isLinkMode })}>
            <span className={styles.forgetPassword} onClick={openResetPasswordModal}>
              Forgot Password?
            </span>
            <Input
              className={styles.passwordInput}
              onChange={form.handleChange}
              id={PASSWORD_INPUT}
              name={PASSWORD_INPUT}
              type={PASSWORD_INPUT}
              onBlur={form.handleBlur}
              value={form.values.password}
              helperText="Your Password"
              placeholder="Your Password"
              icon={<PasswordIcon />}
              error={form.touched.password ? form.errors.password : undefined}
              autoComplete="current-password"
            />
          </div>
          {shouldShowInLinkMode() && (
            <Checkbox
              className={styles.rememberMe}
              onChange={() => setRememberMe((prev) => !prev)}
              checked={rememberMe}
              text={'Keep me signed in'}
            />
          )}
          <ActionButton className={styles.submitBtn} type="submit" title="Submit" disabled={!form.isValid} />
        </form>
        <div className={styles.providers}>
          {Object.values(SupportedAuthProvidersEnum)
            .filter((p) => p !== SupportedAuthProvidersEnum.Password)
            .filter(shouldShowInLinkMode)
            .filter((p) => (!isJaneStreet ? p !== SupportedAuthProvidersEnum.JaneStreet : true))
            .filter((p) => (!isPace ? p !== SupportedAuthProvidersEnum.Pace : true))
            .filter((p) => (!isVercel ? p !== SupportedAuthProvidersEnum.Vercel : true))
            .map((providerId) => (
              <button
                key={providerId}
                className={styles.providerBtn}
                onClick={() => startAuth({ providerId })}
                type="button"
              >
                {getButtonIcon(providerId)}
                <span>Sign in with {getButtonTitle(providerId)}</span>
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;
