import { FieldAttributes } from 'formik';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format';

import Input from './Input';

interface IProps extends FieldAttributes<any>, NumericFormatProps {}

const NumericInput: React.FC<IProps> = ({ name, onChange, field, prefix, decimalScale = 2, ...restProp }) => {
  const handleValueChange = (values: NumberFormatValues) => {
    const syntheticEvent = {
      currentTarget: { name, value: values.floatValue },
    } as unknown as ChangeEvent<HTMLInputElement>;

    if (typeof field?.onChange === 'function') {
      field.onChange(syntheticEvent);
    }

    if (onChange) {
      onChange(syntheticEvent);
    }
  };

  return (
    <NumericFormat
      {...restProp}
      customInput={Input}
      field={field}
      name={name}
      onValueChange={handleValueChange}
      fixedDecimalScale
      prefix={prefix}
      decimalScale={decimalScale}
    />
  );
};

export default NumericInput;
