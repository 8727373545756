import {
  ICreateEngagementResponse,
  ICreateOneLinkSendResponse,
  IEditEngagementRequestPayload,
  IEditEngagementSuccessPayload,
  IPostEngagementRequestPayload,
} from '../../types/bucket';
import { IApiError } from '../../types/shell';

export const POST_ENGAGEMENT_REQUEST = 'POST_ENGAGEMENT_REQUEST';
export const POST_ENGAGEMENT_SUCCESS = 'POST_ENGAGEMENT_SUCCESS';
export const POST_ENGAGEMENT_FAILURE = 'POST_ENGAGEMENT_FAILURE';

export const EDIT_ENGAGEMENT_REQUEST = 'EDIT_ENGAGEMENT_REQUEST';
export const EDIT_ENGAGEMENT_SUCCESS = 'EDIT_ENGAGEMENT_SUCCESS';
export const EDIT_ENGAGEMENT_FAILURE = 'EDIT_ENGAGEMENT_FAILURE';

export const postEngagementRequest = (payload: IPostEngagementRequestPayload) => ({
  type: POST_ENGAGEMENT_REQUEST,
  payload,
});

export const postEngagementSuccess = (payload: ICreateEngagementResponse | ICreateOneLinkSendResponse) => ({
  type: POST_ENGAGEMENT_SUCCESS,
  payload,
});

export const postEngagementFailure = (payload: unknown | IApiError) => ({
  type: POST_ENGAGEMENT_FAILURE,
  payload,
});

export const editEngagementRequest = (payload: IEditEngagementRequestPayload) => ({
  type: EDIT_ENGAGEMENT_REQUEST,
  payload,
});

export const editEngagementSuccess = (payload: IEditEngagementSuccessPayload) => ({
  type: EDIT_ENGAGEMENT_SUCCESS,
  payload,
});

export const editEngagementFailure = (payload?: IApiError) => ({
  type: EDIT_ENGAGEMENT_FAILURE,
  payload,
});
