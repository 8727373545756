import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toggleIsAIExtensionInstalled } from '../store/actions/shell';
import { MessageSendersEnum, MessageTypesEnum } from '../types/messaging';

const useConnectWithAIExtension = () => {
  const [hasExtension, setHasExtension] = React.useState(false);
  const dispatch = useDispatch();

  const requestCheckExtensionVersion = useCallback(async () => {
    await chrome.runtime.sendMessage(
      process.env.REACT_APP_AI_EXTENSION_ID,
      { sender: MessageSendersEnum.Portal, type: MessageTypesEnum.Version },
      (reply) => {
        if (chrome.runtime.lastError) {
          dispatch(toggleIsAIExtensionInstalled(false));
        }
        if (reply) {
          dispatch(toggleIsAIExtensionInstalled(true));
        }
      },
    );
  }, [dispatch]);

  useEffect(() => {
    const handleMessageFromExtension = (event: any) => {
      if (event.source === window && event.data.sender && event.data.sender === 'bb-ai-ext-portal') {
        setHasExtension(true);
        console.log('Got the message in Portal', event.data);
      }
    };

    window.addEventListener('message', handleMessageFromExtension);
    return () => {
      window.removeEventListener('message', handleMessageFromExtension);
    };
  }, []);

  useEffect(() => {
    requestCheckExtensionVersion().catch(() => dispatch(toggleIsAIExtensionInstalled(false)));
  }, [requestCheckExtensionVersion, dispatch]);

  return { hasExtension };
};

export default useConnectWithAIExtension;
