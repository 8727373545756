import { createTransform } from 'redux-persist';

import { IBucketState } from '../../types/bucket';

const bulkTransform = createTransform(
  (stateIn: IBucketState) => ({
    ...stateIn,
    isFetching: false,
    bulk: { ...stateIn.bulk, csv: null, result: null, isLoading: false },
  }),
  (stateOut: IBucketState) => ({
    ...stateOut,
    isFetching: false,
    bulk: { ...stateOut.bulk, csv: null, result: null, isLoading: false },
  }),
  { whitelist: ['bucket'] },
);

const transformArray = [bulkTransform];

export default transformArray;
