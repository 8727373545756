import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { IUser, IUsersState } from '../../types/users';
import * as AuthActions from '../actions/auth';
import * as UsersActions from '../actions/users';

export const initialState: IUsersState = {
  items: null,
  userDetails: null,
  isLoading: false,
  error: null,
};

const reducer = (state: IUsersState = initialState, action: any): IUsersState => {
  switch (action.type) {
    case UsersActions.FETCH_USERS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case UsersActions.FETCH_USERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        items: null,
      };
    }
    case UsersActions.FETCH_USERS_SUCCESS: {
      const { users: responseItems } = action.payload || [];
      const items = [
        ...(responseItems && responseItems.length
          ? responseItems.map(({ user, integration }: any) => ({ ...user, integration }))
          : []),
      ];

      return {
        ...state,
        isLoading: false,
        error: null,
        items,
      };
    }
    case UsersActions.ADD_USER_REQUEST:
    case UsersActions.EDIT_USER_REQUEST: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case UsersActions.DELETE_USER_REQUEST: {
      return {
        ...state,
        error: null,
      };
    }
    case UsersActions.ADD_USER_FAILURE:
    case UsersActions.EDIT_USER_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }
    case UsersActions.DELETE_USER_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case UsersActions.ADD_USER_SUCCESS: {
      // TODO: uncomment these lines after the endpoint returns UID of our newly created user
      // const items = state.items ? state.items.concat(action.payload) : { ...action.payload };

      return {
        ...state,
        isLoading: false,
        // items,
      };
    }
    case UsersActions.EDIT_USER_SUCCESS: {
      const items = state.items ? state.items.slice() : null;

      const index = items?.findIndex((user: IUser) => user.uid === action.payload.uid);

      if (typeof index !== 'undefined' && index !== -1) {
        items?.splice(index, 1, action.payload);
      }

      return {
        ...state,
        isLoading: false,
        items,
      };
    }
    case UsersActions.DELETE_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        items: state.items ? state.items?.filter((user) => user.uid !== action.payload.userId) : null,
      };
    }
    case UsersActions.ADD_USER_DETAILS: {
      return {
        ...state,
        userDetails: {
          ...(state.userDetails ? state.userDetails : {}),
          ...action.payload,
        },
      };
    }
    case UsersActions.CLEAR_USER_DETAILS: {
      return {
        ...state,
        userDetails: null,
      };
    }
    case FirebaseActions.LOGOUT:
    case AuthActions.ADMIN_ROLE_CHANGED:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
