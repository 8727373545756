import cn from 'classnames';
import * as React from 'react';

import styles from './Avatar.module.scss';

interface IProps {
  image?: string;
  className?: string;
}

const Avatar = ({ image, className }: IProps) => (
  <div className={cn(styles.avatar, className)} style={{ backgroundImage: image ? `url(${image})` : 'none' }} />
);

export default Avatar;
