import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as IconCheck } from '../../../assets/images/icon-check.svg';
import { CRM_TYPE, RECEIVER_CRM_OPPORTUNITY_ID } from '../../../constants/shipping';
import { getOpportunitiesRequest, setOpportunity } from '../../../store/actions/integrations';
import { selectCRMRecordId } from '../../../store/selectors/bucket';
import { selectCrmOpportunities } from '../../../store/selectors/integrations';
import { selectCurrentOrganizationCRMType } from '../../../store/selectors/organizations';
import { IOpportunity } from '../../../types/crm';
import { ActionButton, BackButton } from '../../forms';
import ModalActions from '../ModalActions/ModalActions';
import ModalHeader from '../ModalHeader/ModalHeader';

import styles from './OpportunitiesModal.module.scss';

interface IProps {
  onClose: () => void;
  onLoad?: () => void;
  onChoose?: () => void;
}

const formatOpportunity = (selected: string) => ({ [RECEIVER_CRM_OPPORTUNITY_ID]: selected });

const OpportunitiesModal = ({ onClose, onLoad, onChoose }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selected, setSelected] = React.useState<string>('');
  const opportunities = useSelector(selectCrmOpportunities);
  const recordId = useSelector(selectCRMRecordId);
  const crmType = useSelector(selectCurrentOrganizationCRMType);

  React.useEffect(() => {
    if (!opportunities && !selected) {
      dispatch(getOpportunitiesRequest({ cid: recordId, [CRM_TYPE]: crmType }));
    }
  }, [dispatch, opportunities, recordId, selected]);

  React.useEffect(() => {
    if (typeof onLoad === 'function') {
      onLoad();
    }
  }, []);

  const storeOpportunity = React.useCallback(
    (selectedOpportunity: string) => dispatch(setOpportunity(formatOpportunity(selectedOpportunity))),
    [dispatch],
  );

  const submitOpportunity = React.useCallback(() => {
    if (selected) {
      if (typeof onChoose === 'function') {
        onChoose();
      }
      storeOpportunity(selected);
      onClose();
    }
  }, [selected, storeOpportunity, onClose]);

  const onBack = React.useCallback(() => {
    onClose();
  }, [onClose, history]);

  return (
    <div className={styles.container}>
      <ModalHeader
        className={styles.header}
        title="Select the opportunity"
        left={<BackButton className={styles.back} onClick={onBack} />}
      />
      <div className={styles.content}>
        <div className={styles.opportunitiesWrapper}>
          <div className={styles.opportunities}>
            {opportunities &&
              opportunities.records.length &&
              opportunities.records.map((item: IOpportunity) => (
                <button
                  key={item.Id}
                  className={cn(styles.item, { [styles.selected]: item.Id === selected })}
                  type="button"
                  onClick={() => setSelected(item.Id)}
                >
                  <span>{item.Name}</span>
                  {item.Id === selected && <IconCheck />}
                </button>
              ))}
          </div>
        </div>
      </div>
      <ModalActions className={styles.footer}>
        <ActionButton
          disabled={!opportunities || !selected}
          title="Continue"
          className={styles.button}
          onClick={submitOpportunity}
        />
      </ModalActions>
    </div>
  );
};

export default OpportunitiesModal;
