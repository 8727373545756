import cn from 'classnames';
import * as React from 'react';
import { animated, useTrail } from 'react-spring';

import { ICountryItem } from '../../types/shell';
import styles from './CountryList.module.scss';

interface IProps {
  name?: string;
  onSelect: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  items: ICountryItem[] | null;
}

const CountryList = ({ onSelect, items, name }: IProps) => {
  const trail = useTrail(items ? items.length : 0, { opacity: 1 });

  const results = React.useMemo(
    () =>
      items &&
      trail &&
      trail.map((props, i) => {
        const item = items[i];

        return (
          <animated.button
            style={props}
            key={item.name}
            className={cn(styles.item)}
            onClick={onSelect}
            type="button"
            name={name}
            value={item.two_digit_code}
          >
            <div className={cn(styles.caption)}>{item.name}</div>
          </animated.button>
        );
      }),
    [items, trail, name, onSelect],
  );

  const emptyResult = React.useMemo(
    () => <div className={cn(styles.item, styles.noResults)}>No results. Try another query, please.</div>,
    [],
  );

  return (
    <React.Fragment>
      {items === null ? null : <div className={cn(styles.list)}>{items.length ? results : emptyResult}</div>}
    </React.Fragment>
  );
};

export default CountryList;
