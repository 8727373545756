import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as EditButtonImage } from '../../../../assets/images/icon-edit-info.svg';

import styles from './LabelWithEditButton.module.scss';

export interface ILabelWithEditButtonProps {
  text: string | React.ReactNode | React.ReactNode[];
  onEdit?: () => void;
  className?: string;
  labelClassName?: string;
  buttonClassName?: string;
  isEditable?: boolean;
}

const LabelWithEditButton: React.FC<ILabelWithEditButtonProps> = ({
  text,
  onEdit,
  className,
  labelClassName,
  buttonClassName,
  isEditable,
}) => {
  const shouldShowEditButton = React.useMemo(() => isEditable && typeof onEdit === 'function', [isEditable, onEdit]);

  return (
    <div className={cn(styles.labelWithEditButton, className)}>
      <div className={cn(styles.labelText, labelClassName)}>{text}</div>
      {shouldShowEditButton && (
        <button type="button" onClick={onEdit}>
          <EditButtonImage className={cn(styles.editImage, buttonClassName)} />
        </button>
      )}
    </div>
  );
};

export default LabelWithEditButton;
