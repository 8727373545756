import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { ITEM_ID, MAX_INVENTORY_ITEMS_PER_PAGE } from '../../constants/inventories';
import {
  IAddInventoryItemRequest,
  IFetchInventoryItemResponse,
  IFetchInventoryItemsResponse,
  IFetchLowInventoryItemsResponse,
  IInventoriesState,
  IInventoryItem,
  ISetUploadInventoryCSVFilePayload,
  IUploadInventoryCSVResponsePayload,
} from '../../types/inventories';
import { convertArrayToObject } from '../../utils/helpers';
import * as AuthActions from '../actions/auth';
import * as InventoriesActions from '../actions/inventories';

export const initialState: IInventoriesState = {
  csvUpload: {
    file: null,
    result: null,
    isLoading: false,
  },
  items: null,
  lowInventoryItems: null,
  itemDetails: null,
  isLoading: false,
  isLowInventoryLoading: false,
  isInventoryReportLoading: false,
  error: null,
  isFinal: false,
  totalCount: 0,
};

const reducer = (state: IInventoriesState = initialState, action: any): IInventoriesState => {
  switch (action.type) {
    case InventoriesActions.FETCH_INVENTORY_ITEMS_REQUEST: {
      const { shouldClearItems } = action.metadata || {};
      return {
        ...state,
        isLoading: true,
        error: null,
        ...(shouldClearItems ? { items: null } : {}),
      };
    }
    case InventoriesActions.FETCH_INVENTORY_ITEMS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case InventoriesActions.FETCH_INVENTORY_ITEMS_SUCCESS: {
      const { inventory_items: items, total_count: totalCount } =
        (action.payload as IFetchInventoryItemsResponse) || {};

      const isFinal = !items || (Boolean(items.length) && items.length < MAX_INVENTORY_ITEMS_PER_PAGE);

      return {
        ...state,
        isLoading: false,
        error: null,
        totalCount,
        items: items && items.length ? convertArrayToObject<IInventoryItem, typeof ITEM_ID>(items, ITEM_ID) : {},
        isFinal,
      };
    }
    case InventoriesActions.FETCH_LOW_INVENTORY_ITEMS_REQUEST: {
      return {
        ...state,
        isLowInventoryLoading: true,
        error: null,
        lowInventoryItems: null,
      };
    }
    case InventoriesActions.FETCH_LOW_INVENTORY_ITEMS_FAILURE: {
      return {
        ...state,
        isLowInventoryLoading: false,
        error: action.payload,
      };
    }
    case InventoriesActions.FETCH_LOW_INVENTORY_ITEMS_SUCCESS: {
      const { low_inventory_items: lowInventoryItems } = (action.payload as IFetchLowInventoryItemsResponse) || {};

      return {
        ...state,
        isLowInventoryLoading: false,
        lowInventoryItems,
      };
    }
    case InventoriesActions.SET_INVENTORY_ITEM_TYPE: {
      return {
        ...state,
        itemDetails: {
          type: action.payload,
        },
      };
    }
    case InventoriesActions.ADD_INVENTORY_ITEM_VALUE: {
      return {
        ...state,
        itemDetails: {
          ...state.itemDetails,
          ...action.payload,
        },
      };
    }
    case InventoriesActions.ADD_INVENTORY_ITEM_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        itemDetails: (action.payload as IAddInventoryItemRequest).item,
      };
    }
    case InventoriesActions.ADD_INVENTORY_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case InventoriesActions.ADD_INVENTORY_ITEM_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case InventoriesActions.EDIT_INVENTORY_ITEM_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case InventoriesActions.EDIT_INVENTORY_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case InventoriesActions.EDIT_INVENTORY_ITEM_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case InventoriesActions.DELETE_INVENTORY_ITEM_SUCCESS: {
      if (state.items) {
        const { [action.payload.itemId]: _, ...restItems } = state.items;

        return {
          ...state,
          items: restItems,
        };
      }

      return state;
    }
    case InventoriesActions.FETCH_INVENTORY_ITEM_BY_ID_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case InventoriesActions.FETCH_INVENTORY_ITEM_BY_ID_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case InventoriesActions.FETCH_INVENTORY_ITEM_BY_ID_SUCCESS: {
      const { itemDetails } = state;
      const { inventory_item: item } = (action.payload as IFetchInventoryItemResponse) || {};

      return {
        ...state,
        items: {
          ...(state.items ? state.items : {}),
          ...{ [item.item_id]: item },
        },
        itemDetails:
          itemDetails && itemDetails.item_id === item.item_id
            ? { ...itemDetails, ...{ count: item.count } }
            : itemDetails,
      };
    }
    case InventoriesActions.CLEAR_INVENTORY_ITEMS: {
      return {
        ...state,
        items: null,
      };
    }
    case InventoriesActions.CLEAR_INVENTORY_ITEM_VALUE: {
      return {
        ...state,
        itemDetails: null,
      };
    }
    case InventoriesActions.DOWNLOAD_INVENTORY_REPORT_CSV_REQUEST: {
      return {
        ...state,
        isInventoryReportLoading: true,
        error: null,
      };
    }
    case InventoriesActions.DOWNLOAD_INVENTORY_REPORT_CSV_SUCCESS: {
      return {
        ...state,
        isInventoryReportLoading: false,
      };
    }
    case InventoriesActions.DOWNLOAD_INVENTORY_REPORT_CSV_FAILURE: {
      return {
        ...state,
        isInventoryReportLoading: false,
        error: action.payload,
      };
    }
    case InventoriesActions.SET_UPLOAD_INVENTORY_CSV_FILE: {
      return {
        ...state,
        csvUpload: {
          ...state.csvUpload,
          ...(action.payload as ISetUploadInventoryCSVFilePayload),
        },
      };
    }
    case InventoriesActions.UPLOAD_INVENTORY_CSV_REQUEST: {
      return {
        ...state,
        csvUpload: {
          ...state.csvUpload,
          result: null,
          isLoading: true,
        },
      };
    }
    case InventoriesActions.UPLOAD_INVENTORY_CSV_SUCCESS:
    case InventoriesActions.UPLOAD_INVENTORY_CSV_FAILURE: {
      return {
        ...state,
        csvUpload: {
          ...state.csvUpload,
          file: null,
          result: action.payload as IUploadInventoryCSVResponsePayload,
          isLoading: false,
        },
      };
    }
    case FirebaseActions.LOGOUT:
    case AuthActions.ADMIN_ROLE_CHANGED:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
