import cn from 'classnames';
import * as React from 'react';

import { UISizeEnum } from '../../types/shell';

import styles from './Loader.module.scss';

interface IProps {
  isLoading?: boolean | undefined;
  style?: any;
  className?: string;
  spinnerClassName?: string;
  size?: UISizeEnum;
}

const Loader = ({ className, spinnerClassName, isLoading = false, style, size = UISizeEnum.Normal }: IProps) => {
  return isLoading ? (
    <div className={cn(styles.loader, className)} style={style}>
      <div className={cn(styles.spinner, styles[size], spinnerClassName)} />
    </div>
  ) : null;
};

export default Loader;
