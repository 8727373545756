import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { DeadEndRoute, GuestRoute, PrivateRoute } from './components/routes';
import AdminRoute from './components/routes/AdminRoute';
import { IS_AI_SUGGEST_ENABLED } from './constants/ai';
import { routes } from './constants/routing';
import { DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from './constants/users';
import { LoginContainer, ModalContainer } from './containers';
import {
  AddressBook,
  AuthCallback,
  Automations,
  BusinessReasons,
  Campaigns,
  Dashboard,
  Engagement,
  EngagementsManager,
  Integrations,
  Inventory,
  OneLinkManager,
  Organizations,
  Reports,
  SignatureSetup,
  SuggestGift,
  Templates,
  Users,
} from './pages';
import { selectIsAutomationsEnabled } from './store/selectors/organizations';
import App from './views/App/App';

const Routes = () => {
  const isAutomationsEnabled = useSelector(selectIsAutomationsEnabled);

  return (
    <App>
      <Switch>
        <GuestRoute path={routes.login} component={LoginContainer} />
        <Route
          exact
          path={[routes.sfAuthorizeCallback, routes.hubspotAuthorizeCallback, routes.outlookAuthorizeCallback]}
          component={AuthCallback}
        />
        <PrivateRoute exact path={routes.dashboard} component={Dashboard} />
        <PrivateRoute exact path={routes.engagement.route} component={Engagement} />
        <PrivateRoute exact path={routes.integrations} component={Integrations} />
        <PrivateRoute exact path={routes.inventory.route} component={Inventory} />
        <PrivateRoute exact path={[routes.campaigns.root, routes.campaigns.route]} component={Campaigns} />
        <PrivateRoute exact path={routes.addressBook} component={AddressBook} />
        <PrivateRoute exact path={[routes.reports.root, routes.reports.route]} component={Reports} />
        <PrivateRoute exact path={[routes.templates.root, routes.templates.route]} component={Templates} />
        <PrivateRoute exact path={routes.businessReasons} component={BusinessReasons} />
        {isAutomationsEnabled && (
          <AdminRoute
            exact
            roles={[ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN]}
            path={routes.automations}
            component={Automations}
          />
        )}
        <AdminRoute
          exact
          roles={[DEPT_ADMIN, ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN]}
          path={[routes.users.root, routes.users.route]}
          component={Users}
        />
        <AdminRoute
          exact
          roles={[DISTRIBUTOR, SUPER_ADMIN]}
          path={[routes.organizations.root, routes.organizations.route]}
          component={Organizations}
        />
        <PrivateRoute exact path={routes.engagementsManager} component={EngagementsManager} />
        <AdminRoute
          exact
          roles={[DEPT_ADMIN, ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN]}
          path={[routes.oneLinkManager.root, routes.oneLinkManager.route]}
          component={OneLinkManager}
        />
        <PrivateRoute exact path={routes.signatureSetup} component={SignatureSetup} />
        {IS_AI_SUGGEST_ENABLED && <PrivateRoute exact path={routes.suggestGift} component={SuggestGift} />}
        <DeadEndRoute />
      </Switch>
      <ModalContainer />
    </App>
  );
};

export default Routes;
