import firebase from 'firebase';
import { actionTypes as FirebaseActions } from 'react-redux-firebase';
import { all, call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

// import * as AnalyticsActions from "../actions/analytics";
// import * as GAActions from "../actions/analytics";
import * as AuthUtils from '../../utils/auth';
import * as AuthActions from '../actions/auth';
import * as IntegrationsActions from '../actions/integrations';
import * as InventoriesActions from '../actions/inventories';
import * as OrganizationsActions from '../actions/organizations';
import * as ShellActions from '../actions/shell';

import { apiUrl, appType } from '../../config/app';
import { endpoints, routes } from '../../constants/routing';
import { IAuthenticateRequestPayload, IAuthorizeRequest } from '../../types/auth';
import { IReduxAction } from '../../types/redux';
import {
  selectAdminType,
  selectFirebaseProfile,
  selectIsAdminTypeUser,
  selectIsAuthenticated,
  selectIsDistributor,
  selectIsFirebaseProfileLoaded,
  selectIsOrgAdmin,
  selectIsSuperAdmin,
} from '../selectors/auth';
import { selectOrganizationsIsLoading } from '../selectors/organizations';
// import { FAILURE_REASONS } from "../../constants/analytics";
import { AuthRequest, firebaseSaga, routerSaga, selectorChangeSaga } from './helpers';

function* authenticateInGoogleSaga(action: IReduxAction<IAuthenticateRequestPayload>) {
  try {
    yield put(AuthActions.authenticateSuccess({ ...action.payload }));
    // }
  } catch (error) {
    // yield put(AnalyticsActions.trackException(error));
    yield put(AuthActions.authenticateFailure(error));
  }
}

function* authenticateFlowSaga(): Generator<any, any, any> {
  try {
    let response;

    // Starting the race between two actions for
    //  - users who are signing in - AUTHENTICATE_SUCCESS
    //  - users who are already signed in -  FIREBASE_LOGIN
    // because we don't know which flow will use the /Authorize call
    const [authenticate] = yield race([take(AuthActions.AUTHENTICATE_SUCCESS), take(FirebaseActions.LOGIN)]);
    // Sign in flow entry
    if (authenticate) {
      // User has come from LoginContainer
      // The possible values here are:
      // email & password - EmailAndPassword flow
      // Sign in providers - start sign in flow with supported auth providers
      const { providerId, email, password, pendingCredentials, resolve, reject } =
        (authenticate.payload as IAuthenticateRequestPayload) || {};

      // Sign in to Firebase with different conditions
      if (email && password) {
        response = yield call(AuthUtils.authenticateWithEmailAndPassword, {
          email,
          password,
        });
      } else {
        response = yield call(AuthUtils.authenticateWithPopup, providerId);
      }

      if (response) {
        if (response.error) {
          // Firebase login failed

          if (typeof reject === 'function') {
            yield call(reject, response.error);
          }
          yield put(AuthActions.authorizeUserFailure(response.error));
        } else {
          // Wait for login event occurs
          yield take([FirebaseActions.LOGIN, FirebaseActions.SET_PROFILE]);

          if (pendingCredentials?.credential && response.user) {
            // Linking accounts
            const user = response.user as firebase.User;
            user.linkWithCredential(pendingCredentials.credential);
          }

          // Call /Authorize request
          yield put(
            AuthActions.authorizeUserRequest({
              resolve,
              reject,
            }),
          );
        }
      } else {
        throw new Error('Authentication failed');
      }
    } else {
      // Firebase automatic login (e.g. we already have user credentials cached)
      yield put(AuthActions.authorizeUserRequest());
    }
  } catch (error) {
    // yield put(AnalyticsActions.trackException(error));
    yield put(AuthActions.authorizeUserFailure(error));
  }
}

export function* authorizeWorkerSaga(action: IReduxAction<IAuthorizeRequest>): Generator<any, any, any> {
  let { headers = {} } = action.payload || {};
  const { resolve, reject } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.authorize}`;
    headers = { ...headers, Type: appType };

    const response = yield call<any>(AuthRequest, { endpoint, headers, retry: false });

    if (!response.ok) {
      // const { uid } = yield select(selectFirebaseAuth);
      // yield put(
      //   AnalyticsActions.trackApiException({
      //     endpoint,
      //     reason: FAILURE_REASONS.UNAUTHORIZED,
      //     request: { uid },
      //     response: response.body,
      //   })
      // );
      yield put(AuthActions.authorizeUserFailure(response.body));
      if (typeof reject === 'function') {
        reject(response.body);
      }
    } else {
      if (response.status === 201) {
        /* There is small problem with custom firebase claims
         * It's the workaround to force update user custom claims
         * More information can be found on the page below
         * https://github.com/prescottprue/react-redux-firebase/issues/531
         */
        const token = yield call(AuthUtils.getIdToken, true);
        yield put(ShellActions.firebaseProxyReload());

        const reloadResult = yield race([
          take(FirebaseActions.AUTH_RELOAD_ERROR),
          take(FirebaseActions.AUTH_RELOAD_SUCCESS),
        ]);

        if (reloadResult.type === FirebaseActions.AUTH_UPDATE_ERROR) {
          yield put(ShellActions.firebaseProxyLogout());
        }

        if (token) {
          const profile = yield select(selectFirebaseProfile);
          yield put(AuthActions.firebaseProfileUpdate({ ...profile, ...{ token } }));
        }
      }
      yield put(AuthActions.authorizeUserSuccess(response.body));
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield call(postAuthorizeSaga);
      // yield put(GAActions.gaSetConfig({ userId: response.body.user_id }));
    }
  } catch (error) {
    // yield put(AnalyticsActions.trackException(error));
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(AuthActions.authorizeUserFailure(error));
  }
}

function* changeOrganizationWorkerSaga(action: any): Generator<any, any, any> {
  const { org_id, resolve, reject } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.changeOrganization}`;
    const response = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({ org_id }),
    });

    if (response.ok) {
      yield all([
        put(ShellActions.routerProxyPush(routes.dashboard)),
        put(AuthActions.changeOrganizationsSuccess()),
        put(AuthActions.authorizeUserRequest()),
      ]);

      if (typeof resolve === 'function') {
        resolve();
      }
    } else {
      yield put(AuthActions.changeOrganizationsFailure(response.body));
      if (typeof reject === 'function') {
        reject(response.body);
      }
    }
  } catch (error) {
    yield put(AuthActions.changeOrganizationsFailure(error));
    if (typeof reject === 'function') {
      reject(error);
    }
  }
}

function* getDigitalBudgetWorkerSaga(): Generator<any, any, any> {
  try {
    const endpoint = `${apiUrl}${endpoints.getAccountBalance}`;
    const response = yield call(AuthRequest, { endpoint });

    if (response.ok) {
      const { account_balance } = response.body || {};
      yield put(AuthActions.getDigitalBudgetSuccess(account_balance));
    } else {
      yield put(AuthActions.getDigitalBudgetFailure(response.body));
    }
  } catch (error) {
    yield put(AuthActions.getDigitalBudgetFailure(error));
  }
}

function* initAppSaga(action: any): Generator<any, any, any> {
  yield all([fork(firebaseSaga, action.payload), fork(routerSaga, action.payload)]);
}

function* setProfileSaga(action: any): Generator<any, any, any> {
  /* This saga can serve any movements that you
     need on FirebaseActions.SET_PROFILE fire */
  const adminType = yield select(selectAdminType);
  const isAuthenticated = yield select(selectIsAuthenticated);

  if (isAuthenticated && adminType !== action.profile.admin_type) {
    // This "if" fires when user's role is changed on firestore side
    // but still isn't updated in our claims, and seems like the only
    // method to refresh claims is to "re-authorize"
    yield put(AuthActions.adminRoleChanged());
    yield put(AuthActions.authorizeUserRequest());
    console.warn('Your role was changed, some functionality may become unavailable. Please contact admin for details.');
  }
}

function* postAuthorizeSaga(): Generator<any, any, any> {
  const isFirebaseProfileLoaded = yield select(selectIsFirebaseProfileLoaded);
  if (!isFirebaseProfileLoaded) {
    // Wait until firebase profile will be loaded and then the other logic is started
    yield call<any>(selectorChangeSaga, selectIsFirebaseProfileLoaded);
  }
  const isAnyAdmin = yield select(selectIsAdminTypeUser);
  const isSuperAdmin = yield select(selectIsSuperAdmin);
  const isOrgAdmin = yield select(selectIsOrgAdmin);
  const isDistributor = yield select(selectIsDistributor);
  const isOrganizationsFetching = yield select(selectOrganizationsIsLoading);

  yield all([
    put(ShellActions.fetchThemeRequest()),
    isAnyAdmin &&
      put(
        InventoriesActions.fetchLowInventoryItemsRequest(undefined, {
          canSnoozeNotification: true,
        }),
      ),
    put(IntegrationsActions.crmCheckStart()),
    put(IntegrationsActions.emailCheckStart()),
    (isSuperAdmin || isDistributor) &&
      !isOrganizationsFetching &&
      put(OrganizationsActions.fetchOrganizationsRequest()),
    (isSuperAdmin || isOrgAdmin || isDistributor) && put(AuthActions.getDigitalBudgetRequest()),
  ]);
}

const sagas = {
  *watchAuthenticateFlow() {
    yield takeLatest(
      [FirebaseActions.LOGOUT, ShellActions.INIT_APP, AuthActions.AUTHORIZE_USER_FAILURE],
      authenticateFlowSaga,
    );
  },
  *watchAuthorizeRequest() {
    yield takeLatest(AuthActions.AUTHORIZE_USER_REQUEST, authorizeWorkerSaga);
  },
  *watchAuthenticateRequest() {
    yield takeLatest(AuthActions.AUTHENTICATE_REQUEST, authenticateInGoogleSaga);
  },
  *watchFirebaseSagaInit() {
    yield takeLatest(ShellActions.INIT_APP, initAppSaga);
  },
  *watchChangeOrganizationsWatcher() {
    yield takeLatest(AuthActions.CHANGE_ORGANIZATION_REQUEST, changeOrganizationWorkerSaga);
  },
  *watchGetDigitalBudgetWatcher() {
    yield takeLatest(AuthActions.GET_DIGITAL_BUDGET_REQUEST, getDigitalBudgetWorkerSaga);
  },
  *watchSetProfileWatcher() {
    yield takeLatest(FirebaseActions.SET_PROFILE, setProfileSaga);
  },
};

export default sagas;
