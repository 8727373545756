import React from 'react';

import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning-red-outline.svg';
import { ActionButton } from '../forms';

import styles from './DeleteAllWarning.module.scss';

interface IProps {
  onSubmit: () => void;
  onCancel: () => void;
  message: React.ReactNode;
  submitLabel?: string;
  cancelLabel?: string;
}

const DeleteAllWarning: React.FC<IProps> = ({
  submitLabel = 'Delete all',
  cancelLabel = 'Cancel',
  message,
  onSubmit,
  onCancel,
}) => {
  return (
    <div className={styles.container}>
      <button className={styles.closeBtn} onClick={onCancel} />
      <div className={styles.warning}>
        <WarningIcon />
        <h2>Are you sure?</h2>
        {message}
      </div>
      <div className={styles.buttons}>
        <ActionButton className={styles.delete} onClick={onSubmit}>
          {submitLabel}
        </ActionButton>
        <ActionButton inline className={styles.cancel} onClick={onCancel}>
          {cancelLabel}
        </ActionButton>
      </div>
    </div>
  );
};

export default DeleteAllWarning;
