import Lottie, { LottieRef } from 'lottie-react';
import React from 'react';
import { JsonObject } from 'type-fest';

interface IProps {
  animationData: JsonObject;
  loop?: boolean;
  autoplay?: boolean;
  lottieRef?: LottieRef;
  className?: string;
}

const LottieAnimation = ({ animationData, loop = true, autoplay = true, lottieRef, className }: IProps) => (
  <Lottie
    lottieRef={lottieRef}
    animationData={animationData}
    loop={loop}
    autoplay={autoplay}
    style={{ width: '100%' }}
    className={className}
  />
);

export default LottieAnimation;
