import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DeleteItemModal } from '../../components';
import EditableTable from '../../components/EditableTable/EditableTable';
import { AddButton } from '../../components/forms';
import {
  BUSINESS_REASON_ITEM_LABEL,
  BUSINESS_REASON_TABLE_HEADERS,
  EMPTY_BUSINESS_REASON,
  NAME,
  UID,
} from '../../constants/reasons';
import { routes } from '../../constants/routing';
import { DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import useModal from '../../hooks/useModal';
import useSearchFilter from '../../hooks/useSearchFilter/useSearchFilter';
import useWindowSize from '../../hooks/useWindowSize';
import {
  addBusinessReasonRequest,
  changeReasonDetails,
  clearReasonDetails,
  deleteBusinessReasonRequest,
  editBusinessReasonRequest,
  fetchBusinessReasonsRequest,
} from '../../store/actions/reasons';
import { selectAdminType } from '../../store/selectors/auth';
import {
  selectBusinessReasonById,
  selectBusinessReasons,
  selectIsReasonDetailsLoading,
  selectReasonDetails,
} from '../../store/selectors/reasons';
import { EditableTableModes } from '../../types/forms';
import { IBusinessReason, IReasonsDetails } from '../../types/reasons';
import { IHeaderConfig, NotificationListEnum, SortOrder } from '../../types/shell';
import { ITableConfig } from '../../types/tables';
import notification from '../../utils/notification';
import { handleApiError } from '../../utils/ui';
import { hasPermission } from '../../utils/users';

import styles from './BusinessReasons.module.scss';

const BusinessReasons: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { mobile } = useWindowSize();
  const { setHeader, setAdditionalData } = useHeaderDispatch();

  const {
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
    Modal: DeleteModal,
  } = useModal<IBusinessReason | IReasonsDetails>();

  const adminType = useSelector(selectAdminType);
  const reasons = useSelector(selectBusinessReasons);
  const reasonDetails = useSelector(selectReasonDetails);
  const isReasonDetailsLoading = useSelector(selectIsReasonDetailsLoading);
  const originalReason = useSelector(selectBusinessReasonById(reasonDetails?.uid));

  const { SearchInput, filteredItems: filteredReasonsBySearch } = useSearchFilter(reasons || [], [NAME], {
    placeholder: 'Lookup business reasons',
    className: styles.search,
  });

  const isChanged = React.useMemo(
    () => !isEqual(originalReason, pick(reasonDetails, keys(originalReason))),
    [originalReason, reasonDetails],
  );

  const mode = React.useMemo(() => {
    const { uid, name } = reasonDetails || {};
    if (!uid && typeof name !== 'undefined') {
      return EditableTableModes.Add;
    }
    if (uid && typeof name !== 'undefined') {
      return EditableTableModes.Edit;
    }
    return undefined;
  }, [reasonDetails]);

  const sortedReasons = React.useMemo(() => {
    return orderBy(reasons, [(reason) => reason.name.toLowerCase()], [SortOrder.ASC]);
  }, [reasons]);

  const handleDeleteReason = React.useCallback(
    (uid: string) => {
      return new Promise((resolve, reject) => {
        dispatch(deleteBusinessReasonRequest({ uid, resolve, reject }));
      })
        .then(() => {
          notification.success(NotificationListEnum.Success, { content: 'Business reason was successfully deleted' });
        })
        .catch(handleApiError(`Something bad happened. Business reason wasn't deleted.`))
        .finally(() => {
          if (typeof closeDeleteModal === 'function') {
            closeDeleteModal();
          }
        });
    },
    [dispatch, closeDeleteModal],
  );

  const handleAddReason = React.useCallback(
    (reason: Omit<IReasonsDetails, 'uid'>) => {
      const { name } = reason || {};

      if (name) {
        return new Promise((resolve, reject) => {
          dispatch(addBusinessReasonRequest({ reason: { name }, resolve, reject }));
        })
          .then(() => {
            dispatch(fetchBusinessReasonsRequest());
          })
          .then(() => {
            notification.success(NotificationListEnum.Success, {
              content: `New ${BUSINESS_REASON_ITEM_LABEL} was added`,
            });
          })
          .catch(handleApiError('Something bad happened. New business reason was not added'));
      }
      notification.enterValidData('Business Reason');
      return Promise.reject();
    },
    [dispatch, reasonDetails],
  );

  const handleEditReason = React.useCallback(
    (businessReason: IBusinessReason | IReasonsDetails) => {
      if (businessReason && businessReason.uid) {
        const reason = businessReason as IBusinessReason;
        return new Promise((resolve, reject) => {
          dispatch(editBusinessReasonRequest({ reason, resolve, reject }));
        })
          .then(() => reason)
          .catch(handleApiError('Something bad happened. Business reason was not edited'));
      }
      notification.enterValidData();
      return Promise.reject();
    },
    [reasonDetails, dispatch],
  );

  const handleSubmit = React.useMemo(() => {
    switch (true) {
      case mode === EditableTableModes.Add:
        return handleAddReason;
      case mode === EditableTableModes.Edit:
        return handleEditReason;
      default:
        return Promise.reject;
    }
  }, [mode, handleEditReason, handleAddReason]);

  const handleReasonInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(changeReasonDetails({ [e.target.name]: e.target.value }));
    },
    [dispatch],
  );

  const handleAddClick = React.useCallback(() => {
    if (reasonDetails?.uid) {
      dispatch(clearReasonDetails());
    }

    dispatch(changeReasonDetails(EMPTY_BUSINESS_REASON));
  }, [reasonDetails]);

  const handleEditClick = React.useCallback(
    (item: IBusinessReason | IReasonsDetails) => {
      dispatch(changeReasonDetails(item));
    },
    [dispatch],
  );

  const deleteItemModal = React.useMemo(
    () => (
      <DeleteModal className="common-modal">
        {(reason: IBusinessReason) => {
          return (
            <DeleteItemModal
              assetName={BUSINESS_REASON_ITEM_LABEL}
              onDelete={() => handleDeleteReason(reason.uid)}
              onClose={closeDeleteModal}
            />
          );
        }}
      </DeleteModal>
    ),
    [closeDeleteModal, DeleteModal, handleDeleteReason],
  );

  const fetchBusinessReasons = React.useCallback(() => dispatch(fetchBusinessReasonsRequest()), [dispatch]);

  const clearReason = React.useCallback(() => {
    dispatch(clearReasonDetails());
  }, [dispatch]);

  const onCancelClick = React.useCallback(() => {
    clearReason();
  }, [clearReason]);

  const setHeaderInfo = React.useCallback(
    (headerInfo: Partial<IHeaderConfig>) => {
      if (typeof setHeader === 'function') {
        setHeader(headerInfo);
      }
    },
    [setHeader],
  );

  const readOnly = React.useMemo(() => !hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], adminType), [adminType]);

  const renderAddButton = React.useMemo(
    () =>
      !readOnly && (
        <AddButton assetName={BUSINESS_REASON_ITEM_LABEL} className={styles.addButton} onClick={handleAddClick} />
      ),
    [readOnly, handleAddClick],
  );

  React.useEffect(() => {
    if (!setAdditionalData) {
      return;
    }

    setAdditionalData(mobile ? renderAddButton : null);

    // Need to clean additional information  after yourself
    return setAdditionalData;
  }, [mobile, renderAddButton, setAdditionalData]);

  React.useEffect(() => {
    setHeaderInfo({ title: 'Business reasons', action: () => history.push(routes.dashboard) });
  }, [history]);

  React.useEffect(() => {
    fetchBusinessReasons();
    return clearReason;
  }, []);

  const tableConfig: ITableConfig<IBusinessReason> = React.useMemo(
    () => ({
      columns: {
        headers: !mobile ? BUSINESS_REASON_TABLE_HEADERS : undefined,
        visible: [NAME],
      },
      cells: {
        inputs: {
          [NAME]: {
            props: () => ({
              placeholder: `Input ${BUSINESS_REASON_ITEM_LABEL}`,
            }),
          },
        },
      },
    }),
    [mobile],
  );

  return (
    <div className={styles.businessReasons}>
      <div className={styles.main}>
        <div className={styles.tableControls}>
          {SearchInput}
          {!mobile && renderAddButton}
        </div>
        <EditableTable<IBusinessReason | IReasonsDetails>
          rowClassName={styles.row}
          mode={mode}
          items={filteredReasonsBySearch || sortedReasons}
          onChange={handleReasonInputChange}
          onEdit={handleEditClick}
          onCancel={onCancelClick}
          onSave={handleSubmit}
          onDelete={openDeleteModal}
          isLoading={isReasonDetailsLoading}
          readOnly={readOnly}
          isChanged={isChanged}
          activeItem={reasonDetails}
          checkEditMode={(item: IBusinessReason | IReasonsDetails) => item[UID] === reasonDetails?.uid}
          placeholderLabel="No business reasons yet"
          config={tableConfig}
        />
      </div>
      {deleteItemModal}
    </div>
  );
};

export default BusinessReasons;
