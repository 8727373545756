import { createSelector } from 'reselect';
import { DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN, USER_INITIAL_VALUES } from '../../constants/users';
import { IUser } from '../../types/users';
import { hasPermission } from '../../utils/users';
import { IAppState } from '../reducers';
import { selectAdminType } from './auth';

export const selectRootState = (state: IAppState) => state.users;

export const selectUsers = createSelector(selectRootState, (users): IUser[] | undefined | null =>
  users && users.items ? users.items : undefined,
);

export const selectIsUsersFetching = createSelector(selectRootState, (users) => (users ? users.isLoading : false));

export const selectUsersIsLoaded = createSelector(
  selectUsers,
  selectIsUsersFetching,
  (users, isLoading) => users && !isLoading,
);

export const selectCanEditUser = createSelector(selectAdminType, (adminType) =>
  hasPermission([SUPER_ADMIN, DISTRIBUTOR, ORG_ADMIN], adminType),
);

export const selectUserDetails = createSelector(selectRootState, (users) => (users ? users.userDetails : undefined));

export const selectUserById = (uid: string) =>
  createSelector(selectUsers, (users) => {
    return users ? users.find((u) => u.uid === uid) : undefined;
  });

export const selectUserFormInitialValues = (uid: string) =>
  createSelector(selectUserById(uid), (userDetails) => {
    const { distributor_org_ids, ...rest } = userDetails || {};
    return { ...USER_INITIAL_VALUES, distributor_org_ids: distributor_org_ids || [], ...rest };
  });
