import { call, put, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { endpoints, queryParams } from '../../constants/routing';
import { IReduxAction } from '../../types/redux';
import * as AutomationActions from '../actions/automations';
import { AuthRequest } from './helpers';

import {
  IAddAutomatedCampaignActionPayload,
  IAddAutomatedConnectionActionPayload,
  IDeleteAutomatedCampaignActionPayload,
  IDeleteAutomatedConnectionActionPayload,
  IUpdateAutomatedCampaignActionPayload,
  IUpdateAutomatedConnectionRequestActionPayload,
} from '../../types/automations';

function* addAutomatedConnectionWorkerSaga(
  action: IReduxAction<IAddAutomatedConnectionActionPayload>,
): Generator<any, any, any> {
  const { automatedConnection, orgId, resolve, reject } = action.payload!;
  const qs = { [queryParams.orgId]: orgId };

  try {
    const endpoint = `${apiUrl}${endpoints.addAutomationConnection}`;

    const response = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      queryParams: qs,
      body: JSON.stringify({ ...automatedConnection }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(AutomationActions.addAutomatedConnectionFailure(response.body));
    } else {
      yield put(AutomationActions.addAutomatedConnectionSuccess({ ...automatedConnection, orgId, ...response.body }));

      if (typeof resolve === 'function') {
        resolve();
      }
    }
  } catch (e) {
    if (typeof reject === 'function') {
      reject();
    }
    yield put(AutomationActions.addAutomatedConnectionFailure(e));
  }
}

function* updateAutomatedConnectionWorkerSaga(
  action: IReduxAction<IUpdateAutomatedConnectionRequestActionPayload>,
): Generator<any, any, any> {
  const { automatedConnection, orgId, resolve, reject } = action.payload!;
  const qs = { [queryParams.orgId]: orgId };

  try {
    const endpoint = `${apiUrl}${endpoints.updateAutomationConnection}`;

    const response = yield call(AuthRequest, {
      endpoint,
      method: 'PUT',
      queryParams: qs,
      body: JSON.stringify({ ...automatedConnection }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(AutomationActions.updateAutomatedConnectionFailure(response.body));
    } else {
      yield put(AutomationActions.updateAutomatedConnectionSuccess({ ...automatedConnection, orgId }));
      if (typeof resolve === 'function') {
        resolve();
      }
    }
  } catch (e) {
    if (typeof reject === 'function') {
      reject(e);
    }
    yield put(AutomationActions.updateAutomatedConnectionFailure(e));
  }
}

function* deleteAutomatedConnectionWorkerSaga(
  action: IReduxAction<IDeleteAutomatedConnectionActionPayload>,
): Generator<any, any, any> {
  const { id, orgId, resolve, reject } = action.payload!;

  try {
    const endpoint = `${apiUrl}${endpoints.deleteAutomationConnection}`;
    const qs = { [queryParams.uid]: id, [queryParams.orgId]: orgId };

    const response = yield call(AuthRequest, { endpoint, method: 'DELETE', queryParams: qs });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(AutomationActions.deleteAutomatedConnectionFailure(response.body));
    } else {
      yield put(AutomationActions.deleteAutomatedConnectionSuccess({ id, orgId }));
      if (typeof resolve === 'function') {
        resolve();
      }
    }
  } catch (e) {
    if (typeof reject === 'function') {
      reject(e);
    }
    yield put(AutomationActions.updateAutomatedConnectionFailure(e));
  }
}

function* automatedCampaignsWorkerSaga(): Generator<any, any, any> {
  try {
    const endpoint = `${apiUrl}${endpoints.automatedCampaigns}`;

    const response = yield call<any>(AuthRequest, { endpoint });

    if (!response.ok) {
      yield put(AutomationActions.fetchAutomatedCampaignsFailure(response.body));
    } else {
      yield put(AutomationActions.fetchAutomatedCampaignsSuccess(response.body));
    }
  } catch (error) {
    yield put(AutomationActions.fetchAutomatedCampaignsFailure(error));
  }
}

function* addAutomatedCampaignWorkerSaga(
  action: IReduxAction<IAddAutomatedCampaignActionPayload>,
): Generator<any, any, any> {
  const { automatedCampaign, resolve, reject } = action.payload!;

  try {
    const endpoint = `${apiUrl}${endpoints.addAutomatedCampaign}`;

    const response = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({ ...automatedCampaign }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(AutomationActions.addAutomatedCampaignFailure(response.body));
    } else {
      yield put(AutomationActions.addAutomatedCampaignSuccess({ ...automatedCampaign, ...response.body }));

      if (typeof resolve === 'function') {
        resolve();
      }
    }
  } catch (e) {
    if (typeof reject === 'function') {
      reject();
    }
    yield put(AutomationActions.addAutomatedCampaignFailure(e));
  }
}

function* updateAutomatedCampaignWorkerSaga(
  action: IReduxAction<IUpdateAutomatedCampaignActionPayload>,
): Generator<any, any, any> {
  const { automatedCampaign, resolve, reject } = action.payload!;

  try {
    const endpoint = `${apiUrl}${endpoints.updateAutomationCampaign}`;

    const response = yield call(AuthRequest, {
      endpoint,
      method: 'PUT',
      body: JSON.stringify({ ...automatedCampaign }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(AutomationActions.updateAutomatedCampaignFailure(response.body));
    } else {
      yield put(AutomationActions.updateAutomatedCampaignSuccess(automatedCampaign));
      if (typeof resolve === 'function') {
        resolve();
      }
    }
  } catch (e) {
    if (typeof reject === 'function') {
      reject(e);
    }
    yield put(AutomationActions.updateAutomatedCampaignFailure(e));
  }
}

function* deleteAutomatedCampaignWorkerSaga(
  action: IReduxAction<IDeleteAutomatedCampaignActionPayload>,
): Generator<any, any, any> {
  const { id, resolve, reject } = action.payload!;

  try {
    const endpoint = `${apiUrl}${endpoints.deleteAutomatedCampaign}`;
    const qs = { [queryParams.uid]: id };

    const response = yield call(AuthRequest, { endpoint, method: 'DELETE', queryParams: qs });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(AutomationActions.deleteAutomatedCampaignFailure(response.body));
    } else {
      yield put(AutomationActions.deleteAutomatedCampaignSuccess({ id }));
      if (typeof resolve === 'function') {
        resolve();
      }
    }
  } catch (e) {
    if (typeof reject === 'function') {
      reject(e);
    }
    yield put(AutomationActions.updateAutomatedCampaignFailure(e));
  }
}

const sagas = {
  *watchAddAutomatedConnectionRequest() {
    yield takeLatest(AutomationActions.ADD_AUTOMATED_CONNECTION_REQUEST, addAutomatedConnectionWorkerSaga);
  },
  *watchUpdateAutomatedConnectionRequest() {
    yield takeLatest(AutomationActions.UPDATE_AUTOMATED_CONNECTION_REQUEST, updateAutomatedConnectionWorkerSaga);
  },
  *watchDeleteAutomatedConnectionRequest() {
    yield takeLatest(AutomationActions.DELETE_AUTOMATED_CONNECTION_REQUEST, deleteAutomatedConnectionWorkerSaga);
  },
  *watchAutomatedCampaignsRequest() {
    yield takeLatest(AutomationActions.FETCH_AUTOMATED_CAMPAIGNS_REQUEST, automatedCampaignsWorkerSaga);
  },
  *watchAddAutomatedCampaignRequest() {
    yield takeLatest(AutomationActions.ADD_AUTOMATED_CAMPAIGN_REQUEST, addAutomatedCampaignWorkerSaga);
  },
  *watchUpdateAutomatedCampaignRequest() {
    yield takeLatest(AutomationActions.UPDATE_AUTOMATED_CAMPAIGN_REQUEST, updateAutomatedCampaignWorkerSaga);
  },
  *watchDeleteAutomatedCampaignRequest() {
    yield takeLatest(AutomationActions.DELETE_AUTOMATED_CAMPAIGN_REQUEST, deleteAutomatedCampaignWorkerSaga);
  },
};

export default sagas;
