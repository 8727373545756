import * as React from 'react';

interface IRefObject {
  current: any;
}

export default (ref: IRefObject, callback: (_?: any) => void) => {
  const handleClickOutside = React.useCallback(
    (event: Event) => {
      if (ref.current && !ref.current.contains(event.target) && callback) {
        callback();
      }
    },
    [ref, callback],
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
};
