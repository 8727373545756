import { createSelector } from 'reselect';

import {
  IOneLink,
  IOneLinkSelectorMetadata,
  IOneLinkSelectorMetadataWithUserId,
  OneLinkStatusEnum,
} from '../../types/oneLink';
import { FilterTypesEnum } from '../../types/shell';
import { sortByCreatedAt } from '../../utils/helpers';
import { IAppState } from '../reducers';
import { selectUserDepartmentId, selectUserInfo } from './auth';

export const selectRootState = (state: IAppState) => state.oneLink;

export const selectOneLinkSends = (status: OneLinkStatusEnum) =>
  createSelector(selectRootState, (oneLink): IOneLink[] | undefined | null => {
    const isNotActive = status === OneLinkStatusEnum.NotActive;

    if (isNotActive) {
      return oneLink && oneLink[OneLinkStatusEnum.NotActive]
        ? Object.values(oneLink[OneLinkStatusEnum.NotActive] || {})
        : undefined;
    }
    return oneLink && oneLink[OneLinkStatusEnum.Active]
      ? Object.values(oneLink[OneLinkStatusEnum.Active] || {})
      : undefined;
  });

export const selectGeneralOneLinksByUserId = ({ userId, filter, status }: IOneLinkSelectorMetadataWithUserId) =>
  createSelector(selectOneLinkSends(status), selectUserDepartmentId, (oneLinkSends, userDepartmentId) =>
    oneLinkSends
      ? oneLinkSends
          .filter((el) => (filter === FilterTypesEnum.Department ? el.department_id === userDepartmentId : true))
          .filter((el) => (userId ? el.user_id === userId : true))
          .sort(sortByCreatedAt)
      : null,
  );

export const selectFilteredOneLinkSends = ({ filter, status }: IOneLinkSelectorMetadata) =>
  createSelector(
    (state: IAppState) => state,
    (state: IAppState) => {
      const isGeneral = filter !== FilterTypesEnum.Individual;
      return isGeneral
        ? selectGeneralOneLinksByUserId({ filter, status })(state)
        : selectSelfOneLinkSends(status)(state);
    },
  );

export const selectSelfOneLinkSends = (status: OneLinkStatusEnum) =>
  createSelector(
    (state: any) => state,
    selectUserInfo,
    (state, userInfo) => {
      if (!userInfo?.user_id) {
        return null;
      }

      return selectGeneralOneLinksByUserId({ userId: userInfo.user_id, status })(state);
    },
  );

export const selectOneLinkById = (uid: string, status: OneLinkStatusEnum) =>
  createSelector(selectRootState, (oneLinks) => (oneLinks && oneLinks[status] ? oneLinks[status]?.[uid] : null));

export const selectIsOneLinksFetching = createSelector(selectRootState, (oneLink) => oneLink.isLoading);

export const selectIsSingleOneLinkFetching = createSelector(selectRootState, (oneLink) => oneLink.isLoadingSingle);

export const selectIsFinalOneLink = createSelector(selectRootState, (oneLink) => oneLink.isFinal);

export const selectOneLinkRecipients = createSelector(selectRootState, (oneLink) => oneLink.recipients);

export const selectOneLinkTotalCount = createSelector(selectRootState, (oneLink) => {
  return oneLink ? oneLink.totalCount : 0;
});

export const selectOneLinkSummaryByIDShippingConfiguration = (uid: string, status: OneLinkStatusEnum) =>
  createSelector(selectOneLinkById(uid, status), (oneLink) => {
    if (!oneLink) {
      return {};
    }

    const { is_receiver_address_fixed, org_address_ids, receiver_fixed_address } = oneLink;

    return {
      is_receiver_address_fixed,
      org_address_ids,
      receiver_fixed_address,
    };
  });
