import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as DropdownArrow } from '../../../../assets/images/icon-arrow-down.svg';
import { BUTTON_BUTTON } from '../../../../types/forms';

import styles from './ControlButton.module.scss';

interface IProps {
  label: string;
  onClick?: () => void;
  isOpened: boolean;
  readOnly?: boolean;
}

const ControlButton = React.forwardRef(
  ({ label, onClick, isOpened, readOnly }: IProps, ref: React.Ref<HTMLButtonElement>) => (
    <button
      ref={ref}
      onClick={onClick}
      className={cn(styles.toggle, { [styles.opened]: isOpened })}
      type={BUTTON_BUTTON}
      disabled={readOnly}
    >
      {label}
      {!readOnly && <DropdownArrow className={styles.icon} />}
    </button>
  ),
);

export default ControlButton;
