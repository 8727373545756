import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as DropdownIcon } from '../assets/images/icon-dropdown-indicator.svg';
import DropdownWithContent, { TDropdownProps } from '../components/DropdownWithContent/DropdownWithContent';
import FulfillmentCenterSelect, {
  IFulfillmentCenterSelectProps,
} from '../components/forms/inputs/FulfillmentCenterSelect/FulfillmentCenterSelect';
import { selectFulfillmentCenterItems } from '../store/selectors/fulfillmentCenters';
import { IUseFilterSelectorContainerProp } from '../types/shell';

interface IFulfillmentCenterFilterProp extends IFulfillmentCenterSelectProps {
  selectContainer?: React.FC<IUseFilterSelectorContainerProp>;
  children?: React.ReactNode;
}

export default function useFulfillmentCenterFilter<T>(items: T[], fieldName: keyof T, props: TDropdownProps) {
  const [selectedFulfillmentCenters, setSelectedFulfillmentCenters] = React.useState<string | string[] | null>(null);
  const [candidate, setCandidate] = React.useState<string | string[] | null>(null);

  const storedFulfillmentCenterItems = useSelector(selectFulfillmentCenterItems);

  const fulfillmentCenterItems = React.useMemo(
    () => storedFulfillmentCenterItems || [],
    [storedFulfillmentCenterItems],
  );

  const FulfillmentCenterFilter: React.FC<IFulfillmentCenterFilterProp> = React.useMemo(
    () =>
      ({ isFiltered = false, selectContainer, isMulti = false, ...selectProp }) => {
        // Use default container which does nothing if selectContainer is unset
        const SelectorContainer: React.FC<IUseFilterSelectorContainerProp> =
          selectContainer || (({ children }) => <React.Fragment>{children}</React.Fragment>);

        const resetToPreviousValue = () => setCandidate(selectedFulfillmentCenters);

        const fulfillmentCenterList = isFiltered
          ? fulfillmentCenterItems?.filter((fc) => fc.has_org_inventory)
          : fulfillmentCenterItems;

        const label = () => {
          const value =
            selectedFulfillmentCenters && fulfillmentCenterList
              ? fulfillmentCenterList
                  .filter((fc) => selectedFulfillmentCenters.includes(fc.uid))
                  .map(({ name }) => name)
                  .join(', ')
              : '';

          return (
            <React.Fragment>
              <span>{value || `Fulfillment Center (${fulfillmentCenterList.length})`}</span>
              <DropdownIcon />
            </React.Fragment>
          );
        };

        const handleOnChangeSelect = (selectValue: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = selectValue.currentTarget || {};
          setCandidate(value);

          if (!isMulti) {
            setSelectedFulfillmentCenters(value);
          }
        };

        return (
          <DropdownWithContent {...props} label={label} onOpen={resetToPreviousValue}>
            {({ close, isOpened }) => (
              <SelectorContainer
                close={close}
                isOpened={isOpened}
                select={() => {
                  setSelectedFulfillmentCenters(candidate);
                  close();
                }}
                reset={resetToPreviousValue}
              >
                <FulfillmentCenterSelect
                  {...selectProp}
                  isMulti={isMulti}
                  placeholder="Search fulfillment center"
                  value={candidate}
                  onChange={handleOnChangeSelect}
                  menuIsOpen
                  isFiltered={isFiltered}
                />
              </SelectorContainer>
            )}
          </DropdownWithContent>
        );
      },
    [fulfillmentCenterItems, selectedFulfillmentCenters, candidate, props],
  );

  const filteredItems = React.useMemo(() => {
    if (!selectedFulfillmentCenters) {
      return items || [];
    }

    return items
      ? items.filter((item) => {
          if (Array.isArray(item[fieldName])) {
            // FIXME: It's a quiz! Remove `any` if you can
            return (item[fieldName] as any).some((id: string) => selectedFulfillmentCenters.includes(id));
          }

          if (typeof item[fieldName] === 'string') {
            // FIXME: It's a quiz! Remove `any` if you can
            return selectedFulfillmentCenters.includes(item[fieldName] as any);
          }

          return true;
        })
      : [];
  }, [selectedFulfillmentCenters, items, fieldName]);

  return {
    fulfillmentCenters: selectedFulfillmentCenters,
    setFulfillmentCenters: setSelectedFulfillmentCenters,
    filteredItems,
    FulfillmentCenterFilter,
  };
}
