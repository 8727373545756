import { ILowInventoryNotificationsContent, IRemovePIIModalPayload } from '../../types/modals';
import { IDownloadCSVSuccessPayload } from '../../types/shell';

export const TOGGLE_RESET_PASSWORD_MODAL = 'TOGGLE_RESET_PASSWORD_MODAL';
export const TOGGLE_ROCKET_MODAL = 'TOGGLE_ROCKET_MODAL';
export const TOGGLE_OPPORTUNITIES_MODAL = 'TOGGLE_OPPORTUNITIES_MODAL';
export const TOGGLE_INTEGRATION_WARNING_MODAL = 'TOGGLE_INTEGRATION_WARNING_MODAL';
export const TOGGLE_DOWNLOAD_SUCCESS_MODAL = 'TOGGLE_DOWNLOAD_SUCCESS_MODAL';
export const TOGGLE_LOW_INVENTORY_MODAL = 'TOGGLE_LOW_INVENTORY_MODAL';
export const TOGGLE_REMOVE_PII_MODAL = 'TOGGLE_REMOVE_PII_MODAL';

export const toggleResetPasswordModal = (payload?: boolean) => ({
  type: TOGGLE_RESET_PASSWORD_MODAL,
  payload,
});

export const toggleRocketModal = (payload?: any, metadata?: any) => ({
  type: TOGGLE_ROCKET_MODAL,
  payload,
  metadata,
});

export const toggleOpportunitiesModal = (payload?: any) => ({
  type: TOGGLE_OPPORTUNITIES_MODAL,
  payload,
});

export const toggleIntegrationWarning = (payload?: any) => ({
  type: TOGGLE_INTEGRATION_WARNING_MODAL,
  payload,
});

export const toggleDownloadSuccessModal = (payload?: IDownloadCSVSuccessPayload) => ({
  type: TOGGLE_DOWNLOAD_SUCCESS_MODAL,
  payload,
});

export const toggleLowInventoryModal = (payload: boolean, metadata?: null | ILowInventoryNotificationsContent) => ({
  type: TOGGLE_LOW_INVENTORY_MODAL,
  payload,
  metadata,
});

export const toggleRemovePIIModal = (payload: boolean, metadata?: IRemovePIIModalPayload) => ({
  type: TOGGLE_REMOVE_PII_MODAL,
  payload,
  metadata,
});
