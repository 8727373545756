import { call, put, select, take, takeLatest } from 'redux-saga/effects';

import { apiUrl, hubspotUrl, outlookUrl, sfUrl } from '../../config/app';
import { BLUEBIRD, GMAIL, HUBSPOT, NONE, OUTLOOK, SALESFORCE } from '../../constants/integrations';
import { crmEndpoints, endpoints, routes } from '../../constants/routing';
import { ISearchLeadsAndContactsPayload } from '../../types/integrations';
import { IPromiseCallbacks, IReduxAction } from '../../types/redux';
import { googleAuthorize } from '../../utils/google';
import { PopupProvider } from '../../utils/PopupProvider';
import * as IntegrationsActions from '../actions/integrations';
import { selectCrmClientId } from '../selectors/integrations';
import { selectCurrentOrganizationCRMType, selectCurrentOrganizationEmailType } from '../selectors/organizations';
import { AuthRequest } from './helpers';

function* crmCheckFlowSaga(): Generator<any, any, any> {
  const crmType = yield select(selectCurrentOrganizationCRMType);

  switch (crmType) {
    case HUBSPOT: {
      yield put(IntegrationsActions.checkHubspotAuthRequest());
      yield take([IntegrationsActions.CHECK_HUBSPOT_AUTH_SUCCESS, IntegrationsActions.CHECK_HUBSPOT_AUTH_FAILURE]);
      break;
    }
    case SALESFORCE: {
      yield put(IntegrationsActions.checkSalesForceAuthRequest());
      yield take([IntegrationsActions.CHECK_SF_AUTH_SUCCESS, IntegrationsActions.CHECK_SF_AUTH_FAILURE]);
      break;
    }
    case NONE:
    default:
      break;
  }
  yield put(IntegrationsActions.crmCheckEnd());
}

function* checkSalesforceAuthWorkerSaga(): Generator<any, any, any> {
  try {
    const endpoint = `${apiUrl}${endpoints.checkSFAuth}`;

    const response = yield call<any>(AuthRequest, { endpoint });
    if (!response.ok) {
      yield put(IntegrationsActions.checkSalesForceAuthFailure(response.body));
      // yield put(
      //   AnalyticsActions.trackApiException({
      //     endpoint,
      //     reason: FAILURE_REASONS.REQUEST_FAILED,
      //     response: response.body,
      //   }),
      // );
    } else {
      yield put(IntegrationsActions.checkSalesForceAuthSuccess(response.body));
    }
  } catch (error) {
    // yield put(trackException(error));
    yield put(IntegrationsActions.checkSalesForceAuthFailure(error));
  }
}

export function* crmStartOAuthFlowWorkerSaga(action?: IReduxAction<IPromiseCallbacks>): Generator<any, any, any> {
  const crmType = yield select(selectCurrentOrganizationCRMType);

  switch (crmType) {
    case SALESFORCE: {
      yield call(sfOAuthFlowWorkerSaga, action);
      break;
    }
    case HUBSPOT: {
      yield call(hubspotOAuthFlowWorkerSaga, action);
      break;
    }
    case NONE:
    default:
      yield put(IntegrationsActions.crmOAuthEnd());
  }
}

export function* sfOAuthFlowWorkerSaga(action?: IReduxAction<IPromiseCallbacks>): Generator<any, any, any> {
  const { resolve, reject } = action?.payload || {};

  try {
    const clientId = yield select(selectCrmClientId);
    if (clientId) {
      const redirectUrl = `${window.location.origin}${routes.sfAuthorizeCallback}`;
      const url = `${sfUrl}${crmEndpoints.authorizeSF}?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code`;

      const popupResponse = yield call<any>(new PopupProvider().openPopUpInWindow, { url });

      if (!popupResponse.ok) {
        yield put(IntegrationsActions.crmOAuthFailure(popupResponse.error));
        if (typeof reject === 'function') {
          reject(popupResponse.error);
        }
      } else {
        const authResponse = yield call<any>(AuthRequest, {
          endpoint: `${apiUrl}${endpoints.exchangeSfAuthCode}`,
          headers: { redirect_url: redirectUrl, auth_code: popupResponse.data },
        });
        if (!authResponse.ok) {
          yield put(IntegrationsActions.crmOAuthFailure(authResponse.error));
          if (typeof reject === 'function') {
            reject(authResponse.error);
          }
        } else {
          if (typeof resolve === 'function') {
            resolve();
          }

          yield put(IntegrationsActions.crmOAuthSuccess(authResponse.body));
          yield put(IntegrationsActions.checkSalesForceAuthRequest());
        }
      }
    }
  } catch (e) {
    // yield put(trackException(e));
    yield put(IntegrationsActions.crmOAuthFailure(e));
    if (typeof reject === 'function') {
      reject(e);
    }
  } finally {
    yield put(IntegrationsActions.crmOAuthEnd());
  }
}

function* searchSaga(action: IReduxAction<ISearchLeadsAndContactsPayload>): Generator<any, any, any> {
  const { query: q, limit = 10, crm_type, signal, resolve, reject } = action.payload || {};

  try {
    const queryParams = { q, limit };

    const endpoint = `${apiUrl}${endpoints.getLeadsAndContacts}`;

    const response = yield call<any>(AuthRequest, { endpoint, headers: { crm_type }, queryParams, signal });
    if (!response.ok) {
      if (reject) {
        reject(response.body);
      }
      yield put(IntegrationsActions.searchLeadsAndContactsFailure(response.body));
    } else {
      if (resolve) {
        resolve(response.body);
      }
      yield put(IntegrationsActions.searchLeadsAndContactsSuccess(response.body));
    }
  } catch (error) {
    if (reject) {
      reject(error);
    }
    yield put(IntegrationsActions.searchLeadsAndContactsFailure(error));
  }
}

export function* opportunitiesSaga(action: any): Generator<any, any, any> {
  const { cid, crm_type } = action.payload || {};
  const { resolve, reject } = action.options || {};

  try {
    const endpoint = `${apiUrl}${endpoints.getOpportunities}`;
    const queryParams = { cid };

    const response = yield call<any>(AuthRequest, { endpoint, queryParams, headers: { crm_type } });
    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(IntegrationsActions.getOpportunitiesFailure(response.body));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(IntegrationsActions.getOpportunitiesSuccess(response.body));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(IntegrationsActions.getOpportunitiesFailure(error));
  }
}

function* checkHubspotAuthWorkerSaga(): Generator<any, any, any> {
  try {
    const crmType = yield select(selectCurrentOrganizationCRMType);
    const isHubspotSupported = crmType === HUBSPOT;

    if (isHubspotSupported) {
      const endpoint = `${apiUrl}${endpoints.checkHubspotAuth}`;

      const response = yield call<any>(AuthRequest, { endpoint });
      if (!response.ok) {
        yield put(IntegrationsActions.checkHubspotAuthFailure(response.body));
        // yield put(
        //   AnalyticsActions.trackApiException({
        //     endpoint,
        //     reason: FAILURE_REASONS.REQUEST_FAILED,
        //     response: response.body,
        //   }),
        // );
      } else {
        yield put(IntegrationsActions.checkHubspotAuthSuccess(response.body));
      }
    } else {
      yield put(IntegrationsActions.checkHubspotAuthSuccess());
    }
  } catch (error) {
    // yield put(trackException(error));
    yield put(IntegrationsActions.checkHubspotAuthFailure(error));
  }
}

export function* hubspotOAuthFlowWorkerSaga(action?: IReduxAction<IPromiseCallbacks>): Generator<any, any, any> {
  const { resolve, reject } = action?.payload || {};

  try {
    const clientId = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
    const scopes = process.env.REACT_APP_HUBSPOT_AUTH_SCOPES;

    if (clientId) {
      const redirectUrl = `${window.location.origin}${routes.hubspotAuthorizeCallback}`;
      const url = `${hubspotUrl}${crmEndpoints.authorizeHubspot}?client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scopes}`;

      const popupResponse = yield call<any>(new PopupProvider().openPopUpInWindow, { url });

      if (!popupResponse.ok) {
        yield put(IntegrationsActions.crmOAuthFailure(popupResponse.error));
        if (typeof reject === 'function') {
          reject(popupResponse.error);
        }
      } else {
        const authResponse = yield call<any>(AuthRequest, {
          endpoint: `${apiUrl}${endpoints.exchangeHubspotAuthCode}`,
          headers: { redirect_url: redirectUrl, auth_code: popupResponse.data },
        });

        if (!authResponse.ok) {
          yield put(IntegrationsActions.crmOAuthFailure(authResponse.error));
          if (typeof reject === 'function') {
            reject(authResponse.error);
          }
        } else {
          if (typeof resolve === 'function') {
            resolve();
          }
          yield put(IntegrationsActions.crmOAuthSuccess(authResponse.body));
          yield put(IntegrationsActions.checkHubspotAuthRequest());
        }
      }
    } else {
      yield put(IntegrationsActions.crmOAuthFailure({ message: 'No hubspot client_ID found' }));
    }
  } catch (e) {
    // yield put(trackException(e));
    if (typeof reject === 'function') {
      reject(e);
    }
    yield put(IntegrationsActions.crmOAuthFailure(e));
  } finally {
    yield put(IntegrationsActions.crmOAuthEnd());
  }
}

function* emailCheckFlowSaga(): Generator<any, any, any> {
  const emailType = yield select(selectCurrentOrganizationEmailType);

  switch (emailType) {
    case GMAIL:
      yield put(IntegrationsActions.checkEmailAuthRequest(GMAIL));
      yield take([IntegrationsActions.CHECK_EMAIL_AUTH_SUCCESS, IntegrationsActions.CHECK_EMAIL_AUTH_FAILURE]);
      break;
    case OUTLOOK: {
      yield put(IntegrationsActions.checkEmailAuthRequest(OUTLOOK));
      yield take([IntegrationsActions.CHECK_EMAIL_AUTH_SUCCESS, IntegrationsActions.CHECK_EMAIL_AUTH_FAILURE]);
      break;
    }
    case BLUEBIRD:
    default:
      break;
  }
  yield put(IntegrationsActions.emailCheckEnd());
}

export function* emailStartOAuthFlowWorkerSaga(): Generator<any, any, any> {
  const emailType = yield select(selectCurrentOrganizationEmailType);

  switch (emailType) {
    case GMAIL: {
      yield call(gmailStartOAuthWorkerSaga);
      break;
    }
    case OUTLOOK: {
      yield call(outlookStartOAuthWorkerSaga);
      break;
    }
    case BLUEBIRD:
    default:
      yield put(IntegrationsActions.emailOAuthEnd());
  }
}

function* gmailStartOAuthWorkerSaga(): Generator<any, any, any> {
  try {
    const { code, error }: any = yield call(googleAuthorize);

    if (error) {
      yield put(IntegrationsActions.emailOAuthFailure(error));
    } else if (code) {
      const endpoint = `${apiUrl}${endpoints.exchangeAuth}`;
      // Postmessage redirect_url should be used for the gapi grantOfflineAccess() method
      const headers = { auth_code: code, redirect_url: 'postmessage', email_provider: GMAIL };

      const authResponse = yield call(AuthRequest, { endpoint, headers });

      if (authResponse.ok) {
        yield put(IntegrationsActions.emailOAuthSuccess(authResponse.body));
        yield put(IntegrationsActions.checkEmailAuthRequest(GMAIL));
      } else {
        yield put(IntegrationsActions.emailOAuthFailure(authResponse.body));
      }
    }
  } catch (error) {
    yield put(IntegrationsActions.emailOAuthFailure(error));
  } finally {
    yield put(IntegrationsActions.emailOAuthEnd());
  }
}

function* checkEmailOAuthWorkerSaga(action: IReduxAction<typeof GMAIL | typeof OUTLOOK>): Generator<any, any, any> {
  try {
    const endpoint = `${apiUrl}${endpoints.userOptions}`;
    const headers = action.payload ? { email_provider: action.payload } : {};

    const response = yield call<any>(AuthRequest, { endpoint, headers });

    if (response.ok) {
      yield put(IntegrationsActions.checkEmailAuthSuccess(response.body));
    } else {
      yield put(IntegrationsActions.checkEmailAuthFailure(response.body));
    }
  } catch (error) {
    yield put(IntegrationsActions.checkEmailAuthFailure(error));
  }
}

function* outlookStartOAuthWorkerSaga(): Generator<any, any, any> {
  try {
    const queryParams = new URLSearchParams({
      client_id: process.env.REACT_APP_OUTLOOK_CLIENT_ID || '',
      scope: 'offline_access mail.send',
      response_type: 'code',
      redirect_uri: `${window.location.origin}${routes.outlookAuthorizeCallback}`,
      response_code: 'query',
      state: Math.round(Math.random() * 100000).toString(),
    });

    const url = `${outlookUrl}${crmEndpoints.authorizeOutlook}?${queryParams.toString()}`;

    const popupResponse = yield call<any>(new PopupProvider().openPopUpInWindow, { url });

    if (!popupResponse.ok) {
      yield put(IntegrationsActions.emailOAuthFailure(popupResponse.error));
    } else {
      const authResponse = yield call<any>(AuthRequest, {
        endpoint: `${apiUrl}${endpoints.exchangeAuth}`,
        headers: {
          redirect_url: queryParams.get('redirect_uri'),
          auth_code: popupResponse.data,
          email_provider: OUTLOOK,
        },
      });
      if (!authResponse.ok) {
        yield put(IntegrationsActions.emailOAuthFailure(authResponse.body));
      } else {
        yield put(IntegrationsActions.emailOAuthSuccess(authResponse.body));
        yield put(IntegrationsActions.checkEmailAuthRequest(OUTLOOK));
      }
    }
  } catch (error) {
    yield put(IntegrationsActions.emailOAuthFailure(error));
  } finally {
    yield put(IntegrationsActions.emailOAuthEnd());
  }
}

function* generateZapierApiKeyWorkerSaga(action: IReduxAction<IPromiseCallbacks>): Generator<any, any, any> {
  const { resolve, reject } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.generateAPIKey}`;

    const response = yield call(AuthRequest, { endpoint });

    if (response.ok) {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(IntegrationsActions.generateZapierApiKeySuccess());
    } else {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(IntegrationsActions.generateZapierApiKeyFailure(response.body));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(IntegrationsActions.generateZapierApiKeyFailure(error));
  }
}

function* getUserApiKeyWorkerSaga(action: IReduxAction<IPromiseCallbacks>): Generator<any, any, any> {
  const { resolve, reject } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.getUserApiKey}`;
    const response = yield call(AuthRequest, {
      method: 'GET',
      endpoint,
    });

    if (response.ok) {
      const { api_key } = response.body || {};

      if (typeof resolve === 'function') {
        resolve(api_key);
      }
      yield put(IntegrationsActions.getUserApiKeySuccess(api_key));
    } else {
      if (typeof reject === 'function') {
        reject(response.body);
      }

      yield put(IntegrationsActions.getUserApiKeyFailure(response.body));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(IntegrationsActions.getUserApiKeyFailure(error));
  }
}

const sagas = {
  *watchCheckCrmFlowStart() {
    yield takeLatest(IntegrationsActions.CHECK_CRM_INTEGRATIONS_START, crmCheckFlowSaga);
  },
  *watchCheckSalesForceAuthRequest() {
    yield takeLatest(IntegrationsActions.CHECK_SF_AUTH_REQUEST, checkSalesforceAuthWorkerSaga);
  },
  *watchSFOAuthStartWatcher() {
    yield takeLatest(IntegrationsActions.CRM_OAUTH_START, crmStartOAuthFlowWorkerSaga);
  },
  *watchCheckHubspotAuthSaga() {
    yield takeLatest(IntegrationsActions.CHECK_HUBSPOT_AUTH_REQUEST, checkHubspotAuthWorkerSaga);
  },
  *searchLeadsAndContactsRequest() {
    yield takeLatest(IntegrationsActions.SEARCH_LEADS_AND_CONTACTS_REQUEST, searchSaga);
  },
  *getOpportunitiesRequest() {
    yield takeLatest(IntegrationsActions.GET_OPPORTUNITIES_REQUEST, opportunitiesSaga);
  },
  *watchCheckEmailFlowStart() {
    yield takeLatest(IntegrationsActions.CHECK_EMAIL_INTEGRATIONS_START, emailCheckFlowSaga);
  },
  *watchEmailOAuthWatcher() {
    yield takeLatest(IntegrationsActions.EMAIL_OAUTH_START, emailStartOAuthFlowWorkerSaga);
  },
  *watchCheckEmailWatcher() {
    yield takeLatest(IntegrationsActions.CHECK_EMAIL_AUTH_REQUEST, checkEmailOAuthWorkerSaga);
  },
  *watchZapierApyKeyWatcher() {
    yield takeLatest(IntegrationsActions.GENERATE_ZAPIER_API_KEY_REQUEST, generateZapierApiKeyWorkerSaga);
  },
  *watchUserApiKeySagaRequest() {
    yield takeLatest(IntegrationsActions.GET_USER_API_KEY_REQUEST, getUserApiKeyWorkerSaga);
  },
};

export default sagas;
