import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DeleteItemModal } from '../../components';
import { PrivateRoute } from '../../components/routes';
import AdminRoute from '../../components/routes/AdminRoute';
import { CAMPAIGN_LABEL } from '../../constants/campaigns';
import { URL_VARS } from '../../constants/routing';
import { DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import { CampaignsContainer, EditCampaignContainer } from '../../containers';
import useModal from '../../hooks/useModal';
import { deleteCampaignRequest } from '../../store/actions/campaigns';
import { fetchEmailsTemplatesRequest, fetchNotesTemplatesRequest } from '../../store/actions/templates';
import { IDeleteModalProps } from '../../types/modals';
import { ICampaignRouteParams } from '../../types/routing';
import { NotificationListEnum } from '../../types/shell';
import notification from '../../utils/notification';
import { handleApiError } from '../../utils/ui';

const Campaigns: React.FC = () => {
  const { flowId } = useParams<ICampaignRouteParams>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchEmailsTemplatesRequest());
    dispatch(fetchNotesTemplatesRequest());
  }, [dispatch]);

  const {
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
    Modal: DeleteModal,
  } = useModal<IDeleteModalProps>();

  const handleDeleteItem = React.useCallback(
    (uid: string, cb?: () => void) =>
      new Promise((resolve, reject) => {
        dispatch(deleteCampaignRequest({ uid, resolve, reject }));
      })
        .then(() => {
          notification.success(NotificationListEnum.Success, { content: 'Campaign was successfully deleted' });

          if (cb) {
            cb();
          }
        })
        .catch(handleApiError(`Something bad happened. The campaign wasn't deleted.`))
        .finally(() => {
          if (typeof closeDeleteModal === 'function') {
            closeDeleteModal();
          }
        }),
    [dispatch, closeDeleteModal],
  );

  const deleteItemModal = React.useMemo(
    () => (
      <DeleteModal className="common-modal">
        {({ id, cb }) => {
          return (
            <DeleteItemModal
              assetName={CAMPAIGN_LABEL}
              onDelete={() => handleDeleteItem(id, cb)}
              onClose={closeDeleteModal}
            />
          );
        }}
      </DeleteModal>
    ),
    [closeDeleteModal, DeleteModal, handleDeleteItem],
  );

  const renderFlow = React.useMemo(() => {
    switch (true) {
      case flowId === URL_VARS.NEW || flowId === URL_VARS.EDIT: {
        return <AdminRoute roles={[DISTRIBUTOR, SUPER_ADMIN, ORG_ADMIN]} render={() => <EditCampaignContainer />} />;
      }
      case flowId === URL_VARS.VIEW: {
        return <PrivateRoute render={() => <EditCampaignContainer />} />;
      }
      default:
        return <CampaignsContainer openDeleteModal={openDeleteModal} />;
    }
  }, [flowId, openDeleteModal]);

  return (
    <React.Fragment>
      {renderFlow}
      {deleteItemModal}
    </React.Fragment>
  );
};

export default Campaigns;
