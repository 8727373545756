import merge from 'lodash/merge';
import { ReactNode, ReactText } from 'react';
import {
  toast,
  ToastContent,
  ToastContentProps,
  ToastOptions,
  TypeOptions as ToastTypeOptions,
  UpdateOptions as ToastUpdateOptions,
} from 'react-toastify';

import { IIntegrationError, NotificationListEnum } from '../types/shell';

type TypeOptions = ToastTypeOptions | 'promise';

type ShowToastFunctionType<DataType = {}> = (content: ToastContent, options?: ToastOptions<DataType>) => ReactText;

// Parent interface doesn't have ability to define DataType correctly.
// react-toastify package doesn't allow to pass additional props normally.
// So we created this interface overriding schema as a workaround.
// Currently, it works, but shows an error that we are doing it wrong.
// And all we did - we changed versions of the core packages
// I think it makes sense, but we don't have too much time at the moment.
// Moreover, this function should be refactored because the PR https://github.com/fkhadra/react-toastify/pull/646 that fixes the issue with toast.promise is merged.
// @ts-ignore
interface IUpdateOptions<DataType> extends ToastUpdateOptions {
  render?: ReactNode | ((props: ToastContentProps<DataType>) => ReactNode);
}

interface ICustomToastOptions {
  shouldClear?: boolean;
}

interface IPromiseParams<DataType> {
  pending: string | IUpdateOptions<DataType>;
  success?: string | IUpdateOptions<DataType>;
  error?: string | IUpdateOptions<DataType>;
}

interface IToastFunctionTypeProps<DataType> extends ICustomToastOptions {
  content: ToastContent;
  options?: ToastOptions<DataType>;
}

interface IShowPromiseFunctionProps<DataType> extends ICustomToastOptions {
  promise: Promise<DataType> | (() => Promise<DataType>);
  promiseParams: IPromiseParams<DataType>;
  options?: ToastOptions<DataType>;
}

const clearToast = (id: ReactText) => {
  if (id) {
    toast.dismiss(id);
  }
};

// TODO: remove notification after PR https://github.com/fkhadra/react-toastify/pull/646
const promiseToast = <DataType>({
  promise,
  promiseParams: { pending, success, error },
  options,
}: IShowPromiseFunctionProps<DataType>) => {
  const resetParams = {
    isLoading: null,
    autoClose: null,
    closeOnClick: null,
    closeButton: null,
    draggable: null,
  };

  const resolver = (type: ToastTypeOptions, input: string | IUpdateOptions<DataType>, result: DataType) => {
    const params = typeof input === 'string' ? { render: input } : input;
    toast.update(id, merge({ type }, resetParams, options, params, { data: result }));
    return result;
  };

  const id =
    typeof pending === 'string'
      ? toast.loading(pending, options)
      : // this hack should be resolved after this function is refactored.
        // @ts-ignore
        toast.loading(pending.render, merge({}, options, pending));

  const p = typeof promise === 'function' ? promise() : promise;

  p.then((result) => {
    return toast.isActive(id) && success ? resolver('success', success, result) : clearToast(id);
  }).catch((err) => {
    console.warn(err);
    return toast.isActive(id) && error && resolver('error', error, err);
  });

  return id;
};

/** Create the right toast on the type and additional options
 * @template {{}} DataType - The type of the data if it uses inside the toast
 * @template {TypeOptions} T
 * @param {T} type - Needed toast type for showing
 * @param {T extends 'promise' ? IShowPromiseFunctionProps<DataType> : IToastFunctionTypeProps<DataType>} options - Additional toast options
 * @return The id of the created toast
 */
const createToast = <DataType = {}, T extends TypeOptions = TypeOptions>(
  type: T,
  options: T extends 'promise' ? IShowPromiseFunctionProps<DataType> : IToastFunctionTypeProps<DataType>,
) => {
  switch (type) {
    case 'promise':
      return promiseToast(options as IShowPromiseFunctionProps<DataType>);
    case 'default': {
      const { content, options: toastOptions } = options as IToastFunctionTypeProps<DataType>;
      return toast(content, toastOptions);
    }
    default: {
      const { content, options: toastOptions } = options as IToastFunctionTypeProps<DataType>;
      return (toast[type as keyof typeof toast] as ShowToastFunctionType<DataType>)(content, toastOptions);
    }
  }
};

/** Used to show toast. If initialId is set, toast is considered in the single mode
 * @template {{}} DataType - The type of the data if it uses inside the toast
 * @template {TypeOptions} T
 * @param {ReactText | undefined} initialId - Initial toast id
 * @param {T} type - Needed toast type for showing
 * @param {T extends 'promise' ? ShowPromiseFunctionType<DataType> : ShowToastFunctionType<DataType>} config - an object of toast configs
 * @return The id of the created toast
 */
const showToast = <DataType = {}, T extends TypeOptions = TypeOptions>(
  initialId: ReactText | undefined,
  type: T,
  config: T extends 'promise' ? IShowPromiseFunctionProps<DataType> : IToastFunctionTypeProps<DataType>,
): Promise<ReactText> => {
  const isSingle = !!initialId;
  let intervalId: NodeJS.Timeout;
  let toastId = initialId;

  // "shouldClear === true" flag means that toast with same id
  // will close previous toast and appear, instead of just not appear.
  const { shouldClear = true, options } = config || {};

  if (isSingle && toastId && toast.isActive(toastId) && shouldClear) {
    clearToast(toastId);
  }

  if (isSingle && shouldClear) {
    return new Promise<ReactText>((resolve) => {
      /*
        react-tostify has the problem with immediately hiding (https://github.com/fkhadra/react-toastify/issues/594)
        setInterval() is the small hack to show new toast when the old one is fully hiding
      */
      intervalId = setInterval(() => {
        toastId = createToast(type, { ...config, options: { ...options, toastId } });
        if (toast.isActive(toastId)) {
          resolve(toastId);
        }
      }, 100);
    }).finally(() => {
      clearInterval(intervalId);
    });
  }

  // Probably in future we will remove `shouldClear` at all
  // cuz we will use all toast's without "shouldClear" behavior
  shouldClear
    ? (toastId = createToast<DataType, T>(type, config))
    : createToast<DataType, T>(type, { ...config, options: { ...options, toastId } });

  return Promise.resolve(toastId || '');
};

const notification = {
  show: showToast,
  clear: clearToast,
  error: <DataType>(initialId: ReactText | undefined, options: IToastFunctionTypeProps<DataType>) => {
    return showToast<DataType>(initialId, 'error', options);
  },
  info: <DataType>(initialId: ReactText | undefined, options: IToastFunctionTypeProps<DataType>) => {
    return showToast<DataType>(initialId, 'info', options);
  },
  success: <DataType>(initialId: ReactText | undefined, options: IToastFunctionTypeProps<DataType>) => {
    return showToast<DataType>(initialId, 'success', options);
  },
  warning: <DataType>(initialId: ReactText | undefined, options: IToastFunctionTypeProps<DataType>) => {
    return showToast<DataType>(initialId, 'warning', options);
  },
  promise: <DataType>(initialId: ReactText | undefined, options: IShowPromiseFunctionProps<DataType>) => {
    return showToast<DataType>(initialId, 'promise', options);
  },
  comingSoon: <DataType>() => {
    return notification.info<DataType>(NotificationListEnum.ComingSoon, { content: 'Coming soon!' });
  },
  accessDenied: <DataType>(content?: string) => {
    return notification.error<DataType>(NotificationListEnum.AccessDenied, {
      content: content || 'Access denied!',
      shouldClear: false,
    });
  },
  outOfMoney: <DataType>(content: ReactText | ReactNode) => {
    return notification.warning<DataType>(NotificationListEnum.OutOfMoney, { content });
  },
  noAsset: <DataType>(asset: string) => {
    return notification.error<DataType>(NotificationListEnum.NoAsset, { content: `No ${asset} with that id!` });
  },
  enterValidData: <DataType>(asset: string = 'data') => {
    return notification.error<DataType>(NotificationListEnum.EnterValidData, {
      content: `Please, enter a valid ${asset}`,
    });
  },
  integrationSuccess: <DataType>() => {
    return notification.success<DataType>(NotificationListEnum.AddIntegrationSuccess, {
      content: `The integration was added successfully!`,
    });
  },
  integrationError: <DataType>(content: IIntegrationError) => {
    const { error } = content;
    return notification.error<DataType>(NotificationListEnum.AddIntegrationError, {
      content: `${error}`,
    });
  },
};

export default notification;
