import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as AlertIcon } from '../../assets/images/icon-alert.svg';
import { ReactComponent as BudgetIcon } from '../../assets/images/icon-film.svg';
import { FALLBACK_CURRENCY_CODE } from '../../constants/shell';
import { FeatureBudgetEnum } from '../../types/shell';
import { Price } from '../index';

import styles from './FeatureBudget.module.scss';

export interface IFeatureBudgetProps {
  label: string;
  name: FeatureBudgetEnum;
  warningValue: number;
  budgetValue?: { amount: string | number | null | undefined; budget_currency?: string };
  hint?: string;
  className?: string;
  onLowBudget?: (name: FeatureBudgetEnum, data: boolean) => void;
}

const FeatureBudget = ({
  label,
  name,
  warningValue,
  hint = '',
  budgetValue,
  onLowBudget,
  className,
}: IFeatureBudgetProps) => {
  const isLowBudget = React.useMemo(() => {
    if (typeof budgetValue === 'undefined' || budgetValue === null) {
      return false;
    }

    return (budgetValue.amount ?? 0) < warningValue;
  }, [budgetValue, warningValue]);

  React.useEffect(() => {
    if (onLowBudget) {
      onLowBudget(name, isLowBudget);
    }
  }, [onLowBudget, name, isLowBudget]);

  if (!budgetValue) {
    return null;
  }

  return (
    <div title={hint} className={cn(className, styles.userBudget)}>
      <div className={styles.iconWrapper}>
        <BudgetIcon className={styles.icon} />
        {isLowBudget && <AlertIcon className={styles.alert} />}
      </div>
      <div className={styles.info}>
        <span className={styles.description}>{label}&nbsp;</span>
        {typeof budgetValue.amount === 'string' ? (
          <span title={hint} className={cn(styles.currency, { [styles.warning]: isLowBudget })}>
            {budgetValue.amount}
          </span>
        ) : (
          <Price
            value={{ price: budgetValue.amount || 0, currency: budgetValue.budget_currency || FALLBACK_CURRENCY_CODE }}
            hint={hint}
            thousandSeparator
            className={cn(styles.currency, { [styles.warning]: isLowBudget })}
          />
        )}
      </div>
    </div>
  );
};

export default FeatureBudget;
