import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as ErrorIcon } from '../../assets/images/icon-warning-red.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning.svg';
import { UNKNOWN_COUNT_VALUE } from '../../constants/inventories';
import { InventoryTypesEnum } from '../../types/inventories';
import { overrideTooltipPosition } from '../../utils/helpers';
import { getItemCountWarning, isItemOutOfStock } from '../../utils/inventories';
import { ItemCount } from '../index';
import { HelpTooltip } from '../tooltips';

import styles from './ItemCountWithWarning.module.scss';

interface IProps {
  className?: string;
  count?: number;
  id: string;
  isPreorderAllowed?: boolean;
  type: InventoryTypesEnum;
}

const ItemCountWithWarning = ({ className, count, id, isPreorderAllowed, type }: IProps) => {
  const warningMessage = React.useMemo(() => getItemCountWarning({ count }), [count]);

  const isItemAvailable = React.useMemo(
    () => !isItemOutOfStock({ count, preorder_allowed: isPreorderAllowed, type }),
    [count, isPreorderAllowed, type],
  );

  const warningIcon = React.useMemo(() => {
    if (!warningMessage) {
      return;
    }
    return isItemAvailable ? WarningIcon : ErrorIcon;
  }, [warningMessage, isItemAvailable]);

  return (
    <div className={cn(styles.container, className)}>
      {warningMessage && (
        <HelpTooltip
          effect="float"
          className={cn(styles.hint, { [styles.floating]: count !== UNKNOWN_COUNT_VALUE })}
          contentClassName={cn(styles.hintContent, { [styles.error]: !isItemAvailable })}
          icon={warningIcon}
          id={`inventory-item-preview-${id}`}
          overridePosition={overrideTooltipPosition}
        >
          {warningMessage}
        </HelpTooltip>
      )}
      {count !== UNKNOWN_COUNT_VALUE && <ItemCount count={count} className={styles.value} />}
    </div>
  );
};

export default ItemCountWithWarning;
