import getSymbolFromCurrency from 'currency-symbol-map';
import * as React from 'react';
import { SingleValueProps } from 'react-select';

import { ISelectorValue } from '../../../../../../types/shell';

const CurrencyValue: React.ComponentType<SingleValueProps<ISelectorValue>> = ({ data, hasValue }) => {
  return <div>{hasValue ? getSymbolFromCurrency(data.value) : ''}</div>;
};

export default CurrencyValue;
