import cn from 'classnames';
import * as React from 'react';

import { UID } from '../../../constants/reports';
import { IDashboardDelayedPackage } from '../../../types/dashboard';
import { InputLabel } from '../../forms';
import { HelpTooltip } from '../../index';
import NoResultsPlaceholder from '../../NoResultsPlaceholder/NoResultsPlaceholder';
import DelayedPackageItem from './DelayedPackageItem/DelayedPackageItem';

import styles from './DelayedPackagesWidget.module.scss';

interface IProps {
  className?: string;
  items?: IDashboardDelayedPackage[];
}

const DelayedPackagesWidget: React.FC<IProps> = ({ items, className }) => {
  return (
    <div className={cn(className, styles.container)}>
      <InputLabel
        value={
          <div className={styles.label}>
            Delayed Packages
            <HelpTooltip id="delayed packages" className={styles.hint}>
              Packages that are stuck in transit for 3 days for US, 7 for International
            </HelpTooltip>
          </div>
        }
      />
      <div className={cn(styles.list, { [styles.empty]: !items?.length })}>
        {!items?.length ? (
          <NoResultsPlaceholder
            className={styles.placeholder}
            imageClassName={styles.image}
            label="No current issues"
          />
        ) : (
          items?.map((item) => <DelayedPackageItem className={styles.item} key={item[UID]} item={item} />)
        )}
      </div>
    </div>
  );
};

export default DelayedPackagesWidget;
