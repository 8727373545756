import { createTransform } from 'redux-persist';

import { IToolsState } from '../../types/tools';

const toolsTransform = createTransform(
  (stateIn: IToolsState) => stateIn,
  (stateOut: IToolsState) => {
    return {
      ...stateOut,
      error: null,
      isLoading: false,
    };
  },
  { whitelist: ['tools'] },
);

const transformArray = [toolsTransform];

export default transformArray;
