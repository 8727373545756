import cn from 'classnames';
import * as React from 'react';

import { Accordion, AccordionItem } from '../../components';
import { IOrganizationSummary } from '../../types/dashboard';
import OrganizationSummaryContent from './OrganizationSummaryContent/OrganizationSummaryContent';
import OrganizationSummaryTitle from './OrganizationSummaryTitle/OrganizationSummaryTitle';

import styles from './OrganizationSummaryList.module.scss';

interface IProps {
  summaryList: IOrganizationSummary[] | null;
  activeSummary: string | null;
  onItemClick: (id: string) => void;
  className?: string;
}

const OrganizationSummaryList: React.FC<IProps> = ({ summaryList, activeSummary, onItemClick, className }) => {
  return (
    <div className={cn(styles.listWrapper, className)}>
      <div className={styles.controls}>
        <span className={styles.columnLabel}>
          <span>Showing:</span>&nbsp;
          <span className={styles.filter}>last 30 days</span>
        </span>
        <span className={styles.columnLabel}>Digital Wallet Balance</span>
        <span className={styles.columnLabel}>Sends</span>
        <span className={styles.columnLabel}>Amount Spent</span>
      </div>
      {summaryList ? (
        <Accordion activeItemId={activeSummary} className={styles.orgSummaryList}>
          {summaryList.map((summary) => {
            const { org_id: orgId } = summary;

            return (
              <AccordionItem
                key={orgId}
                id={orgId}
                iconContainerClassName={styles.iconContainer}
                onClick={onItemClick}
                title={<OrganizationSummaryTitle summary={summary} />}
                content={<OrganizationSummaryContent summary={summary} />}
              />
            );
          })}
        </Accordion>
      ) : null}
    </div>
  );
};

export default OrganizationSummaryList;
