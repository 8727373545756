import cn from 'classnames';
import * as React from 'react';

import styles from './IntegrationItem.module.scss';

interface IProps {
  className?: string;
  active?: boolean;
  onClick?: () => any;
  icon?: React.ReactNode;
}

export default ({ className, active, onClick, icon }: IProps) => {
  return (
    <div
      className={cn(className, styles.integrationItem, { [styles.active]: active })}
      style={{ backgroundImage: `url(${icon})` }}
      onClick={onClick}
    />
  );
};
