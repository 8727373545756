import tinycolor from 'tinycolor2';

import { IApiError } from '../types/shell';
import notification from './notification';

export const getFirstname = (displayName: string) => {
  if (!displayName) {
    return;
  }
  let firstName = displayName;

  if (displayName.includes(' ')) {
    firstName = displayName.split(' ')[0];
  }
  return firstName;
};

export const getInitialLetter = (displayName: string) => {
  if (!displayName) {
    return;
  }

  return displayName.split('')[0];
};

export const capitalize = (str: string, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

export const takeAllAfterLastSlash = (str: string) => {
  const matched = str.match(/([^/]+$)/gm);

  if (matched) {
    return matched[0];
  }

  return str;
};

export const handleApiError: {
  (fallback?: string): (error?: IApiError) => void;
  (fallback: string | undefined, callback: undefined): (error?: IApiError) => void;
  <T = any>(fallback: string | undefined, callback: (error?: IApiError) => T): (error?: IApiError) => T;
} =
  (fallback?: string, callback?: (error?: IApiError) => any) =>
  async (error?: IApiError): Promise<any | undefined> => {
    if ((error && error.message) || fallback) {
      const message = error?.message || fallback;

      await notification.error(message, { content: message });
    }

    if (callback) {
      return callback(error);
    }
  };

/**
 * Save a text as file using HTML <a> temporary element
 * @see https://stackoverflow.com/questions/49988202/macos-webview-download-a-html5-blob-file
 * @param url String
 */
export const downloadFile = (url: string): void => {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => {
    if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
      const blobUrl = window.URL.createObjectURL(xmlHttp.response);
      const e = document.createElement('a');
      e.href = blobUrl;
      e.download = blobUrl.substr(blobUrl.lastIndexOf('/') + 1);
      document.body.appendChild(e);
      e.click();
      document.body.removeChild(e);
    }
  };
  xmlHttp.responseType = 'blob';
  xmlHttp.open('GET', url, true);
  xmlHttp.send(null);
};

export const delay = (ms: number) => new Promise<void>((res) => setTimeout(res, ms));

export const isLightColor = (color: string | undefined) => {
  const backgroundColor = tinycolor(color);
  return backgroundColor.isLight();
};

export const isBrightColor = (color?: string) => {
  const validColor = tinycolor(color);
  return validColor ? validColor.getBrightness() > 190 : false;
};
