import { call, put, race, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { UID } from '../../constants/oneLink';
import { endpoints, queryParams } from '../../constants/routing';
import { IFetchCampaignSummariesResponsePayload } from '../../types/campaigns';
import { IResponse } from '../../types/http';
import {
  IDisableOneLinkSendRequestPayload,
  IDisableOneLinkSendSuccessPayload,
  IFetchOneLinkByIdRequestPayload,
  IFetchOneLinkByIdResponsePayload,
  IFetchOneLinkSendsRequestPayload,
  IFetchOneLinkSendsSuccessPayload,
  IPopulateOneLinkCampaignSummariesRequestPayload,
  IPopulateOneLinkCampaignSummariesResponsePayload,
  OneLinkStatusEnum,
} from '../../types/oneLink';
import { IReduxAction } from '../../types/redux';
import { ReportsRequestSearchTypes } from '../../types/reports';
import { IApiError } from '../../types/shell';
import { getQuery } from '../../utils/request';
import * as CampaignActions from '../actions/campaigns';
import * as OneLinkActions from '../actions/oneLink';
import { AuthRequest } from './helpers';

function* oneLinkSendsWorkerSaga(action: IReduxAction<IFetchOneLinkSendsRequestPayload>) {
  const {
    isOrgSearch,
    isDeptSearch,
    page_size,
    department_ids,
    range,
    page,
    status = OneLinkStatusEnum.Active,
    resolve,
    reject,
  } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.getOneLinkSends}`;
    const body = JSON.stringify({
      page_size,
      ...(isOrgSearch ? getQuery(ReportsRequestSearchTypes.orgSearch, queryParams.searchType) : {}),
      ...(isDeptSearch ? getQuery(ReportsRequestSearchTypes.deptSearch, queryParams.searchType) : {}),
      ...(department_ids?.length ? { department_ids } : {}),
      range,
      status,
      page,
    });

    const response: IResponse<IFetchOneLinkSendsSuccessPayload | IApiError> = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      body,
    });
    if (!response.ok) {
      yield put(OneLinkActions.fetchOneLinkSendsFailure(response.body as IApiError));
      if (reject) {
        yield call(reject, response.body);
      }
    } else {
      yield put(
        OneLinkActions.fetchOneLinkSendsSuccess({ ...(response.body as IFetchOneLinkSendsSuccessPayload), status }),
      );
      if (resolve) {
        yield call(resolve, response.body);
      }
    }
  } catch (error) {
    yield put(OneLinkActions.fetchOneLinkSendsFailure(error));
    if (reject) {
      yield call(reject, error);
    }
  }
}

function* oneLinkSendsByIdWorkerSaga(action: IReduxAction<IFetchOneLinkByIdRequestPayload>) {
  const { itemId, status = OneLinkStatusEnum.Active, resolve, reject } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.getOneLinkSendById}`;
    const qs = {
      ...(itemId ? getQuery(itemId, queryParams.uid) : {}),
    };

    const response: IResponse<IFetchOneLinkByIdResponsePayload | IApiError> = yield call<any>(AuthRequest, {
      queryParams: qs,
      endpoint,
      method: 'GET',
    });
    if (!response.ok) {
      if (reject) {
        reject(response.body);
      }
      yield put(OneLinkActions.fetchOneLinkByIdFailure(response.body as IApiError));
    } else {
      if (resolve) {
        resolve(response.body);
      }
      yield put(
        OneLinkActions.fetchOneLinkByIdSuccess({ ...(response.body as IFetchOneLinkByIdResponsePayload), status }),
      );
    }
  } catch (error) {
    if (reject) {
      reject(error);
    }
    yield put(OneLinkActions.fetchOneLinkByIdFailure(error));
  }
}

function* populateOneLinkCampaignSummariesWorkerSaga(
  action: IReduxAction<IPopulateOneLinkCampaignSummariesRequestPayload>,
): Generator<any, any, any> {
  try {
    const { itemId, status, campaign_ids } = action.payload!;

    yield put(CampaignActions.fetchCampaignSummariesRequest({ campaign_ids }));

    const [successAction] = yield race([
      take(CampaignActions.FETCH_CAMPAIGN_SUMMARIES_SUCCESS),
      take(CampaignActions.FETCH_CAMPAIGN_SUMMARIES_FAILURE),
    ]);

    if (successAction) {
      yield put(
        OneLinkActions.populateOneLinkCampaignSummariesSuccess({
          ...((successAction as IReduxAction<IPopulateOneLinkCampaignSummariesResponsePayload>)
            .payload as IFetchCampaignSummariesResponsePayload),
          itemId,
          status,
        }),
      );
    } else {
      yield put(OneLinkActions.populateOneLinkCampaignSummariesFailure(successAction.payload as IApiError));
    }
  } catch (error) {
    yield put(OneLinkActions.populateOneLinkCampaignSummariesFailure(error));
  }
}

function* disableOneLinkSendSaga(action: IReduxAction<IDisableOneLinkSendRequestPayload>) {
  const { uid, resolve, reject } = action.payload || {};
  try {
    if (!uid) {
      throw new Error('An error has occurred when was trying to disable One Link Send');
    }

    const endpoint = `${apiUrl}${endpoints.disableOneLinkSend}`;

    const response: IResponse<never | IApiError> = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      queryParams: getQuery(uid, UID),
    });

    if (!response.ok) {
      reject?.(response.body);
      yield put(OneLinkActions.disableOneLinkFailure(response.body as IApiError));
    } else {
      resolve?.(uid);
      yield put(OneLinkActions.disableOneLinkSuccess({ uid } as IDisableOneLinkSendSuccessPayload));
    }
  } catch (e) {
    reject?.(e);
    yield put(OneLinkActions.disableOneLinkFailure(e));
  }
}

const sagas = {
  *watchOneLinkSendsRequest() {
    yield takeLatest(OneLinkActions.FETCH_ONE_LINK_SENDS_REQUEST, oneLinkSendsWorkerSaga);
  },
  *watchOneLinkSendByIdRequest() {
    yield takeLatest(OneLinkActions.FETCH_ONE_LINK_BY_ID_REQUEST, oneLinkSendsByIdWorkerSaga);
  },
  *watchFetchOneLinkCampaignSummariesRequest() {
    yield takeLatest(
      OneLinkActions.POPULATE_ONE_LINK_CAMPAIGN_SUMMARIES_REQUEST,
      populateOneLinkCampaignSummariesWorkerSaga,
    );
  },
  *watchDisableOneLinkSendRequest() {
    yield takeEvery(OneLinkActions.DISABLE_ONE_LINK_SEND_REQUEST, disableOneLinkSendSaga);
  },
};

export default sagas;
