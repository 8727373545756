import cn from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Row } from 'react-table';

import { ReactComponent as EditIcon } from '../../assets/images/icon-edit-gray.svg';
import { AddButton, AutomationTable } from '../../components';
import { fetchAutomatedCampaignsRequest } from '../../store/actions/automations';
import { fetchUsersRequest } from '../../store/actions/users';
import { selectAutomatedCampaigns } from '../../store/selectors/automations';
import { selectUsers } from '../../store/selectors/users';
import { AutomatedCampaignTypeEnum, IAutomatedCampaign } from '../../types/automations';
import { getAutomationStatusLabel } from '../../utils/automations';

import styles from './AutomatedCampaigns.module.scss';

interface IProps {
  disabled: boolean;
  onAdd: () => void;
  onEdit: (campaign: IAutomatedCampaign) => void;
}

const AutomatedCampaignsContainer: React.FC<IProps> = ({ disabled, onAdd, onEdit }) => {
  const dispatch = useDispatch();

  const users = useSelector(selectUsers);
  const campaigns = useSelector(selectAutomatedCampaigns);

  const senderEmails = useMemo(() => {
    if (!users || !campaigns) {
      return [];
    }

    return campaigns.map((c: IAutomatedCampaign) => {
      const user = users.find((person) => person.uid === c.sender_id);
      return `${user?.first_name} ${user?.last_name}`;
    });
  }, [users, campaigns]);

  const getTypeLabel = (type: AutomatedCampaignTypeEnum): string => {
    switch (type) {
      case AutomatedCampaignTypeEnum.NewHire:
        return 'New Hire';
      case AutomatedCampaignTypeEnum.Birthday:
        return 'Birthday';
      case AutomatedCampaignTypeEnum.Anniversary:
        return 'Anniversary';
      default:
        return '';
    }
  };

  const columns: Column<IAutomatedCampaign>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ value }: { value: AutomatedCampaignTypeEnum }) => <span>{getTypeLabel(value)}</span>,
      },
      {
        Header: 'Sender',
        Cell: ({ row }: { row: Row<IAutomatedCampaign> }) => {
          const sender = senderEmails[row.index];
          return <span>{sender}</span>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }: { value: string }) => {
          const { message, color } = getAutomationStatusLabel(value, 'Campaign');

          return (
            <div className={styles.statusWrapper}>
              <div
                style={{
                  backgroundColor: color,
                }}
                className={styles.statusBullet}
              />
              {message}
            </div>
          );
        },
      },
      {
        id: 'action',
        Cell: ({ row }: { row: Row<IAutomatedCampaign> }) => (
          <button onClick={() => onEdit(row.original)}>
            <EditIcon />
          </button>
        ),
      },
    ],
    [onEdit, senderEmails],
  );

  useEffect(() => {
    if (!users || !users.length) {
      dispatch(fetchUsersRequest());
    }
  }, [users, dispatch]);

  useEffect(() => {
    dispatch(fetchAutomatedCampaignsRequest());
  }, [dispatch]);

  return (
    <div
      className={cn({
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.header}>
        <AddButton assetName="Campaign" onClick={onAdd} className={styles.addButton} />
      </div>
      <AutomationTable
        data={campaigns || []}
        columns={columns}
        placeholderText="There are no campaigns"
        containerClassName={styles.tableContainer}
      />
    </div>
  );
};

export default AutomatedCampaignsContainer;
