import cn from 'classnames';
import * as React from 'react';

import { RadioButton } from '..';
import { IRadioButtonOption } from '../../../../types/forms';
import { IRadioButtonProps } from '../RadioButton/RadioButton';

import styles from './RadioButtonGroup.module.scss';

interface IProps extends Omit<IRadioButtonProps, 'className' | 'label' | 'defaultValue'> {
  containerClassName?: string;
  optionClassName?: string;
  options?: IRadioButtonOption[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  name?: string;
  isRounded?: boolean;
}

const RadioButtonGroup = ({
  containerClassName,
  optionClassName,
  disabled,
  options,
  onChange,
  name,
  value,
  isRounded,
}: IProps) => {
  return options && options.length ? (
    <div className={cn(styles.container, { [styles.rounded]: isRounded }, containerClassName)}>
      {options.map((option: IRadioButtonOption) => (
        <RadioButton
          containerClassName={cn(styles.option, { [styles.rounded]: isRounded }, optionClassName)}
          className={styles.input}
          value={value}
          name={name}
          label={option.label}
          onChange={onChange}
          key={option.value}
          defaultValue={option.value}
          disabled={disabled}
        />
      ))}
    </div>
  ) : null;
};

export default RadioButtonGroup;
