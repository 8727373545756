import { createTransform } from 'redux-persist';
import { IOrganizationsState } from '../../types/organizations';

const organizationTransform = createTransform(
  (stateIn: IOrganizationsState) => {
    return {
      ...stateIn,
      items: null,
    };
  },
  (stateOut: IOrganizationsState) => {
    return {
      ...stateOut,
      error: null,
      isLoading: false,
      current: {
        ...stateOut.current,
        isDepartmentUpdating: false,
        isDepartmentSummariesLoading: false,
        error: null,
      },
    };
  },
  { whitelist: ['organizations'] },
);

const transformArray = [organizationTransform];

export default transformArray;
