import { createSelector } from 'reselect';
import {
  DIGITAL_INVENTORY_ITEM_VALIDATION_SCHEMA,
  INITIAL_DIGITAL_INVENTORY_ITEM_FORM_STATE,
  INITIAL_MSKU_INVENTORY_ITEM_FORM_STATE,
  INITIAL_PHYSICAL_INVENTORY_ITEM_FORM_STATE,
  MSKU_INVENTORY_ITEM_VALIDATION_SCHEMA,
  PHYSICAL_INVENTORY_ITEM_VALIDATION_SCHEMA,
} from '../../constants/inventories';
import { IInventoryItem, InventoryFilterTypesEnum, InventoryTypesEnum } from '../../types/inventories';
import { IAppState } from '../reducers';
import { selectIsDistributor, selectIsSuperAdmin } from './auth';

export const selectRootState = (state: IAppState) => state.inventories;

export const selectCanEditInventory = createSelector(
  selectIsSuperAdmin,
  selectIsDistributor,
  (isSuper, isDistributor) => isSuper || isDistributor,
);

export const selectInventoryItems = (type: InventoryFilterTypesEnum = InventoryFilterTypesEnum.All) =>
  createSelector(selectRootState, (inventory) => {
    const getItemsByType = (items: IInventoryItem[]) => {
      return type === InventoryFilterTypesEnum.All ? items : items.filter((item) => item.type === type.toString());
    };

    return inventory && inventory.items ? getItemsByType(Object.values(inventory.items)) : undefined;
  });

export const selectInventoryTotalCount = createSelector(selectRootState, (inventory) => {
  return inventory ? inventory.totalCount : 0;
});

export const selectLowInventoryItems = createSelector(selectRootState, (inventory) =>
  inventory ? inventory.lowInventoryItems : null,
);

export const selectItemDetails = createSelector(selectRootState, (inventory) =>
  inventory ? inventory.itemDetails : undefined,
);

export const selectIsInventoryFetching = createSelector(selectRootState, (inventory) =>
  inventory ? inventory.isLoading : false,
);

export const selectIsFinalInventoryItem = createSelector(selectRootState, (inventory) =>
  inventory ? inventory.isFinal : false,
);

export const selectInventoryCSVUpload = createSelector(selectRootState, (inventory) =>
  inventory ? inventory.csvUpload : undefined,
);

export const selectInventoryUploadCSVFileInfo = createSelector(selectInventoryCSVUpload, (csvUpload) =>
  csvUpload ? csvUpload.file : null,
);

export const selectIsInventoryUploadCSVLoading = createSelector(selectInventoryCSVUpload, (csvUpload) =>
  csvUpload ? csvUpload.isLoading : false,
);
export const selectInventoryBulkResult = createSelector(selectInventoryCSVUpload, (csvUpload) =>
  csvUpload ? csvUpload.result : null,
);

export const selectInventoryItemValidationSchema = createSelector(selectItemDetails, (itemDetails) => {
  // Empty type is physical according to the logic from the inventory container
  switch (itemDetails?.type) {
    case InventoryTypesEnum.Digital:
      return DIGITAL_INVENTORY_ITEM_VALIDATION_SCHEMA;
    case InventoryTypesEnum.MSKU:
      return MSKU_INVENTORY_ITEM_VALIDATION_SCHEMA;
    default:
      return PHYSICAL_INVENTORY_ITEM_VALIDATION_SCHEMA;
  }
});

export const selectInventoryItemInitialValues = createSelector(selectItemDetails, (itemDetails) => {
  const initialValues = (() => {
    switch (itemDetails?.type) {
      case InventoryTypesEnum.Digital:
        return INITIAL_DIGITAL_INVENTORY_ITEM_FORM_STATE;
      case InventoryTypesEnum.MSKU:
        return INITIAL_MSKU_INVENTORY_ITEM_FORM_STATE;
      default:
        return INITIAL_PHYSICAL_INVENTORY_ITEM_FORM_STATE;
    }
  })();

  return { ...initialValues, ...itemDetails };
});

export const selectInventoryItemById = (itemId: string | undefined) =>
  createSelector(selectInventoryItems(), (inventoryItems) => {
    if (!itemId) {
      return null;
    }

    return inventoryItems?.find((item) => item.item_id === itemId);
  });

export const selectIsInventoryReportLoading = createSelector(
  selectRootState,
  (inventories) => inventories?.isInventoryReportLoading,
);
