import cn from 'classnames';
import * as React from 'react';

import styles from './NavIcon.module.scss';

interface IProps {
  style?: any;
  children?: React.ReactNode;
  className?: string;
}

const NavIcon = ({ style, children, className }: IProps) => (
  <div className={cn(styles.navIcon, className)} style={style}>
    {children}
  </div>
);

export default NavIcon;
