import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { IIntegrationsState } from '../../types/integrations';
import * as AuthActions from '../actions/auth';
import * as IntegrationsActions from '../actions/integrations';

export const initialState: IIntegrationsState = {
  crm: {
    isAuthInProgress: false,
    isAuthenticated: undefined,
    isLoading: false,
    isSearching: false,
    isLoadingOpportunities: false,
    searchItems: null,
    opportunities: null,
    error: null,
  },
  email: {
    isAuthInProgress: false,
    isAuthenticated: undefined,
    isLoading: false,
    error: null,
  },
  zapier: {
    isLoading: false,
    error: null,
    wasLoaded: false,
    apiKey: '',
  },
};

const reducer = (state: IIntegrationsState = initialState, action: any): IIntegrationsState => {
  switch (action.type) {
    case IntegrationsActions.CHECK_CRM_INTEGRATIONS_START: {
      return {
        ...state,
        crm: {
          ...state.crm,
          isAuthenticated: typeof state.crm.isAuthenticated !== 'undefined' ? state.crm.isAuthenticated : undefined,
          error: null,
        },
      };
    }
    case IntegrationsActions.CHECK_SF_AUTH_REQUEST:
    case IntegrationsActions.CHECK_HUBSPOT_AUTH_REQUEST: {
      return {
        ...state,
        crm: {
          ...state.crm,
          isLoading: true,
          error: null,
        },
      };
    }
    case IntegrationsActions.CHECK_CRM_INTEGRATIONS_END: {
      return {
        ...state,
        crm: {
          ...state.crm,
          isLoading: false,
        },
      };
    }
    case IntegrationsActions.CHECK_SF_AUTH_SUCCESS:
    case IntegrationsActions.CHECK_SF_AUTH_FAILURE: {
      const { salesforceTokenFailed, client_id: clientId } = action.payload || {};
      return {
        ...state,
        crm: {
          ...state.crm,
          isLoading: false,
          isAuthenticated: typeof salesforceTokenFailed !== 'undefined' ? !salesforceTokenFailed : false,
          ...(clientId ? { clientId } : {}),
        },
      };
    }
    case IntegrationsActions.CRM_OAUTH_START: {
      return {
        ...state,
        crm: {
          ...state.crm,
          isAuthInProgress: true,
          error: null,
        },
      };
    }
    case IntegrationsActions.CRM_OAUTH_END: {
      return {
        ...state,
        crm: {
          ...state.crm,
          isAuthInProgress: false,
        },
      };
    }
    case IntegrationsActions.CRM_OAUTH_SUCCESS: {
      return {
        ...state,
        crm: {
          ...state.crm,
          error: null,
        },
      };
    }
    case IntegrationsActions.CRM_OAUTH_FAILURE: {
      return {
        ...state,
        crm: {
          ...state.crm,
          error: action.payload,
        },
      };
    }
    case IntegrationsActions.CHECK_HUBSPOT_AUTH_SUCCESS:
    case IntegrationsActions.CHECK_HUBSPOT_AUTH_FAILURE: {
      const { hubspotTokenFailed } = action.payload || {};
      return {
        ...state,
        crm: {
          ...state.crm,
          isLoading: false,
          isAuthenticated: typeof hubspotTokenFailed !== 'undefined' ? !hubspotTokenFailed : false,
        },
      };
    }
    case IntegrationsActions.SEARCH_LEADS_AND_CONTACTS_REQUEST: {
      return {
        ...state,
        crm: {
          ...state.crm,
          isSearching: true,
          searchItems: null,
          error: null,
        },
      };
    }
    case IntegrationsActions.SEARCH_LEADS_AND_CONTACTS_FAILURE: {
      return {
        ...state,
        crm: {
          ...state.crm,
          isSearching: false,
          error: action.payload,
        },
      };
    }
    case IntegrationsActions.SEARCH_LEADS_AND_CONTACTS_SUCCESS: {
      return {
        ...state,
        crm: {
          ...state.crm,
          isSearching: false,
          searchItems: action.payload,
          error: null,
        },
      };
    }
    case IntegrationsActions.CLEAR_SEARCH_ITEMS: {
      return {
        ...state,
        crm: {
          ...state.crm,
          isSearching: false,
          searchItems: null,
        },
      };
    }
    case IntegrationsActions.GET_OPPORTUNITIES_REQUEST: {
      return {
        ...state,
        crm: {
          ...state.crm,
          isLoadingOpportunities: true,
          opportunities: null,
        },
      };
    }
    case IntegrationsActions.GET_OPPORTUNITIES_SUCCESS: {
      return {
        ...state,
        crm: {
          ...state.crm,
          isLoadingOpportunities: false,
          opportunities: action.payload,
          error: null,
        },
      };
    }
    case IntegrationsActions.GET_OPPORTUNITIES_FAILURE: {
      return {
        ...state,
        crm: {
          ...state.crm,
          isLoadingOpportunities: false,
          opportunities: null,
          error: action.payload,
        },
      };
    }
    case IntegrationsActions.SET_OPPORTUNITY:
    case IntegrationsActions.CLEAR_OPPORTUNITIES: {
      return {
        ...state,
        crm: {
          ...state.crm,
          isLoadingOpportunities: false,
          opportunities: null,
          error: null,
        },
      };
    }

    case IntegrationsActions.EMAIL_OAUTH_START: {
      return {
        ...state,
        email: {
          ...state.email,
          isAuthInProgress: true,
          error: null,
        },
      };
    }
    case IntegrationsActions.EMAIL_OAUTH_SUCCESS: {
      return {
        ...state,
        email: {
          ...state.email,
          isAuthInProgress: false,
          error: null,
        },
      };
    }
    case IntegrationsActions.EMAIL_OAUTH_FAILURE: {
      return {
        ...state,
        email: {
          ...state.email,
          isAuthInProgress: false,
          error: action.payload,
        },
      };
    }
    case IntegrationsActions.CHECK_EMAIL_AUTH_REQUEST: {
      return {
        ...state,
        email: {
          ...state.email,
          isLoading: true,
          error: null,
        },
      };
    }
    case IntegrationsActions.CHECK_EMAIL_AUTH_FAILURE: {
      return {
        ...state,
        email: {
          ...state.email,
          isLoading: false,
          isAuthenticated: false,
          error: action.payload,
        },
      };
    }
    case IntegrationsActions.CHECK_EMAIL_AUTH_SUCCESS: {
      const { gmail_send_enabled: hasGmail, outlook_send_enabled: hasOutlook } = action.payload || {};
      return {
        ...state,
        email: {
          ...state.email,
          isLoading: false,
          isAuthenticated: hasGmail || hasOutlook || false,
          error: null,
        },
      };
    }
    case IntegrationsActions.GENERATE_ZAPIER_API_KEY_REQUEST: {
      return {
        ...state,
        zapier: {
          isLoading: true,
          error: null,
          apiKey: '',
        },
      };
    }
    case IntegrationsActions.GENERATE_ZAPIER_API_KEY_FAILURE: {
      return {
        ...state,
        zapier: {
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case IntegrationsActions.GENERATE_ZAPIER_API_KEY_SUCCESS: {
      return {
        ...state,
        zapier: {
          ...state.zapier,
          isLoading: false,
        },
      };
    }
    case IntegrationsActions.GET_USER_API_KEY_REQUEST: {
      return {
        ...state,
        zapier: {
          ...state.zapier,
          isLoading: true,
          wasLoaded: false,
        },
      };
    }
    case IntegrationsActions.GET_USER_API_KEY_SUCCESS: {
      return {
        ...state,
        zapier: {
          ...state.zapier,
          isLoading: false,
          wasLoaded: true,
          apiKey: action.payload,
        },
      };
    }
    case IntegrationsActions.GET_USER_API_KEY_FAILURE: {
      return {
        ...state,
        zapier: {
          ...state.zapier,
          isLoading: false,
          error: action.payload,
          wasLoaded: true,
        },
      };
    }
    case FirebaseActions.LOGOUT:
    case AuthActions.ADMIN_ROLE_CHANGED:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
