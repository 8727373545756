import { crmSupports, emailSupports } from '../constants/integrations';
import { CRM_TYPE } from '../constants/shipping';
import { IOpportunityResponse, ISearchItem } from './crm';
import { IPromiseCallbacks } from './redux';

//#region Related enums

export enum IntegrationsEnum {
  Salesforce,
  Hubspot,
  Gmail,
  Outlook,
}

//#endregion

//#region State interface

export interface IIntegrationsState {
  crm: {
    isAuthInProgress: boolean;
    isAuthenticated: boolean | undefined;
    isLoading: boolean;
    clientId?: string;
    isSearching: boolean;
    isLoadingOpportunities: boolean;
    searchItems: ISearchItem[] | null;
    opportunities: IOpportunityResponse | null;
    error: any;
  };
  email: {
    isAuthInProgress: boolean;
    isAuthenticated: boolean | undefined;
    isLoading: boolean;
    error: any;
  };
  zapier: {
    isLoading: boolean;
    error: any;
    wasLoaded?: boolean;
    apiKey?: string;
  };
}

//#endregion

//#region Main and related interfaces and types

export interface IIntegrationElement {
  type: IntegrationsEnum;
  title: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  className: string;
  status?: IIntegrationStatus;
  buttonTitle?: string;
}

export interface IIntegrationStatus {
  isLoading: boolean | undefined;
  isAuthenticated: boolean | undefined;
  isAuthInProgress: boolean | undefined;
  error?: any;
}

export interface IZapierAPIKey {
  api_key: string;
  user_email: string;
}

export type TCrmSupports = typeof crmSupports[number];

export type TEmailSupports = typeof emailSupports[number];

//#endregion

//#region Request Interfaces and Types

export interface ISearchLeadsAndContactsPayload extends IPromiseCallbacks {
  query: string;
  limit: number;
  [CRM_TYPE]?: TCrmSupports;
  signal?: AbortSignal;
}

//#endregion
