import * as React from 'react';

import styles from './FlowPickerItem.module.scss';

interface IProps {
  title?: string | React.ReactNode;
  description?: string;
  onClick?: (...args: any[]) => void;
  icon: React.ReactNode;
}

const FlowPickerItem: React.FC<IProps> = ({ title, description, onClick, icon }) => {
  return (
    <div className={styles.flowPickerItem} onClick={onClick}>
      <div className={styles.iconInner}>
        <div className={styles.icon} style={{ backgroundImage: `url(${icon})` }} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default FlowPickerItem;
