import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DropdownWithSpinner } from '../../components';
import { AddNoteForm } from '../../components/forms';
import InventorySidebar from '../../components/InventorySidebar/InventorySidebar';
import { AI_DID_NO_CHANGES_WARNING } from '../../constants/ai';
import {
  ADD_BULK_NOTE_VALIDATION_SCHEMA,
  ADD_NOTE_VALIDATION_SCHEMA,
  ADD_ONE_LINK_NOTE_VALIDATION_SCHEMA,
  INITIAL_ADD_NOTE_FORM_STATE,
  MESSAGE,
  NOTE_TEMPLATE_ID,
} from '../../constants/templates';
import { addNote, toggleSkipNote } from '../../store/actions/bucket';
import { improveTextRequest } from '../../store/actions/shell';
import { fetchNotesTemplatesRequest } from '../../store/actions/templates';
import { selectIsSignedIn } from '../../store/selectors/auth';
import {
  selectBucketItems,
  selectIsNoteSkipped,
  selectNoteInformation,
  selectPYGCampaignsList,
  selectSelectedTemplate,
  selectTemplateValues,
} from '../../store/selectors/bucket';
import { selectNoteTemplates, selectNoteTemplatesIsLoading } from '../../store/selectors/templates';
import { IFlowStepProps } from '../../types/bucket';
import {
  IChatGPTSelectorValue,
  IImproveTextResponse,
  InventorySidebarTypeEnum,
  NotificationListEnum,
} from '../../types/shell';
import { IAddNoteFormValues, INoteTemplate } from '../../types/templates';
import notification from '../../utils/notification';
import { replaceLineBreaks, setTemplateTokens } from '../../utils/template';
import { handleApiError } from '../../utils/ui';

import styles from './AddNoteContainer.module.scss';

const AddNoteContainer: React.FC<IFlowStepProps> = ({ next, isBulk, isOneLink, SenderInfo }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const bucketItems = useSelector(selectBucketItems);
  const PYGCampaignsList = useSelector(selectPYGCampaignsList);
  const note = useSelector(selectNoteInformation);
  const selectedTemplate = useSelector(selectSelectedTemplate);
  const messageTemplates = useSelector(selectNoteTemplates);
  const isLoadingNotes = useSelector(selectNoteTemplatesIsLoading);
  const isSignedIn = useSelector(selectIsSignedIn);
  const templateValues = useSelector(selectTemplateValues);
  const isSkipped = useSelector(selectIsNoteSkipped);

  const [isTextImproveSubmitting, toggleTextImproveSubmitting] = React.useState(false);

  const fetchMessageTemplates = React.useCallback(() => {
    if (!isSkipped && !isLoadingNotes && !messageTemplates?.length) {
      dispatch(fetchNotesTemplatesRequest());
    }
  }, [dispatch, isSkipped, isLoadingNotes, messageTemplates]);

  const persistNote = React.useCallback(
    (noteToAdd: IAddNoteFormValues) => {
      dispatch(addNote(noteToAdd));
    },
    [dispatch],
  );

  const formatTemplate = React.useCallback(
    (id: string | undefined) => {
      if (id && messageTemplates && messageTemplates.length) {
        const { item_id, body } =
          messageTemplates.find((template: INoteTemplate): INoteTemplate | null => {
            return id === template.item_id ? template : null;
          }) || {};

        if (item_id !== undefined && body !== undefined) {
          return {
            [NOTE_TEMPLATE_ID]: item_id,
            [MESSAGE]:
              isBulk || isOneLink
                ? replaceLineBreaks(body)
                : setTemplateTokens(replaceLineBreaks(body), templateValues), // No need to parse if in Bulk mode
          };
        }
      }
      return null;
    },
    [messageTemplates, templateValues, isBulk, isOneLink],
  );

  const handleSelectTemplate = React.useCallback(
    (id: string) => {
      const newNote = formatTemplate(id);
      if (newNote) {
        persistNote(newNote);
      }
    },
    [formatTemplate, persistNote],
  );

  const emptyTemplate = React.useMemo(
    () => ({
      ...INITIAL_ADD_NOTE_FORM_STATE,
      note_template_id: null,
    }),
    [],
  );

  React.useEffect(() => {
    if (!isSkipped && note?.note_template_id) {
      persistNote(note);
    }

    if (isSkipped) {
      persistNote(emptyTemplate);
    }
  }, [note?.note_template_id, isSkipped, emptyTemplate, persistNote]);

  React.useEffect(() => {
    // TODO: is this real case?
    if (isSignedIn) {
      fetchMessageTemplates();
    }
  }, [isSignedIn, fetchMessageTemplates]);

  const onHandleSubmit = (newNote: IAddNoteFormValues) => {
    if (isSkipped) {
      persistNote(emptyTemplate);
    }
    persistNote(newNote);
    if (next) {
      history.push(next);
    }
  };

  const handleImproveTextSubmit = React.useCallback(
    (value: IChatGPTSelectorValue, message: string) => {
      const { instruction, fieldName, config } = value;

      return new Promise((resolve, reject) => {
        toggleTextImproveSubmitting(true);
        dispatch(
          improveTextRequest({
            text: instruction(message || ''),
            ...config,
            resolve,
            reject,
          }),
        );
      })
        .then((res) => {
          if (!(res as IImproveTextResponse)?.text.trim()) {
            return notification.warning(NotificationListEnum.Warning, {
              content: AI_DID_NO_CHANGES_WARNING,
            });
          }
          persistNote({
            ...note,
            [(fieldName || MESSAGE) as keyof IAddNoteFormValues]: (res as IImproveTextResponse).text,
          } as IAddNoteFormValues);
          notification.success(NotificationListEnum.Success, {
            content: 'Text successfully improved!',
          });
        })
        .catch(handleApiError('Something bad happened. Text was not generated'))
        .finally(() => toggleTextImproveSubmitting(false));
    },
    [dispatch, note, persistNote],
  );

  const toggleSkip = React.useCallback(() => {
    dispatch(toggleSkipNote({ isNoteSkipped: !isSkipped }));
  }, [isSkipped, dispatch]);

  const validationSchema = React.useMemo(() => {
    switch (true) {
      case isBulk:
        return ADD_BULK_NOTE_VALIDATION_SCHEMA;
      case isOneLink:
        return ADD_ONE_LINK_NOTE_VALIDATION_SCHEMA;
      default:
        return ADD_NOTE_VALIDATION_SCHEMA;
    }
  }, [isBulk, isOneLink]);

  return (
    <div className={styles.addNoteContainer}>
      <div className={styles.formWrapper}>
        <p className={styles.subHeading}>Write your custom message below that will be included with your shipment</p>
        <div>
          {SenderInfo}
          <DropdownWithSpinner
            disabled={isSkipped}
            helperText="Template style"
            className={styles.dropdown}
            items={messageTemplates || []}
            selected={selectedTemplate}
            onSelect={handleSelectTemplate}
            isLoading={isLoadingNotes}
            placeholder="Select template"
          />
          <AddNoteForm
            onImproveText={handleImproveTextSubmit}
            isTextImproveSubmitting={isTextImproveSubmitting}
            onSubmit={onHandleSubmit}
            initialValues={note || INITIAL_ADD_NOTE_FORM_STATE}
            validation={validationSchema}
            toggleSkip={toggleSkip}
            isSkipped={isSkipped}
            isBulkEng={isBulk}
            isOneLinkEng={isOneLink}
          />
        </div>
      </div>
      <InventorySidebar
        className={styles.inventorySidebar}
        items={PYGCampaignsList || bucketItems}
        type={PYGCampaignsList ? InventorySidebarTypeEnum.Campaign : InventorySidebarTypeEnum.InventoryItem}
        label="Your Send"
        showQuantity
        showCount={false}
      />
    </div>
  );
};

export default AddNoteContainer;
