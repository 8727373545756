import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { IAutomatedCampaign, IAutomationsState } from '../../types/automations';
import * as AuthActions from '../actions/auth';
import * as AutomatedCampaignsActions from '../actions/automations';

export const initialState: IAutomationsState = {
  items: null,
  isLoading: false,
  error: null,
};

const reducer = (state: IAutomationsState = initialState, action: any): IAutomationsState => {
  switch (action.type) {
    case AutomatedCampaignsActions.FETCH_AUTOMATED_CAMPAIGNS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case AutomatedCampaignsActions.FETCH_AUTOMATED_CAMPAIGNS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        items: null,
      };
    }
    case AutomatedCampaignsActions.FETCH_AUTOMATED_CAMPAIGNS_SUCCESS: {
      const { automated_campaigns: responseItems } = action.payload || [];
      const items = [...(responseItems && responseItems.length ? responseItems : [])];
      return {
        ...state,
        isLoading: false,
        error: null,
        items,
      };
    }
    case AutomatedCampaignsActions.ADD_AUTOMATED_CAMPAIGN_REQUEST:
    case AutomatedCampaignsActions.UPDATE_AUTOMATED_CAMPAIGN_REQUEST: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case AutomatedCampaignsActions.DELETE_AUTOMATED_CAMPAIGN_REQUEST: {
      return {
        ...state,
        error: null,
      };
    }
    case AutomatedCampaignsActions.ADD_AUTOMATED_CAMPAIGN_FAILURE:
    case AutomatedCampaignsActions.UPDATE_AUTOMATED_CAMPAIGN_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }
    case AutomatedCampaignsActions.DELETE_AUTOMATED_CAMPAIGN_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case AutomatedCampaignsActions.ADD_AUTOMATED_CAMPAIGN_SUCCESS: {
      const items = state.items ? state.items.concat(action.payload) : { ...action.payload };

      return {
        ...state,
        isLoading: false,
        items,
      };
    }
    case AutomatedCampaignsActions.UPDATE_AUTOMATED_CAMPAIGN_SUCCESS: {
      const items = state.items ? state.items.slice() : null;

      const index = items?.findIndex(
        (automatedCampaign: IAutomatedCampaign) => automatedCampaign.uid === action.payload.uid,
      );

      if (typeof index !== 'undefined' && index !== -1) {
        items?.splice(index, 1, action.payload);
      }

      return {
        ...state,
        isLoading: false,
        items,
      };
    }
    case AutomatedCampaignsActions.DELETE_AUTOMATED_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        items: state.items?.filter((item: IAutomatedCampaign) => item.uid !== action.payload.id),
      };
    }
    case FirebaseActions.LOGOUT:
    case AuthActions.ADMIN_ROLE_CHANGED:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
