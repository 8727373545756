import qs from 'query-string';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { routes } from '../../constants/routing';
import { selectIsAuthenticated } from '../../store/selectors/auth';

type Props = RouteProps;

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
export default ({ ...rest }: Props) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { search } = useLocation();
  const { redirect } = qs.parse(search);

  return isAuthenticated ? (
    <Redirect to={{ pathname: redirect ? (redirect as string) : routes.dashboard }} />
  ) : (
    <Route {...rest} />
  );
};
