import cn from 'classnames';
import { FormikValues } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { OnChangeValue } from 'react-select';

import { selectFulfillmentCenterItems } from '../../../../store/selectors/fulfillmentCenters';
import { IFulfillmentCenterItem } from '../../../../types/fulfillmentCenters';
import { ISelectorValue } from '../../../../types/shell';
import { Selector } from '../../../forms/inputs';

import styles from './FulfillmentCenterSelect.module.scss';

export interface IFulfillmentCenterSelectProps extends FormikValues {
  isMulti?: boolean;
  readOnly?: boolean;
  className?: string;
  defaultValue?: string | null;
  excludedOptions?: string[] | string;
  isFiltered?: boolean;
}

const FulfillmentCenterSelect = ({
  name,
  containerClassName,
  value,
  onChange,
  readOnly = false,
  isFiltered = false,
  defaultValue,
  excludedOptions,
  ...rest
}: IFulfillmentCenterSelectProps) => {
  const fulfillmentCenters = useSelector(selectFulfillmentCenterItems);

  const fulfillmentCenterList = isFiltered
    ? fulfillmentCenters?.filter((fc) => fc.has_org_inventory)
    : fulfillmentCenters;

  const formattedValue = React.useMemo<OnChangeValue<ISelectorValue, boolean>>(() => {
    let result = null;
    if (Array.isArray(value)) {
      result = (value as string[])?.map((id: string) => {
        const fulfillmentCenter = fulfillmentCenterList
          ? fulfillmentCenterList.find((fc) => fc.uid === id)
          : ({} as IFulfillmentCenterItem);

        return {
          value: id,
          label: fulfillmentCenter ? fulfillmentCenter?.name : '',
        };
      });
    } else if (typeof value === 'string') {
      const fulfillmentCenter = fulfillmentCenterList
        ? fulfillmentCenterList.find((fc) => fc.uid === value)
        : ({} as IFulfillmentCenterItem);

      result = {
        value: fulfillmentCenter ? fulfillmentCenter?.uid : '',
        label: fulfillmentCenter ? fulfillmentCenter?.name : '',
      };
    }

    return result;
  }, [value, fulfillmentCenterList]);

  const formattedOptions = React.useMemo(() => {
    if (!fulfillmentCenterList) {
      return [];
    }

    let formattedData = fulfillmentCenterList;

    if (excludedOptions && excludedOptions.length) {
      formattedData = fulfillmentCenterList.filter((fc) =>
        Array.isArray(excludedOptions) ? !excludedOptions.includes(fc.uid) : excludedOptions !== fc.uid,
      );
    }

    return formattedData.map((fc) => ({ value: fc.uid, label: fc.name }));
  }, [fulfillmentCenterList, excludedOptions]);

  const handleChange = React.useCallback(
    (values: any) => {
      if (Array.isArray(values)) {
        if (!values || !values.length) {
          onChange?.({ currentTarget: { name, value: defaultValue ? [defaultValue] : null } });
        } else {
          onChange?.({
            currentTarget: {
              name,
              value: values.map(({ value: newValue }: any) => newValue),
            },
          });
        }
      } else {
        onChange?.({
          currentTarget: {
            name,
            value: values?.value || defaultValue,
          },
        });
      }
    },
    [onChange, name, defaultValue],
  );

  const isClearable = React.useMemo(() => {
    if (Array.isArray(value)) {
      return !(value.length === 1 && value[0] === defaultValue);
    }
    if (typeof value === 'string') {
      return !(value === defaultValue);
    }
  }, [value, defaultValue]);

  return (
    <Selector
      clearHint="Reset to default"
      isClearable={isClearable}
      containerClassName={cn(styles.container, containerClassName)}
      isSearchable={!readOnly}
      value={formattedValue}
      options={formattedOptions}
      onChange={handleChange}
      name={name}
      concreteOption={!isClearable ? defaultValue ?? '' : undefined}
      readOnly={readOnly}
      {...rest}
    />
  );
};
// );

export default FulfillmentCenterSelect;
