import cn from 'classnames';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import * as React from 'react';

import { DateFormatsEnum } from '../../../../constants/date';
import useModal from '../../../../hooks/useModal';
import { IDatePickerOutput, UISizeEnum } from '../../../../types/shell';
import { getUTCDate } from '../../../../utils/date';
import { ActionButton, ControlButton, DropdownWithContent } from '../../../index';
import DatePicker from '../DatePicker/DatePicker';

import styles from './DateRangePicker.module.scss';

interface IProps {
  value: IDatePickerOutput | null;
  maxDate?: Date;
  wide?: boolean;
  pickerClassName?: string;
  onReset?: () => void;
  onSelect?: (value: IDatePickerOutput) => void;
}

interface IControlProps {
  close: () => void;
}

/*
 * This component should be refactored and split by layout and business logic
 * TODO:
 *  https://bluebirdcx.atlassian.net/browse/EX-1420
 *  create ShrinkLayout component
 *  create WideLayout component
 *  move renderControls to separate component and reuse it in other similar places
 * */

const DateRangePicker = React.forwardRef(
  ({ value, maxDate, onReset, onSelect, wide, pickerClassName }: IProps, ref: React.Ref<HTMLButtonElement>) => {
    const { from, to } = React.useMemo(() => value || ({} as IDatePickerOutput), [value]);
    const maximumDate = React.useMemo(
      () => (maxDate ? getUTCDate(maxDate.toISOString()) : getUTCDate(new Date().toISOString())),
      [maxDate],
    );

    const [startDate, setStartDate] = React.useState<Date | null>(from ? getUTCDate(from) : null);
    const [endDate, setEndDate] = React.useState<Date | null>(to ? getUTCDate(to) : null);

    const {
      Modal: MobileModal,
      openModal: openMobileModal,
      closeModal: closeMobileModal,
      isOpen: isMobileModalOpened,
    } = useModal({ resetStyles: true });

    const handleSelect = React.useCallback(
      ({ close }: IControlProps) =>
        () => {
          const newFrom = startDate ? format(startDate, DateFormatsEnum.Start) : null;
          const newTo = (() => {
            if (endDate) {
              return format(endDate, DateFormatsEnum.End);
            }
            if (startDate) {
              return format(startDate, DateFormatsEnum.End);
            }
            return null;
          })();

          onSelect?.({
            from: newFrom,
            to: newTo,
          });
          close();
        },
      [startDate, endDate, onSelect],
    );

    const handleReset = React.useCallback(() => {
      setStartDate(null);
      setEndDate(null);
      onReset?.();
    }, [onReset]);

    React.useEffect(() => {
      if (startDate && endDate && isBefore(endDate, startDate)) {
        setEndDate(null);
      }
    }, [startDate, endDate]);

    const handleOpen = React.useCallback(() => {
      if (from) {
        setStartDate(getUTCDate(from));
      }
      if (to) {
        setEndDate(getUTCDate(to));
      }
    }, [from, to]);

    const handleClose = React.useCallback(() => {
      if (!startDate && !endDate) {
        onSelect?.({ from: null, to: null });
      }
    }, [startDate, endDate, onSelect]);

    const isStartDate = React.useCallback(
      (d: Date) => (startDate && isSameDay(startDate, d) ? styles.start : null),
      [startDate],
    );

    const handleModalOpen = React.useCallback(() => {
      handleOpen();
      openMobileModal();
    }, [handleOpen, openMobileModal]);

    React.useEffect(() => {
      if (wide && isMobileModalOpened) {
        closeMobileModal();
      }
    }, [wide, closeMobileModal, isMobileModalOpened]);

    const dayCount = React.useMemo(() => {
      if (startDate && endDate) {
        return differenceInDays(endDate, startDate) + 1;
      }
      if (startDate) {
        return 1;
      }
      return 0;
    }, [startDate, endDate]);

    const label = React.useMemo(() => {
      if (from || to) {
        const fromLabel = from ? format(getUTCDate(from), 'dd MMM yyyy') : 'Select';
        const toLabel = to ? format(getUTCDate(to), 'dd MMM yyyy') : 'Select';

        return `${fromLabel} - ${toLabel}`;
      }
      return 'Select date range';
    }, [from, to]);

    const renderControls = React.useCallback(
      ({ close }: IControlProps) => (
        <div className={styles.controls}>
          <div className={styles.rangeCount}>
            {dayCount} day{dayCount === 1 ? '' : 's'}
          </div>
          <ActionButton className={styles.controlBtn} size={UISizeEnum.Small} outlined onClick={handleReset}>
            Reset
          </ActionButton>
          <ActionButton className={styles.controlBtn} size={UISizeEnum.Small} onClick={handleSelect({ close })}>
            Select
          </ActionButton>
        </div>
      ),
      [handleReset, handleSelect, dayCount],
    );

    const shrinkLayout = React.useMemo(
      () => (
        <MobileModal>
          {() => (
            <div className={cn(styles.picker, styles.mobile)}>
              <div className={cn(styles.calendarsPanel)}>
                <DatePicker
                  openToDate={startDate || undefined}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={([start, end]: [Date, Date]) => {
                    setStartDate(start);
                    setEndDate(end);
                  }}
                  selectsRange
                  maxDate={maximumDate}
                  inline
                  disabledKeyboardNavigation
                  dayClassName={isStartDate}
                />
              </div>
              {renderControls({ close: closeMobileModal })}
            </div>
          )}
        </MobileModal>
      ),
      [MobileModal, startDate, endDate, renderControls, closeMobileModal, isStartDate, maxDate],
    );

    const wideLayout = React.useMemo(() => {
      return (
        <React.Fragment>
          <DatePicker
            selectsStart
            openToDate={startDate || undefined}
            startDate={startDate}
            endDate={endDate}
            onChange={(newStartDate) => setStartDate(Array.isArray(newStartDate) ? newStartDate[0] : newStartDate)}
            maxDate={maximumDate}
            inline
            disabledKeyboardNavigation
            dayClassName={isStartDate}
          />
          <DatePicker
            disabledKeyboardNavigation
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            openToDate={endDate || undefined}
            onChange={(newEndDate) => setEndDate(Array.isArray(newEndDate) ? newEndDate[1] : newEndDate)}
            maxDate={maximumDate}
            minDate={Array.isArray(startDate) ? startDate[0] : startDate}
            inline
          />
        </React.Fragment>
      );
    }, [startDate, endDate, isStartDate, maxDate]);

    return wide ? (
      <DropdownWithContent
        onOpen={handleOpen}
        onClose={handleClose}
        contentClassName={styles.container}
        label={({ isOpened }) => <ControlButton ref={ref} label={label} isOpened={isOpened} />}
      >
        {({ close }) => (
          <div className={cn(styles.picker, pickerClassName)}>
            <div className={cn(styles.calendarsPanel)}>{wideLayout}</div>
            {renderControls({ close })}
          </div>
        )}
      </DropdownWithContent>
    ) : (
      <React.Fragment>
        <ControlButton ref={ref} label={label} isOpened={isMobileModalOpened} onClick={handleModalOpen} />
        {shrinkLayout}
      </React.Fragment>
    );
  },
);

DateRangePicker.whyDidYouRender = false;

export default DateRangePicker;
