import * as React from 'react';

import { capitalize } from '../../../utils/ui';
import { ActionButton, BackButton } from '../../forms';
import Loader from '../../Loader/Loader';
import ModalActions from '../ModalActions/ModalActions';
import ModalHeader from '../ModalHeader/ModalHeader';

import styles from './CommonModals.module.scss';

interface IProps {
  onClose: () => void;
  onDelete: () => void;
  assetName?: string;
}

const DeleteItemModal: React.FC<IProps> = ({ onClose, onDelete, assetName = 'item' }: IProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const name = React.useMemo(() => assetName.toLowerCase(), [assetName]);

  const capitalizedName = React.useMemo(() => capitalize(name, true), [name]);

  const handleDeleteItem = React.useCallback(() => {
    onDelete();
    setIsLoading(true);
  }, [onDelete]);

  const isUser = React.useMemo(() => assetName === 'User', [assetName]);

  const DELETE_USER_TEXT = React.useMemo(() => {
    return " Once confirmed, we'll verify if this user has any open orders. If they do, you'll need to transfer those orders to another user.";
  }, []);

  return (
    <div className={styles.container}>
      <Loader isLoading={isLoading} className={styles.loader} />
      <ModalHeader className={styles.header} left={<BackButton className={styles.back} onClick={onClose} />} />
      <div className={styles.content}>
        <div className={styles.contentHeader}>Delete {capitalizedName}</div>
        <div className={styles.description}>
          Are you sure you want to delete this {name}?{isUser && DELETE_USER_TEXT}
        </div>
      </div>
      <ModalActions className={styles.footer}>
        <ActionButton className={styles.delete} title="Delete" onClick={handleDeleteItem} />
      </ModalActions>
    </div>
  );
};

export default DeleteItemModal;
