import React from 'react';
import { useSelector } from 'react-redux';

import { ControlButton, DepartmentSelect, DropdownWithContent } from '../components';
import { TDropdownProps } from '../components/DropdownWithContent/DropdownWithContent';
import { IDepartmentSelectProps } from '../components/forms/inputs/DepartmentSelect/DepartmentSelect';
import { selectCurrentOrganizationDepartmentList } from '../store/selectors/organizations';
import { IUseFilterSelectorContainerProp } from '../types/shell';

interface IUseDeptOptions<FilteredItemType> {
  departments: string | string[] | null;
  filteredItems: FilteredItemType[];
  setDepartments: React.Dispatch<React.SetStateAction<string | string[] | null>>;
  DeptFilter: React.FC<IDeptFilterProp>;
}

interface IDeptFilterProp extends IDepartmentSelectProps {
  selectContainer?: React.FC<IUseFilterSelectorContainerProp>;
}

function useDeptFilter<ItemType>(items: null, fieldName: null, props: TDropdownProps): IUseDeptOptions<ItemType>;
function useDeptFilter<ItemType>(
  items: ItemType[],
  fieldName: keyof ItemType,
  props: TDropdownProps,
): IUseDeptOptions<ItemType>;
function useDeptFilter<IItemType>(
  items: IItemType[] | null,
  fieldName: keyof IItemType | null,
  props: TDropdownProps,
): IUseDeptOptions<IItemType> {
  const [selectedDepartments, setSelectedDepartments] = React.useState<string | string[] | null>(null);
  const [candidate, setCandidate] = React.useState<string | string[] | null>(null);

  const departments = useSelector(selectCurrentOrganizationDepartmentList) ?? [];

  const DeptFilter: React.FC<IDeptFilterProp> = React.useMemo(
    () =>
      ({ selectContainer, isMulti = false, ...departmentSelectProp }) => {
        // Use default container which does nothing if selectContainer is unset
        const SelectorContainer: React.FC<IUseFilterSelectorContainerProp> =
          selectContainer || (({ children }) => <React.Fragment>{children}</React.Fragment>);

        const revertToPreviousValue = () => setCandidate(selectedDepartments);

        const label = (isOpened: boolean) => {
          const value =
            selectedDepartments && departments
              ? departments
                  .filter((department) => selectedDepartments.includes(department.uid))
                  .map(({ name }) => name)
                  .join(', ')
              : '';

          return <ControlButton label={value || `Department (${departments.length - 1})`} isOpened={isOpened} />;
        };

        const handleOnChangeDepartmentSelect = (selectValue: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = selectValue.currentTarget || {};
          setCandidate(value);

          if (!isMulti) {
            setSelectedDepartments(value);
          }
        };

        return (
          // selectedDepartments and candidate are synchronized in onOpen event
          <DropdownWithContent
            {...props}
            label={({ isOpened }) => label(isOpened)}
            onOpen={revertToPreviousValue}
            onClose={() => {
              if ((!candidate || !candidate.length) && isMulti) {
                setSelectedDepartments(candidate);
              }
            }}
          >
            {({ close, isOpened }) => (
              <SelectorContainer
                close={close}
                isOpened={isOpened}
                select={() => {
                  setSelectedDepartments(candidate);
                  close();
                }}
                reset={() => setCandidate(null)}
              >
                <DepartmentSelect
                  {...departmentSelectProp}
                  isMulti={isMulti}
                  placeholder="Search department"
                  value={candidate}
                  onChange={handleOnChangeDepartmentSelect}
                  menuIsOpen
                />
              </SelectorContainer>
            )}
          </DropdownWithContent>
        );
      },
    [departments, selectedDepartments, candidate, props],
  );

  const filteredItems = React.useMemo(() => {
    if (!selectedDepartments) {
      return items || [];
    }

    return fieldName && items
      ? items.filter((item) => {
          if (Array.isArray(item[fieldName])) {
            // FIXME: It's a quiz! Remove `any` if you can
            return (item[fieldName] as any).some((id: string) => selectedDepartments.includes(id));
          }

          if (typeof item[fieldName] === 'string') {
            // FIXME: It's a quiz! Remove `any` if you can
            return selectedDepartments.includes(item[fieldName] as any);
          }

          return true;
        })
      : [];
  }, [selectedDepartments, items, fieldName]);

  return {
    departments: selectedDepartments,
    setDepartments: setSelectedDepartments,
    filteredItems,
    DeptFilter,
  };
}

export default useDeptFilter;
