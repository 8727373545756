import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useHeaderState } from '../../contexts/HeaderInfo';
import useFlowGuard from '../../hooks/useFlowGuard';
import useWindowSize from '../../hooks/useWindowSize';
import { selectFirstName } from '../../store/selectors/auth';
import { BackButton } from '../forms';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import UserBudget from '../UserBudget/UserBudget';

import styles from './Header.module.scss';

const Header: React.FC = () => {
  const { title, action, isShowHeader, additionalData, className } = useHeaderState();
  const { mobile, smallTablet } = useWindowSize();
  const { isSingle: isInSingleFlow } = useFlowGuard(false);

  const firstName = useSelector(selectFirstName);

  const heading = React.useMemo(() => {
    return title || `Hello${firstName ? `, ${firstName}` : ''}!`;
  }, [title, firstName]);

  const onBack = React.useCallback(() => {
    if (typeof action === 'function') {
      action();
      return;
    }
  }, [action]);

  return isShowHeader ? (
    <header className={className}>
      <div className={styles.information}>
        <div className={styles.titleWrapper}>
          {action && <BackButton className={styles.back} onClick={onBack} />}
          {typeof heading === 'string' ? <h1 className={cn(styles.title)}>{heading}</h1> : heading}
        </div>
        {additionalData}
      </div>
      {!mobile && (
        <div className={cn(styles.controls)}>
          {isInSingleFlow && <UserBudget hideAvailableBudget={smallTablet} />}
          <HeaderMenu />
        </div>
      )}
    </header>
  ) : null;
};

export default Header;
