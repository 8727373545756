import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as ArrowRightIcon } from '../../assets/images/icon-arrow-right.svg';
import {
  RECEIVER_COMPANY_NAME,
  RECEIVER_EMAIL,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  SENDER_EMAIL,
  SENDER_FIRST_NAME,
  SENDER_LAST_NAME,
} from '../../constants/shipping';
import { DEPARTMENT_ID } from '../../constants/users';
import { selectCurrentOrganizationDepartmentList } from '../../store/selectors/organizations';
import { IReport } from '../../types/reports';
import { getReportStatusAndDate, isValidScheduledEngagement, isZapierEngagement } from '../../utils/reports';
import { getDepartmentLabelById } from '../../utils/users';
import { CopyableButton } from '../forms';
import { Card, ReportsStatus } from '../index';
import CreatedByLabel from './CreatedByLabel/CreatedByLabel';
import DetailsCard from './DetailsCard/DetailsCard';
import PersonCard from './PersonCard/PersonCard';
import SendIcons from './SendIcons/SendIcons';

import styles from './EngagementCard.module.scss';

interface IProps {
  className?: string;
  engagement: IReport;
  controls?: React.ReactNode | React.ReactNode[];
  wide?: boolean;
}

// TODO: Rename it to ReportCard
// TODO: Create a separate component for AdminTools -> SendManager
const EngagementCard = ({ className, engagement, wide = false, controls }: IProps) => {
  const departments = useSelector(selectCurrentOrganizationDepartmentList);

  const { order_id: orderId } = React.useMemo(() => engagement, [engagement]);

  const isScheduledEngagement = React.useMemo(() => isValidScheduledEngagement(engagement), [engagement]);
  const { label: status, color: backgroundColor } = React.useMemo(
    () => getReportStatusAndDate(engagement),
    [engagement],
  );

  const getDepartmentLabel = React.useCallback((id: string) => getDepartmentLabelById(id, departments), [departments]);

  const fromZapier = React.useMemo(() => isZapierEngagement(engagement), [engagement]);

  return (
    <article className={cn(styles.container, className, { [styles.expanded]: wide })}>
      <div className={styles.status}>
        <CopyableButton inline value={orderId} className={styles.orderId}>
          {orderId}
        </CopyableButton>
        {!isScheduledEngagement && (
          <ReportsStatus className={styles.statusModule} status={status} backgroundColor={backgroundColor} />
        )}
      </div>
      <Card
        className={cn(styles.card)}
        middleClassName={styles.people}
        bottomClassName={styles.controlsWrapper}
        top={wide && <CreatedByLabel className={styles.createdBy} instance={engagement} fromZapier={fromZapier} />}
        middle={
          <React.Fragment>
            <PersonCard
              className={styles.sender}
              label="Sender"
              top={`${engagement[SENDER_FIRST_NAME]} ${engagement[SENDER_LAST_NAME]}`}
              middle={engagement[SENDER_EMAIL]}
              bottom={getDepartmentLabel(engagement[DEPARTMENT_ID])}
            />

            <ArrowRightIcon className={styles.arrowIcon} />

            <PersonCard
              label="Receiver"
              top={`${engagement[RECEIVER_FIRST_NAME]} ${engagement[RECEIVER_LAST_NAME]}`}
              middle={engagement[RECEIVER_EMAIL]}
              bottom={engagement[RECEIVER_COMPANY_NAME]}
            />
          </React.Fragment>
        }
        bottom={
          !wide ? (
            <React.Fragment>
              {<SendIcons instance={engagement} />}
              {controls ? <div className={styles.controls}>{controls}</div> : null}
            </React.Fragment>
          ) : null
        }
      />
      {wide && <DetailsCard className={cn(styles.card, styles.details)} instance={engagement} controls={controls} />}
    </article>
  );
};

export default EngagementCard;
