import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsSuperAdmin } from '../../store/selectors/auth';
import { selectFulfillmentCenterById } from '../../store/selectors/fulfillmentCenters';

import styles from './FCDetails.module.scss';

interface IProps {
  productSku?: string;
  fulfillmentCenterId?: string;
  isHighlighted?: boolean;
  isDisabled?: boolean;
  className?: string;
}

const FCDetails: React.FC<IProps> = ({ fulfillmentCenterId, productSku, isHighlighted, className, isDisabled }) => {
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const { name: fcName } = useSelector(selectFulfillmentCenterById(fulfillmentCenterId)) || {};

  return productSku || fcName ? (
    <div
      className={cn(
        styles.fcDetails,
        className,
        { [styles.highlighted]: isHighlighted },
        { [styles.disabled]: isDisabled },
      )}
    >
      {productSku && <span title={`SKU: ${productSku}`}>{productSku}</span>}
      {isSuperAdmin && fcName && (
        <span className={styles.fcLabel} title={`FC: ${fcName}`}>
          {fcName}
        </span>
      )}
    </div>
  ) : null;
};

export default FCDetails;
