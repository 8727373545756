import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ModalContainer.module.scss';

import FadeInOut from '../../animations/FadeInOut';
import Overlay from '../../components/Overlay/Overlay';
import { MODAL_ACTIONS, MODAL_COMPONENTS } from '../../constants/modals';
import useNoScroll from '../../hooks/useNoScroll';
import {
  selectDownloadSuccessModal,
  selectLowInventoryModal,
  selectOpportunitiesModal,
  selectRemovePIIModal,
  selectResetPasswordModal,
  selectRocketModal,
} from '../../store/selectors/modals';

const ModalContainer: React.FC = () => {
  const dispatch = useDispatch();
  const modals = [
    useSelector(selectResetPasswordModal),
    useSelector(selectRocketModal),
    useSelector(selectOpportunitiesModal),
    useSelector(selectDownloadSuccessModal),
    useSelector(selectLowInventoryModal),
    useSelector(selectRemovePIIModal),
  ];

  const hideModal = React.useCallback(
    (modalId: string) => {
      const action = MODAL_ACTIONS[modalId];

      return dispatch(action(false));
    },
    [dispatch],
  );

  const shouldSetNoScroll = React.useMemo(() => modals.some((m) => m?.isOpen), [modals]);

  useNoScroll(shouldSetNoScroll);

  return (
    <React.Fragment>
      {modals.map((modal) => {
        if (modal) {
          const ModalComponent = MODAL_COMPONENTS[modal.id];
          const handleClose = () => hideModal(modal.id);
          const handleOverlayClose = () => {
            if (!modal.required) {
              hideModal(modal.id);
            }
          };
          const style = {
            ...(modal.background ? { backgroundImage: `url(${modal.background})` } : {}),
          };

          return (
            <React.Fragment key={modal.id}>
              <Overlay
                onClick={handleOverlayClose}
                active={modal.isOpen}
                className={cn(styles.overlay, { [styles.required]: modal.required })}
              />
              <FadeInOut
                isOpen={modal.isOpen}
                className={cn(styles.modalContainer, styles[modal.id], {
                  [styles.default]: !modal.resetStyles,
                })}
                style={style}
              >
                {ModalComponent ? <ModalComponent onClose={handleClose} /> : null}
              </FadeInOut>
            </React.Fragment>
          );
        }
        return null;
      })}
    </React.Fragment>
  );
};

export default ModalContainer;
