import cn from 'classnames';
import * as React from 'react';

import { DIGITAL_CAMPAIGN_KEY } from '../../../constants/inventories';
import { Input } from '../inputs';
import Common, { IChildRenderProps } from './Common';
import { IRenderInventoryProps } from './InventoryItemForm';

import styles from './InventoryItemForm.module.scss';

const Digital = (props: IRenderInventoryProps) => {
  const { disabled, form, requiredFields, onInputChange, getFieldErrors, readOnly } = props;

  //#region Components to render

  const digitalCampaignInput = React.useMemo(() => {
    return (
      <div className={cn(styles.additionalInfoInputWrapper, styles.input)}>
        <Input
          field={form.getFieldProps(DIGITAL_CAMPAIGN_KEY)}
          helperText="Digital Campaign Key"
          name={DIGITAL_CAMPAIGN_KEY}
          placeholder="Digital Campaign Key"
          disabled={disabled}
          value={form.values[DIGITAL_CAMPAIGN_KEY]}
          onChange={onInputChange}
          error={getFieldErrors(DIGITAL_CAMPAIGN_KEY)}
          showErrors={false}
          readOnly={readOnly}
          required={requiredFields[DIGITAL_CAMPAIGN_KEY]}
        />
      </div>
    );
  }, [
    form.values[DIGITAL_CAMPAIGN_KEY],
    form.getFieldProps,
    onInputChange,
    getFieldErrors,
    readOnly,
    disabled,
    requiredFields[DIGITAL_CAMPAIGN_KEY],
  ]);

  //#endregion

  return (
    <Common {...props}>
      {({
        nameInput,
        descriptionTextArea,
        imageInput,
        departmentsInput,
        priceInput,
        hiddenOrgIdInput,
        hiddenTypeInput,
      }: IChildRenderProps) => (
        <form onSubmit={form.handleSubmit} className={styles.inventoryItemForm}>
          <div className={styles.baseInfo}>
            {imageInput}
            <div className={styles.info}>
              <div className={cn(styles.infoRow, styles.headerInputRow)}>
                {departmentsInput}
                {nameInput}
                {priceInput}
              </div>
            </div>
          </div>
          {descriptionTextArea}
          <div className={styles.additionalInfo}>{digitalCampaignInput}</div>
          {hiddenOrgIdInput}
          {hiddenTypeInput}
        </form>
      )}
    </Common>
  );
};

export default Digital;
