import cn from 'classnames';
import { FieldAttributes } from 'formik';
import React, { useCallback } from 'react';

import { Checkbox } from '../index';

import styles from './CheckboxGroup.module.scss';

export interface ICheckboxProps extends FieldAttributes<any> {
  containerClassName?: string;
  className?: string;
  id?: string;
  checked?: boolean;
  text?: string | React.ReactNode;
  textAbout?: string;
}

interface IProps {
  containerClassName?: string;
  onChange: (name: string, value: boolean) => void;
  items: ICheckboxProps[];
}

const CheckboxGroup = ({ containerClassName, onChange, items }: IProps) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.currentTarget;
      onChange(name, checked);
    },
    [onChange],
  );

  return (
    <div className={cn(styles.container, containerClassName, styles.checkboxContainer)}>
      {items &&
        items.map((item: ICheckboxProps) => {
          return (
            <Checkbox
              key={item.id}
              id={item.id}
              onChange={handleChange}
              checked={item.checked}
              text={item.text}
              hint={item.textAbout}
              name={item.name}
            />
          );
        })}
    </div>
  );
};

export default CheckboxGroup;
