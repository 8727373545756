import cn from 'classnames';
import * as React from 'react';

import { IHoverContent, TSendsDashboardSummaryUnion } from '../../types/dashboard';
import { capitalize } from '../../utils/ui';
import { GraphListItem } from '../index';

import styles from './GraphList.module.scss';

interface IProps {
  className?: string;
  items?: TSendsDashboardSummaryUnion;
  onHover: (content: IHoverContent | null) => void;
  onPointerLeave: () => void;
}

const GraphList = ({ className, items, onHover, onPointerLeave }: IProps) => {
  return (
    <div className={cn(styles.container, className)} onPointerLeave={onPointerLeave}>
      {Object.entries(items || {}).map(([label, item]) => {
        const { total_count, percentage, total_price, currency_code, manual_count, automated_count } = item;

        return (
          <GraphListItem
            label={capitalize(label, true)}
            item={item}
            key={`${label}-${total_count}-${percentage}`}
            className={styles.row}
            onPointerEnter={() =>
              onHover({
                total: total_price,
                currency_code,
                count: {
                  total: total_count,
                  manual: manual_count,
                  automated: automated_count,
                },
              })
            }
            onPointerLeave={() => onHover(null)}
          />
        );
      })}
    </div>
  );
};

export default GraphList;
