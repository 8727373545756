import cn from 'classnames';
import * as React from 'react';

import FadeInOut from '../../animations/FadeInOut';
import Overlay from '../../components/Overlay/Overlay';
import useBluebirdPortal from '../../hooks/useBluebirdPortal';

import styles from './ModalContainer.module.scss';

interface IProps<T> {
  className?: string;
  overlayClassName?: string;
  children(props: T): React.ReactNode;
  childProps: T;
  closeModal: () => void;
  openModal: (props?: T) => void;
  isOpen: boolean;
  required?: boolean;
  resetStyles?: boolean;
}

// tslint:disable-next-line: prettier
const ModalPortal = <PropertyType,>({
  children,
  overlayClassName,
  childProps,
  closeModal,
  isOpen,
  className,
  required = false,
  resetStyles = false,
}: IProps<PropertyType>) => {
  const { portal } = useBluebirdPortal();

  return portal(
    <React.Fragment>
      <FadeInOut
        isOpen={isOpen}
        className={cn(styles.modalContainer, className, {
          [styles.default]: !resetStyles,
        })}
      >
        {children(childProps)}
      </FadeInOut>
      <Overlay
        {...(!required ? { onClick: closeModal } : {})}
        active={isOpen}
        className={cn(styles.overlay, overlayClassName, { [styles.required]: required })}
      />
    </React.Fragment>,
  );
};

export default ModalPortal;
