// Order matters!
// 'slug' determines the flow step
// 'next' and 'prev' are calculated values. We should set them here only if we need to override the calculations
import { IFlowStep } from '../types/bucket';
import { routes, URL_VARS } from './routing';

export const BULK_FLOW_SCHEMA: IFlowStep[] = [
  { slug: URL_VARS.BOX, prev: routes.dashboard },
  { slug: URL_VARS.NOTE },
  { slug: URL_VARS.EMAIL },
  { slug: URL_VARS.UPLOAD },
];

// Order matters!
export const SINGLE_FLOW_SCHEMA: IFlowStep[] = [
  { slug: URL_VARS.BOX, prev: routes.dashboard },
  { slug: URL_VARS.SHIPPING },
  { slug: URL_VARS.NOTE },
  { slug: URL_VARS.EMAIL },
  { slug: URL_VARS.SUMMARY },
];

export const ONE_LINK_FLOW_SCHEMA: IFlowStep[] = [
  { slug: URL_VARS.BOX, prev: routes.dashboard },
  { slug: URL_VARS.SHIPPING },
  { slug: URL_VARS.NOTE },
  { slug: URL_VARS.EMAIL },
  { slug: URL_VARS.SUMMARY },
];

export const DIRECT_FLOW_SCHEMA: IFlowStep[] = [
  { slug: URL_VARS.BOX, prev: routes.dashboard },
  { slug: URL_VARS.SHIPPING },
  { slug: URL_VARS.SUMMARY },
];

export const ENGAGEMENT_REMINDER_HELP_TEXT = 'We’ll send an email reminder if the gift is not redeemed.';
export const DELAYED_SHIPPING_EXPIRATION_DATE_HINT = 'This option lets you put the date when the send will be expired.';
