import { format, subDays } from 'date-fns';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as PlaceholderImage } from '../../assets/images/no-mobile-support.svg';

import { BlogSection, OrganizationSummaryList } from '../../components';
import { DateFormatsEnum } from '../../constants/date';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import useWindowSize from '../../hooks/useWindowSize';
import { fetchOrganizationSummariesRequest, setActiveOrganizationSummary } from '../../store/actions/dashboard';
import { selectActiveSummary, selectOrganizationSummaries } from '../../store/selectors/dashboard';

import styles from './DistributorDashboardContainer.module.scss';

// const newsMock = [
//   {
//     title: 'Distributor Dashboard: features and new stuff for sales people',
//     posted_on: '06.23.2021',
//     target_url: 'url-of-news1',
//     image_url: '/static/images/image-news-placeholder-2.png',
//   },
//   {
//     title: ' Inventory counts: new feature showing availability of items in stock',
//     posted_on: '06.21.2021',
//     target_url: 'url-of-news2',
//     image_url: '/static/images/image-news-placeholder-1.png',
//   },
// ];

const DistributorDashboardContainer = () => {
  const dispatch = useDispatch();
  const { setHeader } = useHeaderDispatch();
  const { medium } = useWindowSize();

  const organizationSummaries = useSelector(selectOrganizationSummaries);
  const activeSummary = useSelector(selectActiveSummary);

  const handleItemClick = useCallback(
    (id: string) => {
      dispatch(setActiveOrganizationSummary(id === activeSummary ? null : id));
    },
    [activeSummary, dispatch],
  );

  const fetchOrganizationSummaries = useCallback(() => {
    dispatch(
      fetchOrganizationSummariesRequest({
        date_range: {
          from: format(subDays(new Date(), 30), DateFormatsEnum.Start),
          to: format(new Date(), DateFormatsEnum.End),
        },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    setHeader?.({ title: 'Distributor Dashboard' });
  }, [setHeader]);

  useEffect(() => {
    fetchOrganizationSummaries();
  }, [fetchOrganizationSummaries]);

  return (
    <div className={styles.dashboardWrapper}>
      {medium ? (
        <div className={styles.noSupport}>
          <span className={styles.label}>
            <span>Unfortunately, we do not support this feature for mobile users. </span>
            <span>Please, visit desktop version to use the dashboard.</span>
          </span>
          <PlaceholderImage className={styles.image} />
        </div>
      ) : (
        <div className={styles.information}>
          <OrganizationSummaryList
            className={styles.summaryList}
            summaryList={organizationSummaries || null}
            activeSummary={activeSummary}
            onItemClick={handleItemClick}
          />
          {/* {
            newsMock.length && <BlogSection className={styles.news} items={newsMock} label="Product updates" />
            // TODO: Revisit: how should be displayed news section if no news
          } */}
        </div>
      )}
    </div>
  );
};

export default DistributorDashboardContainer;
