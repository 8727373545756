import cn from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';

import { DateSinglePicker, InputLabel } from '../../index';

import {
  DEFAULT_DELAYED_SHIPPING_EXPIRATION_DATE,
  MAX_DELAYED_SHIPPING_EXPIRATION_DATE,
  MIN_DELAYED_SHIPPING_EXPIRATION_DATE,
} from '../../../../constants/bucket';
import { DateFormatsEnum } from '../../../../constants/date';
import { UISizeEnum } from '../../../../types/shell';
import { Loader } from '../../../index';

import styles from './ExpirationDateInput.module.scss';

interface IProps {
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  minDate?: Date;
  maxDate?: Date;
  hint?: ReactNode;
  isLoading?: boolean;
  formatView?: DateFormatsEnum;
  readOnly?: boolean;
}

const ExpirationDateInput: React.FC<IProps> = ({
  onChange,
  value,
  className,
  hint,
  minDate = MIN_DELAYED_SHIPPING_EXPIRATION_DATE,
  maxDate = MAX_DELAYED_SHIPPING_EXPIRATION_DATE,
  isLoading,
  formatView = DateFormatsEnum.FullHumanReadable,
  readOnly,
}) => {
  const handleSelectDate = React.useCallback(
    (val: string | null) => {
      onChange?.(val ?? '');
    },
    [onChange],
  );

  return (
    <div className={cn(styles.container, className)}>
      <InputLabel
        name="expiration-date"
        containerClassName={styles.labelContainer}
        className={styles.label}
        hint={!isLoading ? hint : undefined}
        value={
          <>
            Expiration date
            {isLoading && <Loader isLoading size={UISizeEnum.Tiny} className={styles.spinner} />}
          </>
        }
      />
      <DateSinglePicker
        resetLabel="Cancel"
        readOnly={readOnly}
        minDate={minDate}
        maxDate={maxDate}
        contentClassName={styles.dateInput}
        className={cn(styles.datePicker, isLoading && styles.loading)}
        value={value ? value : DEFAULT_DELAYED_SHIPPING_EXPIRATION_DATE.toISOString()}
        formatRule={DateFormatsEnum.yyyyMMdd}
        formatView={formatView}
        onSelect={handleSelectDate}
        closeModalOnReset
      />
    </div>
  );
};

export default ExpirationDateInput;
