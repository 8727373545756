import { createTransform } from 'redux-persist';

import { ICampaignsState } from '../../types/campaigns';

const campaignsItemsTransforms = createTransform(
  (stateIn: ICampaignsState) => {
    return {
      ...stateIn,
      items: null,
    };
  },
  (stateOut: ICampaignsState) => stateOut,
  { whitelist: ['campaigns'] },
);

const campaignsMetaDataTransforms = createTransform(
  (stateIn: ICampaignsState) => stateIn,
  (stateOut: ICampaignsState) => {
    return {
      ...stateOut,
      error: null,
      isLoading: false,
    };
  },
  { whitelist: ['campaigns'] },
);

const transformArray = [campaignsItemsTransforms, campaignsMetaDataTransforms];

export default transformArray;
