import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { ChoosingItemTypeModal, DeleteItemModal } from '../../components';
import AdminRoute from '../../components/routes/AdminRoute';
import { routes, URL_VARS } from '../../constants/routing';
import { TEMPLATE_LABEL } from '../../constants/templates';
import { DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import { EditTemplatesContainer, TemplatesContainer } from '../../containers';
import useModal from '../../hooks/useModal';
import {
  addTemplateValue,
  deleteTemplateRequest,
  fetchEmailsTemplatesRequest,
  fetchNotesTemplatesRequest,
} from '../../store/actions/templates';
import { selectAdminType, selectUserDepartmentId } from '../../store/selectors/auth';
import { selectTemplateById, selectTemplatesIsLoaded } from '../../store/selectors/templates';
import { ITemplateRouteParams } from '../../types/routing';
import { NotificationListEnum } from '../../types/shell';
import { TCollectiveTemplateItem, TemplateTypesEnum } from '../../types/templates';
import notification from '../../utils/notification';
import { getAddTemplateInitialValue, includesOnlyThisDep } from '../../utils/template';
import { handleApiError } from '../../utils/ui';
import { hasPermission } from '../../utils/users';

const Templates: React.FC = () => {
  const { flowId, itemId: templateId } = useParams<ITemplateRouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const adminType = useSelector(selectAdminType);
  const userDepartmentId = useSelector(selectUserDepartmentId);
  const originalTemplate = useSelector(selectTemplateById(templateId));
  const isTemplatesLoaded = useSelector(selectTemplatesIsLoaded);

  const { openModal: openDeleteModal, closeModal: closeDeleteModal, Modal: DeleteModal } = useModal();
  const {
    openModal: openNewTemplateModal,
    closeModal: closeNewTemplateModal,
    Modal: AddNewTemplateModal,
  } = useModal({
    required: flowId === URL_VARS.NEW,
  });

  const getInitialValue = React.useCallback(
    (type: TemplateTypesEnum) => {
      return getAddTemplateInitialValue(type, adminType, userDepartmentId);
    },
    [adminType, userDepartmentId],
  );

  const handleDeleteItem = React.useCallback(
    ({ item_id: itemId, templateType: type }: TCollectiveTemplateItem) =>
      new Promise((resolve, reject) => {
        dispatch(deleteTemplateRequest({ itemId, type, resolve, reject }));
      })
        .then(() => {
          notification.success(NotificationListEnum.Success, { content: 'Template was successfully deleted' });
        })
        .catch(handleApiError(`Something bad happened. The template wasn't deleted.`))
        .finally(() => {
          if (typeof closeDeleteModal === 'function') {
            closeDeleteModal();
          }
        }),
    [dispatch, closeDeleteModal],
  );

  const handleAddNewItem = React.useCallback(
    (type: TemplateTypesEnum) => {
      dispatch(addTemplateValue(getInitialValue(type)));
      history.push(routes.templates.getTemplateUrl({ flowId: URL_VARS.NEW }));
    },
    [history, dispatch, getInitialValue],
  );

  const deleteItemModal = React.useMemo(
    () => (
      <DeleteModal className="common-modal">
        {(template: TCollectiveTemplateItem) => {
          return (
            <DeleteItemModal
              assetName={TEMPLATE_LABEL}
              onDelete={() => handleDeleteItem(template)}
              onClose={closeDeleteModal}
            />
          );
        }}
      </DeleteModal>
    ),
    [closeDeleteModal, handleDeleteItem, DeleteModal],
  );

  const newItemActions = React.useMemo(() => {
    return [
      { title: 'Custom message', onClick: () => handleAddNewItem(TemplateTypesEnum.Note) },
      { title: 'Email template', onClick: () => handleAddNewItem(TemplateTypesEnum.Email) },
    ];
  }, [handleAddNewItem]);

  const addNewItemModal = React.useMemo(
    () => (
      <AddNewTemplateModal className="common-modal">
        {({ required }: any) => {
          return (
            <ChoosingItemTypeModal
              assetName={TEMPLATE_LABEL}
              description="Do you want to add a custom message or email template?"
              actions={newItemActions}
              onClose={closeNewTemplateModal}
              showCloseButton={!required}
            />
          );
        }}
      </AddNewTemplateModal>
    ),
    [closeNewTemplateModal, newItemActions, AddNewTemplateModal],
  );

  const renderFlow = React.useMemo(() => {
    switch (true) {
      case flowId === URL_VARS.EDIT:
        if (!originalTemplate) {
          if (!isTemplatesLoaded) {
            return null;
          }

          notification.noAsset(TEMPLATE_LABEL.toLocaleLowerCase());
          return <Redirect to={{ pathname: routes.dashboard }} />;
        }

        // Department admin - are able to manage templates for their department only.
        if (hasPermission([DEPT_ADMIN], adminType)) {
          const { department_ids: depIds = [] } = originalTemplate || {};

          if (!includesOnlyThisDep(depIds, userDepartmentId)) {
            notification.accessDenied();
            return <Redirect to={{ pathname: routes.dashboard }} />;
          }
        }

        return (
          <AdminRoute
            roles={[DEPT_ADMIN, ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN]}
            render={() => <EditTemplatesContainer onAdd={openNewTemplateModal} getInitialValue={getInitialValue} />}
          />
        );
      case flowId === URL_VARS.NEW: {
        return (
          <AdminRoute
            roles={[DEPT_ADMIN, ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN]}
            render={() => <EditTemplatesContainer onAdd={openNewTemplateModal} getInitialValue={getInitialValue} />}
          />
        );
      }
      default:
        return <TemplatesContainer onDelete={openDeleteModal} onAdd={openNewTemplateModal} />;
    }
  }, [
    flowId,
    getInitialValue,
    openDeleteModal,
    openNewTemplateModal,
    originalTemplate,
    adminType,
    userDepartmentId,
    isTemplatesLoaded,
  ]);

  React.useEffect(() => {
    dispatch(fetchNotesTemplatesRequest());
    dispatch(fetchEmailsTemplatesRequest());
  }, [dispatch]);

  return (
    <React.Fragment>
      {renderFlow}
      {addNewItemModal}
      {deleteItemModal}
    </React.Fragment>
  );
};

export default Templates;
