import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useCurrency } from '../../contexts/CurrencyProvider';
import { selectAvailableBudget, selectUsedBudget } from '../../store/selectors/auth';

import { Price } from '../index';

import styles from './UserBudget.module.scss';

interface IProps {
  className?: string;
  hideAvailableBudget?: boolean;
}

const UserBudget = ({ className, hideAvailableBudget = false }: IProps) => {
  const { getRequiredPriceAndCurrency, budgetCurrency } = useCurrency();
  const availableBudget = useSelector(selectAvailableBudget);
  const usedBudget = useSelector(selectUsedBudget);
  const remainingBudget = usedBudget ? availableBudget - usedBudget : 0;

  return (
    <div className={cn(className, styles.userBudget)}>
      <Price
        value={getRequiredPriceAndCurrency({ price: remainingBudget, currency_code: budgetCurrency })}
        hint="Remaining Balance"
        className={styles.current}
      />
      {!hideAvailableBudget ? (
        <React.Fragment>
          <span className={styles.available}> / </span>
          <Price
            value={getRequiredPriceAndCurrency({ price: availableBudget, currency_code: budgetCurrency })}
            hint="Total Balance"
            className={styles.available}
            thousandSeparator
          />
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default UserBudget;
