import * as React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { routes, URL_VARS } from '../../constants/routing';
import { ReportsContainer, ReportSummaryContainer } from '../../containers';
import { IReportsRouteParams } from '../../types/routing';

const Reports: React.FC = () => {
  const { reportId, flowId } = useParams<IReportsRouteParams>();

  if (flowId && flowId !== URL_VARS.SUMMARY) {
    return <Redirect to={routes.reports.root} />;
  }

  if (reportId) {
    return <ReportSummaryContainer />;
  }

  return <ReportsContainer />;
};

export default Reports;
