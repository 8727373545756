import { createTransform } from 'redux-persist';

import { IUsersState } from '../../types/users';

const usersTransform = createTransform(
  (stateIn: IUsersState) => ({
    ...stateIn,
    items: null,
    error: null,
    isLoading: false,
  }),
  (stateOut: IUsersState) => ({
    ...stateOut,
    items: null,
    error: null,
    isLoading: false,
  }),
  { whitelist: ['users'] },
);

const transformArray = [usersTransform];

export default transformArray;
