import cn from 'classnames';
import debounce from 'lodash/debounce';
import React from 'react';
import * as yup from 'yup';

import { ReactComponent as SearchIcon } from '../../assets/images/icon-search.svg';

import { Input } from '../../components/forms';
import { IInputProps } from '../../components/forms/inputs/Input/Input';

import styles from './useSearchFilter.module.scss';

export default function useSearchFilter<T>(
  items: T[] | null | undefined,
  fieldNames: (keyof T)[],
  props?: IInputProps,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  validation?: yup.Schema<any>,
  error?: any,
) {
  const [query, setQuery] = React.useState<string>('');
  const [filterQuery, setFilterQuery] = React.useState<string>('');

  const handleFilterChange = React.useCallback(
    debounce((searchQuery) => {
      setFilterQuery(searchQuery);
    }, 500),
    [],
  );

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === 'function') {
        onChange(e);
      }
      setQuery(e.target.value);
      handleFilterChange(e.target.value);
    },
    [handleFilterChange, onChange],
  );

  const SearchInput: React.ReactComponentElement<any> = React.useMemo(() => {
    return (
      <Input
        {...props}
        className={cn(props?.className, styles.search)}
        value={query}
        icon={<SearchIcon title={typeof props?.hint === 'string' ? props.hint : undefined} />}
        onChange={handleChange}
      />
    );
  }, [query, handleChange, props]);

  const filteredItems = React.useMemo(() => {
    if (!items) {
      return [];
    }
    return items.filter((item) => {
      if (!query.trim()) {
        return true;
      }

      return fieldNames.some(
        (name) => typeof item[name] === 'string' && `${item[name]}`.toLowerCase().includes(query.toLowerCase()),
      );
    });
  }, [items, fieldNames, query]);

  return {
    filteredItems,
    SearchInput,
    query: filterQuery,
    error,
  };
}
