import cn from 'classnames';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import useWindowSize from '../../hooks/useWindowSize';
import { CopyableButton, InputLabel } from '../forms';
import { HelpTooltip } from '../tooltips';

import styles from './TemplateTagPicker.module.scss';

interface ITemplateTag {
  title: string;
  isRequired?: boolean | null;
  tooltip?: string | React.ReactNode;
}

interface IProps {
  className?: string;
  label?: string | React.ReactNode;
  tags: ITemplateTag[];
}

const TemplateTagPicker = ({ className, label, tags }: IProps) => {
  const { mobile } = useWindowSize();

  return (
    <div className={cn(styles.container, className)}>
      {typeof label === 'string' ? <InputLabel value={label} /> : label}
      {tags.map(({ title, isRequired, tooltip }) => (
        <CopyableButton key={title} value={title} className={cn(styles.tag, { [styles.required]: !!isRequired })}>
          <span>{title}</span>
          {isRequired && (
            <React.Fragment>
              <span
                data-for="main"
                data-tip="Required tag"
                data-border="true"
                data-border-color="black"
                className={styles.tooltipWrapper}
                onClick={(e) => e.stopPropagation()}
              >
                *
              </span>
              <ReactTooltip
                id="main"
                place={mobile ? 'top' : 'right'}
                type="light"
                effect="solid"
                offset={{ right: mobile ? 0 : 10 }}
                className={styles.requiredTooltip}
              />
            </React.Fragment>
          )}
          {tooltip && (
            <HelpTooltip
              id={title}
              className={cn(styles.tagTooltipWrapper, styles.questionMark)}
              contentClassName={styles.tagTooltipContent}
            >
              {tooltip}
            </HelpTooltip>
          )}
        </CopyableButton>
      ))}
    </div>
  );
};

export default TemplateTagPicker;
