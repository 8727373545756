import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { Toggle } from '..';
import { InputLabel } from '../../labels';

import styles from './ToggleInput.module.scss';

interface IChildProps {
  toggleInputStyles: any;
  isActive: boolean;
  isReadOnly?: boolean;
}

interface IProps {
  className?: string;
  name: string;
  value: string | number;
  toggleLabel?: React.ReactNode;
  inputLabel?: React.ReactNode;
  hint?: string | React.ReactNode;
  isReadOnly?: boolean;
  isRequired?: boolean;
  initValue?: string | number | null;
  onChange?: (name: string, value: string | number | boolean | null) => void;
  children: (childProps: IChildProps) => React.ReactElement | null;
  direction?: 'column' | 'row';
  disabled?: boolean;
}

const ToggleInput = ({
  className,
  name,
  value,
  toggleLabel,
  inputLabel,
  hint,
  isReadOnly,
  isRequired,
  initValue,
  disabled,
  onChange,
  children,
  direction = 'column',
}: IProps) => {
  const [isActive, setIsActive] = useState<boolean>(!!value);

  const handleToggleClick = useCallback(() => {
    setIsActive((prevActive) => !prevActive);
  }, [isActive]);

  useEffect(() => {
    if (isActive && initValue) {
      onChange?.(name, initValue);
    }
  }, [isActive, initValue, name, onChange]);

  useEffect(() => {
    if (typeof value === 'string') {
      if (!isActive && value.trim()) {
        onChange?.(name, '');
      }
    } else {
      if (!isActive) {
        onChange?.(name, null);
      }
    }

    if (disabled) {
      setIsActive(false);
      onChange?.(name, '');
    }
  }, [isActive, onChange, name, initValue, value, disabled]);

  return (
    <div className={cn(className, styles.container)}>
      {!isReadOnly && toggleLabel && <InputLabel value={toggleLabel} hint={hint} name={name} />}
      <div className={cn(styles.wrapper, styles[direction])}>
        {!isReadOnly && (
          <div className={styles.toggle}>
            <Toggle onClick={handleToggleClick} checked={isActive} name={name} disabled={disabled} />
          </div>
        )}
        <div className={styles.input}>
          <InputLabel
            containerClassName={styles.inputLabel}
            value={inputLabel}
            name={name}
            required={isActive && isRequired && !isReadOnly}
          />
          <div className={styles.field}>{children({ isActive, isReadOnly, toggleInputStyles: styles })}</div>
        </div>
      </div>
    </div>
  );
};

export default ToggleInput;
