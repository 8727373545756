import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as IconKeyboardDown } from '../../../assets/images/keyboard_arrow_down.svg';
import { BUTTON_BUTTON } from '../../../types/forms';

import styles from './AccordionItem.module.scss';

export interface IAccordionItemProps {
  id: string;
  isActive?: boolean;
  title: React.ReactNode | React.ReactNode[] | string;
  content: React.ReactNode | React.ReactNode[] | string;
  className?: string;
  contentClassName?: string;
  iconContainerClassName?: string;
  titleClassName?: string;
  onClick?: (id: string) => void;
}

const AccordionItem: React.FC<IAccordionItemProps> = ({
  id,
  isActive = false,
  title,
  content,
  className,
  contentClassName,
  titleClassName,
  iconContainerClassName,
  onClick,
}) => {
  const handleOnClick = React.useCallback(() => {
    onClick?.(id);
  }, [onClick, id]);

  return (
    <div className={cn(styles.item, { [styles.show]: isActive }, className)}>
      <div
        role={BUTTON_BUTTON}
        className={cn(styles.title, titleClassName, { [styles.clickOnIcon]: isActive })}
        {...(!isActive && { onClick: handleOnClick })}
      >
        <div className={cn(styles.iconContainer, iconContainerClassName)}>
          <IconKeyboardDown className={styles.icon} {...(isActive && { onClick: handleOnClick })} />
        </div>
        {title}
      </div>
      <div className={styles.contentWrapper}>
        <div className={cn(styles.content, contentClassName)}>{content}</div>
      </div>
    </div>
  );
};

export default AccordionItem;
