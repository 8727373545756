import { call, put, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { endpoints, queryParams } from '../../constants/routing';
import { IReduxAction } from '../../types/redux';
import { IEngagementOrderIdRequestActionPayload } from '../../types/tools';
import { getQuery } from '../../utils/request';
import * as ToolsActions from '../actions/tools';
import { AuthRequest } from './helpers';

function* fetchEngagementByOrderIdWorkerSaga(
  action: IReduxAction<IEngagementOrderIdRequestActionPayload>,
): Generator<any, any, any> {
  const { orderId, resolve, reject, type } = action.payload!;

  try {
    const endpoint = `${apiUrl}${endpoints.report}`;
    const qs = {
      ...(orderId ? getQuery(orderId, queryParams.orderId) : {}),
      ...(type ? getQuery(type, queryParams.type) : {}),
    };

    const response = yield call<any>(AuthRequest, {
      queryParams: qs,
      endpoint,
      method: 'GET',
    });
    if (!response.ok) {
      if (reject) {
        reject({ ...response.body, status: response.status });
      }
      yield put(ToolsActions.fetchEngagementByOrderIdFailure(response.body));
    } else {
      if (resolve) {
        resolve(response.body);
      }
      yield put(ToolsActions.fetchEngagementByOrderIdSuccess(response.body));
    }
  } catch (error) {
    if (reject) {
      reject(error);
    }
    yield put(ToolsActions.fetchEngagementByOrderIdFailure(error));
  }
}

function* deleteEngagementByOrderIdWorkerSaga(
  action: IReduxAction<IEngagementOrderIdRequestActionPayload>,
): Generator<any, any, any> {
  const { orderId, resolve, reject, type } = action.payload!;

  try {
    const endpoint = `${apiUrl}${endpoints.deleteEngagement}`;
    const qs = {
      ...(orderId ? getQuery(orderId, queryParams.orderId) : {}),
      ...(type ? getQuery(type, queryParams.type) : {}),
    };

    const response = yield call<any>(AuthRequest, {
      queryParams: qs,
      endpoint,
      method: 'DELETE',
    });
    if (!response.ok) {
      if (reject) {
        reject(response.body);
      }
      yield put(ToolsActions.deleteEngagementByOrderIdFailure(response.body));
    } else {
      if (resolve) {
        resolve(response.body);
      }
      yield put(ToolsActions.deleteEngagementByOrderIdSuccess({ orderId, type }));
    }
  } catch (error) {
    if (reject) {
      reject(error);
    }
    yield put(ToolsActions.deleteEngagementByOrderIdFailure(error));
  }
}

function* cancelEngagementByOrderIdWorkerSaga(
  action: IReduxAction<IEngagementOrderIdRequestActionPayload>,
): Generator<any, any, any> {
  const { orderId, resolve, reject, type } = action.payload!;

  try {
    const endpoint = `${apiUrl}${endpoints.cancelEngagement}`;
    const qs = {
      ...(orderId ? getQuery(orderId, queryParams.orderId) : {}),
      ...(type ? getQuery(type, queryParams.type) : {}),
    };

    const response = yield call<any>(AuthRequest, {
      queryParams: qs,
      endpoint,
      method: 'POST',
    });
    if (!response.ok) {
      if (reject) {
        reject(response.body);
      }
      yield put(ToolsActions.cancelEngagementByOrderIdFailure(response.body));
    } else {
      if (resolve) {
        resolve(response.body);
      }
      yield put(ToolsActions.cancelEngagementByOrderIdSuccess({ orderId, type }));
    }
  } catch (error) {
    if (reject) {
      reject(error);
    }
    yield put(ToolsActions.cancelEngagementByOrderIdFailure(error));
  }
}

const sagas = {
  *watchFetchEngagementByOrderIdRequest() {
    yield takeLatest(ToolsActions.FETCH_ENGAGEMENT_BY_ORDER_ID_REQUEST, fetchEngagementByOrderIdWorkerSaga);
  },
  *watchDeleteEngagementByOrderIdRequest() {
    yield takeLatest(ToolsActions.DELETE_ENGAGEMENT_BY_ORDER_ID_REQUEST, deleteEngagementByOrderIdWorkerSaga);
  },
  *watchCancelEngagementByOrderIdRequest() {
    yield takeLatest(ToolsActions.CANCEL_ENGAGEMENT_BY_ORDER_ID_REQUEST, cancelEngagementByOrderIdWorkerSaga);
  },
};

export default sagas;
