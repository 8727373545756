import {
  IDisableOneLinkSendRequestPayload,
  IDisableOneLinkSendSuccessPayload,
  IFetchOneLinkByIdRequestPayload,
  IFetchOneLinkByIdSuccessPayload,
  IFetchOneLinkSendsRequestPayload,
  IFetchOneLinkSendsSuccessPayload,
  IOneLinkReceiverFixedAddress,
  IOneLinkRecipient,
  IOneLinkRecipients,
  IOneLinkRecipientWithId,
  IPopulateOneLinkCampaignSummariesRequestPayload,
  IPopulateOneLinkCampaignSummariesResponsePayload,
} from '../../types/oneLink';
import { IApiError } from '../../types/shell';

export const FETCH_ONE_LINK_SENDS_REQUEST = 'FETCH_ONE_LINK_SENDS_REQUEST';
export const FETCH_ONE_LINK_SENDS_SUCCESS = 'FETCH_ONE_LINK_SENDS_SUCCESS';
export const FETCH_ONE_LINK_SENDS_FAILURE = 'FETCH_ONE_LINK_SENDS_FAILURE';

export const FETCH_ONE_LINK_BY_ID_REQUEST = 'FETCH_ONE_LINK_BY_ID_REQUEST';
export const FETCH_ONE_LINK_BY_ID_SUCCESS = 'FETCH_ONE_LINK_BY_ID_SUCCESS';
export const FETCH_ONE_LINK_BY_ID_FAILURE = 'FETCH_ONE_LINK_BY_ID_FAILURE';

export const POPULATE_ONE_LINK_CAMPAIGN_SUMMARIES_REQUEST = 'POPULATE_ONE_LINK_CAMPAIGN_SUMMARIES_REQUEST';
export const POPULATE_ONE_LINK_CAMPAIGN_SUMMARIES_SUCCESS = 'POPULATE_ONE_LINK_CAMPAIGN_SUMMARIES_SUCCESS';
export const POPULATE_ONE_LINK_CAMPAIGN_SUMMARIES_FAILURE = 'POPULATE_ONE_LINK_CAMPAIGN_SUMMARIES_FAILURE';

export const DISABLE_ONE_LINK_SEND_REQUEST = 'DISABLE_ONE_LINK_SEND_REQUEST';
export const DISABLE_ONE_LINK_SEND_SUCCESS = 'DISABLE_ONE_LINK_SEND_SUCCESS';
export const DISABLE_ONE_LINK_SEND_FAILURE = 'DISABLE_ONE_LINK_SEND_FAILURE';

export const SET_ONE_LINK_RECIPIENTS = 'SET_ONE_LINK_RECIPIENTS';
export const UPDATE_ONE_LINK_RECIPIENTS_SUCCESS = 'UPDATE_ONE_LINK_RECIPIENTS_SUCCESS';
export const ADD_ONE_LINK_RECIPIENTS_SUCCESS = 'ADD_ONE_LINK_RECIPIENTS_SUCCESS';
export const DELETE_ONE_LINK_RECIPIENTS_SUCCESS = 'DELETE_ONE_LINK_RECIPIENTS_SUCCESS';
export const DELETE_ALL_ONE_LINK_RECIPIENTS_SUCCESS = 'DELETE_ALL_ONE_LINK_RECIPIENTS_SUCCESS';
export const UPLOAD_ONE_LINK_RECIPIENTS = 'UPLOAD_ONE_LINK_RECIPIENTS';

export const SET_ONE_LINK_FIXED_ADDRESS = 'SET_ONE_LINK_FIXED_ADDRESS';

export const fetchOneLinkSendsRequest = (payload: IFetchOneLinkSendsRequestPayload) => ({
  type: FETCH_ONE_LINK_SENDS_REQUEST,
  payload,
});

export const fetchOneLinkSendsSuccess = (payload: IFetchOneLinkSendsSuccessPayload) => ({
  type: FETCH_ONE_LINK_SENDS_SUCCESS,
  payload,
});

export const fetchOneLinkSendsFailure = (payload: IApiError | Error | unknown) => ({
  type: FETCH_ONE_LINK_SENDS_FAILURE,
  payload,
});

export const fetchOneLinkByIdRequest = (payload: IFetchOneLinkByIdRequestPayload) => ({
  type: FETCH_ONE_LINK_BY_ID_REQUEST,
  payload,
});

export const fetchOneLinkByIdSuccess = (payload: IFetchOneLinkByIdSuccessPayload) => ({
  type: FETCH_ONE_LINK_BY_ID_SUCCESS,
  payload,
});

export const fetchOneLinkByIdFailure = (payload: IApiError | Error | unknown) => ({
  type: FETCH_ONE_LINK_BY_ID_FAILURE,
  payload,
});

export const populateOneLinkCampaignSummariesRequest = (payload: IPopulateOneLinkCampaignSummariesRequestPayload) => ({
  type: POPULATE_ONE_LINK_CAMPAIGN_SUMMARIES_REQUEST,
  payload,
});

export const populateOneLinkCampaignSummariesSuccess = (payload: IPopulateOneLinkCampaignSummariesResponsePayload) => ({
  type: POPULATE_ONE_LINK_CAMPAIGN_SUMMARIES_SUCCESS,
  payload,
});

export const populateOneLinkCampaignSummariesFailure = (payload: IApiError | Error | unknown) => ({
  type: POPULATE_ONE_LINK_CAMPAIGN_SUMMARIES_FAILURE,
  payload,
});

export const disableOneLinkRequest = (payload: IDisableOneLinkSendRequestPayload) => ({
  type: DISABLE_ONE_LINK_SEND_REQUEST,
  payload,
});

export const disableOneLinkSuccess = (payload: IDisableOneLinkSendSuccessPayload) => ({
  type: DISABLE_ONE_LINK_SEND_SUCCESS,
  payload,
});

export const disableOneLinkFailure = (payload: IApiError | Error | unknown) => ({
  type: DISABLE_ONE_LINK_SEND_FAILURE,
  payload,
});

export const setOneLinkRecipients = (payload: IOneLinkRecipient[] | null) => ({
  type: SET_ONE_LINK_RECIPIENTS,
  payload,
});

export const updateOneLinkRecipientsSuccess = (payload: IOneLinkRecipientWithId) => ({
  type: UPDATE_ONE_LINK_RECIPIENTS_SUCCESS,
  payload,
});

export const addOneLinkRecipientsSuccess = (payload: IOneLinkRecipientWithId) => ({
  type: ADD_ONE_LINK_RECIPIENTS_SUCCESS,
  payload,
});

export const deleteOneLinkRecipientsSuccess = (payload: Pick<IOneLinkRecipientWithId, 'uid'>) => ({
  type: DELETE_ONE_LINK_RECIPIENTS_SUCCESS,
  payload,
});

export const deleteAllOneLinkRecipientsSuccess = () => ({
  type: DELETE_ALL_ONE_LINK_RECIPIENTS_SUCCESS,
});

export const uploadOneLinkRecipients = (payload: IOneLinkRecipients) => ({
  type: UPLOAD_ONE_LINK_RECIPIENTS,
  payload,
});

export const setOneLinkFixedAddress = (payload?: IOneLinkReceiverFixedAddress) => ({
  type: SET_ONE_LINK_FIXED_ADDRESS,
  payload,
});
