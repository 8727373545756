import { call, put, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { DateFormatsEnum } from '../../constants/date';
import { endpoints, queryParams } from '../../constants/routing';
import { IResponse } from '../../types/http';
import {
  IAddOrganizationActionPayload,
  IAddOrganizationActionResponse,
  IAddOrganizationDepartmentActionPayload,
  IDepartment,
  IDepartmentBudgetSummariesResponse,
  IEditOrganizationActionPayload,
  IEditOrganizationDepartmentActionPayload,
  IFetchOrganizationRequestActionPayload,
  IOrganizationItem,
  IRemovePIIRequestPayload,
} from '../../types/organizations';
import { IReduxAction } from '../../types/redux';
import { IApiError } from '../../types/shell';
import { formatDate } from '../../utils/date';
import * as AuthActions from '../actions/auth';
import * as OrganizationsActions from '../actions/organizations';
import * as ShellActions from '../actions/shell';
import { AuthRequest } from './helpers';

function* getOrganizationsWorkerSaga(
  action: IReduxAction<IFetchOrganizationRequestActionPayload>,
): Generator<any, any, any> {
  try {
    const { responseDataType } = action.payload || {};
    const endpoint = `${apiUrl}${endpoints.getOrganizations}`;
    const qs = {
      ...(responseDataType ? { [queryParams.responseData]: responseDataType } : {}),
    };
    const response = yield call(AuthRequest, { queryParams: qs, endpoint });

    if (response.ok) {
      yield put(OrganizationsActions.fetchOrganizationsSuccess(response.body));
    } else {
      yield put(OrganizationsActions.fetchOrganizationsFailure(response.body));
    }
  } catch (error) {
    yield put(OrganizationsActions.fetchOrganizationsFailure(error));
  }
}

function* addOrganizationWorkerSaga(action: IReduxAction<IAddOrganizationActionPayload>): Generator<any, any, any> {
  const { values, resolve, reject } = action.payload!;

  const {
    // org_options should go at the root level
    org_options,
    // all the rest should go under `organization` field name
    org_customization_options,
    ...organization
  } = values;

  try {
    const endpoint = `${apiUrl}${endpoints.createOrg}`;
    const response = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({ organization, org_options, org_customization_options }),
    });

    if (response.ok) {
      const { org_id: uid } = response.body;
      const createdOrganization = { ...values, uid };

      if (typeof resolve === 'function') {
        resolve(createdOrganization);
      }
      yield put(OrganizationsActions.addOrganizationSuccess(createdOrganization));
    } else {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(OrganizationsActions.addOrganizationFailure(response.body));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(OrganizationsActions.addOrganizationFailure(error));
  }
}

function* editOrganizationWorkerSaga(action: IReduxAction<IEditOrganizationActionPayload>): Generator<any, any, any> {
  const { values, metadata, resolve, reject } = action.payload!;
  const { shouldReauthorize = false } = metadata! || {};

  const {
    // org_options should go at the root level
    org_options,
    // the fields below shouldn't be sent to the endpoint
    salesforce_client_secret_has_value,
    // rybbon_client_secret_has_value - we support removing value for this field, so it should always be sent to the endpoint
    // all the rest should go under `organization` field name
    org_customization_options,
    ...organization
  } = values;

  try {
    const endpoint = `${apiUrl}${endpoints.editOrg}`;

    const response = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({ organization, org_options, org_customization_options }),
    });

    if (response.ok) {
      if (typeof resolve === 'function') {
        resolve(values);
      }

      yield put(OrganizationsActions.editOrganizationSuccess(values as IOrganizationItem));
      if (shouldReauthorize) {
        yield put(AuthActions.authorizeUserRequest());
      }
      yield put(ShellActions.fetchThemeRequest());

      // recheck what response returning, maybe we should pass `request.organization` there and update org list in reducer.
    } else {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(OrganizationsActions.editOrganizationFailure(response.body));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(OrganizationsActions.editOrganizationFailure(error));
  }
}

function* addOrganizationDepartmentWorkerSaga(action: IReduxAction<IAddOrganizationDepartmentActionPayload>) {
  const { department, resolve, reject } = action.payload!;

  try {
    const endpoint = `${apiUrl}${endpoints.createOrgDepartment}`;
    const response: IResponse<IAddOrganizationActionResponse | IApiError> = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({ department }),
    });

    if (response.ok) {
      // Response body has only id of the created org department
      const { id: uid } = response.body as IAddOrganizationActionResponse;

      // backend protects us <3
      const createdDep: IDepartment = (({ available_budget: availableBudget, ...restDepProp }) => {
        return {
          ...restDepProp,
          uid,
          available_budget: typeof availableBudget === 'undefined' ? 0 : availableBudget,
          used_budget: 0,
          created_date: formatDate(new Date().toString(), DateFormatsEnum.Full),
        };
      })(department);

      if (typeof resolve === 'function') {
        resolve(createdDep);
      }
      yield put(OrganizationsActions.addOrganizationDepartmentSuccess(createdDep));
    } else {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(OrganizationsActions.addOrganizationDepartmentFailure(response.body));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(OrganizationsActions.addOrganizationDepartmentFailure(error));
  }
}

function* editOrganizationDepartmentWorkerSaga(
  action: IReduxAction<IEditOrganizationDepartmentActionPayload>,
): Generator<any, any, any> {
  const { department, resolve, reject } = action.payload!;

  try {
    const endpoint = `${apiUrl}${endpoints.editOrgDepartment}`;
    const response = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      body: (() => {
        const { used_budget, ...depProps } = department;

        return JSON.stringify({
          department: depProps,
        });
      })(),
    });

    if (response.ok) {
      if (typeof resolve === 'function') {
        resolve(department);
      }
      yield put(OrganizationsActions.editOrganizationDepartmentSuccess(department));
    } else {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(OrganizationsActions.editOrganizationDepartmentFailure(response.body));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(OrganizationsActions.editOrganizationDepartmentFailure(error));
  }
}

function* departmentBudgetSummariesSaga() {
  try {
    const endpoint = `${apiUrl}${endpoints.getDepartmentBudgetSummaries}`;

    const response: IResponse<IDepartmentBudgetSummariesResponse | IApiError> = yield call(AuthRequest, { endpoint });

    if (!response.ok) {
      yield put(OrganizationsActions.getDepartmentBudgetSummariesFailure(response.body as IApiError));
    } else {
      yield put(
        OrganizationsActions.getDepartmentBudgetSummariesSuccess(response.body as IDepartmentBudgetSummariesResponse),
      );
    }
  } catch (e) {
    yield put(OrganizationsActions.getDepartmentBudgetSummariesFailure(e));
  }
}

function* removePIIFromOrganizationWorkerSaga(
  action: IReduxAction<IRemovePIIRequestPayload>,
): Generator<any, any, any> {
  const { orgId, from, to, resolve, reject } = action.payload!;

  try {
    const endpoint = `${apiUrl}${endpoints.handlePIIForOrganization}`;
    const response = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({
        org_id: orgId,
        date_range: {
          from,
          to,
        },
      }),
    });

    if (response.ok) {
      if (typeof resolve === 'function') {
        resolve();
      }
      yield put(OrganizationsActions.removePIISuccess());
    } else {
      if (typeof reject === 'function') {
        const { message } = response.body || {};
        reject(message);
      }

      yield put(OrganizationsActions.removePIIFailure(response.body));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(OrganizationsActions.removePIIFailure(error));
  }
}

const sagas = {
  *watchOrganizationsWatcher() {
    yield takeLatest(OrganizationsActions.FETCH_ORGANIZATIONS_REQUEST, getOrganizationsWorkerSaga);
  },
  *watchAddOrganizationWatcher() {
    yield takeLatest(OrganizationsActions.ADD_ORGANIZATION_REQUEST, addOrganizationWorkerSaga);
  },
  *watchEditOrganizationWatcher() {
    yield takeLatest(OrganizationsActions.EDIT_ORGANIZATION_REQUEST, editOrganizationWorkerSaga);
  },
  *watchAddOrganizationDepartmentWatcher() {
    yield takeLatest(OrganizationsActions.ADD_ORGANIZATION_DEPARTMENT_REQUEST, addOrganizationDepartmentWorkerSaga);
  },
  *watchEditOrganizationDepartmentWatcher() {
    yield takeLatest(OrganizationsActions.EDIT_ORGANIZATION_DEPARTMENT_REQUEST, editOrganizationDepartmentWorkerSaga);
  },
  *watchGetDepartmentBudgetSummariesWatcher() {
    yield takeLatest(OrganizationsActions.GET_DEPARTMENT_BUDGET_SUMMARIES_REQUEST, departmentBudgetSummariesSaga);
  },
  *watchRemovePIIForOrganization() {
    yield takeLatest(OrganizationsActions.REMOVE_PII_REQUEST, removePIIFromOrganizationWorkerSaga);
  },
};

export default sagas;
