import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as BluebirdLogo } from '../../../assets/images/iex_logo_light.svg';
import styles from './NonAuthSidenav.module.scss';

interface IProps {
  className?: string;
}

const NonAuthSidenav = ({ className }: IProps) => {
  return (
    <div className={cn(styles.sidenav, className)}>
      <BluebirdLogo className={styles.logo} />
    </div>
  );
};

export default NonAuthSidenav;
