import './wdyr';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import './index.scss';

import firebase from './config/fbConfig';
import Routes from './Routes';
import { persistor, store } from './store';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ReactReduxFirebaseProvider
        firebase={firebase}
        config={{
          userProfile: 'users',
          useFirestoreForProfile: true,
          enableClaims: true,
        }}
        dispatch={store.dispatch}
      >
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </PersistGate>
  </Provider>,
);
