import { Editor, IAllProps as ITinymceProps } from '@tinymce/tinymce-react';
import cn from 'classnames';
import { FieldAttributes } from 'formik';
import * as React from 'react';

import { EMAIL_IMAGE_TYPES, MAX_IMAGE_SIZE } from '../../../../constants/upload';
import useUploadFile from '../../../../hooks/useUploadFile';
import { IApiError } from '../../../../types/shell';
import { UploadFileTypeEnum } from '../../../../types/upload';
import { formatFileSize } from '../../../../utils/bucket';
import { Loader } from '../../../index';
import { InputLabel } from '../../labels';

import styles from './TinyEditor.module.scss';

export interface IBlobInfo {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
}

export interface IProps extends ITinymceProps, FieldAttributes<any> {
  name?: string;
  value?: string;
  defaultValue?: string;
  helperText?: string | React.ReactNode;
  className?: string;
  containerClassName?: string;
  changeCallback?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  maxImageSize?: number;
  acceptImageTypes?: string[];
  onEditorInit?: (isInit: boolean) => void;
  onUploadImageSuccess?: () => void;
  onUploadImage?: (fileInfo: IBlobInfo) => Promise<string>;
  onUploadImageFailure?: () => void;
  showHint?: boolean;
  showFontColorPicker?: boolean;
  showErrors?: boolean;
  error?: string | string[] | null;
}

const TinyEditor: React.FC<IProps> = ({
  name,
  value,
  defaultValue,
  onChange,
  helperText,
  className,
  containerClassName,
  changeCallback,
  field,
  init,
  maxImageSize = MAX_IMAGE_SIZE,
  acceptImageTypes = EMAIL_IMAGE_TYPES,
  onEditorInit,
  onUploadImageSuccess,
  onUploadImage,
  onUploadImageFailure,
  showHint = true,
  showFontColorPicker = true,
  showErrors,
  error,
  ...editorProps
}) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const shouldShowValidationError = React.useMemo(
    () => showErrors && !editorProps.disabled && error,
    [showErrors, editorProps.disabled, error],
  );

  const renderValidationError = React.useMemo(() => {
    if (!shouldShowValidationError) {
      return null;
    }
    return Array.isArray(error) ? (
      error.map((e) => (
        <span key={e} className={cn(styles.error)}>
          {e}
        </span>
      ))
    ) : (
      <span className={cn(styles.error)}>{error}</span>
    );
  }, [error, shouldShowValidationError]);

  const handleOnChangeEditor = React.useCallback(
    (v: string) => {
      const syntheticEvent = {
        currentTarget: { name: name || field?.name, value: v === defaultValue ? '' : v },
      } as unknown as React.ChangeEvent<HTMLInputElement>;

      if (typeof field?.onChange === 'function') {
        field.onChange(syntheticEvent);
      }

      if (changeCallback) {
        changeCallback(syntheticEvent);
      }
    },
    [value, defaultValue, changeCallback],
  );

  const { uploadFile } = useUploadFile();

  const defaultImageUploadRequest = React.useCallback(
    async (fileInfo: IBlobInfo) => {
      return uploadFile(fileInfo.blob(), UploadFileTypeEnum.EmailImage).then(({ public_url }) => {
        return public_url;
      });
    },
    [uploadFile],
  );

  const handleImageUploadRequest = React.useCallback(
    (file: IBlobInfo) => (onUploadImage ? onUploadImage(file) : defaultImageUploadRequest(file)),
    [defaultImageUploadRequest, onUploadImage],
  );

  const imageUploadHandler: (blobInfo: IBlobInfo, progress: (percent: number) => void) => Promise<string> =
    React.useCallback(
      (blobInfo: IBlobInfo) => {
        return new Promise((resolve, reject) => {
          const { size, type } = blobInfo.blob();
          const validExtensions = acceptImageTypes.map((imageTypes) => imageTypes.slice(1));

          if (!validExtensions.some((el) => type.includes(el))) {
            reject('File type must be one of '.concat(acceptImageTypes.join(', ')));
          }

          if (size > maxImageSize) {
            reject(`File should not be larger than ${formatFileSize(+maxImageSize)}`);
          }

          return handleImageUploadRequest(blobInfo)
            .then((fileUrl: string) => {
              if (onUploadImageSuccess) {
                onUploadImageSuccess();
              }

              resolve(fileUrl);
            })
            .catch((data: IApiError | any) => {
              if (onUploadImageFailure) {
                onUploadImageFailure();
              }

              reject(data?.message || data);
            });
        });
      },
      [handleImageUploadRequest, onUploadImageSuccess, onUploadImageFailure, acceptImageTypes, maxImageSize],
    );

  return (
    <div
      className={cn(styles.editorContainer, containerClassName, { [styles.errorBorder]: !!shouldShowValidationError })}
    >
      <InputLabel value={helperText} />
      <div className={cn(styles.editor, className)}>
        <Loader isLoading={isLoading} />
        <Editor
          tinymceScriptSrc="/static/libs/tinymce/tinymce.min.js"
          licenseKey="gpl"
          init={{
            setup: (editor) => {
              editor.on('init', () => {
                if (onEditorInit) {
                  onEditorInit(true);
                }
                setIsLoading(false);
              });
            },
            content_style: 'div { border: none; }',
            resize: false,
            height: '100%',
            menubar: false,
            plugins: [
              'advlist',
              'anchor',
              'autolink',
              'charmap',
              'code',
              'emoticons',
              'fullscreen',
              'help',
              'image',
              'insertdatetime',
              'link',
              'lists',
              'media',
              'nonbreaking',
              'pagebreak',
              'preview',
              'searchreplace',
              'table',
              'visualblocks',
              'visualchars',
              'wordcount',
            ],
            toolbar: `undo redo |
            styleselect |
            ${showFontColorPicker ? 'forecolor' : ''} bold italic |
            alignleft aligncenter alignright alignjustify |
            bullist numlist outdent indent |
            link image`,
            mobile: {
              toolbar_mode: 'sliding',
            },
            images_upload_handler: imageUploadHandler,
            forced_root_block: 'div',
            ...init,
          }}
          onEditorChange={handleOnChangeEditor}
          value={value === undefined || value === null ? field?.value : value}
          {...editorProps}
        />
      </div>
      {renderValidationError}
      {showHint && <div className={styles.hint}>* Images loaded with unsecure http protocol are not supported.</div>}
    </div>
  );
};

export default TinyEditor;
