import * as React from 'react';
import { useSelector } from 'react-redux';

import { DISTRIBUTOR } from '../../constants/users';
import { DashboardContainer, DistributorDashboardContainer } from '../../containers';
import { selectAdminType } from '../../store/selectors/auth';

const Dashboard = () => {
  const adminType = useSelector(selectAdminType);
  const isDistributorDashBoardFeatureEnabled = process.env.REACT_APP_DISTRIBUTOR_DASHBOARD_ENABLED === 'true';

  if (!adminType) {
    return null;
  }

  return isDistributorDashBoardFeatureEnabled && adminType === DISTRIBUTOR ? (
    <DistributorDashboardContainer />
  ) : (
    <DashboardContainer />
  );
};

export default Dashboard;
