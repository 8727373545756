import { IReasonsDetails } from '../types/reasons';

export const BUSINESS_REASON_ITEM_LABEL = 'Business Reason';

export const EMPTY_BUSINESS_REASON: IReasonsDetails = {
  name: '',
  org_id: '',
};
export const BUSINESS_REASON_ID = 'business_reason_id';

export const UID = 'uid';
export const ORG_ID = 'org_id';
export const NAME = 'name';

export const BUSINESS_REASON_TABLE_HEADERS = {
  [NAME]: `${BUSINESS_REASON_ITEM_LABEL}s`,
};
