import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column, Row } from 'react-table';

import { ReactComponent as EditIcon } from '../../assets/images/icon-edit-gray.svg';
import { AddButton, AutomationTable, Filter, TableCellWithHighlight } from '../../components';
import OrgAddressForm from '../../components/forms/OrgAddressForm/OrgAddressForm';
import OrgAddressSidebar from '../../components/OrgAddressSidebar/OrgAddressSidebar';
import {
  ADDRESS1,
  ADDRESS2,
  ADDRESS_FILTERS,
  CITY,
  COUNTRY,
  LABEL,
  NEW_ID,
  OrgAddressFilterTypesEnum,
  STATE,
  STATUS,
  ZIP,
} from '../../constants/addressBook';
import { routes } from '../../constants/routing';
import { DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import useSearchFilter from '../../hooks/useSearchFilter/useSearchFilter';
import { fetchAddressBookRequest } from '../../store/actions/addressBook';
import { selectAddressBook } from '../../store/selectors/addressBook';
import { selectAdminType } from '../../store/selectors/auth';
import { IOrgAddress } from '../../types/addressBook';
import { sortOrgAddressesAlphabeticallyAscending } from '../../utils/helpers';
import { hasPermission } from '../../utils/users';

import styles from './AddressBook.module.scss';

const AddressBook: React.FC = () => {
  const { setHeader } = useHeaderDispatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const adminType = useSelector(selectAdminType);
  const orgAddresses = useSelector(selectAddressBook);

  const [filter, setFilter] = React.useState(OrgAddressFilterTypesEnum.Active);
  const [candidate, setCandidate] = React.useState<string | undefined>();

  const {
    SearchInput,
    filteredItems: filteredAddressesBySearch,
    query,
  } = useSearchFilter(orgAddresses || [], [LABEL, ADDRESS1, COUNTRY, CITY, STATE, ZIP, ADDRESS2], {
    placeholder: 'Search Address',
    className: styles.searchByFilterContainer,
  });

  // const {
  //   departments: selectedDepartment,
  //   DeptFilter,
  //   filteredItems,
  // } = useDeptFilter<IOrgAddress>((orgAddresses as IOrgAddress[]) || [], DEPARTMENT_IDS, {
  //   className: styles.dropdownFilter,
  //   contentClassName: styles.dropdownContent,
  //   labelClassName: styles.dropdownLabel,
  // });

  const getStatusLabel = (status: OrgAddressFilterTypesEnum): React.ReactNode => {
    switch (status) {
      case OrgAddressFilterTypesEnum.Active:
        return 'Active';
      case OrgAddressFilterTypesEnum.NotActive:
        return 'Not active';
      default:
        return '';
    }
  };

  const handleEdit = React.useCallback((uid: string) => {
    setCandidate(uid);
  }, []);

  const handleAdd = React.useCallback(() => {
    setCandidate(NEW_ID);
  }, []);

  const handleCloseSidebar = React.useCallback(() => {
    setCandidate(undefined);
  }, []);

  const columns: Column<IOrgAddress>[] = useMemo(() => {
    const Cell = TableCellWithHighlight<IOrgAddress>({ query });

    return [
      {
        Header: 'Label',
        accessor: 'label',
        Cell,
      },
      {
        Header: 'Address 1',
        accessor: 'address1',
        Cell,
      },
      {
        Header: 'Address 2',
        accessor: 'address2',
        Cell,
      },
      {
        Header: 'City',
        accessor: 'city',
        Cell,
      },
      {
        Header: 'State',
        accessor: 'state',
        Cell,
      },
      {
        Header: 'Zip Code',
        accessor: 'zip',
        Cell,
      },
      {
        Header: 'Country',
        accessor: 'country',
        Cell,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value, ...cellProps }) => <Cell {...cellProps} value={getStatusLabel(value)} />,
        meta: {},
      },
      ...(hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], adminType)
        ? [
            {
              Header: 'Actions',
              id: 'action',
              Cell: ({ row }: { row: Row<IOrgAddress> }) => (
                <button onClick={() => handleEdit(row.original?.uid)}>
                  <EditIcon />
                </button>
              ),
              meta: {
                className: 'actions',
              },
            },
          ]
        : []),
    ];
  }, [adminType, handleEdit, query]);

  const filterConfig = React.useMemo(() => {
    return ADDRESS_FILTERS.map(({ filterId, label: optionLabel }) => {
      return {
        onClick: () => setFilter(filterId),
        isActive: filterId === filter,
        key: optionLabel,
        label: `${optionLabel}`,
      };
    });
  }, [filter]);

  const filteredAddressesByStatus = React.useMemo(() => {
    const addresses =
      filter === OrgAddressFilterTypesEnum.All
        ? filteredAddressesBySearch
        : filteredAddressesBySearch.filter((address) => address?.status === filter);

    return addresses.sort(sortOrgAddressesAlphabeticallyAscending);
  }, [filteredAddressesBySearch, filter]);

  React.useEffect(() => {
    if (setHeader) {
      setHeader({ title: 'Address book', action: () => history.push(routes.dashboard) });
    }
  }, [history, setHeader]);

  useEffect(() => {
    dispatch(fetchAddressBookRequest());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.filter}>
          {SearchInput}
          {/*{DeptFilter({})}*/}
          <Filter className={styles.typeFilter} config={filterConfig} />
        </div>
        {hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], adminType) && (
          <AddButton assetName="Address" onClick={handleAdd} className={styles.addButton} />
        )}
      </div>
      <AutomationTable
        data={filteredAddressesByStatus}
        columns={columns}
        placeholderText="There are no addresses"
        containerClassName={styles.table}
        className={styles.tableBody}
        headerClassName={styles.tableHeader}
        bodyClassName={styles.tableBody}
        classNames={{
          cell: {
            [STATUS]: ({ status }: IOrgAddress) =>
              status === OrgAddressFilterTypesEnum.NotActive ? styles.notActiveStatus : '',
          },
        }}
      />
      <div className={styles.total}>
        Total records: <span className={styles.amount}>{filteredAddressesByStatus.length}</span>
      </div>
      <OrgAddressSidebar onClose={handleCloseSidebar} trigger={!!candidate}>
        {candidate && <OrgAddressForm id={candidate} onClose={handleCloseSidebar} />}
      </OrgAddressSidebar>
    </div>
  );
};

export default AddressBook;
