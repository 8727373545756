import cn from 'classnames';
import * as React from 'react';

import iconDownload from '../../assets/images/icon-cloud.svg';
import { ReactComponent as iconCSVError } from '../../assets/images/icon-csv-error.svg';
import { ReactComponent as iconCSVSuccess } from '../../assets/images/icon-csv-success.svg';
import FileDetails from '../FileDetails/FileDetails';

import styles from './UploadResult.module.scss';

import { IBulkEngagementResponse } from '../../types/bucket';

interface IProps {
  className?: string;
  result: IBulkEngagementResponse | null;
}

const UploadResult = ({ className, result }: IProps) => {
  const renderButton = React.useCallback((url: string) => {
    return (
      <a className={styles.action} href={url} title="Download File">
        <img src={iconDownload} alt="" />
      </a>
    );
  }, []);

  const handleFileClick = React.useCallback((url: string) => (window.location.href = url), []);

  return result ? (
    <div className={cn(styles.container, className)}>
      <div className={styles.title}>Send Results</div>
      <div className={styles.content}>
        {result.success_count > 0 ? (
          <FileDetails
            onClick={() => handleFileClick(result?.success_file_url)}
            className={styles.result}
            action={renderButton(result.success_file_url)}
            name="Successful_Sends.csv"
            title="Successful sends"
            icon={iconCSVSuccess}
          />
        ) : null}
        {result.error_count > 0 ? (
          <React.Fragment>
            <FileDetails
              onClick={() => handleFileClick(result?.error_file_url)}
              className={styles.result}
              action={renderButton(result.error_file_url)}
              name="Failed_Sends.csv"
              title="Failed sends"
              icon={iconCSVError}
            />
            <div className={styles.hint}>Download this file, make the changes and try uploading it again!</div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default UploadResult;
