import { NO_DEPARTMENT } from '../constants/organizations';
import {
  EMAIL_TEMPLATE_TYPES,
  INITIAL_TEMPLATE_EMAIL_FORM_STATE,
  INITIAL_TEMPLATE_NOTE_FORM_STATE,
  TEMPLATE_TOKENS_REGEX,
  TOKENS_MAP,
} from '../constants/templates';
import { DEPT_ADMIN } from '../constants/users';
import { EmailTemplateTypesEnum, IEmailTemplate, INoteTemplate, TemplateTypesEnum } from '../types/templates';
import { hasPermission } from './users';

export const replaceLineBreaks = (template: string) => template.replace(/(\\n|\\\\n)/g, '\n');

export const setTemplateTokens = (template: string, values: any) => {
  if (!values) {
    return template;
  }

  try {
    const matches = template.match(TEMPLATE_TOKENS_REGEX);

    if (matches && matches.length) {
      matches.forEach((key) => {
        const rawKey = key.slice(1, -1);
        const keyToChange = TOKENS_MAP[rawKey] || null;

        if (keyToChange) {
          template = template.replace(
            key,
            Array.isArray(values[keyToChange]) ? values[keyToChange].join(', ') : values[keyToChange],
          );
        }
        return template;
      });
    }
  } catch (e) {
    console.error('An error has occurred during template parsing', e);
  }

  return template;
};

export const sortByDepId =
  (depId: string) => (a: IEmailTemplate | INoteTemplate, b: IEmailTemplate | INoteTemplate) => {
    if (includesOnlyThisDep(a.department_ids, depId) !== includesOnlyThisDep(b.department_ids, depId)) {
      return includesOnlyThisDep(a.department_ids, depId) ? -1 : 1;
    }

    if (
      !includesOnlyThisDep(a.department_ids, depId) &&
      !includesOnlyThisDep(b.department_ids, depId) &&
      a.department_ids.includes(NO_DEPARTMENT) !== b.department_ids.includes(NO_DEPARTMENT)
    ) {
      return a.department_ids.includes(NO_DEPARTMENT) ? -1 : 1;
    }

    return ((a as INoteTemplate).title || (a as IEmailTemplate).subject).localeCompare(
      (b as INoteTemplate).title || (b as IEmailTemplate).subject,
    );
  };

export const getEmailTemplateLabelByType = (type: EmailTemplateTypesEnum) => {
  return EMAIL_TEMPLATE_TYPES.find((template) => template.value === type)?.label || '';
};

export const isTemplateEmail = (type: TemplateTypesEnum | null | undefined): boolean => {
  if (!type) {
    return false;
  }

  return type === TemplateTypesEnum.Email;
};

export const includesOnlyThisDep = (depIds: string[], id: string) => {
  return depIds.length === 1 && depIds.includes(id);
};

export const getAddTemplateInitialValue = (type: TemplateTypesEnum, adminType: string, userDepartmentId: string) => {
  const value = isTemplateEmail(type) ? INITIAL_TEMPLATE_EMAIL_FORM_STATE : INITIAL_TEMPLATE_NOTE_FORM_STATE;
  return hasPermission([DEPT_ADMIN], adminType) ? { ...value, department_ids: [userDepartmentId] } : value;
};
