import cn from 'classnames';
import { FieldAttributes } from 'formik';
import * as React from 'react';

import styles from './RadioButton.module.scss';

export interface IRadioButtonProps extends FieldAttributes<any> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  label?: string | React.ReactNode;
  containerClassName?: string;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  name?: string;
}

const RadioButton: React.FC<IRadioButtonProps> = ({
  className,
  label,
  containerClassName,
  value,
  defaultValue,
  name,
  disabled,
  field,
  onChange,
}) => {
  const handleOnchange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (field?.onChange(e)) {
      field.onChange(e);
    }
    if (onChange) {
      onChange(e);
    }
  }, []);

  return (
    <label className={cn(containerClassName, styles.radioButton)}>
      <input
        name={name || field?.name}
        type="radio"
        value={defaultValue}
        checked={value === field?.value || value === defaultValue}
        className={cn(className, styles.input)}
        disabled={disabled}
        onChange={handleOnchange}
      />
      <span>{label}</span>
    </label>
  );
};

export default RadioButton;
