import cn from 'classnames';
import * as React from 'react';
import { UISizeEnum } from '../../../../types/shell';

import { Loader } from '../../../index';
import { IActionButtonProps } from '../ActionButton/ActionButton';
import { ActionButton } from '../index';

import styles from './AsyncButton.module.scss';

interface IProps extends IActionButtonProps {
  loaderClassName?: string;
  isLoading?: boolean;
}

const AsyncButton = ({
  className,
  isLoading,
  disabled,
  loaderClassName,
  children,
  title,
  ...actionButtonProps
}: IProps) => (
  <ActionButton {...actionButtonProps} disabled={disabled || isLoading} className={cn(styles.button, className)}>
    {!isLoading ? title || children : null}
    <Loader size={UISizeEnum.Tiny} className={cn(styles.spinner, loaderClassName)} isLoading={isLoading} />
  </ActionButton>
);

export default AsyncButton;
