import * as React from 'react';

import useCopyClipboard from '../../../../hooks/useCopyClipboard';
import { UISizeEnum } from '../../../../types/shell';
import ActionButton, { IActionButtonProps } from '../ActionButton/ActionButton';

export interface IProps extends IActionButtonProps {
  children?: React.ReactNode | React.ReactNode[];
  value: string | number;
  disabled?: boolean;
  onClick?: (...args: any[]) => void;
}

const CopyableButton = ({ value, onClick, children, size = UISizeEnum.Small, disabled, ...restProp }: IProps) => {
  const { setCopied } = useCopyClipboard({ successDuration: 1000, toastDuration: 1000 });

  const handleClick = React.useCallback(
    (...args: any[]) => {
      setCopied(value.toString());

      if (typeof onClick === 'function') {
        onClick(...args);
      }
    },
    [onClick, value, setCopied],
  );

  return (
    <ActionButton {...restProp} size={size} onClick={handleClick} disabled={disabled}>
      {children}
    </ActionButton>
  );
};

export default CopyableButton;
