export enum MessageSendersEnum {
  Portal = 'bb-portal',
  ExtPortal = 'bb-ai-ext-portal',
  ExtBackground = 'bb-ai-ext-bg',
  ExtParser = 'bb-ai-ext-parser',
}

export enum MessageTypesEnum {
  Init = 'init',
  Ping = 'ping',
  Pong = 'pong',
  Capture = 'capture',
  Transfer = 'transfer',
  Success = 'success',
  Failure = 'failure',
  Version = 'version',
}

export interface IMessage<T extends {} = {}> {
  type: MessageTypesEnum;
  payload?: T;
}
