interface IPopupOptions {
  url: string;
  name?: string;
  appendedHtml?: string;
}

export class PopupProvider {
  private _windowObjectReference: Window | null = null;
  private _previousUrl: string | null = null;
  private _pollTimer: number = 0;

  static _sender = '_popupProvider';

  static getResponse = (data: string | null, error: string) => {
    return data
      ? { data, ok: true, sender: PopupProvider._sender }
      : { error, ok: false, sender: PopupProvider._sender };
  };

  openPopUpInWindow = ({ url, name = '', appendedHtml }: IPopupOptions) => {
    return new Promise((resolve, reject) => {
      // remove any existing event listeners
      this._removeListeners();

      if (this._windowObjectReference === null || this._windowObjectReference.closed) {
        /* if the pointer to the window object in memory does not exist
        or if such pointer exists but the window was closed */
        this._windowObjectReference = this._showPopup(url, name, appendedHtml);
      } else if (this._previousUrl !== url) {
        /* if the resource to load is different,
        then we load it in the already opened secondary window and then
        we bring such window back on top/in front of its parent window. */
        this._windowObjectReference = this._showPopup(url, name, appendedHtml);
        (this._windowObjectReference as Window).focus();
      } else {
        /* else the window reference must exist and the window
        is not closed; therefore, we can bring it back on top of any other
        window with the focus() method. There would be no need to re-create
        the window or to reload the referenced resource. */
        this._windowObjectReference.focus();
      }

      // add the listener for receiving a message from the popup
      window.addEventListener(
        'message',
        (event: MessageEvent) => {
          if (event.data && event.data.sender && event.data.sender === PopupProvider._sender) {
            this._removeListeners();
            resolve(this._receiveMessage(event));
          }
        },
        false,
      );

      // add the "listener" for the oncloce event
      this._pollTimer = window.setInterval(() => {
        if ((this._windowObjectReference as Window).closed) {
          this._removeListeners();
          reject(PopupProvider.getResponse(null, `Window was closed before auth`));
        }
      }, 500);

      // assign the previous URL
      this._previousUrl = url;
    });
  };

  private _showPopup = (url: string, name: string, appendedHtml?: string) => {
    const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';
    const windowObjectReference = window.open(url, name, strWindowFeatures);

    if (appendedHtml && windowObjectReference) {
      windowObjectReference.document.write(appendedHtml);
    }

    return windowObjectReference;
  };

  private _receiveMessage = (event: MessageEvent) => {
    // Do we trust the sender of this message? (might be
    // different from what we originally opened, for example).
    if (event.origin !== window.location.origin || !event.isTrusted) {
      return;
    }

    const { data } = event;
    return data;
  };

  private _removeListeners = () => {
    window.clearInterval(this._pollTimer);
    window.removeEventListener('message', this._receiveMessage);
  };
}
