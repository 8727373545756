import { createSelector } from 'reselect';

import {
  IReport,
  IReportsSelectorMetadata,
  IReportsSelectorMetadataWithUserId,
  ReportTypesEnum,
} from '../../types/reports';
import { FilterTypesEnum } from '../../types/shell';
import { sortReportsByCreatedTime } from '../../utils/reports';
import { IAppState } from '../reducers';
import { selectUserDepartmentId, selectUserInfo } from './auth';

export const selectRootState = (state: IAppState) => state.reports;

export const selectDeliveryReports = (type: ReportTypesEnum) =>
  createSelector(selectRootState, (reports): IReport[] | undefined | null => {
    const isScheduled = type === ReportTypesEnum.Scheduled;

    if (isScheduled) {
      return reports && reports[ReportTypesEnum.Scheduled]
        ? Object.values(reports[ReportTypesEnum.Scheduled] || {})
        : undefined;
    }
    return reports && reports[ReportTypesEnum.Regular]
      ? Object.values(reports[ReportTypesEnum.Regular] || {})
      : undefined;
  });

export const selectGeneralReportsByUserId = ({ userId, filter, type }: IReportsSelectorMetadataWithUserId) =>
  createSelector(selectDeliveryReports(type), selectUserDepartmentId, (reports, userDepartmentId) => {
    const isScheduled = type === ReportTypesEnum.Scheduled;
    return reports
      ? reports
          .filter((el) => (filter === FilterTypesEnum.Department ? el.department_id === userDepartmentId : true))
          .filter((el) => (userId ? el.user_id === userId : true))
          .sort(sortReportsByCreatedTime(isScheduled))
      : null;
  });

export const selectFilteredReports = ({ filter, type }: IReportsSelectorMetadata) =>
  createSelector(
    (state: IAppState) => state,
    (state: IAppState) => {
      const isGeneral = filter !== FilterTypesEnum.Individual;
      return isGeneral ? selectGeneralReportsByUserId({ filter, type })(state) : selectSelfReports(type)(state);
    },
  );

export const selectReportsCount = createSelector(selectRootState, (reports) => {
  return reports ? reports.totalCount : 0;
});

export const selectSelfReports = (type: ReportTypesEnum) =>
  createSelector(
    (state: any) => state,
    selectUserInfo,
    (state, userInfo) => {
      if (!userInfo?.user_id) {
        return null;
      }

      return selectGeneralReportsByUserId({ userId: userInfo.user_id, type })(state);
    },
  );

export const selectDeliveryReportById = (id: string | undefined, type: ReportTypesEnum) =>
  createSelector(selectRootState, (reports) => {
    if (!id) {
      return;
    }

    return reports && reports[type] ? reports[type]?.[id] : null;
  });

export const selectIsDeliveryReportsFetching = createSelector(selectRootState, (reports) =>
  reports ? reports.isLoading : false,
);

export const selectIsSingleDeliveryReportsFetching = createSelector(selectRootState, (reports) =>
  reports ? reports.isLoadingSingle : false,
);

export const selectIsEditEngagementFetching = createSelector(selectRootState, (reports) =>
  reports ? reports.isLoadingEdit : false,
);

export const selectIsFinalReport = createSelector(selectRootState, (reports) => (reports ? reports.isFinal : false));

export const selectIsDownloadingReportsCSV = createSelector(selectRootState, (reports) =>
  reports && reports.download ? reports.download.isLoading : false,
);
