import cn from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { countryList } from '../../../constants/countries';
import { NO_FULFILLMENT_CENTER } from '../../../constants/fulfillmentCenters';
import {
  COLOR,
  COUNTRY_OF_ORIGIN,
  FLAVOR,
  FULFILLMENT_CENTER_ID,
  GENDER,
  HTS_CODE,
  ITEM_CUSTOMIZATION_ALLOWED,
  ITEM_CUSTOMIZATION_REQUIRED,
  ITEM_CUSTOMIZATION_TEXT_LABEL,
  OTHER_OPTION,
  PHYSICAL_INVENTORY_ITEM_FC_CHANGE_WARNING,
  PREORDER_ALLOWED,
  PREORDER_HINT_TEXT,
  PREORDER_ITEM_WARNING,
  SIZE,
  SKU,
} from '../../../constants/inventories';
import { selectInventoryItemById } from '../../../store/selectors/inventories';
import { IInventoryItemCandidate } from '../../../types/inventories';
import { IInventoryRouteParams } from '../../../types/routing';
import { getCountryNameByTwoDigits } from '../../../utils/country';
import { isItemFromPYC } from '../../../utils/inventories';
import CountryList from '../../CountryList/CountryList';
import { HelpTooltip, Toggle, WarningMessage } from '../../index';
import LookupSearch from '../../LookupSearch/LookupSearch';
import { FulfillmentCenterSelect, GenderSelect, Input } from '../inputs';
import Common, { IChildRenderProps } from './Common';
import { IRenderInventoryProps } from './InventoryItemForm';

import styles from './InventoryItemForm.module.scss';

const Physical = (props: IRenderInventoryProps) => {
  const { disabled, form, requiredFields, onChange, onInputChange, getFieldErrors, readOnly, isAdding } = props;

  const { itemId } = useParams<IInventoryRouteParams>();
  const originalItem = useSelector(selectInventoryItemById(itemId));
  const [country, setCountry] = React.useState('');

  const isPYCItem = useMemo(
    () => isItemFromPYC({ fulfillment_center_id: form.values?.[FULFILLMENT_CENTER_ID] || '' }),
    [form.values?.[FULFILLMENT_CENTER_ID]],
  );
  const isItemCustomizationAllowed = useMemo(
    () => !!form.values?.[ITEM_CUSTOMIZATION_ALLOWED],
    [form.values?.[ITEM_CUSTOMIZATION_ALLOWED]],
  );
  const isItemCustomizationRequired = useMemo(
    () => !!form.values?.[ITEM_CUSTOMIZATION_REQUIRED],
    [form.values?.[ITEM_CUSTOMIZATION_REQUIRED]],
  );
  const itemCustomizationTextLabelValue = useMemo(() => {
    return form.values?.[ITEM_CUSTOMIZATION_TEXT_LABEL];
  }, [form.values?.[ITEM_CUSTOMIZATION_TEXT_LABEL]]);

  const setCountryNameByTwoDigit = useCallback((digitCode: string) => {
    const selectedCountry = getCountryNameByTwoDigits(digitCode);
    if (selectedCountry) {
      setCountry(selectedCountry.name);
    }
  }, []);

  const handleCountrySelect = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onInputChange(e);
      const { value } = e.currentTarget;
      setCountryNameByTwoDigit(value);
      form.setFieldValue(COUNTRY_OF_ORIGIN, value, true);
    },
    [onInputChange, form.setFieldValue, setCountryNameByTwoDigit],
  );

  const handleClearCountryValue = React.useCallback(() => {
    setCountry('');
    onChange(COUNTRY_OF_ORIGIN, '');
  }, [onChange]);

  const handleFulfillmentCenterChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { value } = e.currentTarget;

      onChange(FULFILLMENT_CENTER_ID, value);
    },
    [onChange],
  );

  const handleToggleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.currentTarget;

      if (onChange) {
        onChange(name as keyof IInventoryItemCandidate, checked);
      }
    },
    [onChange],
  );

  const filteredCountries = React.useMemo(
    () => countryList.filter(({ name }) => name.toLocaleLowerCase().includes(country.toLocaleLowerCase())),
    [country],
  );

  const shouldDisplayFCSelectWarning = React.useMemo(() => {
    return (
      !isAdding &&
      originalItem &&
      form.values?.[FULFILLMENT_CENTER_ID] &&
      form.values?.[FULFILLMENT_CENTER_ID] !== originalItem?.fulfillment_center_id
    );
  }, [form.values?.[FULFILLMENT_CENTER_ID], originalItem, isAdding]);

  React.useEffect(() => {
    // It's used for initial transform two digit
    if (form.values[COUNTRY_OF_ORIGIN] && form.values[COUNTRY_OF_ORIGIN] !== country) {
      setCountryNameByTwoDigit(form.values[COUNTRY_OF_ORIGIN]!);
    }
  }, []);

  useEffect(() => {
    if (!isItemCustomizationAllowed && itemCustomizationTextLabelValue && itemCustomizationTextLabelValue.length > 0) {
      onChange(ITEM_CUSTOMIZATION_TEXT_LABEL, '');
    }

    if (!isItemCustomizationAllowed && isItemCustomizationRequired) {
      onChange(ITEM_CUSTOMIZATION_REQUIRED, false);
    }

    if (!isPYCItem && isItemCustomizationAllowed) {
      onChange(ITEM_CUSTOMIZATION_ALLOWED, false);
    }

    if (isPYCItem && isItemCustomizationAllowed && !isItemCustomizationRequired) {
      onChange(ITEM_CUSTOMIZATION_REQUIRED, true);
    }
  }, [isItemCustomizationAllowed, itemCustomizationTextLabelValue, isItemCustomizationRequired, isPYCItem, onChange]);

  //#region Components to render

  const fulfillmentCenterSelect = React.useMemo(() => {
    return (
      <div className={cn(styles.additionalInfoInputWrapper, styles.input)}>
        <div className={styles.fcSelectContainer}>
          <FulfillmentCenterSelect
            isClearable={false}
            isMulti={false}
            isSearchable
            closeMenuOnSelect
            excludedOptions={NO_FULFILLMENT_CENTER}
            helperText="Fulfillment Center Options"
            name={FULFILLMENT_CENTER_ID}
            placeholder="Fulfillment Center Options"
            value={form.values[FULFILLMENT_CENTER_ID]}
            onChange={handleFulfillmentCenterChange}
            onBlur={() => form.handleBlur(FULFILLMENT_CENTER_ID)}
            showErrors
            error={getFieldErrors(FULFILLMENT_CENTER_ID)}
            readOnly={readOnly}
            required={requiredFields[FULFILLMENT_CENTER_ID]}
          />
          {shouldDisplayFCSelectWarning && <WarningMessage message={PHYSICAL_INVENTORY_ITEM_FC_CHANGE_WARNING} />}
        </div>
      </div>
    );
  }, [
    form.values[FULFILLMENT_CENTER_ID],
    form.handleBlur,
    handleFulfillmentCenterChange,
    readOnly,
    shouldDisplayFCSelectWarning,
    getFieldErrors,
  ]);

  const flavorInput = React.useMemo(() => {
    return (
      <div className={cn(styles.additionalInfoInputWrapper, styles.input, styles.additionalInput)}>
        <Input
          field={form.getFieldProps(FLAVOR)}
          helperText="Flavor"
          name={FLAVOR}
          placeholder="Flavor"
          disabled={disabled}
          value={form.values[FLAVOR]}
          onChange={onInputChange}
          error={getFieldErrors(FLAVOR)}
          showErrors={false}
          readOnly={readOnly}
          required={requiredFields[FLAVOR]}
        />
      </div>
    );
  }, [
    form.values[FLAVOR],
    form.getFieldProps,
    onInputChange,
    getFieldErrors,
    readOnly,
    disabled,
    requiredFields[FLAVOR],
  ]);

  const skuInput = React.useMemo(() => {
    return (
      <div className={cn(styles.input, styles.headerInputField)}>
        <Input
          field={form.getFieldProps(SKU)}
          helperText="SKU"
          name={SKU}
          placeholder="SKU"
          disabled={disabled}
          value={form.values[SKU]}
          onChange={onInputChange}
          error={getFieldErrors(SKU)}
          showErrors={false}
          readOnly={readOnly}
          required={requiredFields[SKU]}
        />
      </div>
    );
  }, [form.values[SKU], form.getFieldProps, onInputChange, getFieldErrors, readOnly, disabled, requiredFields[SKU]]);

  const htsCodeInput = React.useMemo(
    () => (
      <div className={cn(styles.additionalInfoInputWrapper, styles.input, styles.additionalInput)}>
        <Input
          field={form.getFieldProps(HTS_CODE)}
          helperText={
            <div className={styles.label}>
              <span>Harmonization Code</span>
              <HelpTooltip id="hts_code">This is a code needed for customs.</HelpTooltip>
            </div>
          }
          name={HTS_CODE}
          placeholder="Harmonization Code"
          disabled={disabled}
          value={form.values[HTS_CODE]}
          onChange={onInputChange}
          error={getFieldErrors(HTS_CODE)}
          showErrors={false}
          readOnly={readOnly}
          required={requiredFields[HTS_CODE]}
        />
      </div>
    ),
    [form.getFieldProps, disabled, onInputChange, getFieldErrors, readOnly, requiredFields],
  );

  const lookupInput = React.useMemo(() => {
    return (
      <div className={cn(styles.input, styles.headerInputField)}>
        <LookupSearch
          isClearable
          helperText="Country of Origin"
          placeholder="Country of Origin"
          name={COUNTRY_OF_ORIGIN}
          onFocus={(e, value) => setCountry(value)}
          onChange={(e, value) => setCountry(value || '')}
          onDelete={handleClearCountryValue}
          readOnly={readOnly}
          disabled={disabled}
          isLoading={false}
          value={country}
          required={requiredFields[COUNTRY_OF_ORIGIN]}
          result={({ setActive }) => (
            <CountryList
              onSelect={(e) => {
                handleCountrySelect(e);
                setActive(false);
              }}
              items={filteredCountries}
              name={COUNTRY_OF_ORIGIN}
            />
          )}
        />
      </div>
    );
  }, [
    handleClearCountryValue,
    readOnly,
    disabled,
    country,
    requiredFields[COUNTRY_OF_ORIGIN],
    filteredCountries,
    handleCountrySelect,
  ]);

  const preorderToggle = React.useMemo(() => {
    return (
      <>
        <Toggle
          field={form.getFieldProps(PREORDER_ALLOWED)}
          helperText="Enable Preorder"
          name={PREORDER_ALLOWED}
          hint={PREORDER_HINT_TEXT}
          className={styles.preorderToggle}
          onChange={handleToggleChange}
          checked={!!form.values?.[PREORDER_ALLOWED]}
          defaultValue={false}
        />
        {
          <div className={styles.wrapper}>
            {!!form.values?.[PREORDER_ALLOWED] && <WarningMessage message={PREORDER_ITEM_WARNING} />}
          </div>
        }
      </>
    );
  }, [form.getFieldProps, handleToggleChange]);

  const colorInput = React.useMemo(() => {
    return (
      <div className={cn(styles.additionalInfoInputWrapper, styles.input, styles.additionalInput)}>
        <Input
          field={form.getFieldProps(COLOR)}
          helperText="Color"
          name={COLOR}
          placeholder="Color"
          disabled={disabled}
          value={form.values[COLOR]}
          onChange={onInputChange}
          error={getFieldErrors(COLOR)}
          showErrors={false}
          readOnly={readOnly}
          required={requiredFields[COLOR]}
        />
      </div>
    );
  }, [
    form.values[COLOR],
    form.getFieldProps,
    onInputChange,
    getFieldErrors,
    readOnly,
    disabled,
    requiredFields[COLOR],
  ]);

  const sizeInput = React.useMemo(() => {
    return (
      <div className={cn(styles.additionalInfoInputWrapper, styles.input, styles.additionalInput)}>
        <Input
          field={form.getFieldProps(SIZE)}
          helperText="Size"
          name={SIZE}
          placeholder="Size"
          disabled={disabled}
          value={form.values[SIZE]}
          onChange={onInputChange}
          error={getFieldErrors(SIZE)}
          showErrors={false}
          readOnly={readOnly}
          required={requiredFields[SIZE]}
        />
      </div>
    );
  }, [form.values[SIZE], form.getFieldProps, onInputChange, getFieldErrors, readOnly, disabled, requiredFields[SIZE]]);

  const genderInput = React.useMemo(() => {
    return (
      <div className={cn(styles.additionalInfoInputWrapper, styles.input, styles.additionalInput)}>
        <GenderSelect
          field={form.getFieldProps(GENDER)}
          helperText="Gender"
          name={GENDER}
          placeholder="Gender"
          disabled={disabled}
          value={form.values[GENDER]}
          onChange={onChange}
          error={getFieldErrors(GENDER)}
          showErrors={false}
          readOnly={readOnly}
          required={requiredFields[GENDER]}
        />
      </div>
    );
  }, [
    form.values[GENDER],
    form.getFieldProps,
    onInputChange,
    getFieldErrors,
    readOnly,
    disabled,
    requiredFields[GENDER],
  ]);

  const otherOptionInput = React.useMemo(() => {
    return (
      <div className={cn(styles.additionalInfoInputWrapper, styles.input, styles.additionalInput)}>
        <Input
          field={form.getFieldProps(OTHER_OPTION)}
          helperText="Other Option"
          name={OTHER_OPTION}
          placeholder="Other Option"
          disabled={disabled}
          value={form.values[OTHER_OPTION]}
          onChange={onInputChange}
          error={getFieldErrors(OTHER_OPTION)}
          showErrors={false}
          readOnly={readOnly}
          required={requiredFields[OTHER_OPTION]}
        />
      </div>
    );
  }, [
    form.values[OTHER_OPTION],
    form.getFieldProps,
    onInputChange,
    getFieldErrors,
    readOnly,
    disabled,
    requiredFields[OTHER_OPTION],
  ]);

  const customizationToggle = React.useMemo(() => {
    return (
      <Toggle
        field={form.getFieldProps(ITEM_CUSTOMIZATION_ALLOWED)}
        name={ITEM_CUSTOMIZATION_ALLOWED}
        className={cn(styles.input, styles.toggle)}
        disabled={disabled || readOnly}
        hint={
          <React.Fragment>
            This option turns on
            <br />
            item customization option.
          </React.Fragment>
        }
        helperText="Allow item customization"
        onChange={handleToggleChange}
        checked={!!form.values?.[ITEM_CUSTOMIZATION_ALLOWED]}
      />
    );
  }, [
    form.values[ITEM_CUSTOMIZATION_ALLOWED],
    form.getFieldProps,
    handleToggleChange,
    onInputChange,
    readOnly,
    disabled,
  ]);

  const customizationLabelInput = React.useMemo(() => {
    return (
      <Input
        showErrors
        className={cn(styles.input, styles.additionalInput)}
        field={form.getFieldProps(ITEM_CUSTOMIZATION_TEXT_LABEL)}
        helperText="Name for the text area for the recipient"
        name={ITEM_CUSTOMIZATION_TEXT_LABEL}
        placeholder="Input your text to customize"
        disabled={disabled}
        value={form.values[ITEM_CUSTOMIZATION_TEXT_LABEL]}
        onChange={onInputChange}
        error={getFieldErrors(ITEM_CUSTOMIZATION_TEXT_LABEL)}
        readOnly={readOnly}
        required={requiredFields[ITEM_CUSTOMIZATION_TEXT_LABEL]}
      />
    );
  }, [
    form.values[ITEM_CUSTOMIZATION_TEXT_LABEL],
    form.getFieldProps,
    handleToggleChange,
    onInputChange,
    readOnly,
    disabled,
    getFieldErrors,
    requiredFields[ITEM_CUSTOMIZATION_TEXT_LABEL],
  ]);

  //#endregion

  return (
    <Common {...props}>
      {({
        nameInput,
        descriptionTextArea,
        imageInput,
        departmentsInput,
        priceInput,
        hiddenOrgIdInput,
        hiddenTypeInput,
      }: IChildRenderProps) => (
        <form onSubmit={form.handleSubmit} className={styles.inventoryItemForm}>
          <div className={styles.baseInfo}>
            {imageInput}
            <div className={styles.info}>
              <div className={cn(styles.infoRow, styles.headerInputRow)}>
                {departmentsInput}
                {skuInput}
                {nameInput}
                {priceInput}
                {lookupInput}
              </div>
              {preorderToggle}
            </div>
          </div>
          {descriptionTextArea}
          <div className={styles.additionalInfo}>
            {fulfillmentCenterSelect}
            {htsCodeInput}
            {flavorInput}
            {colorInput}
            {sizeInput}
            {genderInput}
            {otherOptionInput}
            {isPYCItem && (
              <div className={cn(styles.itemCustomizationContainer)}>
                {customizationToggle}
                {isItemCustomizationAllowed && (
                  <div className={styles.itemCustomizationOptions}>
                    <h2 className={styles.heading}>Item Customization</h2>
                    {customizationLabelInput}
                  </div>
                )}
              </div>
            )}
          </div>
          {hiddenOrgIdInput}
          {hiddenTypeInput}
        </form>
      )}
    </Common>
  );
};

export default Physical;
