import * as React from 'react';

import { IOrgAddress } from '../../types/addressBook';
import { IOneLinkReceiverFixedAddress } from '../../types/oneLink';
import { isObjectEmpty } from '../../utils/helpers';
import { CompactOrgAddressView } from '../index';

import styles from './SingleAddress.module.scss';

interface IProps {
  address?: IOrgAddress | IOneLinkReceiverFixedAddress;
}

export const SingleAddress: React.FC<IProps> = ({ address }) => {
  if (!address || isObjectEmpty(address)) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <CompactOrgAddressView row={address} addressClassName={styles.address} />
    </div>
  );
};

export default SingleAddress;
