// DEV
// export const apiUrl = 'http://localhost:5000/bluebird-234f6/us-central1';
// PROD
// export const apiUrl = 'http://localhost:5000/bluebird-prod-fc31a/us-central1';
export const apiUrl = process.env.REACT_APP_API_URL || '';
export const sfUrl = 'https://login.salesforce.com';
export const hubspotUrl = 'https://app.hubspot.com';
export const outlookUrl = 'https://login.microsoftonline.com/common';
export const appType = 'Portal';
// export const gaId = process.env.REACT_APP_GA_ID || '';
export const remoteLoggerKey = process.env.REACT_APP_LOGGER_KEY || '';
export const CSV_BULK_SEND_EXAMPLE_URL = process.env.REACT_APP_CSV_BULK_SEND_EXAMPLE_URL || '';
export const CSV_BULK_INVENTORY_UPLOAD_EXAMPLE_URL = process.env.REACT_APP_CSV_BULK_INVENTORY_UPLOAD_EXAMPLE_URL || '';
export const CSV_ONE_LINK_RECIPIENTS_EXAMPLE_URL = process.env.REACT_APP_CSV_ONE_LINK_RECIPIENTS_EXAMPLE_URL || '';

// Feature flags
