import cn from 'classnames';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import * as React from 'react';

import { DateFormatsEnum } from '../../../../constants/date';
import useModal from '../../../../hooks/useModal';
import { UISizeEnum } from '../../../../types/shell';
import { getUTCDate } from '../../../../utils/date';
import { ActionButton, ControlButton, DropdownWithContent } from '../../../index';
import { DatePicker } from '../index';

import styles from './DateSinglePicker.module.scss';

interface IProps {
  className?: string;
  contentClassName?: string;
  value: string | null;
  formatRule: DateFormatsEnum;
  formatView?: DateFormatsEnum;
  onSelect?: (value: string | null) => void;
  onReset?: () => void;
  defaultLabel?: string;
  resetLabel?: string;
  submitLabel?: string;
  modalMode?: boolean;
  maxDate?: Date | null;
  minDate?: Date | null;
  readOnly?: boolean;
  closeModalOnReset?: boolean;
}

interface IControlProps {
  close: () => void;
}

const DateSinglePicker = React.forwardRef(
  (
    {
      className,
      contentClassName,
      defaultLabel,
      value,
      formatRule,
      formatView = DateFormatsEnum.ddMMMyyyy,
      onSelect,
      onReset,
      modalMode,
      resetLabel,
      submitLabel,
      maxDate,
      minDate,
      readOnly,
      closeModalOnReset,
    }: IProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const maxPickerDate = React.useMemo(() => (maxDate ? getUTCDate(maxDate.toISOString()) : maxDate), [maxDate]);
    const minPickerDate = React.useMemo(() => (minDate ? getUTCDate(minDate.toISOString()) : minDate), [minDate]);

    const [date, setDate] = React.useState<Date | null>(value ? getUTCDate(value) : null);

    const {
      Modal: MobileModal,
      openModal: openMobileModal,
      closeModal: closeMobileModal,
      isOpen: isMobileModalOpened,
    } = useModal({ resetStyles: true });

    const label = React.useMemo(() => {
      if (value) {
        const utcDate = getUTCDate(value);

        return `${utcDate ? format(utcDate, formatView) : 'Select date'}`;
      }
      return defaultLabel || 'Select date';
    }, [value, defaultLabel, formatView]);

    const handleSelect = React.useCallback(
      ({ close }: IControlProps) =>
        () => {
          onSelect?.(date ? format(date, formatRule) : null);
          close();
        },
      [date, onSelect],
    );

    const handleOpen = React.useCallback(() => {
      if (value) {
        setDate(getUTCDate(value));
      }
    }, [value]);

    const handleClose = React.useCallback(() => {
      if (!date) {
        onSelect?.(null);
      }
    }, [date, onSelect]);

    const handleReset = React.useCallback(
      ({ close }: IControlProps) =>
        () => {
          if (closeModalOnReset) {
            close();
          }
          setDate(null);
          onReset?.();
        },
      [onReset, closeModalOnReset],
    );

    const renderControls = React.useCallback(
      ({ close }: IControlProps) => (
        <div className={styles.controls}>
          <ActionButton className={styles.controlBtn} size={UISizeEnum.Small} outlined onClick={handleReset({ close })}>
            {resetLabel || 'Reset'}
          </ActionButton>
          <ActionButton className={styles.controlBtn} size={UISizeEnum.Small} onClick={handleSelect({ close })}>
            {submitLabel || 'Select'}
          </ActionButton>
        </div>
      ),
      [handleReset, handleSelect, resetLabel, submitLabel],
    );

    const isSelectedDate = React.useCallback(
      (d: Date) => (date && isSameDay(date, d) ? styles.selected : null),
      [date],
    );

    const handleModalOpen = React.useCallback(() => {
      handleOpen();
      openMobileModal();
    }, [handleOpen, openMobileModal]);

    React.useEffect(() => {
      if (!modalMode && isMobileModalOpened) {
        closeMobileModal();
      }
    }, [modalMode, closeMobileModal, isMobileModalOpened]);

    const defaultLayout = React.useMemo(
      () => (
        <DatePicker
          selectsStart
          openToDate={date || undefined}
          startDate={date}
          onChange={(newStartDate) => {
            setDate(newStartDate as Date);
          }}
          maxDate={maxPickerDate}
          minDate={minPickerDate}
          inline
          disabledKeyboardNavigation
          dayClassName={isSelectedDate}
        />
      ),
      [date, isSelectedDate, maxPickerDate, minPickerDate],
    );

    const modalLayout = React.useMemo(
      () => (
        <MobileModal>
          {() => (
            <div className={cn(styles.picker, styles.modal)}>
              <div className={styles.calendarsPanel}>
                <DatePicker
                  selectsStart
                  openToDate={date || undefined}
                  startDate={date}
                  onChange={(newStartDate) => {
                    setDate(newStartDate as Date);
                  }}
                  maxDate={maxPickerDate}
                  minDate={minPickerDate}
                  inline
                  disabledKeyboardNavigation
                  dayClassName={isSelectedDate}
                />
              </div>
              {renderControls({ close: closeMobileModal })}
            </div>
          )}
        </MobileModal>
      ),
      [MobileModal, date, isSelectedDate, closeMobileModal, minPickerDate, maxPickerDate, renderControls],
    );

    return !modalMode ? (
      <DropdownWithContent
        canOpen={!readOnly}
        onOpen={handleOpen}
        onClose={handleClose}
        className={className}
        contentClassName={cn(styles.container, contentClassName)}
        label={({ isOpened }) => <ControlButton ref={ref} label={label} isOpened={isOpened} readOnly={readOnly} />}
      >
        {({ close }) => (
          <div className={styles.picker}>
            <div className={cn(styles.calendarsPanel)}>{defaultLayout}</div>
            {renderControls({ close })}
          </div>
        )}
      </DropdownWithContent>
    ) : (
      <React.Fragment>
        <ControlButton label={label} isOpened={isMobileModalOpened} onClick={handleModalOpen} />
        {modalLayout}
      </React.Fragment>
    );
  },
);

export default DateSinglePicker;
