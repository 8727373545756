import cn from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FileError, FileRejection } from 'react-dropzone';
import { useSelector } from 'react-redux';

import {
  CURRENCY_CODE,
  DEPARTMENT_IDS,
  DESCRIPTION,
  IMAGE_URL,
  MAX_ITEM_CUSTOMIZATION_DESCRIPTION_LENGTH,
  NAME,
  ORG_ID,
  PRICE,
  TYPE,
} from '../../../constants/inventories';
import { NO_DEPARTMENT } from '../../../constants/organizations';
import { endpoints } from '../../../constants/routing';
import useFetch from '../../../hooks/useFetch';
import useModal from '../../../hooks/useModal';
import useUploadFile from '../../../hooks/useUploadFile';
import { selectCurrentOrganizationId } from '../../../store/selectors/organizations';
import { IDropzoneResult, MaintenanceFormStateEnum } from '../../../types/forms';
import { IFetchInventoryImagesResponse } from '../../../types/inventories';
import { IUploadResponse, UploadFileTypeEnum } from '../../../types/upload';
import notification from '../../../utils/notification';
import { preparedDropZoneErrors } from '../../../utils/upload';
import ImageLibraryModal from '../../modals/ImageLibraryModal/ImageLibraryModal';
import { DepartmentSelect, HiddenInput, ImageUploadInput, Input, MoneyInput, Textarea } from '../inputs';
import { IRenderInventoryProps } from './InventoryItemForm';

import styles from './InventoryItemForm.module.scss';

export interface IChildRenderProps {
  nameInput: React.ReactNode;
  departmentsInput: React.ReactNode;
  imageInput: React.ReactNode;
  descriptionTextArea: React.ReactNode;
  priceInput: React.ReactNode;
  hiddenTypeInput: React.ReactNode;
  hiddenOrgIdInput: React.ReactNode;
  readOnly: boolean;
}

interface IProps extends IRenderInventoryProps {
  children: (childProps: IChildRenderProps) => JSX.Element;
}

const Common = ({
  children,
  form,
  disabled,
  requiredFields,
  onChange,
  onInputChange,
  mode,
  getFieldErrors,
}: IProps) => {
  const { uploadFile } = useUploadFile();
  const { openModal: openChooseImageModal, closeModal: closeChooseImageModal, Modal: ChooseImageModal } = useModal();
  const { make: fetchInventoryImages } = useFetch<never, IFetchInventoryImagesResponse>({
    endpoint: endpoints.getInventoryImagesList,
  });

  const currentOrgId = useSelector(selectCurrentOrganizationId);

  const readOnly = useMemo(() => mode === MaintenanceFormStateEnum.Reading, [mode]);

  const onUploadStart = useCallback(
    ({ files, resolve, reject }: IDropzoneResult) => {
      uploadFile(files[0], UploadFileTypeEnum.InventoryItem).then(resolve).catch(reject);
    },
    [uploadFile],
  );

  const onUploadSuccess = useCallback(
    (files: File[], response: IUploadResponse) => {
      const { public_url: url } = response;
      onChange(IMAGE_URL, url);
    },
    [onChange],
  );

  const onUploadFailure = useCallback((errors: FileError[]) => {
    const showError = ({ message }: any) => notification.error(message, { content: message });

    Array.isArray(errors) ? errors.map(showError) : showError(errors);
  }, []);

  const onDropReject = useCallback(
    (fileRejections: FileRejection[]) => {
      fileRejections.forEach((fileReject) => {
        const { errors } = fileReject;
        onUploadFailure(preparedDropZoneErrors(errors));
      });
    },
    [onUploadFailure],
  );

  const handleImageChoose = useCallback(
    (url: string) => {
      form.setFieldValue(IMAGE_URL, url, true);
      onChange(IMAGE_URL, url);
    },
    [onChange, form.setFieldValue],
  );

  useEffect(() => {
    onChange(ORG_ID, currentOrgId);
  }, [currentOrgId]);

  //#region Components to render

  const chooseImageModal = useMemo(
    () => (
      <ChooseImageModal className="image-library">
        {() => (
          <ImageLibraryModal
            onFetch={({ resolve, reject }) =>
              fetchInventoryImages()
                .then(({ images }) => resolve(images))
                .catch(reject)
            }
            onChoose={(img) => handleImageChoose(img.public_url)}
            onClose={closeChooseImageModal}
            onUploadStart={onUploadStart}
            onUploadFailure={onUploadFailure}
            onDropReject={onDropReject}
          />
        )}
      </ChooseImageModal>
    ),
    [
      closeChooseImageModal,
      ChooseImageModal,
      onUploadStart,
      onUploadFailure,
      handleImageChoose,
      fetchInventoryImages,
      onDropReject,
    ],
  );

  const nameInput = useMemo(
    () => (
      <div className={cn(styles.input, styles.headerInputField)}>
        <Input
          field={form.getFieldProps(NAME)}
          helperText="Name"
          name={NAME}
          placeholder="Name"
          disabled={disabled}
          value={form.values[NAME]}
          onChange={onInputChange}
          error={getFieldErrors(NAME)}
          showErrors
          readOnly={readOnly}
          required={requiredFields[NAME]}
        />
      </div>
    ),
    [form.values[NAME], form.getFieldProps, getFieldErrors, disabled, onInputChange, readOnly, requiredFields[NAME]],
  );

  const departmentsInput = useMemo(
    () => (
      <div className={cn(styles.departmentInput, styles.input, styles.headerInputField)}>
        <DepartmentSelect
          isMulti
          helperText="Choose Department"
          value={form.values[DEPARTMENT_IDS]}
          defaultValue={NO_DEPARTMENT}
          onChange={onInputChange}
          onBlur={form.handleBlur}
          error={getFieldErrors(DEPARTMENT_IDS)}
          className={styles.departmentSelector}
          name={DEPARTMENT_IDS}
          required={requiredFields[DEPARTMENT_IDS]}
          readOnly={readOnly}
        />
      </div>
    ),
    [
      form.values[DEPARTMENT_IDS],
      form.handleBlur,
      form.values,
      onInputChange,
      requiredFields[DEPARTMENT_IDS],
      getFieldErrors,
    ],
  );

  const imageInput = useMemo(
    () => (
      <>
        <ImageUploadInput
          onUpload={onUploadStart}
          onSuccess={onUploadSuccess}
          onFailure={onUploadFailure}
          onDropReject={onDropReject}
          className={styles.uploadField}
          onClick={openChooseImageModal}
          onImageDelete={() => handleImageChoose('')}
          value={form.values[IMAGE_URL]}
          readOnly={readOnly}
        />
        {chooseImageModal}
      </>
    ),
    [
      form.values[IMAGE_URL],
      handleImageChoose,
      onDropReject,
      onUploadFailure,
      onUploadStart,
      onUploadSuccess,
      openChooseImageModal,
      readOnly,
      chooseImageModal,
    ],
  );

  const descriptionTextArea = useMemo(
    () => (
      <Textarea
        className={styles.textareaContainer}
        field={form.getFieldProps(DESCRIPTION)}
        helperText="Description"
        name={DESCRIPTION}
        placeholder="Description"
        disabled={disabled}
        value={form.values[DESCRIPTION]}
        onChange={onInputChange}
        readOnly={readOnly}
        required={requiredFields[DESCRIPTION]}
        showCounter
        maxCharsCount={MAX_ITEM_CUSTOMIZATION_DESCRIPTION_LENGTH}
      />
    ),
    [form.values[DESCRIPTION], disabled, onInputChange, requiredFields[DESCRIPTION], readOnly, requiredFields],
  );

  const priceInput = useMemo(
    () => (
      <div className={cn(styles.input, styles.headerInputField)}>
        <MoneyInput
          currency={form.values[CURRENCY_CODE]}
          amount={form.values[PRICE]}
          helperText="Price"
          names={{ currency: CURRENCY_CODE, amount: PRICE }}
          placeholder="Price"
          isDisabled={disabled}
          onChange={onChange}
          error={getFieldErrors(PRICE)}
          isReadOnly={readOnly}
          isRequired={requiredFields[PRICE]}
          onBlur={form.handleBlur}
        />
      </div>
    ),
    [form.values[PRICE], form.getFieldProps, getFieldErrors, disabled, onInputChange, readOnly, requiredFields[PRICE]],
  );

  const hiddenOrgIdInput = useMemo(
    () => <HiddenInput name={ORG_ID} value={form.values[ORG_ID]} />,
    [form.values[ORG_ID]],
  );

  const hiddenTypeInput = useMemo(() => <HiddenInput name={TYPE} value={form.values[TYPE]} />, [form.values[TYPE]]);

  //#endregion

  return children({
    nameInput,
    departmentsInput,
    imageInput,
    descriptionTextArea,
    priceInput,
    hiddenOrgIdInput,
    hiddenTypeInput,
    readOnly,
  });
};

export default Common;
