import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { EMPTY_EMAIL_TEMPLATE, EMPTY_NOTE_TEMPLATE } from '../../constants/templates';
import { IEmailTemplate, INoteTemplate, ITemplatesState } from '../../types/templates';
import { isTemplateEmail } from '../../utils/template';
import * as AuthActions from '../actions/auth';
import * as TemplatesActions from '../actions/templates';

export const initialState: ITemplatesState = {
  emails: {
    templates: null,
    isLoading: false,
    error: null,
  },
  notes: {
    templates: null,
    isLoading: false,
    error: null,
  },
  templateDetails: {
    template: null,
    isLoading: false,
    error: null,
  },
};

const reducer = (state: ITemplatesState = initialState, action: any): ITemplatesState => {
  switch (action.type) {
    case TemplatesActions.FETCH_EMAILS_TEMPLATES_REQUEST: {
      return {
        ...state,
        emails: {
          templates: null,
          isLoading: true,
          error: null,
        },
      };
    }
    case TemplatesActions.FETCH_EMAILS_TEMPLATES_FAILURE: {
      return {
        ...state,
        emails: { templates: null, isLoading: false, error: action.payload },
      };
    }
    case TemplatesActions.FETCH_EMAILS_TEMPLATES_SUCCESS: {
      const { email_templates } = action.payload || {};
      return {
        ...state,
        emails: {
          templates: [...(email_templates && email_templates.length ? email_templates : []), EMPTY_EMAIL_TEMPLATE],
          isLoading: false,
          error: null,
        },
      };
    }
    case TemplatesActions.FETCH_NOTES_TEMPLATES_REQUEST: {
      return {
        ...state,
        notes: { templates: null, isLoading: true, error: null },
      };
    }
    case TemplatesActions.FETCH_NOTES_TEMPLATES_FAILURE: {
      return {
        ...state,
        notes: { templates: null, isLoading: false, error: action.payload },
      };
    }
    case TemplatesActions.FETCH_NOTES_TEMPLATES_SUCCESS: {
      const { message_templates } = action.payload || {};
      return {
        ...state,
        notes: {
          templates: [...(message_templates && message_templates.length ? message_templates : []), EMPTY_NOTE_TEMPLATE],
          isLoading: false,
          error: null,
        },
      };
    }
    case TemplatesActions.ADD_TEMPLATE_VALUE: {
      return {
        ...state,
        templateDetails: {
          ...state.templateDetails,
          template: {
            ...state.templateDetails.template,
            ...action.payload,
          },
        },
      };
    }
    case TemplatesActions.SET_TEMPLATE_VALUE: {
      return {
        ...state,
        templateDetails: {
          ...state.templateDetails,
          template: action.payload,
        },
      };
    }
    case TemplatesActions.EDIT_TEMPLATE_REQUEST:
    case TemplatesActions.ADD_TEMPLATE_REQUEST: {
      return {
        ...state,
        templateDetails: {
          template: action.payload.template,
          isLoading: true,
          error: null,
        },
      };
    }
    case TemplatesActions.EDIT_TEMPLATE_SUCCESS:
    case TemplatesActions.ADD_TEMPLATE_SUCCESS: {
      return {
        ...state,
        templateDetails: {
          ...state.templateDetails,
          isLoading: false,
        },
      };
    }
    case TemplatesActions.EDIT_TEMPLATE_FAILURE:
    case TemplatesActions.ADD_TEMPLATE_FAILURE: {
      return {
        ...state,
        templateDetails: {
          ...state.templateDetails,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case TemplatesActions.DELETE_TEMPLATE_SUCCESS: {
      const { itemId, type } = action.payload;
      const filteredList = [...((isTemplateEmail(type) ? state.emails.templates : state.notes.templates) || [])].filter(
        (template) => template.item_id !== itemId,
      );

      return {
        ...state,
        ...(isTemplateEmail(type)
          ? {
              emails: {
                ...state.emails,
                templates: filteredList as IEmailTemplate[],
              },
            }
          : {
              notes: {
                ...state.emails,
                templates: filteredList as INoteTemplate[],
              },
            }),
      };
    }
    case TemplatesActions.CLEAR_TEMPLATE_VALUE: {
      return {
        ...state,
        templateDetails: {
          template: null,
          isLoading: false,
          error: null,
        },
      };
    }
    case FirebaseActions.LOGOUT:
    case AuthActions.ADMIN_ROLE_CHANGED:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
