import * as React from 'react';

import { ActionButton } from '../../forms';
import Loader from '../../Loader/Loader';
import ModalActions from '../ModalActions/ModalActions';
import ModalHeader from '../ModalHeader/ModalHeader';

import styles from './CommonModals.module.scss';

interface IProps {
  onCancel?: () => void;
  onReset?: () => void;
  isLoading?: boolean;
}

const ResetUserPasswordModal: React.FC<IProps> = ({ onCancel, onReset, isLoading = false }: IProps) => {
  const handleOnReset = React.useCallback(() => {
    if (onReset) {
      onReset();
    }
  }, [onReset]);

  const handleOnCancel = React.useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  return (
    <div className={styles.container}>
      <Loader isLoading={isLoading} />
      <ModalHeader className={styles.header} />
      <div className={styles.content}>
        <div className={styles.contentHeader}>Reset Password</div>
        <div className={styles.description}>
          <p>Are you sure you want to reset the password?</p>
        </div>
      </div>
      <ModalActions className={styles.footer}>
        <ActionButton title="Cancel" onClick={handleOnCancel} outlined />
        <ActionButton title="Reset" onClick={handleOnReset} />
      </ModalActions>
    </div>
  );
};

export default ResetUserPasswordModal;
