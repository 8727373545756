import cn from 'classnames';
import * as React from 'react';

import { ShippingPageCustomizationForm } from '../../../components/forms';
import { ORGANIZATION_VALIDATION_SCHEMA as validationSchema } from '../../../constants/organizations';
import { ICampaignCandidate } from '../../../types/campaigns';
import { ICampaignFormProps } from '../../../types/forms';
import { getRequiredFields } from '../../../utils/form';

import styles from './EditShippingTextComponent.module.scss';

export interface IEditShippingTextComponentProps extends ICampaignFormProps<ICampaignCandidate> {
  className?: string;
}

const EditShippingTextComponent: React.FC<IEditShippingTextComponentProps> = ({
  form,
  className,
  mode,
  onChange,
  readOnly,
}) => {
  const requiredFields = React.useMemo(() => getRequiredFields(validationSchema), []);

  return (
    <div className={cn(styles.container, className)}>
      <ShippingPageCustomizationForm
        form={form}
        mode={mode}
        readOnly={readOnly}
        onChange={onChange}
        requiredFields={requiredFields}
      />
    </div>
  );
};

export default EditShippingTextComponent;
