import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectUserBudgetCurrency, selectUserDisplayCurrency } from '../store/selectors/auth';
import { selectBaseCurrency, selectCurrencyRates } from '../store/selectors/shell';
import { InventoryTypesEnum } from '../types/inventories';
import { ICurrencyRates, IResultPriceWithCurrency } from '../types/shell';
import { isItemMsku } from '../utils/inventories';

interface IUnitWithPriceAndCurrency {
  price?: number;
  currency_code?: string;
}

interface IItemWithOptions extends IUnitWithPriceAndCurrency {
  type: InventoryTypesEnum;
  sku_options?: IUnitWithPriceAndCurrency[];
}

interface IBoxWithItems {
  items?:
    | (IItemWithOptions & {
        quantity?: number;
      })[]
    | null;
}

interface ISendWithOneOrManyCampaigns extends IBoxWithItems {
  pre_created_engagement_id?: string;
  pick_campaigns?: IBoxWithItems[] | null;
}

interface ICurrencyProviderContext {
  displayCurrency: string;
  budgetCurrency: string;
  requiredCurrency: string;
  currencyRates: ICurrencyRates;
  convertAmount: (amount: number, sourceCurrency?: string, targetCurrency?: string) => number;
  getRequiredPriceAndCurrency: (item: IUnitWithPriceAndCurrency) => IResultPriceWithCurrency;
  getItemPrice: (item: IItemWithOptions) => IResultPriceWithCurrency;
  getCampaignTotal: (campaign: IBoxWithItems) => IResultPriceWithCurrency;
  getSendTotalPrice: (instance?: ISendWithOneOrManyCampaigns | null) => IResultPriceWithCurrency;
}

const CurrencyContext = React.createContext<ICurrencyProviderContext>({
  displayCurrency: '',
  budgetCurrency: '',
  requiredCurrency: '',
  currencyRates: {} as ICurrencyRates,
  convertAmount: (amount: number = 0, sourceCurrency?: string, targetCurrency?: string) => 0,
  getRequiredPriceAndCurrency: (item: IUnitWithPriceAndCurrency) => ({ price: 0, currency: '' }),
  getItemPrice: (item: IItemWithOptions) => ({ price: 0, currency: '' }),
  getCampaignTotal: (campaign: IBoxWithItems) => ({ price: 0, currency: '' }),
  getSendTotalPrice: (send?: ISendWithOneOrManyCampaigns | null) => ({ price: 0, currency: '' }),
});

interface IProps {
  children: React.ReactNode;
}

export const useCurrency = () => React.useContext(CurrencyContext);

const CurrencyProvider: React.FC<IProps> = ({ children }) => {
  const fallbackCurrency = useSelector(selectBaseCurrency);
  const userDisplayCurrency = useSelector(selectUserDisplayCurrency);
  const userBudgetCurrency = useSelector(selectUserBudgetCurrency);
  const currencyRates = useSelector(selectCurrencyRates);

  const requiredDisplayCurrency = React.useMemo(
    () => userDisplayCurrency || userBudgetCurrency || fallbackCurrency,
    [userDisplayCurrency, userBudgetCurrency],
  );

  const convertAmount = React.useCallback(
    (
      amount: number = 0,
      sourceCurrency: string = fallbackCurrency,
      targetCurrency: string = fallbackCurrency,
    ): number => {
      if (!sourceCurrency) {
        console.warn('No source currency provided. Falling back to', fallbackCurrency);
        sourceCurrency = fallbackCurrency;
      }

      if (!targetCurrency) {
        console.warn('No target currency provided. Falling back to', fallbackCurrency);
        targetCurrency = fallbackCurrency;
      }

      if (sourceCurrency === targetCurrency) {
        return amount;
      }

      const sourceRate = currencyRates[sourceCurrency];
      const targetRate = currencyRates[targetCurrency];

      return amount * (targetRate / sourceRate);
    },
    [currencyRates, fallbackCurrency],
  );

  const getRequiredPriceAndCurrency = React.useCallback(
    ({ price, currency_code }: IUnitWithPriceAndCurrency): IResultPriceWithCurrency => {
      return { price: convertAmount(price, currency_code, requiredDisplayCurrency), currency: requiredDisplayCurrency };
    },
    [requiredDisplayCurrency, convertAmount],
  );

  const getItemPrice = React.useCallback(
    (item: IItemWithOptions) => {
      if (isItemMsku(item) && item.sku_options?.length) {
        const pricesArray = item.sku_options.map(({ price, currency_code }) =>
          convertAmount(price, currency_code, requiredDisplayCurrency),
        );

        if (pricesArray && pricesArray.length) {
          return {
            price: Math.max(...pricesArray),
            currency: requiredDisplayCurrency,
          };
        }
      }

      return userDisplayCurrency
        ? { price: convertAmount(item.price, item.currency_code, userDisplayCurrency), currency: userDisplayCurrency }
        : { price: item.price || 0, currency: item.currency_code || fallbackCurrency };
    },
    [convertAmount, userDisplayCurrency, requiredDisplayCurrency, fallbackCurrency],
  );

  const getCampaignTotal = React.useCallback(
    (campaign: IBoxWithItems) => {
      const result = { price: 0, currency: requiredDisplayCurrency };

      if (campaign && campaign.items && campaign.items.length) {
        result.price = campaign.items.reduce((acc, { quantity = 0, ...item }) => {
          const itemPrice = getItemPrice(item);

          const { currency } = itemPrice;
          let { price } = itemPrice;

          if (currency !== requiredDisplayCurrency) {
            price = convertAmount(price, currency, requiredDisplayCurrency);
          }

          return acc + price * quantity;
        }, 0);
      }

      return result;
    },
    [getItemPrice, requiredDisplayCurrency, convertAmount],
  );

  const getSendTotalPrice = React.useCallback(
    (instance?: ISendWithOneOrManyCampaigns | null) => {
      const result = { price: 0, currency: requiredDisplayCurrency };

      if (!instance) {
        return result;
      }

      const { pre_created_engagement_id: preCreatedEngagementId, items, pick_campaigns: pickCampaigns } = instance;

      if (preCreatedEngagementId) {
        return getCampaignTotal({ items });
      }

      if (pickCampaigns) {
        result.price = pickCampaigns
          ?.map(getCampaignTotal)
          .map((c) => c.price)
          .reduce((a, b) => Math.max(a, b));
      }

      return result;
    },
    [getCampaignTotal, requiredDisplayCurrency],
  );

  return (
    <CurrencyContext.Provider
      value={{
        displayCurrency: userDisplayCurrency,
        budgetCurrency: userBudgetCurrency,
        requiredCurrency: requiredDisplayCurrency,
        currencyRates,
        convertAmount,
        getRequiredPriceAndCurrency,
        getItemPrice,
        getCampaignTotal,
        getSendTotalPrice,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
