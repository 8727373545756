import { createSelector } from 'reselect';
import { IOrgAddress } from '../../types/addressBook';
import { IAppState } from '../reducers';

export const selectRootState = (state: IAppState) => state.addressBook;

export const selectAddressBook = createSelector(selectRootState, (orgAddresses): IOrgAddress[] | undefined | null =>
  orgAddresses && orgAddresses.items ? orgAddresses.items : undefined,
);

export const selectOrgAddressById = (id?: string) =>
  createSelector(selectAddressBook, (addresses) =>
    id && addresses && addresses.length ? addresses.find((address) => address.uid === id) : undefined,
  );
