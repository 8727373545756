import { createSelector } from 'reselect';

import { SIGNATURE_INITIAL_VALUES } from '../../constants/signature';
import { selectUserInfo } from './auth';

export const selectSignature = createSelector(selectUserInfo, (userInfo) => {
  return userInfo ? userInfo.signature : undefined;
});

export const selectCurrentSignature = createSelector(selectSignature, (signature) => {
  return signature ? signature.value : undefined;
});

export const selectCandidateSignature = createSelector(selectSignature, (signature) => {
  return signature ? signature.candidate : undefined;
});

export const selectIsSignatureLoading = createSelector(selectSignature, (signature) =>
  signature ? signature.isLoading : false,
);

export const selectSignatureFormInitialValues = createSelector(selectCandidateSignature, (signature) => ({
  ...SIGNATURE_INITIAL_VALUES,
  ...{ signature },
}));
