import cn from 'classnames';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import MaintenanceForm from '../../components/forms/MaintenanceForm/MaintenanceForm';
import UserForm from '../../components/forms/UserForm/UserForm';
import { ReassignUserModal } from '../../components/modals';
import { IReassignUserModalProps } from '../../components/modals/CommonModals/ReassignUserModal/ReassignUserModal';
import { routes, URL_VARS } from '../../constants/routing';
import {
  ADMIN_TYPE,
  COUNTRY,
  DISTRIBUTOR,
  UserSuccessToastMessages,
  USERS_VALIDATION_SCHEMA as validationSchema,
  USER_INITIAL_VALUES,
  USER_LABEL,
  USER_REASSIGN_REQUIRED_ERROR_CODE,
} from '../../constants/users';
import useModal from '../../hooks/useModal';
import {
  addUserDetails,
  addUserRequest,
  clearUserDetails,
  deleteUserRequest,
  editUserRequest,
} from '../../store/actions/users';
import { selectUserById, selectUserDetails, selectUserFormInitialValues } from '../../store/selectors/users';
import { IErrorResponse } from '../../types/bucket';
import { FormStatusEnum, IMaintenanceFormOutputData, MaintenanceFormStateEnum } from '../../types/forms';
import { IUserRouteParams } from '../../types/routing';
import { IApiError, NotificationListEnum } from '../../types/shell';
import { IAddUserActionPayload, IEditUserActionPayload, IUser } from '../../types/users';
import { getRequiredFields } from '../../utils/form';
import { isObjectsEqual } from '../../utils/helpers';
import notification from '../../utils/notification';
import { handleApiError } from '../../utils/ui';
import { getUserFormRequiredFieldsByCountryCode } from '../../utils/users';

import styles from './EditUserContainer.module.scss';

interface IProps {
  onResetPassword?: (email: string) => void;
}

const EditUserContainer = ({ onResetPassword }: IProps) => {
  const { flowId, userId } = useParams<IUserRouteParams>();

  const dispatch = useDispatch();
  const history = useHistory();

  const userDetails = useSelector(selectUserDetails);
  const originalUser = useSelector(selectUserById(userId || '')) as IUser;
  const initialValues = useSelector(selectUserFormInitialValues(userId || ''));
  const {
    openModal: openReassignModal,
    closeModal: closeReassignModal,
    Modal: ReassignModal,
  } = useModal<IReassignUserModalProps>();

  const dispatchSubmit = React.useCallback(
    (payload: IAddUserActionPayload | IEditUserActionPayload) => {
      switch (flowId) {
        case URL_VARS.NEW:
          return dispatch(addUserRequest(payload as IAddUserActionPayload));
        case URL_VARS.EDIT:
          return dispatch(editUserRequest(payload as IEditUserActionPayload));
        default:
          return null;
      }
    },
    [dispatch, flowId],
  );

  const handleSubmit = React.useCallback(
    (user: Partial<IUser>, { resetForm, setSubmitting, setStatus, setErrors }: FormikHelpers<Partial<IUser>>) => {
      if (!validationSchema.isValidSync(user)) {
        return;
      }

      return new Promise((resolve, reject) => {
        const { distributor_org_ids, ...rest } = user;
        const candidate = {
          ...(rest[ADMIN_TYPE] === DISTRIBUTOR ? { distributor_org_ids } : { distributor_org_ids: [] }),
          ...rest,
        };
        dispatchSubmit({ user: candidate, resolve, reject });
      })
        .then(() => {
          resetForm();
          setStatus(FormStatusEnum.Success);
          notification.success(NotificationListEnum.Success, { content: UserSuccessToastMessages[flowId] });
          history.push(routes.users.root);
        })
        .catch((e: IApiError) => {
          setErrors({ api: e?.message } as FormikErrors<Partial<IUser>>);
          setStatus(FormStatusEnum.Error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [flowId, dispatchSubmit, history],
  );

  const handleDeleteRequest = React.useCallback(
    (id: string, newOwnerId?: string) =>
      new Promise((resolve, reject) => {
        dispatch(deleteUserRequest({ userId: id, newOwnerId, resolve, reject }));
      })
        .then(() => notification.success(NotificationListEnum.Success, { content: 'User was successfully deleted' }))
        .then(() => {
          history.push(routes.users.root);
        }),
    [dispatch, history],
  );

  const handleDeleteUser = React.useCallback(
    (cb?: () => void) => {
      return userId
        ? handleDeleteRequest(userId)
            .catch((e: IErrorResponse) => {
              const { error_code } = e;

              if (error_code === USER_REASSIGN_REQUIRED_ERROR_CODE) {
                openReassignModal({ id: userId, onDelete: handleDeleteRequest });
              } else {
                handleApiError(`Something bad happened. The user wasn't deleted.`);
              }
            })
            .finally(cb)
        : Promise.reject();
    },
    [openReassignModal, userId, deleteUserRequest],
  );

  const reassignUserModal = React.useMemo(
    () => (
      <ReassignModal className="common-modal">
        {({ id, onDelete }) => <ReassignUserModal onClose={closeReassignModal} onDelete={onDelete} id={id} />}
      </ReassignModal>
    ),
    [ReassignModal, closeReassignModal],
  );

  React.useEffect(() => {
    return () => {
      dispatch(clearUserDetails());
    };
  }, [dispatch]);

  const isInitialValid = React.useCallback(() => validationSchema.isValidSync(initialValues), [initialValues]);

  const form = useFormik<Partial<IUser>>({
    initialStatus: FormStatusEnum.Start,
    initialValues,
    isInitialValid,
    validateOnMount: true,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const handleFieldChange = React.useCallback(
    (name: keyof IUser, value: string | number | undefined | string[] | boolean) => {
      form.setFieldValue(name, value);
      dispatch(addUserDetails({ [name]: value }));
    },
    [dispatch, form.setFieldValue],
  );

  const handleCancel = React.useCallback(() => {
    if (originalUser) {
      dispatch(addUserDetails(originalUser as IUser));
      form.setValues(initialValues);
    }
  }, [dispatch, originalUser, initialValues, form.setValues]);

  const mode = React.useMemo(() => {
    switch (flowId) {
      case URL_VARS.NEW:
        return MaintenanceFormStateEnum.Adding;
      case URL_VARS.EDIT:
        return MaintenanceFormStateEnum.Editing;
      case URL_VARS.VIEW:
        return MaintenanceFormStateEnum.Reading;
      default:
        return MaintenanceFormStateEnum.Reading;
    }
  }, [flowId]);

  const isValueChanged = React.useMemo(() => {
    switch (true) {
      case mode === MaintenanceFormStateEnum.Adding:
        /* Check if user object candidate has other properties except the integration object
         * If they are missing, the form hasn't changed and the Unsaved Changes modal should not be called
         */

        return isEmpty(userDetails) ? false : !isObjectsEqual(USER_INITIAL_VALUES, userDetails as IUser);
      case mode === MaintenanceFormStateEnum.Editing:
        if (!originalUser || !userDetails) {
          return true;
        }

        return !isObjectsEqual(originalUser, userDetails);
      default:
        return false;
    }
  }, [originalUser, userDetails, mode]);

  const requiredFields = React.useMemo(
    () => getRequiredFields(validationSchema, getUserFormRequiredFieldsByCountryCode(form?.values[COUNTRY])),
    [validationSchema, form?.values[COUNTRY]],
  );

  React.useEffect(() => {
    if (mode !== MaintenanceFormStateEnum.Adding) {
      if (userDetails?.uid !== initialValues.uid && !isEmpty(originalUser)) {
        dispatch(addUserDetails(originalUser));
      }
    } else if (isEmpty(userDetails)) {
      dispatch(addUserDetails(USER_INITIAL_VALUES));
    }
  }, [mode, originalUser, initialValues, userDetails, dispatch]);

  return (
    <div className={cn(styles.container)}>
      <MaintenanceForm
        root={routes.users.root}
        form={form}
        assetName={USER_LABEL}
        mode={mode}
        onDelete={handleDeleteUser}
        onCancel={handleCancel}
        onDiscard={() => history.push(routes.users.root)}
        onSave={form.isValid ? handleSubmit : undefined}
        isValueChanged={isValueChanged}
        controlsClassName={styles.controls}
      >
        {(props: IMaintenanceFormOutputData) => {
          return (
            <UserForm
              {...props}
              onChange={handleFieldChange}
              form={form}
              onResetPassword={onResetPassword}
              requiredFields={requiredFields}
            />
          );
        }}
      </MaintenanceForm>
      {reassignUserModal}
    </div>
  );
};

export default EditUserContainer;
