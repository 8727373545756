import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MAX_CAMPAIGN_ITEMS_PER_PAGE } from '../../constants/campaigns';
import { MAX_INVENTORY_ITEMS_PER_PAGE } from '../../constants/inventories';
import { NO_RESULTS_FOUND_MESSAGE } from '../../constants/shell';
import useSearchFilter from '../../hooks/useSearchFilter/useSearchFilter';
import useWindowSize from '../../hooks/useWindowSize';
import { useInventoryItems } from '../../service/InventoryService';
import { ICampaignItem } from '../../types/campaigns';
import { IInventoryItem, InventoryFilterTypesEnum } from '../../types/inventories';
import { InventorySidebarModeEnum, InventorySidebarTypeEnum, UISizeEnum } from '../../types/shell';
import { getSortedInventoryItems } from '../../utils/inventories';
import { AsyncButton, InputLabel } from '../forms';
import { NoResultsPlaceholder } from '../index';
import InventorySidebar from '../InventorySidebar/InventorySidebar';

import styles from './AvailableItems.module.scss';

interface IPaginationInfo {
  page: number;
}

const INITIAL_PAGINATION_INFO: IPaginationInfo = {
  page: 1,
};

interface IProps {
  onSelect: (item: IInventoryItem | ICampaignItem) => void;
  selectedFCID?: string;
  maxPageSize?: number;
  types?: InventoryFilterTypesEnum[];
  showCount?: boolean;
  label?: string;
  className?: string;
  containerClassName?: string;
  isDisabledFn?: (item: IInventoryItem | ICampaignItem) => boolean;
}

const AvailableItems: React.FC<IProps> = ({
  onSelect,
  types,
  maxPageSize,
  selectedFCID,
  showCount = true,
  label,
  className,
  containerClassName,
  isDisabledFn,
}) => {
  const { mobile } = useWindowSize();

  const [renderItems, setRenderItems] = useState<(IInventoryItem | ICampaignItem)[]>([]);

  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo>(INITIAL_PAGINATION_INFO);

  const { SearchInput, query } = useSearchFilter(
    [],
    [],
    { className: styles.searchForm, placeholder: 'Lookup items', hint: 'Look up specific items by their name or SKU.' },
    () => {
      setRenderItems([]);
    },
  );

  const { data: inventoryItems, isFetching } = useInventoryItems({
    types,
    page: paginationInfo.page,
    page_size: maxPageSize || MAX_INVENTORY_ITEMS_PER_PAGE,
    search_query: query,
    ...(selectedFCID ? { fulfillment_center_id: selectedFCID } : {}),
  });

  const isFinal = useMemo(
    () => inventoryItems?.length && inventoryItems?.length < MAX_CAMPAIGN_ITEMS_PER_PAGE,
    [inventoryItems],
  );

  const sortedInventoryItems = React.useMemo(() => {
    return getSortedInventoryItems(renderItems);
  }, [renderItems]);

  const handlePageChange = useCallback(() => {
    setPaginationInfo((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
    }));
  }, []);

  useEffect(() => {
    if (query) {
      setRenderItems([]);
      setPaginationInfo(INITIAL_PAGINATION_INFO);
    }
  }, [query]);

  useEffect(() => {
    if (inventoryItems) {
      setRenderItems((prevState) => [...prevState, ...inventoryItems]);
    }
  }, [inventoryItems]);

  return (
    <div className={cn(containerClassName, styles.availableItemsContent)}>
      <InputLabel value={label || 'Available Items'} />
      {!mobile && SearchInput}
      <div className={cn(className, styles.availableItemsWrapper)}>
        {sortedInventoryItems && sortedInventoryItems.length ? (
          <InventorySidebar
            type={InventorySidebarTypeEnum.InventoryItem}
            className={styles.inventoryItemsWrapper}
            listClassName={styles.inventoryItemsList}
            isDisabledFn={isDisabledFn}
            // inventoryItems is used here to show all available items
            items={sortedInventoryItems}
            onSelect={onSelect}
            mode={InventorySidebarModeEnum.Select}
            showBorder={false}
            showCount={showCount}
          />
        ) : (
          <NoResultsPlaceholder label={NO_RESULTS_FOUND_MESSAGE} />
        )}
        {!isFinal && (
          <AsyncButton
            className={cn(styles.loadButton, { [styles.loading]: isFetching })}
            loaderClassName={styles.loader}
            isLoading={isFetching}
            size={mobile ? UISizeEnum.Small : UISizeEnum.Normal}
            onClick={handlePageChange}
            outlined
            title={isFetching && mobile ? void 0 : 'Load More'}
          />
        )}
      </div>
    </div>
  );
};

export default AvailableItems;
