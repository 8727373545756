import { call, put, race, take, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { endpoints, queryParams } from '../../constants/routing';
import { IFetchCampaignSummariesResponsePayload } from '../../types/campaigns';
import { IResponse } from '../../types/http';
import { IReduxAction } from '../../types/redux';
import {
  IDownloadReportsCSVRequestPayload,
  IFetchReportByIdRequestPayload,
  IFetchReportsRequestPayload,
  IFetchReportsResponse,
  IPopulateReportsCampaignSummariesRequestPayload,
  ReportsRequestSearchTypes,
  ReportTypesEnum,
} from '../../types/reports';
import { IApiError, IDownloadCSVSuccessPayload } from '../../types/shell';
import { getQuery } from '../../utils/request';
import * as CampaignActions from '../actions/campaigns';
import * as ReportsActions from '../actions/reports';
import { AuthRequest } from './helpers';

function* deliveryReportsWorkerSaga(action: IReduxAction<IFetchReportsRequestPayload>) {
  try {
    const { isOrgSearch, isDeptSearch, body, type = ReportTypesEnum.Regular } = action.payload || {};

    const endpoint = `${apiUrl}${endpoints.reports}`;
    const qs = {
      ...(isOrgSearch ? getQuery(isOrgSearch, queryParams.orgSearch) : {}),
      ...(isDeptSearch ? getQuery(isDeptSearch, queryParams.deptSearch) : {}),
      ...(type ? getQuery(type, queryParams.type) : {}),
    };

    const response: IResponse<IFetchReportsResponse> = yield call(AuthRequest, {
      queryParams: qs,
      endpoint,
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      yield put(ReportsActions.fetchReportsFailure(response.body));
    } else {
      yield put(ReportsActions.fetchReportsSuccess({ type, ...response.body }));
    }
  } catch (error) {
    yield put(ReportsActions.fetchReportsFailure(error));
  }
}

function* deliveryReportByIdWorkerSaga(action: IReduxAction<IFetchReportByIdRequestPayload>): Generator<any, any, any> {
  const { reportId, type, resolve, reject } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.report}`;
    const qs = {
      ...(reportId ? getQuery(reportId, queryParams.uid) : {}),
      ...(type ? getQuery(type, queryParams.type) : {}),
    };

    const response = yield call<any>(AuthRequest, {
      queryParams: qs,
      endpoint,
      method: 'GET',
    });

    if (!response.ok) {
      if (reject) {
        reject(response.body);
      }
      yield put(ReportsActions.fetchReportByIdFailure(response.body));
    } else {
      if (resolve) {
        resolve(response.body);
      }
      yield put(ReportsActions.fetchReportByIdSuccess({ ...response.body, type }));
    }
  } catch (error) {
    if (reject) {
      reject(error);
    }
    yield put(ReportsActions.fetchReportByIdFailure(error));
  }
}

function* populateReportsCampaignSummariesWorkerSaga(
  action: IReduxAction<IPopulateReportsCampaignSummariesRequestPayload>,
): Generator<any, any, any> {
  try {
    const { type, reportId, campaign_ids } = action.payload!;

    yield put(CampaignActions.fetchCampaignSummariesRequest({ campaign_ids }));

    const [successAction] = yield race([
      take(CampaignActions.FETCH_CAMPAIGN_SUMMARIES_SUCCESS),
      take(CampaignActions.FETCH_CAMPAIGN_SUMMARIES_FAILURE),
    ]);

    if (successAction) {
      yield put(
        ReportsActions.populateReportCampaignSummariesSuccess({
          ...((successAction as IReduxAction<IFetchCampaignSummariesResponsePayload>)
            .payload as IFetchCampaignSummariesResponsePayload),
          type,
          reportId,
        }),
      );
    } else {
      yield put(ReportsActions.populateReportCampaignSummariesFailure(successAction.payload as IApiError));
    }
  } catch (error) {
    yield put(ReportsActions.populateReportCampaignSummariesFailure(error));
  }
}

export function* downloadReportsCSVWorkerSaga(action: IReduxAction<IDownloadReportsCSVRequestPayload>) {
  const {
    isOrgSearch,
    isDeptSearch,
    type = ReportTypesEnum.Regular,
    department_ids,
    range,
    resolve,
    reject,
  } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.downloadReportsCSV}`;
    const body = JSON.stringify({
      ...(isOrgSearch ? getQuery(ReportsRequestSearchTypes.orgSearch, queryParams.searchType) : {}),
      ...(isDeptSearch ? getQuery(ReportsRequestSearchTypes.deptSearch, queryParams.searchType) : {}),
      ...(type ? getQuery(type, queryParams.engagementType) : {}),
      ...(department_ids && department_ids.length ? { department_ids } : {}),
      range,
    });

    const response: IResponse<IDownloadCSVSuccessPayload> = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      body,
    });

    if (!response.ok) {
      if (reject) {
        yield call(reject, response.body);
      }
      yield put(ReportsActions.downloadReportsCSVFailure(response.body));
    } else {
      yield put(ReportsActions.downloadReportsCSVSuccess(response.body));
      if (resolve) {
        yield call(resolve, response.body);
      }
    }
  } catch (e) {
    if (reject) {
      yield call(reject, e);
    }
    yield put(ReportsActions.downloadReportsCSVFailure(e));
  }
}

const sagas = {
  *watchDeliveryReportsRequest() {
    yield takeLatest(ReportsActions.FETCH_REPORTS_REQUEST, deliveryReportsWorkerSaga);
  },
  *watchDeliveryReportByIdRequest() {
    yield takeLatest(ReportsActions.FETCH_REPORT_BY_ID_REQUEST, deliveryReportByIdWorkerSaga);
  },
  *watchFetchReportsCampaignSummariesRequest() {
    yield takeLatest(
      ReportsActions.POPULATE_REPORT_CAMPAIGN_SUMMARIES_REQUEST,
      populateReportsCampaignSummariesWorkerSaga,
    );
  },
  *watchDownloadReportsCSVRequest() {
    yield takeLatest(ReportsActions.DOWNLOAD_REPORTS_CSV_REQUEST, downloadReportsCSVWorkerSaga);
  },
};

export default sagas;
