import cn from 'classnames';
import React from 'react';
import { useCSVDownloader } from 'react-papaparse';

import { ReactComponent as CloseIcon } from '../../assets/images/icon-close-cross.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning-red-outline.svg';

import { ActionButton } from '..';
import { TOneLinkRecipient } from '../../types/oneLink';
import styles from './CsvUploadResultMessages.module.scss';

interface ICsvUploadErrorProps {
  fileName: string;
  failedRows?: TOneLinkRecipient[];
  successRows?: TOneLinkRecipient[];
  onClose?: () => void;
}

const CsvUploadError: React.FC<ICsvUploadErrorProps> = ({ failedRows, successRows, onClose, fileName }) => {
  const { CSVDownloader, Type } = useCSVDownloader();

  const failedCount = failedRows?.length || 0;
  const successCount = successRows?.length || 0;

  const isInvalidCsv = failedCount === 0;

  return (
    <div className={cn(styles.message, styles.failed)}>
      {onClose && <CloseIcon className={cn(styles.closeBtn, styles.red)} onClick={onClose} />}
      <div className={styles.failedInfo}>
        <WarningIcon />
        <p>
          <span className={styles.errorText}>
            {isInvalidCsv ? 'This CSV is invalid' : `${failedCount} rows have errors.`}
          </span>{' '}
          {!!successCount && `${successCount} successfully added.`} <br /> Please download, make the changes and upload
          again.
        </p>
      </div>
      <CSVDownloader
        type={Type.Link}
        filename={fileName}
        bom={true}
        config={{
          delimiter: ',',
        }}
        data={failedRows}
        className={styles.downloadBtnContainer}
      >
        <ActionButton className={styles.downloadBtn}>Download file</ActionButton>
      </CSVDownloader>
    </div>
  );
};

export default CsvUploadError;
