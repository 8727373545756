import * as React from 'react';
import { FC, Fragment } from 'react';

import { CONTACT_ADMIN_MESSAGE } from '../../../constants/shell';

interface IProps {
  showContactAdminMessage?: boolean;
  children?: React.ReactNode;
}

const OutOfMoneyToast: FC<IProps> = ({ children, showContactAdminMessage }) => {
  return (
    <Fragment>
      <div>{children}</div>
      {showContactAdminMessage && <div>{CONTACT_ADMIN_MESSAGE}</div>}
    </Fragment>
  );
};

export default OutOfMoneyToast;
