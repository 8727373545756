import cn from 'classnames';
import React from 'react';

import styles from './InfoMessage.module.scss';

interface IProps {
  icon: React.ReactNode;
  text: string;
  className?: string;
}

const InfoMessage: React.FC<IProps> = ({ icon, text, className }) => {
  return (
    <div className={styles.wrapper}>
      {icon}
      <p className={cn(className, styles.text)}>{text}</p>
    </div>
  );
};

export default InfoMessage;
