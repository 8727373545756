import cn from 'classnames';
import * as React from 'react';

import { ISendsDashboardSummary } from '../../../types/dashboard';

import styles from './GraphListItem.module.scss';

interface IProps {
  className?: string;
  label: string;
  item: ISendsDashboardSummary;
  onPointerEnter?: () => void;
  onPointerLeave?: () => void;
}

const GraphListItem = ({
  className,
  label,
  item: { percentage, automated_percentage, automated_count, total_count },
  onPointerEnter,
  onPointerLeave,
}: IProps) => {
  const getPercent = React.useCallback((value: number) => `${value}%`, []);

  return (
    <div className={cn(styles.container, className)} onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave}>
      <div className={styles.description}>
        <div className={styles.label}>{`${label} Sends`}</div>
        <div className={styles.totalCount}>
          {total_count} Send{total_count === 1 ? '' : 's'}
        </div>
        <div className={styles.automatedCount}>
          {automated_count} of them Automated ({automated_percentage}%)
        </div>
      </div>
      <div className={styles.graph}>
        <div
          className={styles.totalPercentage}
          style={{ width: getPercent(percentage) }}
          // This `div` represents parent percentage.
          // But it has a title `Manual sends`
          // because manual sends percentage is represented
          // with the region, which is not covered with this div's children
          title="Manual sends"
        >
          <div
            className={styles.automatedPercentage}
            title="Automated sends"
            style={{ width: getPercent(automated_percentage) }}
          />
        </div>
        <div className={styles.percentage}>{getPercent(percentage)}</div>
      </div>
    </div>
  );
};

export default GraphListItem;
