import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as NotFoundIcon } from '../../assets/images/404.svg';
import { ReactComponent as CancelIcon } from '../../assets/images/icon-cancel-button.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/icon-delete-button.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/icon-search.svg';
import { DeleteItemModal, EngagementCard, HelpTooltip } from '../../components';
import { ActionButton, Input, Toggle } from '../../components/forms';
import { DIGITAL_STATUS, PHYSICAL_STATUS } from '../../constants/reports';
import { routes } from '../../constants/routing';
import {
  DIGITAL_NON_CANCELING_STATUSES,
  ENGAGEMENT_LABEL,
  PHYSICAL_NON_CANCELING_STATUSES,
} from '../../constants/tools';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import useModal from '../../hooks/useModal';
import useWindowSize from '../../hooks/useWindowSize';
import {
  cancelEngagementByOrderIdRequest,
  clearEngagement,
  deleteEngagementByOrderIdRequest,
  fetchEngagementByOrderIdRequest,
} from '../../store/actions/tools';
import { selectCanCancelEngagement, selectCanDeleteEngagement, selectEngagement } from '../../store/selectors/tools';
import { IReport, ReportTypesEnum } from '../../types/reports';
import { IApiError, NotificationListEnum, PositionStylesEnum, UISizeEnum } from '../../types/shell';
import notification from '../../utils/notification';
import { getReportStatusAndDate, isDigitalReport, isValidScheduledEngagement } from '../../utils/reports';
import { handleApiError } from '../../utils/ui';

import styles from './EngagementsManager.module.scss';

const EngagementsManager: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { mobile } = useWindowSize();
  const { setHeader } = useHeaderDispatch();
  const { openModal: openDeleteModal, closeModal: closeDeleteModal, Modal: DeleteModal } = useModal();

  const [engagementNotFound, setEngagementNotFound] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [type, setType] = useState<ReportTypesEnum>(ReportTypesEnum.Regular);

  const engagement = useSelector(selectEngagement);
  const canDeleteEngagement = useSelector(selectCanDeleteEngagement);
  const canCancelEngagement = useSelector(selectCanCancelEngagement);

  const isScheduledOn = React.useMemo(() => type === ReportTypesEnum.Scheduled, [type]);

  const handleOnQueryChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);
  }, []);

  const handleFetchEngagementApiError = React.useCallback(
    (callBack: any) => (error: IApiError) => {
      if (error.status === 500) {
        setEngagementNotFound(true);
        return;
      }

      setEngagementNotFound(false);
      callBack(error);
    },
    [],
  );

  const fetchEngagement = React.useCallback(
    (orderId: string) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchEngagementByOrderIdRequest({ type, orderId, resolve, reject }));
      })
        .then(() => {
          setEngagementNotFound(false);
        })
        .catch(handleFetchEngagementApiError(handleApiError(`Something bad happened. Send wasn't found.`)));
    },
    [dispatch, handleFetchEngagementApiError, type],
  );

  const searchEngagement = React.useCallback(() => {
    if (!query) {
      return;
    }

    fetchEngagement(query);
  }, [query, fetchEngagement]);

  const handleInputKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        searchEngagement();
      }
    },
    [searchEngagement],
  );

  const isNonCancelingEngagementStatus = React.useCallback(
    (status: string) => {
      if (!engagement) {
        return true;
      }

      const isDigital = isDigitalReport(engagement);
      const nonCancelingStatusList = isDigital ? DIGITAL_NON_CANCELING_STATUSES : PHYSICAL_NON_CANCELING_STATUSES;
      const commonStatusList = isDigital ? DIGITAL_STATUS : PHYSICAL_STATUS;

      return nonCancelingStatusList.some((el) => commonStatusList[el]?.label === status);
    },
    [engagement],
  );

  const isCurrentEngagementScheduled = React.useMemo(
    () => (engagement ? isValidScheduledEngagement(engagement) : false),
    [engagement],
  );

  const postProcessDeletion = React.useCallback(() => {
    setQuery('');
    if (isScheduledOn) {
      setType(ReportTypesEnum.Regular);
    }
    if (engagementNotFound) {
      setEngagementNotFound(false);
    }
  }, [engagementNotFound, isScheduledOn, dispatch]);

  const handleCancelEngagement = React.useCallback(
    (currentEngagement: IReport) => {
      const { order_id: orderId } = currentEngagement;
      const { label: status } = getReportStatusAndDate(currentEngagement);

      if (isNonCancelingEngagementStatus(status)) {
        notification.error(NotificationListEnum.Error, { content: 'Send in this status can not be canceled' });
        return;
      }

      return new Promise((resolve, reject) => {
        dispatch(
          cancelEngagementByOrderIdRequest({
            type: isCurrentEngagementScheduled ? ReportTypesEnum.Scheduled : ReportTypesEnum.Regular,
            orderId,
            resolve,
            reject,
          }),
        );
      })
        .then(() => {
          if (isCurrentEngagementScheduled) {
            postProcessDeletion();
          } else {
            fetchEngagement(orderId);
          }

          notification.success(NotificationListEnum.Success, { content: 'This send was canceled!' });
        })
        .catch(handleApiError(`Something bad happened. Send wasn't canceled.`));
    },
    [dispatch, fetchEngagement, isNonCancelingEngagementStatus, isCurrentEngagementScheduled, postProcessDeletion],
  );

  const handleDeleteEngagement = React.useCallback(
    (orderId: string) => {
      return new Promise((resolve, reject) => {
        dispatch(deleteEngagementByOrderIdRequest({ type, orderId, resolve, reject }));
      })
        .then(postProcessDeletion)
        .then(() => {
          notification.success(NotificationListEnum.Success, { content: 'The engagement was successfully deleted' });
        })
        .catch(handleApiError(`Something bad happened. Send wasn't deleted.`))
        .finally(() => {
          closeDeleteModal();
        });
    },
    [dispatch, closeDeleteModal, type, postProcessDeletion],
  );

  const tooltipText = React.useMemo(() => {
    switch (true) {
      case canCancelEngagement && !canDeleteEngagement:
        return isCurrentEngagementScheduled
          ? 'You can only cancel the scheduled send. It also will be deleted from delivery reports.'
          : 'You can only cancel the send, but it will be stored in delivery reports';
      case !canCancelEngagement && canDeleteEngagement:
        return 'You can only delete this send';
      case canCancelEngagement && canDeleteEngagement:
        return (
          <React.Fragment>
            <p>'Cancel' - stops the initiated send, but keeps the record in delivery reports.</p>
            <br />
            <p>'Delete' - deletes the initiated send and removes the record from delivery reports</p>
          </React.Fragment>
        );
      default:
        return '';
    }
  }, [canCancelEngagement, canDeleteEngagement, isCurrentEngagementScheduled]);

  const tooltipPosition = React.useMemo(() => (mobile ? PositionStylesEnum.Top : PositionStylesEnum.Right), [mobile]);

  const controls = React.useMemo(() => {
    if (!(canCancelEngagement || canDeleteEngagement) || !engagement) {
      return null;
    }

    const { order_id: orderId } = engagement;

    return [
      <HelpTooltip
        key="tooltip"
        id="controls-info"
        place={tooltipPosition}
        offset={{ right: mobile ? 0 : 10 }}
        className={styles.controlsInfo}
        data-border
        data-tip
      >
        {tooltipText}
      </HelpTooltip>,
      canCancelEngagement && (
        <ActionButton
          key="cancel-action"
          className={cn(styles.cancel, { [styles.mobile]: mobile })}
          size={UISizeEnum.Small}
          onClick={() => handleCancelEngagement(engagement)}
        >
          {mobile ? (
            <CancelIcon />
          ) : (
            <React.Fragment>
              Cancel
              <span className={styles.optional}> send</span>
            </React.Fragment>
          )}
        </ActionButton>
      ),
      canDeleteEngagement && (
        <ActionButton
          key="delete-action"
          className={cn(styles.delete, { [styles.mobile]: mobile })}
          size={UISizeEnum.Small}
          onClick={() => openDeleteModal(orderId)}
        >
          {mobile ? (
            <DeleteIcon />
          ) : (
            <React.Fragment>
              Delete
              <span className={styles.optional}> send</span>
            </React.Fragment>
          )}
        </ActionButton>
      ),
    ];
  }, [
    mobile,
    canCancelEngagement,
    canDeleteEngagement,
    tooltipText,
    tooltipPosition,
    engagement,
    handleCancelEngagement,
    openDeleteModal,
  ]);

  const renderEngagement = React.useMemo(() => {
    if (!engagement) {
      return;
    }
    return <EngagementCard engagement={engagement} controls={controls} />;
  }, [engagement, controls]);

  const deleteItemModal = React.useMemo(
    () => (
      <DeleteModal className="common-modal">
        {(orderId: string) => {
          return (
            <DeleteItemModal
              assetName={ENGAGEMENT_LABEL}
              onDelete={() => handleDeleteEngagement(orderId)}
              onClose={closeDeleteModal}
            />
          );
        }}
      </DeleteModal>
    ),
    [closeDeleteModal, DeleteModal, handleDeleteEngagement],
  );

  React.useEffect(() => {
    if (typeof setHeader === 'function') {
      setHeader({ title: 'Send Manager', action: () => history.push(routes.dashboard) });
    }

    return () => {
      dispatch(clearEngagement());
    };
  }, []);

  const handleTypeChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setType(e.target.checked ? ReportTypesEnum.Scheduled : ReportTypesEnum.Regular);
  }, []);

  return (
    <div className={cn(styles.container)}>
      <div className={styles.header}>
        <Input
          value={query}
          icon={<SearchIcon />}
          onChange={handleOnQueryChange}
          onKeyDown={handleInputKeyDown}
          className={styles.search}
          placeholder="Search by Order ID"
        />
        <div className={styles.controls}>
          <Toggle
            tooltipProps={{ place: tooltipPosition, offset: { right: mobile ? 0 : 10 } }}
            className={styles.toggle}
            infoClassName={styles.toggleLabel}
            checked={isScheduledOn}
            onChange={handleTypeChange}
            name="type"
            hint="If this option is ‘ON’ only scheduled send will be shown."
          >
            Scheduled
            <br />
            Send
          </Toggle>
          <ActionButton title="Search" onClick={searchEngagement} className={styles.submitBtn} />
        </div>
      </div>
      <div className={styles.listWrapper}>
        <div className={styles.list}>
          {engagement && renderEngagement}
          {engagementNotFound && (
            <React.Fragment>
              <div className={styles.noEngagement}>
                <span>No sends found</span>
                <NotFoundIcon className={styles.image} />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      {deleteItemModal}
    </div>
  );
};

export default EngagementsManager;
