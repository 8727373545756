import { applyMiddleware, compose, createStore, Store } from 'redux';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import packageInfo from '../../package.json';
import { migrations } from './migrations';
import rootReducer, { IAppState } from './reducers';
import rootSaga from './sagas';
import { deepMerge } from './stateReconciler';
import rootTransforms from './transforms';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const persistConfig = {
  key: 'root',
  // If the version inside package.json looks like '1.5.6', here it's presented as 156
  version: parseInt(packageInfo.version.replace(/\./g, ''), 10),
  storage,
  blacklist: ['firebase', 'auth', 'reports', 'integrations', 'oneLink'],
  transforms: rootTransforms,
  stateReconciler: deepMerge,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer<IAppState>(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const store: Store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)));

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
