import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { BUSINESS_REASON_ID } from '../../../constants/reasons';
import { NO_CURRENCY } from '../../../constants/shell';
import { selectBusinessReasonById } from '../../../store/selectors/reasons';
import { IOneLink } from '../../../types/oneLink';
import { IReport } from '../../../types/reports';
import { isValidDate } from '../../../utils/date';
import { calculateDaysLeft, getReportStatusAndDate, isAwaitingAddress } from '../../../utils/reports';
import { Card } from '../../index';
import SendDate from '../SendDate/SendDate';
import SendIcons from '../SendIcons/SendIcons';

import styles from './DetailsCard.module.scss';

interface IProps {
  className?: string;
  controls?: React.ReactNode;
  instance: IReport | IOneLink;
  isOneLink?: boolean;
}

const DetailsCard = ({ className, controls, instance, isOneLink }: IProps) => {
  const { name: reason } = useSelector(selectBusinessReasonById(instance?.[BUSINESS_REASON_ID])) || {};

  const { date, dateLabel, createdAtDate, delayedShippingExpirationDate } = React.useMemo(
    () =>
      isOneLink
        ? { date: undefined, dateLabel: undefined, createdAtDate: undefined, delayedShippingExpirationDate: undefined }
        : getReportStatusAndDate(instance as IReport),
    [instance, isOneLink],
  );
  const { internal_notes: notes } = React.useMemo(() => instance, [instance]);
  const { recipients_count } = React.useMemo(() => instance as IOneLink, [instance]);

  const daysLeftMessage = React.useMemo(() => {
    if (!isOneLink) {
      return calculateDaysLeft((instance as IReport)?.delayed_shipping_expiration);
    }
    return undefined;
  }, [instance, isOneLink]);

  const isAwaitingStatus = React.useMemo(() => isAwaitingAddress(instance as IReport), [instance]);

  const isExpirationDateValid = React.useMemo(
    () => delayedShippingExpirationDate && isValidDate(new Date(delayedShippingExpirationDate)),
    [delayedShippingExpirationDate],
  );

  return (
    <Card
      className={cn(styles.container, className)}
      topClassName={styles.head}
      top={
        <React.Fragment>
          <SendIcons className={cn(styles.icons, { [styles.oneLink]: isOneLink })} instance={instance} />
          <div className={styles.dates}>
            {createdAtDate && <SendDate label="Created Date:" value={createdAtDate} />}
            {date && dateLabel && <SendDate label={dateLabel} value={date} />}
          </div>
        </React.Fragment>
      }
      middleClassName={styles.detailInfo}
      middle={
        <React.Fragment>
          <div className={styles.option}>Business Reason:</div>
          <div className={cn(styles.value, styles.reason)}>{reason ? reason : NO_CURRENCY.label}</div>

          <div className={styles.option}>Internal Notes:</div>
          <div className={cn(styles.value, styles.notes)}>{notes ? notes : NO_CURRENCY.label}</div>
          {isAwaitingStatus && isExpirationDateValid && (
            <React.Fragment>
              <div className={styles.option}>Expiration Date:</div>
              <div className={cn(styles.value, styles.expDate)}>
                {delayedShippingExpirationDate}
                <span className={styles.daysLeft}> ({daysLeftMessage})</span>
              </div>
            </React.Fragment>
          )}
          {recipients_count ? (
            <>
              <div className={styles.option}>2FA Recipients:</div>
              <div className={cn(styles.value, styles.notes)}>{recipients_count.toLocaleString()}</div>
            </>
          ) : null}

          {/* This code was commented in EX-1245
           *  It will be reworked in the future
           */}
          {/* <div className={styles.option}>Send Pack:</div>
              <div className={cn(styles.value, styles.campaign)}>
                {campaign ? (
                  <React.Fragment>
                    <span className={styles.name}>{campaign.name}</span>
                    <HelpTooltip id={`${campaign.box_id}-tooltip`} className={styles.campaignTooltip}>
                      <CampaignPreview campaign={campaign} />
                    </HelpTooltip>
                  </React.Fragment>
                ) : (
                  'Awaiting selection'
                )}
              </div> */}
        </React.Fragment>
      }
      bottomClassName={styles.controls}
      bottom={controls}
    />
  );
};

export default DetailsCard;
