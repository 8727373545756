import cn from 'classnames';
import * as React from 'react';
import { BUTTON_BUTTON } from '../../../../types/forms';

import styles from './TextIconButton.module.scss';

export interface IProps {
  className?: string;
  icon?: React.ReactNode;
  title?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  trigger?: boolean;
  disabled?: boolean;
  hint?: string;
}
export default ({ className, disabled, hint, icon, title, onClick, trigger, type = BUTTON_BUTTON }: IProps) => {
  return (
    <button
      {...(hint ? { title: hint } : {})}
      disabled={disabled}
      type={type}
      className={cn(className, styles.megaButton)}
      onClick={onClick}
      title={trigger ? title : ''}
      {...(trigger ? { style: { backgroundImage: `url(${icon})` } } : {})}
    >
      {!trigger && title}
    </button>
  );
};
