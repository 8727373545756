import cn from 'classnames';
import * as React from 'react';
import { animated, useTransition } from 'react-spring';
import { BUTTON_BUTTON } from '../../types/forms';

import styles from './Overlay.module.scss';

interface IProps {
  className?: string;
  active: boolean;
  onClick?: (...args: any[]) => void;
}

const Overlay = ({ className, active, onClick }: IProps) => {
  const transitions = useTransition(active, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  return (
    <React.Fragment>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.button
              type={BUTTON_BUTTON}
              key={key}
              style={props}
              className={cn(styles.overlay, className, { [styles.nonClickable]: !onClick })}
              onClick={onClick}
            />
          ),
      )}
    </React.Fragment>
  );
};

export default Overlay;
