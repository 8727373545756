import * as React from 'react';
import notification from '../utils/notification';

import useDynamicRoot from './useDynamicRoot';

interface IOptions {
  successDuration?: number;
  toastDuration?: number;
  message?: string;
}

export default function useCopyClipboard({
  successDuration,
  toastDuration,
  message = 'Copied to clipboard!',
}: IOptions) {
  const [isCopied, setIsCopied] = React.useState(false);
  const messageRef = React.useRef<{ message: string }>({ message });
  const elRef = React.useRef<HTMLTextAreaElement | null>(null);
  const root = useDynamicRoot();

  const copy = (str: string) => {
    if (root) {
      elRef.current = document.createElement('textarea');
      const el = elRef.current;
      el.value = str;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      root.appendChild(el);
      const selection = document.getSelection();
      const selected = selection && selection?.rangeCount > 0 ? selection.getRangeAt(0) : false;
      el.select();
      const success = document.execCommand('copy');
      root.removeChild(el);
      if (selected) {
        selection?.removeAllRanges();
        selection?.addRange(selected);
      }
      return success;
    }
    return false;
  };

  React.useEffect(() => {
    if (isCopied && toastDuration) {
      const { message: toastMessage } = messageRef.current;

      notification.info(void 0, {
        content: toastMessage,
        options: {
          autoClose: toastDuration,
          pauseOnHover: false,
          onClose: () => (messageRef.current = { message }),
        },
      });
    }
  }, [isCopied, toastDuration]);

  React.useEffect(() => {
    if (isCopied && successDuration) {
      const id = setTimeout(() => {
        setIsCopied(false);
      }, successDuration);

      return () => {
        clearTimeout(id);
      };
    }
  }, [isCopied, successDuration]);

  return {
    isCopied,
    setCopied: (text: string, toastMessage?: string) => {
      if (!isCopied && text) {
        if (toastMessage) {
          messageRef.current = { message: toastMessage };
        }

        const didCopy = copy(text);
        setIsCopied(didCopy);
      }
    },
  };
}
