import * as yup from 'yup';

export enum SupportedAuthProvidersEnum {
  Google = 'google.com',
  Microsoft = 'microsoft.com',
  JaneStreet = 'saml.jane-street',
  Pace = 'saml.pace',
  Password = 'password',
  Vercel = 'saml.vercel',
}

export enum SupportedTenantsEnum {
  JaneStreet = 'janestreet',
  Pace = 'pace',
  Vercel = 'vercel',
}

export const LOGIN_FORM_VALIDATION_SCHEMA = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
  rememberMeCheck: yup.boolean(),
});

export const LOGIN_FORM_INITIAL_VALUES = {
  email: '',
  password: '',
};

export const ERROR_LINK_CREDENTIALS_IS_NEEDED = 'auth/account-exists-with-different-credential';
