import cn from 'classnames';
import format from 'date-fns/format';
import * as React from 'react';
import { FileRejection } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import { ReactComponent as ImagePlaceholderIcon } from '../../../assets/images/icon-image-placeholder.svg';
import { IMAGE_TYPES, MAX_IMAGE_SIZE } from '../../../constants/upload';
import { IPromiseCallbacks } from '../../../types/redux';
import { IApiError } from '../../../types/shell';
import { IImageLibraryItem, IUploadResponse } from '../../../types/upload';
import { formatFileSize } from '../../../utils/bucket';
import { takeAllAfterLastSlash } from '../../../utils/ui';
import FileDetails from '../../FileDetails/FileDetails';
import { ActionButton, BackButton, UploadInput } from '../../forms';
import Loader from '../../Loader/Loader';
import ModalActions from '../ModalActions/ModalActions';
import ModalHeader from '../ModalHeader/ModalHeader';

import styles from './ImageLibraryModal.module.scss';

interface IProps {
  onChoose?: (img: IImageLibraryItem) => void;
  onClose?: () => void;
  onUploadStart?: (payload: { files: File[] } & IPromiseCallbacks) => void;
  onUploadSuccess?: (...args: any[]) => void;
  onUploadFailure?: (...args: any[]) => void;
  onDropReject?: (fileRejections: FileRejection[]) => void;
  supportedFileTypes?: string | string[];
  maxUploadedFileSize?: number;
  onFetch: ({ resolve, reject }: any) => void;
}

const ImageLibraryModal = ({
  onClose,
  onChoose,
  onUploadStart,
  onUploadSuccess,
  onUploadFailure,
  onDropReject,
  supportedFileTypes = IMAGE_TYPES,
  maxUploadedFileSize = MAX_IMAGE_SIZE,
  onFetch,
}: IProps) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = React.useState<IImageLibraryItem | null>(null);
  const [imagesList, setImagesList] = React.useState<IImageLibraryItem[]>([]);
  const [isLoadingInitial, setIsLoadingInitial] = React.useState(false);
  const [isLoadingImage, setIsLoadingImage] = React.useState(false);

  React.useEffect(() => {
    let isSubscribed = true;
    setIsLoadingInitial(true);
    getImages()
      .then((imgsList) => isSubscribed && setImagesList(imgsList))
      .finally(() => isSubscribed && setIsLoadingInitial(false));

    return () => {
      isSubscribed = false;
    };
  }, []);

  const getImages: () => Promise<IImageLibraryItem[]> = React.useCallback(() => {
    return new Promise<IImageLibraryItem[]>((resolve, reject) => {
      onFetch({ resolve, reject });
    });
  }, [dispatch]);

  const handleUploadFile = React.useCallback(
    ({ files, resolve, reject }: { files: File[] } & IPromiseCallbacks) => {
      if (typeof onUploadStart === 'function') {
        onUploadStart({ files, resolve, reject });
        setIsLoadingImage(true);
      }
    },
    [onUploadStart],
  );

  const handleUploadSuccess = React.useCallback(
    (files: File[], response: IUploadResponse) => {
      const { public_url } = response;

      if (typeof onUploadSuccess === 'function') {
        onUploadSuccess(files, response);
      }

      getImages()
        .then((imgsList) => {
          setImagesList(imgsList);
          setSelectedImage(imgsList ? imgsList.find((el) => el.public_url === public_url) || null : null);
        })
        .catch((error) => {
          if (typeof onUploadFailure === 'function') {
            onUploadFailure(error);
          }
        })
        .finally(() => {
          setIsLoadingImage(false);
        });
    },
    [onUploadSuccess, getImages],
  );

  const handleFailure = React.useCallback(
    (error: IApiError) => {
      if (typeof onUploadFailure === 'function') {
        onUploadFailure(error);
      }

      setIsLoadingImage(false);
    },
    [onUploadFailure],
  );

  const handleDropReject = React.useCallback((fileRejections: FileRejection[]) => {
    if (typeof onDropReject === 'function') {
      onDropReject(fileRejections);
    }
  }, []);

  const handleOnBack = React.useCallback(() => {
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);

  const handleFileChoose = React.useCallback(() => {
    if (typeof onChoose === 'function') {
      onChoose(selectedImage || ({} as IImageLibraryItem));
    }
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onChoose, onClose, selectedImage]);

  const fileDescription = React.useMemo(() => {
    if (!selectedImage) {
      return;
    }

    const {
      meta_data: { updated_at, size },
    } = selectedImage;

    // The `updated_at` date arrives in the format "2020-12-21 16:02:40.404 +0000 UTC",
    // and we need only the first part YYYY-MM-DD
    // Otherwise, there's a bug on Safari EX-1318
    const date = format(new Date(updated_at.split(' ')[0]), `d MMM, yyyy`);
    return `${date} • ${formatFileSize(size)}`;
  }, [selectedImage]);

  const imagesListMarkup = React.useMemo(() => {
    return imagesList && imagesList.length ? (
      imagesList.map((img) => {
        const { public_url } = img;
        return (
          <div
            className={cn(styles.imgItem, styles.iconInner, { [styles.selectedImage]: selectedImage === img })}
            key={public_url}
            onClick={() => setSelectedImage(img)}
          >
            <div className={styles.icon} style={{ backgroundImage: `url(${public_url})` }} />
          </div>
        );
      })
    ) : (
      <div className={styles.noItems}>No uploaded content</div>
    );
  }, [imagesList, selectedImage]);

  const shouldShowControls = React.useMemo(() => !!onUploadStart, [onUploadStart]);

  return (
    <div className={styles.container}>
      <ModalHeader
        className={styles.header}
        title="Image Library"
        left={<BackButton className={styles.backBtn} onClick={handleOnBack} />}
      />
      <div className={styles.content}>
        <Loader className={styles.loader} isLoading={isLoadingImage} />
        {shouldShowControls && (
          <div className={styles.uploadedControls}>
            <div>
              <UploadInput
                accept={supportedFileTypes}
                onUpload={handleUploadFile}
                onSuccess={handleUploadSuccess}
                onFailure={handleFailure}
                onDropReject={handleDropReject}
                className={styles.uploadField}
                multiple={false}
                noDrag={true}
                showLoader={false}
                maxSize={maxUploadedFileSize}
              >
                {
                  (/* isDragReject */) => {
                    return (
                      <ActionButton
                        className={styles.uploadBtn}
                        disabled={isLoadingInitial || isLoadingImage}
                        severity="secondary"
                      >
                        <ImagePlaceholderIcon className={styles.icon} />
                        Upload Image
                      </ActionButton>
                    );
                  }
                }
              </UploadInput>
            </div>
            {selectedImage && (
              <div className={styles.imgPreview}>
                <div className={cn(styles.imgItem, styles.iconInner)}>
                  <div className={styles.icon} style={{ backgroundImage: `url(${selectedImage.public_url})` }} />
                </div>
                <FileDetails name={takeAllAfterLastSlash(selectedImage.name)} description={fileDescription} />
                {/* //TODO: Return in the future */}
                {/* <TextIconButton title="Delete" className={styles.delete} onClick={handleOnDeleteClick} /> */}
              </div>
            )}
          </div>
        )}
        <div className={cn(styles.list, { [styles.loading]: isLoadingInitial })}>
          {isLoadingInitial ? <Loader className={styles.loader} isLoading /> : imagesListMarkup}
        </div>
      </div>
      <ModalActions className={styles.footer}>
        <ActionButton
          title="Choose"
          className={styles.button}
          onClick={handleFileChoose}
          disabled={!selectedImage || isLoadingInitial || isLoadingImage}
        />
      </ModalActions>
    </div>
  );
};

export default ImageLibraryModal;
