import * as React from 'react';
import { useParams } from 'react-router-dom';

import { OneLinkManagerContainer, OneLinkSummaryContainer } from '../../containers';
import { IOneLinkRouteParams } from '../../types/routing';

const OneLinkManager: React.FC = () => {
  const { itemId } = useParams<IOneLinkRouteParams>();

  if (itemId) {
    return <OneLinkSummaryContainer />;
  }

  return <OneLinkManagerContainer />;
};

export default OneLinkManager;
