import {
  IAddInventoryItemRequest,
  IDeleteInventoryItemRequest,
  IDeleteInventoryItemResponse,
  IEditInventoryItemRequest,
  IFetchInventoryItemByIdRequest,
  IFetchInventoryItemResponse,
  IFetchInventoryItemsRequestMetadata,
  IFetchInventoryItemsRequestPayload,
  IFetchInventoryItemsResponse,
  IFetchLowInventoryItemsRequest,
  IFetchLowInventoryItemsResponse,
  IInventoryItem,
  InventoryTypesEnum,
  ISetUploadInventoryCSVFilePayload,
  IUploadInventoryCSVRequestPayload,
  IUploadInventoryCSVResponsePayload,
} from '../../types/inventories';
import { ILowInventoryNotificationsContent } from '../../types/modals';
import { IPromiseCallbacks } from '../../types/redux';
import { IApiError, IDownloadCSVSuccessPayload } from '../../types/shell';

export const FETCH_INVENTORY_ITEMS_REQUEST = 'FETCH_INVENTORY_ITEMS_REQUEST';
export const FETCH_INVENTORY_ITEMS_SUCCESS = 'FETCH_INVENTORY_ITEMS_SUCCESS';
export const FETCH_INVENTORY_ITEMS_FAILURE = 'FETCH_INVENTORY_ITEMS_FAILURE';

export const FETCH_INVENTORY_ITEM_BY_ID_REQUEST = 'FETCH_INVENTORY_ITEM_BY_ID_REQUEST';
export const FETCH_INVENTORY_ITEM_BY_ID_SUCCESS = 'FETCH_INVENTORY_ITEM_BY_ID_SUCCESS';
export const FETCH_INVENTORY_ITEM_BY_ID_FAILURE = 'FETCH_INVENTORY_ITEM_BY_ID_FAILURE';

export const FETCH_LOW_INVENTORY_ITEMS_REQUEST = 'FETCH_LOW_INVENTORY_ITEMS_REQUEST';
export const FETCH_LOW_INVENTORY_ITEMS_SUCCESS = 'FETCH_LOW_INVENTORY_ITEMS_SUCCESS';
export const FETCH_LOW_INVENTORY_ITEMS_FAILURE = 'FETCH_LOW_INVENTORY_ITEMS_FAILURE';

export const ADD_INVENTORY_ITEM_REQUEST = 'ADD_INVENTORY_ITEM_REQUEST';
export const ADD_INVENTORY_ITEM_SUCCESS = 'ADD_INVENTORY_ITEM_SUCCESS';
export const ADD_INVENTORY_ITEM_FAILURE = 'ADD_INVENTORY_ITEM_FAILURE';

export const EDIT_INVENTORY_ITEM_REQUEST = 'EDIT_INVENTORY_ITEM_REQUEST';
export const EDIT_INVENTORY_ITEM_SUCCESS = 'EDIT_INVENTORY_ITEM_SUCCESS';
export const EDIT_INVENTORY_ITEM_FAILURE = 'EDIT_INVENTORY_ITEM_FAILURE';

export const DELETE_INVENTORY_ITEM_REQUEST = 'DELETE_INVENTORY_ITEM_REQUEST';
export const DELETE_INVENTORY_ITEM_SUCCESS = 'DELETE_INVENTORY_ITEM_SUCCESS';
export const DELETE_INVENTORY_ITEM_FAILURE = 'DELETE_INVENTORY_ITEM_FAILURE';

export const ADD_INVENTORY_ITEM_VALUE = 'ADD_INVENTORY_ITEM_VALUE';
export const SET_INVENTORY_ITEM_TYPE = 'SET_INVENTORY_ITEM_TYPE';
export const CLEAR_INVENTORY_ITEM_VALUE = 'CLEAR_INVENTORY_ITEM_VALUE';
export const CLEAR_INVENTORY_ITEMS = 'CLEAR_INVENTORY_ITEMS';

export const DOWNLOAD_INVENTORY_REPORT_CSV_REQUEST = 'DOWNLOAD_INVENTORY_REPORT_CSV_REQUEST';
export const DOWNLOAD_INVENTORY_REPORT_CSV_SUCCESS = 'DOWNLOAD_INVENTORY_REPORT_CSV_SUCCESS';
export const DOWNLOAD_INVENTORY_REPORT_CSV_FAILURE = 'DOWNLOAD_INVENTORY_REPORT_CSV_FAILURE';

export const UPLOAD_INVENTORY_CSV_REQUEST = 'UPLOAD_INVENTORY_CSV_REQUEST';
export const UPLOAD_INVENTORY_CSV_SUCCESS = 'UPLOAD_INVENTORY_CSV_SUCCESS';
export const UPLOAD_INVENTORY_CSV_FAILURE = 'UPLOAD_INVENTORY_CSV_FAILURE';

export const SET_UPLOAD_INVENTORY_CSV_FILE = 'SET_UPLOAD_INVENTORY_CSV_FILE';

export const fetchInventoryItemsRequest = (
  payload: IFetchInventoryItemsRequestPayload,
  metadata?: IFetchInventoryItemsRequestMetadata,
) => ({
  type: FETCH_INVENTORY_ITEMS_REQUEST,
  payload,
  metadata,
});

export const fetchInventoryItemsSuccess = (payload: IFetchInventoryItemsResponse) => ({
  type: FETCH_INVENTORY_ITEMS_SUCCESS,
  payload,
});

export const fetchInventoryItemsFailure = (payload: IApiError | Error | unknown) => ({
  type: FETCH_INVENTORY_ITEMS_FAILURE,
  payload,
});

export const fetchLowInventoryItemsRequest = (
  payload?: IFetchLowInventoryItemsRequest,
  metadata?: ILowInventoryNotificationsContent,
) => ({
  type: FETCH_LOW_INVENTORY_ITEMS_REQUEST,
  payload,
  metadata,
});

export const fetchLowInventoryItemsSuccess = (payload: IFetchLowInventoryItemsResponse) => ({
  type: FETCH_LOW_INVENTORY_ITEMS_SUCCESS,
  payload,
});

export const fetchLowInventoryItemsFailure = (payload: IApiError | Error | unknown) => ({
  type: FETCH_LOW_INVENTORY_ITEMS_FAILURE,
  payload,
});

export const fetchInventoryItemByIdRequest = (payload: IFetchInventoryItemByIdRequest) => ({
  type: FETCH_INVENTORY_ITEM_BY_ID_REQUEST,
  payload,
});

export const fetchInventoryItemByIdSuccess = (payload: IFetchInventoryItemResponse) => ({
  type: FETCH_INVENTORY_ITEM_BY_ID_SUCCESS,
  payload,
});

export const fetchInventoryItemByIdFailure = (payload: IApiError | unknown) => ({
  type: FETCH_INVENTORY_ITEM_BY_ID_FAILURE,
  payload,
});

export const addInventoryItemRequest = (payload: IAddInventoryItemRequest) => ({
  type: ADD_INVENTORY_ITEM_REQUEST,
  payload,
});

// TODO: Add a real type
export const addInventoryItemSuccess = (payload?: any) => ({
  type: ADD_INVENTORY_ITEM_SUCCESS,
  payload,
});

export const addInventoryItemFailure = (payload?: IApiError | Error | unknown) => ({
  type: ADD_INVENTORY_ITEM_FAILURE,
  payload,
});

export const editInventoryItemRequest = (payload: IEditInventoryItemRequest) => ({
  type: EDIT_INVENTORY_ITEM_REQUEST,
  payload,
});

// TODO: Add a real type
export const editInventoryItemSuccess = (payload?: any) => ({
  type: EDIT_INVENTORY_ITEM_SUCCESS,
  payload,
});

export const editInventoryItemFailure = (payload?: IApiError | Error | unknown) => ({
  type: EDIT_INVENTORY_ITEM_FAILURE,
  payload,
});

export const deleteInventoryItemRequest = (payload: IDeleteInventoryItemRequest) => ({
  type: DELETE_INVENTORY_ITEM_REQUEST,
  payload,
});

export const deleteInventoryItemSuccess = (payload: IDeleteInventoryItemResponse) => ({
  type: DELETE_INVENTORY_ITEM_SUCCESS,
  payload,
});

export const deleteInventoryItemFailure = (payload?: IApiError | Error | unknown) => ({
  type: DELETE_INVENTORY_ITEM_FAILURE,
  payload,
});

export const addInventoryItemValue = (payload?: Partial<IInventoryItem>) => ({
  type: ADD_INVENTORY_ITEM_VALUE,
  payload,
});

export const setInventoryItemType = (payload: InventoryTypesEnum) => ({
  type: SET_INVENTORY_ITEM_TYPE,
  payload,
});

export const clearInventoryItems = () => ({
  type: CLEAR_INVENTORY_ITEMS,
});

export const clearInventoryItemValue = () => ({
  type: CLEAR_INVENTORY_ITEM_VALUE,
});

export const downloadInventoryReportCSVRequest = (payload?: IPromiseCallbacks) => ({
  type: DOWNLOAD_INVENTORY_REPORT_CSV_REQUEST,
  payload,
});

export const downloadInventoryReportCSVSuccess = (payload: IDownloadCSVSuccessPayload) => ({
  type: DOWNLOAD_INVENTORY_REPORT_CSV_SUCCESS,
  payload,
});

export const downloadInventoryReportCSVFailure = (payload?: unknown) => ({
  type: DOWNLOAD_INVENTORY_REPORT_CSV_FAILURE,
  payload,
});

export const uploadInventoryCSVRequest = (payload: IUploadInventoryCSVRequestPayload) => ({
  type: UPLOAD_INVENTORY_CSV_REQUEST,
  payload,
});

export const uploadInventoryCSVSuccess = (payload?: IUploadInventoryCSVResponsePayload) => ({
  type: UPLOAD_INVENTORY_CSV_SUCCESS,
  payload,
});

export const uploadInventoryCSVFailure = (payload?: IApiError | unknown) => ({
  type: UPLOAD_INVENTORY_CSV_FAILURE,
  payload,
});

export const setInventoryCSVUploadFile = (payload?: ISetUploadInventoryCSVFilePayload) => ({
  type: SET_UPLOAD_INVENTORY_CSV_FILE,
  payload,
});
