import cn from 'classnames';
import * as React from 'react';
import { ReactComponent as BagIcon } from '../../assets/images/icon-bag.svg';
import styles from './BagCount.module.scss';
export interface IBagCountProps {
  count: number;
  className?: string;
  iconClassName?: string;
  countClassName?: string;
  onClick?: () => void;
}

const BagCount: React.FC<IBagCountProps> = ({ count, className, iconClassName, countClassName, onClick }) => {
  const displayedCount = React.useMemo(() => {
    if (count <= 999) {
      return count;
    }

    return `999+`;
  }, [count]);

  const isBagButtonDisabled = React.useMemo(() => count === 0, [count]);

  const handleBagClick = React.useCallback(() => {
    if (!isBagButtonDisabled && onClick) {
      onClick();
    }
  }, [isBagButtonDisabled, onClick]);
  return (
    <div
      className={cn(styles.container, isBagButtonDisabled ? styles.disabled : '', className)}
      onClick={handleBagClick}
    >
      <BagIcon className={cn(styles.bagIcon, iconClassName)} />
      {count !== 0 && (
        <div className={cn(styles.countValueContainer, countClassName)} key={`bag_count_${count}`}>
          <span className={styles.countValue}>{displayedCount}</span>
        </div>
      )}
    </div>
  );
};
export default BagCount;
