import { IReport } from '../../types/reports';
import { IApiError } from '../../types/shell';
import { IEngagementOrderIdActionPayload, IEngagementOrderIdRequestActionPayload } from '../../types/tools';

export const FETCH_ENGAGEMENT_BY_ORDER_ID_REQUEST = 'FETCH_ENGAGEMENT_BY_ORDER_ID_REQUEST';
export const FETCH_ENGAGEMENT_BY_ORDER_ID_SUCCESS = 'FETCH_ENGAGEMENT_BY_ORDER_ID_SUCCESS';
export const FETCH_ENGAGEMENT_BY_ORDER_ID_FAILURE = 'FETCH_ENGAGEMENT_BY_ORDER_ID_FAILURE';

export const DELETE_ENGAGEMENT_BY_ORDER_ID_REQUEST = 'DELETE_ENGAGEMENT_BY_ORDER_ID_REQUEST';
export const DELETE_ENGAGEMENT_BY_ORDER_ID_SUCCESS = 'DELETE_ENGAGEMENT_BY_ORDER_ID_SUCCESS';
export const DELETE_ENGAGEMENT_BY_ORDER_ID_FAILURE = 'DELETE_ENGAGEMENT_BY_ORDER_ID_FAILURE';

export const CANCEL_ENGAGEMENT_BY_ORDER_ID_REQUEST = 'CANCEL_ENGAGEMENT_BY_ORDER_ID_REQUEST';
export const CANCEL_ENGAGEMENT_BY_ORDER_ID_SUCCESS = 'CANCEL_ENGAGEMENT_BY_ORDER_ID_SUCCESS';
export const CANCEL_ENGAGEMENT_BY_ORDER_ID_FAILURE = 'CANCEL_ENGAGEMENT_BY_ORDER_ID_FAILURE';

export const CLEAR_ENGAGEMENT = 'CLEAR_ENGAGEMENT';

export const fetchEngagementByOrderIdRequest = (payload: IEngagementOrderIdRequestActionPayload) => ({
  type: FETCH_ENGAGEMENT_BY_ORDER_ID_REQUEST,
  payload,
});

export const fetchEngagementByOrderIdSuccess = (payload: IReport) => ({
  type: FETCH_ENGAGEMENT_BY_ORDER_ID_SUCCESS,
  payload,
});

export const fetchEngagementByOrderIdFailure = (payload: IApiError | Error | unknown) => ({
  type: FETCH_ENGAGEMENT_BY_ORDER_ID_FAILURE,
  payload,
});

export const deleteEngagementByOrderIdRequest = (payload: IEngagementOrderIdRequestActionPayload) => ({
  type: DELETE_ENGAGEMENT_BY_ORDER_ID_REQUEST,
  payload,
});

export const deleteEngagementByOrderIdSuccess = (payload: IEngagementOrderIdActionPayload) => ({
  type: DELETE_ENGAGEMENT_BY_ORDER_ID_SUCCESS,
  payload,
});

export const deleteEngagementByOrderIdFailure = (payload: IApiError | Error | unknown) => ({
  type: DELETE_ENGAGEMENT_BY_ORDER_ID_FAILURE,
  payload,
});

export const cancelEngagementByOrderIdRequest = (payload: IEngagementOrderIdRequestActionPayload) => ({
  type: CANCEL_ENGAGEMENT_BY_ORDER_ID_REQUEST,
  payload,
});

export const cancelEngagementByOrderIdSuccess = (payload: IEngagementOrderIdActionPayload) => ({
  type: CANCEL_ENGAGEMENT_BY_ORDER_ID_SUCCESS,
  payload,
});

export const cancelEngagementByOrderIdFailure = (payload: IApiError | Error | unknown) => ({
  type: CANCEL_ENGAGEMENT_BY_ORDER_ID_FAILURE,
  payload,
});

export const clearEngagement = () => ({
  type: CLEAR_ENGAGEMENT,
});
