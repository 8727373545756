import cn from 'classnames';
import * as React from 'react';

import stylesAvatar from '../Avatar/Avatar.module.scss';
import stylesTextAvatar from './InitialLetterAvatar.module.scss';

interface IProps {
  letter: string;
  className?: string;
}

const InitialLetterAvatar = ({ letter, className }: IProps) => {
  return (
    <div className={cn(stylesAvatar.avatar, stylesTextAvatar.textAvatar, className)}>
      <span>{letter.toUpperCase()}</span>
    </div>
  );
};

export default InitialLetterAvatar;
