import { call, put, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { endpoints, queryParams } from '../../constants/routing';
import { IFetchFulfillmentCentersRequest } from '../../types/fulfillmentCenters';
import { IReduxAction } from '../../types/redux';
import { getQuery } from '../../utils/request';
import * as FulfillmentCentersActions from '../actions/fulfillmentCenters';
import { AuthRequest } from './helpers';

function* fetchFulfillmentCentersWorkerSaga(
  action: IReduxAction<IFetchFulfillmentCentersRequest>,
): Generator<any, any, any> {
  try {
    const { hasOrgInventory } = action.payload || {};

    const endpoint = `${apiUrl}${endpoints.fulfillmentCenters}`;
    const qs = {
      ...(hasOrgInventory ? getQuery(hasOrgInventory, queryParams.orgInventory) : {}),
    };

    const response = yield call<any>(AuthRequest, { endpoint, queryParams: qs });

    if (!response.ok) {
      yield put(FulfillmentCentersActions.fetchFulfillmentCentersFailure(response.body));
    } else {
      yield put(FulfillmentCentersActions.fetchFulfillmentCentersSuccess(response.body));
    }
  } catch (error) {
    yield put(FulfillmentCentersActions.fetchFulfillmentCentersFailure(error));
  }
}

const sagas = {
  *watchFulfillmentCentersRequest() {
    yield takeLatest(FulfillmentCentersActions.FETCH_FULFILLMENT_CENTERS_REQUEST, fetchFulfillmentCentersWorkerSaga);
  },
};

export default sagas;
