import cn from 'classnames';
import React from 'react';

import { Drawer } from '../../components';
import useWindowSize from '../../hooks/useWindowSize';
import { BUTTON_BUTTON } from '../../types/forms';
import { DrawerAnimationDirectionEnum } from '../../types/shell';

import variables from '../../_variables.scss';
import styles from './AddressBookSidebar.module.scss';

interface IProps {
  onClose: () => void;
  trigger: boolean;
  children?: React.ReactNode;
}

const AddressBookSidebar: React.FC<IProps> = ({ trigger, onClose, children }) => {
  const { mobile, HD } = useWindowSize();
  const from = React.useMemo(() => (mobile ? variables.orgSidebarMinHeight : variables.orgSidebarMinWidth), [mobile]);

  const to = React.useMemo(() => {
    if (mobile) {
      return variables.orgSidebarMaxHeight;
    }

    if (HD) {
      return variables.orgSidebarMaxWidthHD;
    }

    return variables.orgSidebarMaxWidthFullHD;
  }, [mobile, HD]);

  return (
    <Drawer
      className={cn(styles.container)}
      direction={mobile ? DrawerAnimationDirectionEnum.Vertically : DrawerAnimationDirectionEnum.Horizontally}
      from={from}
      to={to}
      trigger={trigger}
      onClose={onClose}
      withOverlay
      withPortal
      overlayClassName={styles.overlay}
    >
      <div className={styles.wrapper}>
        <button type={BUTTON_BUTTON} className={styles.closeBtn} onClick={onClose} />
        {trigger && children}
      </div>
    </Drawer>
  );
};

export default AddressBookSidebar;
