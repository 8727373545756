import { userIntegrations } from '../constants/integrations';
import { SIGNATURE } from '../constants/signature';
import {
  BUDGET_CURRENCY,
  CRM_SKIP_ALLOWED,
  DIGITAL_BALANCE_EMAILS_DISABLED,
  DISPLAY_CURRENCY,
  DISTRIBUTOR_ORG_IDS,
} from '../constants/users';
import { IErrorResponse } from './bucket';
import { IPromiseCallbacks } from './redux';

export enum UserIntegrationStatusesEnum {
  ON = 'ON',
  OFF = 'OFF',
  NA = 'NA',
}

export type TUserIntegrations = {
  [K in typeof userIntegrations[number]]: UserIntegrationStatusesEnum;
};

export interface IUser {
  address1: string;
  address2: string;
  city: string;
  country: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  state: string;
  [SIGNATURE]: string;
  zip: string;
  department_id: string;
  [DISTRIBUTOR_ORG_IDS]: string[];
  company_name: string;
  org_id: string;
  admin_type: string;
  available_budget: number;
  used_budget: number;
  picture: string;
  integration: TUserIntegrations;
  uid: string;
  [CRM_SKIP_ALLOWED]: boolean;
  [DIGITAL_BALANCE_EMAILS_DISABLED]?: boolean;
  [DISPLAY_CURRENCY]: string;
  [BUDGET_CURRENCY]: string;
}

export interface IUsersState {
  items: IUser[] | null;
  userDetails: Partial<IUser> | null;
  isLoading: boolean;
  error: IErrorResponse | null;
}

export interface IAddUserActionPayload extends IPromiseCallbacks {
  user: Omit<IUser, 'integration' & 'uid' & 'org_id'>;
}

export interface IEditUserActionPayload extends IPromiseCallbacks {
  user: Partial<IUser>;
}

export interface IDeleteUserActionPayload extends IPromiseCallbacks {
  userId: string;
  newOwnerId?: string | undefined;
}

export interface IToggleDigitalBalanceEmailsActionPayload {
  [DIGITAL_BALANCE_EMAILS_DISABLED]: boolean;
}

export interface IResetPasswordActionPayload {
  email: string;
}

export interface IResetPasswordResponse {
  link: string;
}

export interface IResponseUser {
  integration: TUserIntegrations;
  user: IUser;
}

export interface IFetchUsersResponse {
  users: IResponseUser[];
}

export interface IEditUserProfileRequestPayload {
  [key: string]: string;
}
