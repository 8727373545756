export const googleAuthorize = () => {
  return new Promise((resolve, reject) => {
    if (window.google) {
      const client = window.google.accounts.oauth2.initCodeClient({
        scope: process.env.REACT_APP_GMAIL_PERMISSIONS || '',
        client_id: process.env.REACT_APP_GMAIL_CLIENT_ID || '',
        ux_mode: 'popup',
        callback: ({ code, error }) => {
          if (code) {
            resolve({ code });
          } else if (error) {
            reject({ error });
          } else {
            reject({ error: new Error('Unknown error has occurred during the Authorization with Google') });
          }
        },
      });

      client.requestCode();
    } else {
      reject({ error: new Error('Google is not initialized') });
    }
  }).catch((error: unknown) => {
    console.error('Unexpected error during the Gmail authorization');
    return {
      error,
    };
  });
};
