import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteProps } from 'react-router-dom';

import { routes } from '../../constants/routing';
import { selectAdminType, selectIsAuthenticated, selectIsFirebaseProfileLoaded } from '../../store/selectors/auth';
import notification from '../../utils/notification';
import { hasPermission } from '../../utils/users';
import { PrivateRoute } from './index';

interface IProps extends RouteProps {
  roles: string | string[];
}

export default ({ roles, ...rest }: IProps) => {
  const adminType = useSelector(selectAdminType);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isProfileLoaded = useSelector(selectIsFirebaseProfileLoaded);

  if (!isProfileLoaded) {
    return null;
  }

  if (isAuthenticated && !hasPermission(roles, adminType)) {
    notification.accessDenied();
    return <Redirect to={{ pathname: routes.dashboard }} />;
  }

  return <PrivateRoute {...rest} />;
};
