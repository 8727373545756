import * as React from 'react';
import { useSelector } from 'react-redux';
import { OnChangeValue } from 'react-select';

import { ReactComponent as CloseIcon } from '../../../../assets/images/icon-close-cross.svg';
import { DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN, USER } from '../../../../constants/users';
import useWindowSize from '../../../../hooks/useWindowSize';
import { selectUsers } from '../../../../store/selectors/users';
import { BUTTON_BUTTON } from '../../../../types/forms';
import { ISelectorValue } from '../../../../types/shell';
import { IUser } from '../../../../types/users';
import { handleApiError } from '../../../../utils/ui';
import { hasPermission } from '../../../../utils/users';
import { ActionButton, Loader, ModalActions, ModalHeader, Selector, UserOption } from '../../../index';

import styles from './ReassignUserModal.module.scss';

interface IProps {
  onClose: () => void;
  onDelete: (userId: string, newUserId?: string) => Promise<void>;
  id: string;
}

export interface ISelectorValueWithUser extends ISelectorValue {
  user: IUser;
}

export interface IReassignUserModalProps extends Pick<IProps, 'onDelete' | 'id'> {}

const ReassignUserModal: React.FC<IProps> = ({ onClose, onDelete, id }) => {
  const { mobile } = useWindowSize();

  const users = useSelector(selectUsers);

  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedUserUid, setSelectedUserUid] = React.useState<string | undefined>();

  const userToDelete = React.useMemo(() => users?.find((user) => user.uid === id), [users]);

  const userOptions = React.useMemo(() => {
    return users
      ? users
          .filter(({ uid }) => uid !== id)
          .filter(({ admin_type, department_id }) => {
            if (userToDelete) {
              // we can use DEPT amd USER from the same department only
              if (
                hasPermission([DEPT_ADMIN, USER], userToDelete.admin_type) &&
                hasPermission([DEPT_ADMIN, USER], admin_type)
              ) {
                return userToDelete.department_id === department_id;
              }

              // we can use only SUPER in case we delete DIST or higher
              if (hasPermission([DISTRIBUTOR, SUPER_ADMIN], userToDelete.admin_type)) {
                return hasPermission([SUPER_ADMIN], admin_type);
              }

              // for ORG admins - show all admin users in this org
              return hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], admin_type);
            }

            // should be unreachable scenario
            return true;
          })
          .map((user: IUser) => {
            return { value: user.uid, label: `${user.first_name} ${user.last_name}`, user };
          })
      : [];
  }, [users, id, userToDelete]);

  const selectorValue = React.useMemo(() => {
    return userOptions.find((option) => option.value === selectedUserUid);
  }, [userOptions, selectedUserUid]);

  const handleUserSelect = React.useCallback((userOption: OnChangeValue<ISelectorValue, boolean> | null) => {
    if (userOption) {
      const { value } = userOption as ISelectorValue;
      setSelectedUserUid(value);
    } else {
      setSelectedUserUid(undefined);
    }
  }, []);

  const handleDeleteClick = React.useCallback(() => {
    setIsLoading(true);
    return onDelete(id, selectedUserUid)
      .then(() => {
        onClose();
      })
      .catch(handleApiError(`Something bad happened. The user wasn't reassigned.`))
      .finally(() => {
        setIsLoading(false);
      });
  }, [onDelete, id, onClose, selectedUserUid]);

  return (
    <div className={styles.container}>
      <Loader isLoading={isLoading} />
      <ModalHeader
        className={styles.header}
        right={
          <button type={BUTTON_BUTTON} onClick={onClose}>
            <CloseIcon className={styles.closeBtn} />
          </button>
        }
      >
        <h1>Assign another user</h1>
      </ModalHeader>
      <p className={styles.text}>
        The deleted user has open orders that have not yet been confirmed by recipients. Please reassign these open
        orders to an active user by selecting from the list below.
      </p>
      <div className={styles.content}>
        <Selector<ISelectorValueWithUser>
          autoFocus={!mobile}
          placeholder="Search user"
          options={userOptions}
          value={selectorValue}
          menuIsOpen
          onChange={handleUserSelect}
          className={styles.select}
          isClearable
          customOption={UserOption}
        />
      </div>
      <ModalActions className={styles.footer}>
        <ActionButton
          title="Continue"
          className={styles.button}
          onClick={handleDeleteClick}
          disabled={!selectorValue}
        />
      </ModalActions>
    </div>
  );
};

export default ReassignUserModal;
