import {
  BOX_ID,
  BUSINESS_REASON_REQUIRED,
  DELAYED_CUSTOMIZE_ITEMS_PAGE_BODY,
  DELAYED_CUSTOMIZE_ITEMS_PAGE_TITLE,
  DELAYED_MSKU_PAGE_BODY,
  DELAYED_MSKU_PAGE_TITLE,
  DELAYED_SHIPPING_PAGE_BODY,
  DELAYED_SHIPPING_PAGE_TITLE,
  DISABLE_HANDWRITTEN_NOTE_STEP,
  INTERMEDIATE_FC_ID,
  USE_EMAIL_BRANDING,
} from '../constants/campaigns';
import { GenderEnum, IInventoryItem, IInventoryWithType, IOnDemandItem } from './inventories';
import { IPromiseCallbacks } from './redux';
import { IShippingConfiguration } from './shipping';
import { IImageLibraryItem } from './upload';

export interface ICampaignsState {
  items: { [k: string]: ICampaign } | null;
  details: Partial<ICampaignCandidate> | null;
  isLoading: boolean;
  totalCount: number;
  error: any;
  isFinal: boolean;
}

export enum CampaignsTypeEnum {
  Digital = 'digital',
  Physical = 'physical',
  Mixed = 'mixed',
}

export interface ICampaignItemVariation {
  quantity: number;
  is_hidden_for_recipient: boolean;
}

export interface ICampaignItem extends Omit<IInventoryItem, 'is_hidden_for_recipient'>, ICampaignItemVariation {}

export interface ICampaignSummaryItem
  extends Omit<
      IInventoryItem,
      'id' | 'org_id' | 'digital_campaign_key' | 'country_of_origin' | 'is_hidden_for_recipient'
    >,
    ICampaignItemVariation {}

export interface ICustomSend extends Pick<ICampaign, 'name' | 'description' | 'box_id'> {
  items: (ICampaignItem | IOnDemandItem)[];
  shipping_configuration?: never;
}

export interface ICampaign extends Omit<ICampaignSummary, 'items'> {
  org_id: string;
  priority: number;
  items: ICampaignItem[];
  handwritten_template_default: string;
  delayed_shipping_template_default: string;
  digital_link_template_default: string;
  receiver_reminder_interval: string;
  fulfillment_center_id: string;
  shipping_configuration?: IShippingConfiguration;
}

export interface ICampaignSummary {
  description: string;
  name: string;
  department_ids: string[];
  icon_url: string;
  box_id: string;
  items: ICampaignSummaryItem[];
  ds_header_color?: string;
  ds_button_color?: string;
  [DELAYED_SHIPPING_PAGE_TITLE]?: string;
  [DELAYED_SHIPPING_PAGE_BODY]?: string;
  [DELAYED_MSKU_PAGE_TITLE]?: string;
  [DELAYED_MSKU_PAGE_BODY]?: string;
  [DELAYED_CUSTOMIZE_ITEMS_PAGE_TITLE]?: string;
  [DELAYED_CUSTOMIZE_ITEMS_PAGE_BODY]?: string;
  ds_company_name?: string;
  ds_logo_url?: string;
  [USE_EMAIL_BRANDING]?: boolean;
  [DISABLE_HANDWRITTEN_NOTE_STEP]?: boolean;
  [BUSINESS_REASON_REQUIRED]?: boolean;
}

export interface ICampaignFormItem extends IInventoryWithType {
  item_id: string;
  name: string;
  quantity: number;
  image_url: string;
  is_hidden_for_recipient: boolean;
  description: string;
  org_id: string;
  department_ids: string[];
  fulfillment_center_id: string;
  sku_options: IInventoryItem[] | undefined;
  item_customization_allowed?: boolean;
  price?: number;
  currency_code?: string;
  product_sku?: string;
  flavor?: string;
  other_option?: string;
  gender?: GenderEnum;
  size?: string;
  color?: string;
  ds_header_color?: string;
  ds_button_color?: string;
  ds_shipping_page_title?: string;
  ds_shipping_page_body?: string;
  preorder_allowed?: boolean;
  shipping_configuration?: {
    org_address_ids: string[];
    is_receiver_address_fixed: boolean;
    is_delayed_shipping: boolean;
  };
}

export interface ICampaignCandidate extends Partial<Omit<ICampaign, 'items'>> {
  items?: ICampaignFormItem[];
  [INTERMEDIATE_FC_ID]?: string;
}

//#region Request interfaces

export interface IFetchCampaignsRequest {
  type?: CampaignsTypeEnum;
  department_id?: string;
  page_size: number;
  page: number;
  search_query?: string;
}

export interface IFetchCampaignsResponse {
  campaigns: ICampaign[] | null;
  total_count: number;
}

export interface IFetchCampaignByIdRequestPayload extends IPromiseCallbacks {
  campaign_id: string;
}

export interface IFetchCampaignByIdResponsePayload {
  campaign: ICampaign;
}

export interface IFetchCampaignIconsResponse {
  images: IImageLibraryItem[];
}

export interface IAddCampaignRequest extends IPromiseCallbacks {
  campaign: Partial<ICampaign>;
}

export interface IAddCampaignResponse extends Omit<ICampaign, typeof BOX_ID> {}

export interface IEditCampaignRequest extends IPromiseCallbacks {
  campaign: Partial<ICampaign>;
}

export interface IEditCampaignResponse extends Omit<ICampaign, typeof BOX_ID> {}

export interface IDeleteCampaignRequest extends IPromiseCallbacks {
  uid: string;
}

export interface IDeleteCampaignResponse {
  uid: string;
}

export interface IFetchCampaignSummariesRequestPayload {
  campaign_ids: string[];
}

export interface IFetchCampaignSummariesResponsePayload {
  campaign_summaries: ICampaignSummary[];
}

//#endregion
