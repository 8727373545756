import { ORG_ADDRESS_FORM_REQUIRED_FIELDS, ORG_ADDRESS_FORM_REQUIRED_FIELDS_USA } from '../constants/addressBook';
import { USA } from '../constants/countries';

export const getOrgAddressFormRequiredFieldsByCountryCode = (country?: string) => {
  switch (country) {
    case USA.two_digit_code:
      return ORG_ADDRESS_FORM_REQUIRED_FIELDS_USA;
    default:
      return ORG_ADDRESS_FORM_REQUIRED_FIELDS;
  }
};
