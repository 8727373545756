import cn from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';

import { SIGNATURE } from '../../../constants/signature';
import { ISignature } from '../../../types/signature';
import { TinyEditor } from '../inputs';

import styles from './SignatureForm.module.scss';

interface IProps {
  onChange: (name: keyof ISignature, value: string) => void;
  form: FormikProps<Partial<ISignature>>;
  disabled?: boolean;
  className?: string;
}

const SignatureForm: React.FC<IProps> = ({ onChange, form, disabled, className }) => {
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { name, value } = e.currentTarget;
      if (!name) {
        console.warn('No field name was specified');
        return;
      }

      if (typeof onChange === 'function') {
        onChange(name as keyof ISignature, value);
      }
    },
    [onChange],
  );

  return (
    <form className={cn(styles.container, className)} onSubmit={form.handleSubmit}>
      <TinyEditor
        field={form.getFieldProps(SIGNATURE)}
        name={SIGNATURE}
        helperText="Email Signature"
        disabled={disabled}
        value={form.values[SIGNATURE]}
        changeCallback={handleChange}
        className={styles.tinyEditor}
      />
    </form>
  );
};

export default SignatureForm;
