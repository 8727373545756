import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../../../assets/images/icon-close-cross.svg';
import { ActionButton } from '../../../components/forms';
import { URL_VARS } from '../../../constants/routing';
import { selectDownloadSuccessModal } from '../../../store/selectors/modals';
import { IDownloadCSVContent, IModalProperties } from '../../../types/modals';
import { downloadFile } from '../../../utils/ui';
import { ModalHeader } from '../../index';

import styles from './DownloadSuccessModal.module.scss';

interface IProps {
  onClose: () => void;
}

const DownloadSuccessModal = ({ onClose }: IProps) => {
  const location = useLocation();

  const modal: IModalProperties<IDownloadCSVContent> = useSelector(selectDownloadSuccessModal);

  const entity = React.useMemo(() => {
    switch (true) {
      case location.pathname.includes(URL_VARS.INVENTORY):
        return 'inventory items';
      default:
        return 'delivery reports';
    }
  }, [location]);

  const successMessage = React.useMemo(() => {
    return `You can download the file with ${entity}`;
  }, [entity]);

  const { url } = React.useMemo(() => {
    if (modal && modal.content) {
      return modal.content;
    }
    return { url: null };
  }, [modal]);

  const handleActionClick = React.useCallback(() => {
    if (url) {
      downloadFile(url);
    }
    onClose();
  }, [url, onClose]);

  return (
    <div className={cn(styles.container)}>
      <ModalHeader className={styles.header}>
        <div className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </div>
      </ModalHeader>
      <div className={styles.content}>
        <h1 className={styles.title}>The File is Ready</h1>
        <div className={styles.info}>{successMessage}</div>
        <div className={styles.actions}>
          <ActionButton className={styles.btn} onClick={handleActionClick} title="Download" />
        </div>
      </div>
    </div>
  );
};

export default DownloadSuccessModal;
