import cn from 'classnames';
import { FormikValues } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { OnChangeValue } from 'react-select';

import { selectCurrentOrganizationDepartmentList } from '../../../../store/selectors/organizations';
import { IDepartment } from '../../../../types/organizations';
import { ISelectorValue } from '../../../../types/shell';
import { Selector } from '../../../forms/inputs';

import styles from './DepartmentSelect.module.scss';

export interface IDepartmentSelectProps extends FormikValues {
  isMulti?: boolean;
  readOnly?: boolean;
  className?: string;
  defaultValue?: string | null;
  helperText?: string;
}

const DepartmentSelect = ({
  isMulti = true,
  name,
  className,
  value,
  onChange,
  helperText,
  readOnly = false,
  defaultValue,
  ...rest
}: IDepartmentSelectProps) => {
  const departments = useSelector(selectCurrentOrganizationDepartmentList);

  const formattedValue = React.useMemo<OnChangeValue<ISelectorValue, boolean>>(() => {
    let result = null;
    if (Array.isArray(value)) {
      result = (value as string[])?.map((id: string) => {
        const dept = departments ? departments.find((d) => d.uid === id) : ({} as IDepartment);

        return {
          value: id,
          label: dept ? dept?.name : '',
        };
      });
    } else if (typeof value === 'string') {
      const dept = departments ? departments.find((d) => d.uid === value) : ({} as IDepartment);

      result = {
        value: dept ? dept?.uid : '',
        label: dept ? dept?.name : '',
      };
    }

    return result;
  }, [value, departments]);

  const formattedOptions = React.useMemo(
    () => (departments ? departments.map((dept) => ({ value: dept.uid, label: dept.name })) : []),
    [departments],
  );

  const selectedValuesPreparation = React.useCallback(
    (selectedValues: ISelectorValue[]) => {
      const noDeptIndex = selectedValues.findIndex(
        ({ value: optionValue }: ISelectorValue) => optionValue === defaultValue,
      );

      if (noDeptIndex === -1) {
        return selectedValues;
      }

      /* This is a check if No Department was chosen last
       * If it's true, all other options, except No Departments, should be removed
       */
      if (noDeptIndex === selectedValues.length - 1) {
        return [selectedValues[noDeptIndex]];
      }

      /* In other case, if 'No deparment' option is present in selected options
       * and besides it there are also other options, 'No departments' should be removed
       */
      selectedValues.splice(noDeptIndex, 1);
      return selectedValues;
    },
    [defaultValue],
  );

  const handleChange = React.useCallback(
    (values: any) => {
      const val = (() => {
        if (values?.value) {
          return values?.value;
        }
        return isMulti && defaultValue !== null ? [defaultValue] : defaultValue;
      })();

      if (Array.isArray(values)) {
        if (!values || !values.length) {
          onChange({ currentTarget: { name, value: defaultValue ? [defaultValue] : null } });
        } else {
          const preparedValues = selectedValuesPreparation(values);

          onChange({
            currentTarget: {
              name,
              value: Array.from(new Set(preparedValues.map(({ value: newValue }: any) => newValue))),
            },
          });
        }
      } else {
        onChange({
          currentTarget: {
            name,
            value: val,
          },
        });
      }
    },
    [onChange, name, defaultValue, selectedValuesPreparation, isMulti],
  );

  const isClearable = React.useMemo(() => {
    if (Array.isArray(value)) {
      return !(value.length === 1 && value[0] === defaultValue);
    }
    if (typeof value === 'string') {
      return !(value === defaultValue);
    }
  }, [value, defaultValue]);

  return (
    <Selector
      clearHint="Reset to default"
      isClearable={isClearable}
      className={cn(styles.container, className)}
      isMulti={isMulti}
      isSearchable={!readOnly}
      helperText={helperText}
      value={formattedValue}
      options={formattedOptions}
      onChange={handleChange}
      name={name}
      concreteOption={!isClearable ? defaultValue ?? '' : undefined}
      readOnly={readOnly}
      {...rest}
    />
  );
};

export default DepartmentSelect;
