import cn from 'classnames';
import * as React from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import { BUTTON_BUTTON } from '../../../../types/forms';

import styles from './QuantityInput.module.scss';

interface IProps {
  value?: number;
  label?: string;
  minValue?: number;
  maxValue?: number;
  onChange?: (value?: number) => void;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  readOnly?: boolean;
  children?: React.ReactElement;
}

const QuantityInput: React.FC<IProps> = ({
  onChange,
  disabled,
  value = 0,
  minValue = 1,
  maxValue = 9999,
  label,
  className,
  inputClassName,
  readOnly,
  children,
}) => {
  const increase = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      if (typeof onChange === 'function') {
        onChange(value + 1);
      }
    },
    [onChange, value],
  );

  const decrease = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      if (typeof onChange === 'function') {
        onChange(value - 1);
      }
    },
    [onChange, value],
  );

  const handleInputChange = React.useCallback(
    (v: NumberFormatValues) => {
      const { floatValue } = v || {};
      onChange?.(floatValue);
    },
    [onChange],
  );

  const handleBlur = React.useCallback(() => {
    if (typeof minValue !== 'undefined' && value < minValue) {
      onChange?.(minValue);
    }
  }, [minValue, value, onChange]);

  return (
    <div className={cn(styles.container, className)}>
      {label && <div className={cn(styles.label)}>{label}</div>}
      <div className={styles.selector}>
        <button
          className={cn(styles.btn, styles.decrease)}
          onClick={decrease}
          type={BUTTON_BUTTON}
          disabled={!value || value <= minValue || disabled}
        />
        <NumericFormat
          value={value}
          allowNegative={false}
          valueIsNumericString
          allowLeadingZeros={false}
          onBlur={handleBlur}
          readOnly={readOnly}
          decimalScale={0}
          onValueChange={handleInputChange}
          className={cn(styles.customInput, inputClassName)}
          maxLength={maxValue.toString().length}
        />
        <button
          className={cn(styles.btn, styles.increase)}
          onClick={increase}
          type={BUTTON_BUTTON}
          disabled={value >= maxValue || disabled}
        />
        {children}
      </div>
    </div>
  );
};

export default QuantityInput;
