import { USA } from '../constants/countries';
import {
  SENDER_ADDRESS1,
  SENDER_ADDRESS2,
  SENDER_CITY,
  SENDER_COMPANY_NAME,
  SENDER_COUNTRY,
  SENDER_EMAIL,
  SENDER_FIRST_NAME,
  SENDER_LAST_NAME,
  SENDER_PHONE,
  SENDER_STATE,
  SENDER_ZIP,
} from '../constants/shipping';
import {
  DEPT_ADMIN,
  DISTRIBUTOR,
  ORG_ADMIN,
  SUPER_ADMIN,
  UserFilterTypesEnum,
  USER_FORM_REQUIRED_FIELDS_I18L,
  USER_FORM_REQUIRED_FIELDS_USA,
} from '../constants/users';
import { ISenderInfo } from '../types/auth';
import { IDepartment } from '../types/organizations';
import { IUser } from '../types/users';

export const generatePlaceholderUsers = (quantity: number) => {
  if (!quantity || quantity === 0) {
    return [];
  }

  return new Array(quantity).fill(null).map(() => ({
    photo: '',
    email: '',
    name: '',
    role: '',
    budget: null,
    controls: null,
    uid: null,
  }));
};

export const getUserRoleLabel = (role: string) => {
  switch (role) {
    case '':
      return 'User';
    case ORG_ADMIN:
      return 'Admin';
    case SUPER_ADMIN:
      return 'Super';
    case DEPT_ADMIN:
      return 'Department';
    case DISTRIBUTOR:
      return 'Distributor';
    default:
      return 'User';
  }
};

export const isAdminType = (adminType?: string) =>
  [DEPT_ADMIN, ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN].includes(adminType || '');

export const filterByRole = (tabId: number) => (user: IUser) => {
  switch (tabId) {
    case UserFilterTypesEnum.User:
      return !isAdminType(user.admin_type);
    case UserFilterTypesEnum.Admin:
      return isAdminType(user.admin_type);
    default:
      return user;
  }
};

export const hasPermission = (roles: string | string[] | undefined, adminType: string) =>
  !roles || (Array.isArray(roles) ? roles?.includes(adminType) : adminType === roles);

export const getDepartmentLabelById = (id: string, departments: IDepartment[] | null | undefined) =>
  departments && departments.length ? departments.find((dept) => dept.uid === id)?.name : undefined;

export const getUserFormRequiredFieldsByCountryCode = (country?: string) => {
  switch (country) {
    case USA.two_digit_code:
      return USER_FORM_REQUIRED_FIELDS_USA;
    default:
      return USER_FORM_REQUIRED_FIELDS_I18L;
  }
};

export const getSenderInfoObject = (userInfo: ISenderInfo | Partial<IUser> | undefined) => {
  if (!userInfo) {
    return null;
  }
  const { address1, first_name, city, country, phone, last_name, zip, address2, state, email } = userInfo;

  // IUser model has companyName and ISenderInfo has company_name => that's why it is calculated so weird.
  const companyName = (userInfo as IUser).company_name || (userInfo as ISenderInfo).companyName;

  return {
    [SENDER_ADDRESS1]: address1,
    [SENDER_FIRST_NAME]: first_name,
    [SENDER_CITY]: city,
    [SENDER_COUNTRY]: country,
    [SENDER_PHONE]: phone,
    [SENDER_LAST_NAME]: last_name,
    [SENDER_ZIP]: zip,
    [SENDER_ADDRESS2]: address2,
    [SENDER_STATE]: state,
    [SENDER_COMPANY_NAME]: companyName,
    [SENDER_EMAIL]: email,
  };
};
