import cn from 'classnames';
import * as React from 'react';
import { CellProps } from 'react-table';

import CancelIcon from '../../../assets/images/icon-cross-button.svg';
import DeleteIcon from '../../../assets/images/icon-delete-button.svg';
import EditIcon from '../../../assets/images/icon-edit-button.svg';
import SaveIcon from '../../../assets/images/icon-save-button.svg';
import { ReactComponent as SubmittedIcon } from '../../../assets/images/icon-success-check-outline.svg';
import useWindowSize from '../../../hooks/useWindowSize';
import { EditableTableModes } from '../../../types/forms';
import { UISizeEnum } from '../../../types/shell';
import { getCustomProps, getOriginal } from '../../../utils/tables';
import { TextIconButton } from '../../forms';
import { Loader } from '../../index';

import styles from './CellControls.module.scss';

const CellControls = <T extends object, V>(cellData: CellProps<T, V>) => {
  const { mobile } = useWindowSize();

  const originalData = getOriginal(cellData);
  const {
    mode,
    checkEditMode,
    checkIsSubmitted,
    onSave,
    onCancel,
    onDelete,
    onEdit,
    activeItem,
    isChanged,
    allowEmptyInputs,
    isLoading,
    config,
    renderSuccessMessage,
    showControlAsIcons,
  } = getCustomProps<T, V>(cellData) || {};

  const isEditable = checkEditMode?.(originalData);
  const isSubmitted = checkIsSubmitted?.(originalData);
  const areInputsEmpty = config?.columns?.visible
    ?.filter((fieldName: keyof T) => !config?.columns?.readOnly?.includes(fieldName))
    .some((fieldName: keyof T) => {
      const fieldValue = activeItem?.[fieldName];

      if (typeof fieldValue === 'string') {
        return !fieldValue?.trim();
      }

      if (typeof fieldValue === 'number') {
        return fieldValue === 0 ? false : !fieldValue;
      }

      return !fieldValue;
    });

  let showSave;
  let showCancel;
  let showEdit;
  let showDelete;

  switch (mode) {
    case EditableTableModes.Add: {
      showSave = isEditable && (!areInputsEmpty || allowEmptyInputs);
      showCancel = isEditable;
      showEdit = !isEditable;
      showDelete = onDelete && !isEditable;
      break;
    }
    case EditableTableModes.Edit: {
      showSave = isChanged && isEditable && (!areInputsEmpty || allowEmptyInputs);
      showCancel = isEditable;
      showEdit = !isEditable;
      showDelete = onDelete && !isEditable;
      break;
    }
    default:
      showSave = false;
      showCancel = false;
      showEdit = true;
      showDelete = Boolean(onDelete);
      break;
  }

  const isControlIcon = React.useMemo(() => showControlAsIcons || mobile, [showControlAsIcons, mobile]);

  return (
    <div
      className={cn(styles.rowControls, config?.controls?.container?.className)}
      {...cellData.column.getHeaderProps()}
    >
      <Loader isLoading={isLoading && isEditable} size={UISizeEnum.Medium} />
      <React.Fragment>
        {renderSuccessMessage?.(originalData)}
        {!isSubmitted ? (
          <>
            {showSave && (
              <TextIconButton
                trigger={isControlIcon}
                className={cn(styles.controlButton, styles.save, config?.controls?.save?.className)}
                icon={SaveIcon}
                title="Save"
                onClick={() => {
                  onSave?.(activeItem!);
                }}
              />
            )}
            {showEdit && (
              <TextIconButton
                trigger={isControlIcon}
                className={cn(styles.controlButton, styles.edit, config?.controls?.edit?.className)}
                icon={EditIcon}
                title="Edit"
                onClick={() => onEdit?.(originalData)}
              />
            )}
            {onDelete && showDelete && (
              <TextIconButton
                trigger={isControlIcon}
                className={cn(styles.controlButton, styles.delete, config?.controls?.delete?.className)}
                icon={DeleteIcon}
                title="Delete"
                onClick={() => {
                  onDelete?.(originalData);
                }}
              />
            )}
            {showCancel && (
              <TextIconButton
                trigger={isControlIcon}
                className={cn(styles.controlButton, styles.cancel, config?.controls?.cancel?.className)}
                icon={CancelIcon}
                title="Cancel"
                onClick={onCancel}
              />
            )}
          </>
        ) : (
          <SubmittedIcon className={styles.submittedIcon} />
        )}
      </React.Fragment>
    </div>
  );
};

export default CellControls;
