import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { AdminRoute } from '../../components/routes';
import { routes, URL_VARS } from '../../constants/routing';
import { DISTRIBUTOR, SUPER_ADMIN } from '../../constants/users';
import { EditOrganizationContainer, OrganizationsContainer, OrganizationSidebar } from '../../containers';
import { fetchOrganizationsRequest } from '../../store/actions/organizations';
import { fetchMetadataRequest } from '../../store/actions/shell';
import { selectOrganizationById, selectOrganizationsDetailsIsLoading } from '../../store/selectors/organizations';
import { OrganizationsRequestResponseDataTypeEnum } from '../../types/organizations';
import { IOrganizationRouteParams } from '../../types/routing';
import notification from '../../utils/notification';
import { getSimplifiedOrganization } from '../../utils/organizations';

const Organizations: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedOrgId, setSelectedOrgId] = React.useState<string | undefined>();

  const fullOrganization = useSelector(selectOrganizationById(selectedOrgId));

  const simplifiedOrganization = React.useMemo(
    () => (fullOrganization ? getSimplifiedOrganization(fullOrganization) : void 0),
    [fullOrganization],
  );

  const { flowId } = useParams<IOrganizationRouteParams>();

  const toggleDrawer = React.useCallback((uid?: string) => {
    setSelectedOrgId(uid);
  }, []);

  const isDrawerShown = React.useMemo(() => !!selectedOrgId, [selectedOrgId]);
  const isDepartmentDetailsLoading = useSelector(selectOrganizationsDetailsIsLoading);

  React.useEffect(() => {
    dispatch(fetchMetadataRequest());
    dispatch(fetchOrganizationsRequest({ responseDataType: OrganizationsRequestResponseDataTypeEnum.All }));
  }, [dispatch]);

  const onActivate = React.useCallback(notification.comingSoon, []);

  const onDeactivate = React.useCallback(notification.comingSoon, []);

  const render = React.useMemo(() => {
    switch (flowId) {
      case URL_VARS.NEW:
      case URL_VARS.EDIT:
        return (
          <AdminRoute
            roles={[SUPER_ADMIN, DISTRIBUTOR]}
            render={() => (
              <EditOrganizationContainer
                key={flowId}
                onDeactivate={onDeactivate}
                onActivate={onActivate}
                toggleSidebar={toggleDrawer}
              />
            )}
          />
        );
      case URL_VARS.VIEW:
        return (
          <AdminRoute
            roles={[DISTRIBUTOR, SUPER_ADMIN]}
            render={() => (
              <EditOrganizationContainer
                onDeactivate={onDeactivate}
                onActivate={onActivate}
                toggleSidebar={toggleDrawer}
              />
            )}
          />
        );
      default:
        return (
          <OrganizationsContainer onActivate={onActivate} onDeactivate={onDeactivate} onSelect={setSelectedOrgId} />
        );
    }
  }, [flowId, onDeactivate, onActivate, toggleDrawer]);

  return (
    <React.Fragment>
      {render}
      <OrganizationSidebar
        onEdit={(id) => {
          history.push(
            routes.organizations.getOrganizationUrl({
              flowId: URL_VARS.EDIT,
              orgId: id,
            }),
          );
          toggleDrawer();
        }}
        expanded={flowId !== URL_VARS.EDIT}
        onClose={toggleDrawer}
        trigger={isDrawerShown}
        organization={simplifiedOrganization}
        onActivate={onActivate}
        onDeactivate={onDeactivate}
        isRowLoading={isDepartmentDetailsLoading}
      />
    </React.Fragment>
  );
};

export default Organizations;
