import { createSelector } from 'reselect';

import { DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN, USER } from '../../constants/users';
import { ReportStatusEnum } from '../../types/reports';
import { isValidScheduledEngagement } from '../../utils/reports';
import { IAppState } from '../reducers';
import { selectAdminType, selectIsSuperAdmin, selectUserDepartmentId, selectUserProfile } from './auth';
import { selectCurrentOrganizationId } from './organizations';

export const selectRootState = (state: IAppState) => state.tools;

export const selectEngagement = createSelector(selectRootState, (tools) =>
  tools.engagement ? tools.engagement : undefined,
);

export const selectEngagementIsLoading = createSelector(selectRootState, (tools) => (tools ? tools.isLoading : false));

export const selectCanDeleteEngagement = createSelector(selectIsSuperAdmin, selectEngagement, (isSuper, engagement) =>
  Boolean(isSuper && engagement && !isValidScheduledEngagement(engagement)),
);

export const selectCanCancelEngagement = createSelector(
  selectEngagement,
  selectAdminType,
  selectCurrentOrganizationId,
  selectUserDepartmentId,
  selectUserProfile,
  (engagement, adminType, userOrgId, userDepartmentId, userProfile) => {
    const {
      status,
      ship_order_status,
      org_id: engagementOrgId,
      department_id: engagementDepartmentId,
      sender_email: engagementSenderEmail,
      created_by: engagementCreatedBy,
    } = engagement || {};
    const { email: userEmail } = userProfile || {};

    const canEditBasedOnAdminType = (() => {
      const checkEmail = engagementSenderEmail === userEmail || engagementCreatedBy === userEmail;
      const checkDepartmentId = engagementDepartmentId === userDepartmentId;
      const checkOrgId = engagementOrgId === userOrgId;

      switch (true) {
        case adminType === USER:
          return checkEmail;
        case adminType === DEPT_ADMIN:
          return checkEmail || checkDepartmentId;
        case adminType === ORG_ADMIN:
          return checkOrgId;
        case adminType === DISTRIBUTOR:
        case adminType === SUPER_ADMIN:
          return true;
        default:
          return false;
      }
    })();

    return (
      status !== ReportStatusEnum.Canceled &&
      ship_order_status !== ReportStatusEnum.InFulfillment &&
      canEditBasedOnAdminType
    );
  },
);
