import iconGmail from '../assets/images/icon-gmail.svg';
import iconHubspot from '../assets/images/icon-hubspot.svg';
import iconOutlook from '../assets/images/icon-outlook.svg';
import iconSalesforce from '../assets/images/icon-salesforce-small.svg';
import { IZapierAPIKey } from '../types/integrations';

// Property names
export const API_KEY = 'api_key';
export const USER_EMAIL = 'user_email';
export const CRM_INTEGRATION = 'crm_integration';
export const EMAIL_INTEGRATION = 'email_integration';

// Integration names
export const SALESFORCE = 'salesforce';
export const HUBSPOT = 'hubspot';
export const NONE = 'none';

export const GMAIL = 'gmail';
export const OUTLOOK = 'outlook';
export const BLUEBIRD = 'bluebird';

// Related strings
export const ON = 'ON';
export const OFF = 'OFF';
export const NA = 'NA';

export const crmSupports = [SALESFORCE, HUBSPOT, NONE] as const;

export const emailSupports = [GMAIL, OUTLOOK, BLUEBIRD] as const;

export const userIntegrations = [CRM_INTEGRATION, EMAIL_INTEGRATION] as const;

export const UserIntegrationIcons: { [k: string]: string } = {
  [SALESFORCE]: iconSalesforce,
  [GMAIL]: iconGmail,
  [HUBSPOT]: iconHubspot,
  [OUTLOOK]: iconOutlook,
};

export const INITIAL_ZAPIER_FORM_STATE: IZapierAPIKey = {
  [USER_EMAIL]: '',
  [API_KEY]: '',
};

export const SOB_USER_NO_GMAIL_INTEGRATION_MESSAGE: string =
  "Send on behalf is not available for this user because their email hasn't been integrated. Please contact them directly to enable this feature.";
