import * as React from 'react';
import { config as springConfig, SpringConfig, useSpring } from 'react-spring';

interface IChildProps {
  style: React.CSSProperties;
}

interface IProps {
  children?: (childProps: IChildProps) => React.ReactElement | null;
  trigger: boolean;
  config?: SpringConfig;
  transform?: 'scale' | 'scaleX' | 'scaleY' | 'scaleZ';
}

const ScaleInOut = ({ trigger, children, transform = 'scale', config = springConfig.wobbly }: IProps) => {
  const style = useSpring({
    transform: `${transform}(${trigger ? 1 : 0})`,
    config,
  });

  if (!children) {
    return null;
  }

  return children({ style });
};

export default ScaleInOut;
