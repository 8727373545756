import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as ZapierIcon } from '../../../assets/images/icon-zapier.svg';
import { IOneLink } from '../../../types/oneLink';
import { IReport } from '../../../types/reports';

import styles from './CreatedByLabel.module.scss';

interface IProps {
  className?: string;
  instance: IReport | IOneLink;
  fromZapier?: boolean;
}

const CreatedByLabel = ({ className, instance, fromZapier = false }: IProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <span className={styles.label}>Created by:</span>
      {instance.created_by}
      {fromZapier ? (
        <span className={styles.source}>
          via
          <ZapierIcon />
        </span>
      ) : null}
    </div>
  );
};

export default CreatedByLabel;
