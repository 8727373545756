import cn from 'classnames';
import * as React from 'react';

import Pagination, { IPaginationProps } from '../Pagination/Pagination';

import styles from './PaginatedList.module.scss';

interface IProps<ItemType> {
  items: ItemType[] | null;
  children: (props: IPaginatedListOptions<ItemType>) => React.ReactNode;
  pageSize: number;
  defaultValue?: number;
  onChange?: (event: IPaginatedListChangeEvent) => void;
  isFinalItems?: boolean;
  totalCount: number;
}

export interface IPaginatedListOptions<ItemType> {
  items: ItemType[];
  page: number;
  resetPageCount: () => void;
  pagination: React.FC<Omit<IPaginationProps, 'page' | 'onChange' | 'totalPages'>>;
  changePage: (page: number) => void;
}

export interface IPaginatedListChangeEvent {
  page: number;
  setPage: (p: number) => void;
}

export interface IPaginatedListRef<ItemType> {
  page: number;
  resetPageCount: () => void;
  changePage: (page: number) => void;
  lastNeededElement?: ItemType;
}

const PaginatedList = <ItemType extends unknown>(
  { children, items, pageSize, defaultValue = 1, onChange, isFinalItems, totalCount }: IProps<ItemType>,
  ref: React.Ref<IPaginatedListRef<ItemType>>,
) => {
  const [page, setPage] = React.useState(defaultValue);

  const totalPages = React.useMemo(() => Math.ceil(totalCount / pageSize), [pageSize, totalCount]);

  const getLastNeededItem = React.useCallback(
    (p: number) => {
      const endPosition = p * pageSize;

      if (!items?.length || !items[endPosition - 1]) {
        return;
      }

      return items[endPosition - 1];
    },
    [items, pageSize],
  );

  const handlePaginationChange = React.useCallback(
    (pageNumber: number) => {
      if (onChange) {
        onChange({
          page: pageNumber,
          setPage,
        });
      } else {
        setPage(pageNumber);
      }
    },
    [onChange],
  );

  const resetPageCount = React.useCallback(() => {
    setPage(defaultValue);
  }, [defaultValue]);

  const pagination: React.FC<Omit<IPaginationProps, 'page' | 'onChange' | 'totalPages'>> = React.useMemo(
    () =>
      ({ className, ...restProp }) => {
        return (
          <Pagination
            onChange={handlePaginationChange}
            page={page}
            className={cn(styles.pagination, className)}
            disableNextBtn={isFinalItems}
            totalPages={totalPages}
            {...restProp}
          />
        );
      },
    [handlePaginationChange, page, isFinalItems, totalPages],
  );

  React.useEffect(() => {
    if (page > 1 && isFinalItems && items && !items.length) {
      setPage(page - 1);
    }
  }, [items, isFinalItems, page]);

  React.useImperativeHandle(ref, () => ({
    page,
    resetPageCount,
    changePage: handlePaginationChange,
    lastNeededElement: getLastNeededItem(page - 1),
  }));

  return (
    <React.Fragment>
      {children({
        items: items || [],
        page,
        resetPageCount,
        pagination,
        changePage: handlePaginationChange,
      })}
    </React.Fragment>
  );
};

export default React.forwardRef(PaginatedList) as <ItemType extends unknown>(
  p: IProps<ItemType> & { ref?: React.Ref<IPaginatedListRef<ItemType>> },
) => JSX.Element;
