import { createSelector } from 'reselect';

import { IAppState } from '../reducers';

export const selectRootState = (state: IAppState) => state.fulfillmentCenters;

export const selectFulfillmentCenterItems = createSelector(selectRootState, (fulfillmentCenters) =>
  fulfillmentCenters ? fulfillmentCenters.items : undefined,
);

export const selectIsFulfillmentCentersFetching = createSelector(selectRootState, (fulfillmentCenters) =>
  fulfillmentCenters ? fulfillmentCenters.isLoading : false,
);

export const selectHasItemsWithEnabledShippingOptions = (
  items: { fulfillment_center_id: string }[] | undefined | null,
) =>
  createSelector(selectFulfillmentCenterItems, (fcItems) =>
    items?.some(
      (item) => fcItems?.find((fcItem) => fcItem.uid === item.fulfillment_center_id)?.is_shipping_option_supported,
    ),
  );

export const selectFulfillmentCenterById = (fcId?: string) =>
  createSelector(selectFulfillmentCenterItems, (fcItems) =>
    fcId && fcItems ? fcItems.find((fc) => fc.uid === fcId) : undefined,
  );
