import * as React from 'react';
import { animated, useTransition } from 'react-spring';

interface IProps {
  className?: string;
  items: {
    key: string;
    renderer: React.ReactNode;
  }[];
}

const SlideUpList = ({ className, items }: IProps) => {
  const transitions = useTransition(items, ({ key }) => key, {
    from: { transform: 'translate3d(0,-40px,0)', opacity: 0 },
    enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
    leave: { transform: 'translate3d(0,-40px,0)', opacity: 0 },
    trail: 200 / items.length,
    unique: true,
  });

  return (
    <React.Fragment>
      {transitions.map(({ item, props, key }) => {
        const { renderer } = item || {};
        return (
          <animated.div key={key} style={props} className={className}>
            {renderer}
          </animated.div>
        );
      })}
    </React.Fragment>
  );
};

export default SlideUpList;
