import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SlideUpList from '../../animations/SlideUpList';
import { ReactComponent as GmailIcon } from '../../assets/images/icon-gmail.svg';
import { ReactComponent as HubspotIcon } from '../../assets/images/icon-hubspot.svg';
import { ReactComponent as OutlookIcon } from '../../assets/images/icon-outlook.svg';
import { ReactComponent as SalesforceIcon } from '../../assets/images/icon-salesforce.svg';
import integrationsImage from '../../assets/images/integrations.svg';
import IntegrationPicker from '../../components/IntegrationPicker/IntegrationPicker';
import { GMAIL, HUBSPOT, OUTLOOK, SALESFORCE } from '../../constants/integrations';
import { routes } from '../../constants/routing';
import { ZapierKeyContainer } from '../../containers';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import { crmCheckStart, crmOAuthStart, emailCheckStart, emailOAuthStart } from '../../store/actions/integrations';
import { selectCRMIntegrationStatus, selectEmailIntegrationStatus } from '../../store/selectors/integrations';
import { selectCurrentOrganizationOptions } from '../../store/selectors/organizations';
import { IIntegrationElement, IntegrationsEnum } from '../../types/integrations';
import { IHeaderConfig } from '../../types/shell';

import styles from './Integrations.module.scss';

const Integrations: React.FC = () => {
  const history = useHistory();
  const { setHeader } = useHeaderDispatch();
  const { crm_currently_supports: crmType, email_currently_supports: emailType } =
    useSelector(selectCurrentOrganizationOptions) || {};
  const crmIntegrationStatus = useSelector(selectCRMIntegrationStatus);
  const emailIntegrationStatus = useSelector(selectEmailIntegrationStatus);
  const dispatch = useDispatch();

  const crmsList: { [key: string]: IIntegrationElement } = React.useMemo(() => {
    return {
      [SALESFORCE]: {
        type: IntegrationsEnum.Salesforce,
        title: 'Salesforce Integration',
        icon: SalesforceIcon,
        className: 'salesforce',
        status: crmIntegrationStatus,
      },
      [HUBSPOT]: {
        type: IntegrationsEnum.Hubspot,
        title: 'Hubspot Integration',
        icon: HubspotIcon,
        className: 'hubspot',
        status: crmIntegrationStatus,
      },
    };
  }, [crmIntegrationStatus]);

  const emailsList: { [key: string]: IIntegrationElement } = React.useMemo(() => {
    return {
      [GMAIL]: {
        type: IntegrationsEnum.Gmail,
        title: 'Gmail Integration',
        icon: GmailIcon,
        className: 'gmail-integration',
        status: emailIntegrationStatus,
      },
      [OUTLOOK]: {
        type: IntegrationsEnum.Outlook,
        title: 'Outlook Integration',
        icon: OutlookIcon,
        className: 'outlook-integration',
        status: emailIntegrationStatus,
      },
    };
  }, [emailIntegrationStatus]);

  const integrationsList = React.useMemo(() => {
    const crm = crmType ? crmsList[crmType] : null;
    const email = emailType ? emailsList[emailType] : null;

    return [...(crm && !isEmpty(crm) ? [crm] : []), ...(email && !isEmpty(email) ? [email] : [])];
  }, [crmsList, emailsList, crmType, emailType]);

  const setHeaderInfo = React.useCallback(
    (headerInfo: Partial<IHeaderConfig>) => {
      if (typeof setHeader === 'function') {
        setHeader(headerInfo);
      }
    },
    [setHeader],
  );

  React.useEffect(() => {
    setHeaderInfo({ title: 'Integrations', action: () => history.push(routes.dashboard) });
  }, [history]);

  const checkCRM = React.useCallback(() => {
    dispatch(crmCheckStart());
  }, [dispatch]);

  const checkEmail = React.useCallback(() => {
    dispatch(emailCheckStart());
  }, [dispatch]);

  const addCrmIntegration = React.useCallback(() => {
    dispatch(crmOAuthStart());
  }, [dispatch]);

  const addEmailIntegration = React.useCallback(() => {
    dispatch(emailOAuthStart());
  }, [dispatch]);

  const addIntegration = (type: IntegrationsEnum) => {
    switch (type) {
      case IntegrationsEnum.Hubspot:
      case IntegrationsEnum.Salesforce:
        addCrmIntegration();
        break;
      case IntegrationsEnum.Gmail:
      case IntegrationsEnum.Outlook:
        addEmailIntegration();
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    checkCRM();
    checkEmail();
  }, []);

  return (
    <div className={cn(styles.integration)}>
      <div className={cn(styles.listRow)}>
        {integrationsList && integrationsList.length ? (
          <SlideUpList
            className={styles.listCell}
            items={integrationsList.map(
              ({ className, type, title, icon, status, buttonTitle }: IIntegrationElement) => ({
                key: title,
                renderer: (
                  <IntegrationPicker
                    status={status}
                    className={className}
                    title={title}
                    icon={icon}
                    onClick={() => addIntegration(type)}
                    buttonTitle={buttonTitle}
                  />
                ),
              }),
            )}
          />
        ) : (
          <div className={cn(styles.noIntegrations)}>
            <div className={cn(styles.label)}>No integrations available</div>
            <div className={cn(styles.imgWrapper)}>
              <img src={integrationsImage} alt="" />
            </div>
          </div>
        )}
      </div>
      <ZapierKeyContainer className={styles.zapierContainer} />
    </div>
  );
};

export default Integrations;
