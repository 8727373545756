import { call, put, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { endpoints } from '../../constants/routing';
import { IResponse } from '../../types/http';
import { IReduxAction } from '../../types/redux';
import { IApiError, IImproveTextPayload, ISendFeedbackPayload, ITheme } from '../../types/shell';
import { IGenerateGiftSuggestRequestPayload, IGenerateGiftSuggestResponse } from '../../types/suggestedGift';
import { formatDate } from '../../utils/date';
import { mapAIGiftSuggestionsResponseToInventoryItems } from '../../utils/inventories';
import * as ModalActions from '../actions/modals';
import * as ShellActions from '../actions/shell';
import { AuthRequest } from './helpers';

function* metadataWorkerSaga(): Generator<any, any, any> {
  try {
    const endpoint = `${apiUrl}${endpoints.getBluebirdMetadata}`;

    const response = yield call<any>(AuthRequest, { endpoint });
    if (!response.ok) {
      yield put(ShellActions.fetchMetadataFailure(response.body));
    } else {
      yield put(ShellActions.fetchMetadataSuccess(response.body));
    }
  } catch (error) {
    yield put(ShellActions.fetchMetadataFailure(error));
  }
}

function* themeWorkerSaga() {
  try {
    const endpoint = `${apiUrl}${endpoints.getOrgCustomizationOptions}`;

    const response: IResponse<ITheme | IApiError> = yield call<any>(AuthRequest, { endpoint });
    if (!response.ok) {
      yield put(ShellActions.fetchThemeFailure(response.body as IApiError));
    } else {
      yield put(ShellActions.fetchThemeSuccess(response.body as ITheme));
    }
  } catch (error) {
    yield put(ShellActions.fetchThemeFailure(error));
  }
}

function* sendFeedbackRequestSaga(action: IReduxAction<ISendFeedbackPayload>): Generator<any, any, any> {
  if (action.payload) {
    const { message, resolve, reject } = action.payload;

    try {
      const endpoint = `${apiUrl}${endpoints.sendFeedback}`;

      const response = yield call(AuthRequest, { endpoint, body: JSON.stringify({ message }), method: 'POST' });
      if (!response.ok) {
        if (typeof reject === 'function') {
          reject(response.body);
        }
        yield put(ShellActions.sendFeedbackFailure(response.body));
      } else {
        if (typeof resolve === 'function') {
          resolve(response.body);
        }
        yield put(ShellActions.sendFeedbackSuccess(response.body));
      }
    } catch (error) {
      if (typeof reject === 'function') {
        reject(error);
      }
      yield put(ShellActions.sendFeedbackFailure(error));
    }
  }
}

function* lowInventoryModalWorkerSaga(action: IReduxAction) {
  const currentDate = formatDate(new Date().toDateString());
  // We should set this flag when the modal is closed manually
  if (!action.payload) {
    yield put(ShellActions.updateLowItemsShownAt(currentDate));
  }
}

function* improveTextRequestWorkerSaga(action: IReduxAction<IImproveTextPayload>): Generator<any, any, any> {
  if (action.payload) {
    const { text, temperature, max_tokens, presence_penalty, frequency_penalty, resolve, reject } = action.payload;

    try {
      const endpoint = `${apiUrl}${endpoints.improveText}`;

      const response = yield call(AuthRequest, {
        endpoint,
        body: JSON.stringify({ text, temperature, max_tokens, presence_penalty, frequency_penalty }),
        method: 'POST',
      });
      if (!response.ok) {
        if (typeof reject === 'function') {
          reject(response.body);
        }
        yield put(ShellActions.improveTextFailure(response.body));
      } else {
        if (typeof resolve === 'function') {
          resolve(response.body);
        }
        yield put(ShellActions.improveTextSuccess(response.body));
      }
    } catch (error) {
      if (typeof reject === 'function') {
        reject(error);
      }
      yield put(ShellActions.improveTextFailure(error));
    }
  }
}

function* generateGiftSuggestRequestWorkerSaga(
  action: IReduxAction<IGenerateGiftSuggestRequestPayload>,
): Generator<any, any, any> {
  if (action.payload) {
    const { data, resolve, reject } = action.payload;

    try {
      const endpoint = `${apiUrl}${endpoints.generateGiftSuggest}`;

      const response: IResponse<IGenerateGiftSuggestResponse | IApiError> = yield call(AuthRequest, {
        endpoint,
        body: JSON.stringify({ ...data }),
        method: 'POST',
      });
      if (!response.ok) {
        if (typeof reject === 'function') {
          reject(response.body);
        }
        yield put(ShellActions.generateGiftSuggestFailure(response.body));
      } else {
        const gifts = mapAIGiftSuggestionsResponseToInventoryItems(response.body as IGenerateGiftSuggestResponse);
        if (typeof resolve === 'function') {
          resolve(gifts);
        }
        yield put(ShellActions.generateGiftSuggestSuccess(gifts));
      }
    } catch (error) {
      if (typeof reject === 'function') {
        reject(error);
      }
      yield put(ShellActions.generateGiftSuggestFailure(error));
    }
  }
}

const sagas = {
  *watchMetadataRequest() {
    yield takeLatest(ShellActions.FETCH_METADATA_REQUEST, metadataWorkerSaga);
  },
  *watchThemeRequest() {
    yield takeLatest(ShellActions.FETCH_THEME_REQUEST, themeWorkerSaga);
  },
  *watchSendFeedbackRequest() {
    yield takeLatest(ShellActions.SEND_FEEDBACK_REQUEST, sendFeedbackRequestSaga);
  },
  *watchLowInventoryModal() {
    yield takeLatest(ModalActions.TOGGLE_LOW_INVENTORY_MODAL, lowInventoryModalWorkerSaga);
  },
  *watchImproveTextRequest() {
    yield takeLatest(ShellActions.IMPROVE_TEXT_REQUEST, improveTextRequestWorkerSaga);
  },
  *watchGenerateGiftSuggestRequest() {
    yield takeLatest(ShellActions.GENERATE_GIFT_SUGGEST_REQUEST, generateGiftSuggestRequestWorkerSaga);
  },
};

export default sagas;
