import { RefObject, useEffect } from 'react';

export default (trigger: any, ref: RefObject<HTMLElement>, callback?: (...args: any[]) => void) => {
  useEffect(() => {
    ref?.current?.scrollTo(0, 0);
    if (typeof callback === 'function') {
      callback();
    }
  }, [trigger, callback, ref]);

  return null;
};
