import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  DIGITAL_ITEM_EXISTS_IN_CAMPAIGN_ERROR_MESSAGE,
  HIDE_INVENTORY_ITEM_CHECKBOX_HELP_TEXT,
  HIDE_INVENTORY_ITEM_CHECKBOX_SHORT_LABEL,
  HIDE_INVENTORY_ITEM_LAST_PHYSICAL_WARNING,
  ITEMS,
} from '../../../constants/campaigns';
import { IS_HIDDEN_FOR_RECIPIENT } from '../../../constants/inventories';
import { useCurrency } from '../../../contexts/CurrencyProvider';
import { selectInventoryItems } from '../../../store/selectors/inventories';
import { ICampaignCandidate, ICampaignFormItem, ICampaignItem } from '../../../types/campaigns';
import { ICampaignFormProps } from '../../../types/forms';
import { IInventoryItem, InventoryTypesEnum } from '../../../types/inventories';
import { PositionStylesEnum } from '../../../types/shell';
import {
  getBoxItem,
  getCampaignHasSomeHiddenItems,
  getCanRemoveItemFromCampaign,
  getHasAnyPhysicalItems,
  getIsItemLastVisibleInCampaign,
  hasSomeOfType,
} from '../../../utils/campaigns';
import { isItemDigital, isItemMsku, isSameFC } from '../../../utils/inventories';
import notification from '../../../utils/notification';
import { NoResultsPlaceholder, Price } from '../../index';
import InventoryViewItem from '../../InventoryViewItem/InventoryViewItem';
import { HelpTooltip } from '../../tooltips';
import { Checkbox } from '../inputs';
import QuantityInput from '../inputs/QuantityInput/QuantityInput';

import AvailableItems from '../../AvailableItems/AvailableItems';
import styles from './CampaignItemsForm.module.scss';

const CampaignItemsForm = ({ form, onChange }: ICampaignFormProps<ICampaignCandidate>) => {
  const { getCampaignTotal } = useCurrency();

  const inventoryItems = useSelector(selectInventoryItems());

  const handleItemsChange = React.useCallback(
    (items: ICampaignFormItem[]) => {
      if (typeof onChange === 'function') {
        onChange(ITEMS, items);
      }
      form.setFieldValue(ITEMS, items, true);
    },
    [form?.setFieldValue, onChange],
  );

  const handleAvailableItemsSelect = React.useCallback(
    (item: IInventoryItem | ICampaignItem) => {
      const itemsList = form.values[ITEMS]?.slice();
      const isCampaignContainDigitalItem = hasSomeOfType(itemsList || [], InventoryTypesEnum.Digital);
      const isSelectedItemTypeDigital = isItemDigital(item);

      if (isCampaignContainDigitalItem && isSelectedItemTypeDigital) {
        return notification.error('toast-error-digital-in-campaign', {
          content: DIGITAL_ITEM_EXISTS_IN_CAMPAIGN_ERROR_MESSAGE,
        });
      }

      // Check if item was added earlier
      const requiredItem = itemsList?.find((el: any) => el.item_id === item.item_id);
      if (requiredItem) {
        requiredItem.quantity ? requiredItem.quantity++ : (requiredItem.quantity = 1);
      }

      handleItemsChange(
        requiredItem && itemsList ? itemsList : [...(itemsList || []), { ...getBoxItem(item), quantity: 1 }],
      );
    },
    [form.values, handleItemsChange],
  );

  const handleHideChange = React.useCallback(
    (item: ICampaignFormItem) => {
      const itemsList = form.values[ITEMS]?.slice();
      const requiredItem = itemsList?.find((el: any) => el.item_id === item.item_id);

      if (requiredItem && itemsList) {
        requiredItem[IS_HIDDEN_FOR_RECIPIENT] = !requiredItem[IS_HIDDEN_FOR_RECIPIENT];
        handleItemsChange(itemsList);
      }
    },
    [form.values, handleItemsChange],
  );

  const handleOnDeleteAddedItems = React.useCallback(
    (item: ICampaignFormItem) => {
      const itemsList = form.values[ITEMS]?.slice();
      const requiredItemIndex = itemsList?.findIndex((el: any) => el.item_id === item.item_id);

      if (itemsList && requiredItemIndex !== undefined && requiredItemIndex >= 0) {
        itemsList?.splice(requiredItemIndex, 1);
        handleItemsChange(itemsList);
      }
    },
    [form.values, handleItemsChange],
  );

  const handleOnQuantityChange = React.useCallback(
    (value: number | undefined = 0, item: ICampaignFormItem) => {
      const itemsList = form.values[ITEMS]?.slice();
      const requiredItem = itemsList?.find((el: any) => el.item_id === item.item_id);
      if (requiredItem && itemsList) {
        requiredItem.quantity = value;
        handleItemsChange(itemsList);
      }
    },
    [form.values, handleItemsChange],
  );

  const isHiddenInputShown = React.useMemo(() => {
    return !!(
      getHasAnyPhysicalItems(form.values[ITEMS] || []) || getCampaignHasSomeHiddenItems(form.values[ITEMS] || [])
    );
  }, [form.values[ITEMS]]);

  const isDisabledFunction = (item: IInventoryItem) =>
    Boolean(
      (form.values.items && form.values.items?.length > 0 && !isSameFC(form.values[ITEMS] || [])) ||
        (form.values.items &&
          form.values.items[0] &&
          form.values.items[0].fulfillment_center_id !== item.fulfillment_center_id),
    );

  const addedItemsMarkup = React.useMemo(() => {
    return form.values[ITEMS]?.map((item: ICampaignFormItem) => {
      const isMskuItem = isItemMsku(item);
      const isDigitalItem = isItemDigital(item);
      const isLastVisibleItem = getIsItemLastVisibleInCampaign(form.values[ITEMS], item.item_id);
      const canRemoveItem = getCanRemoveItemFromCampaign(form.values[ITEMS], item.item_id);
      const hideCheckboxMessage = isLastVisibleItem
        ? HIDE_INVENTORY_ITEM_LAST_PHYSICAL_WARNING
        : HIDE_INVENTORY_ITEM_CHECKBOX_HELP_TEXT;

      const { item_id: itemId, item_customization_allowed: isCustomizableItem } = item;

      return (
        <div key={itemId} className={styles.addedItem}>
          <InventoryViewItem
            isOutlined
            item={item}
            className={styles.inventoryItem}
            iconClassName={styles.iconInner}
            showCount={false}
            onRemove={canRemoveItem ? () => handleOnDeleteAddedItems(item) : undefined}
            actions={
              <React.Fragment>
                <div className={styles.quantityInputWrapper}>
                  {!isDigitalItem && (
                    <QuantityInput
                      value={item.quantity!}
                      onChange={(value?: number) => handleOnQuantityChange(value, item)}
                    />
                  )}
                  {!isMskuItem && !isDigitalItem && !isCustomizableItem && isHiddenInputShown && (
                    <div className={styles.hideCheckboxContainer}>
                      <Checkbox
                        checked={isHiddenInputShown && item.is_hidden_for_recipient}
                        onChange={() => handleHideChange(item)}
                        className={isLastVisibleItem ? styles.disabled : ''}
                        disabled={isLastVisibleItem}
                        name={IS_HIDDEN_FOR_RECIPIENT}
                        text={HIDE_INVENTORY_ITEM_CHECKBOX_SHORT_LABEL}
                      />
                      <HelpTooltip
                        className={styles.hideTooltipContainer}
                        contentClassName={styles.helpHideTooltipContent}
                        id={IS_HIDDEN_FOR_RECIPIENT + item.item_id}
                        place={PositionStylesEnum.Bottom}
                      >
                        {hideCheckboxMessage}
                      </HelpTooltip>
                    </div>
                  )}
                </div>
              </React.Fragment>
            }
          />
        </div>
      );
    });
  }, [form.values[ITEMS], inventoryItems, isHiddenInputShown]);

  const boxDetailsPrice = React.useMemo(
    () => getCampaignTotal({ items: form.values?.[ITEMS] }),
    [form.values[ITEMS], getCampaignTotal],
  );

  return (
    <div className={cn(styles.container)}>
      <AvailableItems isDisabledFn={isDisabledFunction} showCount={false} onSelect={handleAvailableItemsSelect} />
      <form
        onSubmit={form.handleSubmit}
        className={cn(styles.addedItemsWrapper, {
          [styles.empty]: !(form.values[ITEMS] && form.values[ITEMS]?.length),
        })}
      >
        <label className={styles.label}>Added Items</label>
        <div className={styles.content}>
          {form.values[ITEMS] && form.values[ITEMS]?.length ? addedItemsMarkup : <NoResultsPlaceholder />}
        </div>
        <div className={styles.totalPrice}>
          <span className={styles.priceLabel}>Campaign Price</span>
          <Price value={boxDetailsPrice} className={styles.price} />
        </div>
      </form>
    </div>
  );
};

export default CampaignItemsForm;
