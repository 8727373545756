import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as CopyIcon } from '../../../../assets/images/icon-copy.svg';
import useWindowSize from '../../../../hooks/useWindowSize';
import { IProps as ICopyableButtonProps } from '../CopyableButton/CopyableButton';
import { CopyableButton } from '../index';

import styles from './ReportsCopyableButton.module.scss';

interface IProps extends ICopyableButtonProps {
  isIcon?: boolean;
  name?: string;
}

const ReportsCopyableButton = ({ className, isIcon, name = 'Copy link', ...restProp }: IProps) => {
  const { mobile } = useWindowSize();

  return (
    <CopyableButton className={cn(styles.copyButton, className)} {...restProp}>
      {isIcon || mobile ? <CopyIcon /> : <span className={cn(styles.text)}>{name}</span>}
    </CopyableButton>
  );
};

export default ReportsCopyableButton;
