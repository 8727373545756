import { IDashboardState, IOrganizationSummariesResponse } from '../../types/dashboard';
import * as AuthActions from '../actions/auth';
import * as DashboardActions from '../actions/dashboard';

const initialState: IDashboardState = {
  summaries: null,
  isLoading: false,
  error: null,
  activeSummary: null,
  filters: {
    dateRange: {
      from: null,
      to: null,
    },
  },
};

const reducer = (state: IDashboardState = initialState, action: any): IDashboardState => {
  switch (action.type) {
    case DashboardActions.FETCH_ORGANIZATION_SUMMARIES_REQUEST: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case DashboardActions.FETCH_ORGANIZATION_SUMMARIES_SUCCESS: {
      const { org_sends_dashboard_summaries: summaries } = action.payload as IOrganizationSummariesResponse;

      return {
        ...state,
        summaries,
        isLoading: false,
      };
    }
    case DashboardActions.FETCH_ORGANIZATION_SUMMARIES_FAILURE: {
      return {
        ...state,
        summaries: null,
        error: action.payload,
        isLoading: false,
      };
    }
    case DashboardActions.SET_ACTIVE_ORGANIZATION_SUMMARY: {
      return {
        ...state,
        activeSummary: action.payload,
      };
    }
    case DashboardActions.SET_DASHBOARD_DATE_RANGE_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          dateRange: action.payload,
        },
      };
    }
    case AuthActions.ADMIN_ROLE_CHANGED: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
