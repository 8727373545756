import { IAddOrgAddressActionPayload, IEditOrgAddressActionPayload, IOrgAddress } from '../../types/addressBook';

export const FETCH_ADDRESS_BOOK_REQUEST = 'FETCH_ADDRESS_BOOK_REQUEST';
export const FETCH_ADDRESS_BOOK_SUCCESS = 'FETCH_ADDRESS_BOOK_SUCCESS';
export const FETCH_ADDRESS_BOOK_FAILURE = 'FETCH_ADDRESS_BOOK_FAILURE';

export const ADD_ORG_ADDRESS_REQUEST = 'ADD_ORG_ADDRESS_REQUEST';
export const ADD_ORG_ADDRESS_SUCCESS = 'ADD_ORG_ADDRESS_SUCCESS';
export const ADD_ORG_ADDRESS_FAILURE = 'ADD_ORG_ADDRESS_FAILURE';

export const EDIT_ORG_ADDRESS_REQUEST = 'EDIT_ORG_ADDRESS_REQUEST';
export const EDIT_ORG_ADDRESS_SUCCESS = 'EDIT_ORG_ADDRESS_SUCCESS';
export const EDIT_ORG_ADDRESS_FAILURE = 'EDIT_ORG_ADDRESS_FAILURE';

export const fetchAddressBookRequest = () => ({
  type: FETCH_ADDRESS_BOOK_REQUEST,
});

export const fetchAddressBookSuccess = (payload?: any) => ({
  type: FETCH_ADDRESS_BOOK_SUCCESS,
  payload,
});

export const fetchAddressBookFailure = (payload?: any) => ({
  type: FETCH_ADDRESS_BOOK_FAILURE,
  payload,
});

export const addOrgAddressRequest = (payload: IAddOrgAddressActionPayload) => ({
  type: ADD_ORG_ADDRESS_REQUEST,
  payload,
});

export const addOrgAddressSuccess = (payload: Partial<IOrgAddress>) => ({
  type: ADD_ORG_ADDRESS_SUCCESS,
  payload,
});

export const addOrgAddressFailure = (payload: any) => ({
  type: ADD_ORG_ADDRESS_FAILURE,
  payload,
});

export const editOrgAddressRequest = (payload: IEditOrgAddressActionPayload) => ({
  type: EDIT_ORG_ADDRESS_REQUEST,
  payload,
});

export const editOrgAddressSuccess = (payload?: Partial<IOrgAddress>) => ({
  type: EDIT_ORG_ADDRESS_SUCCESS,
  payload,
});

export const editOrgAddressFailure = (payload: any) => ({
  type: EDIT_ORG_ADDRESS_FAILURE,
  payload,
});
