import { createTransform } from 'redux-persist';

import { IInventoriesState } from '../../types/inventories';

const inventoriesItemsTransforms = createTransform(
  (stateIn: IInventoriesState) => {
    return {
      ...stateIn,
      items: null,
      csvUpload: {
        file: null,
        result: null,
        isLoading: false,
      },
    };
  },
  (stateOut: IInventoriesState) => stateOut,
  { whitelist: ['inventories'] },
);

const inventoryMetaDataTransforms = createTransform(
  (stateIn: IInventoriesState) => stateIn,
  (stateOut: IInventoriesState) => {
    return {
      ...stateOut,
      error: null,
      isLoading: false,
      isLowInventoryLoading: false,
      isInventoryReportLoading: false,
    };
  },
  { whitelist: ['inventories'] },
);

const transformArray = [inventoriesItemsTransforms, inventoryMetaDataTransforms];

export default transformArray;
