import cn from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';
import { animated, useSpring } from 'react-spring';

import useBluebirdPortal from '../../hooks/useBluebirdPortal';
import { DrawerAnimationDirectionEnum } from '../../types/shell';
import { Overlay } from '../index';

import styles from './Drawer.module.scss';

interface IProps {
  className?: string;
  overlayClassName?: string;
  trigger: boolean;
  onClose: () => void;
  children: React.ReactNode;
  from: string | number;
  to: string | number;
  withOverlay?: boolean;
  withPortal?: boolean;
  direction: DrawerAnimationDirectionEnum;
}

const Drawer = ({
  className,
  children,
  from,
  onClose,
  overlayClassName,
  withOverlay = false,
  to,
  trigger,
  direction,
  withPortal,
}: IProps) => {
  const props = useSpring({
    [direction === DrawerAnimationDirectionEnum.Horizontally ? 'width' : 'height']: trigger ? to : from,
  });

  const { portal } = useBluebirdPortal();

  const isActive = useMemo(() => withOverlay && trigger, [withOverlay, trigger]);

  const content = React.useMemo(
    () => (
      <React.Fragment>
        <animated.div className={cn(styles.container, className)} style={{ ...props }}>
          {children}
        </animated.div>

        <Overlay
          onClick={isActive ? onClose : undefined}
          active={isActive}
          className={cn(styles.overlay, overlayClassName)}
        />
      </React.Fragment>
    ),
    [className, children, isActive, onClose, overlayClassName, props],
  );

  return withPortal ? portal(content) : content;
};

export default Drawer;
