import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { routes } from '../../constants/routing';
import { selectIsAuthenticated, selectIsAuthenticatedInProgress } from '../../store/selectors/auth';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
export default ({ path, ...rest }: RouteProps) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isSignedInProgress = useSelector(selectIsAuthenticatedInProgress);
  const { pathname } = useLocation();

  if (isSignedInProgress) {
    return null;
  }

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: routes.login, search: `?redirect=${pathname}` }} />;
  }

  return <Route {...rest} />;
};
