import { FileError } from 'react-dropzone';

import { fileRejectionErrorCode } from '../constants/upload';
import { formatFileSize } from './bucket';

export const preparedDropZoneErrors = (errors: FileError[]) => {
  return errors.map((errorObj) => {
    const { code, message } = errorObj;
    const preparedMessage = [fileRejectionErrorCode.fileTooLarge, fileRejectionErrorCode.fileTooSmall].includes(code)
      ? (() => {
          /*
           *Default error message is 'File is larger than 5242880 bytes'
           * The size should be got for the custom message
           */
          const size = message.split(' ').slice(-2)[0];
          return `File should not be larger than ${formatFileSize(+size)}`;
        })()
      : message;
    return { code, message: preparedMessage };
  });
};
