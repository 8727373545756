import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { IAddressBookState, IOrgAddress } from '../../types/addressBook';
import * as AddressBookActions from '../actions/addressBook';
import * as AuthActions from '../actions/auth';

export const initialState: IAddressBookState = {
  items: null,
  isLoading: false,
  error: null,
};

const reducer = (state: IAddressBookState = initialState, action: any): IAddressBookState => {
  switch (action.type) {
    case AddressBookActions.FETCH_ADDRESS_BOOK_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case AddressBookActions.FETCH_ADDRESS_BOOK_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        items: null,
      };
    }
    case AddressBookActions.FETCH_ADDRESS_BOOK_SUCCESS: {
      const { address_book: responseItems } = action.payload || [];
      const items = [...(responseItems && responseItems.length ? responseItems : [])];
      return {
        ...state,
        isLoading: false,
        error: null,
        items,
      };
    }
    case AddressBookActions.ADD_ORG_ADDRESS_REQUEST:
    case AddressBookActions.EDIT_ORG_ADDRESS_REQUEST: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case AddressBookActions.ADD_ORG_ADDRESS_FAILURE:
    case AddressBookActions.EDIT_ORG_ADDRESS_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }
    case AddressBookActions.ADD_ORG_ADDRESS_SUCCESS: {
      const items = state.items ? state.items.concat(action.payload) : { ...action.payload };

      return {
        ...state,
        isLoading: false,
        items,
      };
    }
    case AddressBookActions.EDIT_ORG_ADDRESS_SUCCESS: {
      const items = state.items ? state.items.slice() : null;

      const index = items?.findIndex((address: IOrgAddress) => address.uid === action.payload.uid);

      if (typeof index !== 'undefined' && index !== -1) {
        items?.splice(index, 1, action.payload);
      }

      return {
        ...state,
        isLoading: false,
        items,
      };
    }
    case FirebaseActions.LOGOUT:
    case AuthActions.ADMIN_ROLE_CHANGED:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
