import {
  IAddAutomatedCampaignActionPayload,
  IAddAutomatedConnectionActionPayload,
  IAutomatedCampaign,
  IAutomatedConnection,
  IDeleteAutomatedCampaignActionPayload,
  IDeleteAutomatedConnectionActionPayload,
  IUpdateAutomatedCampaignActionPayload,
  IUpdateAutomatedConnectionActionPayload,
  IUpdateAutomatedConnectionRequestActionPayload,
} from '../../types/automations';

export const ADD_AUTOMATED_CONNECTION_REQUEST = 'ADD_AUTOMATED_CONNECTION_REQUEST';
export const ADD_AUTOMATED_CONNECTION_SUCCESS = 'ADD_AUTOMATED_CONNECTION_SUCCESS';
export const ADD_AUTOMATED_CONNECTION_FAILURE = 'ADD_AUTOMATED_CONNECTION_FAILURE';

export const UPDATE_AUTOMATED_CONNECTION_REQUEST = 'UPDATE_AUTOMATED_CONNECTION_REQUEST';
export const UPDATE_AUTOMATED_CONNECTION_SUCCESS = 'UPDATE_AUTOMATED_CONNECTION_SUCCESS';
export const UPDATE_AUTOMATED_CONNECTION_FAILURE = 'UPDATE_AUTOMATED_CONNECTION_FAILURE';

export const DELETE_AUTOMATED_CONNECTION_REQUEST = 'DELETE_AUTOMATED_CONNECTION_REQUEST';
export const DELETE_AUTOMATED_CONNECTION_SUCCESS = 'DELETE_AUTOMATED_CONNECTION_SUCCESS';
export const DELETE_AUTOMATED_CONNECTION_FAILURE = 'DELETE_AUTOMATED_CONNECTION_FAILURE';

export const FETCH_AUTOMATED_CAMPAIGNS_REQUEST = 'FETCH_AUTOMATED_CAMPAIGNS_REQUEST';
export const FETCH_AUTOMATED_CAMPAIGNS_SUCCESS = 'FETCH_AUTOMATED_CAMPAIGNS_SUCCESS';
export const FETCH_AUTOMATED_CAMPAIGNS_FAILURE = 'FETCH_AUTOMATED_CAMPAIGNS_FAILURE';

export const ADD_AUTOMATED_CAMPAIGN_REQUEST = 'ADD_AUTOMATED_CAMPAIGN_REQUEST';
export const ADD_AUTOMATED_CAMPAIGN_SUCCESS = 'ADD_AUTOMATED_CAMPAIGN_SUCCESS';
export const ADD_AUTOMATED_CAMPAIGN_FAILURE = 'ADD_AUTOMATED_CAMPAIGN_FAILURE';

export const UPDATE_AUTOMATED_CAMPAIGN_REQUEST = 'UPDATE_AUTOMATED_CAMPAIGN_REQUEST';
export const UPDATE_AUTOMATED_CAMPAIGN_SUCCESS = 'UPDATE_AUTOMATED_CAMPAIGN_SUCCESS';
export const UPDATE_AUTOMATED_CAMPAIGN_FAILURE = 'UPDATE_AUTOMATED_CAMPAIGN_FAILURE';

export const DELETE_AUTOMATED_CAMPAIGN_REQUEST = 'DELETE_AUTOMATED_CAMPAIGN_REQUEST';
export const DELETE_AUTOMATED_CAMPAIGN_SUCCESS = 'DELETE_AUTOMATED_CAMPAIGN_SUCCESS';
export const DELETE_AUTOMATED_CAMPAIGN_FAILURE = 'DELETE_AUTOMATED_CAMPAIGN_FAILURE';

export const addAutomatedConnectionRequest = (payload: IAddAutomatedConnectionActionPayload) => ({
  type: ADD_AUTOMATED_CONNECTION_REQUEST,
  payload,
});

export const addAutomatedConnectionSuccess = (payload: Partial<IAutomatedConnection>) => ({
  type: ADD_AUTOMATED_CONNECTION_SUCCESS,
  payload,
});

export const addAutomatedConnectionFailure = (payload: any) => ({
  type: ADD_AUTOMATED_CONNECTION_FAILURE,
  payload,
});

export const updateAutomatedConnectionRequest = (payload: IUpdateAutomatedConnectionRequestActionPayload) => ({
  type: UPDATE_AUTOMATED_CONNECTION_REQUEST,
  payload,
});

export const updateAutomatedConnectionSuccess = (payload: IUpdateAutomatedConnectionActionPayload) => ({
  type: UPDATE_AUTOMATED_CONNECTION_SUCCESS,
  payload,
});

export const updateAutomatedConnectionFailure = (payload: any) => ({
  type: UPDATE_AUTOMATED_CONNECTION_FAILURE,
  payload,
});

export const deleteAutomatedConnectionRequest = (payload: IDeleteAutomatedConnectionActionPayload) => ({
  type: DELETE_AUTOMATED_CONNECTION_REQUEST,
  payload,
});

export const deleteAutomatedConnectionSuccess = (payload: { id: string; orgId: string }) => ({
  type: DELETE_AUTOMATED_CONNECTION_SUCCESS,
  payload,
});

export const deleteAutomatedConnectionFailure = (payload: any) => ({
  type: DELETE_AUTOMATED_CONNECTION_FAILURE,
  payload,
});

export const fetchAutomatedCampaignsRequest = () => ({
  type: FETCH_AUTOMATED_CAMPAIGNS_REQUEST,
});

export const fetchAutomatedCampaignsSuccess = (payload?: any) => ({
  type: FETCH_AUTOMATED_CAMPAIGNS_SUCCESS,
  payload,
});

export const fetchAutomatedCampaignsFailure = (payload?: any) => ({
  type: FETCH_AUTOMATED_CAMPAIGNS_FAILURE,
  payload,
});

export const addAutomatedCampaignRequest = (payload: IAddAutomatedCampaignActionPayload) => ({
  type: ADD_AUTOMATED_CAMPAIGN_REQUEST,
  payload,
});

export const addAutomatedCampaignSuccess = (payload: Partial<IAutomatedCampaign>) => ({
  type: ADD_AUTOMATED_CAMPAIGN_SUCCESS,
  payload,
});

export const addAutomatedCampaignFailure = (payload: any) => ({
  type: ADD_AUTOMATED_CAMPAIGN_FAILURE,
  payload,
});

export const updateAutomatedCampaignRequest = (payload: IUpdateAutomatedCampaignActionPayload) => ({
  type: UPDATE_AUTOMATED_CAMPAIGN_REQUEST,
  payload,
});

export const updateAutomatedCampaignSuccess = (payload: Partial<IAutomatedCampaign>) => ({
  type: UPDATE_AUTOMATED_CAMPAIGN_SUCCESS,
  payload,
});

export const updateAutomatedCampaignFailure = (payload: any) => ({
  type: UPDATE_AUTOMATED_CAMPAIGN_FAILURE,
  payload,
});

export const deleteAutomatedCampaignRequest = (payload: IDeleteAutomatedCampaignActionPayload) => ({
  type: DELETE_AUTOMATED_CAMPAIGN_REQUEST,
  payload,
});

export const deleteAutomatedCampaignSuccess = (payload: { id: string }) => ({
  type: DELETE_AUTOMATED_CAMPAIGN_SUCCESS,
  payload,
});

export const deleteAutomatedCampaignFailure = (payload: any) => ({
  type: DELETE_AUTOMATED_CAMPAIGN_FAILURE,
  payload,
});
