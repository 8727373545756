import { useYbugApi } from 'ybug-react';

const useYbugService = () => {
  const YbugContext = useYbugApi();
  const YbugApi = YbugContext?.Ybug;

  const openYbugReport = () => {
    if (YbugApi) {
      YbugApi.open('feedback');
    }
  };

  return { openYbugReport };
};

export default useYbugService;
