import { FormikValues } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { OnChangeValue } from 'react-select';

import { selectOrganizations } from '../../../../store/selectors/organizations';
import { IOrganizationItem } from '../../../../types/organizations';
import { ISelectorValue } from '../../../../types/shell';
import { Selector } from '../../index';

interface IProps extends FormikValues {
  isMulti?: boolean;
  readOnly?: boolean;
  className?: string;
  concreteOption?: string;
}

const OrganizationSelect = ({
  isMulti = true,
  name,
  className,
  value,
  concreteOption,
  onChange,
  readOnly = false,
  helperText,
  ...rest
}: IProps) => {
  const organizations = useSelector(selectOrganizations);
  const isClearable = !(Array.isArray(value) && value.length === 1 && value.includes(concreteOption));

  const formattedValue = React.useMemo<OnChangeValue<ISelectorValue, boolean> | undefined>(() => {
    let result;
    if (Array.isArray(value)) {
      result = (value as string[])?.map((id: string) => {
        const org = organizations ? organizations.find((o) => o.uid === id) : ({} as IOrganizationItem);

        return {
          value: id,
          label: org ? org?.name : '',
        };
      });
    } else if (typeof value === 'string') {
      const org = organizations ? organizations.find((o) => o.uid === value) : ({} as IOrganizationItem);

      result = {
        value: org ? org?.uid : '',
        label: org ? org?.name : '',
      };
    }

    return result;
  }, [value, organizations]);

  const formattedOptions = React.useMemo(
    () => (organizations ? organizations.map((org) => ({ value: org.uid, label: org.name })) : []),
    [organizations],
  );

  const handleChange = React.useCallback(
    (values: any) => {
      if (Array.isArray(values)) {
        if (!values || !values.length) {
          onChange({ currentTarget: { name, value: [] } });
        } else {
          onChange({
            currentTarget: {
              name,
              value: values.map(({ value: newValue }: any) => newValue),
            },
          });
        }
      } else {
        onChange({ currentTarget: { name, value: values?.value || [] } });
      }
    },
    [onChange, name],
  );

  return (
    <Selector
      clearHint="Clear"
      className={className}
      isMulti={isMulti}
      concreteOption={concreteOption}
      isSearchable={false}
      placeholder="Choose Organizations"
      helperText={helperText}
      value={formattedValue}
      options={formattedOptions}
      onChange={handleChange}
      isClearable={isClearable}
      name={name}
      readOnly={readOnly}
      {...rest}
    />
  );
};

export default OrganizationSelect;
