import cn from 'classnames';
import * as React from 'react';

import { IE_HC_URL, IE_PP_URL } from '../../constants/routing';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={cn(styles.footerContainer)}>
      <div className={cn(styles.copyright)}>&copy; Imprint Engine {new Date().getFullYear()}.&nbsp;</div>
      &nbsp;
      <a className={styles.link} href={IE_PP_URL} target="_blank" rel="noopener noreferrer">
        Imprint Engine Privacy Policy
      </a>
      &nbsp;|&nbsp;
      <a className={styles.link} href={IE_HC_URL} target="_blank" rel="noopener noreferrer">
        Imprint Engine Help Center
      </a>
    </footer>
  );
};

export default Footer;
