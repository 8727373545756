import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { ReactComponent as DeleteIcon } from '../../assets/images/icon-delete-red.svg';
import { ReactComponent as CancelIcon } from '../../assets/images/icon-square-red-cross.svg';

import { CAMPAIGNS_TOOLTIP_TEXT } from '../../constants/automations';
import useElementSize from '../../hooks/useElementSize';
import useWindowSize from '../../hooks/useWindowSize';
import { useCampaignSummaries } from '../../service/InventoryService';
import { ICampaign } from '../../types/campaigns';
import { ActionButton, CampaignAutocompleteInput, InputLabel } from '../forms';
import { HelpTooltip } from '../tooltips';

import styles from './CampaignsTable.module.scss';

interface ICampaignsTableProps {
  onChange: (value: string[]) => void;
  value: string[];
  error?: string | string[] | null;
  showErrors?: boolean;
}

interface ICachedBox {
  uid: string;
  name: string;
}

const CampaignsTable: React.FC<ICampaignsTableProps> = ({ onChange, value, showErrors = true, error }) => {
  const [isSearchShown, setIsSearchShown] = React.useState(false);
  const { mobile } = useWindowSize();
  const campaignsCacheRef = React.useRef<{ [uid: ICachedBox['uid']]: ICachedBox }>();
  const tableBodyRef = React.useRef<HTMLDivElement>(null);

  const { height: bodyHeight } = useElementSize({ ref: tableBodyRef });
  const { data: campaignSummaries } = useCampaignSummaries({ campaign_ids: value }, value.length > 0);

  // create a list of campaigns for table from ids
  const campaignsList = useMemo(() => {
    return (campaignSummaries ?? [])
      .filter((campaign) => value.includes(campaign.box_id))
      .map((campaign) => ({
        uid: campaign.box_id,
        name: campaign.name,
      }));
  }, [campaignSummaries, value]);

  React.useEffect(() => {
    const list: ICachedBox[] = (campaignSummaries ?? []).map(
      ({ box_id: uid, name }) => ({ uid, name }),
      [campaignSummaries],
    );

    campaignsCacheRef.current = list?.reduce((acc, { uid, name }) => {
      acc[uid] = { uid, name };
      return acc;
    }, {} as { [uid: string]: ICachedBox });
  }, [campaignSummaries]);

  const campaigns = useMemo(
    () => (campaignsList.length > 0 ? campaignsList : Object.values(campaignsCacheRef.current ?? {})),
    [campaignSummaries],
  );

  const emptyTablePlaceholder = useMemo(() => {
    return <span className={styles.placeholder}>There are no campaigns</span>;
  }, []);

  const label = useMemo(() => {
    const tooltip = (
      <>
        {'Campaigns'}
        <HelpTooltip
          id={`campaigns-info`}
          className={styles.tooltip}
          offset={{ right: mobile ? 0 : 10 }}
          data-border
          data-tip
        >
          {CAMPAIGNS_TOOLTIP_TEXT}
        </HelpTooltip>
      </>
    );
    return <InputLabel required value={tooltip} containerClassName={styles.label} />;
  }, [mobile]);

  const handleAddCampaign = useCallback(() => {
    setIsSearchShown(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsSearchShown(false);
  }, []);

  const handleDeleteCampaign = useCallback(
    (campaign: ICachedBox) => {
      const updatedCampaigns = value.filter((uid) => uid !== campaign.uid);
      onChange(updatedCampaigns);
    },
    [value, onChange],
  );

  const shouldShowValidationError = useMemo(() => showErrors && error, [showErrors, error]);

  const renderValidationError = useMemo(() => {
    if (!shouldShowValidationError) {
      return null;
    }
    return Array.isArray(error) ? (
      error.map((e) => (
        <span key={e} className={styles.error}>
          {e}
        </span>
      ))
    ) : (
      <span className={styles.error}>{error}</span>
    );
  }, [error, shouldShowValidationError]);

  const renderTableRow = React.useCallback(
    (uid: string) => {
      const campaign = campaigns.find((c) => c.uid === uid);

      return campaign ? (
        <div key={uid} className={styles.row}>
          <p>{campaign.name}</p>
          <button type="button" onClick={() => handleDeleteCampaign(campaign)}>
            <DeleteIcon />
          </button>
        </div>
      ) : (
        <div key={uid} className={cn(styles.placeholderRow, styles.row)} />
      );
    },
    [campaigns, handleDeleteCampaign],
  );

  const handleCampaignSelect = useCallback(
    ({ box_id }: ICampaign) => {
      onChange(Array.from(new Set([...value, box_id])));
      setIsSearchShown(false);

      requestAnimationFrame(() => {
        if (tableBodyRef.current) {
          tableBodyRef.current.scrollTo({
            top: tableBodyRef.current.scrollHeight,
            left: 0,
            behavior: 'smooth',
          });
        }
      });
    },
    [onChange, value],
  );

  return (
    <div className={cn(styles.container)}>
      {label}
      <div
        className={cn(styles.table, {
          [styles.errorBorder]: error,
        })}
      >
        <div className={styles.head}>
          {isSearchShown ? (
            <>
              <CampaignAutocompleteInput
                styles={{ dropdown: { maxHeight: bodyHeight - 5 } }}
                className={styles.headElement}
                exclude={value}
                onSelect={handleCampaignSelect}
              />
              <button onClick={handleCancel} type="button">
                <CancelIcon />
              </button>
            </>
          ) : (
            <>
              <span className={cn(styles.headElement, styles.title)}>Title</span>
              <ActionButton onClick={handleAddCampaign} className={styles.addButton}>
                +
              </ActionButton>
            </>
          )}
        </div>
        <div className={styles.body} ref={tableBodyRef}>
          {value.length > 0 && <>{value.map(renderTableRow)}</>}
          {value.length === 0 && !isSearchShown && emptyTablePlaceholder}
        </div>
      </div>
      {renderValidationError}
    </div>
  );
};

export default CampaignsTable;
