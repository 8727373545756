import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import { createSelector } from 'reselect';

import {
  ADD_EMAIL_TEMPLATE_VALIDATION_SCHEMA,
  ADD_NOTE_TEMPLATE_VALIDATION_SCHEMA,
  NO_TEMPLATE,
} from '../../constants/templates';
import { EmailTemplateTypesEnum, TCollectiveTemplateItem, TemplateTypesEnum } from '../../types/templates';
import { getAddTemplateInitialValue } from '../../utils/template';
import { IAppState } from '../reducers';
import { selectAdminType, selectUserDepartmentId } from './auth';

export const selectRootState = (state: IAppState) => state.templates;

export const selectNotes = createSelector(selectRootState, (templates) =>
  templates.notes ? templates.notes : undefined,
);

export const selectEmails = createSelector(selectRootState, (templates) =>
  templates.emails ? templates.emails : undefined,
);

export const selectNoteTemplates = createSelector(selectNotes, (notes) => (notes ? notes.templates : undefined));

export const selectEmailTemplates = createSelector(selectEmails, (emails) => (emails ? emails.templates : undefined));

export const selectAllTemplates = createSelector(
  selectEmailTemplates,
  selectNoteTemplates,
  (emailTemplates, noteTemplates) => {
    const preparedList = <T extends { item_id: string }>(list: T[], templateType: TemplateTypesEnum) =>
      list
        .filter((template) => template.item_id !== NO_TEMPLATE)
        .map((template) => ({
          ...template,
          templateType,
        }));

    const templateList: TCollectiveTemplateItem[] = [
      ...(noteTemplates ? preparedList(noteTemplates, TemplateTypesEnum.Note) : []),
      ...(emailTemplates ? preparedList(emailTemplates, TemplateTypesEnum.Email) : []),
    ];

    return templateList;
  },
);

export const selectNoteTemplatesIsLoading = createSelector(selectNotes, (notes) => (notes ? notes.isLoading : false));
export const selectEmailTemplatesIsLoading = createSelector(selectEmails, (emails) =>
  emails ? emails.isLoading : false,
);

export const selectNoteTemplatesIsLoaded = createSelector(
  selectNotes,
  selectNoteTemplatesIsLoading,
  (notes, isLoading) => notes && notes.templates !== null && !isLoading,
);

export const selectEmailTemplatesIsLoaded = createSelector(
  selectEmails,
  selectEmailTemplatesIsLoading,
  (emails, isLoading) => emails && emails.templates !== null && !isLoading,
);

export const selectTemplatesIsLoaded = createSelector(
  selectNoteTemplatesIsLoaded,
  selectEmailTemplatesIsLoaded,
  (isNoteLoaded, isEmailLoaded) => isNoteLoaded && isEmailLoaded,
);

export const selectEmailDelayedShippingTemplates = createSelector(selectEmailTemplates, (templates) =>
  templates ? templates.filter((template) => template.type === EmailTemplateTypesEnum.DELAYED_SHIPPING) : undefined,
);

export const selectEmailDigitalGiftTemplates = createSelector(selectEmailTemplates, (templates) =>
  templates ? templates.filter((template) => template.type === EmailTemplateTypesEnum.DIGITAL_GIFT) : undefined,
);

export const selectTemplateDetails = createSelector(selectRootState, (templates) => templates.templateDetails);

export const selectTemplateDetailsTemplate = createSelector(selectTemplateDetails, (templateDetails) =>
  templateDetails ? templateDetails.template : undefined,
);

export const selectTemplateIsLoading = createSelector(selectTemplateDetails, (templateDetails) =>
  templateDetails ? templateDetails.isLoading : false,
);

export const selectTemplateValidationSchema = createSelector(selectTemplateDetailsTemplate, (template) => {
  return template && template.templateType === TemplateTypesEnum.Email
    ? ADD_EMAIL_TEMPLATE_VALIDATION_SCHEMA
    : ADD_NOTE_TEMPLATE_VALIDATION_SCHEMA;
});

export const selectTemplateInitialValues = createSelector(
  selectTemplateDetailsTemplate,
  selectAdminType,
  selectUserDepartmentId,
  (templateDetails, adminType, userDepartmentId) => {
    const { templateType = TemplateTypesEnum.Note, ...restObj } = templateDetails || {};

    if (!isEmpty(pickBy(restObj, identity))) {
      return templateDetails!;
    }

    return getAddTemplateInitialValue(templateType, adminType, userDepartmentId);
  },
);

export const selectTemplateById = (itemId: string | undefined) =>
  createSelector(selectNoteTemplates, selectEmailTemplates, (noteTemplates, emailTemplates) => {
    if (!itemId) {
      return null;
    }

    return [
      ...(noteTemplates
        ? noteTemplates.map((template) => ({ ...template, templateType: TemplateTypesEnum.Note }))
        : []),
      ...(emailTemplates
        ? emailTemplates.map((template) => ({ ...template, templateType: TemplateTypesEnum.Email }))
        : []),
    ]?.find((template) => template.item_id === itemId);
  });
