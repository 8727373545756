import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { IToolsState } from '../../types/tools';
import * as AuthActions from '../actions/auth';
import * as ToolsActions from '../actions/tools';

const initialState: IToolsState = {
  engagement: null,
  isLoading: false,
  error: null,
};

const reducer = (state: IToolsState = initialState, action: any): IToolsState => {
  switch (action.type) {
    case ToolsActions.FETCH_ENGAGEMENT_BY_ORDER_ID_REQUEST: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case ToolsActions.FETCH_ENGAGEMENT_BY_ORDER_ID_SUCCESS: {
      const { engagement } = action.payload || {};

      return {
        ...state,
        engagement,
        isLoading: false,
      };
    }
    case ToolsActions.FETCH_ENGAGEMENT_BY_ORDER_ID_FAILURE: {
      return {
        ...state,
        engagement: null,
        error: action.payload,
        isLoading: false,
      };
    }
    case ToolsActions.DELETE_ENGAGEMENT_BY_ORDER_ID_REQUEST: {
      return {
        ...state,
        error: null,
      };
    }
    case ToolsActions.DELETE_ENGAGEMENT_BY_ORDER_ID_SUCCESS: {
      return {
        ...state,
        engagement: null,
      };
    }
    case ToolsActions.DELETE_ENGAGEMENT_BY_ORDER_ID_FAILURE: {
      return {
        ...state,
        engagement: null,
        error: action.payload,
      };
    }
    case ToolsActions.CANCEL_ENGAGEMENT_BY_ORDER_ID_REQUEST: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case ToolsActions.CANCEL_ENGAGEMENT_BY_ORDER_ID_SUCCESS: {
      return {
        ...state,
        engagement: null,
        isLoading: false,
      };
    }
    case ToolsActions.CANCEL_ENGAGEMENT_BY_ORDER_ID_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }
    case ToolsActions.CLEAR_ENGAGEMENT: {
      return {
        ...state,
        engagement: null,
        error: null,
      };
    }
    case FirebaseActions.LOGOUT:
    case AuthActions.ADMIN_ROLE_CHANGED:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
