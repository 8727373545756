import cn from 'classnames';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { ElementType, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { Stepper, WarningMessage } from '../../components';
import { CampaignDetailsForm, CampaignSummaryForm } from '../../components/forms';
import MaintenanceForm from '../../components/forms/MaintenanceForm/MaintenanceForm';
import {
  CampaignSuccessToastMessages,
  CAMPAIGN_DETAILS_VALIDATION_SCHEMA,
  CAMPAIGN_ITEMS_VALIDATION_SCHEMA,
  CAMPAIGN_LABEL,
  CAMPAIGN_SUMMARY_VALIDATION_SCHEMA,
  INITIAL_CAMPAIGN_FORM_STATE,
} from '../../constants/campaigns';
import { CAMPAIGN_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING } from '../../constants/inventories';
import { routes, URL_VARS } from '../../constants/routing';
import useWindowSize from '../../hooks/useWindowSize';
import {
  addCampaignRequest,
  addCampaignValue,
  clearCampaignDetails,
  deleteCampaignRequest,
  editCampaignRequest,
} from '../../store/actions/campaigns';
import { fetchMetadataRequest } from '../../store/actions/shell';
import {
  selectCampaignById,
  selectCampaignDetails,
  selectCampaignInitialValues,
} from '../../store/selectors/campaigns';
import { ICampaign, ICampaignCandidate } from '../../types/campaigns';
import { FormStatusEnum, IMaintenanceFormOutputData, MaintenanceFormStateEnum } from '../../types/forms';
import { ICampaignRouteParams } from '../../types/routing';
import { IApiError, NotificationListEnum } from '../../types/shell';
import { getCampaignFCID } from '../../utils/campaigns';
import { areCampaignsEqual, isObjectsEqual } from '../../utils/helpers';
import { isSameFC } from '../../utils/inventories';
import notification from '../../utils/notification';
import { handleApiError } from '../../utils/ui';
import EditCampaignItemsComponent from './EditCampaignItemsComponent/EditCampaignItemsComponent';
import EditShippingTextComponent from './EditShippingTextComponent/EditShippingTextComponent';

import styles from './EditCampaignContainer.module.scss';

const EditCampaignContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { itemId, flowId, stepId } = useParams<ICampaignRouteParams>();
  const { mobile } = useWindowSize();

  const originalBox = useSelector(selectCampaignById(itemId));
  const boxDetails = useSelector(selectCampaignDetails);
  const initialValues = useSelector(selectCampaignInitialValues);

  const mode = useMemo(() => {
    switch (flowId) {
      case URL_VARS.NEW:
        return MaintenanceFormStateEnum.Adding;
      case URL_VARS.EDIT:
        return MaintenanceFormStateEnum.Editing;
      default:
        return itemId ? MaintenanceFormStateEnum.Reading : MaintenanceFormStateEnum.Adding;
    }
  }, [flowId, itemId]);

  const isAdding = useMemo(() => mode === MaintenanceFormStateEnum.Adding, [mode]);
  const isEditing = useMemo(() => mode === MaintenanceFormStateEnum.Editing, [mode]);
  const isReadOnly = useMemo(() => mode === MaintenanceFormStateEnum.Reading, [mode]);

  const validationSchema = useMemo(() => {
    switch (stepId) {
      case URL_VARS.DETAILS:
        return CAMPAIGN_DETAILS_VALIDATION_SCHEMA;
      case URL_VARS.ITEMS:
        return CAMPAIGN_ITEMS_VALIDATION_SCHEMA;
      default:
        return CAMPAIGN_SUMMARY_VALIDATION_SCHEMA;
    }
  }, [stepId]);

  const isInitialValid = useCallback(
    () => validationSchema.isValidSync(initialValues),
    [validationSchema, initialValues],
  );

  const campaignHasItemsFromDifferentFC = useMemo(() => {
    return !boxDetails?.items?.length ? false : !isSameFC(boxDetails.items);
  }, [boxDetails]);

  const maintenanceFormConfig = useMemo(() => {
    return {
      submit: (() => {
        switch (stepId) {
          case URL_VARS.DETAILS:
          case URL_VARS.TEXT_CUSTOMIZATION:
          case URL_VARS.ITEMS:
            return { disabled: false, label: 'Continue' };
          case URL_VARS.SUMMARY:
            return {
              disabled: campaignHasItemsFromDifferentFC,
              ...(campaignHasItemsFromDifferentFC ? { hint: CAMPAIGN_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING } : {}),
            };
          default:
            return { disabled: void 0, label: void 0 };
        }
      })(),
    };
  }, [campaignHasItemsFromDifferentFC, stepId]);

  const steps = useMemo(
    () => [
      {
        id: URL_VARS.DETAILS,
        route: routes.campaigns.getCampaignUrl({
          flowId,
          itemId,
          stepId: URL_VARS.DETAILS,
        }),
        label: 'Add Campaign',
      },
      {
        id: URL_VARS.ITEMS,
        route: routes.campaigns.getCampaignUrl({
          flowId,
          itemId,
          stepId: URL_VARS.ITEMS,
        }),
        label: 'Choose Items',
      },
      {
        id: URL_VARS.TEXT_CUSTOMIZATION,
        route: routes.campaigns.getCampaignUrl({
          flowId,
          itemId,
          stepId: URL_VARS.TEXT_CUSTOMIZATION,
        }),
        label: `Shipping Page${!mobile ? ' Customization' : ''}`,
      },
      {
        id: URL_VARS.SUMMARY,
        route: routes.campaigns.getCampaignUrl({
          flowId,
          itemId,
          stepId: URL_VARS.SUMMARY,
        }),
        label: 'Confirm Campaign',
      },
    ],
    [flowId, itemId, mobile],
  );

  const submitAction = useMemo(() => {
    switch (flowId) {
      case URL_VARS.NEW:
        return addCampaignRequest;
      case URL_VARS.EDIT:
        return editCampaignRequest;
      default:
        return null;
    }
  }, [flowId]);

  const isValueChanged = useMemo(() => {
    switch (true) {
      case mode === MaintenanceFormStateEnum.Adding:
        return !isObjectsEqual(boxDetails as ICampaignCandidate, INITIAL_CAMPAIGN_FORM_STATE);
      case mode === MaintenanceFormStateEnum.Editing:
        const { intermediateFCID, ...pureBoxDetails } = boxDetails as ICampaignCandidate;
        return !areCampaignsEqual(originalBox, pureBoxDetails);
      default:
        return false;
    }
  }, [originalBox, boxDetails, mode]);

  const handleCampaignSubmit = useCallback(
    (
      campaign: ICampaignCandidate,
      { resetForm, setSubmitting, setStatus, setErrors }: FormikHelpers<ICampaignCandidate>,
    ) => {
      if (!validationSchema?.isValidSync(campaign)) {
        return;
      }

      return new Promise((resolve, reject) => {
        if (submitAction) {
          dispatch(submitAction({ campaign, resolve, reject }));
        }
      })
        .then(() => {
          if (resetForm) {
            resetForm();
          }
          if (setStatus) {
            setStatus(FormStatusEnum.Success);
          }
          notification.success(NotificationListEnum.Success, { content: CampaignSuccessToastMessages[flowId] });
          history.push(routes.campaigns.root);
        })
        .catch((e: IApiError) => {
          setErrors({ api: e?.message } as FormikErrors<ICampaignCandidate>);
          setStatus(FormStatusEnum.Error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [flowId, dispatch, history, submitAction, validationSchema],
  );

  const handleDetailsSubmit = useCallback(
    (values: ICampaignCandidate, { setSubmitting }: FormikHelpers<ICampaignCandidate>) => {
      setSubmitting(false);
      history.push(
        routes.campaigns.getCampaignUrl({
          flowId,
          itemId,
          stepId: URL_VARS.ITEMS,
        }),
      );
    },
    [history, flowId, itemId],
  );

  const handleItemsSubmit = useCallback(
    (values: ICampaignCandidate, { setSubmitting }: FormikHelpers<ICampaignCandidate>) => {
      setSubmitting(false);
      history.push(
        routes.campaigns.getCampaignUrl({
          flowId,
          itemId,
          stepId: URL_VARS.TEXT_CUSTOMIZATION,
        }),
      );
    },
    [history, flowId, itemId],
  );

  const handleShippingTextSubmit = useCallback(
    (values: ICampaignCandidate, { setSubmitting }: FormikHelpers<ICampaignCandidate>) => {
      setSubmitting(false);
      history.push(
        routes.campaigns.getCampaignUrl({
          flowId,
          itemId,
          stepId: URL_VARS.SUMMARY,
        }),
      );
    },
    [history, flowId, itemId],
  );

  const {
    StepComponent,
    onSubmit,
  }: {
    StepComponent: ElementType;
    onSubmit: (values: ICampaignCandidate, formikHelpers: FormikHelpers<ICampaignCandidate>) => void | Promise<any>;
  } = useMemo((): {
    StepComponent: ElementType;
    onSubmit: (values: ICampaignCandidate, formikHelpers: FormikHelpers<ICampaignCandidate>) => void | Promise<any>;
  } => {
    switch (stepId) {
      case URL_VARS.DETAILS:
        return { StepComponent: CampaignDetailsForm, onSubmit: handleDetailsSubmit };
      case URL_VARS.ITEMS:
        return { StepComponent: EditCampaignItemsComponent, onSubmit: handleItemsSubmit };
      case URL_VARS.TEXT_CUSTOMIZATION:
        return { StepComponent: EditShippingTextComponent, onSubmit: handleShippingTextSubmit };
      default:
        return { StepComponent: CampaignSummaryForm, onSubmit: handleCampaignSubmit };
    }
  }, [stepId, handleDetailsSubmit, handleItemsSubmit, handleCampaignSubmit, handleShippingTextSubmit]);

  const form = useFormik<ICampaignCandidate>({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    isInitialValid,
  });

  const handleFieldChange = React.useCallback(
    (name: keyof ICampaign, value: string | number | undefined) => {
      form.setFieldValue(name, value);

      dispatch(addCampaignValue({ [name]: value }));
    },
    [dispatch, form.setFieldValue],
  );

  const handleDeleteItem = useCallback(
    (uid: string) => (closeModal?: () => void, id?: string, newCampaignId?: string) =>
      new Promise((resolve, reject) => {
        dispatch(deleteCampaignRequest({ uid, resolve, reject }));
      })
        .then(() => {
          history.push(routes.campaigns.root);
          notification.success(NotificationListEnum.Success, { content: 'Campaign was successfully deleted' });
        })
        .catch(handleApiError(`Something bad happened. Campaign wasn't deleted.`))
        .finally(() => {
          if (typeof closeModal === 'function') {
            closeModal();
          }
        }),
    [dispatch, history],
  );

  const handleDiscardItem = useCallback(() => {
    history.push(routes.campaigns.root);
  }, [history]);

  const handleCancel = useCallback(() => {
    if (originalBox) {
      dispatch(addCampaignValue(originalBox));
    }
  }, [dispatch, originalBox]);

  const campaignFC = React.useMemo(() => {
    return getCampaignFCID(form.values.items || []);
  }, [form.values.items]);

  useEffect(() => {
    if (isEmpty(boxDetails)) {
      if (isAdding) {
        dispatch(addCampaignValue(INITIAL_CAMPAIGN_FORM_STATE));
      }
      if ((isEditing || isReadOnly) && originalBox) {
        dispatch(addCampaignValue(originalBox));
      }
    }
  }, [dispatch, originalBox, boxDetails, isEditing, isReadOnly, isAdding]);

  useEffect(() => {
    dispatch(fetchMetadataRequest());

    return () => {
      dispatch(clearCampaignDetails());
    };
  }, [dispatch]);

  return isReadOnly && stepId && itemId ? (
    <Redirect
      to={routes.campaigns.getCampaignUrl({
        flowId: URL_VARS.VIEW,
        itemId,
      })}
    />
  ) : (
    <div className={cn(styles.container, styles[stepId ? stepId : ''])}>
      {!isReadOnly && (
        <Stepper
          steps={steps}
          disabled={!form.isValid || form.isSubmitting}
          active={stepId}
          className={styles.stepper}
        />
      )}
      {campaignHasItemsFromDifferentFC && (
        <WarningMessage
          className={styles.warning}
          mode="error"
          message={CAMPAIGN_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING}
        />
      )}
      <MaintenanceForm
        config={maintenanceFormConfig}
        controlsClassName={styles.content}
        root={routes.campaigns.root}
        assetName={CAMPAIGN_LABEL}
        form={form}
        mode={mode}
        onCancel={handleCancel}
        onDelete={handleDeleteItem(boxDetails?.box_id!)}
        onDiscard={handleDiscardItem}
        onSave={form.isValid ? handleCampaignSubmit : undefined}
        isValueChanged={isValueChanged}
        showControls={!(!campaignFC && stepId === URL_VARS.ITEMS)}
      >
        {(props: IMaintenanceFormOutputData) => (
          <StepComponent form={form} {...props} readOnly={isReadOnly} onChange={handleFieldChange} />
        )}
      </MaintenanceForm>
    </div>
  );
};

export default EditCampaignContainer;
