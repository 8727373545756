import { ITEM_ID } from '../../../constants/inventories';
import { IInventoryItem } from '../../../types/inventories';
import { convertArrayToObject } from '../../../utils/helpers';

export default (state: any) => {
  const { items, itemDetails, error } = state.inventory;

  return {
    ...state,
    inventories: {
      items: items && items.length ? convertArrayToObject<IInventoryItem, typeof ITEM_ID>(items, ITEM_ID) : null,
      itemDetails,
      error,
    },
  };
};
