import * as React from 'react';

import { useFirebase } from 'react-redux-firebase';

import styles from './ResetPasswordModal.module.scss';

import { ReactComponent as CloseIcon } from '../../../assets/images/icon-close-cross.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/images/icon-success-check.svg';

import { SUBMIT_BUTTON } from '../../../types/forms';
import { handleApiError } from '../../../utils/ui';
import { ActionButton, Input } from '../../forms';
import { Loader } from '../../index';

interface IProps {
  onClose: () => void;
}

const ResetPasswordModal: React.FC<IProps> = (props) => {
  const firebase = useFirebase();

  const [email, setEmail] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setSuccess(true);
      })
      .catch(handleApiError(`Something bad happened. Password wasn't reset.`))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const redirectToGmail = () => {
    window.open('https://mail.google.com/', '_blank');
  };

  const questionUI = React.useMemo(() => {
    return (
      <form className={styles.inner} onSubmit={handleSubmit}>
        <span className={styles.title}>Password Reset</span>
        <span className={styles.infoText}>
          Input your email and we’ll send you instructions to reset your password.
        </span>
        <Input
          className={styles.input}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type={'email'}
          helperText={'Your email'}
          placeholder={'Enter your email'}
        />
        <ActionButton title={'Continue'} type={SUBMIT_BUTTON} disabled={!email} />
      </form>
    );
  }, [email]);

  const successUI = React.useMemo(() => {
    return (
      <div className={styles.inner}>
        <span className={styles.title}>Password Reset</span>
        <span className={styles.infoText}>We’ve sent the email with the instructions!</span>
        <SuccessIcon />
        <ActionButton title={'Check my email'} onClick={redirectToGmail} />
      </div>
    );
  }, []);

  return (
    <div className={styles.resetPasswordModal}>
      <Loader isLoading={isLoading} />
      <div className={styles.closeButton} onClick={props.onClose}>
        <CloseIcon />
      </div>
      {success ? successUI : questionUI}
    </div>
  );
};

export default ResetPasswordModal;
