import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectDigitalBudgetValue } from '../../../store/selectors/auth';
import { selectCurrentOrganizationDigitalBalanceLimit } from '../../../store/selectors/organizations';
import { FeatureBudgetEnum } from '../../../types/shell';
import FeatureBudget, { IFeatureBudgetProps } from '../FeatureBudget';

const DigitalBudget: FC<Pick<IFeatureBudgetProps, 'className' | 'onLowBudget'>> = (props) => {
  const digitalBudgetLimit = useSelector(selectCurrentOrganizationDigitalBalanceLimit);
  const digitalBudgetValue = useSelector(selectDigitalBudgetValue);

  return (
    <FeatureBudget
      {...props}
      name={FeatureBudgetEnum.Digital}
      hint="Digital gift balance remaining"
      budgetValue={digitalBudgetValue}
      label="Digital Card Budget:"
      warningValue={digitalBudgetLimit}
    />
  );
};

export default DigitalBudget;
