import { call, put, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { endpoints, queryParams } from '../../constants/routing';
import {
  IAddCampaignRequest,
  IAddCampaignResponse,
  IDeleteCampaignRequest,
  IDeleteCampaignResponse,
  IEditCampaignRequest,
  IEditCampaignResponse,
  IFetchCampaignByIdRequestPayload,
  IFetchCampaignsRequest,
  IFetchCampaignsResponse,
  IFetchCampaignSummariesRequestPayload,
  IFetchCampaignSummariesResponsePayload,
} from '../../types/campaigns';
import { IResponse } from '../../types/http';
import { IReduxAction } from '../../types/redux';
import { IApiError } from '../../types/shell';
import { mapCampaignItemsForEditCampaignRequest } from '../../utils/campaigns';
import { getQuery } from '../../utils/request';
import * as CampaignsActions from '../actions/campaigns';
import { AuthRequest } from './helpers';

function* campaignsWorkerSaga(action: IReduxAction<IFetchCampaignsRequest>) {
  try {
    const endpoint = `${apiUrl}${endpoints.getCampaigns}`;

    const response: IResponse<IFetchCampaignsResponse | IApiError> = yield call<any>(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify(action.payload),
    });

    if (!response.ok) {
      yield put(CampaignsActions.fetchCampaignsFailure(response.body as IApiError));
    } else {
      yield put(CampaignsActions.fetchCampaignsSuccess(response.body as IFetchCampaignsResponse));
    }
  } catch (error) {
    yield put(CampaignsActions.fetchCampaignsFailure(error));
  }
}

function* campaignsByIdWorkerSaga(action: IReduxAction<IFetchCampaignByIdRequestPayload>) {
  const { campaign_id, resolve, reject } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.getCampaignById}`;
    const qs = {
      ...(campaign_id ? getQuery(campaign_id, queryParams.campaignId) : {}),
    };

    const response: IResponse<any | IApiError> = yield call<any>(AuthRequest, { endpoint, queryParams: qs });

    if (!response.ok) {
      if (reject) {
        reject(response.body);
      }
      yield put(CampaignsActions.fetchCampaignByIdFailure(response.body as IApiError));
    } else {
      if (resolve) {
        resolve(response.body);
      }
      yield put(CampaignsActions.fetchCampaignByIdSuccess(response.body));
    }
  } catch (error) {
    if (reject) {
      reject(error);
    }
    yield put(CampaignsActions.fetchCampaignsFailure(error));
  }
}

function* addCampaignWorkerSaga(action: IReduxAction<IAddCampaignRequest>) {
  const { campaign, resolve, reject } = action.payload || {};
  const { ds_header_color, ds_button_color, ds_shipping_page_title, ds_shipping_page_body, ...campaignData } =
    campaign || {};

  try {
    const endpoint = `${apiUrl}${endpoints.addCampaign}`;
    const response: IResponse<IAddCampaignResponse | IApiError> = yield call<any>(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({
        campaign: {
          ...campaignData,
          org_id: undefined,
          items: campaign?.items?.map((item) => ({
            quantity: item.quantity,
            item_id: item.item_id,
            is_hidden_for_recipient: item.is_hidden_for_recipient,
          })),
          ...(ds_header_color ? { ds_header_color } : {}),
          ...(ds_button_color ? { ds_button_color } : {}),
          ...(ds_shipping_page_body ? { ds_shipping_page_body } : {}),
          ...(ds_shipping_page_title ? { ds_shipping_page_title } : {}),
        },
      }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(CampaignsActions.addCampaignFailure(response.body as IApiError));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(CampaignsActions.addCampaignSuccess(response.body as IAddCampaignResponse));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(CampaignsActions.addCampaignFailure(error));
  }
}

function* editCampaignWorkerSaga(action: IReduxAction<IEditCampaignRequest>) {
  const { campaign, resolve, reject } = action.payload || {};
  const { ds_header_color, ds_button_color, ds_shipping_page_title, ds_shipping_page_body, ...campaignData } =
    campaign || {};

  try {
    const endpoint = `${apiUrl}${endpoints.editCampaign}`;
    const response: IResponse<IEditCampaignResponse | IApiError> = yield call<any>(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({
        campaign: {
          ...campaignData,
          uid: campaign?.box_id,
          org_id: undefined,
          items: campaign?.items?.map(mapCampaignItemsForEditCampaignRequest),
          ...(ds_header_color ? { ds_header_color } : {}),
          ...(ds_button_color ? { ds_button_color } : {}),
          ...(ds_shipping_page_body ? { ds_shipping_page_body } : {}),
          ...(ds_shipping_page_title ? { ds_shipping_page_title } : {}),
        },
      }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(CampaignsActions.editCampaignFailure(response.body as IApiError));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(CampaignsActions.editCampaignSuccess(response.body as IEditCampaignResponse));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(CampaignsActions.editCampaignFailure(error));
  }
}

function* deleteCampaignWorkerSaga(action: IReduxAction<IDeleteCampaignRequest>) {
  const { uid, resolve, reject } = action.payload || {};
  try {
    const endpoint = `${apiUrl}${endpoints.deleteCampaign}`;
    const qs = {
      ...(uid ? { [queryParams.uid]: uid } : {}),
    };

    const response: IResponse<IDeleteCampaignResponse | IApiError> = yield call<any>(AuthRequest, {
      queryParams: qs,
      endpoint,
      method: 'DELETE',
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(CampaignsActions.deleteCampaignFailure(response.body as IApiError));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(CampaignsActions.deleteCampaignSuccess({ uid } as IDeleteCampaignResponse));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(CampaignsActions.deleteCampaignFailure(error));
  }
}

function* fetchCampaignSummariesWorkerSaga(action: IReduxAction<IFetchCampaignSummariesRequestPayload>) {
  try {
    const { campaign_ids } = action.payload!;
    const endpoint = `${apiUrl}${endpoints.getCampaignSummaries}`;

    const response: IResponse<IFetchCampaignSummariesResponsePayload | IApiError> = yield call<any>(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({ campaign_ids }),
    });

    if (!response.ok) {
      yield put(CampaignsActions.fetchCampaignSummariesFailure(response.body as IApiError));
    } else {
      yield put(
        CampaignsActions.fetchCampaignSummariesSuccess(response.body as IFetchCampaignSummariesResponsePayload),
      );
    }
  } catch (error) {
    yield put(CampaignsActions.fetchCampaignSummariesFailure(error));
  }
}

const sagas = {
  *watchCampaignsRequest() {
    yield takeLatest(CampaignsActions.FETCH_CAMPAIGNS_REQUEST, campaignsWorkerSaga);
  },
  *watchCampaignByIdRequest() {
    yield takeLatest(CampaignsActions.FETCH_CAMPAIGN_BY_ID_REQUEST, campaignsByIdWorkerSaga);
  },
  *watchAddCampaignRequest() {
    yield takeLatest(CampaignsActions.ADD_CAMPAIGN_REQUEST, addCampaignWorkerSaga);
  },
  *watchEditCampaignRequest() {
    yield takeLatest(CampaignsActions.EDIT_CAMPAIGN_REQUEST, editCampaignWorkerSaga);
  },
  *watchFetchCampaignSummariesRequest() {
    yield takeLatest(CampaignsActions.FETCH_CAMPAIGN_SUMMARIES_REQUEST, fetchCampaignSummariesWorkerSaga);
  },
  *watchDeleteCampaignRequest() {
    yield takeLatest(CampaignsActions.DELETE_CAMPAIGN_REQUEST, deleteCampaignWorkerSaga);
  },
};

export default sagas;
