import * as yup from 'yup';

import { ReactComponent as DeliveryIcon } from '../assets/images/icon-delivery-man.svg';
import { ReactComponent as GroundDeliveryIcon } from '../assets/images/icon-fast-delivery.svg';
import { ReactComponent as OvernightIcon } from '../assets/images/icon-fast-time.svg';
import { IOneLinkFormFields } from '../types/bucket';
import { IRadioButtonOption } from '../types/forms';
import { ISendDetailsFormValues, IShippingFormValues, ShippingOptionsEnum } from '../types/shipping';
import { USA } from './countries';
import { EMAIL_VALIDATION_REGEX } from './shell';

const EMAIL_DOMAIN_REGEX = /^$|^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;

export const SHIPPING = 'shipping';

export const LEAD = 'Lead';
export const CONTACT = 'Contact';

export const DELAYED_SHIPPING_URL = 'delayed_shipping_url';
export const TRACKING_URL = 'tracking_url';
export const SHIPPED_DATE = 'shipped_date';

export const RECEIVER_FIRST_NAME = 'receiver_first_name';
export const RECEIVER_LAST_NAME = 'receiver_last_name';
export const RECEIVER_COMPANY_NAME = 'receiver_company_name';
export const RECEIVER_CRM_RECORD_ID = 'receiver_crm_record_id';
export const RECEIVER_CRM_RECORD_TYPE = 'receiver_crm_record_type';
export const RECEIVER_CRM_OPPORTUNITY_ID = 'receiver_crm_opportunity_id';
export const RECEIVER_PHONE = 'receiver_phone';
export const RECEIVER_ADDRESS1 = 'receiver_address1';
export const RECEIVER_ADDRESS2 = 'receiver_address2';
export const RECEIVER_STATE = 'receiver_state';
export const RECEIVER_ZIP = 'receiver_zip';
export const RECEIVER_COUNTRY = 'receiver_country';
export const RECEIVER_CITY = 'receiver_city';
export const RECEIVER_EMAIL = 'receiver_email';
export const SHIP_ORDER_STATUS = 'ship_order_status';
export const SHIPPING_OPTION = 'shipping_option';
export const CRM_TYPE = 'crm_type';
export const IS_RECEIVER_ADDRESS_FIXED = 'is_receiver_address_fixed';
export const RECEIVER_FIXED_ADDRESS = 'receiver_fixed_address';

export const AWAITING_ADDRESS = 'awaiting_address';

export const SENDER_ADDRESS1 = 'sender_address1';
export const SENDER_FIRST_NAME = 'sender_first_name';
export const SENDER_CITY = 'sender_city';
export const SENDER_COUNTRY = 'sender_country';
export const SENDER_PHONE = 'sender_phone';
export const SENDER_LAST_NAME = 'sender_last_name';
export const SENDER_ZIP = 'sender_zip';
export const SENDER_ADDRESS2 = 'sender_address2';
export const SENDER_STATE = 'sender_state';
export const SENDER_COMPANY_NAME = 'sender_company_name';
export const SENDER_EMAIL = 'sender_email';
export const DEPARTMENT_ID = 'department_id';
export const EXPIRATION_DATE = 'expiration_date';
export const DISABLE_SENDER_EMAIL_NOTIFICATIONS = 'disable_sender_email_notifications';
export const DISABLE_EMAIL_NOTIFICATIONS = 'disable_email_notifications';
export const PASSWORD = 'password';
export const ALLOWED_DOMAIN = 'allowed_domain';
export const ENABLED_2FA = 'enabled_2fa';
export const RECIPIENTS = 'recipients';
export const REDEMPTION_LIMIT = 'max_submit_count';
export const RECIPIENTS_COUNT = 'recipients_count';

export const PRINT_YOUR_CAUSE_FC_ID = 'PrintYourCause';
export const PRINT_YOUR_CAUSE_UK_FC_ID = 'PrintYourCauseUK';

export const ADDRESS1 = 'address1';
export const ADDRESS2 = 'address2';
export const CITY = 'city';
export const STATE = 'state';
export const ZIP = 'zip';
export const COUNTRY = 'country';

const fieldNamesForErrorOutput = {
  [RECEIVER_FIRST_NAME]: 'First Name',
  [RECEIVER_LAST_NAME]: 'Last Name',
  [RECEIVER_COMPANY_NAME]: 'Company Name',
  [RECEIVER_PHONE]: 'Phone',
  [RECEIVER_ADDRESS1]: 'Address 1',
  [RECEIVER_ADDRESS2]: 'Address 2',
  [RECEIVER_STATE]: 'State',
  [RECEIVER_ZIP]: 'ZIP Code',
  [RECEIVER_COUNTRY]: 'Country',
  [RECEIVER_CITY]: 'City',
  [RECEIVER_EMAIL]: 'Email',
  [ADDRESS1]: 'Address 1',
  [CITY]: 'City',
  [ZIP]: 'ZIP Code',
  [COUNTRY]: 'Country',
  [STATE]: 'State',
};

export const SHIPPING_OPTIONS: IRadioButtonOption[] = [
  { label: 'Ground', value: ShippingOptionsEnum.Ground, icon: GroundDeliveryIcon },
  { label: '2 day', value: ShippingOptionsEnum.TwoDays, icon: DeliveryIcon },
  { label: '3 day', value: ShippingOptionsEnum.ThreeDays, icon: DeliveryIcon },
  { label: 'Overnight', value: ShippingOptionsEnum.OverNight, icon: OvernightIcon },
];

export const SHIPPING_NOTIFICATIONS_OPTIONS = {
  SENDER: {
    TEXT: 'Disable Sender Notifications',
    TEXT_ABOUT:
      `If the box is checked, Imprint Engine will not generate email notifications with updates on every send.` +
      ` ` +
      `You will still be able to check the status of each one within the One Link Manager.`,
  },
};

export const INITIAL_SHIPPING_FORM_STATE: IShippingFormValues = {
  [RECEIVER_FIRST_NAME]: '',
  [RECEIVER_LAST_NAME]: '',
  [RECEIVER_COMPANY_NAME]: '',
  [RECEIVER_CRM_RECORD_ID]: '',
  [RECEIVER_CRM_RECORD_TYPE]: '',
  [RECEIVER_CRM_OPPORTUNITY_ID]: '',
  [RECEIVER_PHONE]: '',
  [RECEIVER_ADDRESS1]: '',
  [RECEIVER_ADDRESS2]: '',
  [RECEIVER_STATE]: '',
  [RECEIVER_ZIP]: '',
  [RECEIVER_EMAIL]: '',
  [RECEIVER_COUNTRY]: '',
  [RECEIVER_CITY]: '',
  [SHIP_ORDER_STATUS]: '', // TODO: Add the feature flag or remote-controlled feature flag
  [IS_RECEIVER_ADDRESS_FIXED]: false,
  [SHIPPING_OPTION]: ShippingOptionsEnum.Ground,
  [DISABLE_SENDER_EMAIL_NOTIFICATIONS]: false,
};

export const INITIAL_SEND_SUMMARY_FORM_STATE: ISendDetailsFormValues = {
  [RECEIVER_FIRST_NAME]: '',
  [RECEIVER_LAST_NAME]: '',
  [RECEIVER_COMPANY_NAME]: '',
  [RECEIVER_PHONE]: '',
  [RECEIVER_ADDRESS1]: '',
  [RECEIVER_ADDRESS2]: '',
  [RECEIVER_STATE]: '',
  [RECEIVER_ZIP]: '',
  [RECEIVER_COUNTRY]: '',
  [RECEIVER_CITY]: '',
  [SHIP_ORDER_STATUS]: '',
  [SHIPPING_OPTION]: ShippingOptionsEnum.Ground,
  note_message: '',
};

export const INITIAL_SHIPPING_FORM_STATE_NO_CRM: IShippingFormValues = {
  [RECEIVER_FIRST_NAME]: '',
  [RECEIVER_LAST_NAME]: '',
  [RECEIVER_COMPANY_NAME]: '',
  [RECEIVER_PHONE]: '',
  [RECEIVER_ADDRESS1]: '',
  [RECEIVER_ADDRESS2]: '',
  [RECEIVER_STATE]: '',
  [RECEIVER_ZIP]: '',
  [RECEIVER_EMAIL]: '',
  [RECEIVER_COUNTRY]: '',
  [RECEIVER_CITY]: '',
  [SHIP_ORDER_STATUS]: '', // TODO: Add the feature flag or remote-controlled feature flag
  [IS_RECEIVER_ADDRESS_FIXED]: false,
  [SHIPPING_OPTION]: ShippingOptionsEnum.Ground,
  [DISABLE_SENDER_EMAIL_NOTIFICATIONS]: false,
};

/*
 * Validation Schema for edit engagement sidebar
 * Non-delayed shipping
 */
export const SEND_DETAILS_FORM_VALIDATION_SCHEMA = yup.object().shape({
  [RECEIVER_FIRST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_FIRST_NAME]).required().trim(),
  [RECEIVER_LAST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_LAST_NAME]).required().trim(),
  [RECEIVER_COMPANY_NAME]: yup.string().trim(),
  [RECEIVER_PHONE]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_PHONE]).required().trim(),
  [RECEIVER_ADDRESS1]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_ADDRESS1]).required().trim(),
  [RECEIVER_ADDRESS2]: yup.string().trim(),
  [RECEIVER_STATE]: yup.string().trim(),
  [RECEIVER_ZIP]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_ZIP]).required().trim(),
  [RECEIVER_COUNTRY]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_COUNTRY]).required().trim(),
  [RECEIVER_CITY]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_CITY]).required().trim(),
  [SHIP_ORDER_STATUS]: yup.string().trim(),
  [SHIPPING_OPTION]: yup.string().default(ShippingOptionsEnum.Ground).required(),
  note_message: yup.string().max(250).trim(),
});

/*
 * Validation Schema for edit engagement sidebar
 * Delayed shipping
 */
export const SIMPLIFIED_SEND_DETAILS_FORM_VALIDATION_SCHEMA = yup.object().shape({
  [RECEIVER_FIRST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_FIRST_NAME]).required().trim(),
  [RECEIVER_LAST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_LAST_NAME]).required().trim(),
  [RECEIVER_COMPANY_NAME]: yup.string().trim(),
  [RECEIVER_PHONE]: yup.string().trim(),
  [RECEIVER_ADDRESS1]: yup.string().trim(),
  [RECEIVER_ADDRESS2]: yup.string().trim(),
  [RECEIVER_STATE]: yup.string().trim(),
  [RECEIVER_ZIP]: yup.string().trim(),
  [RECEIVER_COUNTRY]: yup.string().trim(),
  [RECEIVER_CITY]: yup.string().trim(),
  [SHIP_ORDER_STATUS]: yup.string().oneOf([AWAITING_ADDRESS]).required(),
  [SHIPPING_OPTION]: yup.string().default(ShippingOptionsEnum.Ground).required(),
  note_message: yup.string().max(250).trim(),
});

/*
 * Validation Schema for single send
 * Non-delayed shipping
 */
export const SHIPPING_VALIDATION_SCHEMA = yup.object().shape({
  [RECEIVER_FIRST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_FIRST_NAME]).required().trim(),
  [RECEIVER_LAST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_LAST_NAME]).required().trim(),
  [RECEIVER_COMPANY_NAME]: yup.string().trim(),
  [RECEIVER_CRM_RECORD_ID]: yup.string().required().trim(),
  [RECEIVER_CRM_RECORD_TYPE]: yup.string().trim(),
  [RECEIVER_CRM_OPPORTUNITY_ID]: yup.string().trim(),
  [RECEIVER_PHONE]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_PHONE]).required().trim(),
  [RECEIVER_ADDRESS1]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_ADDRESS1]).required().trim(),
  [RECEIVER_ADDRESS2]: yup.string().trim(),
  [RECEIVER_STATE]: yup.string().trim(),
  [RECEIVER_ZIP]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_ZIP]).required().trim(),
  [RECEIVER_COUNTRY]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_COUNTRY]).required().trim(),
  [RECEIVER_CITY]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_CITY]).required().trim(),
  [RECEIVER_EMAIL]: yup
    .string()
    .label(fieldNamesForErrorOutput[RECEIVER_EMAIL])
    .matches(new RegExp(EMAIL_VALIDATION_REGEX), 'Invalid email format')
    .required()
    .trim(),
  [SHIP_ORDER_STATUS]: yup.string().oneOf([AWAITING_ADDRESS, '']),
  [IS_RECEIVER_ADDRESS_FIXED]: yup.boolean(),
  [SHIPPING_OPTION]: yup.string().default(ShippingOptionsEnum.Ground).required(),
  [DISABLE_EMAIL_NOTIFICATIONS]: yup.boolean(),
});

/*
 * Validation Schema for single send no-crm
 * Non-delayed shipping
 */
export const SHIPPING_VALIDATION_SCHEMA_NO_CRM = yup.object().shape({
  [RECEIVER_FIRST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_FIRST_NAME]).required().trim(),
  [RECEIVER_LAST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_LAST_NAME]).required().trim(),
  [RECEIVER_COMPANY_NAME]: yup.string().trim(),
  [RECEIVER_PHONE]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_PHONE]).trim().required(),
  [RECEIVER_ADDRESS1]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_ADDRESS1]).required().trim(),
  [RECEIVER_ADDRESS2]: yup.string().trim(),
  [RECEIVER_STATE]: yup.string().trim(),
  [RECEIVER_ZIP]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_ZIP]).required().trim(),
  [RECEIVER_COUNTRY]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_COUNTRY]).required().trim(),
  [RECEIVER_CITY]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_CITY]).required().trim(),
  [RECEIVER_EMAIL]: yup
    .string()
    .label(fieldNamesForErrorOutput[RECEIVER_EMAIL])
    .matches(new RegExp(EMAIL_VALIDATION_REGEX), 'Invalid email format')
    .required()
    .trim(),
  [SHIP_ORDER_STATUS]: yup.string().oneOf([AWAITING_ADDRESS, '']),
  [IS_RECEIVER_ADDRESS_FIXED]: yup.boolean(),
  [SHIPPING_OPTION]: yup.string().default(ShippingOptionsEnum.Ground).required().trim(),
  [DISABLE_EMAIL_NOTIFICATIONS]: yup.bool(),
});

/*
 * Validation Schema for single send
 * Delayed shipping
 */
export const SIMPLIFIED_SHIPPING_VALIDATION_SCHEMA = yup.object().shape({
  [RECEIVER_FIRST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_FIRST_NAME]).required().trim(),
  [RECEIVER_LAST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_LAST_NAME]).required().trim(),
  [RECEIVER_COMPANY_NAME]: yup.string().trim(),
  [RECEIVER_CRM_RECORD_ID]: yup.string().required().trim(),
  [RECEIVER_CRM_RECORD_TYPE]: yup.string().trim(),
  [RECEIVER_CRM_OPPORTUNITY_ID]: yup.string().trim(),
  [RECEIVER_PHONE]: yup.string().trim(),
  [RECEIVER_ADDRESS1]: yup.string().trim(),
  [RECEIVER_ADDRESS2]: yup.string().trim(),
  [RECEIVER_STATE]: yup.string().trim(),
  [RECEIVER_ZIP]: yup.string().trim(),
  [RECEIVER_COUNTRY]: yup.string().trim(),
  [RECEIVER_CITY]: yup.string().trim(),
  [RECEIVER_EMAIL]: yup.string().trim(),
  [SHIP_ORDER_STATUS]: yup.string().required().oneOf([AWAITING_ADDRESS, '']),
  [SHIPPING_OPTION]: yup.string().default(ShippingOptionsEnum.Ground).required(),
  [DISABLE_EMAIL_NOTIFICATIONS]: yup.bool(),
});

/*
 * Validation Schema for single send
 * Delayed shipping and Fixed address
 */

export const SIMPLIFIED_SHIPPING_VALIDATION_SCHEMA_FIXED_ADDRESS = (withCRM?: boolean) =>
  (withCRM ? SHIPPING_VALIDATION_SCHEMA : SHIPPING_VALIDATION_SCHEMA_NO_CRM).shape({
    [RECEIVER_PHONE]: yup.string().trim(),
    [RECEIVER_EMAIL]: yup.string().trim(),
    [SHIP_ORDER_STATUS]: yup.string().trim(),
  });

/*
 * Validation Schema for single send non-crm
 * Delayed shipping
 */
export const SIMPLIFIED_SHIPPING_VALIDATION_SCHEMA_NO_CRM = yup.object().shape({
  [RECEIVER_FIRST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_FIRST_NAME]).required().trim(),
  [RECEIVER_LAST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_LAST_NAME]).required().trim(),
  [RECEIVER_COMPANY_NAME]: yup.string().trim(),
  [RECEIVER_PHONE]: yup.string().trim(),
  [RECEIVER_ADDRESS1]: yup.string().trim(),
  [RECEIVER_ADDRESS2]: yup.string().trim(),
  [RECEIVER_STATE]: yup.string().trim(),
  [RECEIVER_ZIP]: yup.string().trim(),
  [RECEIVER_COUNTRY]: yup.string().trim(),
  [RECEIVER_CITY]: yup.string().trim(),
  [RECEIVER_EMAIL]: yup.string().trim(),
  [SHIP_ORDER_STATUS]: yup.string().required().oneOf([AWAITING_ADDRESS, '']),
  [SHIPPING_OPTION]: yup.string().default(ShippingOptionsEnum.Ground).required(),
  [DISABLE_EMAIL_NOTIFICATIONS]: yup.bool(),
});

export const INITIAL_DIGITAL_SHIPPING_FORM_STATE = {
  [RECEIVER_FIRST_NAME]: '',
  [RECEIVER_LAST_NAME]: '',
  [RECEIVER_CRM_RECORD_ID]: '',
  [RECEIVER_CRM_RECORD_TYPE]: '',
  [RECEIVER_CRM_OPPORTUNITY_ID]: '',
};

export const INITIAL_DIGITAL_SHIPPING_FORM_STATE_NO_CRM = {
  [RECEIVER_FIRST_NAME]: '',
  [RECEIVER_LAST_NAME]: '',
};

/*
 * Validation Schema for digital single send
 * Non-delayed shipping
 */
export const DIGITAL_SHIPPING_VALIDATION_SCHEMA = yup.object().shape({
  [RECEIVER_FIRST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_FIRST_NAME]).required().trim(),
  [RECEIVER_LAST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_LAST_NAME]).required().trim(),
  [RECEIVER_CRM_RECORD_ID]: yup.string().required(),
  [RECEIVER_CRM_RECORD_TYPE]: yup.string(),
  [RECEIVER_CRM_OPPORTUNITY_ID]: yup.string(),
});

/*
 * Validation Schema for digital single send non-crm
 * Non-delayed shipping
 */
export const DIGITAL_SHIPPING_VALIDATION_SCHEMA_NO_CRM = yup.object().shape({
  [RECEIVER_FIRST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_FIRST_NAME]).required().trim(),
  [RECEIVER_LAST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_LAST_NAME]).required().trim(),
});

export const INITIAL_ONE_LINK_PHYSICAL_SHIPPING_FORM_STATE: IOneLinkFormFields = {
  [EXPIRATION_DATE]: null,
  [IS_RECEIVER_ADDRESS_FIXED]: false,
  [SHIPPING]: {
    [SHIPPING_OPTION]: ShippingOptionsEnum.Ground,
  },
  [DISABLE_SENDER_EMAIL_NOTIFICATIONS]: true,
  [PASSWORD]: '',
  [ALLOWED_DOMAIN]: '',
  [ENABLED_2FA]: false,
  [REDEMPTION_LIMIT]: null,
};

export const INITIAL_ONE_LINK_DIGITAL_SHIPPING_FORM_STATE: IOneLinkFormFields = {
  [EXPIRATION_DATE]: null,
  [DISABLE_SENDER_EMAIL_NOTIFICATIONS]: true,
  [PASSWORD]: '',
  [ALLOWED_DOMAIN]: '',
  [ENABLED_2FA]: false,
  [REDEMPTION_LIMIT]: null,
};

/*
 * Validation Schema for one-link physical send
 * Non-delayed shipping
 */
export const ONE_LINK_PHYSICAL_SHIPPING_VALIDATION_SCHEMA = yup.object<IOneLinkFormFields>().shape({
  [SHIPPING]: yup
    .object()
    .shape({
      [SHIPPING_OPTION]: yup.string().default(ShippingOptionsEnum.Ground).required(),
    })
    .required(),
  [EXPIRATION_DATE]: yup.string().nullable(),
  [PASSWORD]: yup.string().trim(),
  [REDEMPTION_LIMIT]: yup.number().nullable(),
  [ALLOWED_DOMAIN]: yup.string().trim().matches(EMAIL_DOMAIN_REGEX),
});

/*
 * Validation Schema for one-link digital send
 * Non-delayed shipping
 */
export const ONE_LINK_DIGITAL_SHIPPING_VALIDATION_SCHEMA = yup.object<IOneLinkFormFields>().shape({
  [EXPIRATION_DATE]: yup.string().nullable(),
  [PASSWORD]: yup.string().trim(),
  [REDEMPTION_LIMIT]: yup.number().nullable(),
  [ALLOWED_DOMAIN]: yup.string().trim().matches(EMAIL_DOMAIN_REGEX),
});

export const SHIPPING_FORM_DS_HINT =
  'Don’t have your receiver’s address? Don’t worry, we’ll send you a link to a form the receiver can fill out with their information.';
export const SHIPPING_FORM_DS_PYG_HINT = 'You can’t disable delayed shipping on ’Pick Your Gift’ flow.';
export const SHIPPING_FORM_MSKU_HINT = 'You can’t disable delayed shipping with multiple option items in sends.';
export const SHIPPING_FORM_CUSTOMIZABLE_ITEMS_HINT =
  'You can’t disable delayed shipping with customizable items in sends.';
export const RECEIVER_FIXED_ADDRESS_HINT =
  'Turn this on for a fixed delivery address. The recipient can choose preferred gift options if available.';

export const FIXED_ADDRESS_VALIDATION_SCHEMA = yup.object().shape({
  [ADDRESS1]: yup.string().required().label(fieldNamesForErrorOutput[ADDRESS1]).trim(),
  [ADDRESS2]: yup.string().trim(),
  [CITY]: yup.string().required().label(fieldNamesForErrorOutput[CITY]).trim(),
  [STATE]: yup
    .string()
    .trim()
    .when(COUNTRY, {
      is: USA.two_digit_code,
      then: (schema: yup.StringSchema) => schema.required().label(fieldNamesForErrorOutput[STATE]),
      otherwise: (schema: yup.StringSchema) => schema.notRequired(),
    }),
  [ZIP]: yup.string().required().label(fieldNamesForErrorOutput[ZIP]).trim(),
  [COUNTRY]: yup.string().required().label(fieldNamesForErrorOutput[COUNTRY]).trim(),
});

export const INITIAL_FIXED_ADDRESS_FORM_STATE = {
  [ADDRESS1]: '',
  [ADDRESS2]: '',
  [CITY]: '',
  [STATE]: '',
  [ZIP]: '',
  [COUNTRY]: '',
};
