import cn from 'classnames';
import * as React from 'react';

import styles from './FileDetails.module.scss';

interface IProps {
  className?: string;
  name: string;
  description?: string;
  icon?: React.ElementType;
  action?: React.ReactNode;
  title?: string;
  onClick?: (...args: any[]) => void;
}

export default ({ className, name, description, icon: Icon, action, title, onClick }: IProps) => (
  <div className={cn(styles.container, className, { [styles.button]: !!onClick })} onClick={onClick}>
    {title && <div className={styles.title}>{title}</div>}
    <div className={styles.content}>
      {Icon && <Icon className={styles.icon} />}
      <div className={styles.details}>
        <div className={styles.name}>{name}</div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      {action && <div className={styles.action}>{action}</div>}
    </div>
  </div>
);
