import cn from 'classnames';
import * as React from 'react';

import { HelpTooltip } from '../../../tooltips';

import styles from './InputLabel.module.scss';

interface IInputLabelProps {
  className?: string;
  containerClassName?: string;
  value?: string | React.ReactNode;
  required?: boolean;
  hint?: string | React.ReactNode;
  name?: string;
}

const InputLabel: React.FC<IInputLabelProps> = ({
  value,
  containerClassName,
  className,
  required = false,
  hint,
  name,
}) =>
  value ? (
    <div className={cn(styles.labelContainer, containerClassName)}>
      <span className={cn(styles.text, { [styles.required]: required }, className)}>{value}</span>
      {hint && (
        <HelpTooltip id={`label-tooltip-${name}`} className={styles.hint}>
          {hint}
        </HelpTooltip>
      )}
    </div>
  ) : null;

export default InputLabel;
