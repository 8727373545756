import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as CustomizableIcon } from '../../../assets/images/icon-customizible.svg';
import { CUSTOMIZABLE_ITEM_INDICATOR_HINT_TEXT } from '../../../constants/inventories';
import { HelpTooltip } from '../../tooltips';

import styles from './CustomizableItemIndicator.module.scss';

export interface ICustomizableItemIndicatorProps {
  className?: string;
  tooltipClassName?: string;
  title?: string;
  id?: string;
}

const CustomizableItemIndicator: React.FC<ICustomizableItemIndicatorProps> = ({
  className,
  tooltipClassName,
  title = CUSTOMIZABLE_ITEM_INDICATOR_HINT_TEXT,
  id,
}) => {
  return (
    <HelpTooltip
      id={`customize-tooltip-for-${id}`}
      icon={CustomizableIcon}
      tooltipClassName={tooltipClassName}
      className={cn(styles.icon, className)}
    >
      {title}
    </HelpTooltip>
  );
};

export default CustomizableItemIndicator;
