import * as React from 'react';

import FileDetails from '../../FileDetails/FileDetails';
import ModalHeader from '../ModalHeader/ModalHeader';

import { ReactComponent as CloseIcon } from '../../../assets/images/icon-close-cross.svg';
import iconDownload from '../../../assets/images/icon-cloud.svg';
import { ReactComponent as iconCSVError } from '../../../assets/images/icon-csv-error.svg';

import styles from './FailedUploadInventoryModal.module.scss';

interface IProps {
  onClose: () => void;
  errorFileUrl: string;
}

const FailedUploadInventoryModal = ({ onClose, errorFileUrl }: IProps) => {
  const handleFileClick = React.useCallback((url: string) => (window.location.href = url), []);

  const renderButton = React.useCallback((url: string) => {
    return (
      <a className={styles.action} href={url} title="Download File">
        <img src={iconDownload} alt="" />
      </a>
    );
  }, []);

  return (
    <div className={styles.container}>
      <ModalHeader
        className={styles.header}
        right={
          <div className={styles.closeButton}>
            <CloseIcon onClick={onClose} />
          </div>
        }
      />
      <div className={styles.content}>
        <h1 className={styles.title}>Inventory upload is failed</h1>
        <FileDetails
          onClick={() => handleFileClick(errorFileUrl)}
          className={styles.result}
          action={renderButton(errorFileUrl)}
          name="Failed_Inventory_Items.csv"
          icon={iconCSVError}
        />
        <div className={styles.hint}>Download this file, make the changes and try uploading it again.</div>
      </div>
    </div>
  );
};

export default FailedUploadInventoryModal;
