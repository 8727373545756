import { IOnDemandItem, IRequestOnDemandItem } from './inventories';
import { IPromiseCallbacks } from './redux';

export enum SearchFlowOptionsEnum {
  General = 'general',
  Optimized = 'optimized',
  Json = 'json',
}

export enum SearchByOptionsEnum {
  Title = 'title',
  Description = 'description',
  All = 'all',
}

export interface IGenerateGiftSuggestRequestPayload extends IPromiseCallbacks {
  data: IGenerateGiftSuggestData;
}

export interface IGenerateGiftSuggestData {
  text: string;
  parse_flow_type: string;
  profile_url: string;
  hits_count: number;
}

export interface IGenerateGiftSuggestResponse {
  ai_response: string;
  ai_suggestions: ISuggestionItem[];
  search_flow: SearchFlowOptionsEnum;
  search_by: SearchByOptionsEnum;
  suggested_gifts: IRequestOnDemandItem[][];
}

export interface IGenerateGiftSuggestSuccessPayload extends Omit<IGenerateGiftSuggestResponse, 'suggested_gifts'> {
  suggested_gifts: IOnDemandItem[][];
}

export interface ISuggestionItem {
  raw: string;
  title: string;
  description: string;
  reason: string;
}

export interface IProfileData {
  prompt: string;
  profileURL: string;
  parseFlowType: string;
  firstName: string;
  lastName: string;
}
