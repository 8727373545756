import cn from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';
import { FileError, FileRejection } from 'react-dropzone';
import { useSelector } from 'react-redux';

// import customizationItemsBodyHintImage from '../../../assets/images/hints/customization-items-body-hint.png';
// import customizationItemsTitleHintImage from '../../../assets/images/hints/customization-items-title-hint.png';
import mskuBodyHintImage from '../../../assets/images/hints/msku-body-hint.png';
import mskuTitleHintImage from '../../../assets/images/hints/msku-title-hint.png';
import spBodyHintImage from '../../../assets/images/hints/shipping-page-body-hint.png';
import spButtonColorHintImage from '../../../assets/images/hints/shipping-page-button-color-hint.png';
import spHeaderColorHintImage from '../../../assets/images/hints/shipping-page-header-color-hint.png';
import spTitleHintImage from '../../../assets/images/hints/shipping-page-title-hint.png';

import {
  DELAYED_CUSTOMIZE_ITEMS_PAGE_BODY,
  DELAYED_CUSTOMIZE_ITEMS_PAGE_TITLE,
  DELAYED_MSKU_PAGE_BODY,
  DELAYED_MSKU_PAGE_TITLE,
  DELAYED_SHIPPING_BUTTON_COLOR,
  DELAYED_SHIPPING_COMPANY_NAME,
  DELAYED_SHIPPING_HEADER_COLOR,
  DELAYED_SHIPPING_LOGO_URL,
  DELAYED_SHIPPING_PAGE_BODY,
  DELAYED_SHIPPING_PAGE_TITLE,
  USE_EMAIL_BRANDING,
} from '../../../constants/campaigns';
import useUploadFile from '../../../hooks/useUploadFile';
import useWindowSize from '../../../hooks/useWindowSize';
import { selectDelayedShippingTextCustomizationTags } from '../../../store/selectors/shell';
import { ICampaignCandidate, ICampaignSummary } from '../../../types/campaigns';
import { IDropzoneResult, ITextCustomizationForm, MaintenanceFormStateEnum } from '../../../types/forms';
import { PositionStylesEnum } from '../../../types/shell';
import { IUploadResponse, UploadFileTypeEnum } from '../../../types/upload';
import notification from '../../../utils/notification';
import { preparedDropZoneErrors } from '../../../utils/upload';
import { HtmlView, ImageUploadInput, TabProvider, TemplateTagPicker, Toggle } from '../../index';

import { ITabProviderTab } from '../../TabProvider/TabProvider';
import { HelpTooltip, WarningMessage } from '../../tooltips';
import { ColorInput, Input, TinyEditor } from '../inputs';
import { InputLabel } from '../labels';

import styles from './ShippingPageCustomizationForm.module.scss';

type TCustomizeFormFields = Pick<
  ICampaignCandidate,
  | 'ds_shipping_page_title'
  | 'ds_shipping_page_body'
  | 'ds_msku_page_title'
  | 'ds_msku_page_body'
  | 'ds_customize_items_page_title'
  | 'ds_customize_items_page_body'
>;

export interface IShippingPageCustomizationFormProps {
  readOnly?: boolean;
  isDisabled?: boolean;
  showTitle?: boolean;
  requiredFields: Record<string, boolean>;
  onChange?: (name: keyof ICampaignCandidate, value: string | number | boolean | undefined) => void;
  form: FormikProps<Partial<ICampaignCandidate>>;
  mode?: MaintenanceFormStateEnum;
}

const ShippingPageCustomizationForm: React.FC<IShippingPageCustomizationFormProps> = ({
  form,
  requiredFields,
  isDisabled,
  readOnly,
  onChange,
  showTitle,
}) => {
  const { mobile } = useWindowSize();

  const [customActiveTab, setCustomActiveTab] = React.useState<number>(0);

  const { uploadFile } = useUploadFile();

  const availableDSTags = useSelector(selectDelayedShippingTextCustomizationTags);

  const shouldHideButtonColorInput = React.useMemo(() => {
    return readOnly && !form.values[DELAYED_SHIPPING_BUTTON_COLOR];
  }, [form.values[DELAYED_SHIPPING_BUTTON_COLOR], readOnly]);

  const shouldHideHeaderColorInput = React.useMemo(() => {
    return readOnly && !form.values[DELAYED_SHIPPING_HEADER_COLOR];
  }, [form.values[DELAYED_SHIPPING_HEADER_COLOR], readOnly]);

  const shouldHideImageInput = React.useMemo(() => {
    return readOnly && !form.values[DELAYED_SHIPPING_LOGO_URL];
  }, [form.values[DELAYED_SHIPPING_LOGO_URL], readOnly]);

  const shouldHideCompanyNameInput = React.useMemo(() => {
    return readOnly && !form.values[DELAYED_SHIPPING_COMPANY_NAME];
  }, [form.values[DELAYED_SHIPPING_COMPANY_NAME], readOnly]);

  const isEmailBrandingFieldsPopulated = React.useMemo(
    () =>
      [
        form.values[DELAYED_SHIPPING_BUTTON_COLOR],
        form.values[DELAYED_SHIPPING_HEADER_COLOR],
        form.values[DELAYED_SHIPPING_LOGO_URL],
        form.values[DELAYED_SHIPPING_COMPANY_NAME],
      ].some((v) => v),
    [form.values],
  );

  const shouldHideForm = React.useMemo(() => {
    return [
      DELAYED_SHIPPING_BUTTON_COLOR,
      DELAYED_SHIPPING_HEADER_COLOR,
      DELAYED_SHIPPING_PAGE_TITLE,
      DELAYED_SHIPPING_PAGE_BODY,
      DELAYED_MSKU_PAGE_TITLE,
      DELAYED_MSKU_PAGE_BODY,
      DELAYED_CUSTOMIZE_ITEMS_PAGE_TITLE,
      DELAYED_CUSTOMIZE_ITEMS_PAGE_BODY,
      DELAYED_SHIPPING_LOGO_URL,
      DELAYED_SHIPPING_COMPANY_NAME,
    ].every((value) => readOnly && !form.values[value as keyof ICampaignSummary]);
  }, [form.values, readOnly]);

  const getFieldErrors = React.useCallback(
    (fieldName: string) => {
      const { touched, error } = form.getFieldMeta(fieldName);
      return touched ? error : undefined;
    },
    [form.getFieldMeta],
  );

  const handleFieldChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { name, value } = e.currentTarget;

      if (typeof onChange === 'function') {
        onChange(name as keyof ICampaignCandidate, value);
      }
    },
    [onChange],
  );

  const handleColorChange = React.useCallback(
    (name: string, color: string) => {
      if (typeof onChange === 'function') {
        onChange(name as keyof ICampaignCandidate, color);
      }
    },
    [onChange],
  );

  const handleToggleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked: value } = e.currentTarget;
      if (!name) {
        console.warn('No field name was specified');
        return;
      }

      if (typeof onChange === 'function') {
        onChange(name as keyof ICampaignCandidate, value);
      }
    },
    [onChange],
  );

  React.useEffect(() => {
    if (!isEmailBrandingFieldsPopulated && onChange) {
      onChange(USE_EMAIL_BRANDING, false);
    }
  }, [isEmailBrandingFieldsPopulated, onChange]);

  const onUploadStart = React.useCallback(
    ({ files, resolve, reject }: IDropzoneResult) => {
      uploadFile(files[0], UploadFileTypeEnum.OrgLogo).then(resolve).catch(reject);
    },
    [uploadFile],
  );

  const onUploadSuccess = React.useCallback(
    (files: File[], response: IUploadResponse) => {
      const { public_url: url } = response;
      if (typeof onChange === 'function') {
        onChange(DELAYED_SHIPPING_LOGO_URL, url);
      }
    },
    [onChange],
  );

  const onUploadFailure = React.useCallback((errors: FileError[]) => {
    const showError = ({ message }: any) => notification.error(message, { content: message });

    Array.isArray(errors) ? errors.map(showError) : showError(errors);
  }, []);

  const onDropReject = React.useCallback(
    (fileRejections: FileRejection[]) => {
      fileRejections.forEach((fileReject) => {
        const { errors } = fileReject;
        onUploadFailure(preparedDropZoneErrors(errors));
      });
    },
    [onUploadFailure],
  );

  const onImageDelete = React.useCallback(() => {
    if (typeof onChange === 'function') {
      onChange(DELAYED_SHIPPING_LOGO_URL, '');
    }
  }, [onChange]);

  const customizeForms: ITextCustomizationForm[] = React.useMemo(
    () => [
      ...(readOnly && !form.values[DELAYED_SHIPPING_PAGE_TITLE] && !form.values[DELAYED_SHIPPING_PAGE_BODY]
        ? []
        : [
            {
              id: 'shipping-page',
              tabLabel: 'Shipping Page',
              title_name: DELAYED_SHIPPING_PAGE_TITLE,
              body_name: DELAYED_SHIPPING_PAGE_BODY,
              shouldHideTitle: readOnly && !form.values[DELAYED_SHIPPING_PAGE_TITLE],
              shouldHideBody: readOnly && !form.values[DELAYED_SHIPPING_PAGE_BODY],
              titleLabel: 'Shipping Page Title',
              bodyLabel: 'Shipping Page Body',
              titleHintImage: spTitleHintImage,
              bodyHintImage: spBodyHintImage,
            },
          ]),
      ...(readOnly && !form.values[DELAYED_MSKU_PAGE_TITLE] && !form.values[DELAYED_MSKU_PAGE_BODY]
        ? []
        : [
            {
              id: 'msku-page',
              tabLabel: 'MSKU Page',
              title_name: DELAYED_MSKU_PAGE_TITLE,
              body_name: DELAYED_MSKU_PAGE_BODY,
              shouldHideTitle: readOnly && !form.values[DELAYED_MSKU_PAGE_TITLE],
              shouldHideBody: readOnly && !form.values[DELAYED_MSKU_PAGE_BODY],
              titleLabel: 'MSKU Step Title',
              bodyLabel: 'MSKU Step Body',
              titleHintImage: mskuTitleHintImage,
              bodyHintImage: mskuBodyHintImage,
            },
          ]),
      // ...(readOnly &&
      // !form.values[DELAYED_CUSTOMIZE_ITEMS_PAGE_TITLE] &&
      // !form.values[DELAYED_CUSTOMIZE_ITEMS_PAGE_BODY]
      //   ? []
      //   : [
      //       {
      //         id: 'customizing-items-page',
      //         tabLabel: 'Customizing Items Page',
      //         title_name: DELAYED_CUSTOMIZE_ITEMS_PAGE_TITLE,
      //         body_name: DELAYED_CUSTOMIZE_ITEMS_PAGE_BODY,
      //         shouldHideTitle: readOnly && !form.values[DELAYED_CUSTOMIZE_ITEMS_PAGE_TITLE],
      //         shouldHideBody: readOnly && !form.values[DELAYED_CUSTOMIZE_ITEMS_PAGE_BODY],
      //         titleLabel: 'Item Customization Step Title',
      //         bodyLabel: 'Item Customization Step Body',
      //         titleHintImage: customizationItemsTitleHintImage,
      //         bodyHintImage: customizationItemsBodyHintImage,
      //       },
      //     ]),
    ],
    [form.values, readOnly],
  );

  const customizationItems: ITabProviderTab[] = React.useMemo(
    () =>
      customizeForms.map((item) => ({
        label: item.tabLabel,
        tabId: item.id,
        renderer: (
          <div className={styles.fields}>
            {!item.shouldHideTitle && (
              <div className={styles.info}>
                <div className={styles.inputHeadingContainer}>
                  <InputLabel
                    value={
                      <React.Fragment>
                        {item.titleLabel}
                        <HelpTooltip
                          id={`${item.id}-title-hint`}
                          className={styles.infoIcon}
                          place={mobile ? PositionStylesEnum.Right : PositionStylesEnum.Top}
                          contentClassName={styles.tooltipImage}
                        >
                          <img src={item.titleHintImage} alt="" />
                        </HelpTooltip>
                      </React.Fragment>
                    }
                  />
                </div>
                <Input
                  className={styles.inputContainer}
                  name={item.title_name}
                  field={form.getFieldProps(item.title_name)}
                  value={form.values[item.title_name as keyof TCustomizeFormFields]}
                  onChange={handleFieldChange}
                  disabled={isDisabled}
                  required={requiredFields[item.title_name]}
                  error={getFieldErrors(item.title_name)}
                  showErrors
                  readOnly={readOnly}
                />
              </div>
            )}
            {!item.shouldHideBody && (
              <div className={styles.info}>
                <div className={styles.inputHeadingContainer}>
                  <InputLabel
                    value={
                      <React.Fragment>
                        {item.bodyLabel}
                        <HelpTooltip
                          id={`${item.id}-body-hint`}
                          className={styles.infoIcon}
                          place={mobile ? PositionStylesEnum.Right : PositionStylesEnum.Top}
                          contentClassName={styles.tooltipImage}
                        >
                          <img src={item.bodyHintImage} alt="" />
                        </HelpTooltip>
                      </React.Fragment>
                    }
                  />
                </div>
                {readOnly ? (
                  <HtmlView
                    className={cn(styles.inputContainer, styles.htmlEditor, styles.viewContainer)}
                    html={form.values?.[item.body_name as keyof TCustomizeFormFields] || ''}
                  />
                ) : (
                  <TinyEditor
                    field={form.getFieldProps(item.body_name)}
                    value={form.values?.[item.body_name as keyof TCustomizeFormFields]}
                    changeCallback={handleFieldChange}
                    className={cn(styles.inputContainer, styles.htmlEditor)}
                    onBlur={form.handleBlur}
                    showHint={false}
                    disabled={isDisabled}
                    showFontColorPicker={false}
                  />
                )}
              </div>
            )}
          </div>
        ),
      })),
    [customizeForms, form, getFieldErrors, handleFieldChange, isDisabled, mobile, readOnly, requiredFields],
  );

  return shouldHideForm ? null : (
    <div className={styles.formWrapper}>
      {showTitle && (
        <h2 className={styles.heading}>
          <span>Shipping Page Customization</span>
        </h2>
      )}
      <form className={styles.container}>
        {!readOnly && (
          <WarningMessage
            className={styles.warning}
            message="Leave these inputs empty to use organization default values"
          />
        )}
        <div className={styles.mainInfo}>
          {!shouldHideImageInput && (
            <ImageUploadInput
              onUpload={onUploadStart}
              onSuccess={onUploadSuccess}
              onFailure={onUploadFailure}
              onDropReject={onDropReject}
              onBlur={() => form.getFieldHelpers(DELAYED_SHIPPING_LOGO_URL).setTouched(true, true)}
              value={form.values?.[DELAYED_SHIPPING_LOGO_URL]}
              onImageDelete={onImageDelete}
              readOnly={readOnly}
              error={getFieldErrors(DELAYED_SHIPPING_LOGO_URL)}
              showErrors
            />
          )}
          {[shouldHideCompanyNameInput, shouldHideButtonColorInput, shouldHideHeaderColorInput].some((v) => !v) && (
            <div className={styles.verticalBlock}>
              {!shouldHideCompanyNameInput && (
                <Input
                  helperText="Company Name"
                  placeholder="Company Name"
                  name={DELAYED_SHIPPING_COMPANY_NAME}
                  field={form.getFieldProps(DELAYED_SHIPPING_COMPANY_NAME)}
                  value={form.values[DELAYED_SHIPPING_COMPANY_NAME]}
                  onChange={handleFieldChange}
                  disabled={isDisabled}
                  required={requiredFields[DELAYED_SHIPPING_COMPANY_NAME]}
                  error={getFieldErrors(DELAYED_SHIPPING_COMPANY_NAME)}
                  showErrors
                  readOnly={readOnly}
                />
              )}
              {!(shouldHideButtonColorInput && shouldHideHeaderColorInput) && (
                <div className={styles.colorInputsContainer}>
                  {!shouldHideHeaderColorInput && (
                    <ColorInput
                      isClearable
                      containerClassName={cn(styles.colorPicker)}
                      labelClassName={styles.labelWithHint}
                      helperText={
                        <React.Fragment>
                          Banner Color
                          <HelpTooltip
                            id="sp-header-color-hint"
                            className={styles.infoIcon}
                            place={mobile ? PositionStylesEnum.Right : PositionStylesEnum.Top}
                            contentClassName={styles.tooltipImage}
                          >
                            <img src={spHeaderColorHintImage} alt="" />
                          </HelpTooltip>
                        </React.Fragment>
                      }
                      name={DELAYED_SHIPPING_HEADER_COLOR}
                      position={PositionStylesEnum.Right}
                      showCurrent
                      value={form.values?.[DELAYED_SHIPPING_HEADER_COLOR]}
                      onChange={(data) => handleColorChange(DELAYED_SHIPPING_HEADER_COLOR, data)}
                      readOnly={readOnly}
                    />
                  )}
                  {!shouldHideButtonColorInput && (
                    <ColorInput
                      isClearable
                      containerClassName={cn(styles.colorPicker)}
                      labelClassName={styles.labelWithHint}
                      helperText={
                        <React.Fragment>
                          Button Color
                          <HelpTooltip
                            place={PositionStylesEnum.Top}
                            id="sp-button-color-hint"
                            className={styles.infoIcon}
                            contentClassName={styles.tooltipImage}
                          >
                            <img src={spButtonColorHintImage} alt="" />
                          </HelpTooltip>
                        </React.Fragment>
                      }
                      name={DELAYED_SHIPPING_BUTTON_COLOR}
                      position={PositionStylesEnum.Right}
                      showCurrent
                      value={form.values?.[DELAYED_SHIPPING_BUTTON_COLOR]}
                      onChange={(data) => handleColorChange(DELAYED_SHIPPING_BUTTON_COLOR, data)}
                      readOnly={readOnly}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {!readOnly && (
          <Toggle
            field={form.getFieldProps(USE_EMAIL_BRANDING)}
            name={USE_EMAIL_BRANDING}
            disabled={isDisabled || !isEmailBrandingFieldsPopulated}
            helperText="Use for email branding"
            onChange={handleToggleChange}
            checked={!!form.values?.[USE_EMAIL_BRANDING]}
          />
        )}
        <div className={styles.customizeInputFieldsContainer}>
          {customizationItems.length > 0 && (
            <TabProvider
              className={styles.tabProvider}
              rendererClassName={styles.customizerForm}
              tabClassName={styles.tabLabel}
              tabs={customizationItems}
              onSelect={setCustomActiveTab}
              activeTab={customActiveTab}
              loaderOnChange
            />
          )}
          <div className={styles.tags}>
            {!readOnly && availableDSTags && availableDSTags.length ? (
              <TemplateTagPicker
                label="Available Tags"
                className={styles.tagsContainer}
                tags={availableDSTags.map((title) => ({ title }))}
              />
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ShippingPageCustomizationForm;
