import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { apiUrl } from '../config/app';
import { endpoints } from '../constants/routing';
import useAuthQuery from '../hooks/useAuthQuery';
import { selectUserAccessToken } from '../store/selectors/auth';
import {
  ICampaign,
  IFetchCampaignsRequest,
  IFetchCampaignsResponse,
  IFetchCampaignSummariesRequestPayload,
  IFetchCampaignSummariesResponsePayload,
} from '../types/campaigns';
import { IFetchInventoryItemsRequestPayload, IFetchInventoryItemsResponse } from '../types/inventories';
import { QueryRequest } from '../utils/request';

const InventoryService = {
  getInventoryItems: (params: Partial<IFetchInventoryItemsRequestPayload>, token?: string) =>
    QueryRequest<IFetchInventoryItemsResponse>(
      {
        endpoint: `${apiUrl}${endpoints.inventoryItems}`,
        method: 'POST',
        body: JSON.stringify(params),
      },
      token,
    ).then((res) => {
      if (res.ok) {
        return res.body.inventory_items;
      }
      throw res;
    }),
  getCampaigns: (params: Partial<IFetchCampaignsRequest>, token?: string) =>
    QueryRequest<IFetchCampaignsResponse>(
      {
        endpoint: `${apiUrl}${endpoints.getCampaigns}`,
        method: 'POST',
        body: JSON.stringify(params),
      },
      token,
    ).then((res) => {
      if (res.ok) {
        return res.body.campaigns;
      }
      throw res;
    }),

  getCampaignSummaries: (params: Partial<IFetchCampaignSummariesRequestPayload>, token?: string) =>
    QueryRequest<IFetchCampaignSummariesResponsePayload>(
      {
        endpoint: `${apiUrl}${endpoints.getCampaignSummaries}`,
        method: 'POST',
        body: JSON.stringify(params),
      },
      token,
    ).then((res) => {
      if (res.ok) {
        return res.body.campaign_summaries;
      }
      throw res;
    }),
};

export const useInventoryItems = (params: Partial<IFetchInventoryItemsRequestPayload>) => {
  const token = useSelector(selectUserAccessToken);

  return useAuthQuery({
    queryKey: ['inventory-items', params],
    queryFn: async () => {
      const data = await InventoryService.getInventoryItems(params, token);

      return data ?? null;
    },
  });
};

/**
 * Hook that fetches campaigns data with infinite scroll functionality.
 *
 * @param {Partial<IFetchCampaignsRequest>} params - Parameters for fetching the campaigns.
 * @returns {UseInfiniteQueryResult<ICampaign[], unknown>} Result object containing campaign data and fetching status.
 */
export const useCampaignsInfiniteScroll = (
  params: Partial<IFetchCampaignsRequest>,
): UseInfiniteQueryResult<InfiniteData<ICampaign[]>, unknown> => {
  const token = useSelector(selectUserAccessToken);

  return useInfiniteQuery({
    queryKey: ['campaigns', params],
    queryFn: async ({ pageParam = 1 }) => {
      const data = await InventoryService.getCampaigns(
        {
          ...params,
          page: pageParam as number | undefined,
        },
        token,
      );
      return data ?? [];
    },
    getNextPageParam: (lastPage: ICampaign[], allPages: InfiniteData<ICampaign[]>['pages']) => {
      if (lastPage.length === 0 || (params.page_size && lastPage.length < params.page_size)) {
        return undefined;
      }
      return allPages.length + 1;
    },
    initialPageParam: 1,
  });
};

/**
 * Custom hook that fetches campaigns based on the provided parameters.
 *
 * @param {Partial<IFetchCampaignsRequest>} params - The parameters to filter campaigns.
 * @param {boolean} enabled - Flag to enable or disable the query execution.
 * @returns {UseQueryResult} The result object of the query containing data, error, and status.
 */
export const useCampaigns = (params: Partial<IFetchCampaignsRequest>, enabled: boolean): UseQueryResult => {
  const token = useSelector(selectUserAccessToken);

  return useAuthQuery({
    queryKey: ['campaigns', params],
    queryFn: async () => {
      const data = await InventoryService.getCampaigns(params, token);

      return data ?? null;
    },
    enabled,
  });
};

export const useCampaignSummaries = (params: Partial<IFetchCampaignSummariesRequestPayload>, enabled: boolean) => {
  const token = useSelector(selectUserAccessToken);

  return useAuthQuery({
    queryKey: ['campaign-summaries', params],
    queryFn: async () => {
      const data = await InventoryService.getCampaignSummaries(params, token);

      return data ?? null;
    },
    enabled,
  });
};
