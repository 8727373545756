import { actionTypes as FirebaseActions } from 'react-redux-firebase';
import { DIGITAL_BALANCE_EMAILS_DISABLED } from '../../constants/users';

import { IAuthState } from '../../types/auth';
import * as uiUtils from '../../utils/ui';
import * as AuthActions from '../actions/auth';
import * as SignatureActions from '../actions/signature';

export const initialState: IAuthState = {
  firstname: '',
  initialLetter: '',
  isLoading: false,
  userInfo: null,
  error: null,
};

const reducer = (state: IAuthState = initialState, action: any): IAuthState => {
  switch (action.type) {
    case AuthActions.AUTHENTICATE_REQUEST:
    case AuthActions.AUTHORIZE_USER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case FirebaseActions.AUTHENTICATION_INIT_STARTED: {
      return {
        ...state,
        firstname: '',
        initialLetter: '',
        isLoading: true,
        error: null,
      };
    }
    case FirebaseActions.LOGIN: {
      return {
        ...state,
        firstname: uiUtils.getFirstname(action.auth.displayName) || '',
        initialLetter: uiUtils.getInitialLetter(action.auth.displayName || action.auth.email) || '',
      };
    }
    case FirebaseActions.LOGIN_ERROR:
    case FirebaseActions.AUTH_EMPTY_CHANGE: {
      return {
        ...state,
        firstname: '',
        initialLetter: '',
        isLoading: false,
      };
    }
    case FirebaseActions.SET_PROFILE: {
      return {
        ...state,
        userInfo: state.userInfo
          ? {
              ...state.userInfo,
              [DIGITAL_BALANCE_EMAILS_DISABLED]: action?.profile?.[DIGITAL_BALANCE_EMAILS_DISABLED],
            }
          : null,
      };
    }
    case FirebaseActions.LOGOUT: {
      return {
        ...state,
        firstname: '',
        initialLetter: '',
        userInfo: null,
        error: null,
      };
    }
    case AuthActions.AUTHORIZE_USER_SUCCESS: {
      const firstname = action.payload.user?.sender?.first_name || state.firstname;
      const initialLetter = uiUtils.getInitialLetter(firstname) || state.initialLetter;

      const { signature = '', ...userInfo } = action.payload.user;

      return {
        ...state,
        firstname,
        initialLetter,
        isLoading: false,
        userInfo: {
          ...userInfo,
          signature: {
            value: signature,
          },
        },
        error: null,
      };
    }
    case AuthActions.AUTHENTICATE_FAILURE:
    case AuthActions.AUTHORIZE_USER_FAILURE: {
      return {
        ...state,
        firstname: '',
        initialLetter: '',
        isLoading: false,
        userInfo: null,
        error: action.payload,
      };
    }
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AuthActions.GET_DIGITAL_BUDGET_REQUEST: {
      return {
        ...state,
        userInfo: {
          ...(state.userInfo! || {}),
          digital_budget: {
            ...state.userInfo?.digital_budget,
            isLoading: true,
            error: null,
          },
        },
      };
    }
    case AuthActions.GET_DIGITAL_BUDGET_SUCCESS: {
      return {
        ...state,
        userInfo: {
          ...(state.userInfo! || {}),
          digital_budget: {
            value: action.payload as number,
            isLoading: false,
            error: null,
          },
        },
      };
    }
    case AuthActions.GET_DIGITAL_BUDGET_FAILURE: {
      return {
        ...state,
        userInfo: {
          ...(state.userInfo! || {}),
          digital_budget: {
            value: null,
            isLoading: false,
            error: action.payload,
          },
        },
      };
    }
    case SignatureActions.EDIT_SIGNATURE_REQUEST: {
      return {
        ...state,
        userInfo: {
          ...(state.userInfo! || {}),
          signature: {
            ...(state.userInfo!.signature || {}),
            isLoading: true,
            error: null,
          },
        },
      };
    }
    case SignatureActions.EDIT_SIGNATURE_SUCCESS: {
      const { signature } = action.payload;

      return {
        ...state,
        userInfo: {
          ...(state.userInfo! || {}),
          signature: {
            ...(state.userInfo!.signature || {}),
            value: signature,
            isLoading: false,
            error: null,
          },
        },
      };
    }
    case SignatureActions.EDIT_SIGNATURE_FAILURE: {
      return {
        ...state,
        userInfo: {
          ...(state.userInfo! || {}),
          signature: {
            ...(state.userInfo!.signature || {}),
            isLoading: false,
            error: action.payload,
          },
        },
      };
    }
    case SignatureActions.ADD_CANDIDATE_SIGNATURE: {
      const { signature } = action.payload;
      return {
        ...state,
        userInfo: {
          ...(state.userInfo! || {}),
          signature: {
            ...(state.userInfo!.signature || {}),
            candidate: signature,
            isLoading: false,
            error: action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
