import cn from 'classnames';
import * as React from 'react';
import { Column, useTable } from 'react-table';

import CopyIcon from '../../../assets/images/icon-copy-inversed.svg';
import { routes, URL_VARS } from '../../../constants/routing';
import useWindowSize from '../../../hooks/useWindowSize';
import { IOneLinkCreatedSend } from '../../../types/oneLink';
import { ReportTypesEnum } from '../../../types/reports';
import { InputLabel, ReportsCopyableButton, TextIconButton } from '../../forms';

import styles from './ConfirmedSendsList.module.scss';

interface IProps {
  className?: string;
  items: IOneLinkCreatedSend[];
}

interface ITableData {
  name: React.ReactNode;
  order_id: React.ReactNode;
  links: React.ReactNode[] | null;
}

const ConfirmedSendsList = ({ className, items }: IProps) => {
  const { mobile } = useWindowSize();

  const renderSummaryButton = React.useCallback(
    ({ uid: reportId, order_id }: IOneLinkCreatedSend) => (
      <TextIconButton
        className={styles.summaryButton}
        title={order_id}
        hint="Click to open in new tab"
        trigger={mobile}
        icon={CopyIcon}
        onClick={() =>
          window.open(
            routes.reports.getReportsUrl({ reportId, type: ReportTypesEnum.Regular, flowId: URL_VARS.SUMMARY }),
            '_blank',
          )
        }
      />
    ),
    [mobile],
  );

  const tableData: ITableData[] = React.useMemo(
    () =>
      items.map((item) => {
        const { first_name, last_name, digital_gift_url, tracking_url } = item;

        return {
          name: (
            <span className={styles.name}>
              {first_name} {last_name}
            </span>
          ),
          order_id: renderSummaryButton(item),
          links: [
            digital_gift_url ? (
              <ReportsCopyableButton
                hint="Copy digital gift URL"
                isIcon
                value={digital_gift_url}
                key="digital_gift_url"
              />
            ) : null,
            tracking_url ? (
              <ReportsCopyableButton
                key="tracking_url"
                hint="Copy tracking URL"
                className={styles.copyButton}
                isIcon
                value={tracking_url}
              />
            ) : null,
          ],
        };
      }),
    [items, mobile],
  );

  const tableColumns: Column<ITableData>[] = React.useMemo(
    () => [
      {
        Header: 'Confirmed recipients',
        accessor: 'name',
      },
      {
        Header: 'Order ID',
        accessor: 'order_id',
      },
      {
        Header: 'Links',
        accessor: 'links',
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: tableData,
    columns: tableColumns,
  });

  return (
    <div className={cn(styles.container, className)} {...getTableProps()}>
      <div className={cn(styles.header, styles.grid)}>
        {headerGroups.map((headerGroup) => (
          <div
            {...headerGroup.getHeaderGroupProps()}
            className={styles.row}
            key={headerGroup.getHeaderGroupProps().key}
          >
            {headerGroup.headers.map((column) => (
              <InputLabel
                {...column.getHeaderProps()}
                className={styles.column}
                key={column.getHeaderProps().key}
                value={column.render('Header')}
              />
            ))}
          </div>
        ))}
      </div>
      <div {...getTableBodyProps()} className={cn(styles.body, styles.grid)}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <div {...row.getRowProps()} className={styles.row} key={row.getRowProps().key}>
              {row.cells.map((cell) => {
                return (
                  <div {...cell.getCellProps()} className={styles.cell} key={cell.getCellProps().key}>
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ConfirmedSendsList;
