import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import styles from './Stepper.module.scss';

interface IStep {
  id: string;
  route: string;
  label: string | number;
}

interface IProps {
  active?: string;
  disabled?: boolean;
  className?: string;
  steps: IStep[];
}

export default ({ active, className, steps, disabled }: IProps) => {
  return (
    <ol className={cn(styles.container, className)}>
      {steps.map(({ id, route, label }, i) => {
        const isActive = active === id || i < steps.findIndex((s) => s.id === active);
        const isCurrent = active === id;

        return (
          <li key={id} className={cn(styles.step, { [styles.active]: isActive, [styles.current]: isCurrent })}>
            {isCurrent || disabled ? (
              <span className={styles.label}>{label}</span>
            ) : (
              <Link className={styles.label} role="button" to={route}>
                {label}
              </Link>
            )}
          </li>
        );
      })}
    </ol>
  );
};
