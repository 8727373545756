import cn from 'classnames';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { TemplateForm } from '../../components/forms';
import MaintenanceForm from '../../components/forms/MaintenanceForm/MaintenanceForm';
import { routes, URL_VARS } from '../../constants/routing';
import { TemplateSuccessToastMessages, TEMPLATE_LABEL, TEMPLATE_TYPE } from '../../constants/templates';
import {
  addTemplateRequest,
  addTemplateValue,
  clearTemplateValue,
  deleteTemplateRequest,
  editTemplateRequest,
  fetchEmailsTemplatesRequest,
  fetchNotesTemplatesRequest,
  setTemplateValue,
} from '../../store/actions/templates';
import {
  selectTemplateById,
  selectTemplateDetailsTemplate,
  selectTemplateInitialValues,
  selectTemplateValidationSchema,
} from '../../store/selectors/templates';
import { FormStatusEnum, IMaintenanceFormOutputData, MaintenanceFormStateEnum } from '../../types/forms';
import { ITemplateRouteParams } from '../../types/routing';
import { IApiError, KeysOfUnion, NotificationListEnum } from '../../types/shell';
import {
  IAddTemplateRequest,
  IEditTemplateRequest,
  TCollectiveEmailTemplate,
  TCollectiveTemplateItem,
  TemplateTypesEnum,
} from '../../types/templates';
import { isObjectsEqual } from '../../utils/helpers';
import notification from '../../utils/notification';
import { isTemplateEmail } from '../../utils/template';
import { handleApiError } from '../../utils/ui';

import styles from './EditTemplatesContainer.module.scss';

interface IProps {
  onAdd?: (...args: any) => any;
  getInitialValue: (type: TemplateTypesEnum) => Partial<TCollectiveEmailTemplate>;
}

const EditTemplatesContainer = ({ onAdd, getInitialValue }: IProps) => {
  const { flowId, itemId } = useParams<ITemplateRouteParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const templateInfo = useSelector(selectTemplateDetailsTemplate);
  const validationSchema = useSelector(selectTemplateValidationSchema);
  const originalItem = useSelector(selectTemplateById(itemId));
  const initialValues = useSelector(selectTemplateInitialValues);

  const mode = React.useMemo(() => {
    switch (flowId) {
      case URL_VARS.NEW:
        return MaintenanceFormStateEnum.Adding;
      case URL_VARS.EDIT:
        return MaintenanceFormStateEnum.Editing;
      case URL_VARS.VIEW:
        return MaintenanceFormStateEnum.Reading;
      default:
        return MaintenanceFormStateEnum.Reading;
    }
  }, [flowId]);

  const isValueChanged = React.useMemo(() => {
    switch (true) {
      case mode === MaintenanceFormStateEnum.Editing:
        if (!originalItem || !templateInfo) {
          return true;
        }
        return !isObjectsEqual(originalItem, templateInfo);
      case mode === MaintenanceFormStateEnum.Adding:
        const { templateType = TemplateTypesEnum.Note } = templateInfo || {};
        return !isObjectsEqual(templateInfo, getInitialValue(templateType));
      default:
        return false;
    }
  }, [mode, templateInfo, originalItem, getInitialValue]);

  const isInitialValid = React.useCallback(
    () => validationSchema.isValidSync(initialValues),
    [validationSchema, initialValues],
  );

  const dispatchSubmit = React.useCallback(
    (payload: IAddTemplateRequest | IEditTemplateRequest) => {
      switch (flowId) {
        case URL_VARS.EDIT:
          payload = payload as IEditTemplateRequest;
          return dispatch(editTemplateRequest(payload));
        case URL_VARS.NEW:
          payload = payload as IAddTemplateRequest;
          return dispatch(addTemplateRequest(payload));
        default:
          return null;
      }
    },
    [flowId, dispatch],
  );

  const handleSubmit = React.useCallback(
    (
      template: Partial<TCollectiveTemplateItem>,
      { resetForm, setSubmitting, setStatus, setErrors }: FormikHelpers<Partial<TCollectiveTemplateItem>>,
    ) => {
      if (!validationSchema.isValidSync(template)) {
        return;
      }

      return new Promise((resolve, reject) => {
        dispatchSubmit({ template, resolve, reject });
      })
        .then(() => {
          resetForm();
          setStatus(FormStatusEnum.Success);
          history.push(routes.templates.root);
          notification.success(NotificationListEnum.Success, { content: TemplateSuccessToastMessages[flowId] });
          dispatch(
            isTemplateEmail(template.templateType) ? fetchEmailsTemplatesRequest() : fetchNotesTemplatesRequest(),
          );
        })
        .catch((e: IApiError) => {
          setErrors({ api: e?.message } as FormikErrors<Partial<TCollectiveTemplateItem>>);
          setStatus(FormStatusEnum.Error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [validationSchema, dispatchSubmit, history, flowId, dispatch],
  );

  const form = useFormik<Partial<TCollectiveTemplateItem>>({
    initialStatus: FormStatusEnum.Start,
    initialValues,
    isInitialValid,
    validateOnMount: true,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const handleDeleteItem = React.useCallback(
    (closeModal?: () => void) => {
      const { templateType: type } = templateInfo || {};

      return new Promise((resolve, reject) => {
        if (itemId) {
          dispatch(deleteTemplateRequest({ itemId, type, resolve, reject }));
        }
      })
        .then(() => {
          notification.success(NotificationListEnum.Success, { content: 'Template was successfully deleted' });
        })
        .catch(handleApiError(`Something bad happened. Template wasn't deleted.`))
        .finally(() => {
          if (typeof closeModal === 'function') {
            closeModal();
          }
        });
    },
    [dispatch, templateInfo, itemId],
  );

  const handleCancel = React.useCallback(() => {
    if (originalItem) {
      dispatch(addTemplateValue(originalItem));
    }
  }, [dispatch, originalItem]);

  const handleFieldChange = React.useCallback(
    (
      name: KeysOfUnion<TCollectiveTemplateItem>,
      value: TemplateTypesEnum | string[] | string | number | boolean | undefined,
    ) => {
      if (name === TEMPLATE_TYPE) {
        dispatch(setTemplateValue(getInitialValue(value as TemplateTypesEnum)));
      } else {
        dispatch(addTemplateValue({ [name]: value }));
      }
    },
    [dispatch, getInitialValue],
  );

  React.useEffect(() => {
    if (!isEmpty(templateInfo)) {
      return;
    }

    if (flowId === URL_VARS.NEW) {
      if (typeof onAdd === 'function') {
        onAdd({ required: true });
      }
    }
    if (flowId === URL_VARS.EDIT && originalItem) {
      dispatch(addTemplateValue(originalItem));
    }
  }, [dispatch, originalItem, templateInfo, flowId, onAdd]);

  React.useEffect(() => {
    return () => {
      dispatch(clearTemplateValue());
    };
  }, []);

  return (
    <div className={cn(styles.container)}>
      <MaintenanceForm
        root={routes.templates.root}
        form={form}
        assetName={TEMPLATE_LABEL}
        mode={mode}
        onDelete={handleDeleteItem}
        onCancel={handleCancel}
        onDiscard={() => history.push(routes.templates.root)}
        onSave={form.isValid ? handleSubmit : undefined}
        isValueChanged={isValueChanged}
      >
        {(props: IMaintenanceFormOutputData) => {
          return <TemplateForm {...props} onChange={handleFieldChange} form={form} />;
        }}
      </MaintenanceForm>
    </div>
  );
};

export default EditTemplatesContainer;
