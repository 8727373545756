import { call, put, select, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { SKU_OPTION_IDS } from '../../constants/inventories';
import { endpoints, queryParams } from '../../constants/routing';
import { DISTRIBUTOR, SUPER_ADMIN } from '../../constants/users';
import { IResponse } from '../../types/http';
import {
  IAddInventoryItemRequest,
  IEditInventoryItemRequest,
  IFetchInventoryItemByIdRequest,
  IFetchInventoryItemResponse,
  IFetchInventoryItemsRequestPayload,
  IFetchInventoryItemsResponse,
  IFetchLowInventoryItemsRequest,
  IFetchLowInventoryItemsResponse,
  IInventoryItemCandidate,
  InventoryFilterTypesEnum,
  IUploadInventoryCSVRequestPayload,
  IUploadInventoryCSVResponsePayload,
} from '../../types/inventories';
import { IPromiseCallbacks, IReduxAction } from '../../types/redux';
import { IApiError, IDownloadCSVSuccessPayload, SupportedFileTypesEnum } from '../../types/shell';
import {
  getIsLowInventoryItemsNotificationShownToday,
  getIsLowInventoryItemsNotificationSnoozed,
} from '../../utils/helpers';
import { getQuery } from '../../utils/request';
import { hasPermission } from '../../utils/users';
import * as InventoriesActions from '../actions/inventories';
import * as ModalActions from '../actions/modals';
import { selectAdminType } from '../selectors/auth';
import { selectLowInventoryItems } from '../selectors/inventories';
import { selectCurrentOrganizationId } from '../selectors/organizations';
import { selectLowItemsShownAt, selectLowItemsSnoozedAt } from '../selectors/shell';
import { AuthRequest } from './helpers';

function* fetchInventoryItemsWorkerSaga(action: IReduxAction<IFetchInventoryItemsRequestPayload>) {
  try {
    const endpoint = `${apiUrl}${endpoints.inventoryItems}`;

    const { type, ...rest } = action.payload || {};

    const types = ((filters) => {
      if (Array.isArray(filters)) {
        return filters.filter((f) => f !== InventoryFilterTypesEnum.All);
      }

      return filters === InventoryFilterTypesEnum.All ? undefined : [filters];
    })(type);

    const response: IResponse<IFetchInventoryItemsResponse | IApiError> = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({
        types,
        ...rest,
      }),
    });

    if (!response.ok) {
      yield put(InventoriesActions.fetchInventoryItemsFailure(response.body as IApiError));
    } else {
      yield put(InventoriesActions.fetchInventoryItemsSuccess(response.body as IFetchInventoryItemsResponse));
    }
  } catch (error) {
    yield put(InventoriesActions.fetchInventoryItemsFailure(error));
  }
}

function* fetchLowInventoryItemsWorkerSaga(action: IReduxAction<IFetchLowInventoryItemsRequest>) {
  const { resolve, reject } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.lowInventoryItems}`;

    const response: IResponse<IFetchLowInventoryItemsResponse | IApiError> = yield call(AuthRequest, {
      endpoint,
      method: 'GET',
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }

      yield put(InventoriesActions.fetchLowInventoryItemsFailure(response.body as IApiError));
    } else {
      yield put(InventoriesActions.fetchLowInventoryItemsSuccess(response.body as IFetchLowInventoryItemsResponse));

      if ('low_inventory_items' in response.body && response.body.low_inventory_items) {
        yield call(lowInventoryWorkerSaga, action);
      } else {
        if (typeof resolve === 'function') {
          resolve(response.body);
        }
      }
    }
  } catch (error) {
    yield put(InventoriesActions.fetchLowInventoryItemsFailure(error));

    if (typeof reject === 'function') {
      reject(error);
    }
  }
}

function* fetchInventoryItemByIdWorkerSaga(action: IReduxAction<IFetchInventoryItemByIdRequest>) {
  const { itemId } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.inventoryItemById}`;
    const qs = {
      ...(itemId ? getQuery(itemId, queryParams.itemId) : {}),
    };

    const response: IResponse<IFetchInventoryItemResponse | IApiError> = yield call(AuthRequest, {
      queryParams: qs,
      endpoint,
    });

    if (!response.ok) {
      yield put(InventoriesActions.fetchInventoryItemByIdFailure(response.body as IApiError));
    } else {
      yield put(InventoriesActions.fetchInventoryItemByIdSuccess(response.body as IFetchInventoryItemResponse));
    }
  } catch (error) {
    yield put(InventoriesActions.fetchInventoryItemByIdFailure(error));
  }
}

function* addInventoryItemWorkerSaga(action: IReduxAction<IAddInventoryItemRequest>): Generator<any, any, any> {
  const { item = {}, resolve, reject } = action.payload || {};
  const { sku_options, ...restItemFields } = item;

  try {
    const endpoint = `${apiUrl}${endpoints.addInventoryItem}`;
    const response = yield call<any>(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({
        inventory_item: {
          ...restItemFields,
          [SKU_OPTION_IDS]:
            sku_options && sku_options.length
              ? sku_options.map(({ item_id }: IInventoryItemCandidate) => item_id)
              : undefined,
        },
      }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(InventoriesActions.addInventoryItemFailure(response.body));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(InventoriesActions.addInventoryItemSuccess(response.body));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(InventoriesActions.addInventoryItemFailure(error));
  }
}

function* editInventoryItemWorkerSaga(action: IReduxAction<IEditInventoryItemRequest>): Generator<any, any, any> {
  const { item = {}, resolve, reject } = action.payload || {};
  const { sku_options, ...restItemFields } = item;

  try {
    const endpoint = `${apiUrl}${endpoints.editInventoryItem}`;
    const response = yield call<any>(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({
        inventory_item: {
          ...restItemFields,
          [SKU_OPTION_IDS]:
            sku_options && sku_options.length
              ? sku_options.map(({ item_id }: IInventoryItemCandidate) => item_id)
              : undefined,
        },
      }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(InventoriesActions.editInventoryItemFailure(response.body));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(InventoriesActions.editInventoryItemSuccess(response.body));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(InventoriesActions.editInventoryItemFailure(error));
  }
}

function* deleteInventoryItemWorkerSaga(action: any): Generator<any, any, any> {
  const { itemId, resolve, reject } = action.payload || {};
  try {
    const orgId = yield select(selectCurrentOrganizationId);
    const endpoint = `${apiUrl}${endpoints.deleteInventoryItem}`;
    const qs = {
      ...(itemId ? { [queryParams.itemId]: itemId, [queryParams.orgId]: orgId } : {}),
    };

    const response = yield call<any>(AuthRequest, {
      queryParams: qs,
      endpoint,
      method: 'DELETE',
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(InventoriesActions.deleteInventoryItemFailure(response.body));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(InventoriesActions.deleteInventoryItemSuccess({ itemId }));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(InventoriesActions.deleteInventoryItemFailure(error));
  }
}

function* lowInventoryWorkerSaga(action: IReduxAction<IFetchLowInventoryItemsRequest>): Generator<any, any, any> {
  const userAdminType = yield select(selectAdminType);
  const isSuperOrDist = hasPermission([DISTRIBUTOR, SUPER_ADMIN], userAdminType);
  const lowInventoryNotificationSnoozedAt = yield select(selectLowItemsSnoozedAt);
  const lowInventoryNotificationShownAt = yield select(selectLowItemsShownAt);
  const lowInventoryItems = yield select(selectLowInventoryItems);

  if (lowInventoryItems && !isSuperOrDist) {
    const isLowItemsNotificationSnoozed = getIsLowInventoryItemsNotificationSnoozed(lowInventoryNotificationSnoozedAt);
    const isLowItemsNotificationShownToday = getIsLowInventoryItemsNotificationShownToday(
      lowInventoryNotificationShownAt,
    );

    if (!isLowItemsNotificationSnoozed && !isLowItemsNotificationShownToday) {
      yield put(ModalActions.toggleLowInventoryModal(true, action.metadata));
    }
  }

  if (action?.payload?.showModal) {
    yield put(ModalActions.toggleLowInventoryModal(true, action.metadata));
  }
}

function* downloadInventoryRequestWorkerSaga(action: IReduxAction<IPromiseCallbacks>) {
  const { resolve, reject } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.downloadInventoryReportCSV}`;
    const qs = { [queryParams.fileType]: SupportedFileTypesEnum.CSV };

    const response: IResponse<IDownloadCSVSuccessPayload | IApiError> = yield call(AuthRequest, {
      endpoint,
      queryParams: qs,
    });

    if (response.ok) {
      yield put(InventoriesActions.downloadInventoryReportCSVSuccess(response.body as IDownloadCSVSuccessPayload));
      resolve?.(response.body);
    } else {
      yield put(InventoriesActions.downloadInventoryReportCSVFailure(response.body as IApiError));
      reject?.(response.body);
    }
  } catch (e) {
    yield put(InventoriesActions.downloadInventoryReportCSVFailure(e));
    reject?.(e);
  }
}

function* bulkInventoryWorkerSaga(action: IReduxAction<IUploadInventoryCSVRequestPayload>): Generator<any, any, any> {
  const { resolve, reject, ...requestPayload } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.bulkCreateInventoryItemsFromCSV}`;
    const body = JSON.stringify(requestPayload);

    const response: IResponse<IUploadInventoryCSVResponsePayload | IApiError> = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      body,
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(InventoriesActions.uploadInventoryCSVFailure((response as IResponse<IApiError>).body));
    } else {
      yield put(
        InventoriesActions.uploadInventoryCSVSuccess((response as IResponse<IUploadInventoryCSVResponsePayload>).body),
      );
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
    }
  } catch (error) {
    yield put(InventoriesActions.uploadInventoryCSVFailure(error));
    if (typeof reject === 'function') {
      reject(error);
    }
  }
}

const sagas = {
  *watchInventoryItemsRequest() {
    yield takeLatest(InventoriesActions.FETCH_INVENTORY_ITEMS_REQUEST, fetchInventoryItemsWorkerSaga);
  },
  *watchLowInventoryItemsRequest() {
    yield takeLatest(InventoriesActions.FETCH_LOW_INVENTORY_ITEMS_REQUEST, fetchLowInventoryItemsWorkerSaga);
  },
  *watchInventoryItemByIdRequest() {
    yield takeLatest(InventoriesActions.FETCH_INVENTORY_ITEM_BY_ID_REQUEST, fetchInventoryItemByIdWorkerSaga);
  },
  *watchAddInventoryItemRequest() {
    yield takeLatest(InventoriesActions.ADD_INVENTORY_ITEM_REQUEST, addInventoryItemWorkerSaga);
  },
  *watchEditInventoryItemRequest() {
    yield takeLatest(InventoriesActions.EDIT_INVENTORY_ITEM_REQUEST, editInventoryItemWorkerSaga);
  },
  *watchDeleteInventoryItemRequest() {
    yield takeLatest(InventoriesActions.DELETE_INVENTORY_ITEM_REQUEST, deleteInventoryItemWorkerSaga);
  },
  *watchDownloadInventoryReportCSVRequest() {
    yield takeLatest(InventoriesActions.DOWNLOAD_INVENTORY_REPORT_CSV_REQUEST, downloadInventoryRequestWorkerSaga);
  },
  *watchUploadInventoryCSVRequest() {
    yield takeLatest(InventoriesActions.UPLOAD_INVENTORY_CSV_REQUEST, bulkInventoryWorkerSaga);
  },
};

export default sagas;
