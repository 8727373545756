import React, { useCallback, useMemo, useState } from 'react';

import { IChatGPTSelectorValue, ISelectorValue, UISizeEnum } from '../../types/shell';
import { AsyncButton, InputLabel, Selector } from '../forms';

import styles from './ImproveAISelector.module.scss';

interface IProps {
  label: string;
  onImproveText: (v: IChatGPTSelectorValue) => void;
  disabled?: boolean;
  isFetching?: boolean;
  options: IChatGPTSelectorValue[];
}

const ImproveAiSelector: React.FC<IProps> = ({ options, label, onImproveText, isFetching, disabled }) => {
  const [improvementOption, setImprovementOption] = useState<IChatGPTSelectorValue | null>(options[0]);

  const handleImproveOptionSelect = (v: ISelectorValue) => {
    const chosen = options.find((item) => v.value === item.value);
    setImprovementOption(chosen || null);
  };

  const selectedImproveOption: ISelectorValue | null = useMemo(
    () => (improvementOption ? { value: improvementOption.value, label: improvementOption.label } : null),
    [improvementOption],
  );

  const handleImproveClick = useCallback(() => {
    if (improvementOption) {
      onImproveText(improvementOption);
    }
  }, [improvementOption, onImproveText]);

  return (
    <div className={styles.improveContainer}>
      <InputLabel value={label} />
      <div className={styles.instructionContainer}>
        <Selector
          closeMenuOnSelect
          containerClassName={styles.selector}
          isClearable={false}
          isSearchable={false}
          value={selectedImproveOption}
          onChange={(v) => handleImproveOptionSelect(v as ISelectorValue)}
          options={options}
          isDisabled={disabled}
        />
        <AsyncButton
          isLoading={!!isFetching}
          disabled={disabled}
          hint={isFetching ? 'AI is generating your text' : undefined}
          loaderClassName={styles.loaderButton}
          onClick={handleImproveClick}
          className={styles.improveButton}
          size={UISizeEnum.Small}
          title={isFetching ? undefined : 'Improve with AI'}
        />
      </div>
    </div>
  );
};

export default ImproveAiSelector;
