import { createTransform } from 'redux-persist';

import { IShellState } from '../../types/shell';

const metadataTransform = createTransform(
  (stateIn: IShellState) => stateIn,
  (stateOut: IShellState) => {
    return {
      ...stateOut,
      emergencyNotification: null,
      suggestGiftSidenavIsShown: false,
      metadata: { ...stateOut.metadata, error: null, isLoading: false },
    };
  },
  { whitelist: ['shell'] },
);

const transformArray = [metadataTransform];

export default transformArray;
