import React from 'react';
import { useSelector } from 'react-redux';
import { useYbugApi, YbugProvider } from 'ybug-react';

import { selectFirebaseProfile } from '../store/selectors/auth';

interface IProps {
  children: React.ReactNode | React.ReactNode[];
}

const FeedbackProvider: React.FC<IProps> = ({ children }) => {
  return (
    <YbugProvider
      settings={{
        hide_launcher: true,
        shortcut: true,
      }}
      ybugId={process.env.REACT_APP_YBUG_PROJECT_ID || ''}
    >
      <UserProvider>{children}</UserProvider>
    </YbugProvider>
  );
};

const UserProvider: React.FC<IProps> = ({ children }) => {
  const YbugContext = useYbugApi();
  const userProfile = useSelector(selectFirebaseProfile);

  React.useEffect(() => {
    if (userProfile && YbugContext?.Ybug) {
      const { token: _, ...userInfo } = userProfile;
      YbugContext.init({
        feedback: {
          email: userInfo.email || '',
        },
        user: userInfo,
      });
    }
  }, [YbugContext, userProfile]);

  return <>{children}</>;
};

export default FeedbackProvider;
