import cn from 'classnames';
import * as React from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { IDropzoneResult } from '../../../../types/forms';
import Loader from '../../../Loader/Loader';

import styles from './UploadInput.module.scss';

interface IProps {
  onUpload?: (args: IDropzoneResult) => void;
  onSuccess?: (...args: any[]) => void;
  onFailure?: (...args: any[]) => void;
  onBlur?: (...args: any[]) => void;
  onDropReject?: (fileRejections: FileRejection[]) => void;
  className?: string;
  accept?: string | string[];
  multiple?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  children: (data: any) => React.ReactNode | React.ReactNode[];
  noDrag?: boolean;
  showLoader?: boolean;
  maxSize?: number;
  readOnly?: boolean;
  error?: string | string[];
  showErrors?: boolean;
}

const UploadInput: React.FC<IProps> = ({
  onUpload,
  onSuccess,
  onFailure,
  onDropReject,
  onBlur,
  className,
  accept,
  multiple,
  readOnly,
  children,
  onClick,
  noDrag = false,
  showLoader = true,
  maxSize,
  error,
  showErrors = false,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleDrop = React.useCallback(
    (files: File[], fileRejections: FileRejection[]) => {
      if (fileRejections && fileRejections.length) {
        if (typeof onDropReject === 'function') {
          onDropReject(fileRejections);
        }
      }

      if (files.length && typeof onUpload === 'function') {
        return new Promise((resolve, reject) => {
          setIsLoading(true);
          onUpload({ files, resolve, reject });
        })
          .then((response) => {
            if (typeof onSuccess === 'function') {
              onSuccess(files, response);
            }
          })
          .catch((err) => {
            if (typeof onFailure === 'function') {
              onFailure(err);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [onUpload, onSuccess, onFailure, onDropReject],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    maxSize,
    noDrag,
    onDrop: handleDrop,
    accept: accept || '',
    multiple,
  });

  const renderError = React.useMemo(() => {
    if (showErrors && error) {
      return Array.isArray(error) ? (
        error.map((e) => (
          <span key={e} className={styles.errorMessage}>
            {e}
          </span>
        ))
      ) : (
        <span className={styles.errorMessage}>{error}</span>
      );
    }
  }, [showErrors, error]);

  return (
    <div
      {...getRootProps({ onClick, onBlur })}
      className={cn(styles.uploadInput, className, {
        [styles.activeDrag]: isDragActive,
        [styles.error]: !!error,
        [styles.readOnly]: readOnly,
      })}
    >
      {showLoader && isLoading ? (
        <Loader className={styles.loader} isLoading />
      ) : (
        <React.Fragment>
          <input {...getInputProps()} />
          {children({ isDragActive, isDragReject })}
          {renderError}
        </React.Fragment>
      )}
    </div>
  );
};

export default UploadInput;
