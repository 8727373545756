import { createSelector } from 'reselect';
import { INITIAL_ZAPIER_FORM_STATE, USER_EMAIL } from '../../constants/integrations';
import { IAppState } from '../reducers';
import { selectUserProfile } from './auth';

export const selectRootState = (state: IAppState) => state.integrations;

export const selectCrmState = createSelector(selectRootState, (integrations) =>
  integrations ? integrations.crm : undefined,
);

export const selectEmailState = createSelector(selectRootState, (integrations) =>
  integrations ? integrations.email : undefined,
);

export const selectZapierState = createSelector(selectRootState, (integrations) =>
  integrations ? integrations.zapier : undefined,
);

export const selectZapierIsLoading = createSelector(selectZapierState, (zapier) => (zapier ? zapier.isLoading : false));

export const selectApiKeyWasLoaded = createSelector(selectZapierState, (zapier) => (zapier ? zapier.wasLoaded : false));

export const selectUserApiKey = createSelector(selectZapierState, (zapier) => zapier?.apiKey);

export const selectCrmClientId = createSelector(selectCrmState, (crm) => (crm ? crm.clientId : undefined));

export const selectIsCRMAuthenticated = createSelector(selectCrmState, (crm) => (crm ? crm.isAuthenticated : false));

export const selectCRMIsLoading = createSelector(selectCrmState, (crm) => (crm ? crm.isLoading : false));

export const selectCRMAuthInProgress = createSelector(selectCrmState, (crm) => (crm ? crm.isAuthInProgress : false));

export const selectCRMAuthError = createSelector(selectCrmState, (crm) => (crm ? crm.error : undefined));

export const selectCrmSearchItems = createSelector(selectCrmState, (crm) => (crm ? crm.searchItems : null));

export const selectIsQueryInProgress = createSelector(selectCrmState, (crm) => (crm ? crm.isSearching : false));

export const selectCRMIntegrationStatus = createSelector(
  selectCRMIsLoading,
  selectIsCRMAuthenticated,
  selectCRMAuthInProgress,
  selectCRMAuthError,
  (isLoading, isAuthenticated, isAuthInProgress, error) => ({ isLoading, isAuthenticated, isAuthInProgress, error }),
);

export const selectCRMIsLoadingOpportunities = createSelector(selectCrmState, (crm) =>
  crm ? crm.isLoadingOpportunities : false,
);

export const selectCrmOpportunities = createSelector(selectCrmState, (crm) => (crm ? crm.opportunities : null));

export const selectIsEmailIntegrationLoading = createSelector(selectEmailState, (email) =>
  email ? email.isLoading : false,
);

export const selectIsEmailAuthenticated = createSelector(selectEmailState, (email) =>
  email ? email.isAuthenticated : undefined,
);

export const selectIsEmailAuthInProgress = createSelector(selectEmailState, (email) =>
  email ? email.isAuthInProgress : false,
);

export const selectEmailIntegrationError = createSelector(selectEmailState, (email) =>
  email ? email.error : undefined,
);

export const selectEmailIntegrationStatus = createSelector(
  selectIsEmailIntegrationLoading,
  selectIsEmailAuthenticated,
  selectIsEmailAuthInProgress,
  selectEmailIntegrationError,
  (isLoading, isAuthenticated, isAuthInProgress, error) => ({ isLoading, isAuthenticated, isAuthInProgress, error }),
);

export const selectZapierInitialState = createSelector(selectUserProfile, (userProfile) => {
  const { email } = userProfile || {};
  return { ...INITIAL_ZAPIER_FORM_STATE, ...(email ? { [USER_EMAIL]: email } : {}) };
});
