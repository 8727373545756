import { call, put, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { endpoints } from '../../constants/routing';
import { IReduxAction } from '../../types/redux';
import { IEditSignatureRequestActionPayload } from '../../types/signature';
import * as SignatureActions from '../actions/signature';
import { AuthRequest } from './helpers';

function* editSignatureWorkerSaga(action: IReduxAction<IEditSignatureRequestActionPayload>): Generator<any, any, any> {
  const { signature, resolve, reject } = action.payload!;

  try {
    const endpoint = `${apiUrl}${endpoints.saveEmailSignature}`;

    const response = yield call(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({ signature }),
    });

    if (!response.ok) {
      if (reject) {
        reject(response.body);
      }
      yield put(SignatureActions.editSignatureFailure(response.body));
    } else {
      if (resolve) {
        resolve({ signature });
      }
      yield put(SignatureActions.editSignatureSuccess({ signature }));
    }
  } catch (error) {
    if (reject) {
      reject(error);
    }
    yield put(SignatureActions.editSignatureFailure(error));
  }
}

const sagas = {
  *watchEditSignatureWatcher() {
    yield takeLatest(SignatureActions.EDIT_SIGNATURE_REQUEST, editSignatureWorkerSaga);
  },
};

export default sagas;
