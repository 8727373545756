import { IChatGPTSelectorValue } from '../types/shell';
import { EMAIL_SUBJECT } from './templates';

export const IS_AI_SUGGEST_ENABLED = process.env.REACT_APP_AI_SUGGEST_ENABLED === 'true';

const EMAIL_LAYOUT_INSTRUCTION = [
  'Use HTML layout in your formatting (use HTML tags like <div>, <span>, <p>, <br> and other)',
  'Do not use link tags like "a" and do not change "img" tags content',
  'Always preserve untouchable keys inside brackets []',
];

const HANDWRITTEN_NOTE_INSTRUCTION = ['Keep this note short, up to 250 letters'];

const generateTextImproveInstruction = (instructions: string[]) => {
  const preparedInstruction = 'following these instructions: ';

  return instructions.reduce((acc, instruction, index) => {
    return (acc += `${index + 1}. ${instruction} `);
  }, preparedInstruction);
};
export const AI_DID_NO_CHANGES_WARNING =
  'AI can not edit text at the moment. Please change the text or try again later.';

const generateEmailImproveInstruction = (message: string, instructions: string[]) =>
  `Please, help me improve this email content: "${message}", ${generateTextImproveInstruction([
    ...instructions,
    ...EMAIL_LAYOUT_INSTRUCTION,
  ])}`;

const generateNoteImproveInstruction = (message: string, instructions: string[]) =>
  `Please, help me improve this note: "${message}", ${generateTextImproveInstruction([
    ...instructions,
    ...HANDWRITTEN_NOTE_INSTRUCTION,
  ])}`;

export const IMPROVE_EMAIL_INSTRUCTIONS: IChatGPTSelectorValue[] = [
  {
    value: `more formal`,
    label: 'More formal',
    instruction: (text: string) => generateEmailImproveInstruction(text, ['Use more official language']),
    config: {
      temperature: 1,
    },
  },
  {
    value: `more casual and informal`,
    label: 'More casual',
    instruction: (text: string) =>
      generateEmailImproveInstruction(text, ['Make email tone to be more casual and informal']),
    config: {
      temperature: 1,
    },
  },
  {
    value: `shorten email content`,
    label: 'Shorten text',
    instruction: (text: string) => generateEmailImproveInstruction(text, ['Use less words than me']),
    config: {
      temperature: 1,
    },
  },
  {
    value: 'change subject',
    label: 'Change subject',
    instruction: (text: string) =>
      `Tailor subject line to match email content and return just subject text, but NEVER use tags inside brackets [] (like this [Sender.CompanyName]) for this: ${text}`,
    fieldName: EMAIL_SUBJECT,
    config: {
      temperature: 1,
    },
  },
  {
    value: `correct grammar`,
    label: 'Correct grammar',
    instruction: (text: string) => generateEmailImproveInstruction(text, ['Identify and correct grammatical mistakes']),
    config: {
      temperature: 0,
    },
  },
  {
    value: 'improve readability',
    label: 'Improve readability',
    instruction: (text: string) =>
      generateEmailImproveInstruction(text, ['Improve email structure for readability and coherence']),
    config: {
      temperature: 1,
    },
  },
];

export const IMPROVE_HANDWRITTEN_NOTE_INSTRUCTIONS: IChatGPTSelectorValue[] = [
  {
    value: `Use more official language in the message`,
    label: 'More formal',
    instruction: (text: string) => generateNoteImproveInstruction(text, ['Use more official language in the message']),
    config: {
      temperature: 1,
    },
  },
  {
    value: `Adjust the message tone to be more casual`,
    label: 'More casual',
    instruction: (text: string) => generateNoteImproveInstruction(text, ['Adjust the message tone to be more casual']),
    config: {
      temperature: 1,
    },
  },
  {
    value: `Shorten the message content`,
    label: 'Shorten text',
    instruction: (text: string) => generateNoteImproveInstruction(text, ['Use less words than me']),
    config: {
      temperature: 1,
    },
  },
  {
    value: `Identify and correct grammatical mistakes`,
    label: 'Correct grammar',
    instruction: (text: string) => generateNoteImproveInstruction(text, ['Identify and correct grammatical mistakes']),
    config: {
      temperature: 0,
    },
  },
  {
    value: `Improve message structure for readability and coherence`,
    label: 'Improve readability',
    instruction: (text: string) =>
      generateNoteImproveInstruction(text, ['Improve message structure for readability and coherence']),
    config: {
      temperature: 1,
    },
  },
];
