import { call, put, select, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { endpoints, queryParams } from '../../constants/routing';
import { IReduxAction } from '../../types/redux';
import { IAddUserActionPayload, IDeleteUserActionPayload, IEditUserActionPayload } from '../../types/users';
import { selectCurrentOrganizationId } from '../selectors/organizations';
import { AuthRequest } from './helpers';

import * as UsersActions from '../actions/users';

function* usersWorkerSaga(): Generator<any, any, any> {
  try {
    const endpoint = `${apiUrl}${endpoints.users}`;

    const response = yield call<any>(AuthRequest, { endpoint });

    if (!response.ok) {
      yield put(UsersActions.fetchUsersFailure(response.body));
    } else {
      yield put(UsersActions.fetchUsersSuccess(response.body));
    }
  } catch (error) {
    yield put(UsersActions.fetchUsersFailure(error));
  }
}

function* addUserWorkerSaga(action: IReduxAction<IAddUserActionPayload>): Generator<any, any, any> {
  const { user, resolve, reject } = action.payload!;

  // tslint:disable-next-line:variable-name
  const org_id = yield select(selectCurrentOrganizationId);

  try {
    const endpoint = `${apiUrl}${endpoints.addUser}`;

    const response = yield call(AuthRequest, { endpoint, method: 'POST', body: JSON.stringify({ ...user, org_id }) });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(UsersActions.addUserFailure(response.body));
    } else {
      yield put(UsersActions.addUserSuccess({ ...user, ...response.body }));

      if (typeof resolve === 'function') {
        resolve();
      }

      // TODO: Remove the following line after the endpoint returns UID of our newly created user
      yield put(UsersActions.fetchUsersRequest());
    }
  } catch (e) {
    if (typeof reject === 'function') {
      reject();
    }
    yield put(UsersActions.addUserFailure(e));
  }
}

function* editUserWorkerSaga(action: IReduxAction<IEditUserActionPayload>): Generator<any, any, any> {
  const { user, resolve, reject } = action.payload!;

  try {
    const endpoint = `${apiUrl}${endpoints.editUser}`;

    const response = yield call(AuthRequest, { endpoint, method: 'POST', body: JSON.stringify({ ...user }) });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(UsersActions.editUserFailure(response.body));
    } else {
      yield put(UsersActions.editUserSuccess(user));
      if (typeof resolve === 'function') {
        resolve();
      }
    }
  } catch (e) {
    if (typeof reject === 'function') {
      reject(e);
    }
    yield put(UsersActions.editUserFailure(e));
  }
}

function* deleteUserWorkerSaga(action: IReduxAction<IDeleteUserActionPayload>): Generator<any, any, any> {
  const { userId, newOwnerId, resolve, reject } = action.payload!;
  try {
    const endpoint = `${apiUrl}${endpoints.deleteUser}`;
    const qs = {
      [queryParams.uid]: userId,
      ...(newOwnerId ? { [queryParams.newOwnerId]: newOwnerId } : {}),
    };

    const response = yield call(AuthRequest, { endpoint, method: 'DELETE', queryParams: qs });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(UsersActions.deleteUserFailure(response.body));
    } else {
      yield put(UsersActions.deleteUserSuccess({ userId }));
      if (typeof resolve === 'function') {
        resolve();
      }
    }
  } catch (e) {
    if (typeof reject === 'function') {
      reject(e);
    }
    yield put(UsersActions.editUserFailure(e));
  }
}

const sagas = {
  *watchUsersRequest() {
    yield takeLatest(UsersActions.FETCH_USERS_REQUEST, usersWorkerSaga);
  },
  *watchAddUserRequest() {
    yield takeLatest(UsersActions.ADD_USER_REQUEST, addUserWorkerSaga);
  },
  *watchEditUserRequest() {
    yield takeLatest(UsersActions.EDIT_USER_REQUEST, editUserWorkerSaga);
  },
  *watchDeleteUserRequest() {
    yield takeLatest(UsersActions.DELETE_USER_REQUEST, deleteUserWorkerSaga);
  },
};

export default sagas;
