import cn from 'classnames';
import * as React from 'react';

import SummaryIcon from '../../../../assets/images/icon-chevron-right.svg';
import { routes, URL_VARS } from '../../../../constants/routing';
import { IDashboardDelayedPackage } from '../../../../types/dashboard';
import { ReportTypesEnum } from '../../../../types/reports';
import { formatDate } from '../../../../utils/date';
import { ReportsCopyableButton, TextIconButton } from '../../../forms';

import styles from './DelayedPackageItem.module.scss';

interface IProps {
  className?: string;
  item: IDashboardDelayedPackage;
}

const DelayedPackageItem: React.FC<IProps> = ({ className, item: { uid, shipped_date, tracking_url, order_id } }) => {
  const handleViewSummaryClick = React.useCallback(() => {
    if (!uid) {
      return;
    }
    window.open(
      routes.reports.getReportsUrl({ reportId: uid, flowId: URL_VARS.SUMMARY, type: ReportTypesEnum.Regular }),
      '_blank',
    );
  }, [uid]);

  return (
    <div className={cn(className, styles.item)}>
      <span className={styles.code} title={`Shipped date: ${formatDate(shipped_date)}`}>
        {order_id}
      </span>
      <ReportsCopyableButton hint="Copy link" className={styles.copyButton} isIcon value={tracking_url} />
      <TextIconButton
        hint="View Summary"
        trigger
        icon={SummaryIcon}
        onClick={handleViewSummaryClick}
        className={styles.viewSummaryButton}
      />
    </div>
  );
};

export default DelayedPackageItem;
