import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as WarningIcon } from '../../../assets/images/icon-warning.svg';

import styles from './WarningMessage.module.scss';

export interface IWarningMessageProps {
  message: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
  className?: string;
  mode?: 'warning' | 'error';
}

const WarningMessage: React.FC<IWarningMessageProps> = ({ className, message, icon, mode = 'warning' }) => {
  const Icon = React.useMemo(() => {
    return icon ? icon : WarningIcon;
  }, [icon]);

  return (
    <div className={cn(styles.container, className, styles[mode])}>
      <Icon className={styles.icon} />
      <span className={styles.message}>{message}</span>
    </div>
  );
};

export default WarningMessage;
