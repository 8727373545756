import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ActionButton,
  InventoryItem,
  InventoryThumbItem,
  Loader,
  NoResultsPlaceholder,
  PaginatedList,
} from '../../../components';
import { IPaginatedListChangeEvent, IPaginatedListRef } from '../../../components/PaginatedList/PaginatedList';
import { MAX_INVENTORY_ITEMS_PER_PAGE } from '../../../constants/inventories';
import useSearchFilter from '../../../hooks/useSearchFilter/useSearchFilter';
import { fetchInventoryItemsRequest } from '../../../store/actions/inventories';
import {
  selectInventoryItems,
  selectInventoryTotalCount,
  selectIsFinalInventoryItem,
  selectIsInventoryFetching,
} from '../../../store/selectors/inventories';
import { ICampaignItem } from '../../../types/campaigns';
import {
  IFetchInventoryItemsRequestMetadata,
  IFetchInventoryItemsRequestPayload,
  IInventoryItem,
  InventoryFilterTypesEnum,
} from '../../../types/inventories';
import { IReportEngagementItem } from '../../../types/reports';
import { getCampaignFCID, getCanRemoveItemFromCampaign } from '../../../utils/campaigns';
import { isItemMsku, isItemOutOfStock } from '../../../utils/inventories';
import { getShipmentInfoIsNotConfirmed } from '../../../utils/reports';
import { actions, reducer } from './reducer';

import styles from './AddSendItemsContainer.module.scss';

export interface IAddItemsRef {
  items?: IReportEngagementItem[] | null;
  getValues: () => IReportEngagementItem[];
}

export interface IAddSendItemsContainerProps {
  reportItems?: IReportEngagementItem[] | null;
  status?: string;
  onSubmit?: (items: IReportEngagementItem[]) => void;
  className?: string;
  canRemoveAll?: boolean;
  isShown?: boolean;
  onCancel?: () => void;
  isDirectSend?: boolean;
}

const AddSendItemsContainer = React.forwardRef(
  (
    {
      reportItems,
      status,
      onSubmit,
      className,
      canRemoveAll = true,
      isShown,
      onCancel,
      isDirectSend,
    }: IAddSendItemsContainerProps,
    ref: React.Ref<IAddItemsRef>,
  ) => {
    const dispatch = useDispatch();

    const paginatedListRef = React.useRef<IPaginatedListRef<IInventoryItem>>(null);
    const [items, changeItems] = React.useReducer(reducer(reportItems), reportItems || []);

    const { SearchInput, query } = useSearchFilter([], [], {
      className: styles.searchForm,
      placeholder: 'Lookup items',
      hint: 'Look up specific items by their name or SKU.',
    });

    const inventoryItems = useSelector(selectInventoryItems());
    const inventoryCount = useSelector(selectInventoryTotalCount);
    const isInventoryFetching = useSelector(selectIsInventoryFetching);
    const isFinalInventoryItem = useSelector(selectIsFinalInventoryItem);

    const campaignFulfillmentCenterId = React.useMemo(() => getCampaignFCID(items) || undefined, [items]);

    const isShipmentInfoNotConfirmed = React.useMemo(() => {
      return status ? getShipmentInfoIsNotConfirmed({ ship_order_status: status }) : false;
    }, [status]);

    const isValueChanged = React.useMemo(() => {
      return !isEqual(items, reportItems);
    }, [items, reportItems]);

    const handleRemoveItem = React.useCallback((itemId: string) => {
      changeItems(actions.remove(itemId));
    }, []);

    const handleAddItem = React.useCallback((item: IReportEngagementItem | ICampaignItem) => {
      changeItems(actions.add(item));
    }, []);

    const handleSubmit = React.useCallback(() => {
      if (onSubmit) {
        onSubmit(items);
      }
    }, [onSubmit, items]);

    const checkIsItemSelected = React.useCallback(
      (itemId: string) => {
        return items?.map((item) => item.item_id).includes(itemId);
      },
      [items],
    );

    const getRequestInfo = React.useCallback(
      (payload?: Partial<IFetchInventoryItemsRequestPayload>) => {
        return {
          fulfillment_center_id: campaignFulfillmentCenterId,
          type: isDirectSend ? [InventoryFilterTypesEnum.Physical] : [],
          page: payload?.page || 1,
          page_size: MAX_INVENTORY_ITEMS_PER_PAGE,
          ...(query && { search_query: query }),
          ...payload,
        };
      },
      [query, campaignFulfillmentCenterId, isDirectSend],
    );

    const fetchInventory = React.useCallback(
      (payload?: Partial<IFetchInventoryItemsRequestPayload>, metadata?: IFetchInventoryItemsRequestMetadata) =>
        dispatch(fetchInventoryItemsRequest(getRequestInfo(payload), metadata ?? { shouldClearItems: true })),
      [dispatch, getRequestInfo],
    );

    const handlePaginationChange = React.useCallback(
      ({ page, setPage }: IPaginatedListChangeEvent) => {
        fetchInventory({ page }, { shouldClearItems: false });
        // In case there's an error on the endpoint, paginator will change the page number, which is not correct.
        // The fix is to wrap the request with the Promise and call the `setPage(page)` after the Promise is resolved
        setPage(page);
      },
      [fetchInventory],
    );

    const renderItems = React.useMemo(() => {
      return items.map((item) => {
        const isRemovableItem = canRemoveAll
          ? getCanRemoveItemFromCampaign(items, item.item_id)
          : items?.length > 1 && getCanRemoveItemFromCampaign(items, item.item_id);
        return (
          <InventoryThumbItem
            title={item.name}
            imageUrl={item.image_url}
            quantity={item.quantity}
            itemId={item.item_id}
            key={item.item_id}
            onRemove={isRemovableItem ? handleRemoveItem : undefined}
            isRemoveEnabled
          />
        );
      });
    }, [items, canRemoveAll, handleRemoveItem]);

    React.useEffect(() => {
      if (!isInventoryFetching) {
        fetchInventory();
      }
    }, [fetchInventory]);

    React.useImperativeHandle(
      ref,
      () => ({
        getValues: () => items,
      }),
      [items],
    );

    React.useEffect(() => {
      if (!isShown && reportItems?.length && items.length > 0) {
        changeItems(actions.init(reportItems || []));
      }
    }, [isShown, reportItems]);

    return (
      <div className={cn(styles.container, className)}>
        <div className={styles.addedItemsContainer}>{renderItems}</div>
        <div className={styles.searchFormContainer}>{SearchInput}</div>
        <div className={styles.paginatedListContainer}>
          {isShown && <Loader className={styles.loader} isLoading={isInventoryFetching} />}
          <PaginatedList<IInventoryItem>
            totalCount={inventoryCount}
            ref={paginatedListRef}
            items={inventoryItems || ([] as IInventoryItem[])}
            pageSize={MAX_INVENTORY_ITEMS_PER_PAGE}
            onChange={handlePaginationChange}
            isFinalItems={isFinalInventoryItem}
          >
            {({ items: inventoryList, pagination: Pagination }) =>
              inventoryItems && inventoryList.length ? (
                <React.Fragment>
                  <div className={styles.itemsList}>
                    {inventoryList?.map((item: IInventoryItem) => {
                      const {
                        item_id: itemId,
                        name,
                        image_url: icon,
                        price,
                        count,
                        type,
                        sku_options: skuOptions,
                        item_customization_allowed: isCustomizable,
                        fulfillment_center_id,
                        preorder_allowed: isPreorderAllowed,
                      } = item;
                      const isMultipleSkuItem = isItemMsku({ type });
                      const outOfStock = isItemOutOfStock(item);
                      const isDisabled =
                        (!isShipmentInfoNotConfirmed && (isCustomizable || isMultipleSkuItem)) || outOfStock;

                      const handleItemClick = (inventoryCandidate: IReportEngagementItem) =>
                        checkIsItemSelected(itemId) ? handleRemoveItem(itemId) : handleAddItem(inventoryCandidate);

                      return (
                        <InventoryItem
                          fulfillment_center_id={fulfillment_center_id}
                          itemId={itemId}
                          title={name}
                          icon={icon}
                          price={price}
                          count={count}
                          type={type}
                          skuOptions={skuOptions}
                          key={itemId}
                          isSelected={checkIsItemSelected(itemId)}
                          isDisabled={isDisabled}
                          isCustomizable={isCustomizable}
                          className={styles.item}
                          iconClassName={styles.iconInner}
                          isPreorder={isPreorderAllowed}
                          onSelect={() => handleItemClick(item as IReportEngagementItem)}
                        />
                      );
                    })}
                  </div>
                  <div className={styles.controlsContainer}>
                    <Pagination className={styles.pagination} />
                    <div className={styles.buttonsContainer}>
                      {isValueChanged && (
                        <ActionButton
                          title="Cancel"
                          className={cn(styles.controlButton, styles.cancelButton)}
                          outlined={true}
                          onClick={onCancel}
                        />
                      )}
                      <ActionButton
                        title="Apply Changes"
                        className={styles.controlButton}
                        disabled={!isValueChanged}
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <NoResultsPlaceholder />
              )
            }
          </PaginatedList>
        </div>
      </div>
    );
  },
);

export default AddSendItemsContainer;
