import {
  EMAIL_MESSAGE,
  EMAIL_SUBJECT,
  EMAIL_TEMPLATE_ID,
  MESSAGE,
  NOTE_TEMPLATE_ID,
  RECEIVER_EMAIL,
} from '../constants/templates';
import { IPromiseCallbacks } from './redux';

export enum TemplateFilterTypesEnum {
  All,
  Note,
  Email,
}

export enum TemplateTypesEnum {
  Note = 'Custom message',
  Email = 'Email',
}

export enum EmailTemplateTypesEnum {
  DELAYED_SHIPPING = 'delayed_shipping',
  DIGITAL_GIFT = 'digital_gift',
}

export interface IAddEmailFormValues {
  [RECEIVER_EMAIL]: string;
  [EMAIL_SUBJECT]: string;
  [EMAIL_MESSAGE]: string;
  [EMAIL_TEMPLATE_ID]: string | null;
}

export interface IAddNoteFormValues {
  [MESSAGE]: string;
  [NOTE_TEMPLATE_ID]: string | null;
}

export interface INoteTemplate {
  body: string;
  department_ids: string[];
  is_default: boolean;
  title: string;
  item_id: string;
  org_id: string;
}

export interface IEmailTemplate {
  body: string;
  department_ids: string[];
  item_id: string;
  subject: string;
  type: EmailTemplateTypesEnum;
  is_default?: boolean;
}

// This types used only for the Templates feature in the common list of all templates
export type TCollectiveNoteTemplate = INoteTemplate & { templateType: TemplateTypesEnum };
export type TCollectiveEmailTemplate = IEmailTemplate & { templateType: TemplateTypesEnum };
export type TCollectiveTemplateItem = TCollectiveNoteTemplate | TCollectiveEmailTemplate;

export interface ITemplatesState {
  emails: {
    templates: IEmailTemplate[] | null;
    isLoading: boolean;
    error: any;
  };
  notes: {
    templates: INoteTemplate[] | null;
    isLoading: boolean;
    error: any;
  };
  templateDetails: {
    template: Partial<TCollectiveTemplateItem> | null;
    isLoading: boolean;
    error: any;
  };
}

export interface IAddTemplateRequest extends IPromiseCallbacks {
  template: Partial<TCollectiveTemplateItem>;
}

export interface IEditTemplateRequest extends IPromiseCallbacks {
  item: Partial<TCollectiveTemplateItem>;
}
