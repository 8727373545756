import cn from 'classnames';
import * as React from 'react';

import styles from './ItemQuantity.module.scss';

export interface IItemQuantityProps {
  quantity: number;
  className?: string;
  valueClassName?: string;
}

const ItemQuantity: React.FC<IItemQuantityProps> = ({ className, valueClassName, quantity = 0 }) => {
  return quantity > 1 ? (
    <div className={cn(styles.itemQuantityContainer, className)}>
      <span className={cn(styles.quantityValue, valueClassName)}>{`x${quantity}`}</span>
    </div>
  ) : null;
};

export default ItemQuantity;
