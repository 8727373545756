import classnames from 'classnames';
import * as React from 'react';

import styles from './InlineSpinner.module.scss';

interface IProps {
  isLoading?: boolean;
  className?: string;
}

export default ({ isLoading = true, className }: IProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className={classnames(styles.ldsEllipsis, className)}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
