import React from 'react';

import { IHeaderConfig } from '../types/shell';

interface IDispatchDictionary<T> {
  [key: string]: T;
}

const HeaderInfoContext = React.createContext<IHeaderConfig | undefined>(undefined);
const HeaderInfoDispatchContext = React.createContext<IDispatchDictionary<IHeaderConfig['action']> | undefined>(
  undefined,
);

interface IProps {
  children: React.ReactNode | React.ReactNode[];
}

const HeaderInfoProvider: React.FC<IProps> = ({ children }) => {
  const [headerTitle, setTitle] = React.useState<string | React.ReactNode>('');
  const [headerAction, setHeaderAction] = React.useState<IHeaderConfig['action'] | undefined>(undefined);
  const [showHeader, setShowHeader] = React.useState<boolean>(true);
  const [className, setClassName] = React.useState<string | undefined>();
  const [additionalData, setAdditionalData] = React.useState<React.ReactNode | undefined>(undefined);

  const setAction = React.useCallback((action: IHeaderConfig['action']) => {
    setHeaderAction(() => action);
  }, []);

  const setHeader = React.useCallback(
    ({ title, action, isShowHeader }: IHeaderConfig) => {
      if (title !== undefined && title !== null) {
        setTitle(title);
      }
      setAction(action);
      setShowHeader(isShowHeader !== undefined && isShowHeader !== null ? isShowHeader : true);
    },
    [setAction],
  );

  const headerState = React.useMemo(() => {
    return {
      title: headerTitle,
      action: headerAction,
      isShowHeader: showHeader,
      additionalData,
      className,
    };
  }, [headerTitle, headerAction, showHeader, additionalData, className]);

  const headerDispatch = React.useMemo(() => {
    return { setHeader, setTitle, setAction, setShowHeader, setAdditionalData, setClassName };
  }, [setHeader, setAction]);

  return (
    <HeaderInfoContext.Provider value={headerState}>
      <HeaderInfoDispatchContext.Provider value={headerDispatch}>{children}</HeaderInfoDispatchContext.Provider>
    </HeaderInfoContext.Provider>
  );
};

const useHeaderState = () => {
  const context = React.useContext(HeaderInfoContext);
  if (context === undefined) {
    throw new Error('useHeaderState must be used within a HeaderInfoProvider');
  }
  return context;
};

const useHeaderDispatch = () => {
  const context = React.useContext(HeaderInfoDispatchContext);
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a HeaderInfoProvider');
  }
  return context;
};

export { HeaderInfoProvider, useHeaderState, useHeaderDispatch };
