import * as React from 'react';

import AddressBookIcon from '../assets/images/icon-address-book.svg';
import BusinessReasonsIcon from '../assets/images/icon-business-reasons.svg';
import AdminToolsFlowIcon from '../assets/images/icon-flow-admin-tools.svg';
import AssetsFlowIcon from '../assets/images/icon-flow-assets.svg';
import AutomatedCampaignsFlowIcon from '../assets/images/icon-flow-automated-campaigns.svg';
import BulkEngIcon from '../assets/images/icon-flow-bulk-eng.svg';
import CampaignsFlowIcon from '../assets/images/icon-flow-campaigns.svg';
import DirectSendFlowIcon from '../assets/images/icon-flow-direct-send.svg';
import EngagementsManagerFlowIcon from '../assets/images/icon-flow-engagements-manager.svg';
import IntegrationFlowIcon from '../assets/images/icon-flow-integration.svg';
import InventoryFlowIcon from '../assets/images/icon-flow-inventory.svg';
import OneLinkManagerFlowIcon from '../assets/images/icon-flow-one-link-manager.svg';
import OneLinkFlowIcon from '../assets/images/icon-flow-one-link.svg';
import OrganizationsFlowIcon from '../assets/images/icon-flow-organizations.svg';
import ReportsIcon from '../assets/images/icon-flow-reports.svg';
import SingleEngIcon from '../assets/images/icon-flow-single-eng.svg';
import TemplatesFlowIcon from '../assets/images/icon-flow-templates.svg';
import UsersFlowIcon from '../assets/images/icon-flow-users.svg';

import { ReactComponent as AutomatedCampaignsIcon } from '../assets/images/icon-automations.svg';
import { ReactComponent as BulkIcon } from '../assets/images/icon-bulk.svg';
import { ReactComponent as CreateIcon } from '../assets/images/icon-create.svg';
import { ReactComponent as DeliveryIcon } from '../assets/images/icon-delivery.svg';
import { ReactComponent as DirectSendIcon } from '../assets/images/icon-direct-send.svg';
import { ReactComponent as AdminToolsIcon } from '../assets/images/icon-gear.svg';
import { ReactComponent as HomeIcon } from '../assets/images/icon-home.svg';
// import { ReactComponent as InformationIcon } from '../assets/images/icon-information.svg';
import { ReactComponent as InventoryIcon } from '../assets/images/icon-inventory-sidebar.svg';
import { ReactComponent as OneLinkIcon } from '../assets/images/icon-one-link.svg';
import { ReactComponent as IntegrationIcon } from '../assets/images/icon-puzzle.svg';
import { ReactComponent as UsersIcon } from '../assets/images/icon-users.svg';

import { TFlowOption } from '../types/dashboard';
import { TSidenavItem } from '../types/sidenav';
import { routes } from './routing';
import { DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from './users';

export const mainMenuItems: TSidenavItem[] = [
  { icon: HomeIcon, title: 'Home', route: routes.dashboard, usedInMobile: true, usedInDesktop: true },
  {
    icon: CreateIcon,
    title: 'Create Send',
    route: routes.engagement.new,
    usedInMobile: true,
    usedInDesktop: true,
  },
  {
    icon: BulkIcon,
    title: 'Create Bulk Send',
    route: routes.engagement.bulk,
    usedInMobile: true,
    usedInDesktop: true,
    roles: [DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN],
  },
  {
    icon: DirectSendIcon,
    title: 'Direct Send',
    route: routes.engagement.directSend,
    usedInMobile: true,
    usedInDesktop: true,
  },
  {
    icon: OneLinkIcon,
    title: 'Create One Link Send',
    route: routes.engagement.oneLink,
    usedInMobile: true,
    usedInDesktop: true,
    roles: [DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN],
  },
  {
    icon: AutomatedCampaignsIcon,
    title: 'Automated Campaigns',
    route: routes.automations,
    usedInMobile: true,
    usedInDesktop: true,
    roles: [DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN],
  },
  {
    icon: DeliveryIcon,
    title: 'Delivery Reports',
    route: routes.reports.root,
    usedInMobile: true,
    usedInDesktop: true,
  },
  {
    icon: IntegrationIcon,
    title: 'Integrations',
    route: routes.integrations,
    usedInMobile: true,
    usedInDesktop: true,
  },
  {
    icon: UsersIcon,
    title: 'Users',
    route: routes.users.root,
    usedInMobile: true,
    usedInDesktop: true,
    roles: [DEPT_ADMIN, ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN],
  },
  {
    icon: InventoryIcon,
    title: 'Assets Manager',
    usedInMobile: true,
    usedInDesktop: true,
    subItems: [
      {
        title: 'Campaigns',
        route: '/campaigns',
        usedInMobile: true,
        usedInDesktop: true,
        isSubItem: true,
      },
      {
        title: 'Inventory',
        route: routes.inventory.root,
        usedInMobile: true,
        usedInDesktop: true,
        isSubItem: true,
      },
      {
        title: 'Templates',
        route: routes.templates.root,
        usedInMobile: true,
        usedInDesktop: true,
        isSubItem: true,
      },
      {
        title: 'Organizations',
        route: routes.organizations.root,
        usedInMobile: true,
        usedInDesktop: true,
        isSubItem: true,
        roles: [DISTRIBUTOR, SUPER_ADMIN],
      },
      {
        title: 'Business Reasons',
        route: routes.businessReasons,
        usedInMobile: true,
        usedInDesktop: true,
        isSubItem: true,
      },
      {
        title: 'Address Book',
        route: routes.addressBook,
        usedInMobile: true,
        usedInDesktop: true,
        isSubItem: true,
      },
    ],
  },
  {
    icon: AdminToolsIcon,
    title: 'Admin Tools',
    usedInMobile: true,
    usedInDesktop: true,
    subItems: [
      {
        title: 'Send Manager',
        route: routes.engagementsManager,
        usedInMobile: true,
        usedInDesktop: true,
        isSubItem: true,
      },
      {
        title: 'One Link Manager',
        route: routes.oneLinkManager.root,
        usedInMobile: true,
        usedInDesktop: true,
        isSubItem: true,
        roles: [DEPT_ADMIN, ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN],
      },
    ],
  },

  // { icon: SettingsIcon, title: 'Settings', route: routes.settings, usedInMobile: false, usedInDesktop: true, },
];

export const additionMenuItems: TSidenavItem[] = [
  // { icon: InformationIcon, title: 'Helpdesk', route: routes.userInfo, usedInMobile: true, usedInDesktop: false, },
  // { icon: SettingsIcon, title: 'Settings', route: routes.settings, usedInMobile: true, usedInDesktop: false, },
];

export const dashboardItems: TFlowOption[] = [
  {
    key: 'single-engagement',
    title: 'Create Send',
    description: 'Generate a send to one person',
    link: routes.engagement.new,
    icon: SingleEngIcon,
  },
  {
    key: 'bulk-engagement',
    title: 'Create Bulk Send',
    description: 'Generate the same send to multiple people',
    link: routes.engagement.bulk,
    icon: BulkEngIcon,
    roles: [DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN],
  },
  {
    key: 'direct-send',
    title: 'Direct Send',
    description: 'Quickly send your product wherever they need to go',
    link: routes.engagement.directSend,
    icon: DirectSendFlowIcon,
  },
  {
    key: 'one-link',
    title: 'Create One Link Send',
    description: 'Create one link for multiple recipients to receive a send',
    link: routes.engagement.oneLink,
    icon: OneLinkFlowIcon,
    roles: [DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN],
  },
  {
    key: 'automated-campaigns',
    title: 'Automated Campaigns',
    description: 'Add integrations to automatically trigger sends',
    link: routes.automations,
    icon: AutomatedCampaignsFlowIcon,
    roles: [DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN],
  },
  {
    key: 'delivery-reports',
    title: 'Delivery Reports',
    description: 'See past sends and their status',
    link: routes.reports.root,
    icon: ReportsIcon,
  },
  {
    key: 'integration-options',
    title: 'Integration Options',
    description: 'Status of your product integrations',
    link: routes.integrations,
    icon: IntegrationFlowIcon,
  },
  {
    key: 'users-list',
    title: <React.Fragment>Users</React.Fragment>,
    description: 'Manage all users in one page',
    link: routes.users.root,
    icon: UsersFlowIcon,
    roles: [DEPT_ADMIN, ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN],
  },
  {
    key: 'assets-manager',
    title: <React.Fragment>Assets Manager</React.Fragment>,
    description: 'Manage campaigns, inventory and templates in one tab',
    icon: AssetsFlowIcon,
    subFlowOptions: [
      {
        key: 'campaigns',
        title: <React.Fragment>Campaigns</React.Fragment>,
        link: routes.campaigns.root,
        icon: CampaignsFlowIcon,
      },
      {
        key: 'inventory',
        title: <React.Fragment>Inventory</React.Fragment>,
        link: routes.inventory.root,
        icon: InventoryFlowIcon,
      },
      {
        key: 'template',
        title: <React.Fragment>Templates</React.Fragment>,
        link: routes.templates.root,
        icon: TemplatesFlowIcon,
      },
      {
        key: 'organizations',
        title: <React.Fragment>Organizations</React.Fragment>,
        link: routes.organizations.root,
        icon: OrganizationsFlowIcon,
        roles: [DISTRIBUTOR, SUPER_ADMIN],
      },
      {
        key: 'business-reasons',
        title: <React.Fragment>Business Reasons</React.Fragment>,
        link: routes.businessReasons,
        icon: BusinessReasonsIcon,
      },
      {
        key: 'address-book',
        title: <React.Fragment>Address Book</React.Fragment>,
        link: routes.addressBook,
        icon: AddressBookIcon,
      },
    ],
  },
  {
    key: 'admin-tools',
    title: 'Admin Tools',
    description: 'Manage sends here',
    icon: AdminToolsFlowIcon,
    subFlowOptions: [
      {
        key: 'engagements-manager',
        title: 'Send Manager',
        link: routes.engagementsManager,
        icon: EngagementsManagerFlowIcon,
      },

      {
        icon: OneLinkManagerFlowIcon,
        key: 'one-link-manager',
        title: 'One Link Manager',
        link: routes.oneLinkManager.root,
        roles: [DEPT_ADMIN, ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN],
      },
    ],
  },
];
