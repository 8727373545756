import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as InventoryIcon } from '../../assets/images/icon-msku.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning-round.svg';
import { NO_LOW_INVENTORY_ITEMS_MESSAGE } from '../../constants/inventories';
import { fetchLowInventoryItemsRequest } from '../../store/actions/inventories';
import { selectShouldShowLowInventoryWarning } from '../../store/selectors/shell';
import { BUTTON_BUTTON } from '../../types/forms';
import { IApiError, NotificationListEnum } from '../../types/shell';
import notification from '../../utils/notification';

import styles from './LowInventoryMenuItem.module.scss';

interface IProps {
  className?: string;
  onClick?: () => void;
}

const LowInventoryMenuItem = ({ className, onClick }: IProps) => {
  const dispatch = useDispatch();

  const hasWarning = useSelector(selectShouldShowLowInventoryWarning);

  const handleFetchLowInventoryItems = React.useCallback(() => {
    const successLowInventoriesToast = () => {
      notification.success(NotificationListEnum.Success, {
        content: NO_LOW_INVENTORY_ITEMS_MESSAGE,
      });
    };

    const errorLowInventoriesToast = (error: IApiError) => {
      notification.error(NotificationListEnum.Error, {
        content: error.message,
      });
    };

    dispatch(
      fetchLowInventoryItemsRequest({
        showModal: true,
        resolve: successLowInventoriesToast,
        reject: errorLowInventoriesToast,
      }),
    );
  }, [dispatch]);

  return (
    <button
      className={cn(styles.container, className)}
      type={BUTTON_BUTTON}
      onClick={() => {
        handleFetchLowInventoryItems();
        onClick?.();
      }}
    >
      <div className={styles.icons}>
        <InventoryIcon />
        {hasWarning ? <WarningIcon className={styles.alert} /> : null}
      </div>
      <span className={styles.label}>Low Inventory</span>
    </button>
  );
};

export default LowInventoryMenuItem;
