import cn from 'classnames';
import * as React from 'react';

import styles from './InfoLink.module.scss';

interface IProps {
  className?: string;
  toggleClassName?: string;
  url: string;
  label: string;
  isBlank?: boolean;
  hint?: string;
}

export default ({ className, toggleClassName, url, hint, label, isBlank }: IProps) => {
  const targetProps = React.useMemo(() => (isBlank ? { target: '_blank', rel: 'noopener noreferer' } : {}), [isBlank]);

  return (
    <div className={cn(styles.container, className)}>
      <a className={styles.link} href={url} {...targetProps}>
        {label}
      </a>
      {hint ? (
        <React.Fragment>
          <div className={cn(styles.toggle, toggleClassName)}>?</div>
          <div className={styles.info}>{hint}</div>
        </React.Fragment>
      ) : null}
    </div>
  );
};
