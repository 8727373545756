import { OpportunitiesModal, ResetPasswordModal, RocketModal } from '../components';
import DownloadSuccessModal from '../components/modals/DownloadSuccessModal/DownloadSuccessModal';
import LowInventoryModal from '../components/modals/LowInventoryModal/LowInventoryModal';
import RemovePIIModal from '../components/modals/RemovePIIModal/RemovePIIModal';
import * as ModalActions from '../store/actions/modals';

export const MODALS = {
  RESET_PASSWORD: 'reset-password',
  ROCKET: 'rocket',
  OPPORTUNITIES: 'opportunities',
  INTEGRATION_WARNING: 'integration-warning',
  DOWNLOAD_SUCCESS: 'download-success',
  LOW_INVENTORY_ITEMS: 'low-inventory-items',
  REMOVE_PII: 'remove-pii',
};

export const MODAL_COMPONENTS = {
  [MODALS.RESET_PASSWORD]: ResetPasswordModal,
  [MODALS.ROCKET]: RocketModal,
  [MODALS.OPPORTUNITIES]: OpportunitiesModal,
  [MODALS.DOWNLOAD_SUCCESS]: DownloadSuccessModal,
  [MODALS.LOW_INVENTORY_ITEMS]: LowInventoryModal,
  [MODALS.REMOVE_PII]: RemovePIIModal,
};

export const MODAL_ACTIONS = {
  [MODALS.RESET_PASSWORD]: ModalActions.toggleResetPasswordModal,
  [MODALS.ROCKET]: ModalActions.toggleRocketModal,
  [MODALS.OPPORTUNITIES]: ModalActions.toggleOpportunitiesModal,
  [MODALS.DOWNLOAD_SUCCESS]: ModalActions.toggleDownloadSuccessModal,
  [MODALS.LOW_INVENTORY_ITEMS]: ModalActions.toggleLowInventoryModal,
  [MODALS.REMOVE_PII]: ModalActions.toggleRemovePIIModal,
};

export const MODAL_VIEWS = {
  [MODALS.RESET_PASSWORD]: 'Reset-password_modal_view',
  [MODALS.ROCKET]: 'Confirmation_modal_view',
  [MODALS.OPPORTUNITIES]: 'Opportunities_modal_view',
  [MODALS.INTEGRATION_WARNING]: 'Integration-warning_modal_view',
};
