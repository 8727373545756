import * as React from 'react';
import { CellProps } from 'react-table';

import { getColumnId, getCustomProps, getOriginal } from '../../../utils/tables';
import { Input } from '../../forms';

const EditableCell = <T extends Record<string, string | number>, V>(cellData: CellProps<T, V>) => {
  const {
    cell: { value },
  } = cellData;
  const originalData = getOriginal<T>(cellData);
  const { config, onChange, checkEditMode, isLoading, activeItem, onKeyPress } = getCustomProps<T, V>(cellData) || {};
  const columnId = getColumnId<T>(cellData);
  const isEditable = checkEditMode?.(originalData);

  const renderData = (() => {
    const CustomView = config?.cells?.views?.[columnId]?.render;

    if (CustomView) {
      return <CustomView {...originalData} />;
    }

    return value;
  })();

  const Component: any = config?.cells?.inputs?.[columnId]?.render || Input;

  const configProps = config?.cells?.inputs?.[columnId]?.props?.(activeItem ?? originalData);

  return isEditable && !config?.columns?.readOnly?.includes(columnId) && activeItem ? (
    <Component
      value={activeItem[columnId]}
      onChange={onChange}
      name={columnId}
      inline
      autoFocus
      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyPress?.(e, activeItem)}
      disabled={isLoading}
      {...configProps}
    />
  ) : (
    <div {...cellData.cell.getCellProps()}>{renderData as React.ReactNode}</div>
  );
};

export default EditableCell;
