import { call, put, takeLatest } from 'redux-saga/effects';

import { apiUrl } from '../../config/app';
import { endpoints, routes } from '../../constants/routing';
import { isTemplateEmail } from '../../utils/template';
import { routerProxyPush } from '../actions/shell';
import * as TemplatesActions from '../actions/templates';
import { AuthRequest } from './helpers';

function* emailsTemplatesWorkerSaga(): Generator<any, any, any> {
  try {
    const endpoint = `${apiUrl}${endpoints.emails}`;

    const response = yield call<any>(AuthRequest, { endpoint });
    if (!response.ok) {
      yield put(TemplatesActions.fetchEmailsTemplatesFailure(response.body));
    } else {
      yield put(TemplatesActions.fetchEmailsTemplatesSuccess(response.body));
    }
  } catch (error) {
    yield put(TemplatesActions.fetchEmailsTemplatesFailure(error));
  }
}

function* notesTemplatesWorkerSaga(): Generator<any, any, any> {
  try {
    const endpoint = `${apiUrl}${endpoints.notes}`;

    const response = yield call<any>(AuthRequest, { endpoint });
    if (!response.ok) {
      yield put(TemplatesActions.fetchNotesTemplatesFailure(response.body));
    } else {
      yield put(TemplatesActions.fetchNotesTemplatesSuccess(response.body));
    }
  } catch (error) {
    yield put(TemplatesActions.fetchNotesTemplatesFailure(error));
  }
}

function* addTemplateWorkerSaga(action: any): Generator<any, any, any> {
  const {
    template: { templateType, ...rest },
    resolve,
    reject,
  } = action.payload || {};

  try {
    const endpoint = `${apiUrl}${endpoints.createTemplate}`;
    const response = yield call<any>(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({
        [isTemplateEmail(templateType) ? 'email_templates' : 'message_templates']: [rest],
      }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(TemplatesActions.addTemplateFailure(response.body));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(TemplatesActions.addTemplateSuccess(response.body));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(TemplatesActions.addTemplateFailure(error));
  }
}

function* editTemplateWorkerSaga(action: any): Generator<any, any, any> {
  const {
    template: { templateType: type, ...originalTemplate },
    resolve,
    reject,
  } = action.payload || {};
  try {
    const endpoint = `${apiUrl}${endpoints.editTemplate}`;
    const response = yield call<any>(AuthRequest, {
      endpoint,
      method: 'POST',
      body: JSON.stringify({
        [isTemplateEmail(type) ? 'email_template' : 'message_template']: originalTemplate,
      }),
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(TemplatesActions.editTemplateFailure(response.body));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(TemplatesActions.editTemplateSuccess(response.body));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(TemplatesActions.editTemplateFailure(error));
  }
}

function* deleteTemplateWorkerSaga(action: any): Generator<any, any, any> {
  const { itemId, type, resolve, reject } = action.payload || {};
  try {
    if (!itemId || !type) {
      if (typeof reject === 'function') {
        reject();
      }
      yield put(TemplatesActions.deleteTemplateFailure('No id or type'));
    }

    const endpoint = `${apiUrl}${endpoints.deleteTemplate}`;
    const qs = { item_id: itemId, type: isTemplateEmail(type) ? 'email_template' : 'message_template' };

    const response = yield call(AuthRequest, {
      queryParams: qs,
      endpoint,
      method: 'DELETE',
    });

    if (!response.ok) {
      if (typeof reject === 'function') {
        reject(response.body);
      }
      yield put(TemplatesActions.deleteTemplateFailure(response.body));
    } else {
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
      yield put(routerProxyPush(routes.templates.root));
      yield put(TemplatesActions.deleteTemplateSuccess({ itemId, type }));
    }
  } catch (error) {
    if (typeof reject === 'function') {
      reject(error);
    }
    yield put(TemplatesActions.deleteTemplateFailure(error));
  }
}

const sagas = {
  *watchEmailsTemplatesRequest() {
    yield takeLatest(TemplatesActions.FETCH_EMAILS_TEMPLATES_REQUEST, emailsTemplatesWorkerSaga);
  },
  *watchNotesTemplatesRequest() {
    yield takeLatest(TemplatesActions.FETCH_NOTES_TEMPLATES_REQUEST, notesTemplatesWorkerSaga);
  },
  *watchAddTemplateRequest() {
    yield takeLatest(TemplatesActions.ADD_TEMPLATE_REQUEST, addTemplateWorkerSaga);
  },
  *watchEditTemplateRequest() {
    yield takeLatest(TemplatesActions.EDIT_TEMPLATE_REQUEST, editTemplateWorkerSaga);
  },
  *watchDeleteTemplateRequest() {
    yield takeLatest(TemplatesActions.DELETE_TEMPLATE_REQUEST, deleteTemplateWorkerSaga);
  },
};

export default sagas;
