import * as React from 'react';
import { useSelector } from 'react-redux';
import { animated, config as springConfig } from 'react-spring';
import ScaleInOut from '../../animations/ScaleInOut';

import { selectOrganizationInfo, selectUserProfile } from '../../store/selectors/auth';

import styles from './CurrentUserInfo.module.scss';

const CurrentUserInfo: React.FC = () => {
  const userProfile = useSelector(selectUserProfile);
  const organization = useSelector(selectOrganizationInfo);

  return (
    <ScaleInOut trigger={!!organization?.name} config={springConfig.gentle}>
      {({ style }) => (
        <animated.div style={style} className={styles.wrapper}>
          <div className={styles.name}>{userProfile?.displayName}</div>
          <div className={styles.organization}>{organization?.name}</div>
        </animated.div>
      )}
    </ScaleInOut>
  );
};
export default CurrentUserInfo;
