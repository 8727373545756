import cn from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';

import { ReactComponent as IconUser } from '../../../assets/images/icon-user.svg';
import {
  RECEIVER_CRM_OPPORTUNITY_ID,
  RECEIVER_CRM_RECORD_ID,
  RECEIVER_CRM_RECORD_TYPE,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
} from '../../../constants/shipping';
import { IShippingFormValues } from '../../../types/shipping';
import { HiddenInput, Input } from '../inputs';
import styles from './DigitalShippingForm.module.scss';

interface IProps {
  onChange: (...args: any[]) => void;
  disabled?: boolean;
  isShippingOptionEnabled?: boolean;
  isCRMEnabled?: boolean;
  form: FormikProps<Partial<IShippingFormValues>>;
  requiredFields: Record<string, boolean>;
  className?: string;
}

const DigitalShippingForm = ({ onChange, disabled, isCRMEnabled, form, requiredFields, className }: IProps) => {
  const getFieldErrors = React.useCallback(
    (fieldName: keyof IShippingFormValues) => {
      return form.errors[fieldName];
    },
    [form.errors],
  );

  return (
    <form onSubmit={form.handleSubmit} className={cn(styles.digitalShippingForm, className)}>
      <div className={cn(styles.info)}>
        <Input
          helperText="First name"
          name={RECEIVER_FIRST_NAME}
          className={styles.input}
          placeholder="First name"
          disabled={disabled}
          required={requiredFields[RECEIVER_FIRST_NAME]}
          icon={<IconUser />}
          onChange={onChange}
          value={form.values[RECEIVER_FIRST_NAME]}
          error={getFieldErrors(RECEIVER_FIRST_NAME)}
        />
        <Input
          helperText="Last name"
          name={RECEIVER_LAST_NAME}
          className={styles.input}
          placeholder="Last name"
          disabled={disabled}
          required={requiredFields[RECEIVER_LAST_NAME]}
          icon={<IconUser />}
          onChange={onChange}
          value={form.values[RECEIVER_LAST_NAME]}
          error={getFieldErrors(RECEIVER_LAST_NAME)}
        />
      </div>
      {isCRMEnabled && (
        <React.Fragment>
          <HiddenInput name={RECEIVER_CRM_RECORD_ID} value={form.values[RECEIVER_CRM_RECORD_ID]} />
          <HiddenInput name={RECEIVER_CRM_RECORD_TYPE} value={form.values[RECEIVER_CRM_RECORD_TYPE]} />
          <HiddenInput name={RECEIVER_CRM_OPPORTUNITY_ID} value={form.values[RECEIVER_CRM_OPPORTUNITY_ID]} />
        </React.Fragment>
      )}
    </form>
  );
};

export default DigitalShippingForm;
