import * as React from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/images/icon-close-cross.svg';
import { BUTTON_BUTTON } from '../../../types/forms';
import { UISizeEnum } from '../../../types/shell';
import { CopyableButton } from '../../forms';
import ModalActions from '../ModalActions/ModalActions';
import ModalHeader from '../ModalHeader/ModalHeader';

import styles from './CommonModals.module.scss';

interface IProps {
  url: string;
  onClose?: () => void;
}

const ResetUserPasswordInfoModal: React.FC<IProps> = ({ url, onClose }: IProps) => {
  return (
    <div className={styles.container}>
      <ModalHeader
        className={styles.header}
        right={
          <button type={BUTTON_BUTTON}>
            <CloseIcon onClick={onClose} />
          </button>
        }
      />
      <div className={styles.content}>
        <div className={styles.contentHeader}>Password Reset</div>
        <div className={styles.description}>
          <p>You can send a link to the user now</p>
        </div>
        <div className={styles.status}>
          <CopyableButton className={styles.link} value={url}>
            The Link
          </CopyableButton>
          <p className={styles.linkInfo}>* Expires in one hour</p>
        </div>
      </div>
      <ModalActions className={styles.footer}>
        <CopyableButton value={url} size={UISizeEnum.Normal}>
          Copy the link
        </CopyableButton>
      </ModalActions>
    </div>
  );
};

export default ResetUserPasswordInfoModal;
