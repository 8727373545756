import cn from 'classnames';
import { FieldAttributes } from 'formik';
import * as React from 'react';

import { HelpTooltip } from '../../../tooltips';

import styles from './Checkbox.module.scss';

interface IProps extends FieldAttributes<any> {
  containerClassName?: string;
  className?: string;
  id?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  text?: string | React.ReactNode;
  hint?: string;
  disabled?: boolean;
  isReadOnly?: boolean;
}

const Checkbox: React.FC<IProps> = ({
  containerClassName,
  className,
  id,
  checked,
  onChange,
  text,
  hint,
  field,
  disabled,
  isReadOnly,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
    if (field?.onChange) {
      field.onChange(e);
    }
  };

  const handleClick = React.useCallback((e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  return (
    <div className={cn(styles.checkboxWrapper, containerClassName)}>
      <label
        htmlFor={id}
        className={cn(styles.container, className, { [styles.readOnly]: isReadOnly })}
        onClick={handleClick}
      >
        <input
          id={id}
          name={field?.name || id}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          readOnly={isReadOnly}
        />
        <span className={styles.text}>{text}</span>
        <span className={styles.checkmark} />
      </label>

      {hint && (
        <HelpTooltip id={`checkbox-tooltip-${field?.name || id}`} className={styles.hint}>
          {hint}
        </HelpTooltip>
      )}
    </div>
  );
};

export default Checkbox;
