import cn from 'classnames';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { OrganizationForm, TextIconToggle } from '../../components/forms';
import MaintenanceForm from '../../components/forms/MaintenanceForm/MaintenanceForm';
import {
  DEFAULT_EMAIL_CUSTOMIZATION_OPTIONS,
  DEFAULT_PORTAL_CUSTOMIZATION_OPTIONS,
  DEFAULT_SHIPPING_PAGE_CUSTOMIZATION_OPTIONS,
  DELAYED_SHIPPING_BASE_URL,
  DELAYED_SHIPPING_PAGE,
  INITIAL_ORGANIZATION_FORM_STATE,
  ORDER_KEY,
  OrganizationSuccessToastMessages,
  ORGANIZATION_LABEL,
  ORGANIZATION_VALIDATION_SCHEMA as validationSchema,
} from '../../constants/organizations';
import { endpoints, queryParams, routes, URL_VARS } from '../../constants/routing';
import useFetch from '../../hooks/useFetch';
import useWindowSize from '../../hooks/useWindowSize';
import {
  addOrganizationDetails,
  addOrganizationRequest,
  clearOrganizationDetails,
  editOrganizationRequest,
} from '../../store/actions/organizations';
import { selectOrganizationById, selectOrganizationDetails } from '../../store/selectors/organizations';
import { selectDelayedShippingBaseUrl } from '../../store/selectors/shell';
import { FormStatusEnum, IMaintenanceFormOutputData, MaintenanceFormStateEnum } from '../../types/forms';
import {
  IAddOrganizationActionPayload,
  IEditOrganizationActionPayload,
  IOrganizationItem,
  IValidateOrderKeyResponse,
  OrganizationResetCustomizationOptionsTypesEnum,
  TOrganizationsDetails,
} from '../../types/organizations';
import { IOrganizationRouteParams } from '../../types/routing';
import { IApiError, NotificationListEnum } from '../../types/shell';
import { getRequiredFields, handleComplexFieldChange } from '../../utils/form';
import { isObjectsEqual } from '../../utils/helpers';
import notification from '../../utils/notification';
import { removeUntrackedOrgFields } from '../../utils/organizations';
import { getQuery } from '../../utils/request';

import styles from './EditOrganizationContainer.module.scss';

interface IProps {
  onDeactivate?: (...args: unknown[]) => unknown; // TODO add type definitions when ready
  onActivate?: (...args: unknown[]) => unknown; // TODO add type definitions when ready
  toggleSidebar: (uid?: string) => void;
}

const EditOrganizationContainer: React.FC<IProps> = ({ /* onActivate, onDeactivate, */ toggleSidebar }: IProps) => {
  const { flowId, orgId } = useParams<IOrganizationRouteParams>();
  const dispatch = useDispatch();
  const history = useHistory();

  const [shouldValidateOrderKey, setShouldValidateOrderKey] = React.useState(false);

  const { mobile } = useWindowSize();
  const originalItem = useSelector(selectOrganizationById(orgId));
  const organizationDetails = useSelector(selectOrganizationDetails);
  const delayedShippingBaseUrl = useSelector(selectDelayedShippingBaseUrl);

  const {
    make: validateOrderKeyRequest,
    request: validateOrderKeyPayload,
    response: validateOrderKeyResponse,
    error: orderKeyError,
    isLoading: isOrderKeyValidating,
    reset: resetOrderKey,
  } = useFetch<never, IValidateOrderKeyResponse, IValidateOrderKeyResponse>({
    endpoint: endpoints.validateOrderKey,
    flags: {
      clearResponseOnRequest: true,
      clearResponseOnFailure: true,
    },
  });

  const mode = React.useMemo(() => {
    switch (flowId) {
      case URL_VARS.NEW:
        return MaintenanceFormStateEnum.Adding;
      case URL_VARS.EDIT:
        return MaintenanceFormStateEnum.Editing;
      case URL_VARS.VIEW:
        return MaintenanceFormStateEnum.Reading;
      default:
        return MaintenanceFormStateEnum.Reading;
    }
  }, [flowId]);

  const lastCheckedOrderKey = React.useMemo(() => {
    const usedQuery = validateOrderKeyPayload ? validateOrderKeyPayload.query : null;
    return usedQuery ? (usedQuery[queryParams.orderKey] as string) : null;
  }, [validateOrderKeyPayload]);

  const rawInitialValues = React.useMemo(
    () => ({
      ...INITIAL_ORGANIZATION_FORM_STATE,
      [DELAYED_SHIPPING_BASE_URL]: delayedShippingBaseUrl,
    }),
    [delayedShippingBaseUrl],
  );

  const initialValues = React.useMemo(
    () =>
      organizationDetails
        ? (organizationDetails as IOrganizationItem)
        : // Cannot merge initial values in a selector because or webpack circular dependencies issue
          rawInitialValues,
    [organizationDetails, rawInitialValues],
  );

  const isInitialValid = React.useMemo(() => validationSchema.isValidSync(initialValues), [initialValues]);

  const isOrganizationActive = React.useMemo(() => true, []);

  const requiredFields = React.useMemo(() => getRequiredFields(validationSchema), []);

  const isValueChanged = React.useMemo(() => {
    switch (true) {
      case mode === MaintenanceFormStateEnum.Editing:
        if (!originalItem || !organizationDetails) {
          return true;
        }

        // FIXME: [Workaround] EX-4405 This is done because the form should not overwrite org_options.automation_connections
        return !isObjectsEqual(
          removeUntrackedOrgFields(originalItem),
          removeUntrackedOrgFields(organizationDetails as IOrganizationItem),
        );
      case mode === MaintenanceFormStateEnum.Adding:
        return !isObjectsEqual(organizationDetails, rawInitialValues);
      default:
        return false;
    }
  }, [mode, originalItem, organizationDetails, rawInitialValues]);

  const dispatchSubmit = React.useCallback(
    (orgFormOutput: IAddOrganizationActionPayload | IEditOrganizationActionPayload) => {
      const shouldReauthorize =
        orgFormOutput.values?.org_options?.email_currently_supports !==
          originalItem?.org_options?.email_currently_supports ||
        orgFormOutput.values?.org_options?.crm_currently_supports !==
          originalItem?.org_options?.crm_currently_supports ||
        orgFormOutput.values?.org_options?.sign_in_method_supports !==
          originalItem?.org_options?.sign_in_method_supports;

      switch (flowId) {
        case URL_VARS.NEW:
          return dispatch(addOrganizationRequest(orgFormOutput));
        case URL_VARS.EDIT:
          // FIXME: [Workaround] EX-4405 This is done because the form should not overwrite org_options.automation_connections
          const { values: orgCandidate, ...payload } = orgFormOutput as IEditOrganizationActionPayload;

          dispatch(
            editOrganizationRequest({
              ...payload,
              values: removeUntrackedOrgFields(orgCandidate),
              metadata: { shouldReauthorize },
            }),
          );
          return;
        default:
          return null;
      }
    },
    [dispatch, flowId, originalItem],
  );

  const handleSubmit = React.useCallback(
    (
      values: TOrganizationsDetails,
      { resetForm, setSubmitting, setStatus, setErrors }: FormikHelpers<TOrganizationsDetails>,
    ) => {
      if (!validationSchema.isValidSync(values)) {
        return;
      }

      return new Promise<TOrganizationsDetails>((resolve, reject) => {
        dispatchSubmit({
          values,
          resolve,
          reject,
        });
      })
        .then((response) => {
          resetForm();
          setStatus(FormStatusEnum.Success);
          setSubmitting(false);
          notification.success(NotificationListEnum.Success, { content: OrganizationSuccessToastMessages[flowId] });
          if (mode === MaintenanceFormStateEnum.Adding) {
            history.push(
              response.uid
                ? routes.organizations.getOrganizationUrl({ flowId: URL_VARS.EDIT, orgId: response.uid })
                : routes.organizations.root,
            );
          }
          if (mode === MaintenanceFormStateEnum.Editing) {
            history.push(routes.organizations.root);
          }
        })
        .catch((e: IApiError) => {
          setErrors({ api: e?.message } as FormikErrors<TOrganizationsDetails>);
          setStatus(FormStatusEnum.Error);
        });
    },
    [flowId, dispatchSubmit, history, mode],
  );

  // name here can be a complex object with lodash dot notation, like `shipping.address`
  const handleFieldChange = React.useCallback(
    (name: string, value: string | boolean | number | string[] | null) => {
      const isClearedArrayFieldValue = Array.isArray(value) && value.length === 0;

      return dispatch(addOrganizationDetails(handleComplexFieldChange(name, isClearedArrayFieldValue ? null : value)));
    },
    [dispatch],
  );

  const handleCancel = React.useCallback(() => {
    if (originalItem) {
      dispatch(addOrganizationDetails(originalItem));
    }
  }, [dispatch, originalItem]);

  const form = useFormik<TOrganizationsDetails>({
    initialStatus: FormStatusEnum.Start,
    initialValues,
    isInitialValid,
    validateOnMount: true,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const orderKey = React.useMemo(() => form.values?.[ORDER_KEY], [form.values?.[ORDER_KEY]]);
  const encodedOrderKey = React.useMemo(() => encodeURIComponent(orderKey || ''), [orderKey]);

  React.useEffect(() => {
    if (flowId === URL_VARS.NEW && isEmpty(omit(organizationDetails, DELAYED_SHIPPING_PAGE))) {
      dispatch(addOrganizationDetails(initialValues));
    }

    if (isEmpty(omit(organizationDetails, DELAYED_SHIPPING_PAGE)) || orgId !== organizationDetails?.uid) {
      if ((flowId === URL_VARS.EDIT || flowId === URL_VARS.VIEW) && originalItem) {
        dispatch(addOrganizationDetails(originalItem));
      }
    }
  }, [dispatch, originalItem, organizationDetails, flowId, orgId, initialValues]);

  React.useEffect(() => {
    return () => {
      dispatch(clearOrganizationDetails());
    };
  }, [dispatch]);

  React.useEffect(() => {
    // This effect handles order_key field async validation call
    // It should be validated if
    //  - it has length more than 2 characters
    //  - if we didn't check it before
    //  - if ir was really changed

    // escaping orderKey value since it's used as a regular request query param
    if (
      orderKey &&
      orderKey.length > 2 &&
      encodedOrderKey !== lastCheckedOrderKey &&
      orderKey !== originalItem?.[ORDER_KEY] &&
      !isOrderKeyValidating &&
      ((originalItem && mode === MaintenanceFormStateEnum.Editing) || mode === MaintenanceFormStateEnum.Adding)
    ) {
      setShouldValidateOrderKey(true);
    }
  }, [orderKey, lastCheckedOrderKey, isOrderKeyValidating, originalItem?.[ORDER_KEY], mode]);

  /*
   * The effect below and isOrderKeyValidating state are needed to prevent multiple requests to the server

    * If any warnings in the response are observed, they are shown in a notification
   */
  React.useEffect(() => {
    if (shouldValidateOrderKey && !isOrderKeyValidating && encodedOrderKey) {
      Promise.resolve()
        .then(() => {
          setShouldValidateOrderKey(false);
        })
        .then(() => {
          const query = getQuery(encodedOrderKey, queryParams.orderKey);
          return validateOrderKeyRequest({ query });
        });
    }
  }, [shouldValidateOrderKey, validateOrderKeyRequest, encodedOrderKey, isOrderKeyValidating]);

  React.useEffect(() => {
    // This effect resets the useFetch hook to initial state if:
    // - there's an error kept from the previous validation requests
    // AND
    // - entered order key is the same as in original object;
    // OR
    // - entered order key is shorter than 3 symbols;
    if (orderKey && orderKeyError && (orderKey === originalItem?.[ORDER_KEY] || orderKey.length <= 2)) {
      resetOrderKey();
    }
  }, [resetOrderKey, orderKeyError, orderKey, originalItem?.[ORDER_KEY]]);

  const handleResetCustomization = React.useCallback(
    (property: OrganizationResetCustomizationOptionsTypesEnum) => {
      switch (property) {
        case OrganizationResetCustomizationOptionsTypesEnum.ShippingPage: {
          dispatch(addOrganizationDetails(DEFAULT_SHIPPING_PAGE_CUSTOMIZATION_OPTIONS(delayedShippingBaseUrl || '')));
          return;
        }
        case OrganizationResetCustomizationOptionsTypesEnum.Portal: {
          dispatch(addOrganizationDetails(DEFAULT_PORTAL_CUSTOMIZATION_OPTIONS));
          return;
        }
        case OrganizationResetCustomizationOptionsTypesEnum.Email: {
          dispatch(addOrganizationDetails(DEFAULT_EMAIL_CUSTOMIZATION_OPTIONS));
          return;
        }
        default:
          return;
      }
    },
    [dispatch, delayedShippingBaseUrl],
  );

  return (
    <div className={cn(styles.container)}>
      <MaintenanceForm
        root={routes.organizations.root}
        form={form}
        assetName={ORGANIZATION_LABEL}
        mode={mode}
        onCancel={handleCancel}
        onDiscard={() => history.push(routes.organizations.root)}
        isValueChanged={isValueChanged}
        disabled={Boolean(orderKeyError?.errors && orderKeyError.errors.length)}
        config={{
          delete: {
            hide: true,
          },
          ...(mode === MaintenanceFormStateEnum.Editing || mode === MaintenanceFormStateEnum.Adding
            ? {
                actions: [
                  {
                    component: (
                      <TextIconToggle
                        key="activate-deactivate-button"
                        title={isOrganizationActive ? 'Deactivate this organization' : 'Activate this organization'}
                        trigger={mobile}
                        active={isOrganizationActive}
                        textClassName={cn(styles.actionBtn, isOrganizationActive ? styles.deactivate : styles.activate)}
                        onClick={notification.comingSoon}
                      />
                    ),
                  },
                ],
              }
            : {}),
        }}
      >
        {(props: IMaintenanceFormOutputData) => {
          return (
            <OrganizationForm
              {...props}
              onChange={handleFieldChange}
              form={form}
              requiredFields={requiredFields}
              toggleSidebar={toggleSidebar}
              onResetCustomization={handleResetCustomization}
              orderKey={{
                validating: isOrderKeyValidating,
                error: orderKeyError?.errors,
                warning: validateOrderKeyResponse?.warnings,
                lastChecked: lastCheckedOrderKey ? decodeURIComponent(lastCheckedOrderKey) : null,
              }}
            />
          );
        }}
      </MaintenanceForm>
    </div>
  );
};

EditOrganizationContainer.whyDidYouRender = true;
export default EditOrganizationContainer;
